import React from "react";
import { Helmet } from "react-helmet";
import { CONSTANTS as C } from "config";

const Seo = () => {
  return (
    <Helmet>
      <title>{process.env.REACT_APP_TITLE}</title>
      <meta property="og:title" content={process.env.REACT_APP_TITLE} />
      <meta name="twitter:title" content={process.env.REACT_APP_TITLE} />
      <link
        rel="icon"
        type="image/x-icon"
        href={process.env.REACT_APP_FAVICON}
      />
      <meta property="og:image" content={process.env.REACT_APP_FAVICON} />
      <meta property="og:image" content={process.env.REACT_APP_COMPANY_LOGO} />
      {process.env.REACT_APP_COMPANY_NAME === C.COMPANY_NAMES.CUSINETTE ? (
        <link rel="manifest" href="/cusinette_manifest.json" />
      ) : (
        <link rel="manifest" href="/manifest.json" />
      )}
      <link rel="apple-touch-icon" href={process.env.REACT_APP_FAVICON} />
    </Helmet>
  );
};

export default Seo;
