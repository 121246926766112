import React, { useState, useMemo } from "react";
import { Table, Button, Spin } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import RenderTime from "features/shipping_methods/common/RenderTime";
import RadioButton from "components/RadioButton";
import useToggleStatus from "./hooks/useChangeAreaStatus";
import CurrencyFormatter from "components/CurrencyFormatter";
import { MAPPED_AREA_MODAL_ID } from "features/shipping_methods/constants";
function AreasTable({
  setOpen,
  setDialogContentId,
  selectedRowKeys,
  setSelectedDataRows,
  setSelectedRowsKeys,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const changeAreaStatus = useToggleStatus();

  const { addedMappedAreas, selectedStore } = useSelector(
    (state) => state.AreasReducer
  );

  const [searchTerm, setSearch] = useState("");
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  const toggleAreaStatus = (row, val) => {
    const payload = {
      mappedAreaBaseDeliveryMethodId: row?._id,
      isActive: !val,
    };
    changeAreaStatus({ payload, onClose: () => {} });
  };

  const columns = [
    {
      key: "Status",
      title: t("Status"),
      dataIndex: "isActive",
      width: "10%",
      render: (val, row) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <RadioButton
              key={row?._id}
              label={""}
              value={val}
              keyVal={val}
              handleValues={() => {
                toggleAreaStatus(row, val);
              }}
            />
          </div>
        );
      },
    },
    {
      key: "areaID",
      title: t("Area Name"),
      dataIndex: ["mappedAreaInfo", "translations"],
      sorter: (a, b) =>
        a?.areaID?.areaNameEN?.localeCompare(b?.areaID?.areaNameEN),
      render: (val) => {
        return val?.[currentLanguage?.toLowerCase()]?.name;
      },
    },

    {
      key: "cityID",
      title: t("City Name"),
      sorter: (a, b) =>
        a?.cityID?.cityNameEN?.localeCompare(b?.cityID?.cityNameEN),
      dataIndex: ["mappedAreaInfo", "city"],
      render: (val, row, index) => {
        return val?.[currentLanguage?.toLowerCase()];
      },
    },

    {
      key: "minOrder",
      title: t("Min. Basket Value"),
      dataIndex: "minOrder",
      sorter: {
        compare: (a, b) => a.minOrder - b.minOrder,
        multiple: 3,
      },
      render: (val) => {
        return <CurrencyFormatter {...{ currentLanguage, val }} />;
      },
    },

    {
      key: "deliveryFee",
      title: t("Delivery Fee"),
      dataIndex: "deliveryFee",
      sorter: {
        compare: (a, b) => a?.deliveryFee - b?.deliveryFee,
        multiple: 3,
      },
      render: (val) => {
        return <CurrencyFormatter {...{ currentLanguage, val }} />;
      },
    },

    {
      key: "deliveryTime",
      title: t("Delivery Time"),
      dataIndex: "deliveryTime",
      sorter: {
        compare: (a, b) => a.deliveryTime - b.deliveryTime,
        multiple: 3,
      },
      render: (val, row, index) => {
        return <RenderTime {...{ val, row, index }} />;
      },
    },
    {
      key: "Type",
      title: t("Type"),
      dataIndex: "isCustom",
      render: (val) => {
        return val ? t("Custom") : t("Default");
      },
    },
  ];

  const Items = useMemo(() => {
    const lowerCasedSearchTerm = searchTerm?.toLowerCase();
    if (!lowerCasedSearchTerm) {
      return addedMappedAreas;
    }
    return addedMappedAreas?.filter((item) => {
      const areaName =
        item?.mappedAreaInfo?.translations?.en?.name?.toLowerCase();
      const cityName = item?.mappedAreaInfo?.city?.en?.toLowerCase();
      return (
        areaName?.includes(lowerCasedSearchTerm) ||
        cityName?.includes(lowerCasedSearchTerm)
      );
    });
  }, [addedMappedAreas, searchTerm]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDataRows(selectedRows);
      setSelectedRowsKeys(selectedRowKeys);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selectedRowKeys.length !== 0) {
        setSelectedRowsKeys([]);
      }
    },
  };

  const handSearch = (e) => setSearch(e.target.value);

  const deleteArea = () => {
    setOpen(true);
    setDialogContentId(MAPPED_AREA_MODAL_ID.DELETE_AREA);
  };
  const editArea = () => {
    setOpen(true);
    setDialogContentId(MAPPED_AREA_MODAL_ID.UPDATE_AREA);
  };

  const areaCount = addedMappedAreas?.length || 0;
  const areaText = `${areaCount} ${t(areaCount <= 1 ? "Area" : "Areas")}`;

  return (
    <>
      <div className="bg-white rounded shadow-sm border-gray-200 border px-2 py-2">
        <Spin spinning={isLoading}>
          {selectedStore && (
            <>
              <div className="flex justify-between my-4 items-center flex-row-reverse flex-col-reverse  sm:flex-row">
                <div className="w-full sm:w-auto text-start pt-4 sm:pt-0">
                  <h2 className="font-bold p-1 align-middle text-16px sm:text-19px ">
                    {areaText}
                  </h2>
                </div>

                <div className="flex flex-col w-full sm:w-auto sm:flex-row">
                  <div className="flex justify-end">
                    {selectedRowKeys?.length > 0 && (
                      <>
                        <Button
                          type="primary"
                          className="mx-2 h-10"
                          onClick={editArea}
                        >
                          {t("Edit")} ({selectedRowKeys.length})
                        </Button>

                        <Button
                          danger
                          className="mx-2 h-10"
                          onClick={deleteArea}
                        >
                          {t("Delete")} ({selectedRowKeys.length})
                        </Button>
                      </>
                    )}
                  </div>

                  <div className="group relative mt-4 sm:mt-0">
                    <svg
                      fill="currentColor"
                      className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      />
                    </svg>
                    <input
                      onChange={handSearch}
                      value={searchTerm}
                      className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm"
                      type="text"
                      aria-label="Search area by name"
                      placeholder={t("search area by name")}
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="overflow-hidden overflow-x-auto md:overflow-hidden border-gray-200 border rounded-sm">
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={Items}
                  //row click
                  onRow={(record, rowIndex) => {
                    // exclude action column

                    return {
                      onClick: (event) => {
                        if (
                          selectedRowKeys.length === Items.length ||
                          selectedRowKeys.length >= 1
                        )
                          return;

                        if (record?.isCustom) {
                          history.push({
                            pathname: `/app/dashboard/edit-map-area/${record._id}`,
                            state: {
                              record,
                              selectedStore,
                            },
                          });
                        } else {
                          setSelectedDataRows([record]);
                          setSelectedRowsKeys([record._id]);
                          setDialogContentId(MAPPED_AREA_MODAL_ID.UPDATE_AREA);
                          setOpen(true);
                        }
                      }, // click row
                    };
                  }}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }}
                  locale={{ emptyText: t("No Data") }}
                  rowKey={(record) => record._id}
                />
              </div>
            </>
          )}
        </Spin>
      </div>
    </>
  );
}

export default AreasTable;
