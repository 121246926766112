import React from "react";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import Card from "components/Card";
import moment from "moment";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const AllPaymentLinksTable = ({ LINKS }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  const PAID_STATUS = "PAID";
  const renderStatus = (val) => (
    <span
      className={`${
        val === PAID_STATUS
          ? "bg-green-50 text-green-600"
          : "bg-yellow-50 text-yellow-600"
      } w-fit px-2 py-1`}
    >
      {val === PAID_STATUS ? t("Paid") : t("Pending")}
    </span>
  );

  const redirectToPaymentLink = (paymentLink) => {
    history.push("/app/dashboard/payment-link-detail", {
      paymentLink: paymentLink,
    });
  };
  const getPaymentLinkUrl = (val, row) => {
    const userDetail = UTILS.getCurrentUser();
    const storeFrontURL = userDetail?.storeFrontURL;
    if (storeFrontURL && row?.cartId && userDetail?.temporaryDomainName) {
      const paymentLink = `${storeFrontURL}${userDetail.temporaryDomainName}/payment-link/${row?.cartId}`;
      navigator.clipboard.writeText(paymentLink);
      UTILS.successNotification(t("Copied to Clipboard"));
    }
  };
  const renderActions = (val, row, index) => {
    return (
      <div>
        <svg
          onClick={() => {
            redirectToPaymentLink(row);
          }}
          data-v-1826c20e=""
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5  inline-block cursor-pointer hover:text-blue-600"
        >
          <path
            d="M10.097 15.561A4.265 4.265 0 014.44 17.6v0a4.265 4.265 0 01-2.04-5.66l1.294-2.748a4.264 4.264 0 015.658-2.038v0a4.252 4.252 0 012.35 2.986"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M9.34 4.439A4.265 4.265 0 0115 2.4a4.266 4.266 0 012.038 5.658l-1.293 2.749a4.265 4.265 0 01-5.659 2.038v0a4.241 4.241 0 01-2.2-2.451"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>

        <svg
          onClick={() => {
            getPaymentLinkUrl(val, row);
          }}
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 inline-block mx-2 cursor-pointer hover:text-blue-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M6.5 5.5h8a2 2 0 012 2v6a2 2 0 01-2 2h-8a2 2 0 01-2-2v-6a2 2 0 012-2zm1 1v6h6v-6h-6zM7.5 4a2 2 0 00-2 2v6a2 2 0 002 2h8a2 2 0 002-2v-6a2 2 0 00-2-2h-8z"
            clip-rule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M8.5 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2h-8a2 2 0 01-2-2v-1h1v1a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1h-8a1 1 0 00-1 1v1h-1V2z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    );
  };
  const columns = [
    {
      width: "25%",
      title: t("Store name"),
      dataIndex: "storeName",
      align: "initial",
      key: "storeName",
    },
    {
      title: t("Payment link id"),
      dataIndex: "cartId",
      key: "cartId",
      sorter: {
        compare: (a, b) => a.cartId - b.cartId,
        multiple: 3,
      },
    },
    {
      key: t("Amount"),
      title: t("Amount"),
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      title: t("Creation date"),
      dataIndex: "linkCreationDate",
      key: "linkCreationDate",
      render: (val, row, index) => {
        return moment.unix(row.linkCreationDate).format("DD/MM/YYYY");
      },
      sorter: {
        compare: (a, b) => a.linkCreationDate - b.linkCreationDate,
        multiple: 3,
      },
    },

    {
      title: t("Created by"),
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: t("Status"),
      dataIndex: "linkStatus",
      key: "status",
      render: renderStatus,
    },

    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
      render: renderActions,
    },
  ];

  return (
    <div>
      <div className="my-5">
        <Card styleClasses="py-2">
          <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
            <Table
              columns={columns}
              dataSource={LINKS}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
                defaultPageSize: 20,
              }}
              size="small"
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
export default AllPaymentLinksTable;
