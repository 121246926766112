import React, { Fragment } from "react";
import { Button } from "antd";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { ifnullOrUndefined } from "utils/Utils";
import { errorHandler } from "api/api/errorHandler";
import { API } from "api";
import { logErrorOnSentry } from "utils/Utils";
const CreateClaim = ({ fetchOrderDetailsByID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);

  const sendOrderToDeliveryZone = async () => {
    try {
      if (!orderInfo?._id) {
        UTILS.errorNotification(t("Invalid Order ID. Please try again."));
        logErrorOnSentry(
          "Invalid Order ID. Please try again--DZ create Order",
          "error",
          JSON.stringify(orderInfo)
        );
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const result = await API.createDZDelivery({
        orderId: orderInfo._id,
      });
      if (result?.success === 1) {
        UTILS.successNotification(t(result?.message));
      } else {
        UTILS.errorNotification(t(result?.error));
      }
    } catch (error) {
      UTILS.handleErrorNotification(errorHandler(error?.error));
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      dispatch(LoaderAction.setLoading(false));
      await fetchOrderDetailsByID();
    }
  };

  const allowSendOrderToDZ = () => {
    return ifnullOrUndefined(
      orderInfo?.logisticPartnerMetadata?.DELIVERY_ZONE?.createDeliveryResponse
        ?.order
    );
  };

  return (
    <Fragment>
      <div className="flex flex-col justify-center items-center">
        <div className="ml-4 py-4 text-center">
          {allowSendOrderToDZ() ? (
            <Button
              type="default"
              size="large"
              onClick={sendOrderToDeliveryZone}
              className="my-2 rounded-md font-semibold"
            >
              {t("Send order to delivery zone")}
            </Button>
          ) : (
            <div>
              <span className="text-sm text-gray-500">
                {t(
                  "Your order has been sent to delivery zone for delivery. Please check the ORDER DETAIL tab for Rider details"
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CreateClaim;
