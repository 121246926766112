import React from "react";
import { useTranslation } from "react-i18next";
import DashboardCard from "features/dashboard/components/DashboardCard";

const PaymentLinkStats = ({ earning }) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-1 gap-1 md:gap-6 mt-4">
      <div className="col-span-2 md:col-span-1">
        <DashboardCard
          pClass="font-montserrat font-semibold text-40px"
          heading={t("Payment Links Earning")}
          count={earning}
        />
      </div>
    </div>
  );
};

export default PaymentLinkStats;
