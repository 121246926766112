import React from "react";
import { SVG_FACEBOOK } from "svg/SVGs";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
const Facebook = ({ shopUrl }) => {
  const getUrl = () => {
    let platform = "?platform=fb";
    if (shopUrl) {
      return shopUrl + platform;
    } else {
      const userDetail = UTILS.getCurrentUser();
      const url =
        userDetail.storeFrontURL + userDetail.temporaryDomainName + platform;
      return url;
    }
  };
  const { t } = useTranslation();
  return (
    <div className="w-full lg:w-1/2 border border-gray-300 rounded px-2 py-2 mx-1">
      <div className="flex justify-betwen">
        <div className="flex w-1/2">
          <SVG_FACEBOOK />
          <h2 className="py-3 text-17px px-2">{t("Facebook")}</h2>
        </div>
        <div className="w-1/2 text-right ihidden smsblock px-2 py-1">
          <button
            className="bg-blue-200 text-blue-600 w-32 font-semibold  px-2 md:px-4 py-2 rounded"
            onClick={() => {
              navigator.clipboard.writeText(getUrl());
              UTILS.handleSuccesNotification(t("Copied to Clipboard"));
            }}
          >
            {t("Copy Link")}
          </button>
        </div>
      </div>
      <h3 className="text-12px">
        {t(
          "Now you can add a Shop now link on your business Facebook page that goes straight to your ordering menu!"
        )}
      </h3>
      <div className="relative flex w-full flex-wrap items-stretch my-2">
        <input
          readOnly
          type="text"
          placeholder={getUrl()}
          className="px-3 py-3 text-16px placeholder-blueGray-300 text-blueGray-600 relative  bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full pr-10"
        />
      </div>
      <div className="smblock smhidden  py-1">
        <button
          className="bg-blue-200 text-blue-600 w-32 font-semibold  px-2 md:px-4 py-2 rounded"
          onClick={() => {
            navigator.clipboard.writeText(getUrl());
            UTILS.handleSuccesNotification(t("Copied to Clipboard"));
          }}
        >
          {t("Copy Link")}
        </button>
      </div>
    </div>
  );
};
export default Facebook;
