import { API } from "api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";

function useGetCustomBuisnessAres() {
  const dispatch = useDispatch();
  const [customBuisnessAreas, setCustomBuisnessAreas] = useState([]); // [
  const { selectedStore } = useSelector((state) => state.AreasReducer);

  const getAllCustomBuisnessAreas = async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const response = await API.getAllCustomBuisnessAreas();
      if (response?.success === 1 && response?.data) {
        setCustomBuisnessAreas(response?.data);
      }
    } catch (error) {
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  useEffect(() => {
    getAllCustomBuisnessAreas();
  }, [selectedStore?.value]);
  return customBuisnessAreas;
}

export default useGetCustomBuisnessAres;
