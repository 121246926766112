import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "./components/Tabs";
import { LogisticAction } from "redux/actions";
import HomeTab from "logistic-integrations/components/HomeTab";
const Yandex = ({ order, fetchOrderDetailsByID }) => {
  const dispatch = useDispatch();
  const { careemModal } = useSelector((state) => state.LogisticReducer);

  return (
    <HomeTab
      {...{
        order,
        fetchOrderDetailsByID,
        isOpen: careemModal,
        onClose: () => {
          dispatch(LogisticAction.toggleCareemModal(false));
        },
      }}
    >
      <Tabs
        {...{ order, fetchOrderDetailsByID }}
        setOpen={() => {
          dispatch(LogisticAction.toggleCareemModal(false));
        }}
      />
    </HomeTab>
  );
};

export default Yandex;
