import React from "react";
import PropTypes from "prop-types";

const Card = (props) => {
  return (
    <div className="bg-white rounded shadow-sm border-gray-200 border">
      <div className="mt-5 md:mt-0 md:col-span-2">
        {/* <div className=""> */}
          <div className={props.styleClasses}>{props.children}</div>
        {/* </div> */}
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.object,
  styleClasses: PropTypes.string,
};
export default Card;
