import React from "react";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import PageHeading from "components/PageHeading";
import TextInputReadOnly from "components/TextInputReadOnly";
const ViewModifier = ({ row, setViewGroup }) => {
  const { t } = useTranslation();

  return (
    <div>
      <PageHeading heading={t("Modifier Detail")} />
      <div className="px-3 py-3 ">
        <div className="flex justify-end">
          <span
            className="cursor-pointer mb-3"
            onClick={() => setViewGroup(false)}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </span>
        </div>
        <Card>
          <h1 className="px-2 md:px-6 pt-4 text-19px text-blue-600">
            {t("Modifier Detail")}
          </h1>
          <h2 className="px-2 md:px-6 pt-1 text-gray-500">
            {t("Please enter complete details for better user experience")}
          </h2>
          <div className="flex flex-wrap justify-between py-4">
            <div className="w-full md:w-1/2 px-2 md:px-6 py-1">
              <div className="pb-3">
                <TextInputReadOnly
                  label={t("Name")}
                  value={row.baseLanguage.name}
                />
              </div>
              <div className="pb-3">
                <TextInputReadOnly
                  label={t("Price") + " (" + t("AED") + ")"}
                  value={row.price}
                />
              </div>

              <div className="pb-3">
                <TextInputReadOnly
                  label={t("Maximum Order Quantity")}
                  value={row.maximumOrderQuantity}
                />
              </div>
            </div>
            <div className="py-2 w-full md:w-1/2">
              {row?.imageURL ? (
                <img
                  src={row?.imageURL}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : null}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ViewModifier;
