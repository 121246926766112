import RadioButton from "components/RadioButton";
import SectionHeading from "components/SectionHeading";
import React from "react";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";

function OrderManagement({
  store,
  asap,
  hndlepreOrderingState,
  handleStoreInfo,
  getValue,
  averagePrepTime,
}) {
  const { t } = useTranslation();
  return (
    <div className="mb-4" key={asap.keyVal}>
      <SectionHeading title={t("Order Management")} />
      <div className="pb-2 mt-5">
        <TextInput
          placeHolder={t("Example 24 Hours")}
          label={t("Average Preparation Time")}
          value={getValue(averagePrepTime.keyVal)}
          keyVal={averagePrepTime.keyVal}
          handleValues={handleStoreInfo}
          mandatory={true}
          error={t(store?.errors?.averagePrepTime)}
        />
      </div>
      <RadioButton
        label={t("Accept ASAP orders")}
        value={asap.value}
        keyVal={asap.keyVal}
        handleValues={hndlepreOrderingState}
      />
    </div>
  );
}

export default OrderManagement;
