import { useEffect, useState, useContext } from "react";
import { UTILS } from "utils";
import { API } from "api";
import { CatelougeContext } from "store/contexts/Catelouge";
import { CONSTANTS as C } from "config";
const useCatalogue = () => {
  const [showStore, setShow] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { state } = useContext(CatelougeContext);
  const getCurrentUserStores = async (userDetail) => {
    setLoading(true);
    const result = await API.GET_CRRENT_USER_STORES({
      token: userDetail.token,
      language: "EN",
    });
    if (result?.data && result?.success === 1) {
      if (
        userDetail?.catalogueType === C.BRAND_BASED &&
        result?.data?.length >= 1
      ) {
        setShow(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const userDetail = UTILS.getCurrentUser();
    if (userDetail) {
      getCurrentUserStores(userDetail);
    }
  }, [state]);

  return {
    showStore,
    isLoading,
    setLoading,
  };
};
export default useCatalogue;
