import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { API } from "api";
import { useEffect } from "react";
const useAllStores = () => {
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCurrentUserStores = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_CRRENT_USER_STORES({
      token: userDetail.token,
    });
    if (result?.success === 1 && UTILS.isValidObject(["data"], result)) {
      if (result?.data?.length > 0) {
        convertStoresToSelectInputArray(result.data);
      } else {
        setLoading(false);
        setStores([]);
      }
    } else {
      setLoading(false);
      UTILS.errorNotification(t("Please try again later"));
    }
  };

  const convertStoresToSelectInputArray = (data) => {
    console.log("convertStoresToSelectInputArray", data);
    setStores(
      data.map((item, index) => ({
        value: item.baseLanguage ? item._id : item?.storeId._id,
        label: item.baseLanguage
          ? item.baseLanguage.name
          : item?.storeId?.baseLanguage?.name,
        key: index,
        isDineInEnabled: item.isDineInEnabled,
        storeLocation: item.storeLocation,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    getCurrentUserStores();
    return () => {
      setStores([]);
    };
  }, []);

  return { stores, loading, setLoading };
};

export default useAllStores;
