import React, { useState, useEffect } from "react";
import PageHeading from "../../../components/PageHeading";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { API } from "api";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PackageTabs from "../components/PackageTabs";
import { useSelector, useDispatch } from "react-redux";
import { getPackagePrice } from "utils/Utils";
import PackageInfoTwo from "../components/PackageInfoTwo";
import TabPane from "antd/lib/tabs/TabPane";
import { CONSTANTS as C } from "config";
import { PkgActions } from "redux/actions";
import { PackageUtil } from "utils";
import { TRACKING_EVENTS } from "utils";
const Packages = (props) => {
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const [subscribedPlan, setSubscribedPlan] = useState({});
  const { selectedKey } = useSelector((state) => state.PackagesReducer);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const verifyPackagePayment = async () => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.VERIFY_PACKAGE_PAYMENT({
      token: userDetail.token,
    });
    if (result?.success === 1 && result?.data?.subscribedPackge) {
      sessionStorage.setItem("paymentVerified", true);
      await getAllActivePackages();
      check_PackageValidity();
      const userDetail = UTILS.getCurrentUser();
      const totalPrice = result.data?.subscribedPackge?.amountPaid;
      TRACKING_EVENTS.subscribedUsers({
        plan_name: result.data?.subscribedPackge?.billingCycle,
        plan_price: totalPrice,
        currency: C.AED,
        transaction_id: userDetail?.userId,
      });
    }
  };

  const check_PackageValidity = async () => {
    const { text, notify, result } = await UTILS.checkPackageValidity(
      currentLanguage
    );
    dispatch(PkgActions.setNotifyVisible(notify));
    if (result?.data) {
      const diff = PackageUtil.getPackageExpiry(result.data.subscription);
      dispatch(PkgActions.setDays(diff));
      dispatch(PkgActions.setPackText(text));
    }
  };

  const getAllActivePackages = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_ALL_ACTIVE_PACKAGES({
      token: userDetail.token,
    });
    if (
      result.success === 1 &&
      UTILS.isValidObject(["data", "packages"], result)
    ) {
      const arr = result.data.packages;
      if (UTILS.isValidObject(["data", "subscribedPackage"], result)) {
        if (Array.isArray(arr)) {
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if (
              element._id === result.data.subscribedPackage.packageId &&
              result.data.subscribedPackage.isValid
            ) {
              if (result?.data?.subscribedPackage?.billingCycle) {
                dispatch(
                  PkgActions.setSelectedKey(
                    result.data.subscribedPackage?.billingCycle
                  )
                );
              }
              setSubscribedPlan(result.data.subscribedPackage);
              element["subscribed"] = true;
              arr[index] = element;
            } else {
              element["subscribed"] = false;
            }
          }
        }
      }

      setPackages(arr);
    }

    setLoading(false);
  };

  const handleSubscription = async (packageId, billingCycle, subscribed) => {
    if (subscribed) {
      return;
    }
    sessionStorage.removeItem("paymentVerified");
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_HOSTED_PAYMENT_PAGE({
      token: userDetail.token,
      billingCycle: billingCycle,
      packageId: packageId,
      successRedirectURL:
        window.location.href.split("?")[0] + "?status=success",
      failureRedirectURL:
        window.location.href.split("?")[0] + "?status=failure",
    });
    if (
      result.success === 1 &&
      UTILS.isValidObject(["data", "axiosRes", "gatewayData", "order"], result)
    ) {
      window.location.href = result.data.axiosRes.gatewayData.order.url;
    } else {
      UTILS.errorNotification(
        t("Please contact help at", {
          email: process.env.REACT_APP_SUPPORT_EMAIL,
        })
      );
    }
    setLoading(false);
  };

  const msgs = ["You have purchased successfully", "لقد اشتريت بنجاح"];
  const msg2 = [
    `
  Payment is delcined please contact ${process.env.REACT_APP_SUPPORT_EMAIL}`,
    `تم رفض الدفع يرجى التواصل مع ${process.env.REACT_APP_SUPPORT_EMAIL}`,
  ];

  useEffect(() => {
    async function verifyPackage() {
      const paymentVerified = sessionStorage.getItem("paymentVerified");
      if (params?.get("status") === "success" && !paymentVerified) {
        await verifyPackagePayment();
        UTILS.handleSuccesNotification(
          currentLanguage === "AR" ? msgs[1] : msgs[0]
        );
      } else if (params.get("status") === "failure") {
        UTILS.handleErrorNotification(
          currentLanguage === "AR" ? msg2[1] : msg2[0]
        );

        await getAllActivePackages();
      } else {
        await getAllActivePackages();
      }
    }
    verifyPackage();
  }, []);

  function getTabDiscount(planTYpe) {
    let selectedPackage = packages?.[0];
    let discountPercent = selectedPackage?.[planTYpe];
    let locale = currentLanguage === "AR" ? "ar-EG" : "en";
    let discountText = `${t("save")} ${discountPercent?.toLocaleString(
      locale
    )}%`;
    if (discountPercent > 0 && discountText) {
      return t(discountText);
    } else {
      return false;
    }
  }

  const callback = (key) => {
    dispatch(PkgActions.setSelectedKey(key));
  };

  return (
    <>
      <PageHeading heading={t("Subscription")} />
      <div className="p-0 lg:p-5 pb-24 w-full  h-full">
        <div className="text-center mb-8 font-semibold">
          <h1 className="text-40px font-bold">
            {t("Plans that best suit your business requirements")}
          </h1>
        </div>

        <Spin spinning={loading}>
          {packages && (
            <>
              <PackageTabs
                {...{ selectedKey, callback }}
                packages={packages}
                handleSubscription={handleSubscription}
                subscribedPlan={subscribedPlan}
              >
                {C.packageHeader?.map((pckg) => {
                  return (
                    <TabPane tab={t(pckg.header)} key={pckg.key}>
                      {getTabDiscount(pckg.subscriptionDiscount) && (
                        <div className="discount-label text-center">
                          {getTabDiscount(pckg.subscriptionDiscount)}
                        </div>
                      )}
                      <div className="flex mt-8 flex-wrap">
                        {packages?.map((item, index) => {
                          return (
                            <PackageInfoTwo
                              price={getPackagePrice(
                                item,
                                pckg.subscriptionDiscount
                              )}
                              subscribedPlan={subscribedPlan}
                              name={
                                currentLanguage === "AR"
                                  ? item.nameAR
                                  : item.nameEN
                              }
                              packageId={item._id}
                              text={C.getPackText(item.packageIdentifier)}
                              billingCycle={pckg.key}
                              key={index}
                              billedBy={pckg.billedBy}
                              packageIdentifier={item.packageIdentifier}
                              originalPrice={item.price}
                              subscribed={item?.subscribed}
                              subscribePackage={handleSubscription}
                            />
                          );
                        })}
                      </div>
                    </TabPane>
                  );
                })}
              </PackageTabs>
            </>
          )}
        </Spin>
      </div>
    </>
  );
};
Packages.propTypes = {
  packages: PropTypes.object,
  subscribeTrailPackage: PropTypes.func,
};
export default Packages;
