import React from "react";
import DashboardCard from "features/dashboard/components/DashboardCard";
import { useTranslation } from "react-i18next";
const Stats = ({ cardEarning, onlineEarning }) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-1 md:gap-6">
      <div className="col-span-2 md:col-span-1 text-center">
        <DashboardCard
          pClass="font-montserrat font-semibold text-40px text-center"
          heading={t("CASH Orders Earning")}
          count={cardEarning}
        />
      </div>
      <div className="col-span-2 md:col-span-1 text-center">
        <DashboardCard
          pClass="font-montserrat font-semibold text-40px text-center"
          heading={t("ONLINE Orders Earning")}
          count={onlineEarning}
        />
      </div>
    </div>
  );
};

export default Stats;
