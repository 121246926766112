import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router";
import { SVG_PLUS } from "svg/SVGs";
import { CONSTANTS as C } from "config";
import { useTranslation } from "react-i18next";
const AreaBox = (props) => {
  const router = useHistory();
  const { t } = useTranslation();
  return (
    <div
      className="flex flex-col sm:flex-row shadow rounded-md bg-gray-50 mb-6 px-5 py-8 items-center cursor-pointer"
      onClick={() => router.push(props.url)}
    >
      <div>{props.icon}</div>
      <div className="px-4">
        <h4 className="text-16px pt-4 sm:pt-0 pb-4 font-medium text-center sm:text-justify">
          {t(C.SHIPPING_METHODS[props.methodName])}
        </h4>
        <p className="pb-4 text-center sm:text-justify">{props?.description}</p>
      </div>
      <div className=" px-4">
        <Button
          type="primary"
          className="rounded-md w-48i items-center"
          size="large"
        >
          <SVG_PLUS color="#FFF" />
          <span className="ps-1">{props.footerText} </span>
        </Button>
      </div>
    </div>
  );
};

export default AreaBox;
