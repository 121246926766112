import { Spin, Table } from "antd";
import { API } from "api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const ModalSelectStore = (props) => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const router = useHistory();
  const getCurrentUserStores = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_CRRENT_USER_STORES({
      token: userDetail.token,
      language: "EN",
    });
    if (
      result &&
      result.success == 1 &&
      Array.isArray(result.data) &&
      result.data.length >= 1
    )
      setStores(result.data);
    else {
    }
    setLoading(false);
  };

  const renderActions = (value, rowData, index) => {
    return (
      <button
        onClick={() => {
          localStorage.setItem("storeId", rowData._id);
          router.push({
            pathname: "/app/dashboard/shipping",
            state: {
              sId: rowData._id,
              pushFrom: "ON_BOARDING",
            },
          });
        }}
        className="py-1 px-2 text-13px border border-blue-600 shadow-sm rounded-sm text-blue-600 bg-gray-50 focus:outline-none hover:bg-blue-600 hover:text-white"
      >
        {t("Add Area")}
      </button>
    );
  };

  useEffect(() => {
    getCurrentUserStores();
  }, []);

  const columns = [
    {
      title: t("Store"),
      dataIndex: "baseLanguage",
      render: (value, rowData, index) => {
        return rowData[C.BASE_LANGUAGE] ? rowData[C.BASE_LANGUAGE].name : "";
      },
    },
    {
      title: t("Description"),
      dataIndex: "description",
      render: (value, rowData, index) => {
        return rowData[C.BASE_LANGUAGE]
          ? rowData[C.BASE_LANGUAGE].description
          : "";
      },
    },
    {
      title: t("Select"),
      dataIndex: "_id",
      width: "30%",
      render: renderActions,
    },
  ];

  return (
    <div className="w-full px-4 py-4 store-table">
      <h1 className="py-2 text-16px font-bold text-blue-600">
        {t("Add areas where your store can deliver")}
      </h1>
      <Spin spinning={loading}>
        <Table
          dataSource={stores}
          columns={columns}
          pagination={false}
          locale={{ emptyText: t("No Data") }}
        />
      </Spin>
      <div className="p-3 mt-2 text-center space-x-4 md:block">
        <button
          onClick={props.onClose}
          className="mb-2 md:mb-0 me-2 bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100"
        >
          {t("Cancel")}
        </button>
      </div>
    </div>
  );
};
ModalSelectStore.propTypes = {
  onClose: PropTypes.func,
};
export default ModalSelectStore;
