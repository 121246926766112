import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import ClaimInfoTable from "../components/ClaimInfoTable";
const ClaimInfo = ({ fetchLatestClaimInfo, lyveGlobalOrderInfo }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-start">
        <Button type="default" size="large" onClick={fetchLatestClaimInfo}>
          {t("Refresh for latest info")}
        </Button>
      </div>
      <ClaimInfoTable {...{ lyveGlobalOrderInfo }} />
    </div>
  );
};

export default ClaimInfo;
