import { useMemo } from "react";
const useUserType = (searchTerm, list, language) => {
  const Items = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return list;
    }

    return list?.filter((item) =>
      language === "en"
        ? item?.label?.toLowerCase()?.includes(searchTerm?.trim())
        : item?.label?.toLowerCase()?.includes(searchTerm?.trim())
    );
  }, [list, searchTerm, language]);
  return Items;
};

export default useUserType;
