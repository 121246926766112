import React from "react";
import { Button, Spin, Select } from "antd";
import BackButton from "components/BackButton";
import GoogleMap from "../../components/GoogleMap";
import CustomMapAreaHeader from "features/shipping_methods/common/CustomMapAreaHeader";
import { useTranslation } from "react-i18next";
import useAllStores from "hooks/useCurrentUserStores";
import useGetCustomBuisnessAres from "../hooks/useGetCustomBuisnessAres";
import { getStoreInfo } from "utils/Utils";
import { useSelector } from "react-redux";

const { Option } = Select;

function DrawAreaContainer({
  selectedStore,
  onStoreChange,
  areaState,
  handleInputValue,
  handledeliveryFee,
  selectedCountry,
  selectedCity,
  handleCountryChange,
  handleCityChange,
  addAreafromMap,
  polygons,
  setPolygons,
  drawingEnabled,
  setDrawingEnabled,
  plottedPolygons,
  operationType,
  mapRef,
}) {
  const { t } = useTranslation();
  const { stores } = useAllStores();
  const countriesData = useGetCustomBuisnessAres();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const countryOptions = countriesData?.countries?.map((country) => (
    <Option key={country.name.en} value={country.name.en} data={country}>
      {currentLanguage === "AR" ? country.name.ar : country.name.en}
    </Option>
  ));

  const cityOptions = () => {
    if (selectedCountry) {
      return countriesData?.countries
        ?.find((country) => country?.name?.en === selectedCountry?.name?.en)
        ?.cities.map((city) => (
          <Option key={city.name.en} value={city.name.en} data={city}>
            {currentLanguage === "AR" ? city.name.ar : city.name.en}
          </Option>
        ));
    }
    return null;
  };

  return (
    <Spin spinning={isLoading}>
      <BackButton />

      <div className="flex items-center my-3 w-full sm:w-96 sm:my-0">
        <Select
          value={selectedStore}
          size="large"
          labelInValue={true}
          showSearch
          className="w-full rounded"
          placeholder={t("Select store")}
          optionFilterProp="children"
          onChange={onStoreChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={stores}
        />
      </div>

      {selectedStore && (
        <>
          <div className="w-full p-2 sm:p-5 mx-auto my-auto  bg-white">
            <div className="">
              <div className="p-1 justify-center">
                <CustomMapAreaHeader
                  heading="Add delivery area"
                  {...{
                    onClose: () => {},
                    state: areaState,
                    handleInputValue,
                    handledeliveryFee,
                  }}
                />

                <div className="flex items-center gap-3">
                  <div className="flex gap-2 flex-1">
                    <Select
                      style={{ width: "150px" }}
                      value={selectedCountry ? selectedCountry.name.en : null}
                      showSearch
                      size="large"
                      placeholder={"Select Country"}
                      optionFilterProp="children"
                      onChange={handleCountryChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {countryOptions}
                    </Select>
                    <Select
                      style={{ width: "150px" }}
                      value={selectedCity ? selectedCity.name.en : null}
                      showSearch
                      size="large"
                      placeholder={"Select City"}
                      optionFilterProp="children"
                      onChange={handleCityChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={!selectedCountry}
                    >
                      {cityOptions()}
                    </Select>
                  </div>
                  <div className="flex-1">
                    <Button
                      className=""
                      type="primary"
                      size="large"
                      onClick={addAreafromMap}
                    >
                      {operationType === "add" ? t("Add") : t("Update")}
                    </Button>
                  </div>
                </div>

                <div>
                  <GoogleMap
                    {...{
                      mapRef,
                      polygons,
                      setPolygons,
                      drawingEnabled,
                      setDrawingEnabled,
                    }}
                    selectedStore={getStoreInfo(selectedStore, stores)}
                    operationType={operationType}
                    plottedPolygons={plottedPolygons}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Spin>
  );
}

export default DrawAreaContainer;
