import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import useUserType from "hooks/useUserType";
const ProtectedRoute = ({
  component: Component,
  CURRENT_USER_TYPE,
  roleRequired,
  ...rest
}) => {
  const { t } = useTranslation();
  let loggedInUserToken = UTILS.getCurrentUser()?.token;
  const userType = useUserType();
  return (
    <Route
      key={rest.path}
      exact
      {...rest}
      render={(props) => {
        if (!loggedInUserToken) {
          return <Redirect to="/login" />;
        } else if (loggedInUserToken && roleRequired) {
          return userType === roleRequired ? (
            <Component {...props} />
          ) : (
            <h2 className="text-center font-semibold">
              {t("You cannot Access this page")}
            </h2>
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
