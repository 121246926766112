import React from "react";
import { useTranslation } from "react-i18next";

const ClaimInfo = ({ lyveGlobalOrderInfo }) => {
  const { t } = useTranslation();

  const renderRow = (label, value) => {
    return (
      <tr key={label}>
        <td className="border border-slate-300 p-4">{t(label)}</td>
        <td className="border border-slate-300 p-4">{value}</td>
      </tr>
    );
  };

  return (
    <div>
      <table className="border-collapse border border-slate-400 w-full mt-4">
        {lyveGlobalOrderInfo && (
          <>
            {lyveGlobalOrderInfo?.order_status &&
              renderRow("Order Status", lyveGlobalOrderInfo?.order_status)}
            {lyveGlobalOrderInfo?.driver &&
              renderRow("Rider Name", lyveGlobalOrderInfo?.driver)}
            {lyveGlobalOrderInfo?.driver_phone &&
              renderRow(
                "Rider Phone Number",
                lyveGlobalOrderInfo?.driver_phone
              )}
            {lyveGlobalOrderInfo?.driver_id &&
              renderRow("Rider Id", lyveGlobalOrderInfo?.driver_id)}
          </>
        )}
      </table>
    </div>
  );
};

export default ClaimInfo;
