import React from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { useSelector } from "react-redux";

import PageHeading from "components/PageHeading";
import MenuListing from "../components/MenuListing";

import useAllStores from "hooks/useCurrentUserStores";
import CreateNewMenuModal from "../components/modals/CreateNewMenuModal";
import useLinkUnlinkStoreWithMenu from "../hooks/useLinkUnlinkStoreWithMenu";
import useGetMenus from "../hooks/useGetMenus";
import LinkStoresWithMenu from "../components/modals/LinkStoresWithMenu";
import { useMenuContext } from "store/contexts/Menus";
import useGetMenuInfo from "../hooks/useGetMenuInfo";
import CloneMenu from "../components/modals/CloneMenu";
import EditMenu from "../components/modals/EditMenu";

const Menus = () => {
  const { t } = useTranslation();
  const { menuState, updateState } = useMenuContext();
  const { stores, setLoading, loading } = useAllStores();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const [cloneMenuId, setCloneMenuId] = React.useState(null);

  const getAllMenus = useGetMenus({ setLoading, updateState });
  const { linkMenu } = useLinkUnlinkStoreWithMenu({
    setLoading,
    updateState,
    selectedStores: menuState?.linkedStores,
    getAllMenus: getAllMenus,
    menuId: menuState?.menuInfo?._id,
  });
  const getMenuById = useGetMenuInfo();
  React.useEffect(() => {
    getAllMenus();
  }, []);

  return (
    <Spin spinning={loading || isLoading}>
      <div className="w-full">
        <PageHeading heading={t("Menus")} />
        <LinkStoresWithMenu
          {...{
            updateState,
            stores,
            loading,
            linkMenu,
          }}
          menuState={menuState}
        />
        <CreateNewMenuModal
          {...{
            updateState,
            stores,
            loading,
            getAllMenus,
            setLoading,
            menuState,
          }}
        />

        <div className="w-full px-4 py-4">
          <MenuListing
            {...{
              menus: menuState.addedMenus,
              getMenuById,
            }}
            CreateNewMenu={() => {
              updateState("isCreateNewMenuModalOpen", true);
            }}
            setOpen={(val, menuId) => {
              getMenuById(menuId);
              updateState("isLinkMenuWithStoresModalOpen", true);
            }}
            setCloneMenuId={setCloneMenuId}
          />
        </div>
      </div>
      <CloneMenu {...{ menuState, updateState, cloneMenuId }} />
      <EditMenu {...{ menuState, updateState }} />
    </Spin>
  );
};

export default Menus;
