import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
const orderService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderInfoByOrderId: builder.mutation({
      query: (body) => ({
        url: URL.GET_ORDER_INFO_BY_ID,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});
export const { useGetOrderInfoByOrderIdMutation } = orderService;
export default orderService;
