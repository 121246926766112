import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import TextInput from "components/TextInput";
import ChooseTime from "./ChooseTime";
import PriceInput from "components/useForm/PriceInput";

function UpdateAreaModal({
  isLoading,
  selectedAreas,
  onClose,
  editAreas,
  state,
  handleInputValue,
  handleValues,
  isValid,
  time,
  setTime,
}) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-5 mx-auto my-auto rounded-xl shadow-lg  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            <div className="flex justify-between">
              <div>
                <h2 className="text-19px font-bold mb-3 py-1 px-2">
                  {t("Edit")} {`${selectedAreas?.length ?? ""} `}
                  {t("Areas")}
                </h2>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="px-2 w-full sm:w-1/2">
                <TextInput
                  label={t("Min. Basket Value")}
                  value={state.minOrder.trim()}
                  keyVal={"minOrder"}
                  handleValues={handleInputValue}
                  mandatory={true}
                />
              </div>
              <div className="px-2 w-full sm:w-1/2">
                <PriceInput
                  label={t("Delivery Fee")}
                  value={state.deliveryFee.trim()}
                  keyVal={"deliveryFee"}
                  handleValues={handleValues}
                  mandatory={true}
                  //error={category.errors.baseLanguage.name}
                />
              </div>
            </div>
            <div className="flex flex-wrap px-2">
              <ChooseTime {...{ time, setTime }} />
            </div>
          </div>
          <div className="p-3 mt-2 flex justify-end space-x-4 md:block">
            <Button type="default" size="large" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="primary" size="large" onClick={editAreas}>
              {t("Update Areas")}
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default UpdateAreaModal;
