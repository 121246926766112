import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { handleAddIsAddedPropToBuisnessAreas } from "features/shipping_methods/helpers";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { AreasAction } from "redux/actions";
import { UTILS } from "utils";

function useGetStoreAddedMappedAreas() {
  const dispatch = useDispatch();
  const { areaData } = useSelector((state) => state.AreasReducer);

  const getStoreMappedAddedAreas = async ({ storeId, isPolygonRequired }) => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      const response = await API.getStoreMappedAreas({
        storeId,
        brandId: userDetail.brandId,
        isPolygonRequired: isPolygonRequired ? isPolygonRequired : false,
      });
      if (response?.success === 1 && response?.data) {
        const filteredAreasList = await handleAddIsAddedPropToBuisnessAreas(
          areaData,
          response?.data
        );
        dispatch(AreasAction.setMapCustomBuisnessAreas(filteredAreasList));
        dispatch(AreasAction.setMapAddedAreas(response?.data));
      } else {
        console.log("error", response);
        UTILS.errorNotification(errorHandler(response?.message));
      }
    } catch (error) {
      console.log("error", error);
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return getStoreMappedAddedAreas;
}

export default useGetStoreAddedMappedAreas;
