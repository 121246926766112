import React, { useEffect, useState } from "react";
import PageHeading from "components/PageHeading";
import { API } from "api";
import { UTILS } from "utils";
import { Button } from "antd";
import DashboardBranchesTable from "features/dashboard/components/DashboardBranchesTable";
import DashboardCard from "features/dashboard/components/DashboardCard";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import useUserType from "hooks/useUserType";
import useCatalogue from "hooks/useCatalogue";
const Dashboard = () => {
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const router = useHistory();
  const [stats, setStats] = useState({});
  const catelougeType = useCatalogue();
  const { t } = useTranslation();
  const userType = useUserType();
  const getStats = async () => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_STATS({
      token: userDetail.token,
      language: currentLanguage,
    });
    if (result && result.success === 1 && UTILS.isValidObject(["data"], result))
      setStats(result.data);
    else {
    }
  };

  const addStore = () => {
    router.push("/app/dashboard/addstore");
  };

  useEffect(() => {
    getStats();
  }, []);

  const getCountFromOrders = (status) => {
    if (stats?.orders && Array.isArray(stats.orders)) {
      for (let index = 0; index < stats.orders.length; index++) {
        const element = stats.orders[index];
        if (element.orderStatus === status) {
          return element.count;
        }
      }
      return "0";
    } else {
      return "0";
    }
  };
  return (
    <>
      <PageHeading heading={t("Dashboard")} />
      <div className="m-4 sm:m-5">
        <div className="grid grid-cols-4 gap-1 md:gap-6">
          <div className="col-span-2 md:col-span-1 ">
            <DashboardCard
              pClass="font-montserrat font-semibold text-40px text-center"
              heading={t("Total Customers")}
              count={stats?.customersCount ? stats?.customersCount : "0"}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DashboardCard
              pClass="font-montserrat font-semibold text-40px text-center"
              heading={t("Completed Orders")}
              count={getCountFromOrders("COMPLETE")}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DashboardCard
              pClass="font-montserrat font-semibold text-40px text-center"
              heading={t("Rejected Orders")}
              count={getCountFromOrders("REJECTED")}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DashboardCard
              pClass="font-montserrat font-semibold text-40px text-center"
              heading={t("Canceled Orders")}
              count={getCountFromOrders("CANCELLED")}
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="py-5">
          <div className="flex justify-between px-10">
            <div>
              <h2 className="text-16px font-montserrat">{t("Stores")}</h2>
            </div>
            <div>
              {catelougeType?.showStore &&
              !catelougeType?.isLoading &&
              userType === "BRAND-ADMIN" ? (
                <Button
                  type="primary"
                  size="medium"
                  className="w-36"
                  onClick={() => addStore()}
                >
                  {t("Add Store")}
                </Button>
              ) : null}
            </div>
          </div>
          <DashboardBranchesTable />
        </div>
      </div>
    </>
  );
};
export default Dashboard;
