import { useState, useEffect } from "react";
import { UTILS } from "utils";
import { API } from "api";
const useAllStores = () => {
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);

  const getCurrentUserStores = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_CRRENT_USER_STORES({
      token: userDetail.token,
      language: "EN",
    });
    if (result.success === 1) {
      if (result.data.length > 0) convertStoresToArray(result.data);
    } else {
    }
    setLoading(false);
  };

  const convertStoresToArray = (data) => {
    let s = [];
    if (data) {
      data.map((item, index) => {
        return s.push({
          value: item.baseLanguage ? item._id : item?.storeId._id,
          label: item.baseLanguage
            ? item.baseLanguage.name
            : item?.storeId?.baseLanguage?.name,
          key: index,
        });
      });
    }
    setStores(s);
    setLoading(false);
  };

  useEffect(() => {
    getCurrentUserStores();
  }, []);

  return { stores, loading };
};

export default useAllStores;
