import React from "react";
import PropTypes from "prop-types";
import { UTILS } from "utils";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};
const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600"> {error} </span>;
  }
};
const PriceInput = (props) => {
  const mandatoryField = getMandatoryField(props.mandatory);
  const fieldError = getFieldError(props.error);

  const handleChangePrice = (event) => {
    let v = UTILS.ARtoENPrice(event.target.value);
    props.handleValues(props.keyVal, v);
  };

  return (
    <>
      <label className="text-gray-600 block text-14px">
        {props.label}
        {mandatoryField}
        {fieldError}
      </label>
      <input
        onChange={(event) => handleChangePrice(event)}
        placeholder={props.placeHolder ? props.placeHolder : ""}
        value={props.value}
        type={props?.type ? props.type : "text"}
        disabled={props.disabled ? true : false}
        className="font-montserrat text-16px text-input-color text-sm border border-grey-600 focus:ring-indigo-500 outline-none focus:border-indigo-500 block w-full border-gray-300 p-2"
      />
    </>
  );
};

PriceInput.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
};

export default PriceInput;
