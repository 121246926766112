import React from "react";
import { useTranslation } from "react-i18next";
const Checkout = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full lg:w-1/2 border border-gray-300 rounded mb-2 px-2 py-2 mx-1">
      <div className="flex justify-betwen">
        <div className="flex items-center w-full sm:w-1/2">
          <img
            alt="checkout"
            src="/images/checkout.png"
            style={{ height: "50px", width: "50px" }}
            width={50}
            height={50}
          />
          <h2 className="py-3 text-17px px-2">
            {t("Checkout Online Payment")}
          </h2>
        </div>
        <div className="w-1/2 text-right px-2 py-1 ihidden smsblock">
          <button
            className="bg-blue-200 text-blue-600 font-semibold px-2 md:px-4 py-2 rounded"
            onClick={() => {
              window.open(
                `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Checkout%20Integration%20Required&body=Please%20enter%20your%20brand%20details`
              );
            }}
          >
            {t("Contact Support")}
          </button>
        </div>
      </div>
      <h3>
        {t("Integrating checkout")}{" "}
        <span className="text-red-600">
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </span>
      </h3>
      <div className="w-1/2 py-1 pt-3 smblock smhidden">
        <button
          className="bg-blue-200 text-blue-600 font-semibold px-2 md:px-4 py-2 rounded"
          onClick={() => {
            window.open(
              `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Checkout%20Integration%20Required&body=Please%20enter%20your%20brand%20details`
            );
          }}
        >
          {t("Contact Support")}
        </button>
      </div>
    </div>
  );
};
export default Checkout;
