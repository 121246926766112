import React, { useEffect, useRef, useState } from "react";
import { useAccordion } from "features/menu/components/AccordionN";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  AngleUpIcon,
  AngleDownIcon,
  SVG_THREE_DOT_VERTICAL,
  SVG_DRAGDROP,
} from "svg/SVGs";

const SingleCategory = ({
  toggle,
  cat,
  editCat,
  keyVal,
  unLinkMenuCat,
  getCategoryInfo,
}) => {
  const { t } = useTranslation();
  const { selected, toggleItem } = useAccordion();
  const [hidden, setHidden] = useState(true);

  const classToggle = (event) => {
    setHidden(!hidden);
    event.stopPropagation();
  };

  const myRef = useRef();
  const outside = (e) => {
    if (!myRef?.current?.contains(e.target)) {
      setHidden(true);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", outside);
    return () => document.removeEventListener("mousedown", outside);
  });

  return (
    <div
      className="flex  focus:outline-none border-b p-2 md:p-4 text-16px hover:bg-gray-100 cursor-pointer"
      onClick={() => {
        toggleItem(toggle);
        if (selected !== toggle) getCategoryInfo(cat._id);
      }}
    >
      <span className="float-left pe-4 mt-1 cursor-pointer z-10">
        {selected === toggle ? <AngleUpIcon /> : <AngleDownIcon />}
      </span>
      <div className="w-5 h-5 me-4 mt-1.5 cursor-move">
        <SVG_DRAGDROP />
      </div>
      <h3 className="w-full text-14px">{cat?.baseLanguage?.name}</h3>
      <div className="flex text-end cursor-pointer" ref={myRef}>
        <span className="w-32 text-12px text-indigo-600 font-bold px-5">
          {t("link items")}
        </span>
        <div className="group relative w-4 h-6 float-end">
          <div className="w-3 h-6" onClick={classToggle}>
            <SVG_THREE_DOT_VERTICAL />
          </div>
          <ul
            className={` absolute left3rem text-gray-800 pt-1  w-20 top-0 border-gray-200 border bg-white z-10
             ${hidden ? "hidden" : "block"}
            `}
          >
            <li
              onClick={(event) => {
                editCat(keyVal);
                event.stopPropagation();
              }}
              className="z-10 w-full flex py-1 px-3 text-14px text-black hover:text-indigo-600 border-b border-gray-200"
            >
              {t("Edit")}
            </li>
            <li
              onClick={(event) => {
                unLinkMenuCat(keyVal);
                event.stopPropagation();
              }}
              className="z-10 flex py-1 px-3 text-14px text-theme-red hover:text-indigo-600 font-semibold"
            >
              {t("Delete")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SingleCategory;

SingleCategory.propTypes = {
  toggle: PropTypes.string,
  cat: PropTypes.object,
  editCat: PropTypes.func,
  deleteCat: PropTypes.func,
  keyVal: PropTypes.number,
  itemCount: PropTypes.number,
};
