import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import PageHeading from "components/PageHeading";
import CitiesTable from "../components/CitiesTable";
import { apiSlice } from "redux/Api.Slice";
import { AreasAction } from "redux/actions";
import { useGetAddedCitiesMutation } from "redux/shipping-methods/shipping-service";
import { Spin } from "antd";
import { LoaderAction } from "redux/actions";
const ManageCities = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [getAddedCities] = useGetAddedCitiesMutation();
  const { selectedStore, syncData } = useSelector(
    (state) => state.AreasReducer
  );
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const [trigger] = apiSlice.endpoints.getBuisnessAreas.useLazyQuery();

  const getBussinessAreas = UTILS.withTryCatch(async () => {
    dispatch(LoaderAction.setLoading(true));
    const { data } = await trigger();
    if (data?.success === 1 && data?.data) {
      await getCities(data.data);
      dispatch(AreasAction.setFetchedAreas(data.data));
    } else {
      console.error("cities error", data);
    }
    dispatch(LoaderAction.setLoading(false));
  });

  const getCities = UTILS.withTryCatch(async (shippingData) => {
    dispatch(LoaderAction.setLoading(true));
    const userDetail = UTILS.getCurrentUser();
    if (selectedStore?.value && userDetail?.brandId) {
      const obj = {
        brandId: userDetail.brandId,
        storeId: selectedStore.value,
        language: "EN",
      };
      const data = await getAddedCities(obj).unwrap();

      if (data?.success === 1) {
        dispatch(AreasAction.setAddedCities(data.data.areas));
        const listCities = await UTILS.getCustomizedCitiesList(
          shippingData,
          data.data.areas
        );

        if (listCities) {
          dispatch(AreasAction.setcustomizedCityList(listCities));
        }
      }
    }
    dispatch(LoaderAction.setLoading(false));
  });

  useEffect(() => {
    getBussinessAreas();
  }, [selectedStore?.value, syncData]);

  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeading heading={t("Delivery Cities")} />
        <div className="pb-3 px-2">
          <CitiesTable />
        </div>
      </Spin>
    </div>
  );
};
export default ManageCities;
