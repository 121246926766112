import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Card from "components/Card";
import { useSelector } from "react-redux";
const UserLoyaltyTableData = ({
  userWalletHistory,
  fetchUserWalletTransactionHistory,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  const renderName = (value, row, index) => {
    return (
      <div>
        <p className="m-0">{t(value)}</p>
      </div>
    );
  };

  const columns = [
    {
      title: t("Type"),
      dataIndex: "type",
      key: "name",
      render: renderName,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      // render: renderName,
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      title: t("Balance before"),
      dataIndex: "walletBalanceBefore",
      key: "walletBalanceBefore",
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      title: t("Balance after"),
      dataIndex: "walletBalanceAfter",
      key: "walletBalanceAfter",
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      title: t("Created at"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (val, row, index) => {
        return <span>{moment(val).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  return (
    <div className="w-full">
      <div className="mb-4">
        <h3 className="text-gray-900  text-17px pt-4 font-semibold">
          {t("Loyalty transaction history")}
        </h3>
      </div>
      <Card styleClasses="px-2 py-2">
        <div className="overflow-hidden overflow-x-auto lg:overflow-hidden">
          <Table
            locale={{ emptyText: t("No Data") }}
            columns={columns}
            // onChange={(e) => fetchUserWalletTransactionHistory(e.current * 10)}
            dataSource={userWalletHistory}
            pagination={{
              position: ["bottomCenter"],
              defaultPageSize: 10,
            }}
            size="small"
            rowKey={(record) => record._id}
          />
        </div>
      </Card>
    </div>
  );
};

export default UserLoyaltyTableData;
