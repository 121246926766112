import React from "react";
import PropTypes from "prop-types";

const VerificationInput = (props) => {
  return (
    <>
      <input
        id={props.id}
        min={0}
        onKeyUp={props.onKeyUp}
        maxLength={1}
        type="text"
        autoComplete="off"
        value={props.value}
        name={props.name}
        onChange={props.handleChange}
        className="bg-gray-200 border border-gray-300 m-2 outline-none w-16 font-semibold text-20px text-black h-14 text-center inline-block rounded p-2"
      />
    </>
  );
};
VerificationInput.propTypes = {
  length: PropTypes.number,
  character: PropTypes.string,
  onchangeValue: PropTypes.func,
};
export default VerificationInput;
