import { API } from "api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AreasAction } from "redux/actions";
import { LoaderAction } from "redux/actions";

function useGetAllBusinessAreas(getDeliveryAreas) {
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const dispatch = useDispatch();

  const getAllBuisnessAreas = async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const response = await API.getMappedCountriesCitiesAreas();
      if (response?.success === 1 && response?.data) {
        getDeliveryAreas(response.data?.countries);
        dispatch(AreasAction.setFetchedAreas(response.data?.countries));
      }
    } catch (error) {
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  useEffect(() => {
    getAllBuisnessAreas();
  }, [selectedStore?.value]);
}

export default useGetAllBusinessAreas;
