import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

moment.locale("ar");
const Order = (props) => {
  const onClickOrder = (id) => {
    props.handoleOrderId(id);
  };

  return (
    <div
      className="flex w-full border-b bg-gray-800 border-gray-700 text-gray-300 hover:text-white cursor-pointer font-light text-14px"
      onClick={() => onClickOrder(props?.order?._id)}
      style={{
        backgroundColor:
          props?.currentOrderId === props?.order?._id
            ? "#f5f5f5"
            : "transparent",
        color: props?.currentOrderId === props?.order?._id ? "#000" : "#f5f5f5",
      }}
    >
      <div className="w-full py-2 px-4">
        <div className="">{"#: " + props?.order?.orderNumber}</div>
        <div className="font-semibold">
          <span className="uppercase text-blue-400">
            {props?.order?.orderingPlatform + ", "}
          </span>
          {moment.unix(props?.order?.orderPlacedTime).fromNow()}
        </div>
      </div>
    </div>
  );
};
export default Order;
Order.propTypes = {
  color: PropTypes.string,
  status: PropTypes.string,
  order: PropTypes.object,
  handoleOrderId: PropTypes.func,
};
