import { Button, Collapse, Tag } from "antd";
import React, { useRef } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import Instagram from "features/integrations/components/instagram";
import Facebook from "features/integrations/components/facebook";
import Whatsapp from "features/integrations/components/whatsapp";
import Messenger from "features/integrations/components/messenger";
import useUserType from "hooks/useUserType";

const Step = (props) => {
  const userType = useUserType() || UTILS.getCurrentUser()?.userType;
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const router = useHistory();
  let ref = useRef();
  const getExtra = (step) => {
    return (
      <div>{step?.isCompleted && <Tag color="#87d068">{t("Done")}</Tag>}</div>
    );
  };
  const getDefaultActiveKey = () => {
    try {
      window.scrollTo({ behavior: "smooth", top: ref?.current?.offsetTop });
    } catch (error) {}
    return props.opened ? ["1"] : ["0"];
  };

  const getKeyAgainstLanguage = () => {
    const lang = getI18n();
    return lang.language == "en" ? "en" : "ar";
  };

  const playVideo = (url) => {
    const id = getVideoId(url);
    props.playVideo(id);
  };

  const previewStore = () => {
    const userDetail = UTILS.getCurrentUser();
    const url =
      userDetail?.storeFrontURL +
      userDetail?.temporaryDomainName +
      "?platform=Web";
    if (url) {
      window.open(url, "_blank").focus();
    }
  };

  const getActionButtonAgainstStep = () => {
    switch (props?.step?.taskType) {
      case "GET_TO_KNOW":
        return (
          <Button
            type="primary"
            size={"large"}
            onClick={() => {
              props.addTaskToDone(props?.step?._id);
              if (
                props?.step?.translations[getKeyAgainstLanguage()]?.video_url !=
                ""
              )
                playVideo(
                  props?.step?.translations[getKeyAgainstLanguage()]?.video_url
                );
            }}
          >
            {t("Continue")}
          </Button>
        );
      case "CREATE_STORE":
        return props?.catelougeType?.showStore ? (
          <Button
            disabled={userType === "MANAGER"}
            type="primary"
            size={"large"}
            onClick={() => router.push("/app/dashboard/addstore")}
          >
            {t("Create Store")}
          </Button>
        ) : null;
      case "ADD_DELIVERY_AREA":
        return (
          <Button
            disabled={userType === "MANAGER"}
            type="primary"
            size={"large"}
            onClick={() => props.openModal()}
          >
            {t("Add Area")}
          </Button>
        );
      case "CUSTOMIZE_BRANDING":
        return (
          <Button
            type="primary"
            size={"large"}
            onClick={() => {
              props.addTaskToDone(props?.step?._id);
              router.push("/app/dashboard/settings/branding");
            }}
          >
            {t("Customize Branding")}
          </Button>
        );
      case "CREATE_ITEM":
        return (
          <Button
            type="primary"
            size={"large"}
            onClick={() => router.push("/app/dashboard/items")}
          >
            {t("Create Item")}
          </Button>
        );
      case "CREATE_CATEGORY":
        return (
          <Button
            type="primary"
            size={"large"}
            onClick={() =>
              router.push({
                pathname: "/app/dashboard/menu-list",
                state: {
                  pushFrom: "ON_BOARDING",
                },
              })
            }
          >
            {t("Add Category")}
          </Button>
        );
      case "PUBLISH_STORE":
        return (
          <Button
            type="primary"
            size={"large"}
            onClick={() =>
              router.push({
                pathname: "/app/dashboard/stores",
                state: {
                  pushFrom: "ON_BOARDING",
                },
              })
            }
          >
            {t("Publish Store")}
          </Button>
        );
      case "PREVIEW_STORE":
        return (
          <Button
            type="primary"
            size={"large"}
            onClick={() => {
              props.addTaskToDone(props?.step?._id);
              previewStore();
            }}
          >
            {t("Preview Store")}
          </Button>
        );
      default:
        break;
    }
  };

  const getVideoId = (url) => {
    var video_id = "",
      result;
    if (url) {
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)))
        video_id = result.pop();
      else if ((result = url.match(/youtu.be\/(.{11})/)))
        video_id = result.pop();
    }
    return video_id;
  };

  const getYoutubeThumbNail = (url, quality) => {
    if (url) {
      var video_id, thumbnail, result;
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)))
        video_id = result.pop();
      else if ((result = url.match(/youtu.be\/(.{11})/)))
        video_id = result.pop();
      if (video_id) {
        if (typeof quality == "undefined") quality = "high";
        var quality_key = "maxresdefault"; // Max quality
        if (quality === "low") quality_key = "sddefault";
        else if (quality === "medium") quality_key = "mqdefault";
        else if (quality === "high") quality_key = "hqdefault";
        thumbnail =
          "https:///img.youtube.com/vi/" +
          video_id +
          "/" +
          quality_key +
          ".jpg";
        return thumbnail;
      }
    }
    return false;
  };

  const getVideoContent = () => {
    return (
      <div className="w-full">
        <img
          src={getYoutubeThumbNail(
            props?.step?.translations[getKeyAgainstLanguage()]?.video_url,
            "low"
          )}
        />
        <button
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          onClick={() => {
            if (props?.step?.taskType == "GET_TO_KNOW")
              props.addTaskToDone(props?.step?._id);
            playVideo(
              props?.step?.translations[getKeyAgainstLanguage()]?.video_url
            );
          }}
        >
          <img src="/images/youtube.png" className="w-16 h-16" />
        </button>
      </div>
    );
  };
  const DetailView = () => {
    return (
      <div>
        <div
          className="flex justify-between flex-wrap  py-2 px-1 md:px-4"
          key={props.index}
        >
          <div className="w-full lg:w-1/2 pb-4 lg:pb-0">
            <h3 className="font-bold py-1">
              {props?.step?.translations[getKeyAgainstLanguage()]?.caption}
            </h3>
            <p className="text-13px py-4">
              {props?.step?.translations[getKeyAgainstLanguage()]?.description}
            </p>
            {getActionButtonAgainstStep()}
          </div>
          <div className="w-full lg:w-1/2 px-2 h-full relative">
            {props?.step?.translations[getKeyAgainstLanguage()]?.video_url != ""
              ? getVideoContent()
              : ""}
          </div>
        </div>
        <div
          className={props?.index == props?.length - 1 ? "visible" : "hidden"}
        >
          <div className="flex justify-betwen flex-wrap md:flex-nowrap w-full">
            <Instagram />
            <Facebook />
          </div>
          <div className="flex justify-betwen flex-wrap md:flex-nowrap w-full my-2">
            <Whatsapp />
            <Messenger />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div ref={ref}>
      <Collapse
        defaultActiveKey={getDefaultActiveKey()}
        style={{ fontSize: 16, color: "blue" }}
        collapsible={props.enabled}
      >
        <Panel
          header={
            props?.index +
            1 +
            " - " +
            props?.step?.translations[getKeyAgainstLanguage()]?.title
          }
          key="1"
          extra={getExtra(props.step)}
          style={{ fontSize: 14, color: "blue" }}
        >
          <DetailView />
        </Panel>
      </Collapse>
      <div style={{ height: 0.2, marginTop: 8, marginBottom: 8 }} />
    </div>
  );
};
Step.propTypes = {
  step: PropTypes.object,
  enabled: PropTypes.string,
  index: PropTypes.number,
  opened: PropTypes.bool,
  openModal: PropTypes.func,
  playVideo: PropTypes.func,
  addTaskToDone: PropTypes.func,
  length: PropTypes.number,
};
export default Step;
