import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import PriceInput from "components/PriceInput";
import ChooseTime from "./ChooseTime";

function CustomMapAreaHeader({
  heading,
  state,
  handleInputValue,
  handledeliveryFee,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between">
        <div>
          <h2 className="text-19px font-bold mb-3 py-1 px-2">{t(heading)}</h2>
        </div>
        {/* <div className="cursor-pointer" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="w-6 h-6 bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div> */}
      </div>
      <div className="flex flex-wrap">
        <div className="px-2  w-full sm:w-1/3 register-page">
          <TextInput
            label={t("Enter Area Name")}
            value={state.areaName}
            keyVal="areaName"
            handleValues={handleInputValue}
            mandatory={false}
          />
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="px-2  w-full sm:w-1/3 register-page">
          <PriceInput
            label={t("Min. Basket Value")}
            value={state.minOrder}
            keyVal={"minOrder"}
            handleValues={handleInputValue}
            mandatory={true}
          />
        </div>
        <div className="px-2 w-full sm:w-1/3 register-page">
          <PriceInput
            label={t("Delivery Fee")}
            value={state.deliveryFee}
            keyVal={"deliveryFee"}
            handleValues={handledeliveryFee}
            mandatory={true}
          />
        </div>
        <div className="px-2  w-full sm:w-1/3 register-page">
          <TextInput
            label={t("Description")}
            value={state.description}
            keyVal="description"
            handleValues={handleInputValue}
            mandatory={false}
          />
        </div>
      </div>
      <div className="flex flex-wrap px-2">
        <ChooseTime
          {...{
            time: state.deliveryTime,
            setTime: (val) => handleInputValue("deliveryTime", val),
          }}
        />
      </div>
    </>
  );
}

export default CustomMapAreaHeader;
