import { API } from "api";
import { LogisticAction } from "redux/actions";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useOrderMetaDataContext } from "store/contexts/OrderMetaDataContext";
import useRefundableOrder from "hooks/useRefundableOrders";

function useGetORderByStatus(
  setLoading,
  tabStatus,
  setOrders,
  setCurrentOrderId
) {
  const { revalidate } = useOrderMetaDataContext();
  const { revalidate: refundableMutate } = useRefundableOrder();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getOrdersByStatus = async (type) => {
    try {
      setLoading(true);

      const userDetail = UTILS.getCurrentUser();
      const result = await API.GET_ORDER_BY_STATUS({
        orderStatus: tabStatus,
        token: userDetail.token,
      });
      if (result?.success === 1 && result?.data?.orders) {
        setOrders(result.data.orders);
        if (result?.data?.orders?.length > 0) {
          setCurrentOrderId(result.data.orders[0]._id);
          dispatch(LogisticAction.setOrderInfo(result.data.orders[0]));
        } else {
          dispatch(LogisticAction.setOrderInfo({}));
        }
      } else if (result.status === 1) {
        UTILS.successNotification(t("There are no orders"));
      } else {
        UTILS.errorNotification(t("Please try again later!"));
      }
    } catch (error) {
    } finally {
      setLoading(false);
      if (tabStatus === "SCHEDULED") {
        revalidate("order/get-order-meta-data-info", { revalidate: true });
      }
      if (tabStatus === "REFUND") {
        refundableMutate("order/get-refundable-orders-count", {
          revalidate: true,
        });
      }
    }
  };
  return getOrdersByStatus;
}

export default useGetORderByStatus;
