import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import ClaimInfoTable from "./ClaimInfoTable";
const ClaimInfo = (props) => {
  let { careemOrder } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-start">
        <Button
          type="default"
          size="large"
          onClick={props.getCareemORderStatus}
        >
          {t("Refresh for latest info")}
        </Button>
      </div>
      <ClaimInfoTable {...{ careemOrder }} />
    </div>
  );
};

export default ClaimInfo;
