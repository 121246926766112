import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

import SectionHeading from "components/SectionHeading";
import RadioButton from "components/RadioButton";
import TextInput from "components/TextInput";
import {
  getHMinutSlotsTill8Hors,
  getThirdsDaysArrayWithDayLebel,
  getNumOfDaysWithZero,
} from "./constants";
import { UTILS } from "utils";
function PreOrderingWidget({
  isPreOrderEnabled,
  sameDayPreOrder,
  preOrderTimig,
  hndlepreOrderingState,
  handlePreOrderingTiming,
  type,
}) {
  const { t } = useTranslation();
  const [error, setError] = React.useState("");

  const handleInputValue = (key, val) => {
    const value = UTILS.NumberConversion(val);
    let time = Number(value);
    if (time >= 17 && preOrderTimig?.minTimeInAdvanceUnit === "hours") {
      setError(t("Minimum Lead Time should be less than 17 hours"));
      return;
    }
    if (time >= 960 && preOrderTimig?.minTimeInAdvanceUnit === "minutes") {
      setError(t("Minimum Lead Time should be less than 960 minutes"));
      return;
    }
    setError("");
    handlePreOrderingTiming(key, time);
  };

  return (
    <div className="my-4" key={type}>
      <SectionHeading title={t("Pre-Ordering Management")} />
      <div className="mt-5">
        <RadioButton
          label={t("Accept pre-orders")}
          value={isPreOrderEnabled.value}
          keyVal={isPreOrderEnabled.keyVal}
          handleValues={hndlepreOrderingState}
        />
      </div>
      {isPreOrderEnabled?.value ? (
        <>
          <RadioButton
            customKey={`acceptTodayPreOrders ${type}`}
            label={t("Accept same day pre-orders")}
            value={sameDayPreOrder.value}
            keyVal={sameDayPreOrder.keyVal}
            handleValues={handlePreOrderingTiming}
          />

          <div className="mt-10">
            <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
              <div className="w-full md:w-1/2 ">
                <label className="text-gray-600 block text-14px">
                  {t("Minimum Lead Time Unit")}
                </label>
                <Select
                  value={preOrderTimig?.minTimeInAdvanceUnit}
                  style={{ width: "100%" }}
                  size="large"
                  showSearch
                  placeholder={t("Select Unit")}
                  optionFilterProp="children"
                  onChange={(e) => {
                    handlePreOrderingTiming("minTimeInAdvanceUnit", e);
                    handleInputValue("minTimeInAdvance", "");
                  }}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  options={[
                    {
                      _id: "1",
                      label: t("Hours"),
                      value: "hours",
                    },
                    {
                      _id: "2",
                      label: t("Minutes"),
                      value: "minutes",
                    },
                  ]}
                />
              </div>
              <div className="w-full md:w-1/2 ">
                <TextInput
                  placeholder={t("Enter Minimum Lead Time")}
                  value={preOrderTimig?.minTimeInAdvance}
                  keyVal="minTimeInAdvance"
                  handleValues={handleInputValue}
                  error={error}
                  label={t("Enter Minimum Lead Time")}
                  mandatory={true}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
              <div className="w-full sm:w-1/2 ">
                <div className="text-gray-600 block text-14px my-1 ">
                  {t("Max Days In Advance")}
                </div>

                <Select
                  value={preOrderTimig?.maxDaysInAdvance}
                  style={{ minWidth: "100%" }}
                  size="large"
                  showSearch
                  placeholder={t("select minutes")}
                  optionFilterProp="children"
                  onChange={(e) =>
                    handlePreOrderingTiming("maxDaysInAdvance", e)
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  options={getThirdsDaysArrayWithDayLebel()}
                />
              </div>
              <div className="w-full sm:w-1/2 ">
                <div className="">
                  <div className=" my-1 text-gray-600 block text-14px">
                    {t("Slot Length")}
                  </div>
                  <Select
                    value={preOrderTimig?.timeInterval}
                    style={{ minWidth: "100%" }}
                    size="large"
                    showSearch
                    placeholder={t("Select Slot Length")}
                    optionFilterProp="children"
                    onChange={(e) => handlePreOrderingTiming("timeInterval", e)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    options={getHMinutSlotsTill8Hors()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
              <div className="w-full sm:w-1/2 ">
                <div className="text-gray-600 block text-14px my-1 ">
                  {t("Minimum Days In Advance")}
                </div>

                <Select
                  value={preOrderTimig?.minDaysInAdvance}
                  style={{ minWidth: "100%" }}
                  size="large"
                  showSearch
                  placeholder={t("select minutes")}
                  optionFilterProp="children"
                  onChange={(e) =>
                    handlePreOrderingTiming("minDaysInAdvance", e)
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  options={getNumOfDaysWithZero()}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default PreOrderingWidget;
