import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UTILS } from "utils";
import { useGetYandexClaimInfoMutation } from "redux/Logistic/LogisticService";
import { LogisticAction } from "redux/actions";
import { ErrorAction } from "redux/actions";
import YandexClaimInfo from "logistic-integrations/yandex/components/ClaimInfo";

const ClaimInfo = (props) => {
  const dispatch = useDispatch();
  const { claimInfo } = useSelector((state) => state?.LogisticReducer);
  const [getYandexClaimInfo] = useGetYandexClaimInfoMutation();

  useEffect(() => {
    const getyandexOrderInfo = async (order) => {
      const userDetail = UTILS.getCurrentUser();
      let obj = {
        brandId: userDetail.brandId,
        orderId: order._id,
      };
      const isValid = UTILS.ValidateParams(obj);
      if (isValid) {
        await getYandexClaimInfo(obj);
      } else {
        dispatch(LogisticAction.setClaimInfo(null));
        dispatch(
          ErrorAction.setError("Something went wrong while fetching claim info")
        );
      }
    };
    if (props?.order?._id) getyandexOrderInfo(props?.order);
  }, [props?.order?._id]);

  return (
    <Fragment>
      <YandexClaimInfo {...{ claimInfo }} type={true} />
    </Fragment>
  );
};

export default ClaimInfo;
