import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const LoaderReducer = createSlice({
  name: "LoaderReducer",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = LoaderReducer.actions;
export default LoaderReducer.reducer;
