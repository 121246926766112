import React from "react";

const LoadPreview = ({
  classes,
  random,
  width,
  height,
  getStorePreviewURL,
}) => {
  return (
    <iframe
      className={classes}
      key={random}
      width={width}
      height={height}
      src={getStorePreviewURL()}
    ></iframe>
  );
};
export default LoadPreview;
