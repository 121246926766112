import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { API } from "api/";
import { UTILS } from "utils";
import BgPasswordInput from "../../../components/BgPasswordInput";
import TextSlider from "../components/TextSlider";
import PhoneNumberInput from "components/PhoneNumberInput";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { LoginAction, SettingsAction, Language } from "redux/actions";
import LanguageToggle from "components/LanguageToggle";
import useToggleLanguage from "hooks/useToggleLanguage";
import { CONSTANTS as C } from "config";

const SignIn = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();
  const { lang, switchLanguage } = useToggleLanguage();
  const [invalidLogin, setInvalidLogin] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [stateLogin, setState] = useState({
    values: {
      phoneNumber: "",
      password: "",
    },
    errors: {
      phoneNumber: "",
      password: "",
    },
  });

  const handleValues = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: value,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const checkIfValidUser = (USER) => {
    if (USER === "BRAND-ADMIN" || USER === "MANAGER") {
      return true;
    } else return false;
  };
  const verifyAccount = (success) => {
    stateLogin.values.email = success.data.user.email;
    stateLogin.values.baseLanguage = success.data.user.brandId.baseLanguage;
    stateLogin.values.name = success.data.user.userName;
    history.push("/verify/validate-otp", {
      stateRegister: stateLogin,
    });
  };
  const loginUser = (success) => {
    const userDetail = UTILS.getCurrentUser();
    dispatch(LoginAction.toogleLogin(true));
    localStorage.setItem("loginEvent", Date.now().toString());
    if (
      UTILS.isValidObject(["data", "user", "brandId", "baseLanguage"], success)
    ) {
      let obj = {
        direction:
          success?.data?.user?.brandId?.baseLanguage === "en" ? "ltr" : "rtl",
        lang: success?.data?.user?.brandId?.baseLanguage,
      };
      UTILS.saveLanguageInformation(obj);
      document
        .getElementsByTagName("html")[0]
        .setAttribute("dir", obj.direction);
      let clang = success?.data?.user?.brandId?.baseLanguage?.toUpperCase();
      dispatch(Language.updateLanguage(clang));
    }
    if (checkIfValidUser(success?.data?.user?.userType)) {
      UTILS.saveCurrentUser({
        ...userDetail,
        token: success.data.token,
      });
      process.env.REACT_APP_COMPANY_NAME === C.COMPANY_NAMES.RESTHERO
        ? history.push("/app/dashboard/onboarding")
        : history.push("/app/dashboard");
    } else {
      setInvalidLogin(t("invalid username or password"));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!loginValidation()) return;
    setLoading(true);
    if (stateLogin.values.phoneNumber.charAt(4) === "0") {
      stateLogin.values.phoneNumber = UTILS.formatPhoneNumber(
        stateLogin.values.phoneNumber
      );
    }
    const success = await API.SIGN_IN({
      phoneNumber: stateLogin.values.phoneNumber,
      password: stateLogin.values.password,
      userType: "BRAND-ADMIN",
      deviceType: "web",
      language: "EN",
    });
    if (success?.response === 200 && success?.success === 1 && success?.data) {
      UTILS.saveCurrentUser({
        name: success?.data?.user?.name,
        email: success?.data?.user?.email,
        phoneNumber: success.data.user?.phoneNumber,
        token: null,
        storeFrontURL: success.data?.storeFrontURL,
        storePreviewURL: success?.data?.storePreviewURL,
        brandId: success.data?.user?.brandId?._id,
        userId: success.data?.user?.userId,
        userType: success?.data?.user?.userType,
        brandName: success.data?.user.brandId?.brandName,
        subscribedPackage: success?.data?.user.subscribedPackage?.packageId
          ? success.data?.user?.subscribedPackage?.packageId
          : "",
        catalogueType: success?.data?.user?.brandId?.catalogueType,
        temporaryDomainName: success?.data?.brandSettings?.temporaryDomainName,
      });
      dispatch(
        SettingsAction.updateCatalogueType(
          success?.data?.user?.brandId?.catalogueType
        )
      );
      let obj = {
        direction:
          success?.data?.user?.brandId?.baseLanguage === "en" ? "ltr" : "rtl",
        lang: success?.data?.user?.brandId?.baseLanguage,
      };
      UTILS.saveLanguageInformation(obj);
      if (
        success?.response === 200 &&
        success?.success === 1 &&
        success?.data?.user?.isVerified
      ) {
        loginUser(success);
      } else if (
        success?.response === 200 &&
        success?.success === 1 &&
        !success?.data?.user?.isVerified
      ) {
        verifyAccount(success);
      }
    } else {
      setInvalidLogin(success.message);
    }
    setLoading(false);
  };

  const loginValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (
      (stateLogin?.values?.phoneNumber?.charAt(4) == 0 &&
        stateLogin?.values?.phoneNumber?.length !== 14) ||
      (stateLogin?.values?.phoneNumber?.charAt(4) != 0 &&
        stateLogin?.values?.phoneNumber?.length !== 13)
    ) {
      errors["phoneNumber"] = t("Please enter a valid number");
      formIsValid = false;
    }
    if (stateLogin.values.password === "") {
      errors["password"] = t("Field is required");
    }

    if (
      Object.keys(errors).filter((itemFilter) => errors[itemFilter]).length > 0
    ) {
      try {
        formIsValid = false;
        window.scrollTo(0, 200);
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            ...errors,
          },
        }));
      } catch (error) {}
    }
    return formIsValid;
  };

  return (
    <>
      <Modal
        open={open}
        modalWidth="rounded-md border border-gray-300"
        onClose={() => setOpen(false)}
      >
        <div className="w-full h-fit">
          <iframe
            className="video w-full h-96"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src=""
          ></iframe>
          <div className="p-3 mt-2 text-center space-x-4 md:block">
            <button
              onClick={() => setOpen(false)}
              className="bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100 justify-center"
            >
              {t("Ok")}
            </button>
          </div>
        </div>
      </Modal>
      <Spin spinning={loading}>
        <div className="lg:flex flex flex-wrap justify-center items-center bg-white">
          <div className="lg:w-1/2 w-full py-16 lg:py-2">
            <div className="h-full flex justify-center items-center">
              <div className=" w-4/5">
                <LanguageToggle
                  onClick={switchLanguage}
                  selected={lang}
                  authenticated={false}
                />
                <img
                  src={process.env.REACT_APP_COMPANY_LOGO_WITH_NAME_BLACK}
                  className="mb-28"
                  alt=""
                />
                <h2 className="text-21px font-bold">
                  {" "}
                  {t("company name", {
                    company: process.env.REACT_APP_COMPANY_NAME,
                  })}
                </h2>
                <p className=" text-17px text-gray-500">
                  {t("Sign In to Dashboard")}
                </p>
                <p className="h-full flex justify-center items-center text-red-600">
                  {invalidLogin ? invalidLogin : ""}
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="col-span-1 sm:col-span-1">
                    <div className="py-2 px-4 bg-input-bg rounded">
                      <label className="font-medium block">
                        {t("Mobile Number")}{" "}
                        <span className="text-red-600"> *</span>
                        <span className="text-red-600">
                          {stateLogin.errors?.phoneNumber}
                        </span>
                      </label>
                      <div
                        className="relative text-gray-400 focus-within:text-gray-600 py-0.5"
                        dir="ltr"
                      >
                        <PhoneNumberInput
                          value={stateLogin.values?.phoneNumber}
                          keyVal={"phoneNumber"}
                          handleValues={handleValues}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-5">
                    <BgPasswordInput
                      label={t("Password")}
                      placeHolder="*******"
                      handleValues={handleValues}
                      value={stateLogin.values.password}
                      error={stateLogin.errors.password}
                      keyVal="password"
                      mandatory={true}
                    />
                  </div>
                  <div className="flex justify-between">
                    <div className="w-1/2 ms-3 text-blue-500 underline">
                      <Link to="/forgotpassword">{t("Forgot Password")}</Link>
                    </div>
                    <button
                      className="text-gray-800 font-bold me-3 cursor-pointer text-end hover:text-blue-600"
                      type="submit"
                    >
                      {t("Login to dashboard")}
                      <img
                        alt=""
                        src="/images/login.png"
                        className="invisible md:visible inline-block"
                      />
                    </button>
                  </div>
                </form>
                <div className="ms-3 text-blue-500 underline">
                  <Link to="/register">
                    {t("Do not have an account? Register")}
                  </Link>
                </div>
                {process.env.REACT_APP_COMPANY_NAME ===
                C.COMPANY_NAMES.RESTHERO ? (
                  <div className="ms-3 text-gray-500 text-12px">
                    {t(
                      "By registering with us, you agreed to terms and conditions"
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="px-4 pt-16 text-gray-500 text-center">
              {process.env.REACT_APP_COMPANY_NAME ===
              C.COMPANY_NAMES.RESTHERO ? (
                <>
                  <button
                    className="px-4 hover:underline"
                    onClick={() =>
                      window.open("https://www.resthero.io/privacy", "_blank")
                    }
                  >
                    {t("Privacy Policy")}
                  </button>
                  <button
                    className="px-4 hover:underline"
                    onClick={() =>
                      window.open("https://www.resthero.io/terms", "_blank")
                    }
                  >
                    {t("Terms and Conditions")}
                  </button>
                  <button
                    className="px-4 hover:underline"
                    onClick={() =>
                      window.open("https://www.resthero.io/", "_blank")
                    }
                  >
                    {t("About Us")}
                  </button>
                  <button
                    className="px-4 hover:underline"
                    onClick={() =>
                      window.open(
                        "https://www.resthero.io/contact-us",
                        "_blank"
                      )
                    }
                  >
                    {t("Contact Us")}
                  </button>
                </>
              ) : null}
              <p className="">
                ©{" "}
                {t("COPYRIGHT", {
                  year: new Date().getFullYear(),
                  company: process.env.REACT_APP_COMPANY_NAME,
                })}
              </p>
            </div>
          </div>
          <div className="bg-login w-full lg:w-1/2">
            <div className="h-full flex justify-center items-center">
              <div className="w-4/5 p-1">
                <img
                  alt=""
                  src={process.env.REACT_APP_COMPANY_LOGO_HERO}
                  className="-mt-24 mb-24"
                />
                <img
                  alt=""
                  src="/images/comment.png"
                  className="pb-2 inline-block"
                />
                <TextSlider />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default SignIn;
