import React, { useEffect, useRef, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

export default function ColorPicker(props) {
  const wrapperRef = useRef(null);
  let { show, notShow } = useOutsideAlerter(wrapperRef);

  const handleClick = () => {
    notShow(!show);
  };

  const handleClose = () => {
    notShow(false);
  };

  const handleChange = (e) => {
    props.onChange(props.pkey, e.hex);
  };
  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `${props?.colorValue}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return props?.colorValue ? (
    <div ref={wrapperRef}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {show ? (
        <div style={styles.popover}>
          <div onClick={handleClose} />
          <SketchPicker color={props.colorValue} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  ) : (
    <></>
  );
}

function useOutsideAlerter(ref) {
  const [show, notShow] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        notShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return { show, notShow };
}
