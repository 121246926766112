import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, Button, Spin, Table } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import SearchInput from "components/SearchInput";
import EmptyFooter from "components/EmptyFooter";
import Card from "components/Card";
import { UTILS } from "utils";

import { errorHandler } from "api/api/errorHandler";
import { useLinkItemWithCategoryMutation } from "redux/menu/service";
import useGetBRandItems from "../hooks/useGetBRandItems";
import { API } from "api";

const LinkItemWithCategoryModal = ({
  cat,
  onClose,
  menuId,
  brandItems,
  getCategoryInfo,
  items,
  open,
}) => {
  const getItemsByBrand = useGetBRandItems();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);

  const handleRowClick = (item) => {
    const updatedArray = [...checkedRows];
    const existingIndex = updatedArray.findIndex(
      (itemId) => itemId?.itemId === item?._id
    );

    if (existingIndex !== -1) {
      // If already selected, remove
      updatedArray.splice(existingIndex, 1);
    } else {
      // If not selected, add
      updatedArray.push({
        itemId: item?._id,
      });
    }
    setCheckedRows(updatedArray);
  };

  const Row = (value, item, index) => {
    return (
      <Checkbox
        key={item._id}
        checked={checkedRows.some((row) => row?.itemId === item?._id)}
        onChange={() => handleRowClick(item)}
      />
    );
  };

  const columns = [
    {
      title: t("Link"),
      dataIndex: "price",
      align: "center",
      width: "10%",
      render: Row,
    },
    {
      title: t("Name"),
      dataIndex: "baseLanguage",
      key: "title",
      align: "center",
      render: (value, row, i) => {
        return <div className="truncate-line-3">{value?.title}</div>;
      },
    },
    {
      title: t("Description"),
      dataIndex: "baseLanguage",
      align: "center",
      render: (value, row, i) => {
        return <div className="truncate-line-3">{value?.description}</div>;
      },
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: "10%",
      align: "center",
      render: (value, row, i) => {
        return <div>AED {value}</div>;
      },
    },
  ];

  const [searchTerm, setSearch] = useState("");

  const getUnattachedItems = (linkedItems) => {
    const attachedItemsLookup = _.keyBy(
      linkedItems,
      (item) => item?.itemId?._id
    );

    const unattachedModifiers = brandItems?.filter(
      (item) => !attachedItemsLookup[item?._id]
    );
    return unattachedModifiers;
  };

  const unlinkedITes = useMemo(() => {
    const unlinkedItems = getUnattachedItems(items);
    if (!searchTerm) return unlinkedItems;

    return unlinkedItems?.filter((item) =>
      item?.baseLanguage?.title
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase())
    );
  }, [
    items,
    searchTerm,
    cat?._id,
    menuId,
    items?.length,
    open,
    brandItems?.length,
  ]);

  const linkItemWithMenuCategory = async () => {
    try {
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.linkItemWithCategory({
        brandId: userDetail.brandId,
        categoryId: cat?._id,
        menuId,
        items: checkedRows,
      });
      if (result?.success === 1) {
        await getCategoryInfo(cat?._id);
        UTILS.successNotification(result?.message);
        onClose();
      } else {
        UTILS.errorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItemsByBrand();
  }, []);
  return (
    <div className="w-full bg-white px-4 py-2 text-center">
      <Spin spinning={loading}>
        <div className="flex flex-col justify-between md:flex-row items-center px-4 pt-4 w-full">
          <div className="">
            <h2 className="text-15px py-0 pe-4 text-start font-bold">
              {t("Add Items to")}{" "}
              <span className="text-indigo-600">{cat?.baseLanguage?.name}</span>
            </h2>
          </div>
          <div className="">
            <SearchInput
              placeholder={t("Search by name")}
              searchTerm={searchTerm}
              setSearchTerm={setSearch}
            />
          </div>
        </div>

        {unlinkedITes?.length >= 1 ? (
          <div
            className="w-full bg-white px-2 sm:px-4 py-2 text-center"
            id="atachmodifierwithgroup"
          >
            <Card styleClasses="px-0 sm:px-2 py-2">
              <div className="overflow-auto table-height">
                <Table
                  locale={{ emptyText: t("No Data") }}
                  columns={columns}
                  dataSource={unlinkedITes}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 50,
                    defaultPageSize: 50,
                  }}
                  rowKey={(record) => record._id}
                />
              </div>
            </Card>

            <div className="flex justify-between">
              <div className="mt-2 text-center  md:block">
                <button
                  onClick={onClose}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100"
                >
                  {t("Close")}
                </button>
              </div>
              <div className="mt-2 text-center  md:block">
                <Button
                  disabled={checkedRows?.length <= 0}
                  type="primary"
                  size="large"
                  className="w-36"
                  onClick={linkItemWithMenuCategory}
                >
                  {t("Attach")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <EmptyFooter
            url="/app/dashboard/createitem"
            heading={t("No Item")}
            heading2={t("Add New Item")}
            handleClick={onClose}
          />
        )}
      </Spin>
    </div>
  );
};
export default LinkItemWithCategoryModal;

LinkItemWithCategoryModal.propTypes = {
  onClose: PropTypes.func,
  cat: PropTypes.object,
  handleValues: PropTypes.func,
  itemsToRemove: PropTypes.array,
};
