import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { API } from "api";
import { UTILS } from "utils";
const CancelOrder = ({ order, setLoading, getOrderInfoById }) => {
  const [reason, setReason] = useState();
  const [disabled, setDisabled] = useState();
  const { t } = useTranslation();
  const cancelOrder = async () => {
    if (!UTILS.validateTalabat(order)) {
    } else {
      setLoading(true);
      let result = "";
      try {
        result = await API.CANCEL_TALABAT_ORDER({
          storeId: order.storeId,
          brandId: order.brandId,
          orderId: order._id,
          cancelReason: reason,
        });
        if (result?.success === 1) {
          UTILS.successNotification(
            result?.message ?? t("Order was successfully cancelled")
          );
        } else if (result?.success === 0) {
          UTILS.errorNotification(result.message ?? "something went wrong");
        }
      } catch (error) {
        UTILS.errorNotification(error.message ?? "something went wrong");
      }
      getOrderInfoById(order._id);
      setLoading(false);
    }
  };
  const check = () => {
    if (
      UTILS.ifNotNullAndUndfine(
        order?.logisticPartnerMetadata?.TALABAT?.orderPlaceResponse?.order_id
      ) &&
      UTILS.Undfined(
        order?.logisticPartnerMetadata?.TALABAT?.cancelOrderResponse
      )
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (!reason || check()) {
      setDisabled(true);
    } else setDisabled(false);
  }, [reason, order]);
  return (
    <div>
      <select name="cars" id="cars" onChange={(e) => setReason(e.target.value)}>
        <option defaultChecked selected disabled>
          {t("Select Reason")}
        </option>
        <option value="DELIVERY TIME TOO LONG">
          {t("DELIVERY TIME TOO LONG")}
        </option>
        <option value="ORDER PLACED BY MISTAKE">
          {t("ORDER PLACED BY MISTAKE")}
        </option>
        <option value="CUSTOMER CANCELLED">{t("CUSTOMER CANCELLED")}</option>
      </select>
      <div className="flex justify-center p-4">
        <Button
          type="default"
          size="large"
          onClick={cancelOrder}
          disabled={disabled}
        >
          {t("Cancel Order To TALABAT")}
        </Button>
      </div>
    </div>
  );
};

export default CancelOrder;
