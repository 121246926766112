import { CONSTANTS as C } from "config";
import { PkgActions } from "redux/actions";
import reduxStore from "redux/store";
import { UTILS } from "utils";
import * as apiUrl from "./apiUrls";
import axios, { post, postUpdated, upload } from "./axiosSetup";
export const SIGN_IN = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(C.BASE_URL + "admin/SignIn", {
      phoneNumber: params.phoneNumber,
      password: params.password,
      userType: params.userType,
      deviceType: params.deviceType,
      language: language ?? params.language,
    })
    .then((response) => {
      if (response) {
        if (response?.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const FORGOT_PASSWORD = async (params) => {
  const language = (await UTILS?.asyncLocalStorage.getLanguage()) ?? "EN";
  return axios
    .post(C.BASE_URL + "admin/forgotPassword", {
      phoneNumber: params.phoneNumber,
      language,
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const NEW_PASSWORD = async (params) => {
  const language = (await UTILS?.asyncLocalStorage.getLanguage()) ?? "EN";

  return axios
    .post(C.BASE_URL + "admin/updatePasswordByCode", {
      phoneNumber: params.phoneNumber,
      verificationCode: params.verificationCode,
      newPassword: params.password,
      language,
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const REGISTER = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(C.BASE_URL + "admin/signupBrand", {
      name: params.name,
      brandName: params.brandName,
      phoneNumber: params.phoneNumber,
      email: params.email,
      password: params.password,
      language: language || params.language,
      superCategoryId: params.superCategoryId,
      baseLanguage: params.baseLanguage,
      supportedLanguages: [{ language: params.baseLanguage, published: true }],
      utmSource: params.utmSource || "",
      utmMedium: params.utmMedium || "",
      utmCampaign: params.utmCampaign || "",
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const RESEND_VERIFICATION_CODE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(C.BASE_URL + `admin/resendSmsOnMobile`, {
      language: language ?? "EN",
      phoneNumber: params.phoneNumber,
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const VERIFY_MOBILE_NUMBER = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(C.BASE_URL + "admin/verifyMobileNumber", {
      verificationCode: params.verificationCode,
      phoneNumber: params.phoneNumber,
      language: language ?? params.language,
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const CHECK_BRAND_NAME = async (params) => {
  return axios
    .post(C.BASE_URL + `brands/validateBrandName`, {
      brandName: params.brandName,
      language: params.language,
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_GUIDANCE_FLOW_SETUP_WITH_STATUS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + `brands/getGuidanceFlowSetupStatus`,
      {
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const ADD_TASK_TO_DONE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + `brands/addNewGuidingTaskInBrand`,
      {
        taskId: params.taskId,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const CREATE_BRAND_STORE = async (params) => {
  let paymentOptions = [];
  if (params?.values?.PaymentOptionCOD == true) {
    paymentOptions = [...paymentOptions, C.CASH_ON_DELIVERY_OBJECT];
  }
  if (params?.values?.PaymentOptionCreditCard == true) {
    paymentOptions = [...paymentOptions, C.CREDIT_CARD_OBJECT];
  }

  return axios
    .post(
      `https://dev-api.theresthero.com/api/v1/user/createBrandAndStoreWithForms`,
      {
        brandTitleEN: params?.values?.brandTitleEN || "",
        email: params?.values?.email || "",
        phoneNumber: params?.values?.phoneNumber || "",
        language: "EN",
        subdomain: params?.values?.brandTitleEN || "",
        store: {
          language: "EN",
          country: "Pakistan",
          city: "City",
          serviceType: [
            {
              nameEN: "delivery",
              nameAR: "توصيل",
            },
          ],
          storeCuisines: [
            {
              nameEN: "italian",
              nameAR: "thai",
            },
          ],
          latitude: params?.values?.latitudeLongitude?.mapCenter?.lat || "",
          longitude: params?.values?.latitudeLongitude?.mapCenter?.lng || "",
          admins: [],
          minOrderPrice: 1,
          deliveryFee: 1,
          minDeliveryTime: 1,
          storeNameAR: params?.values?.brandTitleEN || "",
          storeName: params?.values?.brandTitleEN || "",
          storeDescriptionAR: params?.values?.descriptionAr || "",
          storeDescription: params?.values?.description || "",
          email: params?.values?.email || "",
          mobileNumber: params?.values?.phoneNumber || "",
          workingHours: params?.values?.workingHours || {},
          paymentOptions: paymentOptions,
          storeAddress: params?.values?.latitudeLongitude?.address,
          storeLocation: `${params?.values?.latitudeLongitude?.mapCenter?.lat}, ${params?.values?.latitudeLongitude?.mapCenter?.lng}`,
        },
        brandId: params.brandId,
        userId: params.userId,
        serviceType: "car-rental",
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_CURRENCY_TYPES = (params) => {
  return axios
    .get(C.BASE_URL + `currencies/getCurrencyTypes`, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const getSuperCategories = () => {
  return axios
    .get(C.BASE_URL + `superCategories/getSuperCategories`)
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const SUBSRIBE_TRAIL_PACKAGES = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + `order/subscribeTrailPackage`,
      {
        amount: params.data.price,
        packageId: params.data.packageId,
        brandId: params.brandId,
        noOfExpiryDays: params.data.noOfExpiryDays,
        language: language,
        userId: params.userId,
        owner: true,
        step4: false,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          console.log("");
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_PACKAGES = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(C.BASE_URL + `packages/getAllPackages`, {
      brandId: params.brandId,
      language,
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          console.log("");
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const addUserAreas = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/addRegisterStoreDeliveryAreas",
      {
        storeId: params.storeId,
        userId: params.userId,
        language: language,
        deliveryAreas: params.deliveryAreas,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_CRRENT_USER_STORES = async (params) => {
  return axios
    .get(C.BASE_URL + "store/getCurrentUserStores", {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_STORE_INFO_WITH_STORE_ID = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/getStoreInfoWithStoreId",
      {
        storeId: params.storeId,
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const updateStore = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let store = { ...params.store };
  store["language"] = language;
  return axios
    .post(C.BASE_URL + `store/updateStoreInfo`, store, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const ADD_STORE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let store = { ...params.store };
  store["language"] = language;
  return axios
    .post(C.BASE_URL + "store/addNewStore", store, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const GET_PAYMENT_TYPES = async (params) => {
  return axios
    .get(C.BASE_URL + "paymentTypes/getPaymentTypes", {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_SERVICE_TYPES = async (params) => {
  return axios
    .get(C.BASE_URL + "services/getServiceTypes", {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const UPLOAD_FILE = async (params) => {
  var formData = new FormData();
  formData.append("image", params.image);
  return axios
    .post(C.BASE_URL + "user/uploadFile", formData, {
      headers: {
        Authorization: params.token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const ADD_AREA = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let area = { ...params.objArea };
  area["language"] = language;
  return axios
    .post(C.BASE_URL + "store/addStoreArea", area, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const UPDATE_AREA = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let area = { ...params.objArea };
  area["language"] = language;
  return axios
    .post(C.BASE_URL + "store/updateStoreArea", area, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const DELETE_AREA = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let area = { ...params.objArea };
  area["language"] = language;
  return axios
    .post(C.BASE_URL + "store/deleteStoreArea", area, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_STORE_DELIVERY_AREAS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/getStoreDeliveryAreas",
      {
        storeId: params.storeId,
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const ADD_USER = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let user = { ...params.user };
  user["language"] = language;
  return axios
    .post(C.BASE_URL + "admin/createAdmin", user, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_USERS_BY_BRAND = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "admin/getAdminUsersByBrand",
      {
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_BRAND_STORES = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/getBrandStores",
      {
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_USER_STORES_BY_USER_ID = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/getUserStoresByUserId",
      {
        userId: params.userId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const ADD_NEW_STORE_ADMIN = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/addNewAdmin",
      {
        userType: params.userType,
        brandId: params.brandId,
        adminId: params.adminId,
        storeId: params.storeId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const DELETE_STORE_ADMIN = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/deleteAdmin",
      {
        userType: params.userType,
        brandId: params.brandId,
        adminId: params.adminId,
        storeId: params.storeId,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_DATA_FOR_USERS_WITH_STORE_LIST = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  const [firstResponse, secondResponse] = await Promise.all([
    axios.post(
      C.BASE_URL + "store/getBrandStores",
      {
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    ),
    axios.post(
      C.BASE_URL + "store/getUserStoresByUserId",
      {
        userId: params.userId,
        language: params.language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    ),
  ]);
  return [firstResponse.data, secondResponse.data];
};

export const DELETE_CATEGORY = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "category/deleteItemCategory",
      {
        brandId: params.brandId,
        categoryId: params.categoryId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_CATEGORIES_BY_BRAND = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "category/getCategoriesbyBrand",
      {
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_ITEMS_BY_BRAND = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let category = { ...params.category };
  category["language"] = language;
  return axios
    .post(
      C.BASE_URL + "items/get-items",
      {
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const DELETE_ITEM = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/delete-item",
      {
        itemId: params.itemId,
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status === 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      return error;
    });
};

export const GET_ITEM_INFO = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/get-item-info",
      {
        itemId: params.itemId,
        brandId: params.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const ADD_ITEM_TO_STORE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/addItemInStore",
      {
        brandId: params.brandId,
        storeId: params.storeId,
        itemId: params.itemId,
        categoryId: params.categoryId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const REMOVE_ITEM_FROM_STORE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(
      C.BASE_URL + "items/removeItemFromSpecificCategoryAndStore",
      {
        brandId: params.brandId,
        storeId: params.storeId,
        itemId: params.itemId,
        categoryId: params.categoryId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_ITEMS_OF_CATEGORY_BY_BRAND = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/getCategoryWithItemsByBrand",
      {
        brandId: params.brandId,
        categoryId: params.categoryId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const REMOVE_ITEM_FROM_CATEGORY = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/removeItemFromSpecificCategory",
      {
        brandId: params.brandId,
        categoryId: params.categoryId,
        itemId: params.itemId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_STORES_ATTACHED_TO_ITEM = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/getStoresAttachedToSpecificItemOfCategory",
      {
        brandId: params.brandId,
        categoryId: params.categoryId,
        itemId: params.itemId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_ALL_STORES_AND_STORES_ATTACHED_TO_ITEM = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  const [firstResponse, secondResponse] = await Promise.all([
    axios.post(
      C.BASE_URL + "store/getBrandStores",
      {
        brandId: params.brandId,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    ),
    axios.post(
      C.BASE_URL + "items/getStoresAttachedToSpecificItemOfCategory",
      {
        brandId: params.brandId,
        categoryId: params.categoryId,
        itemId: params.itemId,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    ),
  ]);
  return [firstResponse.data, secondResponse.data];
};

export const UPDATE_MODIFIER_TO_ITEM = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let modifier = { ...params.modifier };
  modifier["language"] = language;
  return axios
    .post(C.BASE_URL + "items/updateItemSection", modifier, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const DELETE_MODIFIER_FROM_ITEM = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/remove-modifier-group-in-itemn",
      {
        brandId: params.brandId,
        itemId: params.itemId,
        sectionId: params.sectionId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_ORDER_COUNT = async (params) => {
  return axios
    .post(
      C.BASE_URL + "order/getAllPendingOrdersCount",
      {},
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_REFUNDABLE_COUNT = async (params) => {
  return axios
    .post(
      C.BASE_URL + "order/get-refundable-orders-count",
      {},
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status === 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_ORDER_BY_STATUS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "order/getOrdersByStatus",
      {
        orderStatus: params.orderStatus,
        filter: params.filter,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};
export const GET_ORDER_INFO_BY_ID = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "order/getOrderInfoById",
      {
        brandId: params.brandId,
        orderId: params.orderId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const SET_ORDER_STATUS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let order = { ...params };
  order["language"] = language;
  return axios
    .post(C.BASE_URL + "order/setOrderStatus", order, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const REFUND_ORDER = (data) => postUpdated("order/refund", data);
export const SET_ORDER_NOT_REFUNDABLE = (data) =>
  postUpdated("order/set-nonrefundable", data);

//
export const GET_ORDERS_HISTORY = (data) =>
  post("order/get-orders-history-v2", data);

export const GET_ITEMS_BY_STORE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/getItemsByStore",
      {
        brandId: params.brandId,
        storeId: params.storeId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const PAUSE_ITEM_IN_STORE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/pauseItemInStore",
      {
        brandId: params.brandId,
        storeId: params.storeId,
        itemId: params.itemId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const PLAY_ITEM_IN_STORE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "items/playItemInStore",
      {
        brandId: params.brandId,
        storeId: params.storeId,
        itemId: params.itemId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const CHECK_PACKAGE_VALIDITY = async (params) => {
  if (!params?.token) return;
  return axios
    .post(
      C.BASE_URL + "onboardingSubscriptions/getCurrentUserSubscription",
      {
        brandId: params.brandId,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          if (response.data.data.subscription.billingCycle) {
            reduxStore.dispatch(
              PkgActions.setSelectedKey(
                response.data.data.subscription.billingCycle
              )
            );
          }

          localStorage.setItem(
            "packageInfo",
            JSON.stringify(response.data.data.subscription)
          );

          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_STATS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "order/stats",
      {
        language,
      },
      {}
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const SEND_ORDER_TO_QUIQUP = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "order/sendOrderToQUIQUP",
      {
        storeId: params.storeId,
        brandId: params.brandId,
        needs_return: false,
        orderId: params.orderId,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const SAVE_SETTINGS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  let settings = { ...params.settings };
  settings["language"] = language;
  return axios
    .post(C.BASE_URL + "branding/updateSetting", settings, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_SETTINGS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "branding/getBrandSettingWithBrandId",
      {
        brandId: params.brandId,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const CHECK_ONLINE_PAYMENT = async (params) => {
  return axios
    .get(C.BASE_URL + "store/isOnlinePaymentOptionAvailableForStore", {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const RE_ORDER_CATEGORIES = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(
      C.BASE_URL + "category/reOrderItemCategory",
      {
        brandId: params.brandId,
        categoryId: params.categoryId,
        index: params.index,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};
export const RE_ORDER_CATEGORY_ITEMS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(
      C.BASE_URL + "category/reorder-item-in-category",
      {
        brandId: params.brandId,
        categoryId: params.categoryId,
        index: params.index,
        itemId: params.itemId,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error: error?.response?.data ?? {
          success: 0,
        },
      };
    });
};

export const GET_ORDER_HISTORY = (data) =>
  post("reports/order-history-v2", data);
export const UPDATE_STORE_AREA = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/updateStoreArea",
      {
        areas: params.areas,
        deliveryFee: params.deliveryFee,
        deliveryTime: params.deliveryTime,
        minOrder: params.minOrder,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const DELETE_STORE_AREA = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "store/deleteStoreArea",
      {
        areas: params.areas,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response?.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      return error;
    });
};
export const GET_CUSTOMER_REPORT = (data) =>
  post("reports/customer-report-v2", data);

export const ADD_STORE_AREA = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(
      C.BASE_URL + "store/addStoreArea",
      {
        areas: params.areas,
        deliveryFee: params.deliveryFee,
        deliveryTime: params.deliveryTime,
        minOrder: params.minOrder,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response?.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const GET_PAYMENTLINK_HISTORY = (data) =>
  post("reports/payments-link-history-v2", data);

export const CREATE_PAYMENT_LINK = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(
      C.BASE_URL + "payment-links",
      {
        storeName: params?.data?.storeName,
        brandId: params?.brandId,
        storeId: params?.data?.storeId,
        amount: params?.data?.amount,
        currency: "AED",
        purposeOfPayment: params?.data?.purposeOfPayment,
        message: params?.data?.message,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      return {
        error: error?.response?.data ?? {
          success: 0,
        },
      };
    });
};
export const GET_ALL_PAYMENT_LINKS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(
      C.BASE_URL + "payment-links/get-all-payment-links",
      {
        brandId: params?.brandId,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error: error?.response?.data ?? {
          success: 0,
        },
      };
    });
};

export const GET_PAYOUT_HISTORY = async (params) => {
  return axios
    .get(C.BASE_URL + `reports/${params.brandId}/get-brand-payout-history`, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status === 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error: error?.response?.data ?? "something went wrong",
      };
    });
};

export const CREATE_DISCOUNT_COUPON = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "discounts/create-discount",
      {
        ...params.data,
        isActive: true,
        language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      return {
        error:
          error?.response?.data || error?.message || "something went wrong",
      };
    });
};

export const GET_ALL_COUPONS = async (params) => {
  return axios
    .get(C.BASE_URL + `discounts/${params.brandId}/get-all-discounts`, {
      headers: {
        Authorization: params.token,
      },
    })
    .then((response) => {
      if (response) {
        if (response.status === 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error:
          error?.response?.data || error?.message || "something went wrong",
      };
    });
};
export const UPDATE_COUPONS = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();
  return axios
    .post(
      C.BASE_URL + "discounts/update-discount",
      {
        ...params.data,
        discountId: params.data?._id,
        language,
      },

      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status === 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error:
          error?.response?.data || error?.message || "something went wrong",
      };
    });
};

export const PUBLISH_UNPUBLISH_STORE = async (params) => {
  const language = await UTILS?.asyncLocalStorage.getLanguage();

  return axios
    .post(
      C.BASE_URL + "store/update-store-publish-status",
      {
        storeId: params.store?.storeId,
        brandId: params.store?.brandId,
        isOpened: params.store?.isOpened,
        language: language,
      },
      {
        headers: {
          Authorization: params.token,
        },
      }
    )
    .then((response) => {
      if (response) {
        if (response.status === 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error: error?.response?.data ?? "something went wrong",
      };
    });
};

export const GET_BUSINESS_AREAS = async (params) => {
  return axios
    .get(C.BASE_URL + `country/getAllCountriesWithCompleteDetail`)
    .then((response) => {
      if (response) {
        if (response.status == 200) {
          return response.data;
        }
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        success: 0,
      };
    });
};

export const GET_BUSINESS_AREAS8 = (data) =>
  post(apiUrl.GET_BUSINESS_AREAS, data);
export const SEND_ORDER_TO_TALABAT = (data) =>
  post(apiUrl.SEND_ORDER_TO_TALABAT, data);
export const CHECK_TALABAT_DELIVERY_FEE = (data) =>
  post(apiUrl.CHECK_TALABAT_DELIVERY_FEE, data);

export const CHECK_TALABAT_DELIVERY_TIME = (data) =>
  post(apiUrl.CHECK_TALABAT_DELIVERY_TIME, data);

export const CANCEL_TALABAT_ORDER = (data) =>
  post(apiUrl.CANCEL_TALABAT_ORDER, data);
export const SEND_ORDER_TO_MARSOOL = (data) =>
  post(apiUrl.SEND_ORDER_TO_MARSOOL, data);

// packages api

export const GET_ALL_ACTIVE_PACKAGES = () => post(apiUrl.GET_ALL_PACKAGES);
export const GET_HOSTED_PAYMENT_PAGE = (data) =>
  post(apiUrl.GET_PAYMENT_PAGE, data);
export const VERIFY_PACKAGE_PAYMENT = () => post(apiUrl.VERIFY_PKG_PAYMENT);
export const getBrandInfoWithBrandId = (data) =>
  post(apiUrl.GET_BRAND_INFO_WITH_ID, data);
export const uploadBulkItems = (data) => upload(apiUrl.UPLOAD_BULK_ITEMS, data);

//feedback api
export const getOrdersBacks = (data) =>
  post(apiUrl.GET_ORDERS_FEEDABACKS, data);

//MAPPED AREA
export const getMappedCountriesCitiesAreas = (data) =>
  post(apiUrl.GET_COUNTRIES_CITIES_MAPPED_AREAS, data);

export const addStoreMappedArea = (data) =>
  post(apiUrl.ADD_STORE_MAPPED_AREAS, data);

export const deleteStoreMappedArea = (data) =>
  post(apiUrl.DELETE_STORE_MAPPED_AREAS, data);

export const getStoreMappedAreas = (data) =>
  post(apiUrl.GET_STORE_MAPPED_AREAS, data);

export const updateStoreMappedAreas = (data) =>
  post(apiUrl.UPDATE_STORE_MAPPED_AREAS, data);

export const addCustomStoreMapArea = (data) =>
  post(apiUrl.ADD_CUSTOM_STORE_MAP_AREA, data);

export const getCustomStoreMapAreaById = (data) =>
  post(apiUrl.GET_CUSTOM_STORE_MAP_AREA_BY_ID, data);

export const getAllCustomBuisnessAreas = (data) =>
  post(apiUrl.GET_ALL_CUSTOM_BUSINESS_AREAS, data);

export const updateCustomStoreMapArea = (data) =>
  post(apiUrl.UPDATE_CUSTOM_STORE_MAP_AREA, data);

export const togglePolyonAreaStatus = (data) =>
  post(apiUrl.TOGGLE_PLOYGON_AREA_STATUS, data);
//POLLING
export const getOrderMetaData = (data) =>
  post(apiUrl.GET_ORDER_META_DATA, data);

//disntance based delivery
export const addDistanceBaseDeliveryZone = (data) =>
  post(apiUrl.ADD_DISTANCE_BASE_DELIVERY_ZONE, data);

export const getDistanceBaseDeliveryZoneInfo = (data) =>
  post(apiUrl.GET_DISTANCE_BASE_DELIVERY_ZONE_INFO, data);

export const updateDistanceBaseDeliveryZone = (data) =>
  post(apiUrl.UPDATE_DISTANCE_BASE_DELIVERY_ZONE, data);

export const deleteDistanceBaseDeliveryZone = (data) =>
  post(apiUrl.DELETE_DISTANCE_BASE_DELIVERY_ZONE, data);

export const getDistanceBaseDeliveryZones = (data) =>
  post(apiUrl.GET_DISTANCE_BASE_DELIVERY_ZONES, data);

export const toggleDistanceBaseDeliveryZoneStatus = (data) =>
  post(apiUrl.TOGGLE_DISTANCE_BASE_DELIVERY_ZONE_STATUS, data);

export const createDZDelivery = (data) => post(apiUrl.CREATE_DZ_DELIVERY, data);
export const trackDZDelivery = (data) => post(apiUrl.TRACK_DZ_DELIVERY, data);
export const downloadOrderHistoryExcelReport = (data) =>
  post(apiUrl.DOWNNLOAD_ORDERHISTORY_EXCEL_REPORT, data);
export const downloadCustomerExcelReport = (data) =>
  post(apiUrl.CUSTOMER_REPORT_EXCEL, data);
export const downloadPaymentLinksExcelReport = (data) =>
  post(apiUrl.PAYMENT_LINKS_EXCEL, data);
export const updateMenu = (data) => post(apiUrl.UPDATE_MENU, data);
export const cloneMenu = (data) => post(apiUrl.CLONE_MENU, data);
export const deleteMenu = (data) => post(apiUrl.DELETE_MENU, data);
export const markAsDefaultMenu = (data) =>
  post(apiUrl.MARK_AS_DEFAULT_MENU, data);
export const blockUnblockItemInStore = (data) =>
  post(apiUrl.BLOCK_UNBLOCK_ITEM_FOR_STORE, data);
export const toggleModifierInventory = (data) =>
  post(apiUrl.MODIFER_STORE_BLOCK, data);

export const UPDATE_ITEM = (data) => post("items/update-item", data);
export const ADD_ITEM = (data) => post("items/add-new-item", data);

export const linkModifierGroupWithItem = (data) =>
  post(apiUrl.LINK_MODIFIER_GROUP_WITH_ITEM, data);

export const LinkModifierWithGroup = (data) =>
  post(apiUrl.LINK_MODIFIER_WITH_GROUP, data);
export const ADD_CATEGORY = (data) => post("category/addNewItemCategory", data);
export const unLinkItemWithCategory = (data) =>
  post(apiUrl.UNLINK_ITEMS_IN_CATEGORY, data);

export const unLinkCategoryWithMenu = (data) =>
  post(apiUrl.UNLINK_CATEGORY_IN_MENU, data);
export const linkItemWithCategory = (data) =>
  post(apiUrl.LINK_ITEMS_IN_CATEGORY, data);

export const sortItemInCategory = (data) =>
  post(apiUrl.SORT_ITEMS_IN_CATEGORY, data);

export const unLinkModifierGroupWithItem = (data) =>
  post(apiUrl.UNLINK_MODIFIER_GROUP_WITH_ITEM, data);

export const UPDATE_CATEGORY = (data) =>
  post("category/updateItemCategory", data);

export const sortModifierGroup = (data) =>
  post(apiUrl.SORT_MODIFIER_GROUPS, data);

export const sortModifierOption = (data) =>
  post(apiUrl.SORT_MODIFIER_OPTION, data);
