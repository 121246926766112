import React from "react";
import { StateProvider } from "../store";
import CatelougeContext from "store/contexts/Catelouge";
import LiveOrderProvider from "../store/LiveOrderContext/LiveOrderProvider";
import Routes from "../routes/index";
import { CONSTANTS as C } from "config";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Help from "features/help/containers/help";
import { useEffect } from "react";
import ProtectedRoute from "routes/middlewares/ProtectectRoute";
import moment from "moment";
import "moment/locale/ar";
import { useSelector } from "react-redux";
import { checkISAppUpdated } from "utils/Utils";
import RefreshModal from "components/VersionUpdate";
import useOffLine from "hooks/useOffLine";
import useSyncTabs from "hooks/useSyncTabs";
import { UTILS } from "utils";
import Seo from "components/Seo";
import FallbackUi from "components/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import WithContextProviders from "components/Hoc/WithContextProviders";
const history = createBrowserHistory();
moment.locale("en");
const Home = () => {
  useOffLine();
  useSyncTabs();
  const { refreshApp } = useSelector((state) => state.APPUpdate);
  const { isInternetConnected } = useSelector(
    (state) => state.NotPersistReducer
  );
  useEffect(() => {
    checkISAppUpdated();
  }, []);

  const handleRefreshClick = () => window.location.reload();
  const updatenew = () => {
    UTILS.checkISAppUpdated();
    window.location.reload();
  };
  if (refreshApp) {
    return <RefreshModal {...C.refreshAppProps} onClick={updatenew} />;
  }
  if (!isInternetConnected) {
    return <RefreshModal {...C.noInternetProps} onClick={handleRefreshClick} />;
  }
  return (
    <StateProvider>
      <LiveOrderProvider>
        <CatelougeContext>
          <WithContextProviders>
            <BrowserRouter>
              <ErrorBoundary
                FallbackComponent={FallbackUi}
                resetKeys={[history.location.pathname]}
              >
                <ProtectedRoute component={Help} />
                <ReactNotification />
                <Seo />
                <Routes />
              </ErrorBoundary>
            </BrowserRouter>
          </WithContextProviders>
        </CatelougeContext>
      </LiveOrderProvider>
    </StateProvider>
  );
};

export default Home;
