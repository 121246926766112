import React from "react";
import { Tabs } from "antd";
import GeneralSettings from "./GeneralSettings";
import BankAccountSettings from "./BankAccountSettings";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const callback = (key) => {};
const BusinessTabSettings = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane
        tabBarStyle={{ color: "pink" }}
        tab={t("General settings")}
        key="1"
      >
        <GeneralSettings />
      </TabPane>
      {/* <TabPane tab={"Bank account"} key="2">
        <BankAccountSettings />
      </TabPane> */}
    </Tabs>
  );
};

export default BusinessTabSettings;
