import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "api/api/axiosSetup";
import { UTILS } from "utils";
const axiosBaseQuery =
  () =>
  async ({ url, method, body, params = {} }) => {
    const language = (await UTILS?.asyncLocalStorage.getLanguage()) ?? "EN";
    let data = { ...body, language };
    try {
      const result = await axios({
        url: url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: axiosBaseQuery(),

  endpoints: (builder) => ({}),
});

export const postConfig = {
  query: (body) => ({
    method: "POST",
    body: body,
  }),
  transformResponse: (response) => {
    return response;
  },
};

export const getConfig = {
  query: () => ({
    method: "GET",
  }),
  transformResponse: (response) => {
    return response;
  },
};
