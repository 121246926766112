// export const BOOT_ROUTE = 'nav.Boot';
export const LOG_IN_ROUTE = "/login";
export const HOME_ROUTE = "/home";
export const LOGOUT_ROUTE = "/logout";
export const ADD_STORE = "/addstore";
export const BRAND_SETTING = "/brandsetting";
export const DASHBOARD = "/dashboard";
export const PENDING_ORDERS = "/pendingorders";
export const CREATE_ADMIN = "/createadmin";
export const PACKAGES = "/packages";
export const LANDING_PAGE = "/landingpage";
export const MENU = "/menu";
export const REGISTER = "/register";
export const ORDER_DETAIL = "/order";
export const FORGOT_PASSWORD = "/forgot-password";
export const ONBOARDING = "/app/dashboard/onboarding";
