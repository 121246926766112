import React, { useEffect, useState } from "react";
import ServiceTypeItem from "./ServiceTypeItem";
import PropTypes from "prop-types";
import { CONSTANTS as C } from "config";
import { Skeleton } from "antd";
import { API } from "api";
import { useTranslation, getI18n } from "react-i18next";
import LanguageSelector from "features/multilingual/containers/languageSelector";
import { SVG_FOOD_AND_BEVARAGES } from "svg/SVGs";
import { SVG_RETAIL } from "svg/SVGs";
import useToggleLanguage from "hooks/useToggleLanguage";
import LanguageToggle from "components/LanguageToggle";
import { useSelector } from "react-redux";
const RegisterStep4 = (props) => {
  const { lang, switchLanguage } = useToggleLanguage();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const [superCategories, setSuperCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getSuperCategories = async () => {
    const result = await API.getSuperCategories();
    if (result.success === 1) setSuperCategories(result.data);
    else return [];
    setLoading(false);
  };

  useEffect(() => {
    getSuperCategories();
  }, []);

  const getKeyAgainstLanguage = () => {
    const lang = getI18n();
    return lang.language == "en" ? C.KEY_CAT_INFO_EN : C.KEY_CAT_INFO_AR;
  };
  return (
    <div className="mt-2">
      <div className="flex justify-center items-center">
        <div className="lg:w-2/3 px-2 pb-32">
          <LanguageToggle
            onClick={switchLanguage}
            selected={lang}
            authenticated={false}
          />
          <LanguageSelector handleValues={props.handleValues} />
          <div className="text-center">
            <img
              className="inline-block"
              src={process.env.REACT_APP_COMPANY_LOGO_SMALL}
              alt={process.env.REACT_APP_COMPANY_NAME}
            />
            <h2 className="font-bold text-24px">
              {t("Hi, I am Abdullah.", {
                owner:
                  currentLanguage === "EN"
                    ? process.env.REACT_APP_OWNER_NAME_EN
                    : process.env.REACT_APP_OWNER_NAME_AR,
              })}
            </h2>
            <p className="text-19px text-gray-500">
              {t(
                "I will help you set up your online ordering in 3 - 4 minutes."
              )}
            </p>
          </div>
          <div className="text-center mt-2 mb-6">
            <h2 className="font-bold text-24px">
              {t("What Service Type of website should this be?")}
            </h2>
          </div>
          <div className="">
            <div className="flex justify-start ps-10">
              <div className="w-4 h-4">
                <SVG_FOOD_AND_BEVARAGES />
              </div>
              <h3 className="font-bold text-start text-19px text-blue-600 px-8 mt-1.5">
                {t("Food & Bevarages")}
              </h3>
            </div>
            <div className="flex justify-center flex-wrap mb-10">
              <Skeleton loading={loading} active avatar />
              {superCategories &&
                superCategories.map((cat) => {
                  return cat.type == "F_AND_B" ? (
                    <ServiceTypeItem
                      key={cat._id}
                      onSubmitnextStep={props.onSubmitRegisterStep}
                      value={cat._id}
                      name={cat[getKeyAgainstLanguage()][C.KEY_CAT_TITLE]}
                    />
                  ) : (
                    ""
                  );
                })}
            </div>
            <div className="flex justify-start ps-10">
              <div className="w-10 h-10">
                <SVG_RETAIL />
              </div>
              <h3 className="font-bold text-start text-19px text-blue-600 px-2 mt-1.5">
                {t("Retail")}
              </h3>
            </div>

            <div className="flex justify-center flex-wrap">
              <Skeleton loading={loading} active avatar />
              {superCategories &&
                superCategories.map((cat) => {
                  return cat.type == "RETAIL" ? (
                    <ServiceTypeItem
                      key={cat._id}
                      onSubmitnextStep={props.onSubmitRegisterStep}
                      value={cat._id}
                      name={cat[getKeyAgainstLanguage()][C.KEY_CAT_TITLE]}
                    />
                  ) : (
                    ""
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
RegisterStep4.propTypes = {
  onSubmitRegisterStep: PropTypes.func,
  handleValues: PropTypes.func,
  stateRegister: PropTypes.object,
  switchStep: PropTypes.func,
};
export default RegisterStep4;
