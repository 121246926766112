import React from "react";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="relative sm:fixed bottom-0 bg-white w-full text-right b-0">
      <p className="text-silver-light mr-5 text-15px p-1">
        {" "}
        ©{" "}
        {t("COPYRIGHT", {
          year: new Date().getFullYear(),
          company: process.env.REACT_APP_COMPANY_NAME,
        })}
      </p>
    </div>
  );
};
export default Footer;
