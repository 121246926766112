import { errorHandler } from "api/api/errorHandler";
import { useGetStoreModifiersMutation } from "redux/modifiers/service";
import { UTILS } from "utils";

function useGetModifiersByStoreId(updateState) {
  const [getStoreModifiers] = useGetStoreModifiersMutation();

  const getModifiersByStore = async (storeId) => {
    try {
      updateState("loading", true);
      const userDetail = UTILS.getCurrentUser();
      const result = await getStoreModifiers({
        brandId: userDetail.brandId,
        storeId: storeId,
      });
      console.log({ getModifiersByStore: result?.data?.data });
      if (result?.data?.success === 1 && result?.data?.data) {
        updateState("modifiers", result.data.data);
      } else UTILS.handleErrorNotification(errorHandler(result?.data?.message));
    } catch (error) {
      UTILS.handleErrorNotification(errorHandler(error?.error));
    } finally {
      updateState("loading", false);
    }
  };
  return getModifiersByStore;
}

export default useGetModifiersByStoreId;
