import Card from "components/Card";
import React, { useState } from "react";
import { UTILS } from "utils";
import PropTypes from "prop-types";
import { SVG_Delete } from "svg/SVGs";
import { SVG_Edit } from "svg/SVGs";
import Modal from "components/Modal";
import StoreAccessRightsList from "./StoreAccessRightsList";
import { CONSTANTS as C } from "config";
import { useTranslation } from "react-i18next";
const ManageAdminUsers = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const { t } = useTranslation();
  const deleteUser = (index, user) => {};

  const editUser = (index, user) => {
    setUser(user);
    if (user.userType === C.USER_TYPE_ADMIN)
      UTILS.HANDLE_ERROR_NOTIFICATION(
        "topRight",
        "info",
        t("BRAND-ADMIN rights can not be changed")
      );
    else setOpen(true);
  };

  const UserRow = (user, index) => {
    return (
      <tr key={index} className="odd:bg-gray-100 border-b border-gray-300">
        <td className="px-3 py-4">{user.userType}</td>
        <td className="px-3 py-4">{user.email}</td>
        <td className="px-3 py-4 " dir="ltr">
          {user.phoneNumber}
        </td>
        <td className="flex px-3 py-4 cursor-pointer">
          <button className="px-1" onClick={() => editUser(index, user)}>
            {SVG_Edit()}
          </button>
          <button className="px-1" onClick={() => deleteUser(index, user)}>
            {SVG_Delete()}
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="px-5 py-4">
      <h2 className="text-19px text-blue-600">
        {t("Edit Access Levels for Users")}
      </h2>
      <div className="">
        <Modal
          open={open}
          modalWidth="w-1/2 rounded-md border border-gray-300"
          onClose={() => setOpen(false)}
          scroll={true}
        >
          <StoreAccessRightsList user={user} onClose={() => setOpen(false)} />
        </Modal>
        <Card>
          <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
            <table className="w-full text-14px">
              <thead className="border-t border-b border-gray-300">
                <tr>
                  <td className="text-gray-500 px-3 py-3 tracking-wider">
                    {t("User type")}
                  </td>
                  <td className="text-gray-500 px-3 py-3 tracking-wider">
                    {t("Email")}
                  </td>
                  <td className="text-gray-500 px-3 py-3 tracking-wider">
                    {t("Mobile number")}
                  </td>
                  <td className="text-gray-500 px-1 py-3 tracking-wider">
                    {t("Actions")}
                  </td>
                </tr>
              </thead>
              <tbody className="bg-white text-black">
                {props.users &&
                  props.users.map((item, index) => {
                    return UserRow(item, index);
                  })}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </div>
  );
};

ManageAdminUsers.propTypes = {
  users: PropTypes.array,
};

export default ManageAdminUsers;
