import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
import { UTILS } from "utils";
import { AreasAction } from "redux/actions";
import { postConfig, getConfig } from "redux/Api.Slice";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";

const areasAPi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrandInfoWithID: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_BRAND_INFO_WITH_ID,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data) {
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    updateShippingMethodStatus: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.UPDATE_SHIPPING_METHOD_STATUS,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data) {
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),

    getBuisnessAreas: builder.query({
      ...getConfig,
      query: () => ({
        url: URL.GET_BUSINESS_AREAS,
        ...getConfig.query(),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));

        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data) {
            dispatch(AreasAction.setFetchedAreas(data.data));
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    getAddedCountries: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_COUNTRY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));

        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data?.areas) {
            dispatch(AreasAction.setAddedCountries(data.data.areas));
          } else {
            errorHandler(data);
          }
        } catch (err) {
          errorHandler(err);
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    addNewCountry: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.ADD_COUNTRY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err.error));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    editCountry: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.EDIT_COUNTRY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));

          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    deleteCountry: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.DELETE_COUNTRY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    addCities: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.ADD_CITY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));

        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    editCities: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.EDIT_CITY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));

          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    deleteCities: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.DELETE_CITY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));

        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    getAddedCities: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_CITY_BASE_DELIVERY,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data.data.areas) {
            dispatch(AreasAction.setAddedCities(data.data.areas));
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
  }),
});
export const {
  useGetBrandInfoWithIDMutation,
  useGetBuisnessAreasQuery,
  useGetAddedCountriesMutation,
  useAddNewCountryMutation,
  useEditCountryMutation,
  useDeleteCountryMutation,
  useAddCitiesMutation,
  useEditCitiesMutation,
  useDeleteCitiesMutation,
  useGetAddedCitiesMutation,
  useUpdateShippingMethodStatusMutation,
} = areasAPi;
export default areasAPi;
