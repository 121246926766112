import React from "react";
import PropTypes from "prop-types";

const SectionHeading = (props) => {
  return (
    <div className="">
      <h2 className="font-bold text-14px text-indigo-700 border-b border-gray-300 py-5">
        {props.title}
      </h2>
    </div>
  );
};
SectionHeading.propTypes = {
  title: PropTypes.string,
};
export default SectionHeading;
