import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, ConfigProvider } from "antd";
import SideBar from "features/dashboard/components/SideBar";
import NavBar from "features/dashboard/components/NavBar";
import { Content } from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import TopNotificationBar from "features/dashboard/components/TopNotificationBar";
import useInterval from "use-interval";
import { CONSTANTS as C } from "config";
import VerificationMenu from "../VerificationMenu";
import { Language } from "redux/actions";
import "moment-timezone";
import LanguageToggle from "components/LanguageToggle";
import moment from "moment";
import { PkgActions } from "redux/actions";
import WhatsAppButton from "components/WhatsAppButton";
import DashboardMenu from "Layouts/sidebars/Index";
const DashboardLayout = (props) => {
  const { isLoggedIn } = useSelector((state) => state.LoginReducer);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isNotificationBarVisible, packageText } = useSelector(
    (state) => state.PackagesReducer
  );

  const [dir, setDir] = useState("");
  const [lang, setlang] = useState("en");

  const checkvalidity = async () => {
    const { text, notify } = await UTILS.checkPackageValidity();
    dispatch(PkgActions.setNotifyVisible(notify));
    dispatch(PkgActions.setPackText(text));
  };

  useInterval(() => {
    async function getData() {
      const response = await UTILS.checkPackageValidity();
      if (response.result) {
        checkvalidity();
      }
    }
    getData();
  }, C.SIXHOURS);

  useEffect(() => {
    checkvalidity();
  }, [isLoggedIn]);

  useEffect(() => {
    const obj = UTILS?.getLanguageInformation();
    if (obj?.lang && obj?.direction) {
      i18n.changeLanguage(obj?.lang);
      document
        ?.getElementsByTagName("html")[0]
        .setAttribute("dir", obj.direction);
      moment.locale(obj.lang);
      setDir(obj.direction);
      setlang(obj.lang);
      let clang = obj?.lang?.toUpperCase();
      dispatch(Language.updateLanguage(clang));
    }
  }, []);

  const topics = ["First topic", "Second topic", "Third topic"];
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
  };
  const [visible, setVisible] = useState(false);
  const Menu = (
    <DashboardMenu
      setVisible={setVisible}
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );
  const VerifyMenu = (
    <VerificationMenu
      setVisible={setVisible}
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  const getDir = (lng) => {
    return lng === "ar" ? "rtl" : "ltr";
  };
  const switchLanguage = (evt) => {
    i18n.changeLanguage(evt);
    document.getElementsByTagName("html")[0].setAttribute("dir", getDir(evt));
    setlang(evt);
    evt === "en" ? setDir("ltr") : setDir("rtl");
    UTILS.saveLanguageInformation({
      direction: getDir(evt),
      lang: evt,
    });
    let clang = evt.toUpperCase();
    dispatch(Language.updateLanguage(clang));
    moment.locale(evt);
  };
  return (
    <ConfigProvider direction={dir}>
      {props?.layoutType === C.VERIFICATION_LAYOUT ? (
        <NavBar menu={VerifyMenu} visible={visible} setVisible={setVisible} />
      ) : (
        <NavBar menu={Menu} visible={visible} setVisible={setVisible} />
      )}
      <Layout style={{ minHeight: "100vh" }}>
        {props?.layoutType === C.VERIFICATION_LAYOUT ? (
          <SideBar menu={VerifyMenu} />
        ) : (
          <SideBar menu={Menu} />
        )}

        <Layout>
          {props?.layoutType === C.DASHBOARD_LAYOUT &&
            process.env.REACT_APP_COMPANY_NAME === C.COMPANY_NAMES.RESTHERO && (
              <div
                className={
                  "py-2 border-b w-full border-border-color bg-red-500 " +
                  (isNotificationBarVisible ? "block" : "hidden")
                }
              >
                <TopNotificationBar text={packageText} />
              </div>
            )}

          <LanguageToggle
            onClick={switchLanguage}
            selected={lang}
            authenticated={true}
          />
          <Content style={{ overflow: "initial", backgroundColor: "white" }}>
            {props.children}
          </Content>
          {process.env.REACT_APP_COMPANY_NAME !== C.COMPANY_NAMES.CUSINETTE && (
            <WhatsAppButton />
          )}
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
export default DashboardLayout;
