import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { API } from "api";
import ManagePaymentLinkHeader from "../components/ManagePaymentLinkHeader";
import { errorHandler } from "api/api/errorHandler";
import AllPaymentLinksTable from "../components/AllPaymentLinksTable";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
const ViewAllPaymentLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [paymentLinks, setPaymentLinks] = useState([]);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const [searchTerm, setSearch] = useState("");

  const getAllPaymentLinks = useCallback(async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      const result = await API.GET_ALL_PAYMENT_LINKS({
        brandId: userDetail.brandId,
      });
      if (result?.success === 1 && result?.data) {
        setPaymentLinks(result.data);
      } else {
        UTILS.errorNotification(errorHandler(result));
      }
    } catch (e) {
      UTILS.errorNotification(errorHandler(e));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  }, []);

  useEffect(() => {
    getAllPaymentLinks();

    return () => {
      setPaymentLinks([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LINKS = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return paymentLinks;
    }
    return paymentLinks.filter((link) => {
      const cartIdString = link.cartId.toString();
      return cartIdString.includes(searchTerm);
    });
  }, [paymentLinks, searchTerm]);

  /**
   * sets the value of `e` to the `search` variable.
   */
  const handSearch = (e) => {
    setSearch(e);
  };

  return (
    <div>
      <ManagePaymentLinkHeader {...{ getAllPaymentLinks }} />
      <div className="flex flex-col sm:flex-row mx-4 items-center justify-between">
        <div></div>
        <div className="group relative my-4 sm:mt-0 w-full sm:w-auto">
          <svg
            fill="currentColor"
            className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            ></path>
          </svg>
          <input
            className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm min-290"
            type="text"
            placeholder={t("Search by link id")}
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => handSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="m-4">
        <Spin spinning={isLoading}>
          <AllPaymentLinksTable {...{ LINKS }} />
        </Spin>
      </div>
    </div>
  );
};
export default ViewAllPaymentLinks;
