import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import Card from "components/Card";
import PageHeading from "components/PageHeading";
import SectionHeading from "components/SectionHeading";
import SelectInput from "components/SelectInput";
import TextInput from "components/TextInput";
import { UTILS } from "utils";
import { useUpdateModifierGroupMutation } from "redux/modifiers/service";
import { errorHandler } from "api/api/errorHandler";
import { useUnLinkModifierFromGroupMutation } from "redux/modifiers/service";
import { useGetModifierGroupMutation } from "redux/modifiers/service";
import Modal from "components/Modal";
import AttacModifierWithGroup from "./components/AttacModifierWithGroup";
import { useGetModifierListMutation } from "redux/modifiers/service";
import ModifierRowWithDrag from "./components/ModifierRowWithDrag";
import BackButton from "components/BackButton";
import DeleteComponent from "components/DeleteConfirm";
import usePushBack from "hooks/usePushBack";
import { API } from "api";
const UpdateModiferGroup = () => {
  const _ = require("lodash");

  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modifiers, setmodifiers] = useState([]);
  const [brandModifiers, setbrandModifiers] = useState([]);

  const [openGroupModal, setopenGroupModal] = useState(false);
  const [getModifierGroup] = useGetModifierGroupMutation();
  const [updateModifierGroup] = useUpdateModifierGroupMutation();
  const [unLinkModifierFromGroup] = useUnLinkModifierFromGroupMutation();
  const [getModifierList] = useGetModifierListMutation();
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    item: null,
  });
  const [m, setM] = useState({
    values: {
      isSingleSelection: false,
      maximumAllowedSelection: 0,
      minimumAllowedSelection: 0,
      baseLanguage: {
        name: "",
      },
    },
    errors: {
      minimumAllowedSelection: "",
      maximumAllowedSelection: "",
      baseLanguage: {
        name: "",
      },
      isSingleSelection: "",
    },
  });

  const handleValues = (key, val) => {
    setM((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const handleText = (key, val) => {
    setM((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        baseLanguage: {
          [key]: val,
        },
        translations: {
          en: {
            name: val,
          },
          ar: {
            name: val,
          },
        },
      },
      errors: {
        ...prevState.errors,
        baseLanguage: {
          [key]: "",
        },
      },
    }));
  };

  const handleSelection = (key, val) => {
    m.values.maximumAllowedSelection = 1;

    setM((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val === "single" ? true : false,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const seterrors = (obj) => {
    setM((prevState) => ({
      ...prevState,
      errors: obj,
    }));
  };

  const validateInputs = () => {
    let isValid = true;
    let obj = {
      baseLanguage: {
        name: "",
      },
      isSingleSelection: "",
    };

    if (
      !m?.values?.baseLanguage?.name ||
      UTILS.hasWhiteSpace(m.values.baseLanguage.name)
    ) {
      isValid = false;
      obj.baseLanguage.name = t("Enter Section Name");
      seterrors(obj);
      return { isValid, obj };
    }

    if (
      !m.values?.maximumAllowedSelection ||
      UTILS.hasWhiteSpace(m.values.maximumAllowedSelection)
    ) {
      isValid = false;
      obj.maximumAllowedSelection = t("Enter Maximum Allowed Sections");
      seterrors(obj);
      return { isValid, obj };
    }
    if (m?.values?.maximumAllowedSelection <= 0) {
      isValid = false;
      obj.maximumAllowedSelection = t("Maximum selection value cannot be zero");
      seterrors(obj);
      return { isValid, obj };
    }
    if (
      m?.values?.minimumAllowedSelection > m?.values?.maximumAllowedSelection
    ) {
      isValid = false;
      obj.minimumAllowedSelection = t(
        "Minimum selection value must be than less or equal to maximum selection value"
      );
      seterrors(obj);
      return { isValid, obj };
    }
    if (m.errors.isSingleSelection) {
      if (m.values.minimumAllowedSelection > 1) {
        isValid = false;
        obj.minimumAllowedSelection = t(
          "For single selection, minimum allowed selection cannot be greater than 1"
        );
        seterrors(obj);
        return { isValid, obj };
      }
      seterrors(obj);
      return { isValid, obj };
    }

    return {
      isValid,
      obj,
    };
  };

  const addNewModifierGroup = async () => {
    const { isValid } = validateInputs();
    if (!isValid) return;
    try {
      const userDetail = UTILS.getCurrentUser();
      const data = await updateModifierGroup({
        brandId: userDetail.brandId,
        modifierGroupId: history?.location?.state?.modifier?._id,
        ...m.values,
      });
      if (data?.data?.success === 1) {
        UTILS.successNotification(
          data?.data?.message ?? t("Added Successfully")
        );
        history.push("/app/dashboard/modifier-group");
      } else {
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    }
  };

  const updatePrice = (key, val) => {
    const value = UTILS.NumberConversion(val);
    let Num = Number(value);
    handleValues(key, Num);
  };

  //attached modifiers
  const getAttachedModifers = async () => {
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const { data } = await getModifierGroup({
        brandId: userDetail.brandId,
        modifierGroupId: history?.location?.state?.modifier?._id,
      });
      if (data?.success === 1) {
        setmodifiers(data?.data?.modifiers);
        setM((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            ...data.data,
          },
        }));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (history?.location.state?.modifier?._id) {
        const attachedModifiersPromise = getAttachedModifers();
        const allBrandModifiersPromise = getAllBrandModifers();
        await Promise.all([attachedModifiersPromise, allBrandModifiersPromise]);
      }
    }

    fetchData();
  }, [history?.location?.state?.modifier?._id]);

  const getAllBrandModifers = async () => {
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const { data } = await getModifierList({
        brandId: userDetail?.brandId,
      });
      if (data?.success === 1) {
        setbrandModifiers(data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const unlinkModFromGroup = async () => {
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const data = await unLinkModifierFromGroup({
        brandId: userDetail.brandId,
        modifierId: deleteModal.item._id,
        modifierGroupId: history.location?.state?.modifier?._id,
      });
      if (data?.data?.success === 1) {
        UTILS.successNotification(
          data?.data?.message ?? t("Unlinked Successfully")
        );
        getAttachedModifers();
      } else {
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      handleCancel();
      setLoading(false);
    }
  };

  const MemizedModifiers = useMemo(() => {
    const attachedModifiersLookup = _.keyBy(
      modifiers,
      (item) => item?.modifierId?._id
    );

    const unattachedModifiers = brandModifiers?.filter(
      (item) => !attachedModifiersLookup[item?._id]
    );
    return unattachedModifiers;
  }, [modifiers, brandModifiers, history.location?.state?.modifier?._id]);

  const sortModiferGroup = async (group) => {
    try {
      let selected = modifiers[group.oldIndex];
      const userDetail = UTILS.getCurrentUser();
      setLoading(true);
      const result = await API.sortModifierOption({
        brandId: userDetail.brandId,
        modifierGroupId: history?.location?.state?.modifier?._id,
        modifierId: selected?.modifierId?._id,
        index: group.newIndex,
      });
      if (result?.success === 1) {
        UTILS.successNotification(result?.message);
      } else {
        UTILS.errorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      getAttachedModifers();
      setLoading(false);
    }
  };

  const onClickDeleteButton = (row) => {
    if (row) {
      setDeleteModal({ isOpen: true, item: row });
    }
  };

  const handleCancel = () => setDeleteModal({ isOpen: false, item: null });

  return (
    <div>
      <Spin spinning={loading}>
        <div className="px-4">
          <BackButton />
        </div>
        <Modal
          scroll={false}
          open={openGroupModal}
          modalWidth="w-full md:w-3/4 rounded-md border border-gray-300"
          onClose={() => setopenGroupModal(false)}
        >
          <AttacModifierWithGroup
            MemizedModifiers={MemizedModifiers}
            item={m}
            onClose={() => setopenGroupModal(false)}
            getAttachedModifers={getAttachedModifers}
          />
        </Modal>

        <Modal
          open={deleteModal.isOpen}
          modalWidth="rounded-md border border-gray-300 top-0"
          onClose={handleCancel}
        >
          <DeleteComponent
            loading={loading}
            onCancel={handleCancel}
            onDelete={unlinkModFromGroup}
            title={deleteModal.item?.baseLanguage?.name}
            Heading={t("will be permanently unlinked")}
          />
        </Modal>

        <div className="">
          <PageHeading heading={t("Create Modifier")} />
          <div className="px-3 py-3">
            <Card>
              <div className="flex flex-wrap justify-between py-4">
                <div className="w-full md:w-1/2 px-6">
                  <SectionHeading title={t("Modifier Detail")} />
                  <div className="py-3">
                    <TextInput
                      label={t("Name")}
                      value={m.values.baseLanguage?.name}
                      handleValues={handleText}
                      keyVal="name"
                      mandatory={true}
                      error={m.errors.baseLanguage?.name}
                    />
                  </div>
                  <div className="pb-3">
                    <SelectInput
                      mandatory={true}
                      label={t("Type Selection")}
                      keyVal="isSingleSelection"
                      handleChange={handleSelection}
                      options={[
                        { value: t("single"), label: t("Single Selection") },
                        {
                          value: t("multiple"),
                          label: t("Multiple Selection"),
                        },
                      ]}
                      value={
                        m.values.isSingleSelection ? t("single") : t("multiple")
                      }
                      styleClass="font-montserrat text-input-color text-16px mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <span className="text-red-600">
                      {m.errors.isSingleSelection}{" "}
                    </span>
                  </div>
                  <div className="pb-3">
                    <TextInput
                      label={t("Maximum Selections required by Customer?")}
                      value={
                        m?.values?.isSingleSelection
                          ? 1
                          : m.values.maximumAllowedSelection
                      }
                      disabled={m?.values?.isSingleSelection}
                      keyVal="maximumAllowedSelection"
                      handleValues={updatePrice}
                      mandatory={false}
                      error={m.errors.maximumAllowedSelection}
                    />
                  </div>

                  <div className="pb-3">
                    <TextInput
                      label={t("Minimum Selections required by Customer")}
                      value={m.values.minimumAllowedSelection}
                      keyVal="minimumAllowedSelection"
                      handleValues={updatePrice}
                      mandatory={false}
                      error={m.errors?.minimumAllowedSelection}
                    />
                  </div>
                  <div className="my-4 text-ckenter space-x-2 md:block">
                    <Button
                      type="default"
                      size="large"
                      className="w-24 me-1"
                      onClick={() => history.goBack()}
                    >
                      {t("Back")}
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      className="w-24"
                      onClick={() => addNewModifierGroup()}
                    >
                      {t("Update")}
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="px-4">
          <Card>
            <div className="flex justify-between flex-wrap h-full">
              <div className="w-full px-2 md:px-4 flex-wrap h-full">
                <div className="py-2 flex items-center justify-between flex-wrap border-b border-gray-200">
                  <div className="">
                    <h2 className="text-19px text-blue-600 mb-2 md:mb-0">
                      {t("Attached Modifiers")}
                    </h2>
                  </div>
                  <div className="flex justify-center w-full sm:w-auto">
                    <Button
                      type="primary"
                      size="large"
                      className=""
                      onClick={() => setopenGroupModal(true)}
                    >
                      {t("Attach New Modifier")}
                    </Button>
                  </div>
                </div>

                <div className="overflow-x-auto">
                  <ModifierRowWithDrag
                    modifiers={modifiers}
                    reOrderCategory={sortModiferGroup}
                    unlinkModFromGroup={onClickDeleteButton}
                    setopenGroupModal={setopenGroupModal}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Spin>
    </div>
  );
};

function UpdateModifiersGroupContainer() {
  const router = useHistory();
  usePushBack(
    "/app/dashboard/modifier-group",
    router?.location?.state?.modifier?._id
  );
  return <UpdateModiferGroup />;
}

export default UpdateModifiersGroupContainer;
