import React, { useEffect } from "react";
import { Spin, Tabs } from "antd";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import DZSupport from "logistic-integrations/delivery-zone/components/DZSupport";
import ClaimInfo from "logistic-integrations/delivery-zone/components/ClaimInfo";
const { TabPane } = Tabs;

function DZOrderInfo({ open, setOpen, order, getDZOrderStatus, DZOrder }) {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const fetchLatestClaimInfo = async () => {
    await getDZOrderStatus(order);
  };

  return (
    <Modal
      open={open}
      modalWidth=" min-h-300 rounded-md border border-gray-300 top-0 w-full lg:w-2/3"
      onClose={() => {
        setOpen(false);
      }}
    >
      <Spin spinning={isLoading}>
        <div className="p-5  bg-white">
          <div className="flex justify-end mb-4">
            <span onClick={() => setOpen(false)} className="cursor-pointer">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-6 h-6 bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
          <div className="">
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab={t("ORDER DETAIL")} key="1">
                <ClaimInfo {...{ order, DZOrder, fetchLatestClaimInfo }} />
              </TabPane>
              <TabPane tab={t("DZ SUPPORT CENTER")} key="2">
                <DZSupport />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default DZOrderInfo;
