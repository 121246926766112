import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { UTILS } from "utils";
import { API } from "api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import RadioButton from "components/RadioButtonWithRow";

const Discount = () => {
  const zone = moment.tz.guess();
  moment.tz.setDefault(zone);
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);

  const history = useHistory();
  const { t } = useTranslation();
  const [searchTerm, setSearch] = useState("");
  const addDiscount = () => {
    history.push("/app/dashboard/adddiscount");
  };
  const fetchAllDiscounts = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_ALL_COUPONS({
      token: userDetail.token,
      brandId: userDetail.brandId,
    });
    if (result?.success == 1) {
      setDiscounts(result.discount);
    } else UTILS.errorNotification("Please try again later");
    setLoading(false);
  };

  useEffect(() => {
    fetchAllDiscounts();
  }, []);
  const formatTime = (time) => {
    return moment.unix(time).locale("en").tz(moment.tz.guess()).format();
  };
  const handlePlayPause = async (key, val, row) => {
    setLoading(true);
    row["isActive"] = val;
    row["startDate"] = formatTime(row["startDate"]);
    row["endDate"] = formatTime(row["endDate"]);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.UPDATE_COUPONS({
      token: userDetail.token,
      data: row,
    });
    if (result?.success == 1) {
      UTILS.successNotification(t("Discount Updated successfully"));
      fetchAllDiscounts();
    }
  };
  const renderPlayPauseComponent = (value, rowData, index) => {
    return (
      <div key={index}>
        <RadioButton
          label=""
          keyVal={index}
          value={rowData?.isActive}
          row={rowData}
          handleValues={handlePlayPause}
        />
      </div>
    );
  };
  const editCouponPage = (row) => {
    history.push({
      pathname: "/app/dashboard/updatediscount",
      state: {
        discount: row,
      },
    });
  };
  const reanderTtile = (value, row, index) => {
    return (
      <div>
        <strong className="">
          {row?.discountCode}
          {/* <a
            to="#"
            className="inline-block px-2"
            onClick={() => editCouponPage(row)}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#292929"
              className="w-4 h-4"
            >
              <path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 004.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 00-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 01-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 014.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"></path>
            </svg>
          </a> */}
        </strong>
        <p className="m-0">{value}</p>
      </div>
    );
  };
  const editCoupon = (value, row, index) => {
    return (
      <div>
        <strong className="">
          <a
            to="#"
            className="inline-block px-2"
            onClick={() => editCouponPage(row)}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#292929"
              className="w-4 h-4"
            >
              <path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 004.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 00-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 01-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 014.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"></path>
            </svg>
          </a>
        </strong>
      </div>
    );
  };
  const renderType = (value, row) => {
    return (
      <div className="text-center">
        <span>{t(value)}</span>
        {row.isApplicableOnAllItems ? (
          <p className="">{t("Applies to all items")}</p>
        ) : (
          <p className="">{t("Applies to selected items")}</p>
        )}
      </div>
    );
  };
  const reanderAvailablity = (value, row) => {
    return (
      <div>
        <span>{moment.unix(row.startDate).format("DD-MM-YYYY")}</span> -{" "}
        <span>{moment.unix(row.endDate).format("DD-MM-YYYY")}</span>
      </div>
    );
  };
  const deleteButton = () => {
    return (
      <div>
        <svg
          data-v-1826c20e=""
          viewBox="0 0 20 20"
          fill="currentColor"
          xmlns="https://www.w3.org/2000/svg"
          className="w-5 h-5 inline-block  mx-2 cursor-pointer hover:text-red-500"
        >
          <path
            fillRule="evenodd"
            d="M2.451 3.989A.75.75 0 013 3.75h14a.75.75 0 01.748.803l-.867 12.143a2.75 2.75 0 01-2.743 2.554H5.862a2.75 2.75 0 01-2.743-2.554L2.252 4.553a.75.75 0 01.2-.564zM3.805 5.25l.81 11.34a1.25 1.25 0 001.247 1.16h8.276c.656 0 1.2-.507 1.247-1.16l.81-11.34H3.805z"
            clipRule="evenodd"
          ></path>
          <path
            fillRule="evenodd"
            d="M1.25 4.5A.75.75 0 012 3.75h16a.75.75 0 010 1.5H2a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          ></path>
          <path
            fillRule="evenodd"
            d="M6.947 1.804a1.893 1.893 0 011.338-.554h3.429a1.893 1.893 0 011.893 1.893v1.143a.75.75 0 01-.75.75H7.143a.75.75 0 01-.75-.75V3.143c0-.502.199-.984.554-1.339zm1.338.946a.393.393 0 00-.392.393v.393h4.214v-.393a.393.393 0 00-.393-.393H8.285zm.001 4.786a.75.75 0 01.75.75V14a.75.75 0 01-1.5 0V8.286a.75.75 0 01.75-.75zm3.428 0a.75.75 0 01.75.75V14a.75.75 0 01-1.5 0V8.286a.75.75 0 01.75-.75z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
    );
  };
  const columns = [
    {
      title: "",
      dataIndex: "isActive",
      align: "center",

      render: renderPlayPauseComponent,
    },
    {
      title: t("Discount"),
      dataIndex: "discountName",
      align: "center",
      key: "discountName",
      render: reanderTtile,
    },
    {
      title: t("Application"),
      dataIndex: "discountApplication",
      align: "center",
      key: "discountApplication",
      render: (value) => {
        return (
          <div className="flex items-center justify-center">{t(value)}</div>
        );
      },
    },
    {
      title: t("Type"),
      dataIndex: "discountType",
      key: "discountType",
      align: "center",
      render: renderType,
    },
    {
      title: t("Amount"),
      dataIndex: "discountAmount",
      key: "discountAmount",
      align: "center",
      render: (value) => {
        return <div className="flex items-center justify-center">{value}</div>;
      },
    },
    {
      title: t("Min. Basket"),
      dataIndex: "minOrderValue",
      key: "minOrderValue",
      align: "center",
      render: (value) => {
        return <div className="flex items-center justify-center">{value}</div>;
      },
    },
    {
      title: t("Max. Discount"),
      dataIndex: "maximumDiscountValue",
      key: "maximumDiscountValue",
      align: "center",
      render: (value) => {
        return <div className="flex items-center justify-center">{value}</div>;
      },
    },
    {
      title: t("Availability"),
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      render: reanderAvailablity,
    },

    {
      title: t("Allowed Usage"),
      dataIndex: "maxUsage",
      key: "maxUsage",
      align: "center",
      render: (value) => {
        return <div className="flex items-center justify-center">{value}</div>;
      },
    },
    {
      title: t("Detail"),
      dataIndex: "action",
      key: "action",
      align: "center",
      render: editCoupon,
    },
  ];
  const Items = React.useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return discounts;
    }

    let searchTermLower = searchTerm.toLowerCase().trim();
    let d = discounts.filter((item) => {
      return (
        item.discountName?.toLowerCase().includes(searchTermLower) ||
        item.discountCode?.toLowerCase().includes(searchTermLower)
      );
    });
    return d;
  }, [discounts, searchTerm]);

  const handSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div className="p-4">
      <Spin spinning={loading}>
        <div className="flex justify-between flex-col sm:flex-row mb-5">
          <div>
            <h1 className="text-blue-600 text-19px pt-4">
              {t("Add a discount")}
            </h1>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="group relative my-4 sm:mt-0">
              <svg
                fill="currentColor"
                className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                ></path>
              </svg>
              <input
                className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm min-290"
                type="text"
                placeholder={t("search discount by name")}
                value={searchTerm}
                autoComplete="off"
                onChange={handSearch}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={addDiscount}
                type="button"
                className="border border-blue-600  px-4 text-blue-600 mx-2 h-10"
              >
                <span className="px-1 align-middle">{t("Add discount")} </span>
              </button>
            </div>
          </div>
        </div>

        <Card styleClasses="px-2 py-2">
          <div className="overflow-hidden overflow-x-auto lg:overflow-hidden">
            <Table
              locale={{ emptyText: t("No Data") }}
              columns={columns}
              dataSource={Items}
              pagination={{ position: ["bottomCenter"] }}
              size="small"
              rowKey={(record) => record._id}
            />
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default Discount;
