import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Card, Table } from "antd";
import { useTranslation } from "react-i18next";
import SearchInput from "components/SearchInput";
const ModifierGroupList = ({ groups, deleteModifierGroup }) => {
  const router = useHistory();
  const { t } = useTranslation();

  const updateM = (rowData) => {
    router.push({
      pathname: "/app/dashboard/update-modifier-group",
      state: {
        modifier: rowData,
      },
    });
  };

  const renderActions = (value, rowData, index) => {
    return (
      <div className="flex gap-4 px-3 py-2 whitespace-nowrap text-center ">
        <button
          className="border border-blue-600  px-4 text-blue-600 h-8"
          onClick={() => updateM(rowData)}
        >
          {t("View")}
        </button>
        <button
          className="border border-red-600 px-4 text-red-600 h-8"
          onClick={() => deleteModifierGroup(rowData)}
        >
          {t("Delete")}
        </button>
      </div>
    );
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "baseLanguage",
      align: "initial",
      render: (value, rowData, index) => {
        return (
          <h3 className="text-16px font-bold truncate-line-4 min-230">
            {value?.name}
          </h3>
        );
      },
    },
    {
      title: t("Selection Type"),
      dataIndex: "isSingleSelection",
      align: "initial",
      width: "10%",
      render: (value, rowData, index) => {
        return value ? t("Single") : t("Multiple");
      },
    },
    {
      title: t("Max Allowed Options"),
      width: "10%",
      dataIndex: "maximumAllowedSelection",
      align: "initial",
    },
    {
      title: t("Required"),
      dataIndex: "isRequired",
      width: "10%",
      align: "initial",
      render: (value, rowData, index) => {
        return value ? t("Yes") : t("No");
      },
    },
    {
      title: t("Actions"),
      dataIndex: "actions",
      width: "10%",
      align: "initial",
      render: renderActions,
    },
  ];

  const createModifier = () => {
    router.push({
      pathname: "/app/dashboard/create-modifier-group",
    });
  };

  const [searchTerm, setSearch] = useState(null);

  const FilteredData = useMemo(() => {
    let searchTermLower = searchTerm?.toLowerCase()?.trim();
    if (searchTerm === "" || searchTerm === null) {
      return groups;
    }
    return groups?.filter((group) =>
      group?.baseLanguage?.name?.toLowerCase()?.includes(searchTermLower)
    );
  }, [groups, searchTerm]);

  return (
    <div className="w-full my-2">
      <div className="flex justify-between flex-col sm:flex-row mb-5">
        <div>
          <h1 className="text-gray-900 text-24px md:text-30px font-medium">
            {t("Modifier Groups")}
          </h1>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="group relative my-4 sm:mt-0">
            <SearchInput
              placeholder={t("Search by name")}
              searchTerm={searchTerm}
              setSearchTerm={setSearch}
            />
          </div>
          <div className="flex justify-center sm:justify-end">
            <Button
              type="primary"
              size="large"
              className=" ms-2 rounded-md"
              onClick={() => createModifier()}
            >
              {t("Create Modifier Group")}
            </Button>
          </div>
        </div>
      </div>
      <Card>
        <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
          <Table
            locale={{ emptyText: t("No Data") }}
            columns={columns}
            dataSource={FilteredData}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 50,
              defaultPageSize: 50,
            }}
            size="small"
          />
        </div>
      </Card>
    </div>
  );
};
export default ModifierGroupList;
ModifierGroupList.propTypes = {
  item: PropTypes.object,
};
