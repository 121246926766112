import DashboardLayout from "../../../Layouts/dashboardLayout/Layout";
import React from "react";

const Index = () => {
  return (
    <div className="">
      <DashboardLayout />
    </div>
  );
};
export default Index;
