import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;
function SelectBox({ placeholder, options, value, onChange, className }) {
  const { t } = useTranslation();
  return (
    <Select
      placeholder={placeholder}
      className={`me-4 ${className}`}
      style={{ margin: "2px 4px", minWidth: "151px" }}
      value={value}
      onChange={(value) => onChange(value)}
    >
      {options.map((option) => (
        <Option key={option} value={option}>
          {t(option)}
        </Option>
      ))}
    </Select>
  );
}

export default SelectBox;
