import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const TopNotificationBar = ({ text }) => {
  const { t } = useTranslation();
  const router = useHistory();
  const goToPackages = () => {
    router.push("/app/dashboard/subscription");
  };

  return (
    <header className="">
      <div className="flex flex-col md:flex-row w-full justify-center items-center text-center">
        <h3 className="font-bold text-14px text-white mt-0 md:mt-2 mb-2">
          {t(text)}
        </h3>
        <button
          className="px-4 mx-4 bg-white text-theme-red text-12px h-8 rounded"
          onClick={() => goToPackages()}
        >
          {t("Subscribe now")}
        </button>
      </div>
    </header>
  );
};
export default TopNotificationBar;
