import TextInput from "components/TextInput";
import React, { useState } from "react";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Spin } from "antd";
import {
  useEditCitiesMutation,
  useGetAddedCitiesMutation,
} from "redux/shipping-methods/shipping-service";
import ChooseTime from "../common/ChooseTime";
import PriceInput from "components/useForm/PriceInput";

const UpdateCity = ({ onClose, selectedCities }) => {
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const { t } = useTranslation();
  const [editCities] = useEditCitiesMutation();
  const [getAddedCities] = useGetAddedCitiesMutation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  const [state, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    deliveryTime: "",
  });
  const handleValues = (key, val) => {
    setState({ ...state, [key]: val });
  };
  const [time, setTime] = useState({
    Hours: 0,
    Minutes: 0,
    Days: 0,
  });

  const edit_cities = UTILS.withTryCatch(async () => {
    let delTime = time.Days * 1440 + time.Hours * 60 + time.Minutes;
    const userDetail = UTILS.getCurrentUser();
    let objectArray = [];
    selectedCities.forEach((city) => {
      objectArray.push({
        cityID: city.cityID._id,
        countryID: city.countryID._id,
        deliveryFee: +Number(state.deliveryFee),
        deliveryTime: +Number(delTime),
        minOrder: +Number(state.minOrder),
        _id: city._id,
      });
    });

    const obj = {
      brandId: userDetail.brandId,
      storeId: selectedStore.value,
      language: currentLanguage,
      areas: objectArray,
    };
    const getObj = {
      brandId: userDetail.brandId,
      storeId: selectedStore.value,
      language: currentLanguage,
    };
    await editCities(obj).unwrap();
    await getAddedCities(getObj).unwrap();
    onClose();
  });

  let isValid = () => {
    if (
      isLoading ||
      state.minOrder === "" ||
      state.deliveryFee === "" ||
      selectedCities?.length <= 0 ||
      (time?.Days === 0 && time?.Hours === 0 && time?.Minutes === 0)
    )
      return false;
    return true;
  };
  const handleInputValue = (key, val) => {
    const value = UTILS.NumberConversion(val);
    handleValues(key, value);
  };
  return (
    <div className="w-full p-5 mx-auto my-auto rounded-xl shadow-lg  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            <div className="flex justify-between">
              <div>
                <h2 className="text-19px font-bold mb-3 py-1 px-2">
                  {t("Edit")} {`${selectedCities?.length ?? ""} `}
                  {t("Countries")}
                </h2>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="px-2 w-full sm:w-1/2">
                <TextInput
                  label={t("Min. Basket Value")}
                  value={state.minOrder.trim()}
                  keyVal={"minOrder"}
                  handleValues={handleInputValue}
                  mandatory={true}
                />
              </div>
              <div className="px-2 w-full sm:w-1/2">
                <PriceInput
                  label={t("Delivery Fee")}
                  value={state.deliveryFee.trim()}
                  keyVal={"deliveryFee"}
                  handleValues={handleValues}
                  mandatory={true}
                />
              </div>
            </div>
            <div className="flex flex-wrap px-2">
              <ChooseTime {...{ time, setTime }} />
            </div>
          </div>
          <div className="p-3 mt-2 flex justify-end space-x-4 md:block">
            <Button type="default" size="large" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={edit_cities}
              disabled={!isValid()}
            >
              {t("Update Cities")}
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default UpdateCity;
