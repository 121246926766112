import PageHeading from "components/PageHeading";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useAllStores from "hooks/useCurrentUserStores";
import StoreModifierList from "../Components/StoreModifierList";
import useGetModifiersByStoreId from "../useGetModifiersByStoreId";
import usePlayPauseModifier from "../usePlayPasuseModifier";
import PauseModalContainer from "../Components/PauseModal";
const PauseModifiers = () => {
  const { t } = useTranslation();
  const { stores, loading } = useAllStores();

  const [state, setState] = useState({
    currentStoreId: null,
    modifiers: [],
    loading: false,
  });

  const updateState = (key, val) =>
    setState((state) => ({ ...state, [key]: val }));

  const [PauseModel, setPauseModel] = useState({
    open: false,
    modifier: null,
    deafaultOption: {
      value: 6,
      unit: "hours",
      duration: "6_HOURS",
    },
  });

  const setPauseModalState = (key, val) => {
    setPauseModel((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const getModifiersByStore = useGetModifiersByStoreId(updateState);

  const { handlePlayPause, handleplayPauseModifier } = usePlayPauseModifier({
    setPauseModalState,
    updateState,
    getModifiersByStore,
    state,
  });

  const handleChange = (key, val) => {
    if (val !== "null" && val) {
      getModifiersByStore(val);
      updateState("currentStoreId", val);
    }
  };
  const isLoading = state.loading || loading;
  const closeModal = () => {
    setPauseModalState("modifier", null);
    setPauseModalState("deafaultOption", {
      value: 6,
      unit: "hours",
      duration: "6_HOURS",
    });
    setPauseModalState("open", false);
  };
  console.log("PauseModifiers", PauseModel);
  return (
    <>
      <PageHeading heading={t("PauseModifiers")} />
      <StoreModifierList
        {...{
          stores,
          handleChange,
          state,
          handlePlayPause,
          isLoading,
        }}
      />

      <PauseModalContainer
        heading={t("Pause modifier")}
        title1={PauseModel?.modifier?.baseLanguage?.name}
        title2="Select a time frame where item will be paused. After this time, it'll be automatically resumed"
        isLoading={isLoading}
        {...{ PauseModel, closeModal, setPauseModalState }}
        playPauseItem={handleplayPauseModifier}
      />
    </>
  );
};
export default PauseModifiers;
