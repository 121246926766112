import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { UTILS } from "utils";
const LanguageSelector = (props) => {
  const [objLang, setObjLang] = useState({
    direction: "ltr",
    lang: "en",
  });
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="text-right w-full">
      <p className="text-14px text-blue-500">
        {t("Select language of your ordering website")}
      </p>
      <Select
        defaultValue={"en"}
        style={{ width: 220 }}
        onChange={(value) => {
          //   let obj = { ...objLang };
          //   obj.lang = value;
          //   obj.direction = value === "en" ? "ltr" : "rtl";
          //   document
          //     .getElementsByTagName("html")[0]
          //     .setAttribute("dir", obj.direction);
          //   changeLanguage(value);
          props.handleValues("baseLanguage", value);
        }}
      >
        <Option value="en">English</Option>
        <Option value="ar">عربي</Option>
      </Select>
    </div>
  );
};
export default LanguageSelector;

LanguageSelector.propTypes = {
  handleValues: PropTypes.func,
};
