import { PAYMENT_GATWAYS, PAYMENT_TYPE_ONLINE } from "config/Constants";

export const getOrderIsRefundable = (order) => {
  return Boolean(
    (!order.refund ||
      order?.refund?.isRequired === null ||
      order?.refund?.isRequired ||
      order?.refund?.isRequired === false) &&
      order?.paymentType === PAYMENT_TYPE_ONLINE &&
      order?.orderCheckoutOnlinePaymentMetadata?.data?.paymentGateway ===
        PAYMENT_GATWAYS.STRIPE
  );
};
