import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import { API } from "api";
import AddForm from "../components/DiscountForm";
import moment from "moment";
import useAllStores from "hooks/useCurrentUserStores";
import useAllItems from "hooks/useCurrentUserItems";

const AddDiscount = () => {
  const { t } = useTranslation();
  const { stores, loading } = useAllStores();
  const { items, Itemloader } = useAllItems();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const zone = moment.tz.guess();
  moment.tz.setDefault(zone);
  const [state, setState] = useState({
    values: {
      discountName: "",
      discountCode: "",
      discountAmount: 0,
      minOrderValue: 0,
      maximumDiscountValue: 50,
      startDate: "",
      endDate: "",
      isoDate: "",
      isoEndDate: "",
      perCustomerUse: 10,
      maxUsage: 100,
      serviceType: [
        {
          label: t("DELIVERY"),
          serviceName: "DELIVERY",
        },
        {
          label: t("PICKUP"),
          serviceName: "PICKUP",
        },
        {
          label: t("DINEIN"),
          serviceName: "DINE IN",
        },
      ],
      applicationType: C.COUPUN_APPLICATION_TYPE.MANUAL,
      discountType: C.PERCENTAGE,
      itemSelectionType: C.ALL,
      customerSegmentationType: "all",
      outletSelectionType: C.ALL,
      selectedOutlets: [],
      selectedItems: [],
    },
    errors: {
      discountName: "",
      discountCode: "",
      discountAmount: "",
      minOrderValue: "",
      maximumDiscountValue: "",
      perCustomerUse: "",
      maxUsage: "",
      startDate: "",
      endDate: "",
      items: [],
    },
  });

  const addDiscount = async () => {
    const { isFormValid } = UTILS.validateCouponsInputs(state);
    if (!isFormValid) return;
    setLoading(true);
    let discountObj = UTILS.getDiscountObj(state);
    const result = await API.CREATE_DISCOUNT_COUPON({
      data: discountObj,
    });
    if (result?.success === 1) {
      UTILS.successNotification(t("Discount created successfully"));
      history.push("/app/dashboard/discount");
    } else {
      UTILS.errorNotification(
        result?.message || result?.error || t("Please try again later")
      );
    }
    setLoading(false);
  };

  const numExecutions = useRef(0);
  const NoStoreCreatedToast = () => {
    numExecutions.current += 1;
    if (numExecutions.current <= 1)
      UTILS.errorNotification(
        t("It appears you have not created your store yet, so please do so")
      );
  };
  const NoItemCreatedToast = () => {
    numExecutions.current += 1;
    if (numExecutions.current <= 1)
      UTILS.errorNotification(
        t("It appears you have not created any product  yet, please do so")
      );
  };

  useEffect(() => {
    if (!Itemloader && !loading) {
      if (stores?.length <= 0) {
        NoStoreCreatedToast();
        return;
      }
      if (items?.length <= 0) {
        NoItemCreatedToast();
        return;
      }
    }
  }, [stores, items, loading, Itemloader]);

  return (
    <div className="p-4">
      <Spin spinning={isLoading}>
        <div className="flex w-full flex-col sm:flex-row">
          <AddForm
            addDiscount={addDiscount}
            text={"Add"}
            setState={setState}
            state={state}
          />
          {/* <DiscountPreview /> */}
        </div>
      </Spin>
    </div>
  );
};

export default AddDiscount;
