import React, { createContext, useContext, useReducer } from "react";

// Create a context
const MenuContext = createContext();

// Define action types
const SET_STATE = "SET_STATE";

// Reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return { ...state, [action.payload.key]: action.payload.value };
    case "RESET_STATE":
      return {
        ...state,
        items: [],
        categories: [],
        itemLinkedStores: [{}],
        open: false,
        dialogContentId: -1,
        categoryToUpdateDelete: "",
        indexToUpdateDelete: -1,
        random: 0,
        newMenuName: null,
        menuId: null,
        isLinkMenuWithStoresModalOpen: false,
        linkedStores: [],
        isCreateNewMenuModalOpen: false,
        newSelectedStores: [],
      };
    default:
      return state;
  }
};

// Create a provider component
export const MenuContextProvider = ({ children }) => {
  const [menuState, dispatch] = useReducer(appReducer, {
    items: [],
    categories: [],
    itemLinkedStores: [{}],
    open: false,
    dialogContentId: -1,
    categoryToUpdateDelete: "",
    indexToUpdateDelete: -1,
    random: 0,
    newMenuName: null,
    menuId: null,
    isLinkMenuWithStoresModalOpen: false,
    linkedStores: [],
    isCreateNewMenuModalOpen: false,
    addedMenus: [],
    newSelectedStores: [],
    menuInfo: {},
    cloneMenuModal: false,
    editMenumodal: false,
  });

  const updateState = (key, value) => {
    dispatch({
      type: SET_STATE,
      payload: { key, value },
    });
  };
  const resetState = () => {
    dispatch({
      type: "RESET_STATE",
    });
  };

  return (
    <MenuContext.Provider value={{ menuState, updateState, resetState }}>
      {children}
    </MenuContext.Provider>
  );
};

// Create a custom hook for using the context
export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};
