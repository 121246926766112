import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
import { errorHandler } from "api/api/errorHandler";
import { UTILS } from "utils";
import { LoaderAction } from "redux/actions";
const postConfig = {
  query: (body) => ({
    method: "POST",
    body: body,
  }),
  transformResponse: (response) => {
    return response;
  },
};

const MenuService = apiSlice.injectEndpoints({
  tagTypes: ["Post", "User"],
  endpoints: (builder) => ({
    getBrandMenus: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_BRAND_MENUS,
        ...postConfig.query(body),
      }),
    }),
    addNewMenu: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CREATE_NEW_MENU,
        ...postConfig.query(body),
      }),
    }),
    getMenuInfo: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_MENU_INFO,
        ...postConfig.query(body),
      }),
    }),
    getBrandItems: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_BRAND_ITEMS,
        ...postConfig.query(body),
      }),
    }),
    getMenuCatInfo: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_MENU_CAT_INFO,
        ...postConfig.query(body),
      }),
    }),
    linkStoreWithMenu: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.LINK_STORES_WITH_MENU,
        ...postConfig.query(body),
      }),
    }),
    linkCategoryWithMenu: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.LINK_CATEGORY_WITH_MENU,
        ...postConfig.query(body),
      }),
    }),

    sortCategoryInMenu: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.SORT_CATEGORY_IN_MENU,
        ...postConfig.query(body),
      }),
    }),

    toggLeStoreInMenuCategoryITem: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.TOGGLE_STORE_IN_MENU,
        ...postConfig.query(body),
      }),
    }),
    getStoreItems: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_STORE_ITEMS,
        ...postConfig.query(body),
      }),
    }),
  }),
});
export const {
  useGetBrandMenusMutation,
  useAddNewMenuMutation,
  useLinkStoreWithMenuMutation,
  useLinkCategoryWithMenuMutation,
  useSortCategoryInMenuMutation,
  useToggLeStoreInMenuCategoryITemMutation,
  useGetMenuInfoMutation,
  useGetMenuCatInfoMutation,
  useGetBrandItemsMutation,
  useGetStoreItemsMutation,
} = MenuService;
export default MenuService;
