import { UTILS } from "utils";
import useGetMenus from "./useGetMenus";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import { useDispatch } from "react-redux";
import { useMenuContext } from "store/contexts/Menus";
import { API } from "api";

function useUpdateMenu() {
  const getAllMenus = useGetMenus();
  const dispatch = useDispatch();
  const { updateState } = useMenuContext();
  const menuUpdate = async ({ rowData, event }) => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const brandId = UTILS.getCurrentUser()?.brandId;
      const data = await API.updateMenu({
        menuId: rowData?._id,
        isActive: event,
        brandId,
        baseLanguage: {
          name: rowData.baseLanguage?.name,
        },
        description: rowData?.description,
      });

      if (data?.success === 1 && data?.data) {
        UTILS.successNotification(data?.message);
        await getAllMenus();
        updateState("editMenumodal", false);
      } else {
        UTILS.handleErrorNotification(errorHandler(data));
      }
    } catch (err) {
      UTILS.handleErrorNotification(errorHandler(err?.error));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return menuUpdate;
}

export default useUpdateMenu;
