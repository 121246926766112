import { API } from "api";
import { UTILS } from "utils";
import { LoaderAction } from "redux/actions";
import { useDispatch } from "react-redux";
import { logToSentry } from "utils/Utils";
import { useMenuContext } from "store/contexts/Menus";

function useMakeDefault() {
  const { updateState, menuState } = useMenuContext();

  const dispatch = useDispatch();
  const makeMenuDeafault = async ({ menuId, callback = () => {} }) => {
    try {
      if (!menuId) {
        logToSentry(
          `Menu Id is required to make default menu menuId${JSON.stringify(
            menuId
          )}`,
          "error"
        );
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const brandId = UTILS.getCurrentUser()?.brandId;
      const res = await API.markAsDefaultMenu({
        brandId,
        menuId: menuId,
      });
      if (res?.success === 1) {
        const menuInfo = {
          ...menuState.menuInfo,
          isDefault: res?.data?.isDefault,
        };
        updateState("menuInfo", menuInfo);
        UTILS.successNotification(res?.message);
        callback();
      } else {
        UTILS.handleErrorNotification(res?.message);
      }
    } catch (error) {
      UTILS.handleErrorNotification(error?.message);
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return makeMenuDeafault;
}

export default useMakeDefault;
