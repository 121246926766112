import { API } from "api";
import React, { useState } from "react";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AreasAction } from "redux/actions";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import UpdateAreaModal from "../common/UpdateAreaModal";
import { calculateDeliveryTimeInMins, isValidInputFields } from "../helpers";
import { errorNotification } from "utils/Utils";

const UpdateAreas = ({ onClose, selectedAreas, setSelectedRowsKeys }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const [state, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    deliveryTime: {
      Hours: 0,
      Minutes: 0,
      Days: 0,
    },
  });

  const handleValues = (key, val) => setState({ ...state, [key]: val });

  const getUpdatedAreas = UTILS.withTryCatch(async () => {
    setSelectedRowsKeys([]);
    const userDetail = UTILS.getCurrentUser();
    const updateAreas = await UTILS.UpdatedAreas(
      selectedAreas[0].storeId,
      userDetail.brandId,
      userDetail.token
    );
    dispatch(AreasAction.setAddedAreas(updateAreas));
    onClose();
    dispatch(LoaderAction.setLoading(false));
  });

  const editAreas = UTILS.withTryCatch(async () => {
    const { error, isValid } = isValidInputFields({
      isLoading,
      state,
      selectedAreas,
    });
    if (!isValid) {
      errorNotification(error);
      return;
    }

    dispatch(LoaderAction.setLoading(true));
    const deliveryTimeInMins = calculateDeliveryTimeInMins({
      deliveryTime: state.deliveryTime,
    });
    const userDetail = UTILS.getCurrentUser();
    let objectArray = [];
    selectedAreas.forEach((area) => {
      objectArray.push({
        cityID: area.cityID._id,
        areaID: area.areaID._id,
        countryID: area.countryID._id,
        storeId: area.storeId,
        brandId: area.brandId,
      });
    });

    const result = await API.UPDATE_STORE_AREA({
      areas: objectArray,
      minOrder: Number(state.minOrder),
      deliveryFee: Number(state.deliveryFee),
      deliveryTime: Number(deliveryTimeInMins),
      token: userDetail.token,
      language: currentLanguage,
    });
    if (result?.success === 1) {
      UTILS.successNotification(t(result.message));
      dispatch(AreasAction.updateSelectedArea([]));
    } else {
      UTILS.errorNotification(errorHandler(result));
    }
    getUpdatedAreas();
  });

  const handleInputValue = (key, val) => {
    const value = UTILS.NumberConversion(val);
    handleValues(key, value);
  };

  return (
    <UpdateAreaModal
      {...{
        isLoading,
        selectedAreas,
        onClose,
        editAreas,
        state,
        handleInputValue,
        handleValues,
        time: state.deliveryTime,
        setTime: (val) => handleValues("deliveryTime", val),
      }}
    />
  );
};

export default UpdateAreas;
