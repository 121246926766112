import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentLanguage: "EN",
};

const LanguageSlice = createSlice({
  name: "LanguageSlice",
  initialState,
  reducers: {
    updateLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
});

export const { updateLanguage } = LanguageSlice.actions;
export default LanguageSlice.reducer;
