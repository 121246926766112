import React from "react";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import Modal from "components/Modal";

function Stripe({
  children,
  orderInfo,
  paymentGatewayInfoModal,
  setPaymentGatewayInfoModal,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={paymentGatewayInfoModal}
        onClose={() => {
          setPaymentGatewayInfoModal(false);
        }}
        modalWidth="rounded-md border border-gray-300 top-0 w-full md:w-2/3"
      >
        <div className="p-4">
          <div
            className="flex justify-end my-2"
            onClick={() => {
              setPaymentGatewayInfoModal(false);
            }}
          >
            <span className="cursor-pointer">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-6 h-6 bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
              </svg>
            </span>
          </div>
          <table className="border-collapse border border-slate-400 w-full my-8">
            <tbody>
              <tr>
                <td className="border border-slate-300 p-2 font-bold">
                  {t("Payment Gateway Id")}
                </td>
                <td className="border border-slate-300 p-2">
                  {orderInfo?.orderCheckoutOnlinePaymentMetadata?.data
                    ?.cartId ?? "N/A"}

                  <button
                    onClick={(e) => {
                      navigator.clipboard.writeText(
                        orderInfo?.orderCheckoutOnlinePaymentMetadata?.data
                          ?.cartId
                      );
                      UTILS.handleSuccesNotification(t("Copied to Clipboard"));
                      setPaymentGatewayInfoModal(false);
                    }}
                    className=" ant-btn ant-btn-primary rounded ms-2"
                  >
                    {t("Copy")}
                  </button>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div
        onClick={() => {
          setPaymentGatewayInfoModal(true);
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default Stripe;
