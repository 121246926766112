import { createSlice } from "@reduxjs/toolkit";
const logoutSlice = createSlice({
  name: "Logout",
  initialState: {},
  reducers: {
    logout: (state) => {
      state = undefined;
    },
  },
});

export const { logout } = logoutSlice.actions;
export default logoutSlice.reducer;
