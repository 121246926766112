import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Modal from "components/Modal";
import RefundButtonStatus from "./RefundButton";

function RefundOrderInfo({ rowData }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={open}
        modalWidth="rounded-md border border-gray-300 top-0"
        onClose={() => setOpen(false)}
      >
        <div className="mx-8 ">
          <div className="flex justify-end my-4">
            <span onClick={() => setOpen(false)} className="cursor-pointer">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-6 h-6 bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
          <table class="border-collapse border border-slate-400 w-full my-8">
            {rowData?.refund?.amount >= 0 ? (
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Refunded Amount")}
                </td>
                <td className="border border-slate-300 p-4">
                  {rowData.refund?.amount} {t("AED")}
                </td>
              </tr>
            ) : null}
            {rowData?.refund?.amount >= 0 ? (
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Refund Reason")}
                </td>
                <td className="border border-slate-300 p-4">
                  {rowData.refund?.reason}
                </td>
              </tr>
            ) : null}
            {rowData?.refund?.amount >= 0 ? (
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Refund Status")}
                </td>
                <td className="border border-slate-300 p-4">
                  {rowData?.refund?.status}
                </td>
              </tr>
            ) : null}
            {rowData?.refund?.amount >= 0 ? (
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Refund Request Date")}
                </td>
                <td className="border border-slate-300 p-4">
                  {moment
                    .unix(rowData?.refund?.stripeResponse?.created)
                    .format("DD/MM/yyyy hh:mm A")}
                </td>
              </tr>
            ) : null}
          </table>
        </div>
      </Modal>

      <RefundButtonStatus status={rowData?.refund?.status} setOpen={setOpen} />
    </>
  );
}

export default RefundOrderInfo;
