import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { LogisticAction } from "redux/actions";
import { INTEGRATION_NAMES } from "./constants";
import { logToSentry } from "utils/Utils";
import { useTranslation } from "react-i18next";

const LogisticModalSelector = ({
  order,
  openType,
  selectedIntegration,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  isSelected(order);

  const pressYes = useCallback(() => {
    switch (openType) {
      case INTEGRATION_NAMES.YANDEX:
        dispatch(LogisticAction.toggleYandexModal(true));
        break;
      case INTEGRATION_NAMES.TALABAT:
        // setTalabat(true);
        break;
      case INTEGRATION_NAMES.MARSOOL:
        // sendToMursool();
        break;
      case INTEGRATION_NAMES.CAREEM_NOW:
        dispatch(LogisticAction.toggleCareemModal(true));
        break;
      case INTEGRATION_NAMES.DELIVERY_ZONE:
        dispatch(LogisticAction.toggleDeliverZoneModal(true));
        break;
      default:
        logToSentry("User wants to change to an unknown logistic type. 166");
        break;
    }
    dispatch(LogisticAction.toggleDeliveryModal(false));
  }, [dispatch, openType]);

  const pressNo = useCallback(() => {
    dispatch(LogisticAction.toggleDeliveryModal(false));
  }, [dispatch]);

  return (
    <div className="p-4 py-16 text-center">
      <h3 className="font-medium text-18px mb-8">
        {`${t("You are delivering using")} ${selectedIntegration} ${t(
          "service, are you sure to change the service?"
        )}`}
      </h3>
      <div>
        <button
          onClick={pressYes}
          className="px-12 py-2 me-2 my-2 text-black rounded border border-green-500"
        >
          {t("YES")}
        </button>
        <button
          onClick={pressNo}
          className="px-12 py-2 me-2 my-2 text-black rounded border border-red-500"
        >
          {t("NO")}
        </button>
      </div>
    </div>
  );
};

export default LogisticModalSelector;
