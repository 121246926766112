import { CONSTANTS as C } from "config";
import { UTILS } from "utils";
import { isValidValue } from "utils/Utils";
import i18next from "i18next";

export const isAllowedToShowShippingMethod = (method, CatalogueType) => {
  if (CatalogueType === C.OUTLET_BASED) {
    if (
      method === C.SHIPPING_METHODS.CITY_BASED ||
      method === C.SHIPPING_METHODS.COUNTRY_BASED
    )
      return true;
    else return false;
  } else return false;
};

export const isValidInputFields = ({ state, selectedAreas }) => {
  if (!isValidValue(state?.minOrder)) {
    return {
      isValid: false,
      error: i18next.t("Please enter minimum order amount"),
    };
  }
  if (!isValidValue(state?.deliveryFee)) {
    return {
      isValid: false,
      error: i18next.t("Please enter delivery fee"),
    };
  }
  if (selectedAreas <= 0) {
    return {
      isValid: false,
      error: i18next.t("Please select at least one area"),
    };
  }
  if (
    state.deliveryTime?.Days === 0 &&
    state.deliveryTime?.Hours === 0 &&
    state.deliveryTime?.Minutes === 0
  ) {
    return {
      isValid: false,
      error: i18next.t("Please choose delivery time"),
    };
  }
  return {
    isValid: true,
  };
};

//getFilteredAreasList : returns all added+not added areas and add a custom property "isAdded" to each area
//@ params: areaData, addedAreas
//areasData: all business areas
//addedAreas: all added areas

export const handleAddIsAddedPropToBuisnessAreas = (
  areasData,
  alreadyAddedAreas
) => {
  console.log({ alreadyAddedAreas, areasData });
  const filteredArray = [];

  if (areasData) {
    areasData?.forEach((country) => {
      const countryCities = country?.cities?.map((city) => {
        const cityAreas = city?.areas?.map((area) => {
          const isAdded = alreadyAddedAreas?.some(
            (addedArea) => addedArea?.mappedAreaInfo?.mappedAreaId === area._id
          );

          return {
            ...area,
            isAdded,
          };
        });

        return {
          ...city,

          areas: cityAreas,
        };
      });

      filteredArray.push({
        ...country,
        cities: countryCities,
      });
    });
    return filteredArray;
  }
};

export const calculateDeliveryTimeInMins = ({ deliveryTime }) => {
  let delTime =
    deliveryTime.Days * 1440 + deliveryTime.Hours * 60 + deliveryTime.Minutes;
  return delTime;
};

export const isValidDrawAreInputFields = ({
  areaState,
  selectedCity,
  selectedCountry,
  selectedStore,
}) => {
  if (!isValidValue(areaState?.minOrder)) {
    UTILS.errorNotification("Please enter minimum order amount");
    return {
      isValid: false,
      error: "Please enter minimum order amount",
    };
  }
  if (!isValidValue(areaState?.deliveryFee)) {
    UTILS.errorNotification("Please enter delivery fee");
    return {
      isValid: false,
      error: "Please enter delivery fee",
    };
  }
  if (
    areaState.deliveryTime?.Days === 0 &&
    areaState.deliveryTime?.Hours === 0 &&
    areaState.deliveryTime?.Minutes === 0
  ) {
    UTILS.errorNotification("Please choose delivery time");
    return {
      isValid: false,
      error: "Please choose delivery time",
    };
  }
  if (!isValidValue(selectedStore?.value)) {
    UTILS.errorNotification("Please select store");
    return {
      isValid: false,
      error: "Please select store",
    };
  }
  if (!selectedCountry) {
    UTILS.errorNotification("Please select country");
    return {
      isValid: false,
      error: "Please select country",
    };
  }
  if (!selectedCity) {
    UTILS.errorNotification("Please select city");
    return {
      isValid: false,
      error: "Please select city",
    };
  }

  return {
    isValid: true,
  };
};

export function convertPolygonArrayToObject(areas) {
  const polygonsObject = areas
    .filter(
      (store) =>
        store?.mappedAreaInfo?.mappedAreaId?.polygon?.coordinates?.length > 0
    )
    .map((area) => {
      const coordinates =
        area?.mappedAreaInfo?.mappedAreaId?.polygon?.coordinates?.[0];
      return coordinates
        ? convertSinglePolygonToObjectArray(coordinates, area)
        : null;
    })
    .filter(Boolean);

  return polygonsObject;
}

function convertSinglePolygonToObjectArray(coordinates, area) {
  return {
    areaInfo: area.mappedAreaInfo,
    coordinates: coordinates.map(([lng, lat]) => ({ lat, lng })),
  };
}

export const isValidUpdateInputFields = ({ state, storeID }) => {
  if (!UTILS?.isValidValue(state?.minOrder)) {
    UTILS.errorNotification("Please enter minimum order amount");
    return {
      isValid: false,
      error: "Please enter minimum order amount",
    };
  }
  if (!UTILS?.isValidValue(state?.deliveryFee)) {
    UTILS.errorNotification("Please enter delivery fee");
    return {
      isValid: false,
      error: "Please enter delivery fee",
    };
  }

  if (
    state.deliveryTime?.Days === 0 &&
    state.deliveryTime?.Hours === 0 &&
    state.deliveryTime?.Minutes === 0
  ) {
    UTILS.errorNotification("Please choose delivery time");
    return {
      isValid: false,
      error: "Please choose delivery time",
    };
  }
  if (!UTILS?.isValidValue(storeID)) {
    UTILS.errorNotification("Please select store");
    return {
      isValid: false,
      error: "Please select store",
    };
  }
  return {
    isValid: true,
  };
};

export const libraries = ["geometry", "places", "drawing"];
