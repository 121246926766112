import React from "react";
import PropTypes from "prop-types";
import { API } from "api";
import { UTILS } from "utils";
import { useSelector, useDispatch } from "react-redux";
import { AreasAction, LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import DeleteAreasModal from "../common/DeleteAreasModal";
const AddCategory = ({ onClose, selectedAreas, setSelectedRowsKeys }) => {
  const dispatch = useDispatch();
  const { areaData } = useSelector((state) => state.AreasReducer);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const getUpdatedAreas = async () => {
    setSelectedRowsKeys([]);
    const userDetail = UTILS.getCurrentUser();
    const updateAreas = await UTILS.UpdatedAreas(
      selectedAreas[0].storeId,
      userDetail.brandId,
      userDetail.token
    );

    const filteredAreasList = await UTILS.filteredAreasList(
      areaData,
      updateAreas
    );
    dispatch(AreasAction.setcustomeAddedAreas(filteredAreasList));
    dispatch(AreasAction.setAddedAreas(updateAreas));
    dispatch(LoaderAction.setLoading(false));
    onClose();
  };
  const deleteAreas = async () => {
    dispatch(LoaderAction.setLoading(true));
    const userDetail = UTILS.getCurrentUser();
    let objectArray = [];
    selectedAreas.forEach((area) => {
      objectArray.push({
        cityID: area.cityID._id,
        areaID: area.areaID._id,
        countryID: area.countryID._id,
        storeId: area.storeId,
        brandId: area.brandId,
      });
    });

    const result = await API.DELETE_STORE_AREA({
      areas: objectArray,
      token: userDetail.token,
      language: currentLanguage,
    });
    if (result?.success === 1) {
      UTILS.successNotification(result.message);
      dispatch(AreasAction.updateSelectedArea([]));
    } else {
      dispatch(LoaderAction.setLoading(true));
      UTILS.errorNotification(errorHandler(result));
    }
    getUpdatedAreas();
  };

  return (
    <DeleteAreasModal {...{ isLoading, selectedAreas, onClose, deleteAreas }} />
  );
};

AddCategory.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddCategory;
