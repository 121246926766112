import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Select } from "antd";
import Modal from "components/Modal";
import AddNewCity from "../../modals/AddNewCity";
import DeleteCity from "../../modals/DeleteCity";
import UpdateCity from "../../modals/UpdateCity";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import useCurrentLang from "hooks/useCurrentLang";
import useUserType from "hooks/useUserType";
import useAllStores from "hooks/useCurrentUserStores";
import { AreasAction } from "redux/actions";
import PlusIcon from "features/icons/PlusIcon";
import BackButton from "components/BackButton";
import RenderTime from "features/shipping_methods/common/RenderTime";
function CitiesTable() {
  const { stores } = useAllStores();
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  const { selectedStore, addedCities } = useSelector(
    (state) => state.AreasReducer
  );

  const userType = useUserType() || UTILS.getCurrentUser()?.userType;
  const [searchTerm, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogContentId, setDialogContentId] = useState(-1);
  const [selectedRowKeys, setSelectedRowsKeys] = useState([]);
  const [selectedDataRows, setSelectedDataRows] = useState({});
  const { t } = useTranslation();
  const language = useCurrentLang();

  const columns = [
    {
      key: "cityID",
      title: t("City Name"),
      sorter: (a, b) =>
        a?.cityID?.cityNameEN?.localeCompare(b?.cityID?.cityNameEN),
      dataIndex: "cityName",
      render: (val, row, index) => {
        return language === "en"
          ? row?.cityID?.cityNameEN
          : row?.cityID?.cityNameAR;
      },
    },
    {
      key: "minOrder",
      title: t("Min. Basket Value"),
      dataIndex: "minOrder",
      sorter: {
        compare: (a, b) => a.minOrder - b.minOrder,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      key: "deliveryFee",
      title: t("Delivery Fee"),
      dataIndex: "deliveryFee",
      sorter: {
        compare: (a, b) => a.deliveryFee - b.deliveryFee,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },

    {
      key: "deliveryTime",
      title: t("Delivery Time"),
      dataIndex: "deliveryTime",
      sorter: {
        compare: (a, b) => a.deliveryTime - b.deliveryTime,
        multiple: 3,
      },
      render: (val, row, index) => {
        return <RenderTime {...{ val, row, index }} />;
      },
    },
  ];

  const DG_CONTENT_ID = {
    ADD_CITY: 0,
    DELETE_CITY: 1,
    UPDATE_CITY: 2,
    ADD_ITEM_TO_CITY: 3,
  };

  const onSelectChange = () => {
    setSelectedRowsKeys(selectedRowKeys);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedRowsKeys([]);
  };
  const renderModalContent = () => {
    switch (dialogContentId) {
      case 0:
        return <AddNewCity language={language} onClose={onClose} />;
      case 1:
        return (
          <DeleteCity
            language={language}
            selectedCities={selectedDataRows}
            onClose={onClose}
            onSelectChange={onSelectChange}
            setSelectedRowsKeys={setSelectedRowsKeys}
          />
        );
      case 2:
        return (
          <UpdateCity
            language={language}
            selectedCities={selectedDataRows}
            onClose={onClose}
            onSelectChange={onSelectChange}
            setSelectedRowsKeys={setSelectedRowsKeys}
          />
        );
      default:
        break;
    }
  };

  const Items = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm?.toLowerCase() ?? "";
    const cityDeliveryData = addedCities?.cityDeliveryData;
    if (!lowerCaseSearchTerm) {
      return cityDeliveryData;
    }

    return cityDeliveryData?.filter(
      (item) =>
        item?.cityID?.cityNameEN
          ?.toLowerCase()
          ?.includes(lowerCaseSearchTerm) ||
        item?.cityID?.cityNameAR?.toLowerCase()?.includes(lowerCaseSearchTerm)
    );
  }, [addedCities, searchTerm]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDataRows(selectedRows);
      setSelectedRowsKeys(selectedRowKeys);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selectedRowKeys.length !== 0) {
        setSelectedRowsKeys([]);
      }
    },
  };

  const handSearch = (e) => {
    setSearch(e.target.value);
  };

  const deleteArea = () => {
    setOpen(true);
    setDialogContentId(DG_CONTENT_ID.DELETE_CITY);
  };
  const editArea = () => {
    setOpen(true);
    setDialogContentId(DG_CONTENT_ID.UPDATE_CITY);
  };
  const addCities = () => {
    setOpen(true);
    setDialogContentId(DG_CONTENT_ID.ADD_CITY);
  };
  const onStoreChange = (value) => {
    localStorage.setItem("storeId", value.value);
    dispatch(AreasAction.setStore(value));
  };

  const cityCount = addedCities?.cityDeliveryData?.length || 0;
  const cityText = `${cityCount} ${t(cityCount <= 1 ? "City" : "Cities")}`;

  return (
    <>
      <div className="mb-3">
        <BackButton />
      </div>
      <div className="bg-white rounded shadow-sm border-gray-200 border px-2 py-2">
        <div className="">
          <h2 className="text-17px text-blue-600 ">{t("Select store")}</h2>
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="flex items-center my-3 w-full sm:w-96 sm:my-0">
              <Select
                value={selectedStore}
                size="large"
                labelInValue={true}
                showSearch={false}
                className="w-full rounded"
                placeholder={t("Select store")}
                optionFilterProp="children"
                onChange={onStoreChange}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={stores}
              />
            </div>
            {selectedStore && (
              <div>
                <Button
                  disabled={userType === "MANAGER"}
                  type="primary"
                  onClick={() => addCities()}
                  className="font-montserrat px-5 border border-transparent shadow-sm  font-medium rounded-sm text-white h-10  focus:outline-none"
                >
                  <span className="align-middle">
                    <PlusIcon />
                  </span>
                  <span className="px-1 align-middle">
                    {t("Add delivery cities")}
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
        {selectedStore && (
          <>
            <Modal
              showScroll={true}
              open={open}
              modalWidth="rounded-md border border-gray-300"
              onClose={() => setOpen(false)}
              responsiveClass="w-full lg:w-full xl:w-4/5 2xl:2/3"
            >
              {renderModalContent()}
            </Modal>
            <div className="flex justify-between my-4 items-center flex-row-reverse flex-col-reverse  sm:flex-row">
              <div className="w-full sm:w-auto text-start pt-4 sm:pt-0">
                <h2 className="font-bold p-1 align-middle text-16px sm:text-19px ">
                  {cityText}
                </h2>
              </div>

              <div className="flex flex-col w-full sm:w-auto sm:flex-row">
                <div className="flex  justify-end">
                  {selectedRowKeys?.length > 0 && (
                    <Button
                      type="primary"
                      className="mx-2 h-10 "
                      onClick={editArea}
                    >
                      {t("Edit")}
                      {`${
                        selectedRowKeys?.length > 0
                          ? `(${selectedRowKeys.length})`
                          : " "
                      } `}
                    </Button>
                  )}

                  {selectedRowKeys?.length > 0 && (
                    <Button
                      danger
                      className="mx-2 h-10"
                      onClick={() => deleteArea()}
                    >
                      {t("Delete")}
                      {`${
                        selectedRowKeys?.length > 0
                          ? `(${selectedRowKeys.length})`
                          : " "
                      } `}
                    </Button>
                  )}
                </div>
                <div className="group relative mt-4 sm:mt-0">
                  <svg
                    fill="currentColor"
                    className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    />
                  </svg>
                  <input
                    onChange={handSearch}
                    value={searchTerm}
                    className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm"
                    type="text"
                    aria-label="Search cty by name"
                    placeholder={t("search city by name")}
                  />
                </div>
              </div>
            </div>{" "}
            <div className="overflow-hidden overflow-x-auto md:overflow-hidden border-gray-200 border rounded-sm">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={Items}
                rowKey={(record) => record.cityID._id}
                pagination={{ position: ["bottomCenter"], pageSize: 50 }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CitiesTable;
