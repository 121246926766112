import React from "react";
import { CONSTANTS as C } from "config";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
const CheckDeliveryModal = ({ open, setOpen, changed, deliveryType }) => {
  const { t } = useTranslation();
  const pressYes = () => {
    if (changed.type === C.OTHER) {
      changed.fun();
    } else {
      changed.fun(true);
    }
    setOpen(false);
  };
  const pressNo = () => {
    setOpen(false);
  };
  const Chceck = () => {
    return (
      <div className="p-4 py-16 text-center">
        <h3 className="font-medium text-18px mb-8">
          {` ${t("You are delivering using")}
          ${deliveryType}
          ${t("service, are you sure to change the service?")}`}
        </h3>
        <div>
          <button
            onClick={pressYes}
            className="px-12 py-2 me-2 my-2  text-black rounded border border-green-500"
          >
            {t("YES")}
          </button>
          <button
            onClick={pressNo}
            className="px-12 py-2 me-2 my-2 text-black rounded border border-red-500"
          >
            {t("NO")}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        modalWidth="rounded-md border border-gray-300 top-0 w-full md:w-1/2"
        onClose={() => setOpen(false)}
      >
        <Chceck />
      </Modal>
    </>
  );
};

export default CheckDeliveryModal;
