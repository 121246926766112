import React from "react";
import PreOrder from "./PreOrder";
function PreOrderingWidget({ store, setStore }) {
  const hndlepreOrderingState = (key, val) => {
    setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
      },
    }));
  };

  const handlePreOrderingTiming = (key, val) => {
    setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        preOrderSettingsForDelivery: {
          ...prevState.values.preOrderSettingsForDelivery,
          [key]: val,
        },
      },
      errors: {
        ...prevState.errors,
      },
    }));
  };

  return (
    <PreOrder
      type="delivery"
      asap={{
        value: store?.values?.isAcceptQuickOrderForDelivery,
        keyVal: "isAcceptQuickOrderForDelivery",
      }}
      isPreOrderEnabled={{
        value: store.values?.isPreOrderEnabledForDelivery,
        keyVal: "isPreOrderEnabledForDelivery",
      }}
      sameDayPreOrder={{
        value: store?.values?.preOrderSettingsForDelivery?.acceptTodayPreOrders,
        keyVal: "acceptTodayPreOrders",
      }}
      preOrderTimig={{
        minTimeInAdvance:
          store?.values?.preOrderSettingsForDelivery?.minTimeInAdvance,
        minTimeInAdvanceUnit:
          store?.values?.preOrderSettingsForDelivery?.minTimeInAdvanceUnit,
        maxDaysInAdvance:
          store?.values?.preOrderSettingsForDelivery?.maxDaysInAdvance,
        timeInterval: store?.values?.preOrderSettingsForDelivery?.timeInterval,
        minDaysInAdvance:
          store?.values?.preOrderSettingsForDelivery?.minDaysInAdvance,
      }}
      handlePreOrderingTiming={handlePreOrderingTiming}
      hndlepreOrderingState={hndlepreOrderingState}
      minTimeErrorInAdvance={store?.errors?.preOrderMinTimeInAdvanceDelivery}
    />
  );
}

export default PreOrderingWidget;
