import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import { CONSTANTS as C } from "config";
import { UTILS } from "utils";
import { API } from "api";
import PhoneNumberInput from "components/PhoneNumberInput";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import TextPassword from "components/TextPassword";

const AddAdminUser = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    values: {
      name: "",
      email: "",
      brandId: "",
      phoneNumber: "",
      deviceType: "web",
      userType: "",
      password: "",
      confirmPassword: "",
    },
    errors: {
      name: "",
      email: "",
      brandId: "",
      phoneNumber: "",
      deviceType: "",
      userType: "",
      password: "",
      confirmPassword: "",
      language: "",
    },
  });

  const handleValues = (key, val) => {
    setUser((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const validateInputs = () => {
    let isValidForm = true;
    let obj = {
      errors: {
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        userType: "",
      },
    };

    if (!user?.values?.email) {
      obj.errors.email = t("Field is required");
      isValidForm = false;
    }
    if (!user?.values?.name || UTILS.hasWhiteSpace(user.values.name)) {
      obj.errors.name = t("Field is required");
      isValidForm = false;
    } else if (!UTILS.emailValidation().test(user.values.email)) {
      obj.errors.email = t("Please enter correct email!");
      isValidForm = false;
    }

    if (!user?.values?.phoneNumber) {
      obj.errors.phoneNumber = t("Field is required");
      isValidForm = false;
    }

    if (!UTILS.isValidMobileNumber(user.values.phoneNumber)) {
      obj.errors.phoneNumber = t("Enter a valid mobile number");
      isValidForm = false;
    }

    if (user?.values?.password == "") {
      obj.errors.password = t("Field is required");
      isValidForm = false;
    }

    if (user?.values?.password?.length <= 6) {
      obj.errors.password = t("password should be 7 character long");
      isValidForm = false;
    }

    if (user?.values?.confirmPassword === "") {
      obj.errors.confirmPassword = t("Field is required");
      isValidForm = false;
    }

    if (user?.values?.password !== user.values.confirmPassword) {
      obj.errors.confirmPassword = t("password do not match");
      isValidForm = false;
    }

    setUser((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
      },
      errors: obj.errors,
    }));
    return isValidForm;
  };
  const addUser = async () => {
    user.values.email = user?.values?.email?.trim();
    if (validateInputs()) {
      setLoading(true);
      user.values.phoneNumber = await UTILS.formatPhoneNumber(
        user.values.phoneNumber
      );
      const userDetail = UTILS.getCurrentUser();
      let objUser = {
        ...user.values,
        brandId: userDetail.brandId,
        userType: "MANAGER",
      };
      const result = await API.ADD_USER({
        token: userDetail.token,
        user: objUser,
      });
      if (result.success === 1) {
        UTILS.successNotification(t("User Added Successfully"));
        props.callback();
        clearFields();
      } else {
        UTILS.errorNotification(result?.message);
      }
      setLoading(false);
    }
  };
  // clear all the fields
  const clearFields = () => {
    setUser((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        userType: "",
      },
    }));
  };
  const sharedProps = {
    handleValues,
    mandatory: true,
  };
  return (
    <div className="px-4 sm:px-5">
      <div className="py-5">
        <h2 className="text-19px text-blue-600">{t("User Information")}</h2>
        <Spin spinning={loading}>
          <div className="py-2">
            <TextInput
              label={t("Name")}
              value={user.values.name}
              keyVal={"name"}
              error={user.errors.name}
              {...sharedProps}
            />
          </div>
          <div className="py-2">
            <TextInput
              label={t("Email")}
              value={user.values.email.trim()}
              keyVal={C.KEY_EMAIL}
              error={user.errors.email}
              {...sharedProps}
            />
          </div>
          <div className="py-2">
            <PhoneNumberInput
              label={t("Mobile Number")}
              value={user.values.phoneNumber}
              keyVal={C.KEY_PHONE_FOR_USER}
              error={user.errors.phoneNumber}
              {...sharedProps}
            />
          </div>
          <div className="py-2">
            <TextPassword
              label={t("Password")}
              value={user.values.password}
              keyVal={C.KEY_PASSWORD}
              error={user.errors.password}
              {...sharedProps}
            />
          </div>
          <div className="py-2">
            <TextPassword
              label={t("Confirm Password")}
              value={user.values.confirmPassword}
              keyVal={C.KEY_CONFIRM_PASSWORD}
              error={user.errors.confirmPassword}
              {...sharedProps}
            />
          </div>
          <div className="py-2">
            <div className="flex justify-center">
              <Button
                type="primary"
                size="large"
                className="w-36"
                onClick={() => addUser()}
              >
                {t("Add User")}
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

AddAdminUser.propTypes = {
  callback: PropTypes.func,
};
export default AddAdminUser;
