import { useDispatch } from "react-redux";
import { LoaderAction } from "redux/actions";
import { useGetBrandItemsMutation } from "redux/menu/service";
import { useMenuContext } from "store/contexts/Menus";
import { UTILS } from "utils";

function useGetBRandItems() {
  const dispatch = useDispatch();
  const { updateState } = useMenuContext();
  const [getBrandItems] = useGetBrandItemsMutation();
  const getItemsByBrand = async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const result = await getBrandItems({
        brandId: UTILS.getCurrentUser()?.brandId,
      });
      if (result?.data?.success === 1 && result?.data) {
        updateState("items", result.data.data);
      }
    } catch (error) {
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return getItemsByBrand;
}

export default useGetBRandItems;
