import React from "react";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import {
  useDeleteCountryMutation,
  useGetAddedCountriesMutation,
} from "redux/shipping-methods/shipping-service";
import { AreasAction } from "redux/actions";
const DeleteCity = ({ selectedRow, onClose, selectedAreas, language }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteCountry] = useDeleteCountryMutation();
  const [getAddedCountries] = useGetAddedCountriesMutation();
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const delete_countries = UTILS.withTryCatch(async () => {
    const userDetail = UTILS.getCurrentUser();
    let objectArray = [];
    selectedAreas.forEach((country) => {
      objectArray.push(country._id);
    });

    const obj = {
      areas: objectArray,
      brandId: userDetail.brandId,
      storeId: selectedStore.value,
    };

    await deleteCountry(obj).unwrap();
    await getAddedCountries(obj);
    onClose();
    dispatch(AreasAction.setSync());
  });

  return (
    <div className="w-full p-5 mx-auto my-auto rounded-xl shadow-lg  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            {selectedRow && selectedRow.length === 1 ? (
              <h2 className="text-19px font-bold py-1">
                {t(
                  `Are you sure want to delete ${selectedRow[0].areaID?.areaNameEN}`
                )}
              </h2>
            ) : (
              <h2 className="text-19px font-bold py-1">
                {t("Are you sure you want to delete the selected countries")}
              </h2>
            )}
          </div>
          <div className="text-gray-500 p-1">
            {t("They will be removed from this outlet’s delivery reach.")}
          </div>
          <div className="p-3 mt-2 flex justify-end space-x-4 md:block">
            {/* <Button type="default" size="large" onClick={onClose}>
              {t("Cancel")}
            </Button> */}
            <Button type="primary" size="large" onClick={delete_countries}>
              {t("Delete")}
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

DeleteCity.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default DeleteCity;
