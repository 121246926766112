import React from "react";
import { useSelector } from "react-redux";

const BackButton = ({ onClick }) => {
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  return (
    <>
      {currentLanguage === "EN" ? (
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="text-gray-500 w-6 h-6 inline-block bi bi-arrow-left-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
      ) : (
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="text-gray-500 w-6 h-6 inline-block bi bi-arrow-right-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M4 8a.5.5 0 0 0 .5.5h6.793l-2.147 2.146a.5.5 0 1 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L11.293 7.5H4.5a.5.5 0 0 0-.5.5z"
          />
        </svg>
      )}
    </>
  );
};
export default BackButton;
