import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spin } from "antd";

import SearchArea from "../../common/Search";
import PriceInput from "components/useForm/PriceInput";
import CitiesList from "../components/CitiesList";

import { AreasAction, LoaderAction } from "redux/actions";
import ChooseTime from "../../common/ChooseTime";
import { errorHandler } from "api/api/errorHandler";
import TextInput from "components/TextInput";

import { isValidInputFields } from "features/shipping_methods/helpers";

import { UTILS } from "utils";
import { API } from "api";
import useGetStoreAddedMappedAreas from "./hooks/useGetStoreAddedMapAreas";
import { errorNotification } from "utils/Utils";
import { calculateDeliveryTimeInMins } from "../../helpers";
const AddNewMapAreas = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getStoreMappedAddedAreas = useGetStoreAddedMappedAreas();

  const [searchTerm, setSearch] = useState("");
  const [openAreas, setOpenAreas] = useState(false);

  const { newSelectedMappedAreas, mapCustomBusinessAreas, selectedStore } =
    useSelector((state) => state.AreasReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const [selectedAreaState, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    deliveryTime: {
      Hours: 0,
      Minutes: 0,
      Days: 0,
    },
  });

  const handleValues = async (key, value) =>
    setState({ ...selectedAreaState, [key]: value });

  const fetchAddedAreas = async (storeID) => {
    try {
      await getStoreMappedAddedAreas({ storeId: storeID });
      onClose();
      dispatch(LoaderAction.setLoading(false));
    } catch (error) {
      UTILS.errorNotification(t(errorHandler(error)));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const selectedAreasArray = newSelectedMappedAreas?.map((area) => ({
    mappedAreaId: area?.area?._id,
    translations: {
      en: { name: area?.area?.translations?.en?.name },
      ar: { name: area?.area?.translations?.ar?.name },
    },
    city: {
      en: area?.city?.en,
      ar: area?.city?.ar,
    },
    country: {
      en: area?.country?.en,
      ar: area?.country?.ar,
    },
  }));

  const addNewMappedAreas = async () => {
    try {
      const { error, isValid } = isValidInputFields({
        isLoading,
        state: selectedAreaState,
        selectedAreas: newSelectedMappedAreas,
      });
      if (!isValid) {
        errorNotification(error);
        return;
      }

      dispatch(LoaderAction.setLoading(true));
      const deliveryTimeInMins = calculateDeliveryTimeInMins({
        deliveryTime: selectedAreaState.deliveryTime,
      });
      const storeID = selectedStore.value;
      const userDetail = UTILS.getCurrentUser();
      const result = await API.addStoreMappedArea({
        mappedAreasInfo: selectedAreasArray,
        minOrder: Number(selectedAreaState.minOrder),
        deliveryFee: Number(selectedAreaState.deliveryFee),
        deliveryTime: Number(deliveryTimeInMins),
        storeId: storeID,
        brandId: userDetail.brandId,
      });
      if (result?.success === 1) {
        UTILS.successNotification(t(result.message));
        dispatch(AreasAction.setNewMappedAreas([]));
      } else {
        UTILS.errorNotification(t(errorHandler(result)));
      }
      await fetchAddedAreas(selectedStore.value);
    } catch (error) {
      UTILS.errorNotification(t(errorHandler(error)));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const handleSearch = (key, val) => {
    setSearch(val.toLowerCase());
    if (val !== "") setOpenAreas(true);
    else setOpenAreas(false);
  };

  const handleInputValue = (key, val) =>
    handleValues(key, UTILS.NumberConversion(val));

  useEffect(() => {
    if (selectedStore?.value)
      getStoreMappedAddedAreas({
        storeId: selectedStore.value,
      });
  }, [selectedStore?.value]);

  return (
    <div className="w-full p-2 sm:p-5 mx-auto my-auto  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            <div className="flex justify-between">
              <div>
                <h2 className="text-19px font-bold mb-3 py-1 px-2">
                  {t("Add delivery areas")}
                </h2>
              </div>
              <div className="cursor-pointer" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-6 h-6 bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="px-2  w-full sm:w-1/2 register-page">
                <TextInput
                  label={t("Min. Basket Value")}
                  value={selectedAreaState.minOrder.trim()}
                  keyVal={"minOrder"}
                  handleValues={handleInputValue}
                  mandatory={true}
                />
              </div>
              <div className="px-2 w-full sm:w-1/2 register-page">
                <PriceInput
                  label={t("Delivery Fee")}
                  value={selectedAreaState.deliveryFee.trim()}
                  keyVal={"deliveryFee"}
                  handleValues={handleValues}
                  mandatory={true}
                />
              </div>
            </div>
            <div className="flex flex-wrap px-2">
              <ChooseTime
                {...{
                  time: selectedAreaState.deliveryTime,
                  setTime: (val) => handleValues("deliveryTime", val),
                }}
              />
            </div>
            <div className="border border-gray-200 mx-2 my-2">
              <SearchArea
                value={searchTerm}
                keyVal={"searchTerm"}
                handleValues={handleSearch}
                setOpenAreas={setOpenAreas}
                placeholder="search area by name"
              />
              <hr className=" border-b-1 border-gray-200" />
              <div className="max-h-60 overflow-y-scroll">
                {mapCustomBusinessAreas?.map((country) => {
                  return country?.cities?.map((city, index) => {
                    return (
                      <CitiesList
                        cityCountry={country.name}
                        openAreas={openAreas}
                        searchTerm={searchTerm}
                        key={index}
                        city={city?.name}
                        cityAreas={city?.areas}
                      />
                    );
                  });
                })}
              </div>
            </div>
          </div>
          <div className="flex justify-between  items-center px-3">
            <div className="text-gray-500 underline max-w-40">
              {t("I can't find an area (please contact support)")}
            </div>
            <div>
              <Button type="primary" size="large" onClick={addNewMappedAreas}>
                {t("Add Areas")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

AddNewMapAreas.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddNewMapAreas;
