import moment from "moment";
import React from "react";
import i18n from "../../../i18n";
const RenderTime = ({ val, row, index }) => {
  var duration = moment.duration(val, "minutes");
  let days = Math.floor(duration.asDays());
  let hours = duration.hours();
  let minutes = duration.minutes();

  const SelectedTime = () => {
    let button = "";
    let dd = 0,
      hh = 0;
    if (days === 0 && hours === 0 && minutes === 0) {
      button = "";
    }

    if (days > 0) {
      if (days === 1) {
        dd = days;
        button += days + i18n.t("day");
      }
      if (days >= 2) {
        dd = days;
        button += days + i18n.t("days");
      }
    }
    if (hours > 0) {
      button += dd >= 1 ? ", " : "";
      if (hours === 1) {
        hh = hours;
        button += hours + i18n.t("hour");
      }
      if (hours >= 2) {
        hh = hours;
        button += hours + i18n.t("hours");
      }
    }
    if (minutes > 0) {
      button += hh >= 1 ? ", " : "" || dd >= 1 ? ", " : "";
      if (minutes === 1) {
        button += minutes + i18n.t("minute");
      }
      if (minutes >= 2) {
        button += minutes + i18n.t("minutes");
      }
    }

    return button;
  };

  return (
    <>
      <SelectedTime />
    </>
  );
};

export default RenderTime;
