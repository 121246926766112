import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import StarRating from "components/StarRating";
import AverageFeedbackRating from "./AverageFeedbackRating";
import { getStarRatingClasses } from "utils/Utils";
const UserFeedbacksTable = ({
  feedbacks,
  avgOrderRating,
  feedbackRatingCount,
  totalCount,
}) => {
  console.log("==", feedbacks);
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col-reverse md:flex-row flex-wrap gap-2 mt-2 ">
        <div className="flex-1">
          {feedbacks?.map((feedback, index) => (
            <div
              className="border border-gray-200 mb-3 rounded-lg"
              key={feedback?._id || index}
            >
              <div className="p-4">
                <div className="flex justify-between">
                  <div>
                    <p className="text-blue-600 mb-2" dir="ltr">
                      {feedback?.customerInfo?.phoneNumber}
                    </p>
                    <p className="mb-1">
                      {t("Order #")} {feedback?.orderInfo?.orderNumber}
                    </p>
                  </div>
                  <div className="">
                    <button className="px-4 py-2 me-2 my-2   rounded bg-gray-200">
                      {t(feedback?.serviceType)}
                    </button>
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <StarRating
                    className={`star ${getStarRatingClasses(
                      feedback?.orderRating
                    )}`}
                    totalStars={5}
                    rating={feedback?.orderRating}
                    handleStarClick={() => {}}
                  />
                  <span className="px-2 text-gray-500">
                    {moment(feedback?.createdAt).format("DD MMM YYYY")}
                  </span>
                </div>
                <div>{feedback?.comments}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="">
          <AverageFeedbackRating
            averageRating={avgOrderRating}
            feedbackRatingCount={feedbackRatingCount}
            totalCount={totalCount}
          />
        </div>
      </div>
    </>
  );
};

export default UserFeedbacksTable;
