import React from "react";

const HorizontalStarRating = ({
  totalStars,
  handleStarClick,
  rating,
  className,
}) => {
  const starElements = [...Array(totalStars)].map((_, index) => (
    <span
      key={index}
      className={
        index < rating ? (className ? className : "star selected") : "star"
      }
      onClick={() => handleStarClick(index + 1)}
    >
      ★
    </span>
  ));

  return <div className="horizontal-star-rating">{starElements}</div>;
};

export default HorizontalStarRating;
