import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import RadioButtonStoreTime from "components/RadioButtonStoreTime";
import moment from "moment";
import { TimePicker } from "antd";

const DayRow = (props) => {
  const { t } = useTranslation();

  const getValue = (key) => {
    if (props?.workingHours) {
      return props.workingHours[key];
    } else return "";
  };

  return (
    <React.Fragment>
      <div className="flex justify-left flex-wrap flex-col sm:flex-row">
        <div
          className="d-flex align-items-start pt-1 pe-3 py-2 sm:py-0 "
          style={{ width: "175px" }}
        >
          <RadioButtonStoreTime
            id={props.id}
            label={props.label}
            value={getValue(props.keyDay)}
            keyVal={props.keyDay}
            handleValues={props.handleValues}
          />
        </div>
        <div className="row flex-nowrap mb-1">
          <div>
            <div className="inline-block" style={{ width: "130px" }}>
              <TimePicker
                className={`mx-0 sm:mx-2 text-16px border  focus:ring-indigo-500 focus:border-indigo-500 block w-24 text-center border-gray-300 rounded-sm p-2 "
                ${
                  props.s1Error
                    ? "border-red-500 text-red-500"
                    : "border-gray-300"
                }`}
                onChange={(date) =>
                  props.handleValues(props.keyStartTime, date)
                }
                status={props.s1Error ? "error" : "success"}
                value={
                  props?.workingHours
                    ? moment(props.workingHours[props.keyStartTime])
                    : null
                }
                style={{
                  width: 120,
                }}
                allowClear={false}
                format="hh:mm A"
                showNow={false}
                minuteStep={5}
                inputReadOnly={true}
                autoFocus={true}
                placeholder={t("Select time")}
              />
            </div>
            <div className="inline-block" style={{ width: "130px" }}>
              <TimePicker
                className={`mx-0 sm:mx-2 text-16px border  focus:ring-indigo-500 focus:border-indigo-500 block w-24 text-center border-gray-300 rounded-sm p-2 "
                ${
                  props.s1Error
                    ? "border-red-500 text-red-500"
                    : "border-gray-300"
                }`}
                onChange={(date) => props.handleValues(props.keyEndTime, date)}
                value={
                  props?.workingHours
                    ? moment(props.workingHours[props.keyEndTime])
                    : null
                }
                style={{
                  width: 120,
                }}
                status={props.s1Error ? "error" : "success"}
                allowClear={false}
                format="hh:mm A"
                use12Hours
                minuteStep={5}
                showNow={false}
                placeholder={t("Select time")}
              />
            </div>

            <span className="px-2 cursor-pointer" onClick={props.addSlotInput}>
              <svg
                viewBox="0 0 16 16"
                xmlns="https://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-4 h-4 inline-block align-text-top"
              >
                <path d="M13.48 2.52a7.749 7.749 0 00-10.96 0 7.749 7.749 0 000 10.96 7.749 7.749 0 0010.96 0 7.749 7.749 0 000-10.96zM9.25 12.125a.375.375 0 01-.376.376L7.126 12.5a.375.375 0 01-.376-.376l.013-2.886-2.888.011a.375.375 0 01-.376-.375l.003-1.748c0-.208.168-.376.375-.376l2.886.014-.01-2.889c0-.207.167-.375.375-.375h1.75c.208 0 .375.168.375.375l-.015 2.889 2.888-.011c.208 0 .376.167.376.375v1.75a.375.375 0 01-.376.376l-2.888-.016.011 2.889z"></path>
              </svg>
              <span className="px-1 add-remove-btn">{t("Add Hours")}</span>
            </span>
          </div>
          <div className="row flex-nowrap mb-1 text-red-500 text-13px px-0 sm:px-2">
            {props.s1Error}
          </div>
        </div>
      </div>

      {props.showSlot2 && (
        <>
          <div
            className="flex justify-left flex-wrap flex-col sm:flex-row
"
          >
            <div
              className="d-flex align-items-start pt-1 pe-3 hide_div"
              style={{ width: "175px" }}
            ></div>
            <div className="row flex-nowrap mb-1">
              <div>
                <div className="inline-block" style={{ width: "130px" }}>
                  <TimePicker
                    className={`mx-0 sm:mx-2 text-16px  border  focus:ring-indigo-500 focus:border-indigo-500 block w-24 text-center border-gray-300 rounded-sm p-2 
                  ${
                    props.s2Error || props.overLapError
                      ? "border-red-500 text-red-500"
                      : "border-gray-300"
                  }`}
                    onChange={(date) =>
                      props.handleValues(props.keyStartTime2, date)
                    }
                    value={
                      props?.workingHours[props.keyStartTime2]
                        ? moment(props.workingHours[props.keyStartTime2])
                        : null
                    }
                    style={{
                      width: 120,
                    }}
                    allowClear={false}
                    format="hh:mm A"
                    use12Hours
                    minuteStep={5}
                    showNow={false}
                    placeholder={t("Select time")}
                  />
                </div>
                <div className="inline-block" style={{ width: "130px" }}>
                  <TimePicker
                    className={`mx-0 sm:mx-2 text-16px  border  focus:ring-indigo-500 focus:border-indigo-500 block w-24 text-center border-gray-300 rounded-sm p-2 
                  ${
                    props.s2Error || props.overLapError
                      ? "border-red-500 text-red-500"
                      : "border-gray-300"
                  }`}
                    onChange={(date) =>
                      props.handleValues(props.keyEndTime2, date)
                    }
                    value={
                      props?.workingHours[props.keyEndTime2]
                        ? moment(props.workingHours[props.keyEndTime2])
                        : null
                    }
                    style={{
                      width: 120,
                    }}
                    allowClear={false}
                    format="hh:mm A"
                    use12Hours
                    minuteStep={5}
                    showNow={false}
                    placeholder={t("Select time")}
                  />
                </div>
                <span
                  className="px-2 cursor-pointer"
                  onClick={props.removeSlotInput}
                >
                  <svg
                    viewBox="0 0 16 16"
                    fill="#A2B8C6"
                    xmlns="https://www.w3.org/2000/svg"
                    className="w-4 h-4 inline-block cursor-pointer align-text-top"
                  >
                    <path d="M15.75 8A7.749 7.749 0 008 .25 7.749 7.749 0 00.25 8 7.749 7.749 0 008 15.75 7.749 7.749 0 0015.75 8zm-9.784 3.8a.375.375 0 01-.532 0L4.2 10.562a.375.375 0 010-.53L6.25 8 4.2 5.966a.375.375 0 010-.532L5.437 4.2a.375.375 0 01.532 0L8 6.25l2.034-2.05a.375.375 0 01.532 0l1.237 1.237a.375.375 0 010 .532L9.75 8l2.05 2.034a.375.375 0 010 .532l-1.238 1.237a.375.375 0 01-.53 0L8 9.75 5.966 11.8z"></path>
                  </svg>
                  <span className="px-1 add-remove-btn">{t("Remove")}</span>
                </span>
              </div>

              <div className="row flex-nowrap mb-1 text-red-500 text-13px px-0 sm:px-2">
                {props.s2Error}
              </div>
              <div className="row flex-nowrap mb-1 text-red-500 text-13px px-0 sm:px-2">
                {props.overLapError}
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default DayRow;

DayRow.propTypes = {
  handleValues: PropTypes.func,
  keyDay: PropTypes.string,
  keyStartTime: PropTypes.string,
  keyEndTime: PropTypes.string,
  store: PropTypes.object,
  values: PropTypes.object,
  label: PropTypes.string,
};
