import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function usePushBack(url, id) {
  const history = useHistory();
  useEffect(() => {
    if (!id) {
      history.push(url || "/app/dashboard/");
    }
  }, []);
}

export default usePushBack;
