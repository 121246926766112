import React from "react";
import { Tabs, Spin } from "antd";
import { useSelector } from "react-redux";
const ModalTabs = ({ children, setOpen, handleTabChange, loading = false }) => {
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  return (
    <div className="p-5  bg-white">
      <Spin spinning={isLoading || loading}>
        <div className="flex justify-end mb-4">
          <span onClick={() => setOpen()} className="cursor-pointer">
            <svg
              xmlns="https://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </span>
        </div>
        <div className="yandex-wrapper">
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            {children}
          </Tabs>
        </div>
      </Spin>
    </div>
  );
};

export default ModalTabs;
