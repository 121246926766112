import React from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactSortable } from "react-sortablejs";
import { SVG_DRAGDROP } from "svg/SVGs";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

const ModifierView = ({ mod, unlinkModFromGroup }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="flex items-center  w-madx sm:w-full  focus:outline-none border-b p-2 md:p-4 text-16px hover:bg-gray-100 cursor-pointer">
      <div className="w-5 h-5 me-4 mt-1.5 cursor-move flex-none">
        <SVG_DRAGDROP />
      </div>
      <h3 className="grow text-14px mx-2 min-230">
        {mod?.modifierId?.baseLanguage?.name}
      </h3>
      <h3 className=" text-14px mx-2 flex-none">
        <span className="font-semibold">Max Order Quantity:</span>
        {mod?.modifierId?.maximumOrderQuantity}
      </h3>

      <h3 className="text-14px mx-2 flex-none">
        <span className="font-semibold">AED:</span>
        {mod?.modifierId?.price}
      </h3>

      <div className="flex gap-2 justify-end px-0 md:ps-3 whitespace-nowrap text-center flex-none ">
        <button
          className="border border-blue-600  px-4 text-blue-600 h-8"
          onClick={() =>
            history.push("/app/dashboard/update-modifier", {
              modifier: {
                _id: mod?.modifierId?._id,
              },
            })
          }
        >
          {t("Edit")}
        </button>
        <button
          className="border border-red-600 px-4 text-red-600 h-8"
          onClick={() => unlinkModFromGroup(mod?.modifierId)}
        >
          {t("Delete")}
        </button>
      </div>
    </div>
  );
};

function ModifierDragger({
  modifiers,
  reOrderCategory,
  unlinkModFromGroup,
  setopenGroupModal,
}) {
  const { t } = useTranslation();

  return (
    <>
      {modifiers && modifiers?.length >= 1 ? (
        isMobile ? (
          <>
            {modifiers &&
              modifiers?.map((cat, index) => {
                return (
                  <ModifierView
                    mod={cat}
                    key={cat.modifierId._id}
                    unlinkModFromGroup={unlinkModFromGroup}
                  />
                );
              })}
          </>
        ) : (
          <ReactSortable
            list={modifiers}
            setList={() => {}}
            animation={300}
            onUpdate={(order) => {
              reOrderCategory(order);
            }}
          >
            {modifiers &&
              modifiers?.map((cat, index) => {
                return (
                  <ModifierView
                    mod={cat}
                    key={cat.modifierId._id}
                    unlinkModFromGroup={unlinkModFromGroup}
                  />
                );
              })}
          </ReactSortable>
        )
      ) : (
        <div className="flex justify-center w-full">
          <div className="py-16">
            <p>{t("There is no modifier attached")}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default ModifierDragger;
