import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
import { SettingsAction, LoaderAction } from "redux/actions";
import i18n from "../../i18n";
import { UTILS } from "utils";
import { SettingErrorAction } from "redux/actions";
let tryAgain = i18n.t("Please try again later");
const settingsAPi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updatePassword: builder.mutation({
      query: (body) => ({
        url: URL.UPDATE_PASSWORD,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            dispatch(SettingErrorAction.setPasswordError(null));
            UTILS.handleSuccesNotification(
              data?.message ?? "Password updated sucessfully"
            );
          }
          if (data?.success === 0) {
            let messageError = data?.message ?? tryAgain;
            dispatch(SettingErrorAction.setPasswordError(messageError));
          }
        } catch (err) {
          let messageError = err?.data?.message ?? tryAgain;
          dispatch(SettingErrorAction.setPasswordError(messageError));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    updatePhoneNumber: builder.mutation({
      query: (body) => ({
        url: URL.UPDATE_PHONE_NUMBER,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          const userDetail = UTILS.getCurrentUser();
          if (data?.success === 1) {
            UTILS.handleSuccesNotification(
              data?.message ?? "Phone  updated sucessfully"
            );
            if (data?.data?.phoneNumber) {
              UTILS.saveCurrentUser({
                ...userDetail,
                phoneNumber: data.data.phoneNumber,
              });
            }
          }
          if (data?.success === 0) {
            let msg = data?.message ?? tryAgain;
            UTILS.handleErrorNotification(msg);
          }
        } catch (err) {
          let msg = err?.data?.message ?? tryAgain;
          UTILS.handleErrorNotification(msg);
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    updateBrandName: builder.mutation({
      query: (body) => ({
        url: URL.UPDATE_BRAND_NAME,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        const language = await UTILS?.asyncLocalStorage.getLanguage();
        body.language = language;
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            dispatch(SettingErrorAction.setBrandError(null));
            UTILS.handleSuccesNotification(
              data?.message ?? "Brand name updated sucessfully"
            );
          } else {
            let Errormessage = data?.message ?? tryAgain;
            dispatch(SettingErrorAction.setBrandError(Errormessage));
          }
        } catch (err) {
          let Errormessage = err?.data?.message ?? tryAgain;
          dispatch(SettingErrorAction.setBrandError(Errormessage));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    updateDomainName: builder.mutation({
      query: (body) => ({
        url: URL.UPDATE_DOMAIN_NAME,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            dispatch(SettingErrorAction.setDomainError(null));
            UTILS.handleSuccesNotification(
              data?.message ?? "Domain name updated sucessfully"
            );
          } else {
            let Errormessage = data?.message ?? tryAgain;
            dispatch(SettingErrorAction.setDomainError(Errormessage));
          }
        } catch (err) {
          let Errormessage = err?.data?.message ?? tryAgain;
          dispatch(SettingErrorAction.setDomainError(Errormessage));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    updateBrandSettings: builder.mutation({
      query: (body) => ({
        url: URL.UPDATE_BRAND_SETTINGS,
        method: "POST",
        body: body,
        responseHandler: (response) => response.json(),
        validateStatus: (response, result) =>
          response.status === 200 && result.success === 1,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        const language = await UTILS?.asyncLocalStorage.getLanguage();
        body.language = language;
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.handleSuccesNotification(
              data?.message ?? "Settings updated sucessfully"
            );
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(data?.message ?? tryAgain);
          }
        } catch (err) {
          UTILS.handleErrorNotification(err?.error?.data?.message ?? tryAgain);
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),

    getSettings: builder.mutation({
      query: (body) => ({
        url: URL.GET_BRAND_SETTINGS,
        method: "POST",
        body: body,
      }),

      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        const userDetail = UTILS.getCurrentUser();
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;

          if (data?.success === 1 && data?.data?.setting) {
            dispatch(SettingsAction.setSettings(data.data.setting));
            dispatch(SettingErrorAction.setError(null));
            UTILS.saveCurrentUser({
              ...userDetail,
              temporaryDomainName: data?.data?.setting?.temporaryDomainName,
            });
          }
          if (data?.success === 0) {
            let Errormessage = data?.message ?? tryAgain;
            dispatch(SettingErrorAction.setError(Errormessage));
            dispatch(SettingsAction.setSettings({}));
            UTILS.handleErrorNotification(Errormessage);
          }
        } catch (err) {
          let Errormessage = err?.error?.data?.message ?? tryAgain;
          dispatch(SettingErrorAction.setError(Errormessage));
          UTILS.handleErrorNotification(Errormessage);
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
  }),
});
export const {
  useUpdatePasswordMutation,
  useUpdatePhoneNumberMutation,
  useUpdateBrandNameMutation,
  useUpdateDomainNameMutation,
  useUpdateBrandSettingsMutation,
  useGetSettingsMutation,
} = settingsAPi;
export default settingsAPi;
