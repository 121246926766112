import React, { useState } from "react";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
const OrderTabs = (props) => {
  const [activeTab, setActiveTab] = useState("Pending");
  const menus = [
    {
      id: "Pending",
      label: "Pending",
    },
    {
      id: "Preparing",
      label: "Preparing",
    },
    {
      id: "On The Way",
      label: "On The Way / Ready",
    },
    {
      id: "Scheduled",
      label: "Scheduled",
    },
    {
      id: "Refunds",
      label: "Order Refunds",
    },
  ];
  const { t } = useTranslation();
  const ORDER_STATUS = {
    PENDING: "PENDING",
    PREPARING: "PREPARING",
    ON_THE_WAY: "ON_THE_WAY",
    COMPLETE: "COMPLETE",
    CANCELED: "CANCELLED",
    REJECTED: "REJECTED",
    SCHEDULED: "SCHEDULED",
    REFUND: "REFUND",
  };

  const handleTabClick = (menu) => {
    setActiveTab(menu);
    if (menu === "Pending") props.handleTabStatus(ORDER_STATUS.PENDING);
    else if (menu === "Preparing")
      props.handleTabStatus(ORDER_STATUS.PREPARING);
    else if (menu === "On The Way")
      props.handleTabStatus(ORDER_STATUS.ON_THE_WAY);
    else if (menu === "Scheduled")
      props.handleTabStatus(ORDER_STATUS.SCHEDULED);
    else if (menu === "Refunds") props.handleTabStatus(ORDER_STATUS.REFUND);
  };

  const getStyleForSelectedTab = (activeTab) => {
    switch (activeTab) {
      case "Pending":
        return "bg-white border-t-4 border-theme-red font-semibold ";
      case "Preparing":
        return "bg-white border-t-4 border-green-600 font-semibold ";
      case "On The Way":
        return "bg-white border-t-4 border-yellow-500 font-semibold ";
      case "Scheduled":
        return "bg-white border-t-4 border-theme-red font-semibold ";
      case "Refunds":
        return "bg-white border-t-4 border-theme-red font-semibold ";
      default:
        break;
    }
  };

  const getPendingOrders = (status) => {
    if (
      UTILS.isValidObject(["pendingOrdersCount"], props.orderCountData) &&
      Array.isArray(props.orderCountData.pendingOrdersCount)
    ) {
      for (
        let index = 0;
        index < props.orderCountData.pendingOrdersCount.length;
        index++
      ) {
        const element = props.orderCountData.pendingOrdersCount[index];
        if (
          UTILS.isValidObject(["orderStatus"], element) &&
          element.orderStatus === status
        ) {
          return element.count;
        }
      }
      return "0";
    }
  };

  const getRefundableOrders = () => {
    console.log(
      "props.refundableOrdersCountData",
      props.refundableOrdersCountData?.orders?.refundableOrdersCount
    );
    if (
      props.refundableOrdersCountData?.orders?.refundableOrdersCount &&
      Array.isArray(
        props.refundableOrdersCountData?.orders?.refundableOrdersCount
      )
    ) {
      return props.refundableOrdersCountData?.orders?.refundableOrdersCount?.reduce(
        (acc, statusCount) => {
          return statusCount.count + acc;
        },
        0
      );
    }
    return "0";
  };

  const getOrderCountComponent = (menu) => {
    switch (menu) {
      case "Pending":
        return (
          <span className="bg-theme-red px-1 py-0.5 mx-2 text-white rounded-sm text-10px">
            {getPendingOrders(ORDER_STATUS.PENDING)}
          </span>
        );
      case "Preparing":
        return (
          <span className="bg-green-600 px-1 py-0.5 mx-2 text-white rounded-sm text-10px">
            {getPendingOrders(ORDER_STATUS.PREPARING)}
          </span>
        );
      case "On The Way":
        return (
          <span className="bg-yellow-500 px-1 py-0.5 mx-2 text-white rounded-sm text-10px">
            {getPendingOrders(ORDER_STATUS.ON_THE_WAY)}
          </span>
        );
      case "Scheduled":
        return (
          <span className="bg-blue-500 px-1 py-0.5 mx-2 text-white rounded-sm text-10px">
            {getPendingOrders(ORDER_STATUS.SCHEDULED)}
          </span>
        );

      case "Refunds":
        const refundableCount = getRefundableOrders();
        return (
          <span className="bg-theme-red px-1 py-0.5 mx-2 text-white rounded-sm text-10px">
            {refundableCount}
          </span>
        );
      default:
        break;
    }
  };
  const TabButtons = ({ activeTab }) => {
    return (
      <nav className="md:ml-auto md:mr-auto flex flex-wrap text-base bg-white border-t text-gray-500 font-semibold border-b">
        {menus.map((menu, index) => (
          <button
            disabled={props.loading}
            key={index}
            className={
              "hover:text-blue-400 text-sm py-3 px-0 md:px-14  mx-0 md:mx-0.5 border-gray-400 font-semibold  w-1/3 md:w-auto " +
              (activeTab === menus[index].id
                ? getStyleForSelectedTab(activeTab)
                : "bg-gray-100")
            }
            onClick={() => {
              handleTabClick(menu.id);
            }}
          >
            {t(menu.label)}
            {getOrderCountComponent(menu.id)}
          </button>
        ))}
      </nav>
    );
  };

  TabButtons.propTypes = {
    activeTab: PropTypes.string,
  };
  return (
    <div>
      <TabButtons activeTab={activeTab} />
    </div>
  );
};

OrderTabs.propTypes = {
  orderCountData: PropTypes.object,
  handleTabStatus: PropTypes.func,
};

export default OrderTabs;
