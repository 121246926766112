import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CONSTANTS as C } from "config";
import { Button, Input, Spin } from "antd";
import { UTILS } from "utils";
import { useUpdatePasswordMutation } from "redux/settings/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { SettingErrorAction } from "redux/actions";
const ProfilePasswordUpdate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const { isError, passwordError } = useSelector(
    (state) => state?.SettingErrorReducer
  );
  const [state, setState] = useState({
    values: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    errors: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };

  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
      },
      errors: {
        ...prevState.errors,
        [key]: value,
      },
    }));
  };

  const resetState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      values: {},
      errors: {},
    }));
  };

  const ErrorMessages = [
    t("Old password is a required field"),
    t("New password is a required field"),
    t("Confirm password is a required field"),
    t("The new password and the confirm password must be the same."),
  ];

  const validateInput = () => {
    dispatch(SettingErrorAction.setPasswordError(null));
    let isValid = true;
    if (!state?.values?.newPassword) {
      isValid = false;
      updateState(C.NEW_PASSWORD, ErrorMessages[1]);
    }
    if (!state?.values?.oldPassword) {
      isValid = false;
      updateState(C.OLD_PASSWORD, ErrorMessages[0]);
    }
    if (!state?.values?.confirmPassword) {
      isValid = false;
      updateState(C.CONFIRM_PASSWORD, ErrorMessages[2]);
    }
    if (state?.values?.newPassword !== state?.values?.confirmPassword) {
      updateState(C.CONFIRM_PASSWORD, ErrorMessages[3]);
      updateState(C.NEW_PASSWORD, ErrorMessages[3]);
      isValid = false;
    }
    return isValid;
  };
  const updateMyPassword = async () => {
    const language = await UTILS?.asyncLocalStorage.getLanguage();
    if (!validateInput()) {
      return;
    } else {
      const obj = {
        oldPassword: state.values.oldPassword,
        newPassword: state.values.newPassword,
        language,
      };
      await updatePassword(obj).unwrap();
      resetState();
    }
  };
  return (
    <>
      <Spin spinning={isLoading}>
        {(isError || passwordError) && (
          <div className="text-red-600">{passwordError}</div>
        )}
        <h3 className="text-19px font-medium mt-12">{t("Security")}</h3>
        <div className="pb-2 w-full mt-4">
          <div className="flex justify-between">
            <label className="text-gray-600 font-bold text-14px">
              {t("Current password")}
            </label>
          </div>
          <div className="text-red-500">
            {state?.errors?.oldPassword && state?.errors?.oldPassword}
          </div>
          <div className="flex justify-between">
            <Input.Password
              type="password"
              name="oldPassword"
              compact
              placeholder={t("Enter current password")}
              value={state?.values?.oldPassword}
              className={`py-2 rounded ${
                state?.errors?.oldPassword ? "border-theme-red" : ""
              }`}
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>
        <div className="pb-2 w-full mt-4">
          <div className="flex justify-between">
            <label className="text-gray-600 font-bold text-14px">
              {t("New password")}
            </label>
          </div>
          <div className="text-red-500">
            {state?.errors?.newPassword && state?.errors?.newPassword}
          </div>
          <div className="flex justify-between">
            <Input.Password
              compact
              name="newPassword"
              placeholder={t("Enter new password")}
              value={state?.values?.newPassword}
              className={`py-2 rounded ${
                state?.errors?.newPassword ? "border-theme-red" : ""
              }`}
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>

        <div className="pb-2 w-full mt-4">
          <div className="flex justify-between">
            <label className="text-gray-600 font-bold text-14px">
              {t("Confirm new password")}
            </label>
          </div>
          <div className="text-red-500">
            {state?.errors?.confirmPassword && state?.errors?.confirmPassword}
          </div>
          <div className="flex justify-between">
            <Input.Password
              name="confirmPassword"
              compact
              placeholder={t("Confirm new password")}
              value={state?.values?.confirmPassword}
              className={`py-2 rounded ${
                state?.errors?.confirmPassword ? "border-theme-red" : ""
              }`}
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          <Button
            size="large"
            className="text-white border-blue-500 rounded bg-blue-500 px-8"
            onClick={updateMyPassword}
          >
            {t("Update")}
          </Button>
        </div>
      </Spin>
    </>
  );
};

export default ProfilePasswordUpdate;
