import React from "react";
import useInterval from "use-interval";
import Context from "./index";
import { getPendingOrders } from "utils/Utils";
import { UTILS } from "utils";
import { post } from "api/api/axiosSetup";
const LiveOrderProvider = (props) => {
  const audio = new Audio(
    "https://uapp-live-com.s3.amazonaws.com/UAPP-MEDIA/PENDING_ORDER_MUSIC.mpeg"
  );

  const reurnPendingOrdersCount = async () => {
    const userDetail = UTILS.getCurrentUser();
    if (!userDetail?.token) return;
    try {
      const res = await post("order/getAllPendingOrdersCount", {
        token: userDetail.token,
      });

      const count = getPendingOrders(res, "PENDING");
      return count;
    } catch (error) {}
  };

  useInterval(() => {
    const playAlarm = async () => {
      const count = await reurnPendingOrdersCount();
      updateTitle(count);
      if (count > 0) {
        playAudio();
      }
    };
    playAlarm();
  }, 5000);

  const updateTitle = (count) => {
    if (count && process.env.REACT_APP_TITLE) {
      document.title = `(${count}) ${process.env.REACT_APP_TITLE}`;
    } else if (process.env.REACT_APP_TITLE) {
      document.title = process.env.REACT_APP_TITLE;
    } else {
      document.title = "Dashboard";
    }
  };

  const playAudio = async () => {
    const playAlarm = () => {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            audio.play();
          })
          .catch((error) => {});
      }
    };
    playAlarm();
  };

  return <Context.Provider>{props.children}</Context.Provider>;
};

export default LiveOrderProvider;
