import React from "react";
import { Button, Spin } from "antd";
import TextInput from "components/useForm/FormInput";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const AddPoints = ({ onClose, onSubmit, register, errors }) => {
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const { t } = useTranslation();
  return (
    <Spin spinning={isLoading}>
      <form onSubmit={onSubmit}>
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto bg-white ">
          <div className="">
            <div className="p-5 flex-auto justify-center">
              <h2 className="text-16px text-blue-600 font-bold pb-4 text-center">
                {t("Add Loyalty Points")}
              </h2>
              <div className="px-4 text-auto pb-2">
                <TextInput
                  label={t("Number of points")}
                  placeHolder={t("Cashback value")}
                  name="MANUAL_CREDITED.amount"
                  register={register}
                  mandatory={true}
                  rules={{
                    required: t("Number of points is a required field"),
                    min: {
                      value: 1,
                      message: t("Number of points should be greater than 0"),
                    },
                  }}
                  error={errors?.MANUAL_CREDITED?.amount?.message}
                />
              </div>
            </div>
            <div className="p-3  mt-2 text-center md:block">
              <Button
                type="default"
                size="large"
                className="mx-2 w-24"
                onClick={() => onClose()}
              >
                {t("Cancel")}
              </Button>
              <Button
                className="w-24"
                type="primary"
                size="large"
                htmlType="submit"
              >
                {t("Add")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Spin>
  );
};

export default AddPoints;
