import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import moment from "moment";
import { useSelector } from "react-redux";
const PaymentLinkTable = ({ paymentLinks, handlePageChange, setPage }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const PAID_STATUS = "PAID";
  const renderStatus = (val) => (
    <span
      className={`${
        val === PAID_STATUS
          ? "bg-green-50 text-green-600"
          : "bg-yellow-50 text-yellow-600"
      } w-fit px-2 py-1`}
    >
      {val === PAID_STATUS ? t("Paid") : t("Pending")}
    </span>
  );

  const columns = [
    {
      title: t("Store name"),
      dataIndex: "storeName",
      align: "initial",
      key: "storeName",
    },
    {
      title: t("Payment link id"),
      dataIndex: "cartId",
      align: "initial",
      key: "cartId",
    },
    {
      key: "Amount",
      title: t("Amount"),
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      title: t("Created by"),
      dataIndex: "createdBy",
      align: "initial",
      key: "Created By",
    },
    {
      title: t("Created at"),
      align: "initial",
      key: "createdAt",
      render: (val, row, index) => {
        return moment(val.createdAt).format("DD/MM/YYYY hh:mm A");
      },
    },

    {
      title: t("Status"),
      dataIndex: "linkStatus",
      align: "initial",
      key: "linkStatus",
      render: renderStatus,
    },
  ];
  return (
    <div className="">
      <Card styleClasses="px-2 py-2">
        <Table
          columns={columns}
          dataSource={paymentLinks?.data}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 20,
            total: paymentLinks.totalPages,
            showQuickJumper: false,
            showSizeChanger: false,
          }}
          onChange={(pagination, filters, sorter) => {
            setPage(pagination.current);
            handlePageChange(pagination.current);
          }}
          size="small"
          locale={{ emptyText: t("No Data") }}
          rowKey={(record) => record._id}
        />
      </Card>
    </div>
  );
};

export default PaymentLinkTable;
