import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StoreInfoSection from "./StoreInfoSection";
import StoreMapSection from "./StoreMapSection";
import Delivery from "./Delivery";
import DineIn from "./DineIn";
import PickUp from "./Pickup";
import { CatelougeContext } from "store/contexts/Catelouge";
import { ACTIONS } from "config";
import { useHistory } from "react-router-dom";
import { Spin, Button } from "antd";
import { API } from "api";
import { UTILS, STOTE_UTILS } from "utils";
import { CONSTANTS as C } from "config";
import PageHeading from "components/PageHeading";
import { useTranslation } from "react-i18next";
import { isValidSlots } from "utils/Utils";
import useCatalogue from "hooks/useCatalogue";
import { errorNotification } from "utils/Utils";

const AddStoreComponent = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const catelougeType = useCatalogue();
  const { dispatch } = React.useContext(CatelougeContext);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const [store, setStore] = useState(UTILS.getDumbStoreObject());
  const [loading, setLoading] = useState(false);
  const [addStage, setAddStage] = useState(0);
  const [currencies, setCurrenies] = useState();

  const getCurrencyTypes = async () => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_CURRENCY_TYPES({
      token: userDetail.token,
    });
    if (result && result.success === 1 && UTILS.isValidObject(["data"], result))
      setCurrenies(result.data);
    else {
    }
  };

  useEffect(() => {
    getCurrencyTypes();
  }, []);

  const handleStoreMapSection = (key, val) => {
    if (key === C.KEY_MAP_ADDRESS) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [C.KEY_MAP_ADDRESS]: val,
        },
        errors: {
          ...prevState.errors,
          [C.KEY_MAP_ADDRESS]: "",
        },
      }));
    } else if (key === "latlng") {
      const lat = val.lat;
      const lng = val.lng;
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          storeLocation: {
            coordinates: [lat, lng],
          },
        },
        errors: {
          ...prevState.errors,
          storeLocation: {
            coordinates: ["", ""],
          },
        },
      }));
    } else if (key === C.KEY_MANUAL_ADDRESS) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [C.KEY_MANUAL_ADDRESS]: val,
        },
        errors: {
          ...prevState.errors,
          [C.KEY_MANUAL_ADDRESS]: "",
        },
      }));
    }
  };

  const handleStoreInfo = (key, val) => {
    if (
      key === C.KEY_EMAIL ||
      key === C.KEY_PHONE ||
      key === C.KEY_IMAGE_URL ||
      key === C.KEY_TAX_RATE ||
      key === C.AVERAGE_PREP ||
      key === C.PICKUP_AVERAGE_PREP ||
      key === C.DINEIN_AVERAGE_PREP ||
      key === C.KEY_IS_TAX_INCLUSIVE ||
      key === C.KEY_CITY ||
      key === C.KEY_TIMEZONE ||
      key === C.KEY_WHATSAPP_PHONE
    ) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [key]: val,
        },
        errors: {
          ...prevState.errors,
          [key]: "",
        },
      }));
    } else if (key === C.KEY_DELETE_IMG_URL) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [key]: "",
        },
        errors: {
          ...prevState.errors,
          [key]: "",
        },
      }));
    } else {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [C.BASE_LANGUAGE]: {
            ...prevState.values[C.BASE_LANGUAGE],
            [key]: val,
          },
        },
        errors: {
          ...prevState.errors,
          [C.BASE_LANGUAGE]: {
            ...prevState.errors[C.BASE_LANGUAGE],
            [key]: "",
          },
        },
      }));
    }
  };

  const validateInputs = () => {
    let objValid = STOTE_UTILS.VALIDATE_INPUTS(store);
    if (!objValid.isFormValid) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
        },
        errors: {
          ...prevState.errors,
          ...objValid.errors,
        },
      }));
    }
    return objValid.isFormValid;
  };

  const getCurrencyId = () => {
    if (Array.isArray(currencies) && currencies.length > 0)
      return currencies[0]._id;
    else return null;
  };

  const didMount = React.useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (store?.values?.workingHours) {
        checkDeliveryTimeSlots(store.values.workingHours, "workingHours");
      }
    } else {
      didMount.current = true;
    }
  }, [store.values.workingHours]);

  useEffect(() => {
    if (didMount.current) {
      if (store?.values?.workingHoursForPickup) {
        checkDeliveryTimeSlots(
          store.values.workingHoursForPickup,
          "workingHoursForPickup"
        );
      }
    } else {
      didMount.current = true;
    }
  }, [store.values.workingHoursForPickup]);

  useEffect(() => {
    if (didMount.current) {
      if (store?.values?.workingHoursForDinein) {
        checkDeliveryTimeSlots(
          store.values.workingHoursForDinein,
          "workingHoursForDinein"
        );
      }
    } else {
      didMount.current = true;
    }
  }, [store.values.workingHoursForDinein]);

  const checkDeliveryTimeSlots = (workingHours, type) => {
    let slotCheck = isValidSlots(workingHours);
    if (!slotCheck.isFormValid) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
        },
        errors: {
          ...prevState.errors,
          [type]: {
            ...prevState.errors[type],
            ...slotCheck.errors,
          },
        },
      }));
    } else {
      setStore((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [type]: {},
        },
      }));
    }
    return slotCheck.isFormValid;
  };

  const checkSlotValidation = () => {
    let slotCheck = isValidSlots(store.values.workingHours);
    if (!slotCheck.isFormValid) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
        },
        errors: {
          ...prevState.errors,
          workingHours: {
            ...prevState.errors.workingHours,
            ...slotCheck.errors,
          },
        },
      }));
    }
    return slotCheck.isFormValid;
  };
  const addStoreFunction = async () => {
    store.values.mobileNumber = await UTILS.formatPhoneNumber(
      store.values.mobileNumber
    );
    store.values.whatsAppContact = await UTILS.formatPhoneNumber(
      store.values.whatsAppContact
    );
    const storeobj = UTILS.getStoreObj(store.values);
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    let s = { ...storeobj };
    s["brandId"] = userDetail.brandId;
    s["currency"] = getCurrencyId();
    s["language"] = currentLanguage;
    const result = await API.ADD_STORE({
      token: userDetail.token,
      store: s,
    });

    if (result?.success === 1) {
      UTILS.successNotification(
        t("Store Created Successfully, Please Add Delivery Areas!")
      );
      router.goBack();
    } else {
      console.log({ result });
      errorNotification(
        result?.message ||
          t("We're sorry, but something went wrong. Please try again")
      );
    }

    dispatch({ type: ACTIONS.STORES_CHANGED });
    setLoading(false);
  };

  const isValidTiming = () => {
    if (
      !checkDeliveryTimeSlots(store?.values?.workingHours, "workingHours") &&
      store.values[C.ISDELIVERYENABLED]
    ) {
      UTILS.errorNotification(
        t("Please check timing in section", {
          serviceType: t("Delivery"),
        })
      );
      return false;
    }

    if (
      !checkDeliveryTimeSlots(
        store.values?.workingHoursForDinein,
        "workingHoursForDinein"
      ) &&
      store.values[C.ISDINEINENABLED]
    ) {
      UTILS.errorNotification(
        t("Please check timing in section", {
          serviceType: t("Contactless Dining"),
        })
      );
      return false;
    }

    if (
      !checkDeliveryTimeSlots(
        store?.values?.workingHoursForPickup,
        "workingHoursForPickup"
      ) &&
      store.values[C.ISPICKUPENABLED]
    ) {
      UTILS.errorNotification(
        t("Please check timing in section", {
          serviceType: t("Pickup"),
        })
      );
      return false;
    }
    return true;
  };

  const addStore = async () => {
    if (!validateInputs()) {
    } else if (!isValidTiming()) {
      return;
    } else {
      addStoreFunction();
    }
  };

  const getBuisnessType = () => {
    const userDetail = UTILS.getCurrentUser();
    return userDetail?.catalogueType;
  };
  return (
    <div className="h-full">
      <PageHeading heading={t("Create Store")} />
      <Spin spinning={loading}>
        <div className="flex justify-between my-2">
          <div className="">
            <h3 className="text-gray-900 text-16px px-2">
              {t(
                "Please fill the complete information to Create Store. Once you finish setting up your store, Activate it to get orders."
              )}
            </h3>
          </div>
          <div className="mx-4 text-right">
            {addStage >= 2 && catelougeType?.showStore && (
              <Button
                type="primary"
                size="medium"
                className="w-36"
                onClick={() => addStore()}
              >
                {t("Create Store")}
              </Button>
            )}
          </div>
        </div>
        <div className="flex w-full flex-wrap border-t min-h-full">
          <div className="w-full md:w-3/12 border-r min-h-full px-6 py-4">
            <h3 className="text-19px py-2">{t("Create Store")}</h3>
            <ul className="">
              <li
                key={0}
                onClick={() => setAddStage(0)}
                className={
                  "py-2 mx-4 text-indigo-700 text-15px cursor-pointer hover:text-black list-decimal " +
                  (addStage == 5 ? "my-10" : "")
                }
              >
                {t("Basic Information")}
              </li>
              <li
                key={1}
                onClick={() => setAddStage(1)}
                className={
                  "py-2 mx-4 text-indigo-700 text-15px cursor-pointer hover:text-black list-decimal " +
                  (addStage == 5 ? "my-10" : "")
                }
              >
                {t("Store Location")}
              </li>
              <li
                key={2}
                onClick={() => setAddStage(2)}
                className={
                  "py-2 mx-4 text-indigo-700 text-15px cursor-pointer hover:text-black list-decimal " +
                  (addStage == 5 ? "my-10" : "")
                }
              >
                {t("Delivery")}
              </li>
              <li
                key={3}
                onClick={() => setAddStage(3)}
                className={
                  "py-2 mx-4 text-indigo-700 text-15px cursor-pointer hover:text-black list-decimal " +
                  (addStage == 5 ? "my-10" : "")
                }
              >
                {t("Pickup")}
              </li>
              {getBuisnessType() !== C.BRAND_BASED && (
                <li
                  key={4}
                  onClick={() => setAddStage(4)}
                  className={
                    "py-2 mx-4 text-indigo-700 text-15px cursor-pointer hover:text-black list-decimal " +
                    (addStage == 5 ? "my-10" : "")
                  }
                >
                  {t("Contactless Dining")}
                </li>
              )}
            </ul>
          </div>

          <div className="w-full md:w-9/12 px-4 sm:px-8 h-full">
            <div
              className={"w-full " + (addStage === 0 ? "visible" : "hidden")}
            >
              <StoreInfoSection
                store={store}
                handleValues={handleStoreInfo}
                step={addStage}
                setStep={setAddStage}
                type="create"
              />
            </div>
            <div
              className={"w-full " + (addStage === 1 ? "visible" : "hidden")}
            >
              <StoreMapSection
                store={store}
                handleValues={handleStoreMapSection}
                step={addStage}
                setStep={setAddStage}
                type="create"
                setStore={setStore}
                zoom={8}
              />
            </div>

            <div
              className={"w-full " + (addStage === 2 ? "visible" : "hidden")}
            >
              <Delivery
                store={store}
                handleStoreInfo={handleStoreInfo}
                addStore={addStore}
                step={addStage}
                setStep={setAddStage}
                type="create"
                setStore={setStore}
              />
            </div>
            <div
              className={"w-full " + (addStage === 3 ? "visible" : "hidden")}
            >
              <PickUp
                store={store}
                handleStoreInfo={handleStoreInfo}
                addStore={addStore}
                step={addStage}
                setStep={setAddStage}
                type="create"
                setStore={setStore}
              />
            </div>
            <div
              className={"w-full " + (addStage === 4 ? "visible" : "hidden")}
            >
              <DineIn
                store={store}
                handleStoreInfo={handleStoreInfo}
                addStore={addStore}
                step={addStage}
                setStep={setAddStage}
                type="create"
                setStore={setStore}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default AddStoreComponent;
