import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const CountryCityList = ({ COUNTRIES, isChecked, saveArea }) => {
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div>
        {COUNTRIES?.length > 0 &&
          COUNTRIES?.map((country) => (
            <div className="flex w-full py-1" key={country._id}>
              <div className="h-6 w-6"></div>
              <div className="flex justify-between w-full mx-4">
                {country?.isAdded ? (
                  <React.Fragment>
                    <div className="text-gray-500">
                      {currentLanguage === "EN"
                        ? country?.countryNameEN
                        : country?.countryNameAR}
                    </div>
                    <div className="text-gray-500">{t("Already added")}</div>
                  </React.Fragment>
                ) : (
                  <div
                    className="flex w-full justify-between"
                    onClick={() => saveArea(country)}
                  >
                    <div>{country?.[`countryName${currentLanguage}`]}</div>
                    {isChecked(country) ? (
                      <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200 check"></span>
                    ) : (
                      <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200"></span>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default CountryCityList;
