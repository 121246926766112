import TextInput from "components/TextInput";
import React, { useEffect, useState } from "react";
import { UTILS } from "utils";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import PriceInput from "components/PriceInput";
import PageHeading from "components/PageHeading";
import Card from "components/Card";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  useUpDateModifierMutation,
  useGetModifierOptionInfoMutation,
} from "redux/modifiers/service";
import { errorHandler } from "api/api/errorHandler";
import { isValidValue } from "utils/Utils";
import usePushBack from "hooks/usePushBack";

const UpdateModifier = () => {
  // Hooks
  const router = useHistory();
  const { t } = useTranslation();
  const history = useHistory();

  const [upDateModifier] = useUpDateModifierMutation();
  const [getModifierOptionInfo] = useGetModifierOptionInfoMutation();
  // States
  const [loading, setLoading] = useState(false);

  const [modifier, setModifier] = useState({
    values: {
      baseLanguage: {
        name: "",
      },
      price: 0,
      imageURL: null,
      sku: "",
      minimumOrderQuantity: 1,
      maximumOrderQuantity: 1,
    },
    errors: {
      baseLanguage: {
        name: "",
      },
      price: "",
      minimumOrderQuantity: "",
      maximumOrderQuantity: "",
    },
  });

  /* A function that is used to set the basic info in the state. */
  const handleBasicInfo = (key, val) => {
    setModifier((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        baseLanguage: {
          ...prevState.values.baseLanguage,
          [key]: val,
        },
        translations: {
          en: {
            name: val,
          },
          ar: {
            name: val,
          },
        },
      },
      errors: {
        ...prevState.errors,
        baseLanguage: {
          ...prevState.errors.baseLanguage,
          [key]: "",
        },
      },
    }));
  };

  /* A function that is used to set the basic info in the state. */
  const handleValues = (key, val) => {
    setModifier((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const validateInputs = () => {
    let isValid = true;
    let obj = {
      baseLanguage: {
        name: "",
      },
      price: "",
      minimumOrderQuantity: null,
      maximumOrderQuantity: "",
    };

    if (
      !modifier?.values?.baseLanguage?.name ||
      UTILS.hasWhiteSpace(modifier.values.baseLanguage.name)
    ) {
      isValid = false;
      obj.baseLanguage.name = t("Enter modifier Name");
    }

    if (!isValidValue(modifier?.values?.price)) {
      isValid = false;
      obj.price = t("Price is a required field");
    }

    if (!isValidValue(modifier?.values?.maximumOrderQuantity)) {
      isValid = false;
      obj.maximumOrderQuantity = t("Maximum Order Quantity is a requird field");
    }
    if (modifier?.values?.maximumOrderQuantity <= 0) {
      isValid = false;
      obj.maximumOrderQuantity = t(
        "Maximum Order Quantity must be greater zero"
      );
    }

    setModifier((prevState) => ({
      ...prevState,
      errors: obj,
    }));
    return isValid;
  };

  const updatedSingleModifier = async () => {
    const isValid = validateInputs();
    if (!isValid) return;
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const data = await upDateModifier({
        brandId: userDetail.brandId,
        modifierId: history.location.state.modifier._id,
        ...modifier?.values,
        price: +parseFloat(modifier?.values?.price).toFixed(2),
      });
      if (data?.data?.success === 1) {
        UTILS.successNotification(
          data?.data?.message ?? t("Modifier Updated Successfully")
        );
        history.push("/app/dashboard/modifiers-list");
      } else {
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      setLoading(false);
    }
  };

  const getSingleModifierInfo = async (modifierId) => {
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const data = await getModifierOptionInfo({
        brandId: userDetail?.brandId,
        modifierId,
      });
      if (data?.data?.success === 1 && data.data?.data?.length >= 1) {
        setModifier((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            ...data.data?.data[0],
          },
        }));
      } else {
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      setLoading(false);
    }
  };

  const updatePrice = (key, val) => {
    const value = UTILS.NumberConversion(val);
    let Num = Number(value);
    handleValues(key, Num);
  };

  useEffect(() => {
    if (history.location?.state?.modifier?._id)
      getSingleModifierInfo(history?.location?.state?.modifier?._id);
  }, [history?.location?.state?.modifier?._id]);

  return (
    <Spin spinning={loading}>
      <div>
        <PageHeading heading={t("Update Modifier")} />
        <div className="px-3 py-3">
          <Card>
            <h1 className="px-2 md:px-6 pt-4 text-19px text-blue-600">
              {t("Update Modifier")}
            </h1>
            <h2 className="px-2 md:px-6 pt-1 text-gray-500">
              {t("Please enter complete details for better user experience")}
            </h2>
            <div className="flex flex-wrap justify-between py-4">
              <div className="w-full md:w-1/2 px-2 md:px-6 py-1">
                <div className="pb-3">
                  <TextInput
                    label={t("Name")}
                    value={modifier?.values?.baseLanguage?.name}
                    handleValues={handleBasicInfo}
                    keyVal={"name"}
                    mandatory={true}
                    error={modifier?.errors?.baseLanguage?.name}
                  />
                </div>
                <div className="pb-3">
                  <PriceInput
                    label={t("Price") + " (" + t("AED") + ")"}
                    value={modifier?.values?.price}
                    keyVal={"price"}
                    handleValues={handleValues}
                    mandatory={true}
                    error={modifier?.errors?.price}
                  />
                </div>

                <div className="pb-3">
                  <PriceInput
                    label={t("Maximum Order Quantity")}
                    value={modifier?.values?.maximumOrderQuantity}
                    keyVal={"maximumOrderQuantity"}
                    handleValues={updatePrice}
                    mandatory={true}
                    error={modifier?.errors?.maximumOrderQuantity}
                  />
                </div>
                <div className="pb-3">
                  <TextInput
                    label={t("SKU (Stock Keeping Unit)")}
                    value={modifier?.values?.sku}
                    keyVal={"sku"}
                    handleValues={handleValues}
                    mandatory={false}
                    error={modifier?.errors?.sku}
                  />
                </div>
              </div>
              <div className="py-2 w-full md:w-1/2"></div>
              <div className="px-2 md:px-6 py-2">
                <Button
                  className="me-2 w-28"
                  type="primary"
                  size="large"
                  onClick={updatedSingleModifier}
                >
                  {t("Update")}
                </Button>
                <Button
                  className="w-28"
                  type="default"
                  size="large"
                  onClick={() => router.goBack()}
                >
                  {t("Back")}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Spin>
  );
};

function UpdateModifiersContainer() {
  const router = useHistory();
  usePushBack(
    "/app/dashboard/modifiers-list",
    router?.location?.state?.modifier?._id
  );
  return <UpdateModifier />;
}

UpdateModifier.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
  addModifierOption: PropTypes.func,
};
export default UpdateModifiersContainer;
