import React, { useEffect, useState } from "react";
import { Spin, Button } from "antd";
import { CONSTANTS as C } from "config";
import BrandingPreviewMenuWidget from "../components/BrandingPreviewMenuWidget";
import BrandingPlatformCustomizeAndWidget from "../components/BrandingPlatformCustomizeAndWidget";
import s from "../components/branding.module.css";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import {
  useGetSettingsMutation,
  useUpdateBrandSettingsMutation,
} from "redux/settings/settingsSlice";
import { useSelector } from "react-redux";
const Branding = () => {
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { t } = useTranslation();
  const [updateBrandSettings] = useUpdateBrandSettingsMutation();
  const [getSettings] = useGetSettingsMutation();
  const [imgLoading, setImgLoading] = useState(false);
  const [settings, setSettings] = useState({
    brandImageHomeBG: "",
    brandLogoFirst: "",
    taglineEN: "",
    titleEN: "",
    sloganEN: "",
    color: "",
    textColor: "",
  });
  const [orderNowPopupConfig, setOrderNowPopupConfig] = useState({
    iconUrl: "",
    message: "",
    buttonText: "",
    title: "",
  });
  const fetchSettings = async () => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
    };
    const data = await getSettings(obj).unwrap();
    if (data?.success === 1) {
      if (data?.data?.setting?.orderNowPopupConfig) {
        setOrderNowPopupConfig(data?.data?.setting?.orderNowPopupConfig);
      }
      if (data?.data?.setting?.color) {
        let obj = { ...data.data.setting };
        obj.color = "#" + data.data.setting.color;
        if (data?.data?.setting?.textColor) {
          obj.textColor = "#" + data.data.setting.textColor;
        }
        setSettings(obj);
      } else {
        let obj = { ...data?.data?.setting };
        obj.color = C.SETTINGS_DEFAULT_COLOUR;
        obj.textColor = C.DEFAULT_TEXT_COLOUR;
        setSettings(obj);
      }
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      UTILS.errorNotification(t("You can only upload JPG/PNG file"));
    }
    const F_Size = file.size / 1024;
    const isLt1M = F_Size <= C.MAX_IMAGE_SIZE;
    console.info("Image Size After Crop=", Math.floor(F_Size), "Kb");
    if (!isLt1M) {
      UTILS.errorNotification(t("Image must be smaller than 2MB"));
    }
    return isJpgOrPng && isLt1M;
  }
  const uploadButton = (
    <div>
      {setImgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t("Upload")}</div>
    </div>
  );

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleChange = async (info, type) => {
    settings.color = settings.color.replace("#", "");
    settings.textColor = settings.textColor.replace("#", "");
    if (info.file.status === "uploading") {
      setImgLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setImgLoading(false);
      let obj = { ...settings };
      let obj2 = { ...orderNowPopupConfig };
      if (type === "iconUrl") {
        obj2[type] = info?.file?.response?.data?.url;
      } else {
        obj[type] = info?.file?.response?.data?.url;
      }
      setOrderNowPopupConfig(obj2);
      setSettings(obj);
      const userDetail = UTILS.getCurrentUser();
      obj.brandId = userDetail.brandId;
      obj.orderNowPopupConfig = obj2;
      await updateBrandSettings(obj).unwrap();
      await fetchSettings();
    }
  };
  const saveSettings = async () => {
    const isValid = UTILS.validateAdminSetting(orderNowPopupConfig);
    if (isValid) {
      settings.color = settings.color.replace("#", "");
      settings.textColor = settings.textColor.replace("#", "");
      const userDetail = UTILS.getCurrentUser();
      let obj = { ...settings };
      obj.orderNowPopupConfig = orderNowPopupConfig;
      obj.brandId = userDetail.brandId;
      await updateBrandSettings(obj).unwrap();
      await fetchSettings();
    } else {
      return;
    }
  };
  return (
    <Spin spinning={isLoading || imgLoading}>
      <div className={s.branding}>
        <div className={s.design}>
          <BrandingPlatformCustomizeAndWidget
            {...{
              setSettings,
              settings,
              orderNowPopupConfig,
              setOrderNowPopupConfig,
              handleChange,
              onPreview,
              uploadButton,
              beforeUpload,
            }}
          />
          <BrandingPreviewMenuWidget
            {...{
              settings,
              orderNowPopupConfig,
            }}
          />
        </div>
      </div>
      <div className="flex mx-8 pb-8">
        <Button
          type="success"
          size="large"
          className="bg-blue-500 px-20 text-white rounded text-15px border-blue-500"
          onClick={saveSettings}
        >
          {t("Save Setting")}
        </Button>
      </div>
    </Spin>
  );
};

export default Branding;
