import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DeliveryFeeInfo from "./DeliveyFeeInfo";
import { useSelector } from "react-redux";

const TableItemsDetail = (props) => {
  const { t } = useTranslation();

  const getSubTotal = () => {
    if (props?.order?.discountApplied?.discountAmountAvailedByCustomer) {
      return (
        props?.order?.itemsTotalPrice +
        props?.order?.discountApplied?.discountAmountAvailedByCustomer
      )?.toFixed(2);
    }
    if (props?.order?.loyaltyApplied?.redeemAmount) {
      return (
        props?.order?.itemsTotalPrice +
        props?.order?.loyaltyApplied?.redeemAmount
      )?.toFixed(2);
    }
    return props?.order?.itemsTotalPrice?.toFixed(2);
  };

  const getItemRow = (index, item) => {
    return (
      <tr className="border-b border-gray-200" key={index}>
        <td width="10%" className="px-1 py-4">
          {item.quantity + "x"}
        </td>
        <td className="px-1 py-4" width="50%">
          {getItemInfo(item)}
        </td>
        <td className="text-right py-4 px-2">{item.price?.toFixed(2)}</td>
      </tr>
    );
  };
  const getItemInfo = (item) => {
    return (
      <div>
        <p>{item?.baseLanguage?.title}</p>
        <p className="px-4">
          {item?.sections &&
            Array.isArray(item.sections) &&
            item?.sections.map((m, index) => {
              return getModifiersInfo(m, index);
            })}
        </p>
      </div>
    );
  };

  const getModifiersInfo = (modifier, index) => {
    return (
      <div key={index}>
        <p className="text-gray-400 text-11px">
          {modifier?.baseLanguage?.sectionName}
        </p>
        <p className="text-10px">
          {modifier &&
            modifier?.options.map((op, index) => {
              return getOptions(op, index);
            })}
        </p>
      </div>
    );
  };

  const getOptions = (op, index) => {
    return (
      <div key={index}>
        <p className="text-11px">
          {`${op?.baseLanguage?.optionName} ${"( "}${op?.price?.toFixed(2)} 
          ${t("AED")} ${")"}`}
          <span className="mx-2">
            {t("Quantity")}: {op?.quantity ? op?.quantity : 1}
          </span>
        </p>
      </div>
    );
  };
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  return (
    <div className="flex px-7">
      <table className="w-full">
        <tbody className="bg-white font-medium">
          {props?.order &&
            props?.order?.orderedMenu?.map((item, index) => {
              return getItemRow(index, item);
            })}
          <tr>
            <td
              colSpan="2"
              className={`${
                currentLanguage === "EN" ? "text-right" : "text-left"
              } py-2 text-gray-500`}
            >
              {t("Sub Total AED")}
            </td>

            <td
              width="10%"
              className={`${
                currentLanguage === "EN" ? "text-right" : "text-left"
              } py-2 px-2`}
            >
              {getSubTotal()}
            </td>
          </tr>
          {props?.order?.discountApplied?.discountAmountAvailedByCustomer ? (
            <tr>
              <td
                colSpan="2"
                // className="text-right py-2 "
                className={`${
                  currentLanguage === "EN" ? "text-right" : "text-left"
                } py-2 text-green-400`}
              >
                {t("Discount Amount AED")}
              </td>
              <td
                width="10%"
                className={`${
                  currentLanguage === "EN" ? "text-right" : "text-left"
                } py-2 px-2 text-green-400`}
              >
                {" - "}
                {props?.order?.discountApplied?.discountAmountAvailedByCustomer?.toFixed(
                  2
                )}
              </td>
            </tr>
          ) : null}
          {props?.order?.loyaltyApplied?.redeemAmount ? (
            <tr>
              <td
                colSpan="2"
                className={`${
                  currentLanguage === "EN" ? "text-right" : "text-left"
                } py-2 text-green-400`}
              >
                {t("Loyalty Discount AED")}
              </td>
              <td
                width="10%"
                className={`${
                  currentLanguage === "EN" ? "text-right" : "text-left"
                } py-2 px-2 text-green-400`}
              >
                {" - "}
                {props?.order?.loyaltyApplied?.redeemAmount?.toFixed(2)}
              </td>
            </tr>
          ) : null}

          <DeliveryFeeInfo order={props.order} />
          <tr>
            <td
              colSpan="2"
              className={`${
                currentLanguage === "EN" ? "text-right" : "text-left"
              } py-2 text-gray-500`}
            >
              {t("Value Added Tax")}
            </td>
            <td
              className={`${
                currentLanguage === "EN" ? "text-right" : "text-left"
              } py-2 px-2`}
            >
              {props?.order?.taxRate + "%"}
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              className={`${
                currentLanguage === "EN" ? "text-right" : "text-left"
              } py-2 text-gray-500`}
            >
              {t("Final Total AED")}
            </td>
            <td
              className={`${
                currentLanguage === "EN" ? "text-right" : "text-left"
              } py-2 px-2`}
            >
              {props?.order?.totalOrderPrice?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TableItemsDetail.propTypes = {
  order: PropTypes.object,
};
export default TableItemsDetail;
