import React from "react";
import Stripe from "./Stripe";
import { CONSTANTS as C } from "config";
import { getORderPaymentGateway } from "utils/Utils";

function Index({
  orderInfo,
  children,
  paymentGatewayInfoModal,
  setPaymentGatewayInfoModal,
}) {
  return (
    <>
      {getORderPaymentGateway(orderInfo) === C.PAYMENT_GATWAYS.STRIPE ? (
        <Stripe
          orderInfo={orderInfo}
          {...{ paymentGatewayInfoModal, setPaymentGatewayInfoModal }}
        >
          {children}
        </Stripe>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default Index;
