import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import PageHeading from "components/PageHeading";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { isValidSlots } from "utils/Utils";
import StoreInfoSection from "./StoreInfoSection";
import StoreMapSection from "./StoreMapSection";
import Delivery from "./Delivery";
import Pickup from "./Pickup";
import DineIn from "./DineIn";
import { CatelougeContext } from "store/contexts/Catelouge";
import { API } from "api";
import { CONSTANTS as C } from "config";
import { UTILS } from "utils";
import { ACTIONS } from "config";
import { logToSentry } from "utils/Utils";
import { STOTE_UTILS } from "utils";
import { errorHandler } from "api/api/errorHandler";
const UpdateStoreComponent = (props) => {
  const router = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const { dispatch } = React.useContext(CatelougeContext);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const [store, setStore] = useState(UTILS.getDumbStoreObject());
  const [loading, setLoading] = useState(false);
  const [updateStage, setUpdateStage] = useState(0);
  const sideMenu = UTILS.getSideMenuListForAddUpdateStore();

  const getStoreInfo = async (sId) => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_STORE_INFO_WITH_STORE_ID({
      storeId: sId,
      brandId: userDetail.brandId,
      token: userDetail.token,
      language: currentLanguage,
    });
    if (result?.success === 1 && result?.data?.store?.length >= 1)
      setStore(getStoreInformation(result.data));
    else {
    }
    setLoading(false);
  };

  const handleStoreMapSection = (key, val) => {
    if (key === C.KEY_MAP_ADDRESS) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [C.KEY_MAP_ADDRESS]: val,
        },
        errors: {
          ...prevState.errors,
          [C.KEY_MAP_ADDRESS]: "",
        },
      }));
    } else if (key === "latlng") {
      const lat = val.lat;
      const lng = val.lng;
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          storeLocation: {
            coordinates: [lat, lng],
          },
        },
        errors: {
          ...prevState.errors,
          storeLocation: {
            coordinates: ["", ""],
          },
        },
      }));
    } else if (key === C.KEY_MANUAL_ADDRESS) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [C.KEY_MANUAL_ADDRESS]: val,
        },
        errors: {
          ...prevState.errors,
          [C.KEY_MANUAL_ADDRESS]: "",
        },
      }));
    }
  };

  const handleStoreInfo = (key, val) => {
    if (
      key === C.KEY_EMAIL ||
      key === C.KEY_PHONE ||
      key === C.KEY_IMAGE_URL ||
      key === C.KEY_TAX_RATE ||
      key === C.AVERAGE_PREP ||
      key === C.PICKUP_AVERAGE_PREP ||
      key === C.DINEIN_AVERAGE_PREP ||
      key === C.KEY_IS_TAX_INCLUSIVE ||
      key === C.KEY_CITY ||
      key === C.KEY_WHATSAPP_PHONE
    ) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [key]: val,
        },
        errors: {
          ...prevState.errors,
          [key]: "",
        },
      }));
    } else if (key === C.KEY_DELETE_IMG_URL) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [key]: "",
        },
        errors: {
          ...prevState.errors,
          [key]: "",
        },
      }));
    } else {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [C.BASE_LANGUAGE]: {
            ...prevState.values[C.BASE_LANGUAGE],
            [key]: val,
          },
        },
        errors: {
          ...prevState.errors,
          [C.BASE_LANGUAGE]: {
            ...prevState.errors[C.BASE_LANGUAGE],
            [key]: "",
          },
        },
      }));
    }
  };
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (store.values.workingHours) {
        checkIsValidTimeSlots(store.values.workingHours, "workingHours");
      }
    } else {
      didMount.current = true;
    }
  }, [store.values.workingHours]);

  useEffect(() => {
    if (didMount.current) {
      if (store?.values?.workingHoursForPickup) {
        checkIsValidTimeSlots(
          store.values.workingHoursForPickup,
          "workingHoursForPickup"
        );
      }
    } else {
      didMount.current = true;
    }
  }, [store.values.workingHoursForPickup]);

  useEffect(() => {
    if (didMount.current) {
      if (store?.values?.workingHoursForDinein) {
        checkIsValidTimeSlots(
          store.values.workingHoursForDinein,
          "workingHoursForDinein"
        );
      }
    } else {
      didMount.current = true;
    }
  }, [store.values.workingHoursForDinein]);

  const checkIsValidTimeSlots = (workingHours, type) => {
    let slotCheck = isValidSlots(workingHours);
    if (!slotCheck.isFormValid) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
        },
        errors: {
          ...prevState.errors,
          [type]: {
            ...prevState.errors[type],
            ...slotCheck.errors,
          },
        },
      }));
    } else {
      setStore((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [type]: {},
        },
      }));
    }
    return slotCheck.isFormValid;
  };

  const validateInputs = () => {
    let objValid = STOTE_UTILS.VALIDATE_INPUTS(store);
    if (!objValid.isFormValid) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
        },
        errors: {
          ...prevState.errors,
          ...objValid.errors,
        },
      }));
    }
    return objValid.isFormValid;
  };

  const getStoreInformation = (s) => {
    if (!s || !s?.store || !Array.isArray(s?.store) || s?.store?.length < 1) {
      logToSentry(`Invalid store data ${store}${s}`, "error");
      return null;
    }
    const obj = { ...store, values: s.store[0] };
    return obj;
  };

  useEffect(() => {
    if (location?.state?.storeId) getStoreInfo(location?.state?.storeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.storeId]);

  const updateStoreFunction = async () => {
    try {
      store.values.mobileNumber = await UTILS.formatPhoneNumber(
        store.values.mobileNumber
      );
      store.values.whatsAppContact = await UTILS.formatPhoneNumber(
        store.values.whatsAppContact
      );
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      let s = { ...store.values };
      const storeobj = UTILS.getStoreObj(store.values);
      storeobj.storeId = s._id;
      storeobj.brandId = userDetail.brandId;
      storeobj.currency = s.currency;
      storeobj.language = currentLanguage || "EN";
      const result = await API.updateStore({
        token: userDetail.token,
        store: storeobj,
      });
      if (result?.success === 1) {
        UTILS.successNotification(t("Store Updated Successfully"));
        router.goBack();
      } else {
        UTILS.errorNotification(errorHandler(result));
      }
      dispatch({ type: ACTIONS.STORES_CHANGED });
      setLoading(false);
    } catch (error) {
      UTILS.errorNotification(errorHandler(error));
      setLoading(false);
    }
  };

  const isValidTiming = () => {
    if (
      !checkIsValidTimeSlots(store?.values?.workingHours, "workingHours") &&
      store.values[C.ISDELIVERYENABLED]
    ) {
      UTILS.errorNotification(
        t("Please check timing in section", {
          serviceType: t("Delivery"),
        })
      );
      return false;
    }

    if (
      !checkIsValidTimeSlots(
        store.values?.workingHoursForDinein,
        "workingHoursForDinein"
      ) &&
      store.values[C.ISDINEINENABLED]
    ) {
      UTILS.errorNotification(
        t("Please check timing in section", {
          serviceType: t("Contactless dining"),
        })
      );
      return false;
    }

    if (
      !checkIsValidTimeSlots(
        store?.values?.workingHoursForPickup,
        "workingHoursForPickup"
      ) &&
      store.values[C.ISPICKUPENABLED]
    ) {
      UTILS.errorNotification(
        t("Please check timing in section", {
          serviceType: t("Pickup"),
        })
      );
      return false;
    }
    return true;
  };

  const updateStore = async () => {
    if (!validateInputs()) {
    } else if (!isValidTiming()) {
      return;
    } else {
      updateStoreFunction();
    }
  };

  const getBuisnessType = () => {
    const userDetail = UTILS.getCurrentUser();

    return userDetail?.catalogueType;
  };

  const getSideItem = (name, id) => {
    return (
      <li
        key={id}
        onClick={() => setUpdateStage(id)}
        className={
          "py-2 mx-4 text-indigo-700 text-15px cursor-pointer hover:text-black list-decimal " +
          (id === 5 ? "my-10" : "")
        }
      >
        {name}
      </li>
    );
  };

  return (
    <div className="">
      <PageHeading heading={t("Update Store")} />
      <Spin spinning={loading}>
        <div className="flex justify-between my-2">
          <div className="">
            <h3 className="text-gray-900 text-16px px-2">
              {t("Please fill the complete information to Update Store.")}
            </h3>
          </div>
          <div className="mx-4 text-right">
            <Button
              type="primary"
              size="medium"
              className="w-36"
              onClick={() => updateStore()}
            >
              {t("Update Store")}
            </Button>
          </div>
        </div>
        <div className="flex w-full flex-wrap border-t min-h-full">
          <div className="w-full md:w-3/12 border-r min-h-full px-6 sm:px-4 py-4">
            <h3 className="text-19px py-2">
              {store.values ? store.values[C.BASE_LANGUAGE].name : ""}
            </h3>
            <ul className="">
              {sideMenu &&
                sideMenu.map((item) => {
                  if (
                    getBuisnessType() === "BRAND_BASED_CATALOGUE" &&
                    item.id === 4
                  ) {
                    return null;
                  }

                  return getSideItem(t(item.name), item.id);
                })}
            </ul>
          </div>
          <div className="w-full md:w-9/12 px-4 sm:px-8 h-full delivery-sec">
            <div
              className={"w-full " + (updateStage === 0 ? "visible" : "hidden")}
            >
              <StoreInfoSection
                store={store}
                handleValues={handleStoreInfo}
                step={updateStage}
                setStep={setUpdateStage}
                type="update"
              />
            </div>
            <div
              className={"w-full " + (updateStage === 1 ? "visible" : "hidden")}
            >
              <StoreMapSection
                store={store}
                handleValues={handleStoreMapSection}
                step={updateStage}
                setStep={setUpdateStage}
                type="update"
                setStore={setStore}
                zoom={21}
              />
            </div>

            <div
              className={"w-full " + (updateStage === 2 ? "visible" : "hidden")}
            >
              <Delivery
                store={store}
                handleStoreInfo={handleStoreInfo}
                addStore={updateStore}
                type="update"
                setStore={setStore}
              />
            </div>
            <div
              className={"w-full " + (updateStage === 3 ? "visible" : "hidden")}
            >
              <Pickup
                store={store}
                setStore={setStore}
                handleStoreInfo={handleStoreInfo}
                addStore={updateStore}
                type="update"
              />
            </div>
            <div
              className={"w-full " + (updateStage === 4 ? "visible" : "hidden")}
            >
              <DineIn
                store={store}
                handleStoreInfo={handleStoreInfo}
                addStore={updateStore}
                type="update"
                setStore={setStore}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
export default UpdateStoreComponent;

UpdateStoreComponent.propTypes = {
  values: PropTypes.object,
};
