import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { errorNotification } from "utils/Utils";
import { successNotification } from "utils/Utils";
import useGetAllZones from "./useGetAllZones";

function useUpdateZone() {
  const dispatch = useDispatch();
  const { getAddedDeliveryZones } = useGetAllZones("UPDATE");
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const updateZone = async ({ payload, onClose }) => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const response = await API.updateDistanceBaseDeliveryZone(payload);
      if (response?.success === 1 && response?.data) {
        successNotification(response?.message);
        await getAddedDeliveryZones(selectedStore?.value);
        onClose();
      } else {
        errorNotification(errorHandler(response?.message));
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return updateZone;
}

export default useUpdateZone;
