import { useEffect, useRef } from "react";
import moment from "moment";

const useCurrentLang = () => {
  let language = useRef("");
  useEffect(() => {
    const locale = JSON.parse(localStorage.getItem("__DIRECTION___"));
    const selected = locale ? locale.lang : moment.locale();
    language.current = selected;
  }, []);
  return language?.current;
};
export default useCurrentLang;
