import { Spin } from "antd";
import React from "react";
import LinkUnLinkMenuWithStoresModal from "./LinkUnLinkMenuWithStoresModal";
import { useTranslation } from "react-i18next";

export default function CreateNewMenuModal({
  handleMenuNameChange,
  loading,
  stores,
  menus,
  selectedStores,
  setselectedStores,
  newMenuState,
}) {
  const { t } = useTranslation();
  return (
    <Spin spinning={loading}>
      <div className="modal">
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Menu Name")}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            autoComplete="off"
            value={newMenuState?.name}
            onChange={handleMenuNameChange}
            placeholder={t("Enter menu name")}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Menu Description")}
          </label>
          <input
            autoComplete="description"
            type="text"
            id="description"
            name="description"
            value={newMenuState?.description}
            onChange={handleMenuNameChange}
            placeholder={t("Enter menu Description")}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      </div>
      <LinkUnLinkMenuWithStoresModal
        stores={stores}
        menus={menus}
        selectedStores={selectedStores}
        setselectedStores={setselectedStores}
        loading={loading}
      />
    </Spin>
  );
}
