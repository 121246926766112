import React from "react";
const LanguageToggle = ({ onClick, selected, authenticated }) => {
  return (
    <div
      className={`flex ${
        !authenticated ? "" : "bg-white"
      } justify-end px-8 pt-4 pb-2`}
    >
      <div className="bg-gray-400 inline-block rounded-full">
        <button
          onClick={() => onClick("en")}
          className={`border-0 ${
            selected === "en" ? "bg-blue-900" : ""
          }  text-white rounded-full px-4 py-1`}
        >
          English
        </button>
        <button
          onClick={() => onClick("ar")}
          className={`border-0 ${
            selected === "ar" ? "bg-blue-900" : ""
          }  text-white rounded-full px-4 py-1`}
        >
          العربية
        </button>
      </div>
    </div>
  );
};

export default LanguageToggle;
