import { Spin, Table } from "antd";
import Card from "components/Card";
import PageHeading from "components/PageHeading";
import React, { useEffect, useState } from "react";
import { UTILS } from "utils";
import { API } from "api";
import { useTranslation } from "react-i18next";
import { useGetAllBrandCustomersMutation } from "redux/loyalty/loyalty-service";
const Customers = () => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const { t } = useTranslation();
  const [getAllBrandCustomers] = useGetAllBrandCustomersMutation();

  const columns = [
    {
      title: t("Mobile"),
      dataIndex: "phoneNumber",
      align: "initial",
      key: "phoneNumber",
      render: (val, row, index) => {
        return <span dir="ltr">{val}</span>;
      },
    },
  ];
  const getBrandCustomers = async () => {
    setLoading(true);
    const { data } = await getAllBrandCustomers();
    if (data?.success === 1 && data?.data?.users?.length > 0) {
      console.log({ getAllBrandCustomers: data.data.users });
      setCustomers(data.data.users);
    } else {
      UTILS.handleSuccesNotification(t("No Customer Report Found"));
      setCustomers([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBrandCustomers();
  }, []);

  return (
    <div>
      <PageHeading heading={t("Customers")} />
      <div className="m-5">
        <Spin spinning={loading}>
          <Card styleClasses="px-2 py-2">
            <Table
              locale={{ emptyText: t("No Data") }}
              columns={columns}
              dataSource={customers}
              pagination={{ position: ["bottomCenter"] }}
              size="small"
            />
          </Card>
        </Spin>
      </div>
    </div>
  );
};
export default Customers;
