import Dashboard from "features/dashboard/container/dashboard";
import UpdateStore from "features/store/containers/updateStore";
import AddStore from "features/store/containers/addStore";
import ManageAreas from "features/shipping_methods/areas/containers/ManageAreas";
import Menu from "features/menu/containers/menu";
import StoreList from "features/store/containers/storeList";
import LiveOrders from "features/orders/containers/liveOrders";
import CreateAdmin from "features/admin/containers/CreateAdmin";
import ItemContainer from "features/menu/containers/ItemContainer";
import CreateItem from "features/menu/components/CreateItem";
import UpdateItem from "features/menu/components/UpdateItem";
import CreateModifier from "features/menu/components/CreateModifier";
import Modifiers from "features/menu/containers/Modifiers";
import UpdateModifier from "features/menu/components/UpdateModifier";
import Packages from "features/packages/containers/Packages";
import OrderHistory from "features/orders/containers/OrderHistory";
import Inventory from "features/inventory/containers/inventory";
import Integrations from "features/integrations/containers/integrations";
import Customers from "features/customers/containers/customers";
import Marketing from "features/marketing/containers/marketing";
import GetStarted from "features/onboarding/containers/GetStarted";
import Orders from "features/reporting/containers/Orders";
import PaymentLinks from "features/reporting/containers/PaymentLinks";
import Payouts from "features/reporting/containers/Payouts";
import CustomersReport from "features/reporting/containers/CustomersReport";
import Discount from "features/discount/containers/Discount";
import AddDiscount from "features/discount/containers/AddDiscount";
import UpdateDiscount from "features/discount/containers/UpdateDiscount";
import Branding from "features/settings/containers/Branding";
import Profile from "features/settings/containers/Profile";
import BusinessSettings from "features/settings/containers/BusinessSettings";
import Help from "features/help/containers/help";
import * as R_CONST from "routes/Constants";

const publicRoutes = [
  {
    path: "/app/dashboard",
    component: Dashboard,
  },

  {
    path: "/app/dashboard/help",
    component: Help,
  },
  {
    path: "/app/dashboard/onboarding",
    component: GetStarted,
  },
  {
    path: "/app/dashboard/updatestore",
    component: UpdateStore,
  },
  {
    path: "/app/dashboard/stores",
    component: StoreList,
  },
  {
    path: R_CONST.ADD_AREA,
    component: ManageAreas,
  },
  {
    path: "/app/dashboard/orderhistory",
    component: OrderHistory,
  },
  {
    path: "/app/dashboard/liveorders",
    component: LiveOrders,
  },
  {
    path: "/app/dashboard/subscription",
    component: Packages,
  },
  {
    path: "/app/dashboard/inventory",
    component: Inventory,
  },
  {
    path: "/app/dashboard/integrations",
    component: Integrations,
  },
  {
    path: "/app/dashboard/customers",
    component: Customers,
  },

  {
    path: "/app/dashboard/marketing",
    component: Marketing,
  },

  {
    path: "/app/dashboard/settings/profile",
    component: Profile,
  },
];
const adminRoutes = [
  {
    path: "/app/dashboard/addstore",
    component: AddStore,
  },
  {
    path: "/app/dashboard/settings/branding",
    component: Branding,
  },
  {
    path: "/app/dashboard/settings/business",
    component: BusinessSettings,
  },
  // {
  //   path: "/app/dashboard/reporting/payouts",
  //   component: Payouts,
  // },
  {
    path: "/app/dashboard/userroles",
    component: CreateAdmin,
  },
  {
    path: "/app/dashboard/menu",
    component: Menu,
  },
  {
    path: "/app/dashboard/items",
    component: ItemContainer,
  },
  {
    path: "/app/dashboard/reporting/orders",
    component: Orders,
  },
  {
    path: "/app/dashboard/reporting/customers",
    component: CustomersReport,
  },
  {
    path: "/app/dashboard/reporting/payments",
    component: PaymentLinks,
  },
  {
    path: "/app/dashboard/createitem",
    component: CreateItem,
  },
  {
    path: "/app/dashboard/updateitem",
    component: UpdateItem,
  },
  {
    path: "/app/dashboard/modifiers",
    component: Modifiers,
  },
  {
    path: "/app/dashboard/createmodifier",
    component: CreateModifier,
  },
  {
    path: "/app/dashboard/updatemodifier",
    component: UpdateModifier,
  },
  {
    path: "/app/dashboard/discount",
    component: Discount,
  },
  {
    path: "/app/dashboard/adddiscount",
    component: AddDiscount,
  },
  {
    path: "/app/dashboard/updatediscount",
    component: UpdateDiscount,
  },
];

const Routes = [...publicRoutes, ...adminRoutes];
export default Routes;
