import React from "react";
import PropTypes from "prop-types";

const ServiceTypeItem = (props) => {
  return (
    <div
      onClick={() => {
        window?.scrollTo(0, 0);
        props.onSubmitnextStep(1, props.value);
      }}
      className="font-semibold bg-gray-100 py-4 rounded-lg text-center w-52 m-2 cursor-pointer hover:bg-theme-blue-color hover:text-white"
    >
      {props.name}
    </div>
  );
};

ServiceTypeItem.propTypes = {
  onSubmitnextStep: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};
export default ServiceTypeItem;
