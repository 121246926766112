import React from "react";
import { Table } from "antd";
import Card from "components/Card";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const ViewPaymentTable = ({ paymentLink }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const PAID_STATUS = t("PAID");
  const renderStatus = (val) => (
    <span
      className={`${
        val === PAID_STATUS
          ? "bg-green-50 text-green-600"
          : "bg-yellow-50 text-yellow-600"
      } w-fit px-2 py-1`}
    >
      {val === PAID_STATUS ? t("Paid") : t("Pending")}
    </span>
  );
  const columns = [
    {
      title: t("Created by"),
      dataIndex: "createdBy",
      key: "name",
    },
    {
      title: t("Creation date"),
      dataIndex: "paymentDate",
      align: "initial",
      key: "paymentDate",
      render: (val, row, index) => {
        return moment.unix(row.linkCreationDate).format("DD/MM/YYYY");
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      title: t("Payment link id"),
      dataIndex: "cartId",
      key: "paymentId",
    },
    {
      title: t("Status"),
      dataIndex: "linkStatus",
      key: "status",
      render: renderStatus,
    },
  ];

  return (
    <div className="my-0 sm:my-5 w-full ">
      <Card styleClasses="px-2 py-2">
        <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
          {paymentLink && (
            <Table
              columns={columns}
              dataSource={paymentLink}
              pagination={false}
              size="small"
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default ViewPaymentTable;
