import React from "react";
import UpdateStoreComponent from "../components/UpdateStoreComponent";
import { useHistory } from "react-router-dom";
import usePushBack from "hooks/usePushBack";

const UpdateStore = () => {
  const router = useHistory();
  usePushBack("/app/dashboard/stores", router?.location?.state?.storeId);
  return <UpdateStoreComponent />;
};
export default UpdateStore;
