import useGetMenus from "./useGetMenus";
import { UTILS } from "utils";
import { API } from "api";
import { LoaderAction } from "redux/actions";
import { useDispatch } from "react-redux";
import { useMenuContext } from "store/contexts/Menus";

function useMakeDuplicateMenu() {
  const getAllMenus = useGetMenus();
  const dispatch = useDispatch();
  const { updateState } = useMenuContext();

  const cloneMenu = async ({ menuId, menu }) => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const brandId = UTILS.getCurrentUser()?.brandId;
      const response = await API.cloneMenu({
        brandId,
        cloneMenuId: menuId,
        baseLanguage: {
          name: menu.name,
        },
        ...(menu?.description?.trim() && {
          description: menu.description,
        }),
      });
      if (response?.success === 1) UTILS.successNotification(response?.message);
      else UTILS.handleErrorNotification(response?.message);
      await getAllMenus();
      updateState("cloneMenuModal", false);
    } catch (error) {
      UTILS.handleErrorNotification(error.message);
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return cloneMenu;
}

export default useMakeDuplicateMenu;
