import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const UserLoyaltyPoints = ({ userWalletBalance, setOpen }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  return (
    <div className="my-12">
      <h3 className="text-gray-900  text-17px font-semibold">
        {t("Loyalty Points")}
      </h3>
      <p className="text-gray-500">
        <span>
          {t("Customer current loyalty points balance", {
            amount: new Intl.NumberFormat(currentLanguage, {
              style: "currency",
              currency: "AED",
            }).format(userWalletBalance?.balance || 0),
          })}

          {/* <strong>
            {new Intl.NumberFormat(currentLanguage, {
              style: "currency",
              currency: "AED",
            }).format(userWalletBalance?.balance || 0)}
          </strong> */}
        </span>
      </p>

      <div className="pt-4">
        <Button
          type="primary"
          size="medium"
          onClick={() => {
            setOpen(true);
          }}
        >
          {t("Add Loyalty Points")}
        </Button>
      </div>
    </div>
  );
};

export default UserLoyaltyPoints;
