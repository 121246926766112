import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { LogisticAction } from "redux/actions";
import OrderButton from "./components/Button";
import Modal from "components/Modal";
import Yandex from "./yandex/Yandex";
import CareemNOW from "./careem-now/CareemNow";
import DeliveryZone from "./delivery-zone/DeliveryZone";
import LyveGlobal from "./lyve-global/index";
import { LoaderAction } from "redux/actions";
import { UTILS } from "utils";
import {
  useGetOrderInfoByIdMutation,
  useGetYandexDeliveryMethodsMutation,
} from "redux/Logistic/LogisticService";
import { API } from "api";
import { logToSentry } from "utils/Utils";
import { ErrorAction } from "redux/actions";
import { INTEGRATION_NAMES } from "./constants";
import LogisticModalSelector from "./LogisticModalSelector";
import { useLogisticContext } from "./context";
function SelectedLogistics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logisticState, updateState } = useLogisticContext();

  const [openType, setopenType] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const { changeDeliveryModal, orderInfo: order } = useSelector(
    (state) => state.LogisticReducer
  );
  const [getOrderInfoById] = useGetOrderInfoByIdMutation();
  const [getYandexDeliveryMethods] = useGetYandexDeliveryMethodsMutation();

  /**
   * It checks if the order has an active integration with the name passed in the parameter.
   * @param name - The name of the integration.
   * @returns isIntegrationDialogOpen
   */

  const isInterated = (name) => {
    let isIntegrationDialogOpen = false;
    if (order && UTILS.isValidObject(["activeIntegrations"], order)) {
      if (Array.isArray(order.activeIntegrations)) {
        for (let index = 0; index < order?.activeIntegrations.length; index++) {
          const element = order?.activeIntegrations[index];
          if (
            element?.name === name &&
            element?.category === "LOGISTIC" &&
            element?.isActive === true
          ) {
            isIntegrationDialogOpen = true;
          }
        }
      }
    }
    return isIntegrationDialogOpen;
  };

  const isSelected = (odr) => {
    const selectedDelivery = UTILS.getSelectedDelivery(odr);
    setSelectedIntegration(selectedDelivery);
    return selectedDelivery;
  };
  useEffect(() => {
    setSelectedIntegration(isSelected(order));
  }, [order]);

  function shouldRenderYandexButton(order) {
    return order && isInterated(INTEGRATION_NAMES.YANDEX);
  }
  const getAllDeliveryMethods = async () => {
    const userDetail = UTILS.getCurrentUser();
    let obj = {
      brandId: userDetail.brandId,
      orderId: order?._id,
    };
    if (obj?.brandId && obj?.orderId) {
      await getYandexDeliveryMethods(obj);
    }
  };

  const checkIfDeliverySelected = (deliveryType) => {
    // Get the currently selected delivery type
    // If no delivery type has been selected
    if (selectedIntegration === INTEGRATION_NAMES.STORE) {
      // Check which delivery type the user wants to use
      if (deliveryType === INTEGRATION_NAMES.TALABAT) {
        // setTalabat(true);
      } else if (deliveryType === INTEGRATION_NAMES.MARSOOL) {
        sendToMursool();
      } else if (deliveryType === INTEGRATION_NAMES.YANDEX) {
        dispatch(LogisticAction.setSyncData());
        dispatch(LogisticAction.toggleYandexModal(true));
        getAllDeliveryMethods();
      } else if (deliveryType === INTEGRATION_NAMES.CAREEM_NOW) {
        dispatch(LogisticAction.toggleCareemModal(true));
      } else if (deliveryType === INTEGRATION_NAMES.DELIVERY_ZONE) {
        dispatch(LogisticAction.toggleDeliverZoneModal(true));
      } else if (deliveryType === INTEGRATION_NAMES.LYVE_GLOBAL) {
        updateState("islyveGlobalModalOpen", true);
      } else {
        logToSentry("User wants to use an unknown logistic type.");
      }
    }

    // If the user wants to check information about the selected delivery type
    if (
      selectedIntegration !== INTEGRATION_NAMES.STORE &&
      selectedIntegration === deliveryType
    ) {
      // Check which delivery type the user wants to view information about
      if (deliveryType === INTEGRATION_NAMES.TALABAT) {
        // setTalabat(true);
      } else if (deliveryType === INTEGRATION_NAMES.MARSOOL) {
      } else if (deliveryType === INTEGRATION_NAMES.YANDEX) {
        dispatch(LogisticAction.setSyncData());
        dispatch(LogisticAction.toggleYandexModal(true));
        getAllDeliveryMethods();
      } else if (deliveryType === INTEGRATION_NAMES.CAREEM_NOW) {
        dispatch(LogisticAction.toggleCareemModal(true));
      } else if (deliveryType === INTEGRATION_NAMES.DELIVERY_ZONE) {
        dispatch(LogisticAction.toggleDeliverZoneModal(true));
      } else if (deliveryType === INTEGRATION_NAMES.LYVE_GLOBAL) {
        updateState("islyveGlobalModalOpen", true);
      } else {
        logToSentry(
          "User wants to view information about an unknown logistic type."
        );
      }
    }

    // If the user wants to change the delivery type
    if (
      selectedIntegration !== INTEGRATION_NAMES.STORE &&
      selectedIntegration !== deliveryType
    ) {
      setSelectedDelivery(selectedDelivery);
      dispatch(LogisticAction.toggleDeliveryModal(true));
      if (deliveryType === INTEGRATION_NAMES.TALABAT) {
        setopenType(INTEGRATION_NAMES.TALABAT);
      } else if (deliveryType === INTEGRATION_NAMES.MARSOOL) {
        setopenType(INTEGRATION_NAMES.MARSOOL);
      } else if (deliveryType === INTEGRATION_NAMES.YANDEX) {
        dispatch(LogisticAction.setSyncData());
        setopenType(INTEGRATION_NAMES.YANDEX);
        getAllDeliveryMethods();
      } else if (deliveryType === INTEGRATION_NAMES.CAREEM_NOW) {
        setopenType(INTEGRATION_NAMES.CAREEM_NOW);
      } else if (deliveryType === INTEGRATION_NAMES.DELIVERY_ZONE) {
        setopenType(INTEGRATION_NAMES.DELIVERY_ZONE);
      } else if (deliveryType === INTEGRATION_NAMES.LYVE_GLOBAL) {
        setopenType(INTEGRATION_NAMES.LYVE_GLOBAL);
      } else {
        logToSentry("User wants to change to an unknown logistic type.");
      }
    }
  };

  /**
   * A function that is called when the user presses the "Yes" or "No" button.
   */
  // const OrderDeliveryCheckModal = () => {
  //   isSelected(order);

  //   const pressYes = () => {
  //     if (openType === INTEGRATION_NAMES.YANDEX) {
  //       dispatch(LogisticAction.toggleYandexModal(true));
  //     } else if (openType === INTEGRATION_NAMES.TALABAT) {
  //       //  setTalabat(true);
  //     } else if (openType === INTEGRATION_NAMES.MARSOOL) {
  //       sendToMursool();
  //     } else if (openType === INTEGRATION_NAMES.CAREEM_NOW) {
  //       dispatch(LogisticAction.toggleCareemModal(true));
  //     } else if (openType === INTEGRATION_NAMES.DELIVERY_ZONE) {
  //       dispatch(LogisticAction.toggleDeliverZoneModal(true));
  //     } else {
  //       logToSentry("User wants to change to an unknown logistic type. 166");
  //     }
  //     dispatch(LogisticAction.toggleDeliveryModal(false));
  //   };
  //   const pressNo = () => {
  //     dispatch(LogisticAction.toggleDeliveryModal(false));
  //   };
  //   return (
  //     <div className="p-4 py-16 text-center">
  //       <h3 className="font-medium text-18px mb-8">
  //         {` ${t("You are delivering using")}
  //            ${selectedIntegration}
  //         ${t("service, are you sure to change the service?")}`}
  //       </h3>
  //       <div>
  //         <button
  //           onClick={pressYes}
  //           className="px-12 py-2 me-2 my-2  text-black rounded border border-green-500"
  //         >
  //           {t("YES")}
  //         </button>
  //         <button
  //           onClick={pressNo}
  //           className="px-12 py-2 me-2 my-2 text-black rounded border border-red-500"
  //         >
  //           {t("NO")}
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  const MarsoolComponent = () => {
    return (
      <button
        className={`px-4 py-2 me-2 my-2  text-white rounded ${
          selectedIntegration === "MARSOOL" ? "bg-green-600 " : "bg-gray-600"
        }`}
        onClick={() => checkIfDeliverySelected("MARSOOL", sendToMursool)}
      >
        <span
          className={` ${selectedIntegration === "MARSOOL" ? "blink" : ""}`}
        >
          {t("Deliver Using Marsool")}
        </span>
      </button>
    );
  };

  const sendToMursool = async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      const result = await API.SEND_ORDER_TO_MARSOOL({
        brandId: userDetail.brandId,
        orderId: order._id,
        storeId: order.storeId,
      });
      if (result?.success === 1) {
        UTILS.successNotification(t(result?.message));
        const { data: updatedOrder } = await getOrderInfoById({
          brandId: order.brandId,
          orderId: order._id,
        });
        if (updatedOrder?.success === 1)
          isSelected(updatedOrder.data.fetchedOrder);
      } else {
        UTILS.errorNotification("Please try again later");
      }
    } catch (error) {
      UTILS.errorNotification("Please try again later");
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  function shouldRenderMarsoolComponent(order) {
    return order && isInterated("MARSOOL");
  }

  function shouldRenderCareemButton(order) {
    return order && isInterated(INTEGRATION_NAMES.CAREEM_NOW);
  }

  function shouldRenderDeliveryZone(order) {
    return order && isInterated(INTEGRATION_NAMES.DELIVERY_ZONE);
  }
  function shouldRenderLyveGlobal(order) {
    return order && isInterated(INTEGRATION_NAMES.LYVE_GLOBAL);
  }

  const fetchOrderDetailsByID = async () => {
    try {
      const userDetail = UTILS.getCurrentUser();
      if (order?._id && userDetail.brandId) {
        const { data } = await getOrderInfoById({
          orderId: order._id,
          brandId: userDetail.brandId,
        });
        return data;
      }
    } catch (error) {
      dispatch(
        ErrorAction.setError("Something went wrong while fetching order info")
      );
    }
  };

  return (
    <>
      {/* YANDEX */}
      {shouldRenderYandexButton(order) && (
        <OrderButton
          deliveyType={INTEGRATION_NAMES.YANDEX}
          btnText={t("Deliver using Yandex")}
          handleDelivery={checkIfDeliverySelected}
          order={order}
        />
      )}
      {/* MARSOOL */}
      {shouldRenderMarsoolComponent(order) && <MarsoolComponent />}
      <Yandex {...{ order, fetchOrderDetailsByID }} />

      {/* CAREEM BUTTON */}
      {shouldRenderCareemButton(order) && (
        <OrderButton
          deliveyType={INTEGRATION_NAMES.CAREEM_NOW}
          btnText={t("Deliver using Careem")}
          handleDelivery={checkIfDeliverySelected}
          order={order}
        />
      )}
      <CareemNOW {...{ order, fetchOrderDetailsByID }} />

      {/* { DELIVERY ZONE} */}
      {shouldRenderDeliveryZone(order) && (
        <OrderButton
          deliveyType={INTEGRATION_NAMES.DELIVERY_ZONE}
          btnText={t("Deliver Using Delivery Zone")}
          handleDelivery={checkIfDeliverySelected}
          order={order}
        />
      )}
      <DeliveryZone {...{ order, fetchOrderDetailsByID }} />
      {shouldRenderLyveGlobal(order) && (
        <OrderButton
          deliveyType={INTEGRATION_NAMES.LYVE_GLOBAL}
          btnText={t("Deliver Using Lyve Global")}
          handleDelivery={checkIfDeliverySelected}
          order={order}
        />
      )}
      <LyveGlobal {...{ order, fetchOrderDetailsByID }} />

      {/* MODAL TO CHECK IF ANY DELIVERY METHOD IS ALREADY ATTACHED TO  THIS ORDER */}
      <Modal
        modalType="redux"
        open={changeDeliveryModal}
        modalWidth="rounded-md border border-gray-300 top-0 w-full md:w-1/2"
        onClose={LogisticAction.toggleDeliveryModal}
      >
        {/* <OrderDeliveryCheckModal /> */}
        <LogisticModalSelector
          {...{ order, openType, selectedIntegration, isSelected }}
        />
      </Modal>
    </>
  );
}

export default SelectedLogistics;
