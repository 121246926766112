import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { logToSentry } from "utils/Utils";
import React from "react";
const ErrorBoundaryFallbackComponent = ({ error }) => {
  const { t } = useTranslation();
  useEffect(() => {
    logToSentry(`${error} -> Error boundary Appears->App crashes`, "error");
  }, [error]);
  return (
    <div id="__layout">
      <div className="system-container ">
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 ">
          <div
            className="bg-white  rounded shadow-md  text-center text-gray-70"
            style={{
              width: "inherit",
              padding: "20px",
            }}
          >
            <h2 className="text-xl font-semibold mb-2">
              {t(
                "We’re sorry for the inconvenience, but there was a problem with your web browser.Click below button to restart the dashboard."
              )}
            </h2>
            <button
              className=" cursor-pointer px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
              onClick={() => window.location.reload()}
            >
              {t("Back to Dashboard")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorBoundaryFallbackComponent;
