import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { Checkbox } from "antd";
import { Button, Empty, Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Card from "components/Card";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import SearchInput from "components/SearchInput";
import Modal from "components/Modal";
import ViewModifier from "../ViewModifier";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
const AttacModifierWithGroup = ({
  MemizedModifiers,
  item,
  onClose,
  getAttachedModifers,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const router = useHistory();
  const [checkedRows, setCheckedRows] = useState([]);

  const linkModifierGroupsWithItems = async () => {
    try {
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.LinkModifierWithGroup({
        brandId: userDetail.brandId,
        modifierGroupId: item?.values?._id,
        modifiers: checkedRows,
      });
      if (result?.success === 1) {
        UTILS.successNotification(
          result?.message ?? t("Attached Successfully")
        );
        getAttachedModifers();
        onClose();
      } else {
        UTILS.errorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (modifier) => {
    setCheckedRows((prevCheckedRows) => {
      const updatedArray = [...prevCheckedRows];

      const existingIndex = updatedArray.findIndex(
        (item) => item.modifierId === modifier?._id
      );

      if (existingIndex !== -1) {
        // If already selected, remove
        updatedArray.splice(existingIndex, 1);
      } else {
        // If not selected, add
        updatedArray.push({
          modifierId: modifier?._id,
          price: modifier?.price,
        });
      }

      return updatedArray;
    });
  };
  const Row = (value, modifier, index) => {
    return (
      <Checkbox
        key={modifier._id}
        checked={checkedRows.some((row) => row.modifierId === modifier?._id)}
        onChange={() => handleRowClick(modifier)}
      />
    );
  };

  const columns = [
    {
      title: t("Link"),
      dataIndex: "price",
      align: "center",
      width: "10%",
      render: Row,
    },
    {
      title: t("Name"),
      dataIndex: "baseLanguage",
      key: "title",
      align: "center",
      render: (value, row, i) => {
        return <div className="truncate-line-3">{value?.name}</div>;
      },
    },
    {
      title: t("Maximum Order Quantity"),
      dataIndex: "maximumOrderQuantity",
      align: "center",
      width: "10%",
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: "10%",
      align: "center",
      render: (value, row, i) => {
        return <div>AED {value}</div>;
      },
    },
    {
      title: t("Action"),
      dataIndex: "price",
      width: "10%",
      align: "center",
      render: (index, record) => (
        <RemoveRedEyeOutlined
          onClick={() => {
            setViewGroup(true);
            setData(record);
          }}
        />
      ),
    },
  ];

  const [viewGroup, setViewGroup] = useState(false);
  const [searchTerm, setSearch] = useState(null);
  const [Data, setData] = useState({});

  const FilteredData = useMemo(() => {
    let searchTermLower = searchTerm?.toLowerCase()?.trim();
    if (!searchTerm) {
      return MemizedModifiers;
    }
    return MemizedModifiers?.filter((mod) => {
      return mod.baseLanguage?.name?.toLowerCase()?.includes(searchTermLower);
    });
  }, [MemizedModifiers, searchTerm]);

  return (
    <div className="w-full bg-white px-4 py-2 text-center">
      <Spin spinning={loading}>
        <Modal
          open={viewGroup}
          modalWidth=""
          onClose={() => setViewGroup(false)}
        >
          <ViewModifier row={Data} setViewGroup={setViewGroup} />
        </Modal>
        <div className="flex flex-col justify-between md:flex-row items-center px-4 pt-4 w-full">
          <div className="">
            <h2 className="text-15px py-0 pe-4 text-start font-bold">
              {t("Add modifiers to")}{" "}
              <span className="text-indigo-600">
                {item?.values?.baseLanguage?.name}
              </span>
            </h2>
          </div>
          <div className="">
            <SearchInput
              placeholder={t("Search by name")}
              searchTerm={searchTerm}
              setSearchTerm={setSearch}
            />
          </div>
        </div>

        {MemizedModifiers && MemizedModifiers?.length >= 1 ? (
          <div
            className="w-full bg-white px-2 sm:px-4 py-2 text-center"
            id="atachmodifierwithgroup"
          >
            <Card styleClasses="px-0 sm:px-2 py-2">
              <div className="overflow-auto table-height">
                <Table
                  locale={{ emptyText: t("No Data") }}
                  columns={columns}
                  dataSource={FilteredData}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 50,
                    defaultPageSize: 50,
                  }}
                  rowKey={(record) => record._id}
                />
              </div>
            </Card>

            <div className="flex justify-between">
              <div className="mt-2 text-center  md:block">
                <button
                  onClick={onClose}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100"
                >
                  {t("Close")}
                </button>
              </div>
              <div className="mt-2 text-center  md:block">
                <Button
                  disabled={checkedRows?.length <= 0}
                  type="primary"
                  size="large"
                  className="w-36"
                  onClick={linkModifierGroupsWithItems}
                >
                  {t("Attach")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Empty description={<span>{t("No Modifier")}</span>}>
            <Button
              onClick={() => router.push("/app/dashboard/add-new-modifier")}
              type="primary"
            >
              {t("Add New Modifier")}
            </Button>
            <div className="mt-2 text-center space-x-4 md:block py-2">
              <button
                onClick={onClose}
                className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100"
              >
                {t("Close")}
              </button>
            </div>
          </Empty>
        )}
      </Spin>
    </div>
  );
};
export default AttacModifierWithGroup;

AttacModifierWithGroup.propTypes = {
  onClose: PropTypes.func,
  cat: PropTypes.object,
  handleValues: PropTypes.func,
  itemsToRemove: PropTypes.array,
};
