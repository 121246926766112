import React from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";

const Modal = (props) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`fixed inset-0 z-10  center-modal-lg ${
          props.showScroll === true ? "" : "overflow-y-auto"
        }`}
        initialFocus={cancelButtonRef}
        open={props.open}
        onClose={props.onClose}
      >
        <div
          className={`min-h-scrn px-4 center-modal-content flex justify-center ${
            props.scroll === true ? "h-screen" : ""
          }`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 theme-backdrop-modal transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            // className="hidden sm:inline-block sm:align-middle sm:h-screen"
            className="inline-block h-scrn align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* w-full lg:w-full xl:w-4/5 2xl:2/3  */}

            <div
              ref={cancelButtonRef}
              className={`inline-block align-bottom bg-white overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:${
                props.modalWidth
              } 
              ${
                props?.responsiveClass
                  ? props?.responsiveClass
                  : "w-full lg:w-3/4 xl:w-2/3 2xl:w-1/2"
              }
         
              
              ${props.scroll === true ? "overflow-y-scroll" : ""}`}
            >
              {props.children}

              <button
                style={{
                  display: "none",
                }}
                className="bg-gray-700 px-4 py-2 text-white rounded-md font-semibold"
                ref={cancelButtonRef}
                onClick={props.onClose}
              >
                CANCEL
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  children: PropTypes.object,
  modalWidth: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Modal;
