import React from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import Modal from "components/Modal";
const HomeTab = ({ order, isOpen, onClose, children }) => {
  const { error } = useSelector((state) => state.ErrorReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  return (
    <Spin spinning={isLoading}>
      <Modal
        open={isOpen}
        modalWidth=" min-h-300 rounded-md border border-gray-300 top-0 w-full lg:w-2/3"
        onClose={onClose}
      >
        {error && (
          <div className="text-center text-red-500 mt-4 font-semibold">
            {error}
          </div>
        )}
        {children}
      </Modal>
    </Spin>
  );
};

export default HomeTab;
