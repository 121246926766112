import { useEffect, useState } from "react";
import moment from "moment";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Language } from "redux/actions";

const useToggleLanguage = () => {
  const dispatch = useDispatch();
  const [lang, setlang] = useState("en");
  const { i18n } = useTranslation();
  const getDir = (lng) => {
    return lng === "ar" ? "rtl" : "ltr";
  };
  const switchLanguage = (evt) => {
    i18n.changeLanguage(evt);
    document.getElementsByTagName("html")[0].setAttribute("dir", getDir(evt));
    setlang(evt);
    UTILS.saveLanguageInformation({
      direction: getDir(evt),
      lang: evt,
    });
    let clang = evt.toUpperCase();
    dispatch(Language.updateLanguage(clang));
    moment.locale(evt);
  };
  useEffect(() => {
    const obj = UTILS?.getLanguageInformation();
    if (obj?.lang && obj?.direction) {
      i18n.changeLanguage(obj?.lang);
      document
        ?.getElementsByTagName("html")[0]
        .setAttribute("dir", obj.direction);
      moment.locale(obj.lang);
      setlang(obj.lang);
      let clang = obj?.lang?.toUpperCase();
      dispatch(Language.updateLanguage(clang));
    }
  }, []);
  return {
    lang,
    switchLanguage,
  };
};
export default useToggleLanguage;
