import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isInternetConnected: true,
};

const notPersited = createSlice({
  name: "notPersited",
  initialState,
  reducers: {
    updateOnlineStatus: (state, action) => {
      state.isInternetConnected = action.payload;
    },
  },
});

export const { updateOnlineStatus } = notPersited.actions;

export default notPersited.reducer;
