import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { LoaderAction } from "redux/actions";
import { UTILS } from "utils";
import { logErrorOnSentry } from "utils/Utils";

function useGetORderInfo() {
  const dispatch = useDispatch();
  const [DZOrder, setDZOrder] = useState(null);
  const getDZOrderStatus = async (order) => {
    try {
      if (!order?._id) {
        logErrorOnSentry(
          "Invalid order number -- dz order status",
          "error",
          JSON.stringify(order)
        );
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const data = await API.trackDZDelivery({ orderId: order._id });
      if (data?.success === 1 && data?.data) {
        setDZOrder(data?.data);
      } else {
        UTILS.errorNotification(errorHandler(data?.error));
        setDZOrder(null);
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return { DZOrder, getDZOrderStatus, setDZOrder };
}

export default useGetORderInfo;
