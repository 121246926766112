import { Button, Radio, Spin } from "antd";
import React from "react";
import { CONSTANTS as C } from "config";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";

function PauseModalContainer({
  PauseModel,
  closeModal,
  setPauseModalState,
  playPauseItem,
  isLoading,
  heading,
  title1,
  title2,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={PauseModel.open}
        modalWidth="rounded-md border border-gray-300"
        onClose={closeModal}
        responsiveClass="w-full md:w-2/3 lg:w-2/3 xl:w-2/5 2xl:1/3"
      >
        <Spin spinning={isLoading}>
          <div className="w-full p-2 sm:p-5 mx-auto my-auto  bg-white">
            <div className="">
              <div className="p-1 justify-center">
                <div className="flex justify-between">
                  <div>
                    <h2 className="text-19px font-bold mb-3 py-1 px-2">
                      {t(heading)}{" "}
                      {/* <span className="text-16px text-gray-600">{title1}</span> */}
                    </h2>
                  </div>
                  <div className="cursor-pointer" onClick={closeModal}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="w-6 h-6 bi bi-x-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
                <p className="mb-4  px-2">
                  {t(title2, {
                    item: title1,
                  })}
                </p>
                <div className="mb-4  px-2">
                  <>
                    {C.ITEM_MODIFIER_UNAVAILABLE_DURATIONS.map((op) => {
                      return (
                        <div className="mb-2">
                          <Radio
                            onChange={(e) =>
                              setPauseModalState("deafaultOption", {
                                value: op.value,
                                unit: op.unit,
                                duration: op.duration,
                              })
                            }
                            style={{ fontSize: "16px", color: "#3f51b5" }}
                            value={op.value}
                            checked={
                              PauseModel?.deafaultOption?.value === op.value &&
                              op.unit === PauseModel?.deafaultOption?.unit
                            }
                          >
                            <span className="text-black">{t(op.title)}</span>
                          </Radio>
                        </div>
                      );
                    })}
                  </>
                </div>

                <div className="flex justify-end gap-1 items-center px-3 mt-4">
                  <Button
                    type="primary"
                    onClick={closeModal}
                    className=" border "
                    size="large"
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      playPauseItem(
                        PauseModel.modifier,
                        PauseModel?.deafaultOption?.duration
                      )
                    }
                  >
                    {t("Pause")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
}

export default PauseModalContainer;
