import { useAccordion } from "features/menu/components/AccordionN";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
const SingleModifer = ({ modiferGroup }) => {
  const ref = useRef();
  const { t } = useTranslation();
  const { selected } = useAccordion();

  const style = {
    panel: `overflow-hidden md:overflow-x-hidden transition-height ease text-gray-600 text-16px`,
  };
  const inlineStyle =
    selected === modiferGroup._id
      ? { height: ref.current?.scrollHeight }
      : { height: 0 };

  return (
    <div
      ref={ref}
      id={modiferGroup._id}
      className={style.panel}
      style={inlineStyle}
    >
      {modiferGroup?.modifierGroupId?.modifiers?.map((modifer) => {
        return (
          <div
            key={modifer?._id}
            className="cursor-pointer flex justify-between px-1 md:px-6 xl:px-12 py-2 text-gray-700 border-b border-gray-200 text-14px"
          >
            <div className="w-1/2">
              <h3>{modifer?.modifierId?.baseLanguage?.name}</h3>
            </div>
            <div className="w-1/2 flex justify-end">
              {modifer?.modifierId?.price + " " + t("AED")}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default SingleModifer;

SingleModifer.propTypes = {
  cat: PropTypes.object,
  handleCount: PropTypes.func,
  pushFrom: PropTypes.string,
};
