import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const AreasList = ({ isChecked, Items, saveAreaIntoState }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  return (
    <React.Fragment>
      <div>
        {Items?.length > 0 &&
          Items?.map((area, index) => (
            <div className="flex w-full py-1" key={area._id}>
              <div className="h-6 w-6"></div>
              <div className="flex justify-between w-full mx-4">
                {area?.isAdded ? (
                  <React.Fragment>
                    <div className="text-gray-500">
                      {area?.[`areaName${currentLanguage}`]}
                    </div>
                    <div className="text-gray-500">{t("Already added")}</div>
                  </React.Fragment>
                ) : (
                  <div
                    className="flex w-full justify-between"
                    onClick={() => saveAreaIntoState(area)}
                  >
                    <div>{area?.[`areaName${currentLanguage}`]}</div>
                    <div>
                      {isChecked(area) ? (
                        <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200 check"></span>
                      ) : (
                        <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200"></span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default AreasList;
