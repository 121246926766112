import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};
const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600"> {error} </span>;
  }
};
const TextPassword = (props) => {
  const mandatoryField = getMandatoryField(props.mandatory);
  const fieldError = getFieldError(props.error);
  return (
    <>
      <label className="text-gray-600 block text-14px">
        {props.label}
        {mandatoryField}
        {fieldError}
      </label>
      <Input.Password
        onChange={(event) =>
          props.handleValues(props.keyVal, event.target.value)
        }
        placeholder={props.placeHolder ? props.placeHolder : ""}
        value={props.value}
        size="large"
        className="p-2"
      />
    </>
  );
};

TextPassword.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
};

export default TextPassword;
