import { RedoOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { API } from "api";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetOrderInfoByIdMutation } from "redux/Logistic/LogisticService";
import { UTILS } from "utils";
import RefundForm from "../../../components/RefundOrderForm";
import OrderDetail from "../../orderdetail/containers/OrderDetail";
import LocalModal from "../modals/LocalModal";
import Order from "./Order";
import RefundNotRequiredConfirmation from "./RefundNotRequiredConfirmation";

const OrdersPanel = (props) => {
  const { t } = useTranslation();
  const {
    tabStatus,
    setLoading,
    orders,
    getOrdersByStatus,
    currentOrderId,
    setCurrentOrderId,
    setRejectReason,
    order,
    setOrder,
    onSubmit,
    refundableMutate,
  } = props;
  const [searchTerm, setSearch] = useState("");
  const [getOrderInfoById] = useGetOrderInfoByIdMutation();
  const [refundNotRequiredInfo, setRefundNotRequiredInfo] = useState({
    loading: false,
    visible: false,
  });
  const [refundForm, setRefundForm] = useState({
    loading: false,
    visible: false,
  });

  useEffect(() => {
    if (tabStatus) {
      getOrdersByStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStatus]);

  const GetNoOrderComponent = () => {
    return (
      <div className="flex w-full justify-center mt-48">
        <div className="justify-center">
          <h1 className="text-red-600">{t("There are no Orders")}</h1>
          {props.tabStatus === "PENDING" ? (
            <Button
              type="primary"
              icon={<RedoOutlined />}
              size={"large"}
              onClick={() => {
                props.setLoading(true);
                getOrdersByStatus();
              }}
            >
              {t("Refresh")}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const setOrderNotRefundable = async () => {
    setRefundNotRequiredInfo({
      ...refundNotRequiredInfo,
      loading: true,
    });
    const obj = {
      orderId: currentOrderId,
      brandId: order?.brandId,
    };
    const result = await API.SET_ORDER_NOT_REFUNDABLE(obj);
    if (result?.success === 1) UTILS.successNotification(t("Order Updated!"));
    else UTILS.errorNotification(t("Please try again later!"));
    getOrdersByStatus();
    setRefundNotRequiredInfo({
      ...refundNotRequiredInfo,
      loading: false,
      visible: false,
    });
    refundableMutate("order/get-refundable-orders-count", {
      revalidate: true,
    });
  };

  const submitRefundOrder = async ({ amount, reason }) => {
    setRefundForm({
      ...refundForm,
      loading: true,
    });
    const obj = {
      orderId: currentOrderId,
      amount,
      reason,
      brandId: order?.brandId,
    };
    const result = await API.REFUND_ORDER(obj);
    if (result?.success === 1)
      UTILS.successNotification(result?.message || t("Refund Requested"));
    else
      UTILS.errorNotification(result?.message || t("Please try again Later"));
    getOrdersByStatus();
    props.setLoading(false);
    setRefundForm({
      ...refundForm,
      loading: false,
      visible: false,
    });
    refundableMutate("order/get-refundable-orders-count", {
      revalidate: true,
    });
  };

  const onSearch = (e) => setSearch(e?.target?.value);

  const PreparingFooter = () => {
    return (
      <div className="flex w-full justify-between">
        <button
          className="w-1/2 py-2 bg-green-600 text-white"
          onClick={() =>
            order?.isPreOrder
              ? onSubmit("SCHEDULED", null)
              : onSubmit("PREPARING", null)
          }
        >
          {order?.isPreOrder ? t("Schedule") : t("Accept")}
        </button>
        <button
          className="w-1/2 py-2 bg-theme-red text-white"
          onClick={() => {
            setRejectReason((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                Modal: true,
                type: "REJECTED",
              },
            }));
          }}
        >
          {t("Reject")}
        </button>
      </div>
    );
  };

  const OnTheWayFooter = () => {
    return (
      <div className="flex w-full justify-between">
        <button
          className="w-1/2 py-2 bg-yellow-600 text-white"
          onClick={() => onSubmit("ON_THE_WAY", null)}
        >
          {t("On The Way / Ready")}
        </button>
        <button
          className="w-1/2 py-2 bg-theme-red text-white"
          onClick={() => {
            setRejectReason((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                Modal: true,
                type: "CANCELLED",
              },
            }));
          }}
        >
          {t("Cancel")}
        </button>
      </div>
    );
  };

  const CompleteOrderFooter = () => {
    return (
      <div className="flex w-full justify-between">
        <button
          className="w-1/2 py-2 bg-blue-600 text-white"
          onClick={() => onSubmit("COMPLETE", null)}
        >
          {t("COMPLETE")}
        </button>
        <button
          className="w-1/2 py-2 bg-theme-red text-white"
          onClick={() => {
            setRejectReason((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                Modal: true,
                type: "CANCELLED",
              },
            }));
          }}
        >
          {t("Cancel")}
        </button>
      </div>
    );
  };
  const RefundsFooter = () => {
    return (
      <div className="flex w-full justify-between">
        <button
          className="w-1/2 py-2 bg-blue-600 text-white"
          onClick={() =>
            setRefundNotRequiredInfo({
              ...refundNotRequiredInfo,
              visible: true,
            })
          }
        >
          {t("No need for refund")}
        </button>
        <button
          className="w-1/2 py-2 bg-theme-red text-white"
          onClick={() => {
            setRefundForm({
              visible: true,
              loading: false,
            });
          }}
        >
          {t("Refund")}
        </button>
      </div>
    );
  };

  const ScheduleFooter = () => {
    return (
      <div className="flex w-full justify-between">
        <button
          className="w-1/2 py-2 bg-green-600 text-white"
          onClick={() => onSubmit("PREPARING", null)}
        >
          {t("Start Preparing")}
        </button>
        <button
          className="w-1/2 py-2 bg-theme-red text-white"
          onClick={() => {
            setRejectReason((prevState) => ({
              ...prevState,
              values: {
                ...prevState.values,
                Modal: true,
                type: "REJECTED",
              },
            }));
          }}
        >
          {t("Reject")}
        </button>
      </div>
    );
  };

  const getFooterComponent = () => {
    if (tabStatus === "PENDING") {
      return <PreparingFooter />;
    } else if (tabStatus === "PREPARING") {
      return <OnTheWayFooter />;
    } else if (tabStatus === "ON_THE_WAY") {
      return <CompleteOrderFooter />;
    } else if (tabStatus === "SCHEDULED") {
      return <ScheduleFooter />;
    } else if (tabStatus === "REFUND") {
      return <RefundsFooter />;
    }
  };
  const sendToQuiqup = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const ord = orders?.find((o) => o._id === currentOrderId);
    const result = await API.SEND_ORDER_TO_QUIQUP({
      token: userDetail.token,
      brandId: userDetail.brandId,
      orderId: currentOrderId,
      storeId: ord.storeId,
    });
    if (result && result.success === 1) {
      UTILS.successNotification(t(result?.message));
    } else {
      UTILS.errorNotification("Please try again later");
    }
    setLoading(false);
  };

  const getOrderInfoByID = async (orderId) => {
    const userDetail = UTILS.getCurrentUser();
    const { data: result } = await getOrderInfoById({
      orderId: orderId,
      brandId: userDetail.brandId,
    });
    if (result?.success === 1) {
      setOrder(result.data.fetchedOrder);
    } else {
      UTILS.errorNotification(t("Please try again later!"));
    }
    setLoading(false);
  };

  const sendToMursool = async () => {
    try {
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const ord = orders?.find((o) => o._id === currentOrderId);
      const result = await API.SEND_ORDER_TO_MARSOOL({
        brandId: userDetail.brandId,
        orderId: currentOrderId,
        storeId: ord.storeId,
      });
      if (result && result.success === 1) {
        UTILS.successNotification(t(result?.message));
        getOrderInfoByID(currentOrderId);
      } else {
        UTILS.errorNotification("Please try again later");
      }
    } catch (error) {
      UTILS.errorNotification("Please try again later");
    } finally {
      setLoading(false);
    }
  };

  const Items = useMemo(() => {
    try {
      if (!searchTerm) {
        return orders;
      }
      return orders?.filter((item) =>
        item?.orderNumber?.toString()?.includes(searchTerm)
      );
    } catch (error) {}
  }, [orders, searchTerm]);
  return (
    <div className="flex flex-col lg:flex-row justify-between">
      <div
        className="w-full lg:w-3/12 bg-gray-800 overflow-scroll search-order"
        style={{ height: 810 }}
      >
        <Input.Search allowClear onChange={onSearch} value={searchTerm} />
        {Items && Items.length > 0 ? (
          Items.map((or, index) => {
            return (
              <Order
                key={index}
                order={or}
                currentOrderId={currentOrderId}
                handoleOrderId={(orderId) => setCurrentOrderId(orderId)}
              />
            );
          })
        ) : (
          <GetNoOrderComponent />
        )}
      </div>
      <div className="w-full lg:w-9/12">
        {orders && orders?.length > 0 ? (
          <OrderDetail
            key={currentOrderId}
            tabStatus={tabStatus}
            orderId={currentOrderId}
            sendToQuiqup={sendToQuiqup}
            sendToMursool={sendToMursool}
            order={order}
            setOrder={setOrder}
            getOrderInfoById={getOrderInfoByID}
          />
        ) : (
          <GetNoOrderComponent />
        )}
        <div className="w-full">
          {orders && orders.length > 0 ? getFooterComponent() : ""}
        </div>
      </div>
      <LocalModal
        open={refundNotRequiredInfo.visible || refundForm.visible}
        showScroll={true}
        modalWidth="w-1/3 rounded-md border border-gray-300 p-8"
        onClose={() => setRejectReason}
      >
        {refundNotRequiredInfo.visible && (
          <RefundNotRequiredConfirmation
            loading={refundNotRequiredInfo?.loading}
            order={order}
            onSubmit={setOrderNotRefundable}
            closeModal={() => {
              setRefundNotRequiredInfo({
                ...refundNotRequiredInfo,
                loading: false,
                visible: false,
              });
            }}
          />
        )}
        {refundForm.visible && (
          <RefundForm
            loading={refundForm?.loading}
            order={order}
            onSubmit={submitRefundOrder}
            closeModal={() => {
              setRefundForm({
                ...refundForm,
                loading: false,
                visible: false,
              });
            }}
          />
        )}
      </LocalModal>
    </div>
  );
};
OrdersPanel.propTypes = {
  tabStatus: PropTypes.string,
  handleSound: PropTypes.func,
};
export default OrdersPanel;
