//
import React, { useState } from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

const SideBar = ({ menu }) => {
  const [collapsed, setCollapsed] = useState(false);
  const Header = () => {
    const onCollapse = () => {
      setCollapsed(!collapsed);
    };

    return (
      <div
        className="relative py-2 ps-2 border-b border-gray-700"
        style={{ backgroundColor: "#001529" }}
        onClick={() => onCollapse()}
      >
        <Link className="flex mt-2 items-center" to="/app/dashboard">
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + process.env.REACT_APP_COMPANY_LOGO}
            className="w-8 h-8"
          />
          <h1 className="font-montserrat text-gray-300 font-bold lg:text-17px xl:text-20px mx-2 overflow-hidden uppercase">
            {/* RESTHERO */}
            {process.env.REACT_APP_COMPANY_NAME}
          </h1>
        </Link>
        {/* <button className="fixed top-10 left-60" onClick={() => onCollapse()}>
                  {
                      collapsed ? <RightCircleTwoTone /> : <LeftCircleTwoTone />
                  }
              </button> */}
      </div>
    );
  };
  return (
    <Layout.Sider
      className="sidebar"
      breakpoint={"lg"}
      theme="dark"
      collapsedWidth={0}
      trigger={null}
      width="15%"
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <Header />
      {menu}
    </Layout.Sider>
  );
};
export default SideBar;
