import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import { ReactSortable } from "react-sortablejs";
import { isMobile } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DeleteCategory from "features/menus/components/modals/DeleteCategory";
import { Accordion } from "features/menu/components/AccordionN";

import PageHeading from "components/PageHeading";
import Card from "components/Card";
import Modal from "components/Modal";
import BackArrow from "features/icons/BackArrow";

import SingleCategory from "features/menus/components/SingleCategory";
import AddCategory from "features/menus/components/AddCategory";
import UpdateCategory from "features/menus/components/UpdateCategory";
import CategoryItem from "features/menus/components/CategoryItem";

import useLinkUnlinkStoreWithMenu from "../hooks/useLinkUnlinkStoreWithMenu";
import useAllStores from "hooks/useCurrentUserStores";

import { UTILS } from "utils";
import { useSortCategoryInMenuMutation } from "redux/menu/service";

import { errorHandler } from "api/api/errorHandler";
import OrderingPagePreview from "../components/OrderingPagePreview";
import LinkStoresWithMenu from "../components/modals/LinkStoresWithMenu";
import useGetMenuInfo from "../hooks/useGetMenuInfo";
import { useMenuContext } from "store/contexts/Menus";
import MakeDefaultMenuModal from "features/menus/components/modals/MakeDefaultMenuModal";
import { LoaderAction } from "redux/actions";
import { useGetMenuCatInfoMutation } from "redux/menu/service";

const MenuEditor = () => {
  const params = useParams();
  const router = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { stores, setLoading, loading } = useAllStores();
  const { menuState, updateState } = useMenuContext();
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const getGetMenuInfoByMenuId = useGetMenuInfo({
    setLoading,
    updateState,
  });

  //mutations
  const [sortCategoryInMenu] = useSortCategoryInMenuMutation();
  //hooks

  const DG_CONTENT_ID = {
    ADD_CATEGORY: 0,
    DELETE_CATEGORY: 1,
    UPDATE_CATEGORY: 2,
    ADD_ITEM_TO_CATEGORY: 3,
  };

  //ADD_CATEGORY
  const handleAddCategory = (cat) => {
    let arr = [...menuState.categories];
    arr.push(cat);
    updateState("categories", arr);
    updateState("open", false);
  };

  //DELETE_CATEGORY
  const handleDeleteCategory = (index) => {
    let arr = [...menuState.categories];
    arr.splice(index, 1);
    updateState("categories", arr);
    updateState("open", false);
  };

  //UPDATE_CATEGORY
  const handleUpdateCategory = (cat) => {
    const arr = [...menuState.categories];
    let updated = {
      categoryId: {
        ...cat,
      },
    };
    arr[menuState.indexToUpdateDelete] = updated;
    updateState("categories", arr);
    updateState("open", false);
  };

  const onClose = () => updateState("open", false);

  useEffect(() => {
    if (params?.menuId) {
      updateState("menuId", params?.menuId);
      getGetMenuInfoByMenuId(params?.menuId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.menuId]);

  //Fetch  all brand items

  const unLinkMenuCat = (index) => {
    const cat = menuState.categories[index];
    updateState("dialogContentId", DG_CONTENT_ID.DELETE_CATEGORY);
    updateState("categoryToUpdateDelete", cat);
    updateState("indexToUpdateDelete", index);
    updateState("open", true);
  };
  const [items, setItems] = useState([]);
  const [getMenuCatInfo] = useGetMenuCatInfoMutation();

  const getCategoryInfo = async (categoryId) => {
    try {
      if (!categoryId) return;
      setLoading(true);
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      const data = await getMenuCatInfo({
        brandId: userDetail.brandId,
        categoryId,
        menuId: params?.menuId,
      });
      if (data?.data?.success === 1 && data?.data?.data?.items) {
        setItems(data.data.data.items);
      } else {
        // setOpen(false);
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      // setOpen(false);
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      setLoading(false);
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const getCategoryView = (cat, index) => {
    return (
      <div key={cat?._id}>
        <SingleCategory
          {...{ unLinkMenuCat, editCat, cat }}
          toggle={cat?._id}
          keyVal={index}
          getCategoryInfo={getCategoryInfo}
        />
        <CategoryItem
          {...{
            setLoading,
            cat,
            connectedStores: menuState.itemLinkedStores,
            loading,
            items,
            setItems,
          }}
          getCategoryInfo={getCategoryInfo}
          menuId={params?.menuId}
          brandItems={menuState?.items}
        />
      </div>
    );
  };

  const renderModalContent = () => {
    switch (menuState.dialogContentId) {
      case 0:
        return (
          <AddCategory
            handleValues={handleAddCategory}
            onClose={onClose}
            menuId={params?.menuId}
          />
        );
      case 1:
        return (
          <DeleteCategory
            handleValues={handleDeleteCategory}
            onClose={onClose}
            cat={menuState.categoryToUpdateDelete}
            index={menuState.indexToUpdateDelete}
          />
        );
      case 2:
        return (
          <UpdateCategory
            handleValues={handleUpdateCategory}
            onClose={onClose}
            cat={menuState.categoryToUpdateDelete.categoryId}
            index={menuState.indexToUpdateDelete}
          />
        );
      default:
        break;
    }
  };

  const editCat = (index) => {
    const cat = menuState.categories[index];
    updateState("dialogContentId", DG_CONTENT_ID.UPDATE_CATEGORY);
    updateState("categoryToUpdateDelete", cat);
    updateState("indexToUpdateDelete", index);
    updateState("open", true);
  };

  const reOrderCategory = async (cat, sortable, evt) => {
    try {
      const userDetail = UTILS.getCurrentUser();
      setLoading(true);
      dispatch(LoaderAction.setLoading(true));
      const result = await sortCategoryInMenu({
        brandId: userDetail.brandId,
        menuId: menuState?.menuId || params?.menuId,
        categoryId: cat.item.dataset.id,
        index: cat.newIndex,
      });
      if (result?.data?.success === 1) {
      } else UTILS.errorNotification(errorHandler(result?.error?.data));
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      setLoading(false);
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const { linkMenu } = useLinkUnlinkStoreWithMenu({
    setLoading,
    updateState,
    selectedStores: menuState?.linkedStores,
    getAllMenus: () => getGetMenuInfoByMenuId(params?.menuId),
    menuId: params?.menuId,
  });

  const [isMakeDefaultOpen, setIsMakeDefault] = useState(false);

  return (
    <Spin spinning={isLoading}>
      <MakeDefaultMenuModal
        {...{ isLoading, setIsMakeDefault, isMakeDefaultOpen }}
        menuId={params?.menuId}
      />

      <div className="h-full" key={params?.menuId}>
        <LinkStoresWithMenu
          {...{
            updateState,
            stores,
            loading,
            linkMenu,
          }}
          menuState={menuState}
        />
        <PageHeading heading={t("Menu Editor")} />
        <Modal
          open={menuState?.open}
          modalWidth="w-1/2 rounded-md border border-gray-300"
          onClose={() => updateState("open", false)}
        >
          {renderModalContent()}
        </Modal>
        <div
          className="cursor-pointer inline-block px-4"
          onClick={() => router.push("/app/dashboard/menu-list")}
        >
          <span className="align-middle">
            <BackArrow />
          </span>
          <span className="align-middle px-2 text-gray-500">{t("Menus")}</span>
        </div>
        <div className="px-4 py-4">
          <Card>
            <div className="flex justify-between flex-wrap h-full">
              <div className="w-full xl:w-7/12 px-2 md:px-4 flex-wrap h-full">
                <div className="py-2 flex justify-between items-center flex-wrap border-b border-gray-200">
                  <div className="w-full md:w-1/4">
                    <h2 className="text-19px text-blue-600 mb-2 md:mb-0">
                      {t("Categories")}
                    </h2>
                  </div>
                  <div className="flex w-full  md:w-3/4 justify-center md:justify-end flex-wrap">
                    <div className="px-1 mb-2 md:mb-0">
                      <Button
                        type="Default"
                        size="large"
                        onClick={() => {
                          getGetMenuInfoByMenuId(params?.menuId);
                          updateState("isLinkMenuWithStoresModalOpen", true);
                        }}
                      >
                        {t("Connections")}
                      </Button>
                    </div>
                    <div className="px-1 mb-2 md:mb-0 anttd-btn">
                      <Button
                        className={`${
                          menuState?.menuInfo?.isDefault ? "antd-btn-green" : ""
                        }`}
                        size="large"
                        onClick={() => {
                          if (!menuState?.menuInfo?.isDefault)
                            // makeMenuDeafault({
                            //   menuId: params?.menuId,
                            // });
                            setIsMakeDefault(true);
                        }}
                      >
                        {menuState?.menuInfo?.isDefault
                          ? t("Default Menu")
                          : t("Set Default Menu")}
                      </Button>
                    </div>

                    <div className="px-1">
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                          updateState(
                            "dialogContentId",
                            DG_CONTENT_ID.ADD_CATEGORY
                          );
                          updateState("open", true);
                        }}
                      >
                        {t("New Category")}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="py-2 md:py-12">
                  <Accordion>
                    {isMobile ? (
                      <>
                        {menuState?.categories?.map((cat, index) => {
                          return getCategoryView(cat.categoryId, index);
                        })}
                      </>
                    ) : (
                      <ReactSortable
                        list={menuState?.categories}
                        setList={(newState) => {
                          updateState("categories", newState);
                        }}
                        animation={300}
                        onUpdate={(cat, sortable, evt) => {
                          reOrderCategory(cat, sortable, evt);
                        }}
                      >
                        {menuState?.categories?.map((cat, index) => {
                          return getCategoryView(cat.categoryId, index);
                        })}
                      </ReactSortable>
                    )}
                  </Accordion>
                  <div className="pt-4 invisible md:visible">
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        updateState(
                          "dialogContentId",
                          DG_CONTENT_ID.ADD_CATEGORY
                        );
                        updateState("open", true);
                      }}
                    >
                      {t("New Category")}
                    </Button>
                  </div>
                </div>
              </div>
              <OrderingPagePreview state={menuState} {...{ updateState }} />
            </div>
          </Card>
        </div>
      </div>
    </Spin>
  );
};
export default MenuEditor;
