import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  error: null,
};

const ErrorSlice = createSlice({
  name: "Integrations",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setError } = ErrorSlice.actions;
export default ErrorSlice.reducer;
