import React, { useState, useMemo } from "react";
import { Table, Button, Spin, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal";
import AddNewCountry from "../../modals/AddNewCountry";
import DeleteCountry from "../../modals/DeleteCountry";
import UpdateCountry from "../../modals/UpdateCountry";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import useUserType from "hooks/useUserType";
import useAllStores from "hooks/useCurrentUserStores";
import { AreasAction } from "redux/actions";
import PlusIcon from "features/icons/PlusIcon";
import BackButton from "components/BackButton";
import RenderTime from "features/shipping_methods/common/RenderTime";
function CountriesTable() {
  const dispatch = useDispatch();
  const userType = useUserType() || UTILS.getCurrentUser()?.userType;
  const { stores } = useAllStores();
  const [searchTerm, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogContentId, setDialogContentId] = useState(-1);
  const [selectedRowKeys, setSelectedRowsKeys] = useState([]);
  const [selectedDataRows, setSelectedDataRows] = useState({});
  const { t } = useTranslation();
  const { selectedStore, addedCountries } = useSelector(
    (state) => state.AreasReducer
  );
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const columns = [
    {
      key: "countryID",
      title: t("country name"),
      sorter: (a, b) =>
        a?.countryID?.countryNameEN?.localeCompare(b?.countryID?.countryNameEN),
      dataIndex: "cityName",
      render: (val, row, index) => {
        return currentLanguage === "EN"
          ? row?.countryID?.countryNameEN
          : row?.countryID?.countryNameAR;
      },
    },
    {
      key: "minOrder",
      title: t("Min. Basket Value"),
      dataIndex: "minOrder",
      sorter: {
        compare: (a, b) => a.minOrder - b.minOrder,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      key: "deliveryFee",
      title: t("Delivery Fee"),
      dataIndex: "deliveryFee",
      sorter: {
        compare: (a, b) => a.deliveryFee - b.deliveryFee,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },

    {
      key: "deliveryTime",
      title: t("Delivery Time"),
      dataIndex: "deliveryTime",
      sorter: {
        compare: (a, b) => a.deliveryTime - b.deliveryTime,
        multiple: 3,
      },
      render: (val, row, index) => {
        return <RenderTime {...{ val, row, index }} />;
      },
    },
  ];

  const DG_CONTENT_ID = {
    ADD_COUNTRY: 0,
    DELETE_COUNTRY: 1,
    UPDATE_COUNTRY: 2,
    ADD_ITEM_TO_COUNTRY: 3,
  };

  const onSelectChange = () => {
    setSelectedRowsKeys(selectedRowKeys);
  };
  const onClose = (e, w) => {
    setOpen(false);
    setSelectedRowsKeys([]);
  };
  const renderModalContent = () => {
    switch (dialogContentId) {
      case 0:
        return (
          <AddNewCountry
            selectedAreas={selectedDataRows}
            onClose={onClose}
            onSelectChange={onSelectChange}
            setSelectedRowsKeys={setSelectedRowsKeys}
          />
        );
      case 1:
        return (
          <DeleteCountry
            selectedAreas={selectedDataRows}
            onClose={onClose}
            onSelectChange={onSelectChange}
            setSelectedRowsKeys={setSelectedRowsKeys}
          />
        );
      case 2:
        return (
          <UpdateCountry
            selectedCountries={selectedDataRows}
            onClose={onClose}
            onSelectChange={onSelectChange}
            setSelectedRowsKeys={setSelectedRowsKeys}
          />
        );
      default:
        break;
    }
  };

  const Items = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return addedCountries?.countryDeliveryData;
    }
    return addedCountries?.countryDeliveryData?.filter(
      (item) =>
        item.areaID?.countryNameEN
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase()) ||
        item.countryID?.countryNameEN
          ?.toLowerCase()
          ?.includes(searchTerm.toLowerCase()) ||
        item.countryID?.countryNameAR
          ?.toLowerCase()
          ?.includes(searchTerm.toLowerCase()) ||
        item.countryID?.countryNameAR
          ?.toLowerCase()
          ?.includes(searchTerm.toLowerCase())
    );
  }, [addedCountries, searchTerm]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDataRows(selectedRows);
      setSelectedRowsKeys(selectedRowKeys);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selectedRowKeys.length !== 0) {
        setSelectedRowsKeys([]);
      }
    },
  };

  const handSearch = (e) => {
    setSearch(e.target.value);
  };

  const deleteCountry = () => {
    setOpen(true);
    setDialogContentId(DG_CONTENT_ID.DELETE_COUNTRY);
  };
  const editCountry = () => {
    setOpen(true);
    setDialogContentId(DG_CONTENT_ID.UPDATE_COUNTRY);
  };
  const AddCountry = () => {
    setOpen(true);
    setDialogContentId(DG_CONTENT_ID.ADD_COUNTRY);
  };
  const onStoreChange = (value) => {
    localStorage.setItem("storeId", value.value);
    dispatch(AreasAction.setStore(value));
  };

  const countryCount = addedCountries?.countryDeliveryData?.length || 0;
  const countryText = `${countryCount} ${t(
    countryCount <= 1 ? "Country" : "Countries"
  )}`;

  return (
    <>
      <div className="mb-3">
        <BackButton />
      </div>
      <div className="bg-white rounded shadow-sm border-gray-200 border px-2 py-2">
        <Spin spinning={isLoading}>
          <div className="">
            <h2 className="text-17px text-blue-600 ">{t("Select store")}</h2>
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="flex items-center my-3 w-full sm:w-96 sm:my-0">
                <Select
                  value={selectedStore}
                  size="large"
                  labelInValue={true} // Add this prop
                  showSearch
                  className="w-full rounded"
                  placeholder={t("Select store")}
                  optionFilterProp="children"
                  onChange={onStoreChange}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={stores}
                />
              </div>
              {selectedStore && (
                <div>
                  <Button
                    disabled={userType === "MANAGER"}
                    type="primary"
                    onClick={() => AddCountry()}
                    className="font-montserrat px-5 border border-transparent shadow-sm  font-medium rounded-sm text-white h-10  focus:outline-none"
                  >
                    <span className="align-middle">
                      <PlusIcon />
                    </span>
                    <span className="px-1 align-middle">
                      {t("Add delivery countries")}
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </div>
          {selectedStore && (
            <>
              <Modal
                open={open}
                showScroll={true}
                responsiveClass="w-full lg:w-full xl:w-4/5 2xl:2/3"
                modalWidth="rounded-md border border-gray-300"
                onClose={onClose}
              >
                {renderModalContent()}
              </Modal>
              <div className="flex justify-between my-4 items-center flex-row-reverse flex-col-reverse  sm:flex-row">
                <div className="w-full sm:w-auto text-start pt-4 sm:pt-0">
                  <h2 className="font-bold p-1 align-middle text-16px sm:text-19px ">
                    {countryText}
                  </h2>
                </div>

                <div className="flex flex-col w-full sm:w-auto sm:flex-row">
                  <div className="flex  justify-end">
                    {selectedRowKeys?.length > 0 && (
                      <Button
                        type="primary"
                        className="mx-2 h-10 "
                        onClick={editCountry}
                      >
                        {t("Edit")}
                        {`${
                          selectedRowKeys?.length > 0
                            ? `(${selectedRowKeys?.length})`
                            : " "
                        } `}
                      </Button>
                    )}

                    {selectedRowKeys?.length > 0 && (
                      <Button
                        danger
                        className="mx-2 h-10"
                        onClick={() => deleteCountry()}
                      >
                        {t("Delete")}
                        {`${
                          selectedRowKeys?.length > 0
                            ? `(${selectedRowKeys.length})`
                            : " "
                        } `}
                      </Button>
                    )}
                  </div>
                  <div className="group relative mt-4 sm:mt-0">
                    <svg
                      fill="currentColor"
                      className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      />
                    </svg>
                    <input
                      onChange={handSearch}
                      value={searchTerm}
                      className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm"
                      type="text"
                      aria-label="Search country by name"
                      placeholder={t("search country by name")}
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="overflow-hidden overflow-x-auto md:overflow-hidden border-gray-200 border rounded-sm">
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={Items}
                  pagination={{ position: ["bottomCenter"], pageSize: 50 }}
                />
              </div>
            </>
          )}
        </Spin>
      </div>
    </>
  );
}

export default CountriesTable;
