import React from "react";
import PropTypes from "prop-types";

const RadioButton = (props) => {
  return (
    <>
      <div className="flex items-center mt-3 px-4 w-full">
        <label
          htmlFor={props?.customKey ? props?.customKey : props.keyVal}
          className="flex items-center cursor-pointer"
        >
          <div className="relative">
            <input
              onChange={(event) =>
                props.handleValues(props.keyVal, event.target.checked)
              }
              checked={props.value}
              type="checkbox"
              id={props?.customKey ? props?.customKey : props.keyVal}
              className="sr-only"
            />
            <div
              className={
                !props.value
                  ? "block bg-gray-300 w-16 h-8 rounded-full"
                  : "block bg-theme-green w-16 h-8 rounded-full"
              }
            ></div>
            <div
              className={
                !props.value
                  ? "dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                  : "dot absolute right-1 top-1 bg-white w-6 h-6 rounded-full transition"
              }
            ></div>
          </div>
          <div className="text-gray-600 text-14px ms-3">{props.label}</div>
        </label>
      </div>
    </>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  keyVal: PropTypes.string,
  handleValues: PropTypes.func,
};

export default RadioButton;
