import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { API } from "api";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
const DeliveryPartner = ({ order, setLoading, getOrderInfoById }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState();
  const sendOrder = async () => {
    if (!UTILS.validateTalabat(order)) {
    } else {
      setLoading(true);
      try {
        const result = await API.SEND_ORDER_TO_TALABAT({
          storeId: order.storeId,
          brandId: order.brandId,
          orderId: order._id,
          coldbagNeeded: false,
          serviceName: "TALABAT",
        });

        if (result?.success === 1) {
          UTILS.successNotification(result.message);
        } else {
          UTILS.errorNotification(result.message ?? "something went wrong");
        }
      } catch (error) {
        UTILS.errorNotification(error.message ?? "something went wrong");
      } finally {
        getOrderInfoById(order._id);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (UTILS.OdrAlrdySndToTalabat(order)) {
      setDisabled(true);
    } else setDisabled(false);
  }, [order]);

  return (
    <>
      <div className="flex justify-center">
        <Button
          type="default"
          size="large"
          onClick={sendOrder}
          disabled={disabled}
        >
          {t("Send Order To TALABAT")}
        </Button>
      </div>
    </>
  );
};

export default DeliveryPartner;
