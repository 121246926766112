import { errorHandler } from "api/api/errorHandler";
import { useGetStoreItemsMutation } from "redux/menu/service";
import { UTILS } from "utils";

function useGetItemsByStoreId(updateState) {
  const [getStoreItems] = useGetStoreItemsMutation();

  const getItemsByStoreId = async (storeId) => {
    if (!storeId) return;
    try {
      updateState("loading", true);
      const userDetail = UTILS.getCurrentUser();
      const result = await getStoreItems({
        brandId: userDetail.brandId,
        storeId: storeId,
      });
      if (result?.data?.success === 1 && result?.data?.data) {
        // setItems(result?.data?.data);
        updateState("items", result?.data?.data);
      } else UTILS.handleErrorNotification(errorHandler(result?.message));
    } catch (error) {
      UTILS.handleErrorNotification(errorHandler(error?.error));
    } finally {
      updateState("loading", false);
    }
  };
  return getItemsByStoreId;
}

export default useGetItemsByStoreId;
