import React from "react";
import { Progress } from "antd";
import { useTranslation } from "react-i18next";
import StarRating from "components/StarRating";
import { getStarRatingClasses } from "utils/Utils";

const AverageRatingDisplay = ({
  averageRating,
  feedbackRatingCount,
  totalCount,
}) => {
  const { t } = useTranslation();
  if (!averageRating) return null;

  return (
    <div className="bg-white pb-6 px-6 rounded shadow-md">
      <div className="text-19px font-semibold mb-1">{t("Average Rating")}</div>
      <div className="text-gray-400 text-14px mb-2">
        {totalCount === 1
          ? t("Based on last 1 rating")
          : t("Based on last x ratings", {
              count: totalCount,
            })}
      </div>
      <div className="flex flex-wrap">
        <div className="min-w-200">
          {feedbackRatingCount?.map((item, index) => (
            <div
              key={item?._id || index}
              className="flex items-center space-x-2 -my-2"
            >
              <StarRating
                className={`star ${getStarRatingClasses(item?.orderRating)}`}
                totalStars={5}
                rating={item?.orderRating}
                handleStarClick={() => {}}
                starContainerClassName="text-yellow-500" // Customize star color if needed
              />
              <span className="text-gray-400 text-sm">
                {item.count} {item?.count === 1 ? t("User") : t("Users")}
              </span>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center mt-4 sm:mt-0 ms-4">
          <Progress
            type="circle"
            percent={averageRating * 20}
            width={100}
            strokeWidth={6}
            format={() => (
              <div className="text-xs font-bold">
                {averageRating}
                <div className="font-normal text-gray-400 text-13px mt-2 ">
                  {t("out of 5")}
                </div>
              </div>
            )}
            strokeColor="#008000"
          />
        </div>
      </div>
    </div>
  );
};

export default AverageRatingDisplay;
