import { Spin } from "antd";
import { API } from "api";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import TextInput from "../../../components/TextInput";
import useToggleLanguage from "hooks/useToggleLanguage";
import LanguageToggle from "components/LanguageToggle";

const ResetPasswordStep2 = (props) => {
  const { lang, switchLanguage } = useToggleLanguage();
  const [code, setCode] = useState("");

  const [Error, setError] = useState({
    codeError: "",
    passwordError: "",
  });
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const router = useHistory();

  const submitNewPassword = async () => {
    setLoading(true);
    const result = await API.NEW_PASSWORD({
      phoneNumber: router.location.state.phoneNumber,
      verificationCode: code,
      password: password,
    });
    if (result && result.success === 1) {
      UTILS.handleSuccesNotification(
        result?.message ?? t("Your Passsword has been changed, please login")
      );
      router.push("/login");
    } else {
      UTILS.HANDLE_ERROR_NOTIFICATION("topRight", "error", result?.message);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="grid grid-cols-1">
        <LanguageToggle
          onClick={switchLanguage}
          selected={lang}
          authenticated={false}
        />
        <div className="flex justify-center mt-10p">
          <div className="w-full md:w-2/5 block">
            <Spin spinning={loading}>
              <div className="text-center">
                <img
                  className="inline-block my-3"
                  src={process.env.REACT_APP_COMPANY_LOGO_SMALL}
                  alt="resthero"
                />
                <h2 className="font-raleway font-bold text-21px">
                  {t("Reset Password")}
                </h2>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <div className="col-span-1 sm:col-span-1 my-3 register-page">
                  <TextInput
                    type="text"
                    label={t("Verfication Code")}
                    mandatory={true}
                    value={code}
                    error={Error.codeError}
                    handleValues={(key, val) =>
                      setCode(UTILS.NumberConversion(val))
                    }
                  />
                </div>
                <div className="col-span-1 sm:col-span-1">
                  <TextInput
                    label={t("New Password")}
                    mandatory={true}
                    value={password}
                    error={Error.passwordError}
                    handleValues={(key, val) => setPassword(val)}
                  />
                </div>
              </div>

              <div className="text-center my-2">
                <button
                  className={` font-montserrat py-2 px-12 border border-transparent shadow-sm font-medium rounded  focus:outline-none ${
                    !password || !code
                      ? "bg-gray-500 text-white cursor-not-allowed"
                      : "bg-theme-red text-white"
                  } `}
                  onClick={() => submitNewPassword()}
                  disabled={!password || !code}
                >
                  {t("Submit")}
                </button>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordStep2;
