import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { UTILS } from "utils";
import SignIn from "../../features/auth/containers/Signin";
import Register from "../../features/register/container/Register";
import { useHistory } from "react-router-dom";
import Dashboard from "features/dashboard/container/index";
import ForgotPassword from "features/auth/containers/ForgotPassword";
import NewPassword from "features/auth/containers/newPassword";
function PublicRoutes() {
  const [islogin, setIsLogin] = useState(false);
  let history = useHistory();
  useEffect(() => {
    const userDetail = UTILS.getCurrentUser();
    if (userDetail?.token) {
      setIsLogin(true);
      history.push("/app/dashboard");
    } else {
      setIsLogin(false);
    }
  }, []);

  const authRoutes = [
    {
      path: "/login",
      component: SignIn,
    },
    {
      path: "/forgotpassword",
      component: ForgotPassword,
    },
    {
      path: "/newpassword",
      component: NewPassword,
    },
    {
      path: "/app/dashboard",
      component: Dashboard,
    },
  ];
  return (
    <Fragment>
      <Switch>
        {authRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact
              component={route.component}
            />
          );
        })}

        {!islogin && (
          <Route exact path="/register">
            <Register />
          </Route>
        )}
        <Route path="">
          <SignIn />
        </Route>
      </Switch>
    </Fragment>
  );
}

export default PublicRoutes;
