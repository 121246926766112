import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import Card from "components/Card";
import PageHeading from "components/PageHeading";
import RadioButton from "components/RadioButton";
import SectionHeading from "components/SectionHeading";
import SelectInput from "components/SelectInput";
import TextInput from "components/TextInput";
import { UTILS } from "utils";
import { useCreateModifierGroupMutation } from "redux/modifiers/service";
import { errorHandler } from "api/api/errorHandler";
const CreateModifierGroup = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [createModifierGroup] = useCreateModifierGroupMutation();
  const [modifierGroup, setModifierGroup] = useState({
    values: {
      isSingleSelection: false,
      maximumAllowedSelection: 0,
      minimumAllowedSelection: 0,
      baseLanguage: {
        name: "",
      },
      translations: {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
      },
    },
    errors: {
      minimumAllowedSelection: "",
      maximumAllowedSelection: "",
      baseLanguage: {
        name: "",
      },
      isSingleSelection: "",
    },
  });

  const handleValues = (key, val) => {
    setModifierGroup((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const handleText = (key, val) => {
    setModifierGroup((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        baseLanguage: {
          [key]: val,
        },
        translations: {
          en: {
            name: val,
          },
          ar: {
            name: val,
          },
        },
      },
      errors: {
        ...prevState.errors,
        baseLanguage: {
          [key]: "",
        },
      },
    }));
  };

  const handleSelection = (key, val) => {
    modifierGroup.values.maximumAllowedSelection = 1;

    setModifierGroup((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val === "single" ? true : false,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const addNewModifierGroup = async () => {
    const { isValid } = validateInputs();
    if (!isValid) return;
    try {
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const data = await createModifierGroup({
        brandId: userDetail.brandId,
        ...modifierGroup.values,
      });
      if (data?.data?.success === 1) {
        UTILS.successNotification(
          data?.data?.message ?? t("Modifier Group Created Successfully")
        );
        history.push("/app/dashboard/modifier-group");
      } else {
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      setLoading(false);
    }
  };

  const updatePrice = (key, val) => {
    const value = UTILS.NumberConversion(val);
    let Num = Number(value);
    handleValues(key, Num);
  };

  const seterrors = (obj) => {
    setModifierGroup((prevState) => ({
      ...prevState,
      errors: obj,
    }));
  };

  const validateInputs = () => {
    let isValid = true;
    let obj = {
      baseLanguage: {
        name: "",
      },
      isSingleSelection: "",
    };

    if (
      !modifierGroup?.values?.baseLanguage.name ||
      UTILS.hasWhiteSpace(modifierGroup?.values?.baseLanguage?.name)
    ) {
      isValid = false;
      obj.baseLanguage.name = t("Enter Section Name");
      seterrors(obj);
      return { isValid, obj };
    }

    if (
      !modifierGroup?.values.maximumAllowedSelection ||
      UTILS.hasWhiteSpace(modifierGroup?.values.maximumAllowedSelection)
    ) {
      isValid = false;
      obj.maximumAllowedSelection = t("Enter Maximum Allowed Sections");
      seterrors(obj);
      return { isValid, obj };
    }
    if (modifierGroup?.values?.maximumAllowedSelection <= 0) {
      isValid = false;
      obj.maximumAllowedSelection = t("Maximum selection value cannot be zero");
      seterrors(obj);
      return { isValid, obj };
    }
    if (
      !modifierGroup.values?.maximumAllowedSelection ||
      UTILS.hasWhiteSpace(modifierGroup.values.maximumAllowedSelection)
    ) {
      isValid = false;
      obj.maximumAllowedSelection = t("Enter Maximum Allowed Sections");
      seterrors(obj);
      return { isValid, obj };
    }
    if (modifierGroup?.values?.maximumAllowedSelection <= 0) {
      isValid = false;
      obj.maximumAllowedSelection = t("Maximum selection value cannot be zero");
      seterrors(obj);
      return { isValid, obj };
    }
    if (
      modifierGroup?.values?.minimumAllowedSelection >
      modifierGroup?.values?.maximumAllowedSelection
    ) {
      isValid = false;
      obj.minimumAllowedSelection = t(
        "Minimum selection value must be than less or equal to maximum selection value"
      );
      seterrors(obj);
      return { isValid, obj };
    }
    if (modifierGroup.errors.isSingleSelection) {
      if (modifierGroup.values.minimumAllowedSelection > 1) {
        isValid = false;
        obj.minimumAllowedSelection = t(
          "For single selection, minimum allowed selection cannot be greater than 1"
        );
        seterrors(obj);
        return { isValid, obj };
      }
      seterrors(obj);
      return { isValid, obj };
    }

    return { isValid, obj };
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="">
          <PageHeading heading={t("Create Modifier Group")} />
          <div className="px-3 py-3">
            <Card>
              <div className="flex flex-wrap justify-between py-4">
                <div className="w-full md:w-1/2 px-6">
                  <SectionHeading title={t("Modifier Group Detail")} />
                  <div className="py-3">
                    <TextInput
                      label={t("Name")}
                      value={modifierGroup?.values?.baseLanguage?.name}
                      handleValues={handleText}
                      keyVal="name"
                      mandatory={true}
                      error={modifierGroup?.errors?.baseLanguage?.name}
                    />
                  </div>
                  <div className="pb-3">
                    <SelectInput
                      mandatory={true}
                      label={t("Type Selection")}
                      keyVal="isSingleSelection"
                      handleChange={handleSelection}
                      options={[
                        { value: t("single"), label: t("Single Selection") },
                        {
                          value: t("multiple"),
                          label: t("Multiple Selection"),
                        },
                      ]}
                      value={
                        modifierGroup?.values?.isSingleSelection
                          ? t("single")
                          : t("multiple")
                      }
                      styleClass="font-montserrat text-input-color text-16px mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <span className="text-red-600">
                      {modifierGroup?.errors?.isSingleSelection}
                    </span>
                  </div>
                  <div className="pb-3">
                    <TextInput
                      label={t("Maximum Selections required by Customer?")}
                      value={
                        modifierGroup?.values?.isSingleSelection
                          ? 1
                          : modifierGroup.values.maximumAllowedSelection
                      }
                      disabled={modifierGroup?.values?.isSingleSelection}
                      keyVal={"maximumAllowedSelection"}
                      handleValues={updatePrice}
                      mandatory={false}
                      error={modifierGroup.errors.maximumAllowedSelection}
                    />
                  </div>
                  <div className="pb-3">
                    <TextInput
                      label={t("Minimum Selections required by Customer")}
                      value={modifierGroup.values.minimumAllowedSelection}
                      keyVal="minimumAllowedSelection"
                      handleValues={updatePrice}
                      mandatory={false}
                      error={modifierGroup.errors?.minimumAllowedSelection}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="p-3  mt-2 text-center space-x-4 md:block">
          <Button
            type="default"
            size="large"
            className="w-24 mx-2"
            onClick={() => history.goBack()}
          >
            {t("Back")}
          </Button>
          <Button
            type="primary"
            size="large"
            className="w-24"
            onClick={() => addNewModifierGroup()}
          >
            {t("Create")}
          </Button>
        </div>
      </Spin>
    </div>
  );
};

export default CreateModifierGroup;
