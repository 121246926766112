import React from "react";

import moment from "moment";
import { isItemPublished } from "../utils";
import { PauseCircleOutline, PlayArrow } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

function PlayPause({ rowData, state, handlePlayPause }) {
  const { t } = useTranslation();

  const IsPublished = isItemPublished(rowData, state);
  const Icon = IsPublished?.isValid ? (
    <PauseCircleOutline
      className="cursor-pointer text-indigo-800 h-16 w-16"
      onClick={() => handlePlayPause("pause", rowData)}
    />
  ) : (
    <>
      <span
        className="text-xs	 cursor-pointer font-thin	"
        onClick={() => handlePlayPause("play", rowData)}
      >
        {!IsPublished?.data?.nextAvailableTime
          ? t("Blocked Permanently")
          : t("Returns on", {
              time: moment
                .unix(IsPublished?.data?.nextAvailableTime)
                .format("MMM Do, YYYY - h:mm a"),
            })}
      </span>
      <PlayArrow
        className="cursor-pointer text-indigo-800 h-16 w-16"
        onClick={() => handlePlayPause("play", rowData)}
      />
    </>
  );

  return Icon;
}

export default PlayPause;
