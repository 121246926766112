import React from "react";
import s from "./branding.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { UTILS } from "utils";
import { useSelector } from "react-redux";
const BrandingPreviewMenuWidget = ({ settings, orderNowPopupConfig }) => {
  const { t } = useTranslation();
  const dir = UTILS.getLanguageInformation()?.direction;
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const closeBtn = dir == "rtl" ? "left-4" : "right-4";
  return (
    <div className={s.designright}>
      <div className="preview">
        <div className="py-0 bg-gray-50 p-3 ms-0 md:ms-2 md:p-8">
          <div className="my-0">
            <h2 className="font-medium text-17px text-gray-600 py-3">
              {t("Ordering menu preview")}
            </h2>
            <div className={`${s.sotre_preview} mx-1 sm:mx-5`}>
              <div className="rounded">
                <div className="border-b p-4">
                  <h4 className="text-14px mb-1">{settings?.taglineEN}</h4>
                  <div className="line h-1 bg-gray-300 w-24 mb-1"></div>
                  <div className="flex items-center">
                    <div
                      className="w-1.5 h-1.5 rounded-full m-r-4 "
                      style={{
                        backgroundColor: settings?.color
                          ? settings?.color
                          : "black",
                      }}
                    ></div>
                    <div className="w-8 h-1 bg-gray-300 mx-1.5"></div>
                    <div
                      className="w-1.5 h-1.5 rounded-full m-r-4"
                      style={{
                        backgroundColor: settings?.color
                          ? settings?.color
                          : "black",
                      }}
                    ></div>
                    <div className="w-8 h-1 bg-gray-300 mx-1.5"></div>
                  </div>
                  <div className="flex items-center mt-2.5">
                    <div className="bg-gray-300 w-5 h-5 me-1 rounded-full flex items-center justify-center">
                      <svg
                        style={{
                          color: settings?.color ? settings?.color : "black",
                        }}
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        className="w-2.5 h-2.5 inline-block"
                      >
                        <path d="M500.59 157.733L424.118 9.035C421.33 3.614 415.765 0 409.067 0H102.931c-.042 0-.084.003-.127.004-.039-.001-.079-.004-.117-.004h-.007a16.648 16.648 0 00-14.799 9.035L11.408 157.733c-2.703 5.258-2.351 11.53.594 16.287.169.336 230.213 330.71 230.443 331.031 6.688 9.336 20.637 9.252 27.192-.17 1.278-1.835 229.948-330.18 230.396-330.925a16.685 16.685 0 00.557-16.223zM381.782 33.299l-49.201 95.672c-28.87-56.027-17.64-34.209-49.248-95.672h98.449zM256.011 53.071c28.797 55.887 17.579 34.093 49.225 95.627h-98.501l49.276-95.627zm-27.295-19.772l-49.299 95.672-49.201-95.672h98.5zM102.8 53.217l49.2 95.481H53.539L102.8 53.217zm-44.706 128.78h108.102l54.01 232.854L58.094 181.997zM255.973 421.68l-55.594-239.682h111.188L255.973 421.68zm35.761-6.805l54.016-232.878H453.9L291.734 414.875zM360 148.698l49.298-95.672 49.202 95.672H360z"></path>
                      </svg>
                    </div>
                    <div>
                      <div className="w-24 h-1 bg-gray-400  mb-1"></div>
                      <div className="w-12 h-1 bg-gray-300"></div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between p-2.5">
                  <div>
                    <div className="w-10 h-1 bg-gray-400 mb-1"></div>
                    <div className="flex items-center">
                      <div
                        className="w-1.5 h-1.5 rounded-full mr-1 "
                        style={{
                          backgroundColor: settings?.color
                            ? settings?.color
                            : "black",
                        }}
                      ></div>
                      <div className="w-11 h-1 bg-gray-300"></div>
                    </div>
                  </div>
                  <div className="preview__delivery-switcher">
                    <div
                      className={`${s.bcstyle}  text-9px px-1 py-0.5 rounded`}
                      style={{
                        backgroundColor: settings?.color
                          ? settings?.color
                          : "black",
                        color: settings?.textColor
                          ? settings?.textColor
                          : "black",
                      }}
                    >
                      {t("Delivery")}
                    </div>
                  </div>
                </div>
                <div className="flex items-center p-2.5 border-b ">
                  <div
                    className={` text-9px ${s.cat_btn}`}
                    style={{
                      backgroundColor: settings?.color
                        ? settings?.color
                        : "black",
                      color: settings?.textColor
                        ? settings?.textColor
                        : "black",
                    }}
                  >
                    {t("Category")}
                  </div>
                  <div className={`text-9px ${s.cat_btn}`}>
                    {t("Category")}{" "}
                  </div>
                  <div className={`text-9px ${s.cat_btn}`}>
                    {" "}
                    {t("Category")}{" "}
                  </div>
                  <div className={`text-9px ${s.cat_btn}`}>
                    {" "}
                    {t("Category")}{" "}
                  </div>
                </div>
                <div className="p-2.5 relative">
                  <div className="w-14 h-1 bg-gray-400 mb-2.5"></div>
                  <div className="flex items-center justify-between mb-2.5">
                    <div>
                      <div className="w-24 h-1 bg-gray-400 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-16 h-1 bg-gray-400 mb-1"></div>
                    </div>
                    <div className="w-9 h-9 bg-gray-200"></div>
                  </div>
                  <div className="flex items-center justify-between mb-2.5">
                    <div>
                      <div className="w-24 h-1 bg-gray-400 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-16 h-1 bg-gray-400 mb-1"></div>
                    </div>
                    <div className="w-9 h-9 bg-gray-200"></div>
                  </div>
                  <div className="flex items-center justify-between mb-2.5">
                    <div>
                      <div className="w-24 h-1 bg-gray-400 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-16 h-1 bg-gray-400 mb-1"></div>
                    </div>
                    <div className="w-9 h-9 bg-gray-200"></div>
                  </div>
                  <div className="flex items-center justify-between mb-2.5">
                    <div>
                      <div className="w-24 h-1 bg-gray-400 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-16 h-1 bg-gray-400 mb-1"></div>
                    </div>
                    <div className="w-9 h-9 bg-gray-200"></div>
                  </div>
                  <div className="flex items-center justify-between mb-2.5">
                    <div>
                      <div className="w-24 h-1 bg-gray-400 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-16 h-1 bg-gray-400 mb-1"></div>
                    </div>
                    <div className="w-9 h-9 bg-gray-200"></div>
                  </div>
                  <div className="flex items-center justify-between mb-2.5">
                    <div>
                      <div className="w-24 h-1 bg-gray-400 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-28 h-1 bg-gray-300 mb-1.5"></div>
                      <div className="w-16 h-1 bg-gray-400 mb-1"></div>
                    </div>
                    <div className="w-9 h-9 bg-gray-200"></div>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 p-2.5">
                    <div
                      className={`${s.basketstyle} `}
                      style={{
                        backgroundColor: settings?.color
                          ? settings?.color
                          : "black",
                        color: settings?.textColor
                          ? settings?.textColor
                          : "black",
                      }}
                    >
                      {t("Add to basket")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <h2 className="font-medium text-17px text-gray-600 pt-6 py-3">
              {t("Ordering widget preview")}
            </h2>
            <div className="flex my-3 p-1 sm:p-5 sm:pt-0 pt-0 mt-0 justify-center items-end flex-col sm:flex-row">
              <div className={s.preview_widget}>
                <svg
                  fill="currentColor"
                  xmlns="https://www.w3.org/2000/svg"
                  className={`w-4 h-4 absolute top-4 ${closeBtn}`}
                >
                  <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M7.414 6l4.293-4.293L10.293.293 6 4.586 1.707.293.293 1.707 4.586 6 .293 10.293l1.414 1.414L6 7.414l4.293 4.293 1.414-1.414L7.414 6z"
                    fill="#000"
                  ></path>
                </svg>
                <h3 className="mb-2 text-24px font-normal">
                  {orderNowPopupConfig?.title
                    ? orderNowPopupConfig?.title
                    : t("Hey there")}
                </h3>
                <p
                  className=" text-15px  mb-2 text-gray-600"
                  style={{ width: "175px" }}
                >
                  {orderNowPopupConfig?.message
                    ? orderNowPopupConfig?.message
                    : t("Order online now!")}
                </p>
                <div className="pg-bg1 flex flex-col justify-center">
                  <div className="text-center mt-1">
                    <Button
                      type="success"
                      size="medium"
                      className=" text-white rounded-full h-10 text-15px"
                      style={{
                        backgroundColor: settings?.color
                          ? settings?.color
                          : "black",
                        color: settings?.textColor
                          ? settings?.textColor
                          : "black",
                      }}
                      onClick={() => {}}
                    >
                      {orderNowPopupConfig?.buttonText
                        ? orderNowPopupConfig?.buttonText
                        : t("Order now!")}
                    </Button>
                  </div>
                  <div className="text-center">
                    <span className="text-9px">
                      {t("Powered by")}{" "}
                      <span className={`uppercase ${s.clrr}`}>
                        {t("company name", {
                          company:
                            currentLanguage === "EN"
                              ? process.env.REACT_APP_COMPANY_NAME_EN
                              : process.env.REACT_APP_COMPANY_NAME_AR,
                        })}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="w-14 h-14 rounded bg-white shadow-sm p-2.5 mx-2 border border-gray-100">
                {orderNowPopupConfig?.iconUrl && (
                  <img
                    style={{ width: "100%" }}
                    src={orderNowPopupConfig?.iconUrl}
                    alt="avatar"
                  />
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingPreviewMenuWidget;
