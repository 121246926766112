import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { useToggleModifierInventoryMutation } from "redux/modifiers/service";
import { UTILS } from "utils";

function usePlayPauseModifier({
  setPauseModalState,
  updateState,
  getModifiersByStore,
  state,
}) {
  const handleplayPauseModifier = async (modifierId, duration) => {
    try {
      updateState("loading", true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.toggleModifierInventory({
        brandId: userDetail.brandId,
        storeId: state?.currentStoreId,
        modifierId: modifierId?._id,
        ...(duration && { duration }),
      });

      if (result?.success === 1) {
        getModifiersByStore(state?.currentStoreId);
      } else {
        console.log({ error: result });
        UTILS.handleErrorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      console.log({ error });
      UTILS.handleErrorNotification(errorHandler(error?.error));
    } finally {
      updateState("loading", false);
      setPauseModalState("open", false);
      setPauseModalState("modifier", null);
      setPauseModalState("deafaultOption", {
        value: 6,
        unit: "hours",
        duration: "6_HOURS",
      });
    }
  };

  const handlePlayPause = async (key, row) => {
    if (key === "pause") {
      setPauseModalState("open", true);
      setPauseModalState("modifier", row);
      return;
    } else if (!row._id || !state?.currentStoreId) return;
    handleplayPauseModifier(row);
  };
  return {
    handlePlayPause,
    handleplayPauseModifier,
  };
}

export default usePlayPauseModifier;
