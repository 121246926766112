import { useState, useEffect } from "react";
import { API } from "api";
import { UTILS } from "utils";

const useAllItems = () => {
  const [items, setItems] = useState([]);
  const [Itemloader, setLoading] = useState(true);
  const getItemsByBrand = async () => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_ITEMS_BY_BRAND({
      token: userDetail.token,
      brandId: userDetail.brandId,
      language: "EN",
    });

    if (result && result?.success === 1) setItems(result?.data);
    else {
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getItemsByBrand();
    return () => {
      setItems([]);
    };
  }, []);

  return { items, Itemloader };
};

export default useAllItems;
