import React from "react";
import PropTypes from "prop-types";
import { UTILS } from "utils";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};
const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600"> {error} </span>;
  }
};
const PriceInput = (props) => {
  const {
    placeHolder,
    register,
    rules,
    disabled,
    name,
    value,
    label,
    mandatory,
    error,
    maxLength,
    handleValues,
  } = props;

  const mandatoryField = getMandatoryField(mandatory);
  const fieldError = getFieldError(error);

  const handleChangePrice = (event) => {
    let v = UTILS.ARtoENPrice(event.target.value);
    handleValues(props.keyVal, v);
  };

  return (
    <>
      <label className="text-gray-600 block text-14px">
        {label}
        {mandatoryField}
      </label>
      <input
        {...props}
        maxLength={maxLength ? maxLength : ""}
        value={value}
        {...(register && register(name, rules))}
        placeholder={placeHolder ? placeHolder : ""}
        className="font-montserrat text-16px text-input-color text-sm border border-grey-600 focus:ring-indigo-500 outline-none focus:border-indigo-500 block w-full border-gray-300 p-2"
        onChange={(event) => handleChangePrice(event)}
        type="text"
      />
      <div className="text-15x">{fieldError}</div>
    </>
  );
};

PriceInput.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
};

export default PriceInput;
