import i18next from "i18next";
export const getThirdsDaysArrayWithDayLebel = () => {
  const Days = [
    {
      value: 1,
      label: 1 + i18next.t("day"),
    },
  ];

  Array.from({ length: 30 }, (_, x) =>
    Days.push({
      value: x + 2,
      label: x + 2 + i18next.t("days"),
    })
  );
  return Days;
};
export const getNumOfDaysWithZero = () => {
  const Days = [
    {
      value: 0,
      label: 0 + i18next.t("day"),
    },
    {
      value: 1,
      label: 1 + i18next.t("day"),
    },
  ];

  Array.from({ length: 30 }, (_, x) =>
    Days.push({
      value: x + 2,
      label: x + 2 + i18next.t("days"),
    })
  );
  return Days;
};

export const getHMinutSlotsTill8Hors = () => {
  const options = [];
  const maxMinutes = 8 * 60; // 8 hours in minutes
  for (let i = 15; i <= maxMinutes; i += 15) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;

    const hourLabel = `${hours} ${
      hours > 1 ? i18next.t("Hours") : i18next.t("Hour")
    }`;
    const hoursLabel = hours > 0 ? hourLabel : "";
    const minuteLabel = `${minutes} ${
      minutes > 1 ? i18next.t("Minutes") : i18next.t("Minute")
    }`;
    const minutesLabel = minutes > 0 ? minuteLabel : "";
    const label = `${hours > 0 ? hoursLabel : ""} ${
      minutes > 0 ? minutesLabel : ""
    }`;

    options.push({
      value: i,
      label: label.trim(),
    });
  }

  return options;
};

export const TimingUnitsWithLabel = [
  { _id: "1", value: "minutes", label: i18next.t("Minutes") },
  { _id: "2", value: "hours", label: i18next.t("Hours") },
];
