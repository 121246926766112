import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Clear, SearchOutlined } from "@material-ui/icons";
import { Input, Spin, Table, Select, Button } from "antd";
import { API } from "api";
import moment from "moment";

import Modal from "components/Modal";
import RefundForm from "components/RefundOrderForm";
import React, { useEffect, useState } from "react";
import { UTILS } from "utils";
import Card from "../../../components/Card";
import PageHeading from "../../../components/PageHeading";
import ShowOrderDetail from "../modals/ShowOrderDetail";
import { errorNotification } from "utils/Utils";
import { errorHandler } from "api/api/errorHandler";
const { Option } = Select;

const OrderHistory = () => {
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const [orders, setOrders] = useState({
    data: [],
    totalPages: 0,
    currentPage: 1,
  });
  const [rowData, setRowData] = useState({});
  const [loading, setLoading] = useState(false);
  const [refundOrder, setRefundOrder] = useState({
    visible: false,
    loading: false,
  });
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const { t } = useTranslation();
  const zone = moment.tz.guess();
  moment.tz.setDefault(zone);
  const renderDate = (value, rowData, index) => {
    return (
      <div className="px-3 py-1 whitespace-nowrap text-center">
        <h3>
          {rowData.orderPlacedTime
            ? moment.unix(rowData.orderPlacedTime).format("DD/MM/YYYY")
            : ""}
        </h3>
        <h5 className="leading-3 text-12px text-gray-500">
          {rowData.orderPlacedTime
            ? moment.unix(rowData.orderPlacedTime).format("HH:mm:ss")
            : ""}
        </h5>
      </div>
    );
  };

  const OrderDetail = (value, rowData, index) => {
    return (
      <>
        <Link
          to="#"
          className="mx-1 text-blue-600 cursor-pointer"
          key="edit"
          onClick={() => {
            setOpen(true);
            setRowData(rowData);
          }}
        >
          <svg
            xmlns="https://www.w3.org/2000/svg"
            fill="currentColor"
            className="h-4 w-4 text-gray-600"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </Link>
      </>
    );
  };
  const columns = [
    {
      title: t("Number"),
      dataIndex: "orderNumber",
      sorter: {
        compare: (a, b) => a.orderNumber - b.orderNumber,
        multiple: 3,
      },
    },
    {
      title: t("Price"),
      dataIndex: "totalOrderPrice",
      sorter: {
        compare: (a, b) => a.totalOrderPrice - b.totalOrderPrice,
        multiple: 3,
      },
    },
    {
      title: t("Date"),
      dataIndex: "orderPlacedTime",
      render: renderDate,
      sorter: {
        compare: (a, b) => a.orderPlacedTime - b.orderPlacedTime,
        multiple: 2,
      },
    },
    {
      title: t("Type"),
      dataIndex: "orderType",
      render: (val, row, index) => {
        return currentLanguage === "EN" ? val.EN.name : val.AR.name;
      },
    },
    {
      title: t("Whatsapp Number"),
      dataIndex: "userInfo",
      render: (val, row, index) => {
        return (
          <div
            dir="ltr"
            className="cursor-pointer text-blue-600 underline hover:text-blue-800"
            onClick={() => {
              UTILS.openWhatsApp(val?.phoneNumber);
            }}
          >
            {val?.phoneNumber}
          </div>
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "orderStatus",
      render: (val, row, index) => {
        return t(val);
      },
    },
    {
      title: t("Store"),
      dataIndex: "storeInfo",
      width: "25%",
      render: (val, row, index) => {
        return val.baseLanguage.name;
      },
    },

    {
      title: t("Detail"),
      dataIndex: "OrderDetail",
      render: (val, row, index) => {
        return OrderDetail(val, row, index);
      },
    },
  ];

  const getParams = (pageNo) => {
    if (searchTerm) {
      return {
        searchOn: selectedSearchCondition,
        search: searchTerm,
        page: pageNo ?? orders.currentPage,
      };
    } else {
      return {
        page: pageNo ?? orders.currentPage,
      };
    }
  };

  const getOrderHistory = async (pageNo) => {
    try {
      setLoading(true);
      const result = await API.GET_ORDERS_HISTORY(getParams(pageNo));
      if (
        result?.success === 1 &&
        UTILS.isValidObject(["data", "orders"], result)
      ) {
        handleStateChange("data", result.data.orders);
        handleStateChange("totalPages", result.data.count);
      } else {
        UTILS.handleErrorNotification(t("Please try again Later"));
      }
    } catch (error) {
      errorNotification(
        errorHandler(error?.error?.message || t("Please try again Later"))
      );
    } finally {
      setLoading(false);
    }
  };

  const submitRefundOrder = async ({ amount, reason, order }) => {
    setRefundOrder({
      ...refundOrder,
      loading: true,
    });
    const { _id: orderId, brandId } = order;
    const obj = {
      orderId,
      amount,
      reason,
      brandId,
    };
    const result = await API.REFUND_ORDER(obj);
    if (result?.success === 1) {
      UTILS.successNotification(result?.message || t("Refund Requested"));
      getOrderHistory(orders.currentPage);
      setRefundOrder({
        ...refundOrder,
        loading: false,
        visible: false,
      });
      setOpen(false);
    } else {
      setRefundOrder({
        ...refundOrder,
        loading: false,
      });
      UTILS.errorNotification(result?.message || t("Please try again Later"));
    }
  };

  const searchByKeys = [
    {
      key: "ORDER_NUMBER",
      label: "By Order Number",
    },
    {
      key: "PHONE_NUMBER",
      label: "By WhatsApp Number",
    },
  ];

  const [selectedSearchCondition, setSelectedSearchCondition] = useState(
    searchByKeys[0].key
  );

  useEffect(() => {
    getOrderHistory(orders.currentPage);
  }, [selectedSearchCondition]);
  useEffect(() => {
    if (searchTerm?.length === 0) {
      getOrderHistory(orders.currentPage);
    }
  }, [searchTerm]);

  const handleStateChange = (key, value) => {
    setOrders((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <Modal
        open={open}
        modalWidth="rounded-md border border-gray-300 top-0"
        onClose={() => setOpen(false)}
        scroll={true}
      >
        <ShowOrderDetail
          rowData={rowData}
          setOpen={setOpen}
          loading={loading}
          setLoading={setLoading}
          showRefundModal={() => {
            setRefundOrder({ ...refundOrder, visible: true });
            setOpen(false);
          }}
        />
      </Modal>

      <Modal
        open={refundOrder?.visible}
        modalWidth="rounded-md border border-gray-300 top-0"
        onClose={() =>
          setRefundOrder({ ...refundOrder, loading: false, visible: false })
        }
        showScroll={true}
        scroll={true}
      >
        <RefundForm
          loading={refundOrder?.loading}
          order={rowData}
          onSubmit={submitRefundOrder}
          closeModal={() => {
            setRefundOrder({
              ...refundOrder,
              loading: false,
              visible: false,
            });
            setOpen(true);
          }}
        />
      </Modal>

      <PageHeading heading={t("Orders History")} />
      <div className="m-5">
        <Spin spinning={loading}>
          <Card styleClasses="px-2 py-2">
            <h2 className="text-gray-500 py-2">
              {t(
                "For more detailed report contact Suppport at with your registered email address",
                {
                  email: process.env.REACT_APP_SUPPORT_EMAIL,
                }
              )}
            </h2>

            <div className="flex flex-col sm:flex-row justify-end gap-2">
              <div className="my-l1">
                <Select
                  placeholder={t("Search by")}
                  className="mx-0 sm:mx-0.5 sm:my-1 w-full"
                  style={{ height: "33px", minWidth: "190px" }}
                  value={selectedSearchCondition}
                  onChange={(value) => {
                    setSelectedSearchCondition(value);
                    setSearchTerm(null);
                  }}
                >
                  {searchByKeys.map((option) => (
                    <Option key={option.key} value={option.key}>
                      {t(option.label)}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="my-1">
                <Input
                  placeholder={
                    selectedSearchCondition === "ORDER_NUMBER"
                      ? t("Order Number")
                      : t("WhatsApp Number")
                  }
                  style={{ height: "33px" }}
                  suffix={
                    searchTerm ? (
                      <Clear
                        className="cursor-pointer"
                        onClick={() => {
                          setSearchTerm("");
                        }}
                      />
                    ) : (
                      <SearchOutlined
                        onClick={() => {
                          getOrderHistory(orders.currentPage);
                          handleStateChange("currentPage", 1);
                        }}
                      />
                    )
                  }
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
              <div className="my-1">
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    getOrderHistory(1);
                    handleStateChange("currentPage", 1);
                  }}
                >
                  {t("Search")}
                </Button>
              </div>
            </div>

            <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
              <Table
                locale={{ emptyText: t("No Data") }}
                columns={columns}
                dataSource={orders.data}
                pagination={{
                  current: orders.currentPage,
                  position: ["bottomCenter"],
                  pageSize: 20,
                  total: orders.totalPages,
                  showQuickJumper: false,
                  showSizeChanger: false,
                }}
                onChange={(pagination, filters, sorter) => {
                  handleStateChange("currentPage", pagination.current);

                  getOrderHistory(pagination.current);
                }}
                size="small"
                rowKey={(record) => record.orderNumber}
              />
            </div>
          </Card>
        </Spin>
      </div>
    </>
  );
};

export default OrderHistory;
