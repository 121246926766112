import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal as AntModal } from "antd";
import { useTranslation } from "react-i18next";
import LinkUnLinkMenuWithStoresModal from "features/menus/components/LinkUnLinkMenuWithStoresModal";

function LinkStoresWithMenu({
  menuState,
  updateState,
  stores,
  loading,
  linkMenu,
}) {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  return (
    <AntModal
      open={menuState.isLinkMenuWithStoresModalOpen}
      onCancel={() => {
        updateState("isLinkMenuWithStoresModalOpen", false);
        updateState("linkedStores", []);
      }}
      footer={[
        <div className="flex justify-center">
          <Button
            disabled={
              menuState.linkedStores?.length === 0 || loading || isLoading
            }
            type="primary"
            onClick={linkMenu}
          >
            {t("Save")}
          </Button>
          <Button
            disabled={loading || isLoading}
            onClick={() => {
              updateState("linkedStores", []);
              updateState("isLinkMenuWithStoresModalOpen", false);
            }}
          >
            {t("Cancel")}
          </Button>
        </div>,
      ]}
      title={t("Connections")}
      width={500}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 500px)" }}
      className="link-modalpopup"
    >
      <LinkUnLinkMenuWithStoresModal
        {...{
          stores,
          loading,
        }}
        selectedStores={menuState.linkedStores}
        setselectedStores={(val) => updateState("linkedStores", val)}
        menus={menuState.addedMenus}
      />
    </AntModal>
  );
}

export default LinkStoresWithMenu;
