import Card from "components/Card";
import React, { useEffect, useRef, useState } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import moment from "moment";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import { RedoOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import TableItemsDetail from "../../orders/components/TableItemsDetail";
import Talabat from "../talabat/Talabat";
import CheckDeliveryModal from "../Modals/CheckDeliveryModal";
import SelectedIntegrations from "../../../logistic-integrations/SelectedLogistics";
import { useSelector } from "react-redux";
import PaymentInfo from "features/order-payment-info/Index";
import { getORderPaymentGateway } from "utils/Utils";
import RefundOrderInfo from "features/orders/modals/stripeRefund/RefundOrderInfo";
const OrderDetail = ({ order, orderId, tabStatus, getOrderInfoById }) => {
  const [open, setOpen] = useState(false);
  const [talabatModal, setTalabat] = useState(false);
  const [changed, setChanged] = useState(false);
  const componentRef = useRef();
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const zone = moment.tz.guess();
  moment.tz.setDefault(zone);

  useEffect(() => {
    if (orderId) {
      getOrderInfoById(orderId);
    }
  }, [orderId, tabStatus, order?._id]);
  const [paymentGatewayInfoModal, setPaymentGatewayInfoModal] = useState(false);

  const Header = () => {
    return (
      <div
        className="bg-white flex justify-between flex-col sm:flex-row px-7 py-3"
        key={orderId}
      >
        <h2 className="w-1/3 text-blue-600 font-medium">
          {order.orderNumber ? t("Order #") + order?.orderNumber : "..."}
        </h2>
        <h2 className="w-1/3 font-medium">
          {order.orderPlacedTime
            ? moment.unix(order?.orderPlacedTime).fromNow()
            : "..."}
        </h2>
        <div className="flex mt-2 sm:mt-0">
          <button
            className="me-2 px-2 bg-white text-blue-500 border border-blue-500"
            onClick={() => {
              getOrderInfoById(orderId);
            }}
          >
            <RedoOutlined />
            <span className="ps-1">{t("Refresh")}</span>
          </button>
          <h2 className="flex items-center text-theme-red text-center border border-theme-red text-12px font-medium px-2 py-1 rounded-sm">
            {t(order.orderStatus ? order?.orderStatus : "...")}
          </h2>
          <button
            className="ms-2 me-2 px-2 py-1 bg-blue-500 text-white rounded-sm"
            onClick={() => handlePrint()}
          >
            <PrinterOutlined />
            <span className="px-2 mt-1">{t("Print")}</span>
          </button>
        </div>
      </div>
    );
  };

  const isInterated = (name) => {
    let isIntegrationDialogOpen = false;
    if (order && UTILS.isValidObject(["activeIntegrations"], order)) {
      if (Array.isArray(order.activeIntegrations)) {
        for (let index = 0; index < order?.activeIntegrations.length; index++) {
          const element = order?.activeIntegrations[index];
          if (
            element?.name === name &&
            element?.category === "LOGISTIC" &&
            element?.isActive === true
          ) {
            isIntegrationDialogOpen = true;
          }
        }
      }
    }
    return isIntegrationDialogOpen;
  };

  const getDeliveryAddress = () => {
    let add = "";
    add += order?.deliveryAddress ? order?.deliveryAddress?.address : "....";
    add +=
      order?.deliveryAddress?.building ||
      order?.deliveryAddress?.floor ||
      order?.deliveryAddress?.appartmentNo
        ? "("
        : "";

    add += order?.deliveryAddress?.building
      ? t("Building") + order?.deliveryAddress?.building + ","
      : "";

    add += order?.deliveryAddress?.floor
      ? t("Floor") + order?.deliveryAddress?.floor + ","
      : "";
    add += order?.deliveryAddress?.appartmentNo
      ? t("Apartment No") + order?.deliveryAddress?.appartmentNo
      : "";
    add +=
      order?.deliveryAddress?.building ||
      order?.deliveryAddress?.floor ||
      order?.deliveryAddress?.appartmentNo
        ? ")"
        : "";
    return add;
  };

  const getGoogleMapLink = () => {
    let gLink = "https://maps.google.com/?q=";
    gLink += order?.deliveryAddress?.street
      ? order?.deliveryAddress?.street
      : "";
    return gLink;
  };

  //delivery type can be  TAblat,mursool,
  // if logistic partner is Store it means no logistic partner has not yet been selected
  //
  const checkIfDeliverySelected = (deliveryType, cb) => {
    const isSelected = UTILS.getSelectedDelivery(order); //get delivery type

    //  Earlier logistic partner selected
    // selected is !== clicked
    // cliked is not talabat
    if (
      isSelected !== C.STORE &&
      isSelected !== deliveryType &&
      deliveryType !== C.TALABAT
    ) {
      setOpen(true); //open chnge delivery model
      setChanged({
        //call the cb function
        fun: cb,
        type: C.OTHER,
      });
    }

    //  Earlier logistic partner selected
    // selected is !== clicked
    // cliked  not talabat
    else if (
      isSelected !== C.STORE &&
      isSelected !== deliveryType &&
      deliveryType === C.TALABAT
    ) {
      setOpen(true); //open model and open talabat model
      setChanged({
        //open talabat model
        fun: cb,
        type: C.TALABAT,
      });
    }

    // logistic partner selected
    // open talabat for showing detail
    else if (isSelected !== C.STORE && deliveryType === C.TALABAT) {
      setTalabat(true); //open talabat
    }
    // No Logistic Partner is selected
    else {
      // clicked is Talabat open talabat model
      if (isSelected === C.STORE && deliveryType === C.TALABAT) {
        cb(true); //
      }
      // cicked is other than talabat ,call that function
      if (isSelected === C.STORE && deliveryType !== C.TALABAT) {
        cb();
      }
    }
    getOrderInfoById(order._id);
  };

  function shouldRenderTalabatComponent(order) {
    return order && isInterated("TALABAT");
  }

  return (
    <div className="overflow-scroll" style={{ height: 773 }}>
      <Spin spinning={false}>
        <Header />
        <CheckDeliveryModal
          {...{ open, setOpen, changed }}
          deliveryType={order.logisticPartner}
        />
        <Card>
          <div
            className="py-5 min-h-300"
            id={"orderForPrint"}
            ref={componentRef}
          >
            <div className="flex flex-wrap px-7">
              {order?.userInfo?.name && (
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Customer Name")}</div>

                  <div className="font-semibold">{order?.userInfo?.name}</div>
                </div>
              )}
              <div className="w-1/2 py-3 hidden-print">
                <div className="text-gray-500">{t("Preparation Type")}</div>

                <div className="font-semibold blink text-red-600">
                  {order.isPreOrder ? t("PRE ORDER") : t("ASAP")}
                </div>
              </div>
              <div className="w-1/2 py-3">
                <div className="text-gray-500">{t("Order Type")}</div>

                <div className="font-semibold blink text-red-600">
                  {order.orderType
                    ? currentLanguage === "EN"
                      ? order.orderType.EN.name
                      : order.orderType.AR.name
                    : "..."}
                </div>
              </div>
              <div className="w-1/2 py-3">
                <div className="text-gray-500">{t("Store")}</div>

                <div className="font-semibold">
                  {order.storeInfo ? order.storeInfo.baseLanguage.name : "..."}
                </div>
              </div>

              {/* <div className="flex px-7 py-1 time__hide"> */}
              <div className="w-1/2 py-3 time__hide">
                <div className="text-gray-500">{t("Order Placed Time")}</div>

                <div className="font-semibold">
                  {order.orderPlacedTime
                    ? moment
                        .unix(order.orderPlacedTime)
                        .format("DD-MM-Y hh:mm A")
                    : "..."}
                </div>
              </div>
              <div className="w-1/2 py-3 time__hide">
                <div className="text-gray-500">{t("Order Id")}</div>

                <div className="font-semibold">
                  {order?.orderNumber ? order?.orderNumber : "..."}
                </div>
              </div>
              {/* </div>

            <div className="flex px-7 py-3"> */}
              {order?.orderType?.type === "DELIVERY" ? (
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Street Address")} </div>
                  <div
                    className="font-semibold text-blue-600 pe-4 cursor-pointer"
                    onClick={() =>
                      window.open(getGoogleMapLink(), "_blank").focus()
                    }
                  >
                    {order?.deliveryAddress?.street
                      ? order?.deliveryAddress?.street
                      : ""}
                  </div>
                </div>
              ) : null}

              {Boolean(
                order?.refund?.status === "FAILED" && tabStatus === "REFUND"
              ) && <RefundOrderInfo rowData={order} />}

              {order?.userInfo?.phoneNumber && (
                <div
                  className="w-1/2 py-3"
                  onClick={() => UTILS.openWhatsApp(order.userInfo.phoneNumber)}
                >
                  <div className="text-gray-500">{t("Whatsapp Number")}</div>
                  <div className="font-semibold">
                    <span
                      dir="ltr "
                      className="cursor-pointer underline text-blue-600 hover:text-blue-800 mb-1"
                    >
                      {order.userInfo ? order.userInfo.phoneNumber : "...."}
                    </span>
                  </div>
                </div>
              )}

              {order?.orderType?.type === C.SERVICE_TYPES.DINEIN ? (
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Selected Table")}</div>
                  <div className="font-semibold">
                    <span dir="ltr" className="text-red-600">
                      {order?.dineIn?.tableNumber
                        ? order?.dineIn?.tableNumber
                        : "...."}
                    </span>
                  </div>
                </div>
              ) : null}
              {order?.orderType?.type === C.SERVICE_TYPES.PICKUP &&
                order?.pickup?.vehicleInfo && (
                  <div className="w-1/2 py-3">
                    <div className="text-gray-500">{t("Vehicle Info")}</div>
                    <div className="font-semibold">
                      <span dir="ltr" className="text-red-600">
                        {order?.pickup?.vehicleInfo
                          ? order?.pickup?.vehicleInfo
                          : "...."}
                      </span>
                    </div>
                  </div>
                )}
              {/* </div>

            <div className="flex px-7 py-3"> */}
              {console.log("order?.selectedPaymentMethod?.EN?.name", order)}
              <div className="w-1/2 py-3">
                <PaymentInfo
                  orderInfo={order}
                  {...{ paymentGatewayInfoModal, setPaymentGatewayInfoModal }}
                >
                  <div className="text-gray-500">{t("Payment")}</div>

                  <div
                    className={`font-semibold ${
                      order?.paymentType === C.PAYMENT_TYPE_ONLINE &&
                      getORderPaymentGateway(order) === C.PAYMENT_GATWAYS.STRIPE
                        ? "cursor-pointer underline text-blue-600 hover:text-blue-800 mb-1"
                        : ""
                    }`}
                  >
                    {order.selectedPaymentMethod
                      ? currentLanguage === "EN"
                        ? order.selectedPaymentMethod.EN.name
                        : order.selectedPaymentMethod.AR.name
                      : "..."}
                  </div>
                </PaymentInfo>
              </div>
              <div className="w-1/2 py-3">
                <div className="text-gray-500">{t("Order From")}</div>
                <div className="font-semibold uppercase text-red-600">
                  {order.orderingPlatform ? order.orderingPlatform : "...."}
                </div>
              </div>
              {/* </div>

            <div className="flex px-7 py-3"> */}
              {order?.orderType?.type === "DELIVERY" ? (
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Full Address")}</div>
                  <div className="font-semibold break-words pe-2">
                    {getDeliveryAddress()}
                  </div>
                </div>
              ) : null}

              {order?.userInfo?.lifetimeSpending ? (
                <div className="w-1/2 hidden-print py-3">
                  <div className="text-gray-500">{t("Lifetime Spending")}</div>
                  <div className="font-semibold break-words pe-2 text-green-600">
                    <span className="text-green-600">AED </span>
                    {parseFloat(order?.userInfo?.lifetimeSpending)?.toFixed(2)}
                  </div>
                </div>
              ) : null}

              {/* <div className="w-1/2">
                <div className="text-gray-500">{t("Selected Area")}</div>
                <div className="font-semibold">
                  {order.selectedArea
                    ? language.current === "en"
                      ? order.selectedArea.areaNameEN
                      : order.selectedArea.areaNameAR
                    : "..."}
                </div>
              </div> */}
              {/* </div>

            <div className="flex px-7 py-3 hidden-print"> */}

              {order?.isPreOrder ? (
                <div className="w-1/2 py-3 hidden-print">
                  <div className="text-gray-500">
                    {order?.orderType?.type === C.SERVICE_TYPES.DELIVERY
                      ? t("Pre Order Delivery Slot")
                      : t("Pre Order Pickup Slot")}
                  </div>
                  <div className="font-bold">
                    <div>
                      Date :{" "}
                      {moment
                        .unix(order?.preOrder?.preOrderSlotStartTime)
                        .format("DD-MM-YYYY")}
                    </div>
                    {t("Slot")} :{" "}
                    {moment
                      .unix(order?.preOrder?.preOrderSlotStartTime)
                      .format("hh:mm A")}{" "}
                    -{" "}
                    {moment
                      .unix(order?.preOrder?.preOrderSlotEndTime)
                      .format("hh:mm A")}
                  </div>
                </div>
              ) : null}

              {order && order?.discountApplied && (
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Discount Info")}</div>
                  <div className="font-semibold">
                    {t("Type")} (
                    {order?.discountApplied
                      ? t(order?.discountApplied?.discountType)
                      : ""}
                    ) - {t("Code")} (
                    {order?.discountApplied
                      ? order?.discountApplied?.discountCode
                      : ""}
                    )
                  </div>
                </div>
              )}
            </div>

            {/* special request */}
            {order?.orderLevelSpecialRequest?.trim()?.length > 0 ? (
              <div className="flex px-7 py-3">
                <div className="w-full lg:w-1/2 xl:w-2/3">
                  <div className="text-gray-500">{t("Special Note")} </div>
                  <div className="font-semibold  pe-4 cursor-pointer">
                    {order?.orderLevelSpecialRequest
                      ? order?.orderLevelSpecialRequest
                      : ""}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="flex flex-wrap px-7 hidden-print">
              {/* {shouldRenderMarsoolComponent(order) && <MarsoolComponent />} */}
              {shouldRenderTalabatComponent(order) &&
                order?.orderType?.type === C.DELIVERY_SERICETYPE && (
                  <Talabat
                    {...{
                      order,
                      getOrderInfoById,
                      open: talabatModal,
                      setOpen: setTalabat,
                      checkIfDeliverySelected,
                    }}
                  />
                )}

              {order.orderType?.type === C.DELIVERY_SERICETYPE && (
                <SelectedIntegrations {...{ order }} />
              )}
            </div>
            <TableItemsDetail order={order} />
          </div>
        </Card>
      </Spin>
    </div>
  );
};
OrderDetail.propTypes = {
  orderId: PropTypes.string,
  tabStatus: PropTypes.string,
  sendToQuiqup: PropTypes.func,
};
export default OrderDetail;
