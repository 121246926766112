import React, { createContext, useReducer } from "react";
import CatelogueReducer from "store/reducers/CatelogueReducer";

export const CatelougeContext = createContext({
  state: null,
  dispatch: null,
});
const Catelouge = (props) => {
  const [state, dispatch] = useReducer(CatelogueReducer, [], () => {
    return [];
  });

  return (
    <CatelougeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CatelougeContext.Provider>
  );
};

export default Catelouge;
