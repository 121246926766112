import React, { useCallback, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  DrawingManager,
  Polygon,
} from "@react-google-maps/api";
import { libraries } from "features/shipping_methods/helpers";
import { logErrorOnSentry } from "utils/Utils";

function MyMapComponent({
  setPolygons,
  plottedPolygons,
  operationType,
  drawingEnabled,
  setDrawingEnabled,
  mapRef,
}) {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(6);
  const [shape, setShape] = useState(null);
  const [center, setCenter] = useState({
    lat: 25.2048,
    lng: 55.2708,
  });
  const [address, setAddress] = useState("");
  const handleChange = (address) => setAddress(address);

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        mapRef?.current?.setCenter(
          new window.google.maps.LatLng(latLng.lat, latLng.lng)
        );
        setCenter(latLng);
        setZoom(18);
      })
      .catch((error) => console.error("Error", error));
  };

  const { isLoaded } = useJsApiLoader({
    id: "script-loader",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "en",
    region: "us",
    libraries: libraries,
  });

  const onPolygonComplete = (polygon) => {
    setDrawingEnabled(false);
    const paths = polygon
      ?.getPath()
      ?.getArray()
      ?.map((point) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));

    polygon?.setOptions({
      draggable: false,
      editable: true,
    });

    // Listen for polygon edits
    window.google.maps.event.addListener(polygon?.getPath(), "set_at", () => {
      handlePolygonEdit(polygon.getPath().getArray());
    });
    window.google.maps.event.addListener(
      polygon?.getPath(),
      "insert_at",
      () => {
        handlePolygonEdit(polygon.getPath().getArray());
      }
    );
    window.google.maps.event.addListener(
      polygon?.getPath(),
      "remove_at",
      () => {
        handlePolygonEdit(polygon?.getPath()?.getArray());
      }
    );

    setPolygons((prev) => ({
      ...prev,
      coordinates: paths,
    }));
    //center map on polygon
    const bounds = new window.google.maps.LatLngBounds();
    polygon?.getPath()?.forEach((element) => {
      bounds?.extend(element);
    });
    mapRef?.current?.fitBounds(bounds);
    setZoom(mapRef?.current?.getZoom());
  };

  const handlePolygonEdit = (updatedPaths) => {
    const paths = updatedPaths?.map((point) => ({
      lat: point.lat(),
      lng: point.lng(),
    }));
    setPolygons((prev) => ({
      ...prev,
      coordinates: paths,
    }));
  };

  const onUnmount = useCallback(() => {
    mapRef.current = null;
    listenersRef?.current?.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const handleOnLoad = (map) => (mapRef.current = map);

  const drawingManagerRef = useRef(null);

  const removeDrawing = () => {
    setDrawingEnabled(true);
    setPolygons({
      coordinates: [],
      areaInfo: {},
    });
    shape?.setMap(null);
  };
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef?.current) {
      const nextPath = polygonRef.current
        ?.getPath()
        ?.getArray()
        ?.map((latLng) => {
          return { lat: latLng?.lat(), lng: latLng?.lng() };
        });
      setPolygons((prev) => ({
        ...prev,
        coordinates: nextPath,
      }));
    }
  }, [setPolygons]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      try {
        if (polygon) polygonRef.current = polygon;

        const path = polygon?.getPath();

        if (path) {
          listenersRef?.current?.push(
            path.addListener("set_at", onEdit),
            path.addListener("insert_at", onEdit),
            path.addListener("remove_at", onEdit)
          );
        }
      } catch (error) {
        logErrorOnSentry("Error in onLoad", error);
      }
    },
    [onEdit]
  );

  const [infoWindows, setInfoWindows] = useState(null);

  const handlePolygonClick = (index) => setInfoWindows(index);

  return (
    <React.Fragment>
      {isLoaded ? (
        <div className="mb-2">
          <div className="">
            <div
              className=""
              style={{
                height: "50vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="px-2 md:px-6 py-2 w-full">
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  searchOptions={{
                    componentRestrictions: { country: ["ae"] },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <>
                        <div className="flex w-full mt-1 border border-grey-600 sm:block lg:w-4/6 border-gray-300 p-2">
                          <input
                            type="text"
                            className=" text-input-color w-inline-block w-10/12 focus: border-none focus:outline-none text-16px"
                            {...getInputProps({
                              placeholder: t("Enter Google Map Location"),
                            })}
                          />
                        </div>
                      </>

                      <div
                        className="autocomplete-dropdown-container"
                        style={{
                          position: "absolute",
                          zIndex: "1000",
                          width: "76.99%",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, key) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={key}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion?.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                <div className="mt-2">
                  {operationType === "add" && (
                    <div
                      style={{
                        background: `white`,
                        border: `1px solid #ccc`,
                        padding: 15,
                      }}
                    >
                      <button
                        className="bg-gray-200 border-2 outline-none border-gray-200 rounded-md px-2 py-1"
                        onClick={removeDrawing}
                      >
                        Clear Polygon
                      </button>
                    </div>
                  )}
                  <GoogleMap
                    key="google-map"
                    ref={mapRef}
                    mapContainerClassName="App-map"
                    zoom={zoom}
                    version="weekly"
                    mapContainerStyle={{
                      width: "100%",
                      height: "400px",
                    }}
                    options={{
                      gestureHandling: "greedy",
                      disableDefaultUI: true,
                      keyboardShortcuts: false,
                    }}
                    center={center}
                    onLoad={handleOnLoad}
                    onUnmount={onUnmount}
                  >
                    <DrawingManager
                      ref={drawingManagerRef}
                      onPolygonComplete={onPolygonComplete}
                      onOverlayComplete={(e) => {
                        setShape(e.overlay);
                      }}
                      options={{
                        drawingMode: drawingEnabled ? "polygon" : null,
                        drawingControl: drawingEnabled ? true : false,
                        drawingControlOptions: {
                          position:
                            window.google.maps.ControlPosition.LEFT_CENTER,
                          drawingModes: ["polygon"],
                        },
                        markerOptions: {
                          draggable: false,
                        },

                        polylineOptions: {
                          editable: true,
                          draggable: false,
                        },
                        rectangleOptions: {
                          editable: true,
                          draggable: false,
                        },
                        circleOptions: {
                          editable: true,
                          draggable: false,
                        },

                        polygonOptions: {
                          editable: true, // Set to true only in edit mode
                          draggable: false,
                          fillOpacity: 0.5,
                          strokeWeight: 2,
                          strokeColor: "#0000FF",
                          zIndex: 999,
                          paths:
                            plottedPolygons?.length > 0
                              ? plottedPolygons[0]
                              : [],
                        },
                      }}
                    />

                    <>
                      {plottedPolygons &&
                        plottedPolygons?.length >= 1 &&
                        plottedPolygons?.map((polygon, key) => (
                          <React.Fragment
                            key={polygon?.areaInfo?.mappedAreaId?._id}
                          >
                            <Polygon
                              paths={polygon?.coordinates}
                              editable={operationType === "edit" ? true : false}
                              draggable={false}
                              onMouseUp={onEdit}
                              onDragEnd={onEdit}
                              onLoad={onLoad}
                              onUnmount={onUnmount}
                              options={{
                                fillColor: "#FF0000",
                                fillOpacity: 0.5,
                                strokeColor: "#FF0000",
                                strokeOpacity: 1,
                                strokeWeight: 2,
                                zIndex: 1,
                                editable:
                                  operationType === "edit" ? true : false,
                              }}
                              onMouseOver={() => {
                                handlePolygonClick(key);
                              }}
                              onMouseOut={() => setInfoWindows(null)}
                            />
                            {infoWindows === key && (
                              <InfoWindow
                                position={{
                                  lat: polygon?.coordinates[0]?.lat,
                                  lng: polygon?.coordinates[0]?.lng,
                                }}
                                onCloseClick={() => setInfoWindows(null)}
                              >
                                <div>
                                  <h1>
                                    {polygon?.areaInfo?.translations?.en?.name}
                                  </h1>
                                </div>
                              </InfoWindow>
                            )}
                          </React.Fragment>
                        ))}
                    </>
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>loading</div>
      )}
    </React.Fragment>
  );
}

export default React.memo(MyMapComponent);
