import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { LoaderAction } from "redux/actions";
import { UTILS } from "utils";
import { logErrorOnSentry } from "utils/Utils";
import { useTrackLyveGlobalOrderMutation } from "./service";

function useLyveGlobalGetORderInfo() {
  const dispatch = useDispatch();
  const [lyveGlobalOrderInfo, setLyveGlobalOrderInfo] = useState(null);
  const [trackLyveGlobalOrder] = useTrackLyveGlobalOrderMutation();
  const getlyveGlobalOrderInfo = async (order) => {
    try {
      if (!order?._id) {
        logErrorOnSentry(
          "Invalid order number -- dz order status",
          "error",
          JSON.stringify(order)
        );
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const data = await trackLyveGlobalOrder({ orderId: order._id });
      if (data?.data?.success === 1 && data?.data?.data) {
        setLyveGlobalOrderInfo(data?.data?.data);
      } else {
        UTILS.errorNotification(errorHandler(data?.error));
        setLyveGlobalOrderInfo(null);
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return {
    lyveGlobalOrderInfo,
    getlyveGlobalOrderInfo,
    setLyveGlobalOrderInfo,
  };
}

export default useLyveGlobalGetORderInfo;
