import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { API } from "api";
import { CONSTANTS as C } from "config";
import PaymentTypes from "./StorePaymentMethodSection";
import WorkingHours from "./StoreHoursSection";
import RadioButton from "../../../components/RadioButton";
import PreOrderingWidgetForDelivery from "./PreOrderingWidgetForDelivery";
import OrderManagement from "./OrderManagement";
import DeliveryChargesWaveOff from "./DeliveryChargesWaveOff";
function Delivery({ store, addStore, type, setStore, handleStoreInfo }) {
  const [serviceTypes, setServiceTypes] = useState([]);
  const { t } = useTranslation();
  const getServiceTypes = async () => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_SERVICE_TYPES({
      token: userDetail.token,
    });
    if (result && result.success === 1) {
      setServiceTypes(result.data);
    } else {
    }
  };
  useEffect(() => {
    getServiceTypes();
  }, []);
  const toggleDelivery = (isDeliveryEnabled, key) => {
    if (isDeliveryEnabled) {
      setStore((store) => ({
        ...store,
        values: {
          ...store.values,
          [C.ISDELIVERYENABLED]: !isDeliveryEnabled,
          [C.KEY_SERVICE_TYPES]: store.values[C.KEY_SERVICE_TYPES].filter(
            (item) => item !== key
          ),
        },
      }));
    } else {
      setStore((store) => ({
        ...store,
        values: {
          ...store.values,
          [C.ISDELIVERYENABLED]: !isDeliveryEnabled,
          serviceType: [...store.values.serviceType, key],
        },
      }));
    }
  };
  const isON = (item) => {
    const arr = store.values[C.KEY_SERVICE_TYPES];
    const found = arr?.find((obj) => obj === item);
    return found ? true : false;
  };
  const ServiceType = () => {
    return (
      <div className="mb-5">
        <RadioButton
          label={t("Delivery")}
          value={isON(serviceTypes[0]?._id)}
          keyVal={C.KEY_SERVICE_TYPES + "_" + serviceTypes[0]?._id}
          handleValues={() => {
            toggleDelivery(
              store.values[C.ISDELIVERYENABLED],
              serviceTypes[0]?._id
            );
          }}
        />
      </div>
    );
  };
  const getValue = (key) => {
    if (store && store.values[key]) return store.values[key];
    else return "";
  };

  const handlePaymentTypes = (key, val) => {
    const v = key;
    let paymentArr = store.values[C.KEY_PAYMENT_TYPES];
    if (val) {
      paymentArr.push(v);
    } else {
      const index = paymentArr.indexOf(v);
      if (index > -1) paymentArr.splice(index, 1);
    }
    setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [C.KEY_PAYMENT_TYPES]: paymentArr,
      },
      errors: {
        ...prevState.errors,
      },
    }));
  };

  const handleTwentyFourHours = (key, val) => {
    setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
      },
    }));

    if (val) {
      setStore((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [key]: val,
          workingHours: {
            saturdayStartTime: C.START_TIME_24_HOURS,
            saturdayEndTime: C.END_TIME_24_HOURS,
            saturdayStartTimeSlot2: null,
            saturdayEndTimeSlot2: null,

            sundayStartTime: C.START_TIME_24_HOURS,
            sundayEndTime: C.END_TIME_24_HOURS,
            sundayStartTimeSlot2: null,
            sundayEndTimeSlot2: null,

            mondayStartTime: C.START_TIME_24_HOURS,
            mondayEndTime: C.END_TIME_24_HOURS,
            mondayStartTimeSlot2: null,
            mondayEndTimeSlot2: null,

            tuesdayStartTime: C.START_TIME_24_HOURS,
            tuesdayEndTime: C.END_TIME_24_HOURS,
            tuesdayStartTimeSlot2: null,
            tuesdayEndTimeSlot2: null,

            wednesdayStartTime: C.START_TIME_24_HOURS,
            wednesdayEndTime: C.END_TIME_24_HOURS,
            wednesdayStartTimeSlot2: null,
            wednesdayEndTimeSlot2: null,

            thursdayStartTime: C.START_TIME_24_HOURS,
            thursdayEndTime: C.END_TIME_24_HOURS,
            thursdayStartTimeSlot2: null,
            thursdayEndTimeSlot2: null,

            fridayStartTime: C.START_TIME_24_HOURS,
            fridayEndTime: C.END_TIME_24_HOURS,
            fridayStartTimeSlot2: null,
            fridayEndTimeSlot2: null,

            sunday: true,
            saturday: true,
            friday: true,
            thursday: true,
            wednesday: true,
            tuesday: true,
            monday: true,
          },
        },
        errors: {
          ...prevState.errors,
        },
      }));
    }
  };

  const handleQuickOrder = (key, val) => {
    setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
      },
    }));
  };
  return (
    <>
      <ServiceType />

      {isON(serviceTypes[0]?._id) && (
        <>
          <PaymentTypes
            serviceType="DELIVERY"
            paymentType={C.KEY_PAYMENT_TYPES}
            values={store.values}
            errors={store.errors.delivery}
            handleValues={handlePaymentTypes}
            addStore={addStore}
            setStore={setStore}
          />
          <OrderManagement
            averagePrepTime={{
              value: store?.values?.averagePrepTime,
              keyVal: "averagePrepTime",
            }}
            store={store}
            asap={{
              value: store?.values?.isAcceptQuickOrderForDelivery,
              keyVal: "isAcceptQuickOrderForDelivery",
            }}
            hndlepreOrderingState={handleQuickOrder}
            handleStoreInfo={handleStoreInfo}
            getValue={getValue}
          />
          <PreOrderingWidgetForDelivery store={store} setStore={setStore} />
          <DeliveryChargesWaveOff store={store} setStore={setStore} />
          <WorkingHours
            values={store.values}
            errors={store.errors.delivery}
            store={store}
            handleTwentyFourHours={handleTwentyFourHours}
            type={type}
            addStore={addStore}
            setStore={setStore}
          />
        </>
      )}
    </>
  );
}

export default Delivery;
