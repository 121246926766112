import React from "react";
import { useTranslation } from "react-i18next";
const ClaimInfo = (props) => {
  let { careemOrder } = props;
  const { t } = useTranslation();

  return (
    <div>
      <table className="border-collapse border border-slate-400 w-full mt-4">
        {careemOrder ? (
          <>
            <tr>
              <td className="border border-slate-300 p-4">{t("status")}</td>
              <td className="border border-slate-300 p-4">
                <div>{careemOrder?.status}</div>
              </td>
            </tr>

            {careemOrder?.tracking_url && (
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Tracking Url")}
                </td>
                <td className="border border-slate-300 p-4">
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={careemOrder?.tracking_url}
                      className="underline text-blue-500"
                    >
                      {careemOrder?.tracking_url}
                    </a>
                  </div>
                </td>
              </tr>
            )}
            {careemOrder?.captain && (
              <>
                <tr>
                  <td className="border border-slate-300 pl-4 border-b-0 ">
                    Captain
                  </td>
                  <td className="border border-slate-300 p-4">
                    <div>
                      <strong>Name:</strong>
                    </div>
                    <div>{careemOrder?.captain?.name}</div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="border border-slate-300 p-4">
                    <div>
                      <strong>Phone:</strong>
                    </div>
                    <div>{careemOrder?.captain?.phone_number}</div>
                  </td>
                </tr>
              </>
            )}
          </>
        ) : null}
      </table>
    </div>
  );
};

export default ClaimInfo;
