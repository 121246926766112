import React, { useState } from "react";
import ItemList from "../Components/ItemList";
import useAllStores from "hooks/useCurrentUserStores";
import { Spin } from "antd";
import useGetItemsByStoreId from "../useGetItemsByStoreId";
import usePauseItem from "../usePauseItem";
import PauseModalContainer from "../Components/PauseModal";
import { useTranslation } from "react-i18next";
const Inventory = () => {
  //local state
  const { t } = useTranslation();

  const [state, setState] = useState({
    currentStoreId: null,
    items: [],
    loading: false,
  });
  //custom hooks
  const { stores, loading } = useAllStores();

  const updateState = (key, val) =>
    setState((state) => ({ ...state, [key]: val }));
  const getItemsByStoreId = useGetItemsByStoreId(updateState);

  const handleChange = (key, val) => {
    if (val) {
      getItemsByStoreId(val);
      updateState("currentStoreId", val);
    }
  };

  const [PauseModel, setPauseModel] = useState({
    open: false,
    modifier: null,
    deafaultOption: {
      value: 6,
      unit: "hours",
      duration: "6_HOURS",
    },
  });

  const setPauseModalState = (key, val) => {
    setPauseModel((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const { playPauseItem, handlePlayPause } = usePauseItem({
    setPauseModalState,
    updateState,
    getItemsByStoreId,
    state,
  });

  const closeModal = () => {
    setPauseModalState("modifier", null);
    setPauseModalState("deafaultOption", {
      value: 6,
      unit: "hours",
      duration: "6_HOURS",
    });
    setPauseModalState("open", false);
  };
  const isLoading = state.loading || loading;
  return (
    <Spin spinning={isLoading}>
      <ItemList
        {...{
          handleChange,
          isLoading,
          stores,
          items: state?.items,
          state,
        }}
        handlePlayPause={handlePlayPause}
      />
      <PauseModalContainer
        heading={t("Pause item")}
        title1={PauseModel?.modifier?.baseLanguage?.title}
        title2="Select a time frame where item will be paused. After this time, it'll be automatically resumed"
        isLoading={isLoading}
        {...{ PauseModel, closeModal, setPauseModalState, playPauseItem }}
      />
    </Spin>
  );
};
export default Inventory;
