import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { errorNotification } from "utils/Utils";
import { errorHandler } from "api/api/errorHandler";
import { API } from "api";
import { calculateDeliveryTimeInMins } from "features/shipping_methods/helpers";
import { LoaderAction } from "redux/actions";
import useGetAllZones from "../hooks/useGetAllZones";
import { successNotification } from "utils/Utils";
import ZoneForm from "../components/ZoneForm";
const AddZone = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getAddedDeliveryZones } = useGetAllZones();

  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const [selectedAreaState, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    distance: "",
    name: "",
    deliveryTime: {
      Hours: 0,
      Minutes: 0,
      Days: 0,
    },
  });

  const handleValues = async (key, value) =>
    setState((prevState) => ({ ...prevState, [key]: value }));

  const handleInputValue = (key, val) =>
    handleValues(key, UTILS.NumberConversion(val));
  const resetState = () => {
    setState((prevState) => ({
      ...prevState,

      deliveryTime: {
        Hours: 0,
        Minutes: 0,
        Days: 0,
      },
    }));
  };

  const validateForm = () => {
    let isValid = true;
    if (!selectedAreaState?.name) {
      errorNotification(t("Zone Name is required"));
      isValid = false;
    }
    if (!selectedAreaState?.distance) {
      errorNotification(t("Distance is required"));
      isValid = false;
    }
    if (selectedAreaState?.distance < 0) {
      errorNotification(t("Distance should be greater than 0"));
      isValid = false;
    }

    if (!selectedAreaState?.minOrder) {
      errorNotification(t("Min. Basket Value is required"));
      isValid = false;
    }
    if (!selectedAreaState?.deliveryFee) {
      errorNotification(t("Delivery Fee is required"));
      isValid = false;
    }
    if (
      !selectedAreaState?.deliveryTime?.Days &&
      !selectedAreaState?.deliveryTime?.Hours &&
      !selectedAreaState?.deliveryTime?.Minutes
    ) {
      errorNotification(t("Delivery Time is required"));
      isValid = false;
    }
    return isValid;
  };

  const addZone = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      dispatch(LoaderAction.setLoading(true));

      const userDetail = UTILS.getCurrentUser();
      const deliveryTime = calculateDeliveryTimeInMins({
        deliveryTime: selectedAreaState.deliveryTime,
      });
      const payload = {
        brandId: userDetail.brandId,
        storeId: selectedStore.value,
        name: selectedAreaState?.name,
        distance: +selectedAreaState?.distance,
        deliveryFee: +selectedAreaState?.deliveryFee,
        deliveryTime: +deliveryTime,
        minOrder: +selectedAreaState?.minOrder,
      };
      const response = await API.addDistanceBaseDeliveryZone(payload);
      if (response?.success === 1 && response?.data) {
        successNotification(response?.message);
        await getAddedDeliveryZones(selectedStore?.value);
        onClose();
      } else {
        errorNotification(errorHandler(response?.message));
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return (
    <ZoneForm
      {...{
        onClose,
        selectedAreaState,
        handleValues,
        handleInputValue,
        handleSubmit: addZone,
        resetState,
      }}
      heading={t("Add Zone")}
    />
  );
};

AddZone.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddZone;
