import { API } from "api";
import useSWR from "swr";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";

const useGetOrderMetaData = () => {
  const token = UTILS.getCurrentUser()?.token;

  const url = C.BASE_URL + "order/get-order-meta-data-info";

  const fetcher = async () => {
    try {
      const response = await API.getOrderMetaData();
      if (response?.success === 1 && response?.data) return response.data;
    } catch (error) {
      return error;
    }
  };

  const { data, error, mutate } = useSWR(token ? url : null, fetcher, {
    refreshInterval: 300000,
    revalidateOnFocus: false,
    refreshWhenHidden: true, // Allow polling even if tab is hidden
    refreshWhenOffline: true, // Allow polling even if the user is offline
    revalidateOnMount: true, // Always revalidate when the hook is mounted
    revalidateOnReconnect: true, // Revalidate when the user is back online
    revalidateOnWindowFocus: true,
  });

  return {
    data: data,
    error: error,
    revalidate: mutate,
  };
};

export default useGetOrderMetaData;
