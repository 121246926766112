import Orders from "features/reporting/containers/Orders";
import PaymentLinks from "features/reporting/containers/PaymentLinks";
import UserFeedbacks from "features/reporting/containers/UserFeedbacks";
import CustomersReport from "features/reporting/containers/CustomersReport";
const routes = [
  {
    path: "/app/dashboard/reporting/user-feedbacks",
    component: UserFeedbacks,
  },
  {
    path: "/app/dashboard/reporting/orders",
    component: Orders,
  },
  {
    path: "/app/dashboard/reporting/customers",
    component: CustomersReport,
  },
  {
    path: "/app/dashboard/reporting/payments",
    component: PaymentLinks,
  },
  // {
  //   path: "/app/dashboard/reporting/payouts",
  //   component: Payouts,
  // },
];

export default routes;
