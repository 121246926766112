import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoggedIn: false,
};

const LoginReducer = createSlice({
  name: "LoginReducer",
  initialState,
  reducers: {
    toogleLogin: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { toogleLogin } = LoginReducer.actions;
export default LoginReducer.reducer;
