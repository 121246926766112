import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Button, Spin } from "antd";
import moment from "moment";

import Card from "components/Card";
import TableItemsDetail from "../components/TableItemsDetail";
import LogisticPartnerModalsContainer from "./logistic-partner-order-info-modals/index";

import PaymentInfo from "features/order-payment-info/Index";
import Header from "./OrderDetail/components/Header";
import RefundOrderInfo from "./stripeRefund/RefundOrderInfo";

import { CONSTANTS as C } from "config";
import { LogisticAction } from "redux/actions";
import { UTILS } from "utils";

import { useGetOrderInfoByOrderIdMutation } from "redux/orders/orderService";
import useGetORderInfo from "logistic-integrations/delivery-zone/useGetORderInfo";
import useLyveGlobalGetORderInfo from "logistic-integrations/lyve-global/useLyveGlobalGetORderInfo";
import { getOrderIsRefundable } from "utils/Order";

const ShowOrderDetail = ({
  rowData,
  setOpen,
  loading,
  setLoading,
  showRefundModal,
}) => {
  const componentRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [courierModal, setCourierModal] = useState({
    TALABAT: false,
    YANDEX: false,
    CAREEM_NOW: false,
    DELIVERY_ZONE: false,
    LYVE_GLOBAL: false,
  });
  const [orderInfo, setOrderInfo] = useState({});
  const [careemOrder, setCareemOrder] = useState(null);
  const [getOrderInfoByOrderId] = useGetOrderInfoByOrderIdMutation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getDeliveryAddress = () => {
    let add = "";
    add += rowData?.deliveryAddress?.appartmentNo
      ? "Apt# " + rowData?.deliveryAddress?.appartmentNo
      : "";
    add += rowData?.deliveryAddress?.floor
      ? ", floor: " + rowData?.deliveryAddress?.floor
      : "";
    add += rowData?.deliveryAddress?.building
      ? ", building " + rowData?.deliveryAddress?.building
      : "";
    add += rowData.deliveryAddress ? rowData?.deliveryAddress?.address : "....";
    return add;
  };

  const fetchOrderInfo = async (modalTYpe) => {
    try {
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const { data } = await getOrderInfoByOrderId({
        brandId: userDetail.brandId,
        token: userDetail.token,
        orderId: rowData._id,
      });
      if (data?.success === 1) {
        setOrderInfo(data.data.fetchedOrder);
        dispatch(LogisticAction.setOrderInfo(data.data.fetchedOrder));
        setCourierModal({
          ...courierModal,
          [modalTYpe]: true,
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const { getDZOrderStatus, DZOrder } = useGetORderInfo();
  const { lyveGlobalOrderInfo, getlyveGlobalOrderInfo } =
    useLyveGlobalGetORderInfo();
  const [paymentGatewayInfoModal, setPaymentGatewayInfoModal] = useState(false);
  return (
    <div className="w-full p-5 mx-auto my-auto rounded-xl bg-white">
      <div className="">
        <Spin spinning={loading}>
          <div
            className="cursor-pointer mb-3"
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <Header
            {...{ rowData, handlePrint, showRefundModal }}
            getOrderIsRefundable={getOrderIsRefundable}
          />
          <Card>
            <div
              className="py-5 min-h-300 bb"
              id={"orderForPrint"}
              ref={componentRef}
            >
              <div className="flex flex-wrap px-3 sm:px-7 py-3">
                {rowData?.userInfo?.name && (
                  <div className="w-1/2 py-3">
                    <div className="text-gray-500">{t("Customer name")}</div>

                    <div className="font-semibold ">
                      {rowData.userInfo?.name}
                    </div>
                  </div>
                )}
                <div className="w-1/2 py-3 hidden-print">
                  <div className="text-gray-500">{t("Preparation Type")}</div>

                  <div className="font-semibold blink text-red-600">
                    {rowData.isPreOrder ? t("PRE ORDER") : t("ASAP")}
                  </div>
                </div>
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Order Type")}</div>

                  <div className="font-semibold">
                    {rowData?.orderType
                      ? currentLanguage === "EN"
                        ? rowData.orderType.EN.name
                        : rowData.orderType.AR.name
                      : "..."}
                  </div>
                </div>
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Store")}</div>

                  <div className="font-semibold">
                    {rowData.storeInfo
                      ? rowData?.storeInfo?.baseLanguage?.name
                      : "..."}
                  </div>
                </div>

                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Order Placed Time")}</div>
                  <div className="font-semibold">
                    {rowData.orderPlacedTime
                      ? moment
                          .unix(rowData.orderPlacedTime)
                          .format("DD-MM-Y hh:mm A")
                      : "..."}
                  </div>
                </div>
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Order Id")}</div>

                  <div className="font-semibold">
                    {rowData?.orderNumber ? rowData?.orderNumber : "..."}
                  </div>
                </div>

                {C.logisticPartnerNames.includes(rowData?.logisticPartner) ? (
                  <div className="w-1/2 py-3 hidden-print">
                    <div className="text-gray-500">{t("Logistic Partner")}</div>
                    <div className="font-semibold mt-2">
                      <Button
                        className=" bg-blue-500 text-white rounded-sm"
                        type="primary"
                        onClick={() => {
                          fetchOrderInfo(rowData?.logisticPartner);
                          if (rowData?.logisticPartner === "DELIVERY_ZONE") {
                            getDZOrderStatus(rowData);
                          }
                          if (rowData?.logisticPartner === "LYVE_GLOBAL") {
                            getlyveGlobalOrderInfo(rowData);
                          }
                        }}
                      >
                        {t(rowData?.logisticPartner)}
                      </Button>
                    </div>
                  </div>
                ) : null}
                <LogisticPartnerModalsContainer
                  {...{
                    courierModal,
                    setCourierModal,
                    rowData,
                    getDZOrderStatus,
                    DZOrder,
                    getlyveGlobalOrderInfo,
                    lyveGlobalOrderInfo,
                    careemOrder,
                    setCareemOrder,
                    orderInfo,
                  }}
                />

                {rowData?.orderType?.type === "DELIVERY" ? (
                  <div className="w-full py-3 sm:w-1/2">
                    <div className="text-gray-500">{t("Location")} </div>
                    <div className="font-semibold text-blue-600 cursor-pointer">
                      {rowData?.deliveryAddress?.street
                        ? rowData?.deliveryAddress?.street
                        : ""}
                    </div>
                  </div>
                ) : null}

                <RefundOrderInfo rowData={rowData} />

                <div className="w-full sm:w-1/2  py-3 mt-4 sm:mt-0">
                  <div className="text-gray-500">{t("Whatsapp Number")}</div>
                  <div className="font-semibold">
                    <span dir="ltr">
                      {rowData?.userInfo
                        ? rowData?.userInfo?.phoneNumber
                        : "...."}
                    </span>
                  </div>
                </div>
                {rowData?.orderType?.type === C.SERVICE_TYPES.DINEIN ? (
                  <div className="w-full sm:w-1/2  py-3 mt-4 sm:mt-0">
                    <div className="text-gray-500">{t("Selected Table")}</div>
                    <div className="font-semibold">
                      <span dir="ltr" className="text-red-600">
                        {rowData?.dineIn?.tableNumber
                          ? rowData?.dineIn?.tableNumber
                          : "...."}
                      </span>
                    </div>
                  </div>
                ) : null}
                {rowData?.orderType?.type === C.SERVICE_TYPES.PICKUP &&
                  rowData?.pickup?.vehicleInfo && (
                    <div className="w-1/2 py-3">
                      <div className="text-gray-500">{t("Vehicle Info")}</div>
                      <div className="font-semibold">
                        <span dir="ltr" className="text-red-600">
                          {rowData?.pickup?.vehicleInfo
                            ? rowData?.pickup?.vehicleInfo
                            : "...."}
                        </span>
                      </div>
                    </div>
                  )}

                <div className="w-1/2 py-3">
                  <PaymentInfo
                    orderInfo={rowData}
                    {...{ paymentGatewayInfoModal, setPaymentGatewayInfoModal }}
                  >
                    <div className="text-gray-500">{t("Payment")}</div>

                    <div
                      className={`font-semibold  pe-1 ${
                        rowData?.paymentType === C.PAYMENT_TYPE_ONLINE &&
                        UTILS.getORderPaymentGateway(rowData) ===
                          C.PAYMENT_GATWAYS.STRIPE
                          ? "cursor-pointer underline text-blue-600 hover:text-blue-800 mb-1"
                          : ""
                      }`}
                    >
                      {rowData.selectedPaymentMethod
                        ? currentLanguage === "EN"
                          ? rowData?.selectedPaymentMethod?.EN?.name
                          : rowData?.selectedPaymentMethod?.AR?.name
                        : "..."}
                    </div>
                  </PaymentInfo>
                </div>
                <div className="w-1/2 py-3">
                  <div className="text-gray-500">{t("Order From")}</div>
                  <div className="font-semibold uppercase text-red-600">
                    {rowData?.orderingPlatform
                      ? rowData?.orderingPlatform
                      : "...."}
                  </div>
                </div>

                {rowData?.orderType?.type === "DELIVERY" ? (
                  <div className="w-full py-3 sm:w-1/2">
                    <div className="text-gray-500">{t("Deliver To")}</div>
                    <div className="font-semibold">{getDeliveryAddress()}</div>
                  </div>
                ) : null}

                {rowData?.orderLevelSpecialRequest?.trim()?.length >= 1 ? (
                  <>
                    <div className="w-full py-3 lg:w-1/2 xl:w-2/3">
                      <div className="text-gray-500">{t("Special Note")} </div>
                      <div className="font-semibold  cursor-pointer">
                        {rowData?.orderLevelSpecialRequest
                          ? rowData?.orderLevelSpecialRequest
                          : ""}
                      </div>
                    </div>
                  </>
                ) : null}
                {rowData && rowData?.discountApplied && (
                  <>
                    <div className="w-1/2 py-3">
                      <div className="text-gray-500">
                        {t("Discount Amount")}
                      </div>
                      <div className="font-semibold">
                        {rowData?.discountApplied
                          ? rowData?.discountApplied?.discountValue
                          : ""}
                      </div>
                    </div>
                    <div className="w-1/2 py-3">
                      <div className="text-gray-500">{t("Discount Info")}</div>
                      <div className="font-semibold">
                        Type (
                        {rowData?.discountApplied
                          ? rowData?.discountApplied?.discountType
                          : ""}
                        ) - Code (
                        {rowData?.discountApplied
                          ? rowData?.discountApplied?.discountCode
                          : ""}
                        )
                      </div>
                    </div>
                  </>
                )}

                {rowData?.userInfo?.lifetimeSpending ? (
                  <>
                    <div className="w-1/2 py-3 hidden-print">
                      <div className="text-gray-500">
                        {t("LifetimeSpending")}
                      </div>
                      <div className="font-semibold text-green-600">
                        <span className="text-green-600">AED </span>
                        {parseFloat(
                          rowData?.userInfo?.lifetimeSpending
                        )?.toFixed(2)}
                      </div>
                    </div>
                  </>
                ) : null}
                {rowData?.isPreOrder ? (
                  <div className="w-1/2 py-3 hidden-print">
                    <div className="text-gray-500">
                      {rowData?.orderType?.type === C.SERVICE_TYPES.DELIVERY
                        ? t("Pre Order Delivery Slot")
                        : t("Pre Order Pickup Slot")}
                    </div>
                    <div className="font-bold">
                      <div>
                        Date :{" "}
                        {moment
                          .unix(rowData?.preOrder?.preOrderSlotStartTime)
                          .format("DD-MM-YYYY")}
                      </div>
                      Slot :{" "}
                      {moment
                        .unix(rowData?.preOrder?.preOrderSlotStartTime)
                        .format("hh:mm A")}{" "}
                      -{" "}
                      {moment
                        .unix(rowData?.preOrder?.preOrderSlotEndTime)
                        .format("hh:mm A")}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="flex px-3 sm:px-7 py-3 hidden-print">
                {(rowData?.declineOrderReason &&
                  rowData?.orderStatus === C.orderStatus.REJECTED) ||
                (rowData?.orderCancelReason &&
                  rowData?.orderStatus === C.orderStatus.CANCELLED) ? (
                  <div className="w-1/2">
                    <div className="text-gray-500">
                      {rowData?.orderStatus === C.orderStatus.REJECTED
                        ? t("Reject Reason")
                        : t("Cancel Reason")}
                    </div>
                    <div className="font-semibold pe-1 truncate-line-3">
                      {rowData?.orderStatus === C.orderStatus.REJECTED
                        ? rowData?.declineOrderReason
                        : rowData?.orderCancelReason}
                    </div>
                  </div>
                ) : null}
              </div>

              <TableItemsDetail order={rowData} />
            </div>
          </Card>
        </Spin>
      </div>
    </div>
  );
};

export default ShowOrderDetail;
