import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UTILS } from "utils";
const BubbleTopMessage = () => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  return (
    <div className="flex justify-center md:justify-start pt-2 pb-4 md:pb-0">
      <div className="w-1/2 my-1">
        <div className="mx-auto rounded-lg shadow p-2 bg-blue-100">
          <p className="text-12px text-gray-500">
            {t("Ali from RestHero", {
              company:
                currentLanguage === "EN"
                  ? process.env.REACT_APP_COMPANY_NAME_EN
                  : process.env.REACT_APP_COMPANY_NAME_AR,
              owner:
                currentLanguage === "EN"
                  ? process.env.REACT_APP_OWNER_NAME_EN
                  : process.env.REACT_APP_OWNER_NAME_AR,
            })}
          </p>
          <h2 className="text-blue-800 leading-normal text-16px font-bold">
            {t("Hi")} {UTILS.getCurrentUser().name}!{" "}
            {t("Bubble_message", {
              company:
                currentLanguage === "EN"
                  ? process.env.REACT_APP_COMPANY_NAME_EN
                  : process.env.REACT_APP_COMPANY_NAME_AR,
              owner:
                currentLanguage === "EN"
                  ? process.env.REACT_APP_OWNER_NAME_EN
                  : process.env.REACT_APP_OWNER_NAME_AR,
            })}
          </h2>
        </div>
      </div>
      <div className="">
        <img src="/images/character.png" className="h-44" />
      </div>
    </div>
  );
};
export default BubbleTopMessage;
