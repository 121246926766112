import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import { CONSTANTS as C } from "config";
import { errorNotification } from "utils/Utils";

import {
  GoogleMap as MapGoogle,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import { libraries } from "features/shipping_methods/helpers";
const MyMap = (props) => {
  const mapref = useRef(null);
  const { t } = useTranslation();

  const handleChange = (address) => props.handleValues("mapAddress", address);

  const handleSelect = (address) => {
    props.handleValues("mapAddress", address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        props.handleValues("latlng", latLng);
        updateZoom(21);
      })
      .catch((error) => console.error("Error", error));
  };

  const onIdle = () => {
    if (mapref?.current) {
      const lat = mapref.current.center.lat();
      const lng = mapref.current.center.lng();
      const mapCenter = {
        lat: lat,
        lng: lng,
      };

      updateStreetAddress(mapCenter);
      props.handleValues("latlng", mapCenter);
    }
  };
  function locateMe() {
    props.setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, errorFn);
    } else {
      props.setLoading(false);
      errorNotification("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    props.setLoading(false);
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    const latLng = {
      lat,
      lng,
    };
    props.handleValues("latlng", latLng);
    updateStreetAddress(latLng);
    updateZoom(21);
  }

  function errorFn(error) {
    props.setLoading(false);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorNotification(
          "User denied the request for Geolocation.Please Enable location from settings"
        );
        break;
      case error.POSITION_UNAVAILABLE:
        errorNotification("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        errorNotification("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        errorNotification(
          "An unknown error occurred while accessing current location."
        );
        break;
      default:
        errorNotification(
          "An unknown error occurred while accessing current location."
        );
        break;
    }
  }

  const updateStreetAddress = (mapCenter) => {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: mapCenter }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          props.handleValues("mapAddress", results[0].formatted_address);
        }
      }
    });
  };

  const updateZoom = (z) => {
    props.setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [C.ZOOM]: z,
      },
      errors: {
        ...prevState.errors,
        [C.ZOOM]: "",
      },
    }));
  };
  const changezoom = () => {
    if (mapref?.current?.map && props.state) {
      const latlng = {
        lat: mapref?.current?.map.center.lat(),
        lng: mapref?.current?.map.center.lng(),
      };
      let l = mapref.current.map.getCenter();
      let no = {
        lat: l.lat(),
        lng: l.lng(),
      };

      let z = mapref.current.map.zoom;
      updateZoom(z);
    }
  };
  const handleOnLoad = (map) => (mapref.current = map);

  const onUnmount = useCallback(() => {
    console.log("unmount");
    mapref.current = null;
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "script-loader",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "en",
    region: "us",
    libraries: libraries,
  });

  return (
    <>
      {isLoaded ? (
        <div>
          <div className="">
            <div
              className=""
              style={{
                height: "50vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="px-2 md:px-6 py-2 w-full">
                <PlacesAutocomplete
                  value={props?.state?.values?.mapAddress}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  searchOptions={{
                    componentRestrictions: { country: ["ae"] },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <>
                        <div className="flex w-full mt-1 border border-grey-600 block w-4/6 border-gray-300 p-2">
                          <input
                            type="text"
                            className=" text-input-color w-inline-block w-10/12 focus: border-none focus:outline-none text-16px"
                            {...getInputProps({
                              placeholder: t("Enter Google Map Location"),
                            })}
                          />
                          <div className="setmap w-2/12 flex items-center justify-end inline-block  pe-2">
                            <svg
                              onClick={locateMe}
                              viewBox="0 0 22 22"
                              fill="none"
                              className="cursor-pointer w-4 h-4 text-brand-base-primary"
                            >
                              <path
                                d="M8.333 11a2.667 2.667 0 105.333 0 2.667 2.667 0 00-5.333 0z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M4.333 11a6.667 6.667 0 1013.334 0 6.667 6.667 0 00-13.334 0v0zM11 1v3.333M1 11h3.333M11 21v-3.333M21 11h-3.333"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </>

                      <div
                        className="autocomplete-dropdown-container"
                        style={{
                          position: "absolute",
                          zIndex: "1000",
                          width: "76.99%",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, key) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={key}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion?.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                <div className="mt-2">
                  <MapGoogle
                    // onReady={onReady}
                    // gestureHandling="greedy"
                    // ref={mapref}
                    // onDragend={onIdle}
                    // onZoomChanged={changezoom}
                    // zoom={
                    //   props?.state?.values?.zoom
                    //     ? props?.state?.values?.zoom
                    //     : props.zoom
                    //     ? props.zoom
                    //     : 8
                    // }
                    // google={props?.google ? props?.google : null}
                    // style={{
                    //   height: "100%",
                    //   width: "95%",
                    // }}
                    // initialCenter={{
                    //   lat: props?.state?.values?.storeLocation?.coordinates?.[0]
                    //     ? props?.state?.values?.storeLocation?.coordinates[0]
                    //     : 24,
                    //   lng: props?.state?.values?.storeLocation?.coordinates?.[1]
                    //     ? props?.state?.values?.storeLocation?.coordinates[1]
                    //     : 53,
                    // }}
                    // center={{
                    //   lat: props?.state?.values?.storeLocation?.coordinates?.[0]
                    //     ? props?.state?.values?.storeLocation?.coordinates?.[0]
                    //     : 24,
                    //   lng: props?.state?.values?.storeLocation?.coordinates?.[1]
                    //     ? props?.state?.values?.storeLocation?.coordinates?.[1]
                    //     : 53,
                    // }}

                    key="google-map"
                    id="myMap"
                    ref={mapref}
                    onDragEnd={onIdle}
                    onZoomChanged={changezoom}
                    mapContainerClassName="App-map"
                    zoom={
                      props?.state?.values?.zoom
                        ? props?.state?.values?.zoom
                        : props.zoom
                        ? props.zoom
                        : 8
                    }
                    version="weekly"
                    mapContainerStyle={{
                      height: "50vh",
                      width: "100%",
                    }}
                    options={{
                      gestureHandling: "greedy",
                      disableDefaultUI: true,
                      keyboardShortcuts: false,
                    }}
                    center={{
                      lat: props?.state?.values?.storeLocation?.coordinates?.[0]
                        ? props?.state?.values?.storeLocation?.coordinates?.[0]
                        : 24,
                      lng: props?.state?.values?.storeLocation?.coordinates?.[1]
                        ? props?.state?.values?.storeLocation?.coordinates?.[1]
                        : 53,
                    }}
                    onLoad={handleOnLoad}
                    onUnmount={onUnmount}
                  >
                    <Marker
                      position={{
                        lat: props?.state?.values?.storeLocation
                          ?.coordinates?.[0]
                          ? props?.state?.values?.storeLocation?.coordinates[0]
                          : 24,
                        lng: props?.state?.values?.storeLocation
                          ?.coordinates?.[1]
                          ? props?.state?.values?.storeLocation?.coordinates[1]
                          : 53,
                      }}
                    />
                  </MapGoogle>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>loading...</div>
      )}
    </>
  );
};

MyMap.propTypes = {
  google: PropTypes.number,
  key: PropTypes.string,
  handleValues: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  state: PropTypes.object,
  store: PropTypes.object,
};

export default MyMap;
