import React from "react";
import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PlusIcon from "features/icons/PlusIcon";
import { CONSTANTS as C } from "config";

function ButtonsDropDown({ addArea, userType, openDistanceModal }) {
  const { t } = useTranslation();
  const history = useHistory();
  const viewOptions = [
    {
      label: <span onClick={() => addArea()}>{t("Add delivery areas")}</span>,
      key: "add",
    },
    {
      label: (
        <span onClick={() => openDistanceModal()}>
          {t("Add distance zone")}
        </span>
      ),
      value: "zones",
    },
  ];
  return (
    <div className="flex gap-2 w-full sm:w-auto">
      <div className="flex w-full flex-col sm:flex-row gap-4 ">
        <Select
          value={t("Add delivery areas")}
          size="large"
          className="w-full sm:w-40  rounded min-w-200"
          placeholder={t("Select store")}
          optionFilterProp="children"
          onChange={() => {}}
        >
          {viewOptions.map((store) => (
            <Select.Option value={store.value} key={store.value}>
              {store.label}
            </Select.Option>
          ))}
        </Select>

        <Button
          disabled={userType === C.USER_TYPE_MANAGER}
          type="primary"
          onClick={() => history.push("/app/dashboard/store-map-areas-listing")}
          className="font-montserrat px-5 border border-transparent shadow-sm  font-medium rounded-sm text-white h-10 focus:outline-none"
        >
          <span className="align-middle">
            <PlusIcon />
          </span>
          <span className="px-1 align-middle">{t("View Areas On Map")}</span>
        </Button>
      </div>
    </div>
  );
}

export default ButtonsDropDown;
