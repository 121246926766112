import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { Button, Input, Spin } from "antd";
import {
  useUpdateBrandNameMutation,
  useUpdateDomainNameMutation,
  useGetSettingsMutation,
} from "redux/settings/settingsSlice";
import Modal from "components/Modal";
const GeneralSettings = () => {
  const { t } = useTranslation();
  const [orderPageLink, setOrderPageLink] = useState();
  const [Error, setError] = useState();
  const [open, setOpen] = useState(false);
  const { settings } = useSelector((state) => state?.SettingsReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const [getSettings] = useGetSettingsMutation();
  const [updateBrandName] = useUpdateBrandNameMutation();
  const { isError, domainError, brandError } = useSelector(
    (state) => state?.SettingErrorReducer
  );
  const [updateDomainName, { reset }] = useUpdateDomainNameMutation();
  const [state, setState] = useState({
    brandName: "",
    domainName: "",
  });

  const updateState = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    //clear error if input is empty
    if (!value) {
      setError(null);
    }
  };
  const updateMyBrand = async () => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
      brandName: state.brandName,
    };
    const { data } = await updateBrandName(obj);

    if (data?.success === 1) {
      await getSettings(obj);
      UTILS.saveCurrentUser({
        ...userDetail,
        brandName: state.brandName,
      });
      setState((prevState) => ({
        ...prevState,
        brandName: "",
      }));
    }
  };
  const updateMyDomain = async () => {
    setOpen(true);
  };
  useEffect(() => {
    const userDetail = UTILS.getCurrentUser();
    const text = userDetail.storeFrontURL + userDetail?.temporaryDomainName;
    setOrderPageLink(text);
  }, [state.domainName]);

  const pressYes = async () => {
    reset();
    setError(null);
    let isValid = UTILS.isDomainNameValid(state.domainName);
    setOpen(true);
    if (!isValid) {
      setError(t("Please enter a valid domain name"));
      setOpen(false);
      return;
    }
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
      temporaryDomainName: state.domainName,
    };
    const { data } = await updateDomainName(obj);
    if (data?.success === 1) {
      await getSettings(obj);
      UTILS.saveCurrentUser({
        ...userDetail,
        temporaryDomainName: state.domainName,
      });

      setState((prevState) => ({
        ...prevState,
        domainName: "",
      }));
    }
    setOpen(false);
  };
  const pressNo = () => {
    setOpen(false);
  };
  const DomainUpdateModal = () => {
    return (
      <Spin spinning={isLoading}>
        <div className="p-4 py-16 text-center">
          <h3 className="font-medium text-18px mb-8">
            {t("If you change your domain name")}
          </h3>
          <h3 className="font-medium text-18px mb-8">
            {t("Do not change the domain name")}
          </h3>
          <div>
            <button
              onClick={pressYes}
              className="px-12 py-2 me-2 my-2  text-black rounded border  border-red-500"
            >
              {t("Update domain name")}
            </button>
            <button
              onClick={pressNo}
              className="px-12 py-2 me-2 my-2 text-black rounded border border-green-500 "
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Spin>
    );
  };
  return (
    <div className="w-full md:w-1/2 ">
      <div className="pb-2 w-full">
        {(isError || brandError) && (
          <div className="text-red-600">{brandError}</div>
        )}
        <div className="flex justify-between">
          <label className="text-gray-600 font-bold text-14px">
            {t("Business name")}
          </label>
        </div>
        <div className="flex justify-between">
          <Input
            placeholder={settings?.browserTabTitle}
            compact
            name="brandName"
            value={state?.brandName}
            className="py-2 rounded"
            onChange={(e) => {
              updateState(e);
            }}
          />
          <Button
            disabled={!state?.brandName}
            onClick={updateMyBrand}
            size="large"
            className="mx-2 text-white border-blue-500 rounded bg-blue-500"
          >
            {t("Update")}
          </Button>
        </div>
      </div>

      <div className="pb-2 w-full mt-4">
        {(isError || Error || domainError) && (
          <div className="text-red-600 py-3">{domainError || Error}</div>
        )}
        <Modal
          open={open}
          modalWidth="rounded-md border border-gray-300 top-0 w-full md:w-1/2"
          onClose={() => setOpen(false)}
        >
          <DomainUpdateModal />
        </Modal>

        <div className="flex justify-between">
          <label className="text-gray-600 font-bold text-14px">
            {t("Domain name")}
          </label>
        </div>
        <div className="flex justify-between">
          <Input
            placeholder={settings?.temporaryDomainName}
            name="domainName"
            compact
            value={state?.domainName}
            className="py-2 rounded border-red-500"
            onChange={(e) => {
              updateState(e);
            }}
          />
          <Button
            disabled={!state?.domainName}
            onClick={updateMyDomain}
            size="large"
            className="mx-2 text-white border-blue-500 rounded bg-blue-500"
          >
            {t("Update")}
          </Button>
        </div>
        <div className="text-gray-400 text-12px mt-1">
          <span className="text-red-500 font-semibold">{t("Note:")}</span>
          <span>
            {t("Lower case alphabets, numeric and '-' are allowed only")}
          </span>
        </div>
      </div>
      <div className="pb-2 w-full mt-4">
        <div className="flex justify-between">
          <label className="text-gray-600 font-bold text-14px">
            {t("Ordering page link")}
          </label>
        </div>
        <div className="flex justify-between items-center">
          <Input
            disabled
            compact
            placeholder={orderPageLink}
            value=""
            className="py-2 rounded"
          />
          <span
            className="cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(orderPageLink);
              UTILS.successNotification(t("Copied to Clipboard"));
            }}
          >
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="https://www.w3.org/2000/svg"
              className="w-5 h-5 text-gray-500 mx-2"
            >
              <path
                fillRule="evenodd"
                d="M16 5.5H6a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5zM6 4a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V6a2 2 0 00-2-2H6z"
                clip-rule="evenodd"
              ></path>
              <path
                fillRule="evenodd"
                d="M1.25 4A2.75 2.75 0 014 1.25h6a.75.75 0 010 1.5H4c-.69 0-1.25.56-1.25 1.25v6a.75.75 0 01-1.5 0V4z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
