import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
const CheckDeliveryFee = ({ order, getOrderInfoById }) => {
  const { t } = useTranslation();
  return (
    <div>
      {order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse && (
        <table className="border-collapse border border-slate-400 w-full">
          <tr>
            <th className="border border-slate-300 p-4">{t("Order")} #</th>
            <td className="border border-slate-300 p-4">
              {
                order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse
                  ?.order_id
              }
            </td>
          </tr>
          {order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse && (
            <tr>
              <th className="border border-slate-300 p-4">
                {t("Client Order Id")}
              </th>
              <td className="border border-slate-300 p-4">
                {`${order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse?.client_order_id}`}
              </td>
            </tr>
          )}
          {order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse && (
            <tr>
              <th className="border border-slate-300 p-4">{t("Status")}</th>
              <td className="border border-slate-300 p-4">
                {
                  order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse
                    ?.status
                }
              </td>
            </tr>
          )}

          <>
            <tr>
              <th className="border border-slate-300 p-4" rowSpan={2}>
                {t("Driver")}
              </th>
              <td className="border border-slate-300 p-4">
                <td>
                  {t("Name")}:{" "}
                  {
                    order?.logisticPartnerMetadata?.TALABAT
                      ?.orderCallbackResponse?.driver?.name
                  }
                </td>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4">
                {t("Phone")}:{" "}
                {
                  order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse
                    ?.driver?.phone_number
                }
              </td>
            </tr>
          </>
          <>
            <tr>
              <th className="border border-slate-300 p-4" rowSpan={2}>
                {t("TimeLine")}
              </th>
              {order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse
                ?.timeline?.estimated_delivery_time && (
                <td className="border border-slate-300 p-4">
                  <td>
                    {t("Delivery Time")}:{" "}
                    {moment(
                      order?.logisticPartnerMetadata?.TALABAT
                        ?.orderCallbackResponse?.timeline
                        ?.estimated_delivery_time
                    ).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                </td>
              )}
            </tr>
            {order?.logisticPartnerMetadata?.TALABAT?.orderCallbackResponse
              ?.timeline?.estimated_pickup_time && (
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Pickup Time")}:{" "}
                  {moment(
                    order?.logisticPartnerMetadata?.TALABAT
                      ?.orderCallbackResponse?.timeline?.estimated_pickup_time
                  ).format("MMMM Do YYYY, h:mm:ss a")}
                </td>
              </tr>
            )}
          </>
        </table>
      )}
    </div>
  );
};

export default CheckDeliveryFee;
