import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin, Button, Modal } from "antd";

import TextInput from "components/TextInput";
import { errorNotification } from "utils/Utils";
import { errorHandler } from "api/api/errorHandler";
import useMakeDuplicateMenu from "features/menus/hooks/useMakeDuplicateMenu";

function CloneMenu({ menuState, updateState }) {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const makeDuplicate = useMakeDuplicateMenu();
  const initialState = {
    name: "",
    description: "",
    _id: "",
  };
  const [editMenu, setEditMenu] = useState({
    values: { ...initialState },
    errors: { ...initialState },
  });

  const handleChange = (key, value) => {
    setEditMenu((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: value,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const handleSetErrors = (key, value) => {
    setEditMenu((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [key]: value,
      },
    }));
  };

  const EditSelectedMenu = async () => {
    const { name, description, _id } = editMenu.values;
    if (!name) {
      handleSetErrors("name", "Please enter menu name");
      return;
    }

    if (description && description?.length < 5) {
      handleSetErrors("description", "Please enter minimum 5 characters");
      return;
    }
    try {
      await makeDuplicate({ menuId: _id, menu: editMenu.values });
    } catch (error) {
      errorNotification(errorHandler(error?.message));
    }
  };

  const resetState = () => {
    setEditMenu({
      values: { ...initialState },
      errors: { ...initialState },
    });
  };

  useEffect(() => {
    const { menuInfo } = menuState || {};
    if (menuInfo && menuInfo?._id) {
      const { baseLanguage, description, _id } = menuInfo;
      setEditMenu({
        values: {
          name: baseLanguage?.name || "",
          description: description || "",
          _id: _id || "",
        },
        errors: { ...initialState },
      });
    } else {
      setEditMenu({
        values: { ...initialState },
        errors: { ...initialState },
      });
    }
    return resetState;
  }, [menuState?.editMenumodal, menuState?.menuInfo]);

  return (
    <div>
      <Modal
        onCancel={() => {
          updateState("cloneMenuModal", false);
          resetState();
        }}
        footer={[
          <div className="flex justify-center">
            <Button type="primary" onClick={EditSelectedMenu}>
              {t("Save")}
            </Button>
            <Button
              onClick={() => {
                updateState("cloneMenuModal", false);
              }}
            >
              {t("Cancel")}
            </Button>
          </div>,
        ]}
        title={t("Clone Menu")}
        width={500}
        open={menuState.cloneMenuModal}
      >
        <Spin spinning={isLoading}>
          <div className="py-2">
            <TextInput
              label={t("Menu Name")}
              placeHolder={t("E.g. Delivery menu")}
              value={editMenu?.values?.name}
              keyVal="name"
              mandatory={true}
              error={editMenu?.errors?.name}
              handleValues={handleChange}
            />
          </div>
          <div className="py-2">
            <TextInput
              label={t("Menu description")}
              placeHolder={t("E.g. menu for delivery")}
              value={editMenu?.values?.description}
              keyVal="description"
              mandatory={false}
              error={editMenu?.errors?.description}
              handleValues={handleChange}
            />
          </div>
        </Spin>
      </Modal>
    </div>
  );
}

export default CloneMenu;
