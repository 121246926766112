import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import { BackButton } from "./BackButton";
import moment from "moment";
import ViewPaymentTable from "./ViewPaymentTable";
import { UTILS } from "utils";
import { useSelector } from "react-redux";
const ViewPaymentLink = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [paymentLink, setPaymentLink] = useState({});
  const [loading, setLoading] = useState(false);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  useEffect(() => {
    setLoading(true);
    if (history?.location?.state) {
      setPaymentLink(history.location.state.paymentLink);
    }
    setLoading(false);
  }, [history.location.state, paymentLink]);

  const getPaymentLinkUrl = (cartId) => {
    const userDetail = UTILS.getCurrentUser();
    const storeFrontURL = userDetail?.storeFrontURL;
    if (storeFrontURL && cartId && userDetail?.temporaryDomainName) {
      const link = `${storeFrontURL}${userDetail.temporaryDomainName}/payment-link/${cartId}`;
      return link;
    }
  };

  const shareonWhatsapp = (link) => {
    const url = getPaymentLinkUrl(paymentLink?.cartId);
    const webWhastsappLinkUrl = `https://wa.me/?text=${url}`;
    window.open(webWhastsappLinkUrl, "_blank");
  };
  const copyLink = (link) => {
    const linkurl = getPaymentLinkUrl(paymentLink?.cartId);
    navigator.clipboard.writeText(linkurl);
    UTILS.successNotification(t("Copied to Clipboard"));
  };

  const PaymentLinkUrl = ({ cartId }) => {
    if (getPaymentLinkUrl(cartId)) {
      const url = getPaymentLinkUrl(cartId);
      return (
        <h2 className="text-18px font-bold">
          {t("Link")} : <span className="pe-1"></span>
          <a
            rel="noreferrer"
            target="_blank"
            href={url}
            className="text-blue-500"
          >
            {url}
          </a>
        </h2>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="px-5">
      <div className="w-full sm:w-1/2">
        <BackButton />

        <Spin spinning={loading}>
          <div className="flex flex-wrap justify-between my-5">
            <div className="inline-block my-2">
              <h2 className="text-gray-900 text-24px sm:text-30px font-medium">
                {t("Payment link id")} #{paymentLink?.cartId}
              </h2>
            </div>
          </div>
          <div className="">
            <div>
              <p className="text-gray-500">
                {moment
                  .unix(paymentLink?.linkCreationDate)
                  .format("DD/MM/YYYY")}
              </p>
              <p className="text-24px py-2 font-bold">
                {new Intl.NumberFormat(currentLanguage, {
                  style: "currency",
                  currency: "AED",
                }).format(paymentLink?.amount || 0)}
              </p>
            </div>
            <div className="py-2">
              <p className="text-18px font-bold">{t("Purpose of payment")}</p>
              <p className="text-gray-600">{paymentLink?.purposeOfPayment}</p>
            </div>
            <div className="py-2">
              <p className="text-18px font-bold">{t("Message")}</p>
              <p className="text-gray-600">{paymentLink?.message}</p>
            </div>
            <div className="flex flex-col sm:flex-row w-full py-2">
              <div className="w-full sm:w-1/2">
                <p className="text-18px font-bold">{t("Store name")}</p>
                <p className="text-gray-600 pe-0 sm:pe-4 ">
                  {paymentLink?.storeName}
                </p>
              </div>
              <div className="w-full sm:w-1/2">
                <p className="text-18px font-bold mt-4 sm:mt-0">
                  {t("Created by")}
                </p>
                <p className="text-gray-600">{paymentLink?.createdBy}</p>
              </div>
            </div>
            <div className="py-2">
              <PaymentLinkUrl cartId={paymentLink.cartId} />
              <div className="py-4">
                <Button
                  type="primary"
                  size="large"
                  className="me-2 py-2 sm:py-0"
                  onClick={() => copyLink(paymentLink)}
                >
                  {t("Copy Link")}
                </Button>
                {/* <Button type="default" size="large" className="me-2 my-2 sm:my-0">
              View QR code
            </Button> */}
                <Button
                  type="default"
                  size="large"
                  className="my-2 sm:my-0"
                  onClick={() => shareonWhatsapp(paymentLink)}
                >
                  {t("Share link via whatsapp")}
                </Button>
              </div>
            </div>
          </div>
          <ViewPaymentTable paymentLink={[paymentLink]} />
        </Spin>
      </div>
    </div>
  );
};

export default ViewPaymentLink;
