import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { UTILS } from "utils";
import PropsTypes from "prop-types";
import Modal from "components/Modal";
import { API } from "api";
import Card from "components/Card";
import PageHeading from "components/PageHeading";
import TextInput from "components/TextInput";
import DeleteComponent from "components/DeleteConfirm";
import { Button, Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import { CONSTANTS as C } from "config";
import { useSelector } from "react-redux";
import BulkMenu from "features/bulk-menu/BulkMenu";
import { errorHandler } from "api/api/errorHandler";
const ItemContainer = (props) => {
  const router = useHistory();
  const { syncData } = useSelector((state) => state.MenuReducer);
  const [items, setItems] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    deleteItemIndex: null,
    item: null,
  });

  const [bulkMenuModal, setBulkMenuModal] = useState(false);
  const [searchTerm, setSearch] = useState("");
  // const [indexToDelete, setIndexToDelete] = useState(-1);
  // const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const renderTitleWithImage = (value, rowData, index) => {
    return (
      <div className="flex justify-between items-center">
        <img
          alt=""
          src={
            rowData.itemImageURLs.length > 0
              ? rowData.itemImageURLs[0]
              : C.PLACEHOLDER_IMAGE
          }
          className="w-14 sm:w-20 h-14"
        />
        <div className="start w-48 md:w-full ms-2 md:ms-4 my-auto">
          <h3 className="text-16px font-bold truncate-line-2">
            {UTILS.isValidObject(["baseLanguage", "title"], rowData)
              ? rowData.baseLanguage.title
              : ""}
          </h3>
          {/* <p className="text-12px text-gray-500 pt-2"><span className="text-indigo-700 font-bold">{t('Ingredients')}: </span>{UTILS.isValidObject(["baseLanguage","ingredients"],rowData) ? rowData.baseLanguage.ingredients : ""}</p> */}
          <p className="text-12px text-gray-500 pt-1 truncate-line-4">
            {UTILS.isValidObject(["baseLanguage", "description"], rowData)
              ? rowData.baseLanguage.description
              : ""}
          </p>
        </div>
      </div>
    );
  };

  const renderDate = (value, rowData, index) => {
    return (
      <div className="px-3 py-1 whitespace-nowrap text-center">
        <h3>
          {rowData.createdAt ? new Date(rowData.createdAt).toDateString() : ""}
        </h3>
        <h5 className="leading-3 text-12px text-gray-500">
          {rowData.createdAt
            ? new Date(rowData.createdAt).toLocaleTimeString()
            : ""}
        </h5>
      </div>
    );
  };
  const renderActions = (value, rowData, index) => {
    return (
      <div className="flex gap-4 px-3 py-2 whitespace-nowrap text-center ">
        <button
          className="border border-blue-600  px-4 text-blue-600 h-8"
          onClick={() => updateItem(rowData._id)}
        >
          {t("View")}
        </button>
        <button
          className="border border-red-600 px-4 text-red-600 h-8"
          onClick={() => onClickDeleteButton(rowData._id)}
        >
          {t("Delete")}
        </button>
      </div>
    );
  };

  const columns = [
    {
      key: "baseLanguage",
      title: t("Item"),
      dataIndex: "baseLanguage",
      align: "initial",
      render: renderTitleWithImage,
    },
    {
      key: "price",
      title: t("Price"),
      dataIndex: "price",
      align: "initial",
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 3,
      },
      render: (value, rowData, index) => {
        return <div>{t("AED") + " " + value}</div>;
      },
    },
    {
      key: "createdAt",
      title: t("Created At"),
      dataIndex: "createdAt",
      align: "initial",
      width: "10%",
      render: renderDate,
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: t("Actions"),
      render: renderActions,
      align: "initial",
      width: "10%",
    },
  ];

  const getItemsByBrand = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_ITEMS_BY_BRAND({
      token: userDetail.token,
      brandId: userDetail.brandId,
      language: "EN",
    });
    if (result?.success === 1) setItems(result.data);
    else {
    }
    setLoading(false);
  };

  useEffect(() => {
    getItemsByBrand();
  }, [syncData]);

  const updateItem = (id) => {
   
    router.push("/app/dashboard/updateitem/", {
      itemId: id,
    });
  };

  const createItem = () => {
    router.push("/app/dashboard/createitem");
  };

  const onClickDeleteButton = (id) => {
    const index = items.findIndex((obj) => obj._id === id);
    if (index !== -1) {
      const item = items[index];
      setDeleteModal({ isOpen: true, deleteItemIndex: index, item: item });
    }
  };

  const handleCancel = () => {
    setDeleteModal({ isOpen: false, deleteItemIndex: -1, item: null });
  };
  const onDeleteItem = async (item) => {
    try {
      const item = items[deleteModal.deleteItemIndex];
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const response = await API.DELETE_ITEM({
        brandId: userDetail.brandId,
        itemId: item._id,
        token: userDetail.token,
      });
      if (response?.success === 1) {
        UTILS.handleSuccesNotification(response?.message ?? t("Item Deleted"));
        let arr = [...items];
        arr.splice(deleteModal.deleteItemIndex, 1);
        setItems(arr);
      } else {
        UTILS.handleErrorNotification(errorHandler(response?.message));
      }
      setLoading(false);
    } catch (error) {
      UTILS.handleErrorNotification(errorHandler(error?.error?.message));
    } finally {
      handleCancel();
    }
  };

  const Items = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return items;
    }
    return items.filter((item) =>
      item?.baseLanguage?.title?.toLowerCase().includes(searchTerm.trim())
    );
  }, [items, searchTerm]);
  const handSearch = (key, val) => {
    setSearch(val.toLowerCase());
  };
  return (
    <div className="">
      <PageHeading heading={t("Items")} />
      <Modal
        open={deleteModal.isOpen}
        modalWidth="rounded-md border border-gray-300 top-0"
        onClose={handleCancel}
      >
        <DeleteComponent
          loading={loading}
          onCancel={handleCancel}
          onDelete={onDeleteItem}
          Heading={t(
            "will be permanentely deleted and removed from existing menus"
          )}
          title={deleteModal.item?.baseLanguage?.title}
        />
      </Modal>
      <Spin spinning={loading}>
        <div className="px-4 md:px-8 py-3">
          <div className="w-full">
            <Card>
              <div className="flex justify-between flex-wrap px-2 md:px-3 py-1 h-44 md:h-20">
                <div className="w-full md:w-1/2">
                  <TextInput
                    label={t("Search Item")}
                    value={searchTerm}
                    keyVal={"searchTerm"}
                    handleValues={handSearch}
                  />
                </div>
                <div className="flex w-full md:w-1/2 justify-center md:justify-end flex-wrap my-auto">
                  <Button
                    className="mx-2 mb-2 md:mb-0"
                    type="Default"
                    size="large"
                    onClick={() => {
                      setBulkMenuModal(true);
                    }}
                  >
                    {t("Import")}
                  </Button>
                  <BulkMenu open={bulkMenuModal} setOpen={setBulkMenuModal} />
                  <Button
                    type="primary"
                    size="large"
                    className="w-24"
                    onClick={() => createItem()}
                  >
                    {t("Create")}
                  </Button>
                </div>
              </div>
            </Card>
          </div>
          <div className="my-2">
            <Card>
              <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
                <Table
                  locale={{ emptyText: t("No Data") }}
                  columns={columns}
                  dataSource={Items}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 50,
                    defaultPageSize: 50,
                  }}
                  size="small"
                  rowKey={(obj) => obj._id}
                />
              </div>
            </Card>
          </div>
        </div>
      </Spin>
    </div>
  );
};

ItemContainer.propTypes = {
  data: PropsTypes.array,
};

export default ItemContainer;
