import { Redirect, Switch } from "react-router-dom";
import { UTILS } from "utils";
import { PackageUtil } from "utils";
import { CONSTANTS as C } from "config";
import useInterval from "use-interval";
import React, { useEffect } from "react";
import Dashboard from "features/dashboard/container/dashboard";
import UpdateStore from "features/store/containers/updateStore";
import AddStore from "features/store/containers/addStore";
import ManageAreas from "features/shipping_methods/areas/containers/ManageAreas";
import Menu from "features/menu/containers/menu";
import StoreList from "features/store/containers/storeList";
import LiveOrders from "features/orders/containers/liveOrders";
import CreateAdmin from "features/admin/containers/CreateAdmin";
import ItemContainer from "features/menu/containers/ItemContainer";

import CreateItem from "features/menu/components/CreateItem";
import UpdateItem from "features/menu/containers/UpdateItem";
import Packages from "features/packages/containers/Packages";
import OrderHistory from "features/orders/containers/OrderHistory";
import Inventory from "features/inventory/containers/inventory";
import Integrations from "features/integrations/containers/integrations";
import Customers from "features/customers/containers/customers";
import Marketing from "features/marketing/containers/marketing";
import GetStarted from "features/onboarding/containers/GetStarted";
import ScrollToTop from "features/settings/components/ScrollToTop";
import TabRoutePermision from "../../routes/middlewares/Protected";
import useUserType from "hooks/useUserType";
import AddUpdateLoyaltyProgram from "features/loyalty/containers/AddUpdateLoyaltyProgram";
import LoyaltyCustomers from "features/loyalty/containers/LoyaltyCustomers";
import CustomerWalletInfo from "features/loyalty/containers/CustomerWalletInfo";

import Discount from "features/discount/containers/Discount";
import AddDiscount from "features/discount/containers/AddDiscount";
import UpdateDiscount from "features/discount/containers/UpdateDiscount";
import Branding from "features/settings/containers/Branding";
import Profile from "features/settings/containers/Profile";
import BusinessSettings from "features/settings/containers/BusinessSettings";
import Help from "features/help/containers/help";
import ManageDelivery from "features/shipping_methods/containers/ManageDelivery";
import ManageCities from "features/shipping_methods/cities/containers/ManageCities";

import ManageCountries from "features/shipping_methods/countries/containers/ManageCountries";
import ShippingDelivery from "features/shipping_methods/containers/ShippingDelivery";
import ViewAllPaymentLinks from "features/paymentlinks/containers/ViewAllPaymentLinks";
import CreatePaymentLinks from "features/paymentlinks/containers/CreatePaymentLinks";
import ViewPaymentLinkInfo from "features/paymentlinks/containers/ViewPaymentLinkInfo";
//modifer
import ModifiersList from "features/Modifier/Modifier";
import ModifiersGroup from "features/Modifier/ModifierGroup";
import CreateModifiersGroup from "features/Modifier/CreateGroup";
import UpdateModifiersGroup from "features/Modifier/UpdateModiferGroup";
//
import CreateNewModifier from "features/Modifier/CreateModifier";
import UpdateNewModifier from "features/Modifier/UpdateModifier";
import PauseModifier from "features/inventory/containers/PauseModifiers";
import ReportingRoutes from "features/reporting/containers/routes";
import MappedAreasRoutes from "features/shipping_methods/mapped-areas/routes";

//Menus
import MenusLsting from "features/menus/containers/Menus";
import MenuEdit from "features/menus/containers/MenuEditor";

import QRCodes from "features/qr-codes/index";
import * as R_CONST from "routes/Constants";

import { useDispatch, useSelector } from "react-redux";
import { PkgActions } from "redux/actions";
const SideBarRoutesWithComponent = () => {
  const { expDays } = useSelector((state) => state.PackagesReducer);
  const { CatalogueType } = useSelector((state) => state?.SettingsReducer);
  const userType = useUserType();
  const dispatch = useDispatch();
  const check_PackageValidity = async () => {
    const { result } = await UTILS.checkPackageValidity();
    if (result?.data) {
      const diff = PackageUtil.getPackageExpiry(result.data.subscription);
      dispatch(PkgActions.setDays(diff));
    }
  };
  useEffect(() => {
    check_PackageValidity();
  }, []);

  useInterval(() => {
    check_PackageValidity();
  }, C.SIXHOURS);

  const restheroSpecificRoutes = [
    {
      path: "/app/dashboard/onboarding",
      component: GetStarted,
    },

    {
      path: "/app/dashboard/subscription",
      component: Packages,
    },
    {
      path: "/app/dashboard/help",
      component: Help,
    },
  ];

  const publicRoutes = [
    {
      path: "/app/dashboard",
      component: Dashboard,
    },
    {
      path: "/app/dashboard/payment-links",
      component: ViewAllPaymentLinks,
    },
    {
      path: "/app/dashboard/integrations",
      component: Integrations,
    },
    {
      path: "/app/qr-codes",
      component: QRCodes,
    },
    {
      path: "/app/dashboard/shipping",
      component: ShippingDelivery,
    },

    {
      path: "/app/dashboard/updatestore",
      component: UpdateStore,
    },
    {
      path: "/app/dashboard/stores",
      component: StoreList,
    },
    {
      path: R_CONST.ADD_AREA,
      component: ManageAreas,
    },

    {
      path: "/app/dashboard/orderhistory",
      component: OrderHistory,
    },
    {
      path: "/app/dashboard/liveorders",
      component: LiveOrders,
    },

    {
      path: "/app/dashboard/inventory",
      component: Inventory,
    },

    {
      path: "/app/dashboard/local-delivery-methods",
      component: ManageDelivery,
    },

    {
      path: "/app/dashboard/customers",
      component: Customers,
    },

    {
      path: "/app/dashboard/marketing",
      component: Marketing,
    },

    {
      path: "/app/dashboard/settings/profile",
      component: Profile,
    },
    {
      path: "/app/dashboard/create-payment-links",
      component: CreatePaymentLinks,
    },
    {
      path: "/app/dashboard/payment-link-detail",
      component: ViewPaymentLinkInfo,
    },
  ];

  const adminRoutes = [
    ...ReportingRoutes,
    ...MappedAreasRoutes,

    {
      path: "/app/dashboard/addstore",
      component: AddStore,
    },
    {
      path: "/app/dashboard/settings/branding",
      component: Branding,
    },
    {
      path: "/app/dashboard/settings/business",
      component: BusinessSettings,
    },

    {
      path: "/app/dashboard/userroles",
      component: CreateAdmin,
    },
    {
      path: "/app/dashboard/menu",
      component: Menu,
    },

    {
      path: "/app/dashboard/items",
      component: ItemContainer,
    },

    {
      path: "/app/dashboard/createitem",
      component: CreateItem,
    },
    {
      path: "/app/dashboard/updateitem",
      component: UpdateItem,
    },

    {
      path: "/app/dashboard/update-modifier",
      component: UpdateNewModifier,
    },
    {
      path: "/app/dashboard/modifiers-list",
      component: ModifiersList,
    },
    {
      path: "/app/dashboard/modifier-group",
      component: ModifiersGroup,
    },
    {
      path: "/app/dashboard/pause-modifier",
      component: PauseModifier,
    },
    {
      path: "/app/dashboard/create-modifier-group",
      component: CreateModifiersGroup,
    },
    {
      path: "/app/dashboard/update-modifier-group",
      component: UpdateModifiersGroup,
    },
    // {
    //   path: "/app/dashboard/updatemodifier",
    //   component: UpdateModifier,
    // },
    {
      path: "/app/dashboard/add-new-modifier",
      component: CreateNewModifier,
    },
    {
      path: "/app/dashboard/discount",
      component: Discount,
    },
    {
      path: "/app/dashboard/adddiscount",
      component: AddDiscount,
    },
    {
      path: "/app/dashboard/updatediscount",
      component: UpdateDiscount,
    },
    {
      path: "/app/dashboard/add-update-brand-loyalty",
      component: AddUpdateLoyaltyProgram,
    },
    {
      path: "/app/dashboard/brand-customers",
      component: LoyaltyCustomers,
    },
    {
      path: "/app/dashboard/customer-wallet-history",
      component: CustomerWalletInfo,
    },

    {
      path: "/app/dashboard/menu-list",
      component: MenusLsting,
    },
    {
      path: "/app/dashboard/menu-editor/:menuId",
      component: MenuEdit,
    },
  ];

  const shippingRoutes = [
    {
      path: R_CONST.ADD_CITY,
      component: ManageCities,
    },
    {
      path: R_CONST.ADD_COUNTRY,
      component: ManageCountries,
    },
  ];
  return (
    <div className="w-full 3xl:w-1619p 3xl:mx-auto 3xl:flex 3xl:justify-center h-full mb-120p">
      <ScrollToTop>
        {expDays <= 5 ? (
          <Switch>
            {publicRoutes.map((route, index) => {
              return (
                <TabRoutePermision
                  key={route.path}
                  path={route.path}
                  exact
                  component={route.component}
                />
              );
            })}

            {process.env.REACT_APP_COMPANY_NAME === C.COMPANY_NAMES.RESTHERO
              ? restheroSpecificRoutes.map((route, index) => {
                  return (
                    <TabRoutePermision
                      key={route.path}
                      path={route.path}
                      exact
                      component={route.component}
                    />
                  );
                })
              : null}
            {adminRoutes.map((route, index) => {
              return (
                <TabRoutePermision
                  key={route.path}
                  path={route.path}
                  exact
                  CURRENT_USER_TYPE={userType}
                  roleRequired="BRAND-ADMIN"
                  component={route.component}
                />
              );
            })}

            {shippingRoutes.map((route, index) => {
              return CatalogueType === C.BRAND_BASED ? (
                <TabRoutePermision
                  key={route.path}
                  path={route.path}
                  exact
                  CURRENT_USER_TYPE={userType}
                  component={route.component}
                />
              ) : (
                <Redirect to="/app/dashboard/local-delivery-methods" />
              );
            })}
          </Switch>
        ) : (
          <Switch>
            <TabRoutePermision path="/app/dashboard/subscription">
              <Packages />
            </TabRoutePermision>
          </Switch>
        )}
      </ScrollToTop>
    </div>
  );
};

export default SideBarRoutesWithComponent;
