import React, { useContext } from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { UTILS } from "utils";
import { CatelougeContext } from "store/contexts/Catelouge";
import { ACTIONS } from "config";

const NavBar = ({ menu, visible, setVisible }) => {
  const { dispatch } = useContext(CatelougeContext);
  const drawerAction = () => {
    // dispatch({ type: ACTIONS.STORES_CHANGED });
    setVisible(false);
  };
  const dir = UTILS.getLanguageInformation()?.direction;
  const setDrawer = dir === "rtl" ? "right" : "left";
  return (
    <nav className="navbar">
      <Button
        className="menu"
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title={process.env.REACT_APP_COMPANY_NAME}
        placement={setDrawer}
        onClick={drawerAction}
        onClose={drawerAction}
        open={visible}
      >
        {menu}
      </Drawer>
      <a href="/">
        <img
          src={process.env.PUBLIC_URL + process.env.REACT_APP_COMPANY_LOGO}
          className="logo"
          alt="logo"
        />
      </a>
    </nav>
  );
};
export default NavBar;
