import { createSlice } from "@reduxjs/toolkit";
import { CONSTANTS as C } from "config";

const initialState = {
  settings: {},
  CatalogueType: C.BRAND_BASED,
};

const SettingsReducer = createSlice({
  name: "settingReducer",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    updateCatalogueType: (state, action) => {
      state.CatalogueType = action.payload;
    },
  },
});

export const { setSettings, updateCatalogueType } = SettingsReducer.actions;
export default SettingsReducer.reducer;
