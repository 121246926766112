import React from "react";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
const RefundNotRequiredConfirmation = ({
  closeModal,
  onSubmit,
  loading,
  order,
}) => {
  const { t } = useTranslation();
  return (
    <Spin spinning={loading}>
      <div className="w-full p-5 relative mx-auto my-auto bg-white ">
        <div className="">
          <div className="flex-auto justify-center my-2">
            <div className="flex justify-between">
              <h2 className="font-semibold me-4">{t("Confirmation")}</h2>
              <h2 className="font-semibold">
                {t("Order")} #{order?.orderNumber}
              </h2>
            </div>
            <div className="px-4 text-auto py-4">
              {t("Are you sure you want to mark Order as nonrefundable", {
                orderNumber: order?.orderNumber,
              })}
            </div>
          </div>
          <div className=" mt-2 px-4 text-center md:block flex justify-end">
            <Button
              type="default"
              size="large"
              className="w-24 mx-2"
              disabled={loading}
              onClick={closeModal}
            >
              {t("No")}
            </Button>
            <Button
              className="w-24"
              type="danger"
              size="large"
              disabled={loading}
              onClick={onSubmit}
              htmlType="button"
            >
              {t("Yes")}
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default RefundNotRequiredConfirmation;
