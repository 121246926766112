import React from "react";
import { useTranslation } from "react-i18next";

const ClaimInfo = ({ DZOrder }) => {
  const { t } = useTranslation();

  const renderRow = (label, value) => {
    return (
      <tr key={label}>
        <td className="border border-slate-300 p-4">{t(label)}</td>
        <td className="border border-slate-300 p-4">{value}</td>
      </tr>
    );
  };

  return (
    <div>
      <table className="border-collapse border border-slate-400 w-full mt-4">
        {DZOrder && (
          <>
            {renderRow("Order Status", DZOrder.status?.toUpperCase())}
            {DZOrder?.rider_name && (
              <tr key="Rider Phone Number">
                <td className="border border-slate-300 p-4">
                  {t("Rider Phone Number")}
                </td>
                <td className="border border-slate-300 p-4">
                  <span dir="ltr">{DZOrder.rider_phone_number}</span>
                </td>
              </tr>
            )}
            {DZOrder?.rider_name && renderRow("Rider Name", DZOrder.rider_name)}
            {DZOrder?.order_id &&
              renderRow("Delivery Zone Order Number", DZOrder?.order_id)}
          </>
        )}
      </table>
    </div>
  );
};

export default ClaimInfo;
