import React, { useEffect } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import BusinessTabSettings from "../components/BusinessTabSettings";
import { useGetSettingsMutation } from "redux/settings/settingsSlice";
import { useSelector } from "react-redux";
const BusinessSettings = () => {
  const { t } = useTranslation();
  const [getSettings] = useGetSettingsMutation();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  useEffect(() => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
      language: "EN",
    };
    getSettings(obj);
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="px-8 py-4">
        <h1 className="text-blue-600 text-19px pt-4">
          {t("Business settings")}
        </h1>
        <h3 className="text-gray-500  pb-4">
          {t("View and manage your business details.")}
        </h3>
        <BusinessTabSettings />
      </div>
    </Spin>
  );
};

export default BusinessSettings;
