import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areaData: [],
  addedAreas: [], //Already added areas
  customAllAreas: [], //added areas+added custom propty "isAdded" from client side
  //Mapped areas
  addedMappedAreas: [],
  newSelectedMappedAreas: [],
  mapCustomBusinessAreas: [],

  cititesList: [],
  countriesList: [],
  customizedCountryList: [],
  customizedCityList: [],
  selectedStore: null,
  addedCountries: [],
  addedCities: [],
  selectedCountries: [],
  selectedCities: [],
  selectedAreas: [],
  syncData: false,
};

const AreasReducer = createSlice({
  name: "areasReducer",
  initialState,
  reducers: {
    setFetchedAreas: (state, action) => {
      state.areaData = action.payload;
    },
    setSync: (state, action) => {
      state.syncData = !state.syncData;
    },
    setCountryList: (state, action) => {
      state.countriesList = action.payload;
    },
    setcititesList: (state, action) => {
      state.cititesList = action.payload;
    },
    setcustomeAddedAreas: (state, action) => {
      state.customAllAreas = action.payload;
    },
    setAddedAreas: (state, action) => {
      state.addedAreas = action.payload;
    },

    //
    setMapAddedAreas: (state, action) => {
      state.addedMappedAreas = action.payload;
    },
    setNewMappedAreas: (state, action) => {
      state.newSelectedMappedAreas = action.payload;
    },
    setMapCustomBuisnessAreas: (state, action) => {
      state.mapCustomBusinessAreas = action.payload;
    },

    //
    setStore: (state, action) => {
      state.selectedStore = action.payload;
    },
    setAddedCountries: (state, action) => {
      state.addedCountries = action.payload;
    },
    setcustomizedCountryList: (state, action) => {
      state.customizedCountryList = action.payload;
    },
    setcustomizedCityList: (state, action) => {
      state.customizedCityList = action.payload;
    },

    updateSelectedCountries: (state, action) => {
      state.selectedCountries = action.payload;
    },

    setAddedCities: (state, action) => {
      state.addedCities = action.payload;
    },

    updateSelectedCities: (state, action) => {
      state.selectedCities = action.payload;
    },

    updateSelectedArea: (state, action) => {
      state.selectedAreas = action.payload;
    },
  },
});

export const {
  setMapCustomBuisnessAreas,
  setMapAddedAreas,
  setNewMappedAreas,
  updateSelectedArea,
  updateSelectedCities,
  setAddedCities,
  setFetchedAreas,
  setCountryList,
  setcititesList,
  setcustomeAddedAreas,
  setAddedAreas,
  updateSelectedCountries,
  setStore,
  setAddedCountries,
  setcustomizedCountryList,
  setSelectedCountries,
  setSync,
  setcustomizedCityList,
} = AreasReducer.actions;
export default AreasReducer.reducer;
