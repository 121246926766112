import React from "react";
import { useSelector } from "react-redux";
import Support from "logistic-integrations/components/Support";
import { useTranslation } from "react-i18next";

function CareemSupport() {
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);
  const { t } = useTranslation();
  return (
    <Support
      orderNo={
        orderInfo?.logisticPartnerMetadata?.CAREEM_NOW?.createDeliveryResponse
          ?.reference
      }
      phone="+97142790334"
      heading={t("Careem Order Number")}
      heading2={t("Careem Helpline Number")}
    />
  );
}

export default CareemSupport;
