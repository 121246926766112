import { API } from "api";
import React, { useEffect, useState } from "react";
import { UTILS } from "utils";
import PageHeading from "../../../components/PageHeading";
import AddAdminUser from "../component/AddAdminUser";
import ManageAdminUsers from "../component/ManageAdminUsers";
import { CONSTANTS as C } from "config";
import { useTranslation } from "react-i18next";
const CreateAdmin = () => {
  const [users, setUsers] = useState([]);

  const { t } = useTranslation();
  const getAdminUsersByBrand = async () => {
    const userDetail = UTILS.getCurrentUser();

    const result = await API.GET_USERS_BY_BRAND({
      brandId: userDetail.brandId,
      token: userDetail.token,
      language: "EN",
    });

    if (UTILS.isValidObject(["data", "users"], result) && result.success === 1)
      setUsers(result.data.users);
    else {
    }
  };

  useEffect(() => {
    getAdminUsersByBrand();
  }, []);

  const handleValues = () => {
    getAdminUsersByBrand();
  };

  return (
    <div className="w-full h-full">
      <PageHeading heading={t("Users")} />
      <h3 className="px-4 py-2 text-gray-600 text-16px">
        {t(
          "You can add new users and set their acccess level, which will decide the stores they can utilize."
        )}
      </h3>
      <div className="flex justify-between flex-wrap border-t border-gray-200">
        <div className="w-full lg:w-5/12 border-r border-gray-200">
          <AddAdminUser callback={handleValues} />
        </div>
        <div className="w-full lg:w-7/12">
          <ManageAdminUsers users={users} />
        </div>
      </div>
    </div>
  );
};
export default CreateAdmin;
