import React, { createContext, useContext } from "react";
import useInterval from "use-interval";
import useGetOrderMetaData from "hooks/useGetOrderMetaData";

// Create a context
const OrderMetaDataContext = createContext();
const audioUrl =
  "https://uapp-live-com.s3.amazonaws.com/UAPP-MEDIA/PENDING_ORDER_MUSIC.mpeg";
const OrderMetaDataProvider = (props) => {
  const { data, revalidate } = useGetOrderMetaData();
  const audio = new Audio(audioUrl);

  const playPreOrderBeep = () => {
    const preOrderBeep = data?.preOrderBeep;
    if (preOrderBeep) playAudio(audio);
  };
  useInterval(() => {
    playPreOrderBeep();
  }, 10000);

  const playAudio = async (mp3) => {
    const playPromise = mp3.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          mp3.play();
        })
        .catch((error) => {});
    }
  };

  return (
    <OrderMetaDataContext.Provider
      value={{
        data,
        audio,
        revalidate,
      }}
    >
      {props.children}
    </OrderMetaDataContext.Provider>
  );
};

export const useOrderMetaDataContext = () => {
  const context = useContext(OrderMetaDataContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};

export default OrderMetaDataProvider;
