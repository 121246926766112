import React from "react";
import PropTypes from "prop-types";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};
const getRows = (rows) => {
  if (rows) {
    return rows;
  } else {
    return 4;
  }
};
const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600"> {error} </span>;
  }
};
const TextArea = (props) => {
  const mandatoryField = getMandatoryField(props.mandatory);
  const fieldError = getFieldError(props.error);
  const rows = getRows(props.rows);
  return (
    <>
      <label className="text-gray-600 block text-14px">
        {props.label}
        {mandatoryField}
        {fieldError}
      </label>
      <textarea
        onChange={(event) =>
          props.handleValues(props.keyVal, event.target.value)
        }
        rows="4"
        cols="50"
        value={props.value}
        className="font-montserrat text-input-color text-16px border border-grey-600 focus:ring-indigo-500 outline-none focus:border-indigo-500 block w-full border-gray-300 p-2"
      />
    </>
  );
};

TextArea.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
};

export default TextArea;
