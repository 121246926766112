import { Button, Empty } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function EmptyFooter({ url, heading, heading2, handleClick }) {
  const router = useHistory();
  const { t } = useTranslation();
  return (
    <Empty description={<span>{heading}</span>}>
      <Button onClick={() => router.push(url)} type="primary">
        {heading2}
      </Button>
      <div className="mt-2 text-center space-x-4 md:block py-2">
        <button
          onClick={handleClick}
          className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100"
        >
          {t("Close")}
        </button>
      </div>
    </Empty>
  );
}

export default EmptyFooter;
