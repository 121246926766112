import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
const postConfig = {
  query: (body) => ({
    method: "POST",
    body: body,
  }),
  transformResponse: (response) => {
    return response;
  },
};

const ModifierService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getModifierGroups: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_MODIFIER_GROUPS,
        ...postConfig.query(body),
      }),
    }),
    createModifierGroup: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CREATE_MODIFIER_GROUP,
        ...postConfig.query(body),
      }),
    }),
    updateModifierGroup: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.UPDATE_MODIFIER_GROUP,
        ...postConfig.query(body),
      }),
    }),
    deleteModifierGroup: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.DELETE_MODIFIER_GROUP,
        ...postConfig.query(body),
      }),
    }),
    getModifierList: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_BRAND_MODIFIERS,
        ...postConfig.query(body),
      }),
    }),
    createNewModifier: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CREATE_NEW_MODIFIER,
        ...postConfig.query(body),
      }),
    }),
    deleteModifier: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.DELETE_MODIFIER,
        ...postConfig.query(body),
      }),
    }),
    upDateModifier: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.UPDATE_MODIFIER,
        ...postConfig.query(body),
      }),
    }),

    unLinkModifierFromGroup: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.UNLINK_MODIFIER_WITH_GROUP,
        ...postConfig.query(body),
      }),
    }),

    getModifierGroup: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_MODIFIER_GROUP_INFO,
        ...postConfig.query(body),
      }),
    }),

    getModifierOptionInfo: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_MODIFIER_GROUP,
        ...postConfig.query(body),
      }),
    }),
    getStoreModifiers: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_STORE_MODIFIERS,
        ...postConfig.query(body),
      }),
    }),
  }),
});
export const {
  useGetModifierGroupsMutation,
  useCreateModifierGroupMutation,
  useUpdateModifierGroupMutation,
  useDeleteModifierGroupMutation,
  useGetModifierListMutation,
  useCreateNewModifierMutation,
  useDeleteModifierMutation,
  useUpDateModifierMutation,
  useUnLinkModifierFromGroupMutation,
  useGetModifierGroupMutation,
  useGetModifierOptionInfoMutation,
  useGetStoreModifiersMutation,
} = ModifierService;
export default ModifierService;
