import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import SearchCity from "../common/Search";
import PriceInput from "components/useForm/PriceInput";
import { UTILS } from "utils";
import {
  useAddCitiesMutation,
  useGetAddedCitiesMutation,
} from "redux/shipping-methods/shipping-service";
import { useSelector, useDispatch } from "react-redux";
import { AreasAction } from "redux/actions";
import ChooseTime from "../common/ChooseTime";
import CityList from "../cities/components/CitiesList";
import TextInput from "components/TextInput";
const AddNewCity = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getAddedCities] = useGetAddedCitiesMutation();
  const [addCities] = useAddCitiesMutation();
  const { selectedStore, selectedCities, customizedCityList } = useSelector(
    (state) => state.AreasReducer
  );
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const [searchTerm, setSearch] = useState("");
  const [state, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    deliveryTime: "",
    selectedAreas: [],
  });
  const [time, setTime] = useState({
    Hours: 0,
    Minutes: 0,
    Days: 0,
  });
  const handleValues = async (key, value) => {
    setState({ ...state, [key]: value });
  };

  const addNewCities = UTILS.withTryCatch(async () => {
    let delTime = time.Days * 1440 + time.Hours * 60 + time.Minutes;
    const userDetail = UTILS.getCurrentUser();
    let areas = [];
    selectedCities.forEach((city) => {
      areas.push({
        cityID: city._id,
        countryID: city.countryID,
        deliveryFee: +Number(state.deliveryFee),
        deliveryTime: +Number(delTime),
        minOrder: +Number(state.minOrder),
      });
    });
    const obj = {
      brandId: userDetail.brandId,
      storeId: selectedStore.value,
      areas,
    };
    const getObj = {
      brandId: userDetail.brandId,
      storeId: selectedStore.value,
    };

    await addCities(obj).unwrap();
    await getAddedCities(getObj).unwrap();
    dispatch(AreasAction.updateSelectedCities([]));
    dispatch(AreasAction.setSync());
    onClose();
  });

  const handleSearch = (key, val) => {
    setSearch(val.toLowerCase());
  };

  const CITIES = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm?.toLowerCase()?.trim() ?? "";
    if (!lowerCaseSearchTerm) {
      return customizedCityList;
    }

    return customizedCityList.filter(
      (item) =>
        item?.cityNameEN?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
        item?.cityNameAR?.toLowerCase()?.includes(lowerCaseSearchTerm)
    );
  }, [customizedCityList, searchTerm]);

  const saveArea = (city) => {
    let update = [];
    const isCityAlreadySelected = !!selectedCities?.find(
      (c) => c?._id === city?._id
    );
    if (isCityAlreadySelected) {
      update = selectedCities.filter((c) => c._id !== city._id);
    } else {
      update = [...selectedCities, city];
    }
    dispatch(AreasAction.updateSelectedCities(update));
  };

  const isChecked = (city) => {
    return !!selectedCities?.find((c) => c?._id === city?._id);
  };

  let isValid = () => {
    if (
      isLoading ||
      state?.minOrder === "" ||
      state?.deliveryFee === "" ||
      selectedCities.length <= 0 ||
      (time?.Days === 0 && time?.Hours === 0 && time?.Minutes === 0)
    )
      return false;
    return true;
  };
  const handleInputValue = (key, val) => {
    const value = UTILS.NumberConversion(val);
    handleValues(key, value);
  };
  return (
    <div className="w-full p-2 sm:p-5 mx-auto my-auto rounded-xl shadow-lg  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            <div className="flex justify-between">
              <div>
                <h2 className="text-19px font-bold mb-3 py-1 px-2">
                  {t("Add delivery cities")}
                </h2>
              </div>
              <div className="cursor-pointer" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-6 h-6 bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="px-2  w-full sm:w-1/2 register-page">
                <TextInput
                  label={t("Min. Basket Value")}
                  value={state.minOrder.trim()}
                  keyVal={"minOrder"}
                  handleValues={handleInputValue}
                  mandatory={true}
                />
              </div>
              <div className="px-2 w-full sm:w-1/2 register-page">
                <PriceInput
                  label={t("Delivery Fee")}
                  value={state.deliveryFee.trim()}
                  keyVal={"deliveryFee"}
                  handleValues={handleValues}
                  mandatory={true}
                />
              </div>
            </div>
            <div className="flex flex-wrap px-2">
              <ChooseTime {...{ time, setTime }} />
            </div>
            <div className="border border-gray-200 mx-2 my-2">
              <SearchCity
                value={searchTerm}
                keyVal={"searchTerm"}
                handleValues={handleSearch}
                placeholder="search city by name"
              />
              <hr className=" border-b-1 border-gray-200" />
              <div className="max-h-60 overflow-y-scroll">
                <CityList
                  {...{
                    CITIES,
                    isChecked,
                    saveArea,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between  items-center px-3">
            <div className="text-gray-500 underline max-w-40">
              {t("I can't find city")}
            </div>
            <div>
              <Button
                type="primary"
                size="large"
                onClick={addNewCities}
                disabled={!isValid()}
              >
                {t("Add Cities")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

AddNewCity.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddNewCity;
