import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { Switch } from "@headlessui/react";
const LinkUnlinkItemWithStoresModal = ({
  item,
  connectedStores,
  removeItemFromStore,
  loading,
  cat,
}) => {
  const handleValues = (storeID, ItemID, event, catID) => {
    removeItemFromStore(storeID, ItemID, event, catID);
  };
  const isChecked = (id) => {
    const store = item?.unConnectedStoreIds?.find(
      (unConttedID) => unConttedID === id
    );
    if (store) return false;
    return true;
  };

  return (
    <Spin spinning={loading}>
      <div className="modal" key={cat?._id}>
        {connectedStores?.map((store, index) => (
          <div
            key={store?.storeId._id || index}
            className="flex items-center mb-2"
          >
            <div className="flex items-center mt-3 w-full">
              <div className="w-16">
                <Switch
                  checked={isChecked(store?.storeId._id)}
                  onChange={(event) => {
                    handleValues(
                      store?.storeId._id,
                      item.itemId._id,
                      event,
                      cat
                    );
                  }}
                  className={`mb-1 ${
                    isChecked(store?.storeId._id)
                      ? "bg-theme-green"
                      : "bg-gray-300"
                  } relative inline-flex items-center h-8 rounded-full w-16`}
                >
                  <span className="sr-only">
                    {store?.storeId?.baseLanguage?.name}
                  </span>
                  <span
                    className={`${
                      isChecked(store?.storeId._id)
                        ? "translate-x-9"
                        : "translate-x-1"
                    } inline-block w-6 h-6 transform bg-white rounded-full`}
                  />
                </Switch>
              </div>
              <span className="text-gray-600 text-14px ms-3 mx-2">
                {store?.storeId?.baseLanguage?.name}
              </span>
            </div>
          </div>
        ))}
      </div>
    </Spin>
  );
};
export default LinkUnlinkItemWithStoresModal;
LinkUnlinkItemWithStoresModal.propTypes = {
  onClose: PropTypes.func,
  cat: PropTypes.object,
  item: PropTypes.object,
};
