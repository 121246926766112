import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { LoaderAction, AreasAction } from "redux/actions";

import { errorHandler } from "api/api/errorHandler";
import { isValidInputFields } from "features/shipping_methods/helpers";
import useGetStoreAddedMappedAreas from "./hooks/useGetStoreAddedMapAreas";

import { API } from "api";
import { UTILS } from "utils";
import UpdateAreaModal from "features/shipping_methods/common/UpdateAreaModal";
import { errorNotification } from "utils/Utils";
import { calculateDeliveryTimeInMins } from "../../helpers";

const UpdateAreas = ({ onClose, selectedAreas, setSelectedRowsKeys }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getStoreMappedAddedAreas = useGetStoreAddedMappedAreas();

  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const [state, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    deliveryTime: {
      Hours: 0,
      Minutes: 0,
      Days: 0,
    },
  });
  const handleValues = (key, val) => setState({ ...state, [key]: val });

  const editAreas = async () => {
    try {
      const { error, isValid } = isValidInputFields({
        isLoading,
        state,
        selectedAreas,
      });
      if (!isValid) {
        errorNotification(error);
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const deliveryTimeInMins = calculateDeliveryTimeInMins({
        deliveryTime: state.deliveryTime,
      });
      const userDetail = UTILS.getCurrentUser();
      let objectArray = [];
      selectedAreas.forEach((area) => {
        objectArray.push(area._id);
      });

      const result = await API.updateStoreMappedAreas({
        brandId: userDetail.brandId,
        storeId: selectedAreas[0].storeId,
        mappedAreaBaseDeliveryMethodIds: objectArray,
        minOrder: Number(state.minOrder),
        deliveryFee: Number(state.deliveryFee),
        deliveryTime: Number(deliveryTimeInMins),
      });
      if (result?.success === 1) {
        UTILS.successNotification(t(result.message));
        dispatch(AreasAction.setNewMappedAreas([]));
      } else {
        UTILS.errorNotification(errorHandler(result));
      }
      setSelectedRowsKeys([]);
      await getStoreMappedAddedAreas({
        storeId: selectedAreas[0].storeId,
      });
      onClose();
    } catch (error) {
      UTILS.errorNotification(errorHandler(error));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const handleInputValue = (key, val) =>
    handleValues(key, UTILS.NumberConversion(val));

  return (
    <UpdateAreaModal
      {...{
        isLoading,
        selectedAreas,
        onClose,
        editAreas,
        state,
        handleInputValue,
        handleValues,
        isValid: () =>
          isValidInputFields({ isLoading, state, selectedAreas })?.isValid,
        time: state.deliveryTime,
        setTime: (val) => handleValues("deliveryTime", val),
      }}
    />
  );
};

UpdateAreas.propTypes = {
  onClose: PropTypes.func,
};

export default UpdateAreas;
