import React from "react";
import { UTILS } from "utils";
import { WhatsAppButtonSvg } from "svg/SVGs";

const WhatsAppButton = () => {
  const dir = UTILS.getLanguageInformation()?.direction;
  const buttonPos =
    dir == "rtl"
      ? "chaty-widget left-position has-single"
      : "chaty-widget right-position has-single";
  const tooltipPos =
    dir == "rtl"
      ? "chaty-tooltip pos-right active has-on-hover"
      : "chaty-tooltip pos-left active has-on-hover";
  return (
    <div
      style={{ display: "none" }}
      className="chaty chaty-id-0 chaty-widget-0 chaty-key-0 all_time active"
      id="chaty-widget-0"
      data-key="0"
      data-id="0"
      data-identifier="0"
      data-nonce="51c384d07b"
      data-animation="waggle"
    >
      <div className={buttonPos}>
        <div className="chaty-channels">
          <div className="chaty-channel-list"></div>
          <div className="chaty-i-trigger single-channel">
            <div
              className="chaty-channel Whatsapp-channel single"
              id="Whatsapp-0-channel"
              data-id="Whatsapp-0"
              data-widget="0"
              data-channel="Whatsapp"
            >
              <a
                href="https://wa.me/971527325000?text=How can we help?"
                target="_blank"
                className={tooltipPos}
                data-form="chaty-form-0-Whatsapp"
                data-hover="WhatsApp"
              >
                <span className="chaty-icon channel-icon-Whatsapp">
                  <span className="chaty-svg chaty-animation-waggle">
                    <WhatsAppButtonSvg />
                    <span className="ch-pending-msg">1</span>
                  </span>
                </span>
                <span className="on-hover-text">Talk to us</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppButton;
