import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isValidValue } from "utils/Utils";
const CancelOrder = ({ cancelCareemORder, careemOrder }) => {
  const { t } = useTranslation();
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);

  if (
    isValidValue(
      orderInfo?.logisticPartnerMetadata?.CAREEM_NOW?.createDeliveryResponse?.id
    )
  ) {
    return (
      <div>
        <div className="flex justify-center p-4">
          <Button type="default" size="large" onClick={cancelCareemORder}>
            {t("Cancel order with Careem")}
          </Button>
        </div>
      </div>
    );
  } else if (careemOrder?.status === "CANCELLED") {
    return (
      <div className="flex justify-center p-4">
        <div>{t("Order Has been Cancelled Already")}</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center p-4">
      <div>
        {t(
          "You haven’t sent your order to Careem delivery yet, you can send it by clicking SEND ORDER tab"
        )}
      </div>
    </div>
  );
};

export default CancelOrder;
