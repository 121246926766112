import React from "react";
import { MenuContextProvider } from "store/contexts/Menus";
import OrderMetaDataProvider from "store/contexts/OrderMetaDataContext";
import { ShippingMethodContextProvider } from "store/contexts/ShippingMethodContext";
import { LogisticContextProvider } from "logistic-integrations/context";

function WithContextProviders({ children }) {
  const providersArray = [
    MenuContextProvider,
    OrderMetaDataProvider,
    ShippingMethodContextProvider,
    LogisticContextProvider,
  ];
  return (
    <>
      {providersArray.reduce((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
}

export default WithContextProviders;
