import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import SectionHeading from "../../../components/SectionHeading";
import PropTypes from "prop-types";
import { Button, Spin } from "antd";
import { CONSTANTS as C } from "config";
import GoogleMap from "components/GoogleMap";
import { useTranslation } from "react-i18next";
import { STOTE_UTILS } from "utils";

const StoreMapSection = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    let objValid = STOTE_UTILS.VALIDATE_INPUTS_STEP2(props.store.values);

    if (objValid.isFormValid) {
      props.setStep((step) => step + 1);
    }
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <div
          className="bg-white"
          style={{ height: "80vh", display: "flex", flexDirection: "column" }}
        >
          <SectionHeading title={t("Store Location")} />
          <div className="px-2 md:px-6 py-4">
            <TextInput
              label={t("Enter Complete Address")}
              mandatory={true}
              value={props.store.values[C.KEY_MANUAL_ADDRESS]}
              handleValues={props.handleValues}
              keyVal={C.KEY_MANUAL_ADDRESS}
              error={props.store.errors[C.KEY_MANUAL_ADDRESS]}
            />
          </div>
          <div className="w-full relative">
            <GoogleMap
              handleValues={props.handleValues}
              width={"90%"}
              height={"450px"}
              state={props.store}
              setState={props.setStore}
              setLoading={setLoading}
              zoom={props.zoom}
            />
          </div>
        </div>
        <div className="w-full text-center mb-4 mt-4">
          {props.type === "create" ? (
            <Button
              type="primary"
              size="large"
              className="w-36"
              onClick={handleSubmit}
            >
              {t("Next")}
            </Button>
          ) : null}
        </div>
      </Spin>
    </React.Fragment>
  );
};
StoreMapSection.propTypes = {
  store: PropTypes.object,
  handleValues: PropTypes.func,
};
export default StoreMapSection;
