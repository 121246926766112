import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import SearchArea from "../common/Search";
import PriceInput from "components/useForm/PriceInput";
import CitiesList from "../areas/components/CitiesList";
import { UTILS } from "utils";
import { API } from "api";
import { useSelector, useDispatch } from "react-redux";
import { AreasAction } from "redux/actions";
import ChooseTime from "../common/ChooseTime";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import TextInput from "components/TextInput";
const AddNewAreas = ({ onClose }) => {
  const dispatch = useDispatch();
  const [openAreas, setOpenAreas] = useState(false);
  const { selectedAreas, areaData, customAllAreas, selectedStore } =
    useSelector((state) => state.AreasReducer);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const { t } = useTranslation();
  const [searchTerm, setSearch] = useState("");
  const [state, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    deliveryTime: "",
  });
  const [time, setTime] = useState({
    Hours: 0,
    Minutes: 0,
    Days: 0,
  });
  const handleValues = async (key, value) => {
    setState({ ...state, [key]: value });
  };

  const getUpdatedAreas = UTILS.withTryCatch(async (storeID) => {
    const userDetail = UTILS.getCurrentUser();
    const updateAreas = await UTILS.UpdatedAreas(
      storeID,
      userDetail.brandId,
      userDetail.token
    );
    const filteredAreasList = await UTILS.filteredAreasList(
      areaData,
      updateAreas
    );
    dispatch(AreasAction.setAddedAreas(updateAreas));
    dispatch(AreasAction.setcustomeAddedAreas(filteredAreasList));
    onClose();
    dispatch(LoaderAction.setLoading(false));
  });

  let isValid = () => {
    if (
      isLoading ||
      state.minOrder === "" ||
      state.deliveryFee === "" ||
      selectedAreas?.length <= 0 ||
      (time?.Days === 0 && time?.Hours === 0 && time?.Minutes === 0)
    )
      return false;
    return true;
  };

  const addAreas = UTILS.withTryCatch(async () => {
    dispatch(LoaderAction.setLoading(true));
    let delTime = time.Days * 1440 + time.Hours * 60 + time.Minutes;
    const storeID = selectedStore.value;
    const userDetail = UTILS.getCurrentUser();
    let objectArray = [];
    selectedAreas?.forEach((area) => {
      objectArray.push({
        cityID: area.cityID,
        areaID: area._id,
        countryID: area.countryID,
        storeId: storeID,
        brandId: userDetail.brandId,
      });
    });

    const result = await API.ADD_STORE_AREA({
      areas: objectArray,
      minOrder: Number(state.minOrder),
      deliveryFee: Number(state.deliveryFee),
      deliveryTime: Number(delTime),
      token: userDetail.token,
      language: currentLanguage,
    });
    if (result?.success === 1) {
      UTILS.successNotification(t(result.message));
      dispatch(AreasAction.updateSelectedArea([]));
    } else {
      dispatch(LoaderAction.setLoading(false));
      UTILS.errorNotification(t(errorHandler(result)));
    }
    getUpdatedAreas(storeID);
  });

  const handleSearch = (key, val) => {
    setSearch(val.toLowerCase());
    if (val !== "") {
      setOpenAreas(true);
    } else {
      setOpenAreas(false);
    }
  };

  const handleInputValue = (key, val) => {
    const value = UTILS.NumberConversion(val);
    handleValues(key, value);
  };

  return (
    <div className="w-full p-2 sm:p-5 mx-auto my-auto  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            <div className="flex justify-between">
              <div>
                <h2 className="text-19px font-bold mb-3 py-1 px-2">
                  {t("Add delivery areas")}
                </h2>
              </div>
              <div className="cursor-pointer" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-6 h-6 bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="px-2  w-full sm:w-1/2 register-page">
                <TextInput
                  label={t("Min. Basket Value")}
                  value={state.minOrder.trim()}
                  keyVal={"minOrder"}
                  handleValues={handleInputValue}
                  mandatory={true}
                />
              </div>
              <div className="px-2 w-full sm:w-1/2 register-page">
                <PriceInput
                  label={t("Delivery Fee")}
                  value={state.deliveryFee.trim()}
                  keyVal={"deliveryFee"}
                  handleValues={handleValues}
                  mandatory={true}
                />
              </div>
            </div>
            <div className="flex flex-wrap px-2">
              <ChooseTime {...{ time, setTime }} />
            </div>
            <div className="border border-gray-200 mx-2 my-2">
              <SearchArea
                value={searchTerm}
                keyVal={"searchTerm"}
                handleValues={handleSearch}
                setOpenAreas={setOpenAreas}
                placeholder="search area by name"
              />
              <hr className=" border-b-1 border-gray-200" />
              <div className="max-h-60 overflow-y-scroll">
                {areaData?.map((country) => {
                  return country?.cities?.map((city, index) => {
                    console.log("city", city);
                    return (
                      <CitiesList
                        openAreas={openAreas}
                        searchTerm={searchTerm}
                        key={index}
                        city={city}
                        cityAreas={UTILS.getAreasByCityID(
                          city?._id,
                          customAllAreas,
                          currentLanguage
                        )}
                      />
                    );
                  });
                })}
              </div>
            </div>
          </div>
          <div className="flex justify-between  items-center px-3">
            <div className="text-gray-500 underline max-w-40">
              {t("I can't find an area (please contact support)")}
            </div>
            <div>
              <Button
                type="primary"
                size="large"
                onClick={addAreas}
                disabled={!isValid()}
              >
                {t("Add Areas")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

AddNewAreas.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddNewAreas;
