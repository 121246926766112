import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { SVG_DELETE_DIALOG } from "svg/SVGs";
import { useMenuContext } from "store/contexts/Menus";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { useParams } from "react-router-dom";
import { logToSentry } from "utils/Utils";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";

const DeleteCategory = ({ handleValues, onClose, cat, index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const { menuState, updateState } = useMenuContext();
  console.log("DeleteCategory -> cat", menuState);
  const deleteCategory = async () => {
    try {
      if (!cat?.categoryId?._id) {
        logToSentry(
          `deleteCategory issue ${JSON.stringify(menuState)}`,
          "error"
        );
        window.location.reload();
        UTILS.errorNotification("Please try again later");
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const result = await API.unLinkCategoryWithMenu({
        brandId: UTILS.getCurrentUser()?.brandId,
        menuId: params?.menuId || menuState.menuId,
        categoryId: cat?.categoryId?._id,
      });

      if (result?.success === 1) {
        handleValues(index);
        updateState("indexToUpdateDelete", index);
        UTILS.successNotification(result?.data?.message || "Category unlinked");
        onClose();
      } else {
        UTILS.errorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return (
    <div className="w-full p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      <div className="">
        <Spin spinning={isLoading}>
          <div className="text-center p-5 flex-auto justify-center">
            <SVG_DELETE_DIALOG />
            <h2 className="text-xl font-bold py-4 ">{t("Delete Category")}</h2>
            <h2 className="text-19px font-bold py-2 text-indigo-600 ">
              {cat?.categoryId?.baseLanguage?.name}
            </h2>
            <p className="text-sm text-gray-500 px-8">
              {t("Do you really want to delete? This process cannot be undone")}
            </p>
          </div>
          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <Button type="default" size="large" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="danger" size="large" onClick={deleteCategory}>
              {t("Delete")}
            </Button>
          </div>
        </Spin>
      </div>
    </div>
  );
};

DeleteCategory.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
  cat: PropTypes.object,
  index: PropTypes.number,
};
export default DeleteCategory;
