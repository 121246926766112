import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardOutlined,
  HistoryOutlined,
  FormatPainterOutlined,
  MenuUnfoldOutlined,
  FileTextOutlined,
  EditOutlined,
  EyeOutlined,
  InstagramOutlined,
  BorderOuterOutlined,
  AimOutlined,
  DollarCircleOutlined,
  SubnodeOutlined,
  SettingOutlined,
  LogoutOutlined,
  InfoCircleOutlined,
  UserOutlined,
  AliwangwangOutlined,
  DatabaseOutlined,
  RocketOutlined,
  MoneyCollectOutlined,
  FileDoneOutlined,
  TrophyOutlined,
  ShopOutlined,
  SoundOutlined,
  ScanOutlined,
  InteractionOutlined,
  UngroupOutlined,
  CompressOutlined,
  PicRightOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { SVG_LIVE_ORDER } from "svg/SVGs";
import { UTILS } from "utils";
import { PackageUtil } from "utils";
import { useTranslation } from "react-i18next";
import useUserType from "hooks/useUserType";
import useInterval from "use-interval";
import "moment-timezone";
import { CONSTANTS as C } from "config";
import { useGetSettingsMutation } from "redux/settings/settingsSlice";
import { LogoutAction } from "redux/actions";
import { PkgActions } from "redux/actions";
import { Feedback } from "@material-ui/icons";
import { MenuBook } from "@material-ui/icons";
const { SubMenu } = Menu;
const DashboardMenu = ({ setVisible }) => {
  const dispatch = useDispatch();
  const [getSettings] = useGetSettingsMutation();
  const { expDays } = useSelector((state) => state.PackagesReducer);
  const [isEnableMenu, setIsEnableMenu] = useState(false);
  const userType = useUserType();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.LoginReducer);

  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const logout = () => {
    // Notify other tabs about the logout
    localStorage.setItem("logoutEvent", Date.now().toString());
    localStorage.clear();
    UTILS.removeCurrentUser();
    UTILS.removeLanguageInformation();
    window.location.href = "/login";
    dispatch(LogoutAction.logout());
  };
  const check_PackageValidity = async () => {
    const { result } = await UTILS.checkPackageValidity(currentLanguage);
    if (result?.data) {
      const diff = PackageUtil.getPackageExpiry(result.data.subscription);
      dispatch(PkgActions.setDays(diff));
    }
  };
  useEffect(() => {
    check_PackageValidity();
  }, [isLoggedIn]);

  useInterval(() => {
    check_PackageValidity();
  }, C.SIXHOURS);
  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/"
      : location.pathname
  );
  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);
  const onMenuClick = (key) => {
    setVisible(false);
    setCurrent(key);
  };

  const openShop = async () => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
    };
    const data = await getSettings(obj).unwrap();

    if (data?.success === 1 && data?.data?.setting) {
      const userDetail = await UTILS.getCurrentUser();
      let url = `${userDetail.storeFrontURL}${data?.data?.setting?.temporaryDomainName}`;
      window.open(url, "_blank");
    }
  };

  if (expDays <= 5) {
    return (
      <div className="flex flex-col justify-between nav-height bgnavbar">
        <div>
          <Menu
            theme="dark"
            selectedKeys={[current]}
            defaultSelectedKeys={["/"]}
            mode="vertical"
            onClick={onMenuClick}
          >
            {process.env.REACT_APP_COMPANY_NAME !==
              C.COMPANY_NAMES.CUSINETTE && (
              <Menu.Item
                align="initial"
                key="/app/dashboard/onboarding"
                icon={<RocketOutlined />}
              >
                <Link className="ps-3 ml-0" to={"/app/dashboard/onboarding"}>
                  {t("Get Started")}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item
              align="initial"
              key="/app/dashboard/liveorders"
              icon={<SVG_LIVE_ORDER />}
              disabled={isEnableMenu}
            >
              <Link className="ps-3 ml-0" to={"/app/dashboard/liveorders"}>
                {t("Live Orders")}
              </Link>
            </Menu.Item>
            <SubMenu
              key="sub1-1"
              align="initial"
              title={t("Operations")}
              icon={<DatabaseOutlined />}
              disabled={isEnableMenu}
            >
              <Menu.Item
                key="/app/dashboard/inventory"
                align="initial"
                icon={<InfoCircleOutlined />}
                disabled={isEnableMenu}
              >
                <Link className="ps-3 ml-0" to={"/app/dashboard/inventory"}>
                  {t("Item Availability")}
                </Link>
              </Menu.Item>
              <Menu.Item
                key="/app/dashboard/pause-modifier"
                align="initial"
                icon={<PicRightOutlined />}
              >
                <Link
                  className="ps-3 ml-0"
                  to={"/app/dashboard/pause-modifier"}
                >
                  {t("Modifier Availability")}
                </Link>
              </Menu.Item>
              <Menu.Item
                key="/app/dashboard/orderhistory"
                align="initial"
                icon={<HistoryOutlined />}
                disabled={isEnableMenu}
              >
                <Link className="ps-3 ml-0" to={"/app/dashboard/orderhistory"}>
                  {t("Order History")}
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub2-2"
              align="initial"
              title={t("Marketing")}
              icon={<SoundOutlined />}
              disabled={isEnableMenu}
            >
              {userType === "BRAND-ADMIN" && (
                <>
                  <Menu.Item
                    key="/app/dashboard/discount"
                    align="initial"
                    icon={<MoneyCollectOutlined />}
                    disabled={isEnableMenu}
                  >
                    <Link className="ps-3 ml-0" to={"/app/dashboard/discount"}>
                      {t("Discounts")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/app/dashboard/add-update-brand-loyalty"
                    align="initial"
                    icon={<TrophyOutlined />}
                    disabled={isEnableMenu}
                  >
                    <Link
                      className="ps-3 ml-0"
                      to={"/app/dashboard/add-update-brand-loyalty"}
                    >
                      {t("Loyalty")}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/app/dashboard/brand-customers"
                    align="initial"
                    icon={<UserOutlined />}
                    disabled={isEnableMenu}
                  >
                    <Link
                      className="ps-3 ml-0"
                      to={"/app/dashboard/brand-customers"}
                    >
                      {t("Customers")}
                    </Link>
                  </Menu.Item>
                </>
              )}
              <Menu.Item
                key="/app/dashboard/marketing"
                align="initial"
                icon={<InstagramOutlined />}
                disabled={isEnableMenu}
              >
                <Link className="ps-3 ml-0" to={"/app/dashboard/marketing"}>
                  {t("Social Posting")}
                </Link>
              </Menu.Item>

              <Menu.Item
                key="/app/dashboard/integrations"
                align="initial"
                icon={<BorderOuterOutlined />}
                disabled={isEnableMenu}
              >
                <Link className="ps-3 ml-0" to={"/app/dashboard/integrations"}>
                  {t("Integrations")}
                </Link>
              </Menu.Item>

              <Menu.Item
                key="/app/qr-codes"
                align="initial"
                icon={<ScanOutlined />}
                disabled={isEnableMenu}
              >
                <Link className="ps-3 ml-0" to={"/app/qr-codes"}>
                  {t("QR Codes")}
                </Link>
              </Menu.Item>
            </SubMenu>
            {userType === "BRAND-ADMIN" && (
              <SubMenu
                key="sub3-3"
                align="initial"
                title={t("Products/Menu")}
                icon={<MenuUnfoldOutlined />}
                disabled={isEnableMenu}
              >
                <Menu.Item
                  key="/app/dashboard/items"
                  align="initial"
                  icon={<PicRightOutlined />}
                >
                  <Link className="ps-3 ml-0" to={"/app/dashboard/items"}>
                    {t("Items")}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="/app/dashboard/modifiers-list"
                  align="initial"
                  icon={<PicRightOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to="/app/dashboard/modifiers-list"
                  >
                    {t("Modifiers")}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="/app/dashboard/modifier-group"
                  align="initial"
                  icon={<PicRightOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to="/app/dashboard/modifier-group"
                  >
                    {t("Modifier Groups")}
                  </Link>
                </Menu.Item>
                {/* <Menu.Item
                  key="/app/dashboard/menu"
                  align="initial"
                  icon={<EditOutlined />}
                >
                  <Link className="ps-3 ml-0" to={"/app/dashboard/menu"}>
                    {t("Product/Menu Editor")}
                  </Link>
                </Menu.Item> */}

                <Menu.Item
                  key="/app/dashboard/menu-list"
                  align="initial"
                  icon={<MenuBook />}
                >
                  <Link className="ps-3 ml-0" to="/app/dashboard/menu-list">
                    {t("Menus")}
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}
            {userType === "BRAND-ADMIN" && (
              <SubMenu
                key="sub4-4"
                align="initial"
                title={t("Reports")}
                icon={<FileDoneOutlined />}
                disabled={isEnableMenu}
              >
                <Menu.Item
                  key="/app/dashboard/reporting/orders"
                  align="initial"
                  icon={<FileTextOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to={"/app/dashboard/reporting/orders"}
                  >
                    {t("Order History")}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="/app/dashboard/reporting/customers"
                  align="initial"
                  icon={<EditOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to={"/app/dashboard/reporting/customers"}
                  >
                    {t("Customer Report")}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="/app/dashboard/reporting/payments"
                  align="initial"
                  icon={<EditOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to={"/app/dashboard/reporting/payments"}
                  >
                    {t("Payment Links History")}
                  </Link>
                </Menu.Item>
                {/* <Menu.Item
                  key="/app/dashboard/reporting/payouts"
                  align="initial"
                  icon={<EditOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to={"/app/dashboard/reporting/payouts"}
                  >
                    {t("Payouts History")}
                  </Link>
                </Menu.Item> */}
                <Menu.Item
                  key="/app/dashboard/reporting/user-feedbacks"
                  align="initial"
                  icon={<Feedback />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to="/app/dashboard/reporting/user-feedbacks"
                  >
                    {t("Order Feedbacks")}
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}

            <Menu.Item
              align="initial"
              key="/app/dashboard"
              icon={<DashboardOutlined />}
            >
              <Link className="ps-3 ml-0" to={"/app/dashboard"}>
                {t("Dashboard")}
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/app/dashboard/shipping"
              align="initial"
              icon={<AimOutlined />}
              disabled={isEnableMenu}
            >
              <Link className="ps-3 ml-0" to={"/app/dashboard/shipping"}>
                {t("Shipping & Delivery")}
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/app/dashboard/payment-links"
              align="initial"
              icon={<DollarCircleOutlined />}
              disabled={isEnableMenu}
            >
              <Link className="ps-3 ml-0" to="/app/dashboard/payment-links">
                {t("Payment Links")}
              </Link>
            </Menu.Item>
          </Menu>
        </div>
        <div>
          <Menu
            theme="dark"
            selectedKeys={[current]}
            defaultSelectedKeys={["/"]}
            mode="vertical"
            onClick={onMenuClick}
          >
            {process.env.REACT_APP_COMPANY_NAME !==
              C.COMPANY_NAMES.CUSINETTE && (
              <Menu.Item
                key="/app/dashboard/subscription"
                align="initial"
                icon={<SubnodeOutlined />}
                style={{ marginTop: 90 }}
                disabled={isEnableMenu}
              >
                <Link className="ps-3 ml-0" to={"/app/dashboard/subscription"}>
                  {t("Subscription")}
                </Link>
              </Menu.Item>
            )}
            <SubMenu
              key="sub6-6"
              align="initial"
              title={t("Settings")}
              icon={<SettingOutlined />}
              disabled={isEnableMenu}
            >
              {userType === C.USER_TYPE_ADMIN && (
                <Menu.Item
                  key="/app/Ordering/page"
                  align="initial"
                  icon={<EyeOutlined />}
                >
                  <span onClick={openShop} className="ps-3 ml-0 cursor-pointer">
                    {t("View Ordering Page")}
                  </span>
                </Menu.Item>
              )}
              {userType === C.USER_TYPE_ADMIN && (
                <Menu.Item
                  key="/app/dashboard/stores"
                  align="initial"
                  icon={<ShopOutlined />}
                  disabled={isEnableMenu}
                >
                  <Link className="ps-3 ml-0" to={"/app/dashboard/stores"}>
                    {t("Stores")}
                  </Link>
                </Menu.Item>
              )}
              {userType === C.USER_TYPE_ADMIN && (
                <Menu.Item
                  key="/app/dashboard/settings/branding"
                  align="initial"
                  icon={<FormatPainterOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to={"/app/dashboard/settings/branding"}
                  >
                    {t("Branding")}
                  </Link>
                </Menu.Item>
              )}
              {userType === C.USER_TYPE_ADMIN && (
                <Menu.Item
                  key="/app/dashboard/settings/business"
                  align="initial"
                  icon={<SettingOutlined />}
                >
                  <Link
                    className="ps-3 ml-0"
                    to={"/app/dashboard/settings/business"}
                  >
                    {t("Business settings")}
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item
                key="/app/dashboard/settings/profile"
                align="initial"
                icon={<UserOutlined />}
              >
                <Link
                  className="ps-3 ml-0"
                  to={"/app/dashboard/settings/profile"}
                >
                  {t("My Profile")}
                </Link>
              </Menu.Item>
            </SubMenu>

            {/* <Menu.Item
          key="/app/dashboard/customers"
          align="initial"
          icon={<UsergroupDeleteOutlined />}
          disabled={isEnableMenu}
        >
          <Link className="ps-3 ml-0" to={"/app/dashboard/customers"}>
            {t("Customers")}
          </Link>
        </Menu.Item>  */}

            {userType === "BRAND-ADMIN" ? (
              <Menu.Item
                key="/app/dashboard/userroles"
                align="initial"
                icon={<UserOutlined />}
                disabled={isEnableMenu}
              >
                <Link className="ps-3 ml-0" to={"/app/dashboard/userroles"}>
                  {t("User Roles")}
                </Link>
              </Menu.Item>
            ) : null}

            {process.env.REACT_APP_COMPANY_NAME !==
              C.COMPANY_NAMES.CUSINETTE && (
              <Menu.Item
                key="/app/dashboard/help"
                align="initial"
                icon={<AliwangwangOutlined />}
              >
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://wa.me/971527325000?text=How can we help?"
                  className="ps-3 ml-0"
                >
                  {t("Need help")}
                </a>
              </Menu.Item>
            )}
            <Menu.Item
              key="/app/dashboard/logout"
              align="initial"
              icon={<LogoutOutlined />}
              onClick={() => logout()}
            >
              <span className="ps-3">{t("Logout")}</span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  } else {
    return (
      <Menu
        theme="dark"
        selectedKeys={[current]}
        defaultSelectedKeys={["/"]}
        mode="vertical"
        onClick={onMenuClick}
      >
        {process.env.REACT_APP_COMPANY_NAME !== C.COMPANY_NAMES.CUSINETTE && (
          <Menu.Item
            key="/app/dashboard/subscription"
            align="initial"
            icon={<SubnodeOutlined />}
            style={{ marginTop: 10 }}
            disabled={isEnableMenu}
          >
            <Link className="ps-3 ml-0" to={"/app/dashboard/subscription"}>
              {t("Subscription")}
            </Link>
          </Menu.Item>
        )}
        <Menu.Item
          key="/app/dashboard/logout"
          align="initial"
          icon={<LogoutOutlined />}
          onClick={() => logout()}
        >
          {t("Logout")}
        </Menu.Item>
      </Menu>
    );
  }
};
export default DashboardMenu;
