import React from "react";
import { SVG_DELETE_DIALOG } from "svg/SVGs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
const DeleteComponent = (props) => {
  const { t } = useTranslation();
  const getTitle = () => {
    const arr = [null, undefined, "", "null", "undefined"];
    if (arr?.includes(props?.title)) return "";
    else return props.title;
  };
  return (
    <Spin spinning={props.loading}>
      <div className="w-full p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
        <div className="">
          <div className="text-center p-5 flex-auto justify-center">
            <SVG_DELETE_DIALOG />
            <h2 className="text-xl font-extrabold py-4 ">
              {t("Are you sure ?")}
            </h2>
            <p className="px-8">
              <span className="font-bold mx-2">{getTitle()}</span>
              <span>{props?.Heading}</span>
            </p>
          </div>
          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <Button onClick={props.onCancel} type="default" size="large">
              {t("Cancel")}
            </Button>
            <Button size="large" type="danger" onClick={props.onDelete}>
              {t("Delete")}
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};
DeleteComponent.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};
export default DeleteComponent;
