import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BackArrow from "features/icons/BackArrow";
export const BackButton = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  const back = () => {
    router.push("/app/dashboard/payment-links");
  };
  return (
    <div className="my-5">
      <div
        className="cursor-pointer inline-block"

        // onClick={back}
      >
        <span className="align-middle">
          <BackArrow onClick={back} />
        </span>
        <span className="align-middle px-2 text-gray-500">
          {t("Payment Links")}
        </span>
      </div>
    </div>
  );
};
