import React from "react";
import { Table, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "components/Card";

const CustomersTable = ({
  customers,
  handlePageChange,
  handSearch,
  searchTerm,
}) => {
  const { t } = useTranslation();
  const router = useHistory();

  const viewDetail = (row) =>
    router.push("/app/dashboard/customer-wallet-history", row);

  const renderMobileNumber = (value, row, index) => {
    return (
      <div>
        <p className="m-0" dir="ltr">
          {` +${row.countryCode}${value}`}
        </p>
      </div>
    );
  };
  const actionButton = (value, row, index) => {
    return (
      <div>
        <Button
          type="primary"
          size="medium"
          className="w-36"
          onClick={() => viewDetail(row)}
        >
          {t("View details")}
        </Button>
      </div>
    );
  };
  const columns = [
    {
      title: t("Customer Name"),
      dataIndex: "name",
      key: "name",
      render: (value, row, index) => {
        return (
          <div>
            <p className="m-0">{value || t("N/A")}</p>
          </div>
        );
      },
    },
    {
      title: t("Whatsapp Number"),
      dataIndex: "phoneNumber",
      key: "mobileNumber",
      render: renderMobileNumber,
    },

    {
      title: t("Lifetime Spending"),
      dataIndex: "lifetimeSpending",
      key: "lifetimeSpending",
      render: (value, row, index) => {
        return (
          <div>
            {value ? (
              <p className="m-0" dir="ltr">
                {t("AED")} {parseFloat(value)?.toFixed(2) || 0}
              </p>
            ) : (
              <p className="m-0" dir="ltr">
                {t("AED")} 0
              </p>
            )}
          </div>
        );
      },
    },

    {
      title: t("Actions"),
      dataIndex: "action",
      key: "action",
      render: actionButton,
    },
  ];

  return (
    <div className="p-4">
      <div className="flex justify-between items-center flex-col sm:flex-row mb-5">
        <div>
          <h1 className="text-gray-900 text-24px sm:text-30px font-normal ">
            {t("My customers")}
          </h1>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="group relative  sm:mt-0">
            <svg
              fill="currentColor"
              className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              ></path>
            </svg>
            <input
              className="focus:outline-none appearance-none w-full text-14px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm min-290"
              type="text"
              placeholder={t("Search by whatsapp number")}
              value={searchTerm}
              autoComplete="off"
              onChange={handSearch}
            />
          </div>
        </div>
      </div>

      <Card styleClasses="px-2 py-2">
        <div className="overflow-hidden overflow-x-auto lg:overflow-hidden">
          <Table
            locale={{ emptyText: t("No Data") }}
            columns={columns}
            dataSource={customers?.data || []}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 20,
              total: customers.totalrecords,
              showQuickJumper: false,
              showSizeChanger: false,
              current: customers.currentPage,
            }}
            size="small"
            rowKey={(record) => record._id}
            onChange={(pagination, filters, sorter) => {
              handlePageChange(pagination.current);
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default CustomersTable;
