import "moment-timezone";
import moment from "moment";
export const getPackageExpiry = (subscription) => {
  let differnce = 0;
  if (subscription?.amountPaid < 1 && subscription?.isValid) {
  } else if (!subscription?.isValid) {
    const endDate = moment.unix(subscription?.endDate).tz("UTC");
    const diff = moment(moment().valueOf()).diff(endDate, "days");

    differnce = diff;
  }
  return differnce;
};
