import React from "react";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import DeliveryService from "./DeliveryService";
const TalabatComponent = ({
  order,
  getOrderInfoById,
  orderShared,
  checkIfDeliverySelected,
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const isSelected = UTILS.getSelectedDelivery(order);
  return (
    <>
      <Modal
        open={open}
        modalWidth=" min-h-300 rounded-md border border-gray-300 top-0 w-full lg:w-2/3"
        onClose={() => {}}
      >
        <DeliveryService
          order={order}
          getOrderInfoById={getOrderInfoById}
          orderShared={orderShared}
          setOpen={setOpen}
        />
      </Modal>

      <button
        className={`px-4 py-2 me-2 my-2  text-white rounded ${
          isSelected === "TALABAT" ? "bg-green-600" : "bg-gray-600"
        }`}
        onClick={() => checkIfDeliverySelected("TALABAT", setOpen)}
      >
        <span className={` ${isSelected === "TALABAT" ? "blink" : ""}`}>
          {t("Deliver Using Talabat")}
        </span>
      </button>
    </>
  );
};

export default TalabatComponent;
