import React from "react";
import { UTILS } from "utils";
const OrderButton = (props) => {
  const { btnText, handleDelivery, deliveyType, order } = props;
  const isSelected = UTILS.getSelectedDelivery(order);
  return (
    <button
      className={`px-4 py-2 me-2 my-2  text-white rounded ${
        isSelected === deliveyType ? "bg-green-600 " : "bg-gray-600"
      }`}
      onClick={() => handleDelivery(deliveyType)}
    >
      <span className={` ${isSelected === deliveyType ? "blink" : ""}`}>
        {btnText}
      </span>
    </button>
  );
};

export default OrderButton;
