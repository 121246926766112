import React from "react";

const getLabelHeading = (heading) => {
  if (heading) {
    return <p className="text-muted m-0 text-gray-400">{heading}</p>;
  }
};

const SimpleRadioButton = ({
  label,
  heading,
  handleValues,
  value,
  keyVal,
  checked,
  disabled,
  me,
}) => {
  const labelHeading = getLabelHeading(heading);
  return (
    <label
      className={`flex relative mb-2 md:mb-0 items-center radio ${
        me === true ? "pe-0" : "pe-6"
      }`}
    >
      <input
        disabled={disabled ? true : false}
        type="radio"
        name="value"
        className="h-0 w-0"
        value={value}
        onChange={(event) => {
          handleValues(keyVal, value);
        }}
      />
      <span
        className={`relative h-5 w-5 min-w-20px rounded-full bg-white  cursor-pointer  ${
          checked
            ? "select-checkbox border border-blue-500"
            : "rounded-full bg-gray-300"
        }`}
      ></span>
      {/* <span className="relative h-5 w-5 min-w-20px rounded-full bg-gray-300 cursor-pointer"></span> */}
      <div className="px-3">
        <p className="m-0">{label}</p>
        {labelHeading}
      </div>
    </label>
  );
};

export default SimpleRadioButton;
