import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
const getLabel = (label) => {
  if (label !== "") {
    return (
      <label className="font-montserrat text-gray-600 block text-14px">
        {label}
      </label>
    );
  }
};
const SelectInput = (props) => {
  const { t } = useTranslation();
  const mandatoryLabel = getLabel(props.label);
  return (
    <>
      {mandatoryLabel}
      <select
        disabled={props.disabled ? props.disabled : false}
        className={props.styleClass}
        onChange={(e) => props.handleChange(props.keyVal, e.target.value)}
        value={props.value ? props.value : null}
      >
        <option value="null" key={`0`} selected disabled>
          {t("Select")}
        </option>
        {props.options.map((option) => (
          <option
            value={option.value}
            key={option.value}
            selected={option.value === props.defaultSelection ? "selected" : ""}
          >
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

SelectInput.propTypes = {
  keyVal: PropTypes.string,
  label: PropTypes.string,
  styleClass: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  defaultSelection: PropTypes.string,
};
export default SelectInput;
