import React, { useState } from "react";
import { Button, Input, Spin } from "antd";
import { useUpdatePhoneNumberMutation } from "redux/settings/settingsSlice";
import { CONSTANTS as C } from "config";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
const ProfileNumberUpdate = () => {
  const { t } = useTranslation();
  const [updatePhoneNumber, { isLoading }] = useUpdatePhoneNumberMutation();
  const [state, setState] = useState({
    values: {
      mobileNumber: "",
      password: "",
    },
    errors: {
      mobileNumber: "",
      oldPassword: "",
    },
  });
  const handleChange = (value, name) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        mobileNumber: "",
        oldPassword: "",
      },
    }));
  };

  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
      },
      errors: {
        ...prevState.errors,
        [key]: value,
      },
    }));
  };

  const ErrorMessages = [
    t("Password is a required field"),
    t("Phone is a required field"),
    t("Please enter a valid phone number"),
  ];

  const validateInput = () => {
    let isValid = true;
    if (!state?.values?.password) {
      isValid = false;
      updateState(C.OLD_PASSWORD, ErrorMessages[0]);
    }
    if (!state?.values?.mobileNumber) {
      isValid = false;
      updateState(C.KEY_PHONE, ErrorMessages[1]);
    }
    if (!UTILS.validatePhoneNumber(state?.values?.mobileNumber)) {
      isValid = false;
      updateState(C.KEY_PHONE, ErrorMessages[2]);
    }
    return isValid;
  };
  const resetState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      values: {},
      errors: {},
    }));
  };
  const updateMyPhone = async () => {
    if (!validateInput()) {
      return;
    } else {
      const Number = UTILS.formatPhoneNumber(
        `+971${state.values.mobileNumber}`
      );
      const obj = {
        newPhoneNumber: Number,
        password: state.values.password,
      };
      const data = await updatePhoneNumber(obj).unwrap();
      if (data?.success === 1) {
        resetState();
      }
    }
  };
  const getNumber = () => {
    const userDetail = UTILS.getCurrentUser();
    return userDetail?.phoneNumber && userDetail?.phoneNumber?.length === 13
      ? userDetail?.phoneNumber?.slice(4, 13)
      : t("Enter new phone number");
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <h3 className="text-19px font-medium mt-16">
          {t("Change phone number")}
        </h3>
        <div className="pb-2 w-full mt-4">
          <div className="flex justify-between">
            <label className="text-gray-600 font-bold text-14px">
              {t("New phone number")}
            </label>
          </div>
          <div className="text-red-500">
            {state?.errors?.mobileNumber && state?.errors?.mobileNumber}
          </div>
          <div className="flex justify-between register-page">
            <Input
              type="text"
              addonBefore="971"
              size="large"
              min={0}
              maxLength={10}
              minLength={9}
              name="mobileNumber"
              compact
              placeholder={getNumber()}
              value={state?.values?.mobileNumber}
              className={`py-2 rounded ${
                state?.errors?.mobileNumber ? "border-theme-red" : ""
              }`}
              onChange={(event) => {
                handleChange(
                  UTILS.NumberConversion(event.target.value),
                  "mobileNumber"
                );
              }}
            />
          </div>
        </div>

        <div className="pb-2 w-full mt-4">
          <div className="flex justify-between">
            <label className="text-gray-600 font-bold text-14px">
              {t("Password")}
            </label>
          </div>
          <div className="text-red-500">
            {state?.errors?.oldPassword && state?.errors?.oldPassword}
          </div>
          <div className="flex justify-between">
            <Input.Password
              compact
              name="password"
              placeholder={t("Password")}
              value={state?.values?.password}
              className={`py-2 rounded ${
                state?.errors?.oldPassword ? "border-theme-red" : ""
              }`}
              onChange={(event) => {
                handleChange(event.target.value, "password");
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          <Button
            onClick={updateMyPhone}
            size="large"
            className="text-white border-blue-500 rounded bg-blue-500 px-8"
          >
            {t("Update")}
          </Button>
        </div>
      </Spin>
    </>
  );
};

export default ProfileNumberUpdate;
