import React from "react";
import { QRCode } from "react-qrcode-logo";
import { useTranslation } from "react-i18next";

function QRCodeGenerator({ id, orderPageLink, size = 170, qrCodeSetting }) {
  const { t } = useTranslation();
  return (
    <div className="">
      <QRCode
        id={id}
        value={orderPageLink}
        size={size}
        enableCORS={true}
        eyeColor={{
          outer: "#000000",
          inner: `#${qrCodeSetting?.innerColor}`,
        }}
        logoWidth={20}
        logoHeight={20}
        removeQrCodeBehindLogo={true}
        logoPadding={5}
        logoPaddingStyle="solid"
        qrStyle="dots"
      />
      <p className="text-gray-500 px-2 text-12px">
        {t("Scan with your phone to preview")}
      </p>
    </div>
  );
}

export default QRCodeGenerator;
