import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isNotificationBarVisible: false,
  selectedKey: "SEMI_ANNUALLY",
  expDays: 0,
  packageText: "",
};

const LanguageSlice = createSlice({
  name: "LanguageSlice",
  initialState,
  reducers: {
    setSelectedKey: (state, action) => {
      state.selectedKey = action.payload;
    },
    setNotifyVisible: (state, action) => {
      state.isNotificationBarVisible = action.payload;
    },
    setDays: (state, action) => {
      state.expDays = action.payload;
    },
    setPackText: (state, action) => {
      state.packageText = action.payload;
    },
  },
});

export const { setNotifyVisible, setSelectedKey, setDays, setPackText } =
  LanguageSlice.actions;
export default LanguageSlice.reducer;
