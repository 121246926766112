import { errorHandler } from "api/api/errorHandler";
import { useGetBrandMenusMutation } from "redux/menu/service";
import { UTILS } from "utils";
import { useMenuContext } from "store/contexts/Menus";
import { LoaderAction } from "redux/actions";
import { useDispatch } from "react-redux";

function useGetMenus() {
  const { updateState } = useMenuContext();
  const dispatch = useDispatch();
  const [getBrandMenus] = useGetBrandMenusMutation();
  const getAllMenus = async () => {
    dispatch(LoaderAction.setLoading(true));
    try {
      const userDetail = UTILS.getCurrentUser();
      const { data } = await getBrandMenus({
        brandId: userDetail.brandId,
      });

      if (data?.success === 1 && data?.data) {
        updateState("addedMenus", data.data);
      } else UTILS.errorNotification(errorHandler(data?.error?.data));
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return getAllMenus;
}

export default useGetMenus;
