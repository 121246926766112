import React from "react";
import AreaBox from "../common/AreaBox";
import BackButton from "components/BackButton";
import { useSelector } from "react-redux";
import { CONSTANTS as C } from "config";
import { useTranslation } from "react-i18next";
import * as R_CONST from "routes/Constants";
import { AREA, CITY, FLAG } from "svg/SVGs";
import { isAllowedToShowShippingMethod } from "../helpers";

const ManageDelivery = () => {
  const { CatalogueType } = useSelector((state) => state?.SettingsReducer);
  const { t } = useTranslation();
  const boxes = [
    {
      footerText: t("Add Areas"),
      url: R_CONST.ADD_AREA,
      methodName: C.SHIPPING_METHODS.AREA_BASED,
      icon: <AREA />,
      description: t("Preferable if you deliver to certain local areas"),
    },
    {
      footerText: t("Add Mapped Areas"),
      url: "/app/dashboard/mapped-area-method",
      methodName: C.SHIPPING_METHODS.MAPPED_AREA_BASED,
      icon: <AREA />,
      description: t(
        "Users will only be allowed to order from your defined areas. Choose delivery areas based on your coverage and convenience. The delivery fee can be customized for each area"
      ),
    },
    {
      footerText: t("Add Cities"),
      url: R_CONST.ADD_CITY,
      methodName: C.SHIPPING_METHODS.CITY_BASED,
      icon: <CITY />,
      description: t("Preferable if you deliver to certain cities"),
    },
    {
      footerText: t("Add Countries"),
      url: R_CONST.ADD_COUNTRY,
      methodName: C.SHIPPING_METHODS.COUNTRY_BASED,
      icon: <FLAG />,
      description: t("Preferable if you deliver to certain countries"),
    },
  ];
  // const showButton = (methodName) => {
  //   if (
  //     CatalogueType === C.OUTLET_BASED &&
  //     methodName !== C.SHIPPING_METHODS.AREA_BASED
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };
  return (
    <div className="pt-4 px-4 lg:px-8 bg-white h-full">
      <BackButton />
      <h2 className="text-21px mb-8 pt-6 ">{t("Delivery Methods")}</h2>
      <div className="flex flex-col w-full lg:w-2/3">
        {boxes?.map((box, index) => {
          let show = !isAllowedToShowShippingMethod(
            box.methodName,
            CatalogueType
          );
          return show ? (
            <AreaBox
              footerText={box.footerText}
              url={box.url}
              key={index}
              icon={box.icon}
              methodName={box.methodName}
              description={box.description}
            />
          ) : null;
        })}
      </div>
    </div>
  );
};

export default ManageDelivery;
