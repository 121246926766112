import React from "react";
import PropTypes from "prop-types";

const TextInputReadOnly = ({ label, value }) => {
  return (
    <>
      <label className="text-gray-600 block text-14px">{label}</label>
      <div className="font-montserrat text-16px text-input-color text-sm border border-grey-600 focus:ring-indigo-500 outline-none focus:border-indigo-500 block w-full border-gray-300 p-2">
        {value}
      </div>
    </>
  );
};

TextInputReadOnly.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
};

export default TextInputReadOnly;
