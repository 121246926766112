import LoadPreview from "features/menu/components/LoadPreview";
import LoadPreviewWithDevice from "features/menu/components/LoadPreviewWithDevice";
import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import { UTILS } from "utils";
import * as C from "config/Constants";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function OrderingPagePreview({ state, updateState }) {
  const params = useParams();

  const getStorePreviewURL = () => {
    const userDetail = UTILS.getCurrentUser();
    if (userDetail?.storeFrontURL && userDetail?.temporaryDomainName) {
      let url = userDetail?.storeFrontURL;
      return `${url}${userDetail?.temporaryDomainName}${C.PREVIEW}?menuId=${params?.menuId}`;
    }
  };

  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <div className="bg-gray-50 w-full xl:w-5/12 text-center justify-center mx-auto border-l border-gray-200">
      <div className="flex justify-center items-center bg-white border-b py-2.5 px-5">
        <div className="">
          <Button
            type="primary"
            size="large"
            icon={<ReloadOutlined style={{ fontSize: "120%" }} />}
            onClick={() => updateState("random", state.random + 1)}
          >
            {t("Refresh")}
          </Button>
        </div>
        <h1 className="w-2/4 text-indigo-600 font-bold text-17px pt-2">
          {t("Ordering Preview")}
        </h1>
      </div>
      <div className="flex w-full justify-center items-center mx-auto mb-8 mt-4">
        {width >= 768 ? (
          <LoadPreviewWithDevice
            classes="bg-white"
            random={state.random}
            width={"100%"}
            height={"100%"}
            getStorePreviewURL={getStorePreviewURL}
          />
        ) : (
          <LoadPreview
            classes="bg-white"
            random={state.random}
            width={"100%"}
            height={"800px"}
            getStorePreviewURL={getStorePreviewURL}
          />
        )}
      </div>
    </div>
  );
}

export default OrderingPagePreview;
