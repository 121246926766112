import React, { useState, useMemo, useEffect } from "react";
import { Table, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";

import RenderTime from "features/shipping_methods/common/RenderTime";
import { MAPPED_AREA_MODAL_ID } from "features/shipping_methods/constants";
import { API } from "api";
import { UTILS } from "utils";
import { errorHandler } from "api/api/errorHandler";
import RadioButton from "components/RadioButton";
import { LoaderAction } from "redux/actions";
import { useShippingContext } from "store/contexts/ShippingMethodContext";
import useGetAllZones from "../hooks/useGetAllZones";
import useToggleStatus from "../hooks/useToggleStatus";
import DeleteComponent from "components/DeleteConfirm";
function ZoneListing({
  setOpen,
  setDialogContentId,
  setSelectedDataRows,
  selectedDataRows,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const changeZoneStatus = useToggleStatus();
  const [searchTerm, setSearch] = useState("");
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    deleteItemIndex: null,
    item: null,
  });

  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { shippingState } = useShippingContext();
  const { getAddedDeliveryZones } = useGetAllZones();

  const deleteZone = async (zoneID) => {
    try {
      if (!zoneID) return;
      dispatch(LoaderAction.setLoading(false));

      const userDetail = UTILS.getCurrentUser();

      const response = await API.deleteDistanceBaseDeliveryZone({
        storeId: selectedStore.value,
        distanceBaseDeliveryMethodId: zoneID,
        brandId: userDetail.brandId,
      });
      if (response?.success === 1) {
        UTILS.successNotification(response?.message);
        await getAddedDeliveryZones(selectedStore?.value);
        setDeleteModal({ isOpen: false, deleteItemIndex: -1, item: null });
      } else {
        UTILS.errorNotification(errorHandler(response?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const toggleZoneStatus = async (row, val) => {
    const payload = {
      distanceBaseDeliveryMethodId: row?._id,
      isActive: !val,
    };
    changeZoneStatus({ payload, onClose: () => {} });
  };

  const RenderStatus = (val, row, index) => {
    return (
      <RadioButton
        key={row?._id}
        label={""}
        value={val}
        keyVal={val}
        handleValues={() => {
          toggleZoneStatus(row, val);
        }}
      />
    );
  };

  const RenderActions = (val, row, index) => {
    return (
      <div className="flex justify-center items-center">
        <Button
          type="primary"
          className="mx-2 h-10"
          onClick={() => {
            setOpen(true);
            setDialogContentId(MAPPED_AREA_MODAL_ID.EDIT_DISTANCE_MODAL);
            setSelectedDataRows(row);
          }}
        >
          {t("Edit")}
        </Button>

        <Button
          onClick={() => onClickDeleteButton(row?._id)}
          danger
          className="mx-2 h-10"
        >
          {t("Delete")}
        </Button>
      </div>
    );
  };
  const columns = [
    {
      key: "Status",
      title: t("Status"),
      dataIndex: "isActive",
      align: "center",
      width: "10%",
      render: RenderStatus,
    },
    {
      key: "name",
      title: t("Zone Name"),
      dataIndex: "name",
    },

    {
      key: "Distance",
      title: t("Distance"),
      dataIndex: "distance",
    },

    {
      key: "minOrder",
      title: t("Min. Basket Value"),
      dataIndex: "minOrder",
      sorter: {
        compare: (a, b) => a.minOrder - b.minOrder,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },

    {
      key: "deliveryFee",
      title: t("Delivery Fee"),
      dataIndex: "deliveryFee",
      sorter: {
        compare: (a, b) => a.deliveryFee - b.deliveryFee,
        multiple: 3,
      },
      render: (val) => {
        return new Intl.NumberFormat(currentLanguage, {
          style: "currency",
          currency: "AED",
        }).format(val);
      },
    },
    {
      key: "deliveryTime",
      title: t("Delivery Time"),
      dataIndex: "deliveryTime",
      sorter: {
        compare: (a, b) => a.deliveryTime - b.deliveryTime,
        multiple: 3,
      },
      render: (val, row, index) => {
        return <RenderTime {...{ val, row, index }} />;
      },
    },
    {
      key: "Actions",
      title: t("Actions"),
      dataIndex: "isActive",
      align: "center",
      width: "10%",
      render: RenderActions,
    },
  ];

  const FilteredZones = useMemo(() => {
    const lowerCasedSearchTerm = searchTerm?.toLowerCase();
    if (!lowerCasedSearchTerm) {
      return shippingState?.distanceBasedAreas;
    }
    return shippingState?.distanceBasedAreas?.filter((item) => {
      return item?.name?.toLowerCase()?.includes(lowerCasedSearchTerm);
    });
  }, [shippingState?.distanceBasedAreas, searchTerm]);

  const handSearch = (e) => setSearch(e.target.value);

  const zoneCount = FilteredZones?.length || 0;
  const zoneText = `${zoneCount} ${t(zoneCount <= 1 ? "Zone" : "Zones")}`;

  useEffect(() => {
    if (selectedStore?.value) {
      getAddedDeliveryZones(selectedStore?.value);
    }
  }, [selectedStore?.value]);

  const onClickDeleteButton = (id) => {
    const index = FilteredZones.findIndex((obj) => obj._id === id);
    if (index !== -1) {
      const item = FilteredZones[index];
      setDeleteModal({ isOpen: true, deleteItemIndex: index, item: item });
    }
  };

  const handleCancel = () => {
    setDeleteModal({ isOpen: false, deleteItemIndex: -1, item: null });
  };

  return (
    <div className="bg-white rounded shadow-sm border-gray-200 border px-2 py-2">
      <Spin spinning={isLoading}>
        <Modal
          open={deleteModal.isOpen}
          modalWidth="rounded-md border border-gray-300 top-0"
          onClose={handleCancel}
        >
          <DeleteComponent
            loading={isLoading}
            onCancel={handleCancel}
            onDelete={() => deleteZone(deleteModal.item?._id)}
            Heading={t("Are you sure you want to delete this zone?")}
            title={deleteModal.item?.baseLanguage?.title}
          />
        </Modal>
        {selectedStore && (
          <>
            <div className="flex justify-between my-4 items-center flex-row-reverse flex-col-reverse  sm:flex-row">
              <div className="w-full sm:w-auto text-start pt-4 sm:pt-0">
                <h2 className="font-bold p-1 align-middle text-16px sm:text-19px ">
                  {zoneText}
                </h2>
              </div>

              <div className="flex flex-col w-full sm:w-auto sm:flex-row">
                <div className="group relative mt-4 sm:mt-0">
                  <svg
                    fill="currentColor"
                    className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    />
                  </svg>
                  <input
                    onChange={handSearch}
                    value={searchTerm}
                    className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm"
                    type="text"
                    aria-label="Search area by name"
                    placeholder={t("search area by name")}
                  />
                </div>
              </div>
            </div>{" "}
            <div className="overflow-hidden overflow-x-auto md:overflow-hidden border-gray-200 border rounded-sm">
              <Table
                columns={columns}
                dataSource={FilteredZones}
                pagination={{ position: ["bottomCenter"], pageSize: 50 }}
                locale={{ emptyText: t("No Data") }}
                rowKey={(record) => record._id}
              />
            </div>
          </>
        )}
      </Spin>
    </div>
  );
}

export default ZoneListing;
