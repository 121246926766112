import React from "react";
import QRCodeGenerator from "./QRCodeGenerator";
import CopyLink from "./CopyLink";
import { Button } from "antd";
import { CloudDownloadOutlined } from "@material-ui/icons";
import DownloadButton from "./DownloadButton";
function PickupDelivery({
  settings,
  orderPageLink,
  handleLogoSettings,
  handleColorSettings,
  qrCodeSetting,
  handleDownload,
}) {
  return (
    <div className=" p-4 mt-4 w-full lg:w-1/2">
      <QRCodeGenerator
        id="pickup"
        orderPageLink={orderPageLink}
        settings={settings}
        handleLogoSettings={handleLogoSettings}
        handleColorSettings={handleColorSettings}
        qrCodeSetting={qrCodeSetting}
      />
      <CopyLink {...{ orderPageLink }} />
      <DownloadButton handleDownload={() => handleDownload("pickup")} />
    </div>
  );
}

export default PickupDelivery;
