import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { Info } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
function DeliveyFeeInfo({ order }) {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  console.log(currentLanguage);

  return (
    <>
      <>
        {order?.orderType?.type === "DELIVERY" ? (
          <>
            {order?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled ===
              true && order?.deliveryFee === 0 ? (
              <Tooltip
                color="black"
                placement={currentLanguage === "EN" ? "topRight" : "topLeft"}
                title={t(
                  "Because of the free delivery offer, delivery charges are waived"
                )}
              >
                <tr>
                  <td
                    colSpan="2"
                    // className="text-right py-2 text-gray-500 line-through cursor-pointer"
                    className={`${
                      currentLanguage === "EN" ? "text-right" : "text-left"
                    } py-2 text-gray-500 line-through cursor-pointer`}
                  >
                    <Info fontSize="small" className="mx-1" />
                    {t("Delivery Charges AED")}
                  </td>
                  <td
                    className={`${
                      currentLanguage === "EN" ? "text-right" : "text-left"
                    } py-2 px-2 line-through`}
                  >
                    {order?.deliveryFee}
                  </td>
                </tr>
              </Tooltip>
            ) : order?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled ===
                true && order?.deliveryFee === 0 ? (
              <Tooltip
                color="black"
                placement={currentLanguage === "EN" ? "topRight" : "topLeft"}
                title={t(
                  "Because of the free delivery offer, delivery charges are waived"
                )}
              >
                <tr>
                  <td
                    colSpan="2"
                    // className="text-right py-2 text-gray-500 line-through cursor-pointer"
                    className={`${
                      currentLanguage === "EN" ? "text-right" : "text-left"
                    } py-2 text-gray-500 line-through cursor-pointer`}
                  >
                    <Info fontSize="small" className="mx-1" />
                    {t("Delivery Charges AED")}
                  </td>
                  <td
                    className={`${
                      currentLanguage === "EN" ? "text-right" : "text-left"
                    } py-2 px-2 line-through`}
                  >
                    {order?.deliveryFee}
                  </td>
                </tr>
              </Tooltip>
            ) : (
              <>
                <td
                  colSpan="2"
                  // className="text-right py-2 text-gray-500"
                  className={`${
                    currentLanguage === "EN" ? "text-right" : "text-left"
                  } py-2 text-gray-500 `}
                >
                  {t("Delivery Charges AED")}
                </td>
                <td
                  className={`${
                    currentLanguage === "EN" ? "text-right" : "text-left"
                  } py-2 px-2`}
                >
                  {order?.deliveryFee}
                </td>
              </>
            )}
          </>
        ) : null}
      </>
    </>
  );
}

export default DeliveyFeeInfo;
