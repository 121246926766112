import { UTILS } from "utils";
import { findIndex } from "lodash";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";

function usePauseItem({
  setPauseModalState,
  updateState,
  getItemsByStoreId,
  state,
}) {
  const playPauseItem = async (row, duration) => {
    try {
      updateState("loading", true);
      const userDetail = UTILS.getCurrentUser();

      const result = await API.blockUnblockItemInStore({
        brandId: userDetail.brandId,
        storeId: state?.currentStoreId,
        itemId: row?._id,
        ...(duration && { duration }),
      });
      if (result?.success === 1) {
        if (Array.isArray(row?.unavailableStores) && row?.unavailableStores) {
          let arr = [...row?.unavailableStores];
          if (!row) {
            //add
            const store = {
              storeId: state?.currentStoreId,
            };
            arr.push(store);
            const index = findIndex(
              state?.items,
              (item) => item._id === row._id
            );
            let newArr = [...state?.items];
            newArr[index] = { ...newArr[index], unavailableStores: arr };
            updateState("items", newArr);
          } else {
            //remove
            arr.splice(
              arr.findIndex((id) => id === state?.currentStoreId),
              1
            );
            const index = findIndex(
              state?.items,
              (item) => item._id === row._id
            );
            let newArr = [...state?.items];
            newArr[index] = { ...newArr[index], unavailableStores: arr };
            updateState("items", newArr);
          }
        } else {
          await getItemsByStoreId(state?.currentStoreId);
        }
      } else {
        UTILS.handleErrorNotification(errorHandler(result?.data?.message));
      }
      await getItemsByStoreId(state?.currentStoreId);
      setPauseModalState("open", false);
      setPauseModalState("modifier", null);
      setPauseModalState("deafaultOption", {
        value: 6,
        unit: "hours",
        duration: "6_HOURS",
      });
    } catch (error) {
      console.log({ 666: error });
      UTILS.handleErrorNotification(errorHandler(error?.error));
    } finally {
      updateState("loading", false);
    }
  };

  const handlePlayPause = async (key, row) => {
    if (key === "pause") {
      setPauseModalState("open", true);
      setPauseModalState("modifier", row);
      return;
    } else if (!row._id || !state?.currentStoreId) return;
    playPauseItem(row);
  };

  return { playPauseItem, handlePlayPause };
}

export default usePauseItem;
