import React from "react";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  SVG_VIEW_EYE,
  SVG_DELETE_SMALL,
  SVG_EDIT_SMALL,
  SVG_DRAGDROP,
} from "svg/SVGs";

const ItemRow = ({ item, deleteItem, handleStores, index }) => {
  const router = useHistory();
  const { t } = useTranslation();

  const updateItem = (id) => {
    router.push("/app/dashboard/updateitem/", {
      itemId: id,
    });
  };

  return (
    <div
      key={item.itemId._id}
      className="cursor-pointer flex justify-between items-center px-1 md:px-6 xl:px-12 py-2 text-gray-700 border-b border-gray-200 text-14px"
    >
      <div className="w-5 h-5 me-4 mt-1.5 cursor-move">
        <SVG_DRAGDROP />
      </div>
      <div className="w-6/12 flex">
        <h3 className="m-0 p-0">{item.itemId.baseLanguage.title}</h3>
      </div>
      <div className="w-2/12 text-end">
        {item.itemId.price + " " + t("AED")}
      </div>
      <div className="w-4/12 flex justify-end">
        <Tooltip placement="topRight" title={t("Stores")}>
          <button
            className="mx-1 xl:mx-4"
            onClick={() => {
              handleStores(item, index);
            }}
          >
            <SVG_VIEW_EYE />
          </button>
        </Tooltip>
        <Tooltip placement="topRight" title={t("Edit")}>
          <button
            className="mx-1 xl:mx-4"
            onClick={() => updateItem(item.itemId._id)}
          >
            <SVG_EDIT_SMALL />
          </button>
        </Tooltip>
        <Tooltip placement="topRight" title={t("Remove")}>
          <button
            className="mx-1 xl:mx-4"
            onClick={() => deleteItem(item.itemId, index)}
          >
            <SVG_DELETE_SMALL />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
export default ItemRow;
ItemRow.propTypes = {
  item: PropTypes.object,
  deleteItem: PropTypes.func,
  handleStores: PropTypes.func,
  index: PropTypes.number,
};
