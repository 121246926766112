import React, { useState } from "react";
import { DatePicker, Space, Spin, Checkbox } from "antd";
import SimpleRadioButton from "components/SimpleRadioButton";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";
import useAllStores from "hooks/useCurrentUserStores";
import useAllItems from "hooks/useCurrentUserItems";
import moment from "moment";
import "moment-timezone";
import { CONSTANTS as C } from "config";
import { UTILS } from "utils";
const DiscountForm = (props) => {
  const { t } = useTranslation();
  const { stores, loading } = useAllStores();
  const [searchTerm, setSearch] = useState("");
  const zone = moment.tz.guess();
  moment.tz.setDefault(zone);
  const { items, Itemloader } = useAllItems();
  const { RangePicker } = DatePicker;

  const addStore = (store) => {
    if (props?.state?.values?.selectedOutlets?.includes(store)) {
      const newSelectedOutlets = props?.state?.values?.selectedOutlets?.filter(
        (item) => item !== store
      );
      props?.setState({
        ...props.state,
        values: {
          ...props.state.values,
          selectedOutlets: newSelectedOutlets,
        },
      });
    } else {
      props.setState({
        ...props.state,
        values: {
          ...props.state.values,
          selectedOutlets: [...props.state.values.selectedOutlets, store],
        },
      });
    }
  };
  const addItem = (sitem) => {
    if (props.state.values.selectedItems.includes(sitem)) {
      const newSelectedItems = props.state?.values?.selectedItems?.filter(
        (item) => item !== sitem
      );
      props.setState({
        ...props.state,
        values: {
          ...props.state.values,
          selectedItems: newSelectedItems,
        },
      });
    } else {
      props.setState({
        ...props.state,
        values: {
          ...props.state.values,
          selectedItems: [...props.state.values.selectedItems, sitem],
        },
      });
    }
  };
  const handleSetDate = (date, dateString) => {
    const momentTimezone = moment.tz.guess();
    const date1 = moment(date[0]).locale("en").tz(momentTimezone).format();
    const date2 = moment(date[1]).locale("en").tz(momentTimezone).format();

    props.setState({
      ...props.state,
      values: {
        ...props.state.values,
        startDate: dateString[0],
        endDate: dateString[1],
        isoDate: date1,
        isoEndDate: date2,
      },
    });
  };

  const handleChange = (key, val) => {
    props.setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };
 
  const handleDiscountCode = (key, val) => {
    if (val.match(/[a-z]/i)) {
      val = val.toLowerCase();
    }
    props.setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const getValue = (key) => {
    if (props?.state && UTILS.ifNotNullAndUndfine(props.state.values[key])) {
      return props.state.values[key];
    }
    if (key === "startDate") {
      return moment().format("YYYY-MM-DD, HH:mm");
    }
    if (key === "endDate") {
      return moment().add(1, "year").format("YYYY-MM-DD, HH:mm");
    }

    return "";
  };
  const Items = React.useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return items;
    }
    let searchTermLower = searchTerm.toLowerCase().trim();

    return items.filter((item) =>
      item?.baseLanguage?.title?.toLowerCase()?.includes(searchTermLower)
    );
  }, [items, searchTerm]);

  const handSearch = (e) => {
    setSearch(e.target.value);
  };
  const updatePrice = (key, val) => {
    const value = UTILS.NumberConversion(val);
    handleChange(key, value);
  };

  const handleServiceTypes = (key, val) => {
    const isAdded = props?.state?.values?.serviceType?.some(
      (value) => value?.serviceName === val?.serviceName
    );
    const newSelectedItems = isAdded
      ? props?.state?.values?.serviceType?.filter(
          (item) => item?.serviceName !== val.serviceName
        )
      : [...props?.state?.values?.serviceType, val];

    props.setState({
      ...props.state,
      values: {
        ...props?.state?.values,
        serviceType: newSelectedItems,
      },
      errors: {
        ...props?.state.errors,
        [key]: "",
      },
    });
  };

  const getBuisnessType = () => {
    const userDetail = UTILS.getCurrentUser();
    return userDetail?.catalogueType;
  };

  return (
    <div className="w-full sm:w-7/12 discount-form">
      <Spin spinning={loading || Itemloader}>
        <h1 className="text-blue-600 text-19px pt-4">
          {props?.text === "Add" ? t("Add a discount") : t("Update Discount")}
        </h1>
        <div className="lg:w-2/3">
          <div className="py-2">
            <TextInput
              label={t("Discount name")}
              placeHolder={t("E.g. Taco Tuesday!")}
              value={getValue("discountName")}
              keyVal={"discountName"}
              mandatory={true}
              error={props?.state?.errors?.discountName}
              handleValues={handleChange}
            />
          </div>

          <div className="py-2">
            <TextInput
              label={
                t("Discount code") +
                " " +
                t("Only small letters & numeric values are allowed")
              }
              placeHolder={t("E.g. happy100")}
              value={getValue("discountCode")}
              keyVal={"discountCode"}
              disabled={props?.text === "Add" ? false : true}
              mandatory={true}
              error={props?.state?.errors?.discountCode}
              handleValues={handleDiscountCode}
            />
          </div>

          <div className="my-12">
            <h3 className="mt-5 mb-4 text-17px">{t("Application")}</h3>
            <div className=" w-full py-1">
              <div className="flex mb-4 items-center">
                <div>
                  <SimpleRadioButton
                    label={t("")}
                    me={true}
                    value={C.COUPUN_APPLICATION_TYPE.MANUAL}
                    keyVal="applicationType"
                    handleValues={handleChange}
                    checked={
                      props?.state?.values?.applicationType ===
                      C.COUPUN_APPLICATION_TYPE.MANUAL
                    }
                  />
                </div>
                <div>
                  <h3 className="font-16px  ">{t("Manual")}</h3>
                  <p className="text-gray-600">
                    {t(
                      "Will be displayed on the store front, and customers will have to select it if they wish to use it"
                    )}
                  </p>
                </div>
              </div>
              <div className="flex mb-4 items-center">
                <div>
                  <SimpleRadioButton
                    label={t("")}
                    me={true}
                    keyVal="applicationType"
                    value={C.COUPUN_APPLICATION_TYPE.HIDDEN}
                    handleValues={handleChange}
                    checked={
                      props?.state?.values?.applicationType ===
                      C.COUPUN_APPLICATION_TYPE.HIDDEN
                    }
                  />
                </div>
                <div>
                  <h3 className="font-16px ">{t("Hidden Code")}</h3>
                  <p className="text-gray-600">
                    {t(
                      "Won’t be displayed on the store front, and customers will type the code at the checkout"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="my-12">
            <h3 className="mt-5 mb-4 text-17px">{t("Order type")}</h3>
            {
              <div className="flex">
                {[
                  {
                    label: t("DELIVERY"),
                    serviceName: "DELIVERY",
                  },
                  {
                    label: t("PICKUP"),
                    serviceName: "PICKUP",
                  },
                  ...(getBuisnessType() !== C.BRAND_BASED
                    ? [
                        {
                          label: t("DINEIN"),
                          serviceName: "DINE IN",
                        },
                      ]
                    : []),
                ].map((item, index) => (
                  <Checkbox
                    onClick={() => {
                      handleServiceTypes("orderType", {
                        serviceName: item?.serviceName,
                      });
                    }}
                    checked={props?.state?.values.serviceType.some(
                      (service) => service.serviceName === item?.serviceName
                    )}
                  >
                    {item?.label}
                  </Checkbox>
                ))}
              </div>
            }
          </div>

          <div className="my-12">
            <h3 className="mt-5 mb-4 text-17px">{t("Applicable outlets")}</h3>
            <div className="py-2 flex flex-wrap">
              <SimpleRadioButton
                label={t("All outlets")}
                keyVal="outletSelectionType"
                value="ALL"
                checked={props?.state?.values?.outletSelectionType === "ALL"}
                handleValues={handleChange}
              />
              <SimpleRadioButton
                label={t("Selected outlets")}
                keyVal="outletSelectionType"
                value={C.SELECTED}
                checked={
                  props?.state?.values?.outletSelectionType === C.SELECTED
                }
                handleValues={handleChange}
              />
            </div>
            {props?.state?.values?.outletSelectionType === C.SELECTED && (
              <div className="border border-gray-200 p-4 mt-4">
                {stores?.map((store, index) => (
                  <div
                    key={index}
                    className="flex w-full py-1"
                    onClick={() => {
                      addStore(store?.value);
                    }}
                  >
                    {props?.state?.values?.selectedOutlets?.includes(
                      store?.value
                    ) ? (
                      <div className="flex justify-between">
                        <span
                          className={`block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200 check`}
                        ></span>
                        <div className="px-2">{store?.label} </div>
                      </div>
                    ) : (
                      <div className="flex justify-between">
                        <span
                          className={`block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200 `}
                        ></span>
                        <div className="px-2">{store?.label} </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mb-12">
            <h3 className="mt-5 my-4 text-17px">{t("Discount type")} </h3>
            <div className="py-4 flex flex-wrap">
              <SimpleRadioButton
                label={t("Percentage") + "(%)"}
                keyVal="discountType"
                value={C.PERCENTAGE}
                disabled={props?.text === "Add" ? false : true}
                checked={props?.state?.values?.discountType === C.PERCENTAGE}
                handleValues={handleChange}
              />
              <SimpleRadioButton
                label={t("Fixed value") + "($)"}
                keyVal="discountType"
                value="fixed"
                disabled={props?.text === "Add" ? false : true}
                checked={props?.state?.values?.discountType === "fixed"}
                handleValues={handleChange}
              />
            </div>

            <div className="lg:w-1/2">
              <div className="py-2">
                <TextInput
                  label={t("Amount")}
                  value={getValue("discountAmount")}
                  decimal={false}
                  keyVal={"discountAmount"}
                  mandatory={true}
                  error={props?.state?.errors?.discountAmount}
                  handleValues={updatePrice}
                />
              </div>
            </div>
            <div className=" flex wrap">
              <div className="py-2">
                <TextInput
                  mandatory={true}
                  label={t("Minimum order value")}
                  value={getValue("minOrderValue")}
                  keyVal={"minOrderValue"}
                  error={props?.state?.errors?.minOrderValue}
                  handleValues={updatePrice}
                />
              </div>
              {props?.state?.values?.discountType === C.PERCENTAGE && (
                <div className="py-2 px-4">
                  <TextInput
                    mandatory={true}
                    label={t("Maximum discount")}
                    value={getValue("maximumDiscountValue")}
                    keyVal={"maximumDiscountValue"}
                    error={props?.state?.errors?.maximumDiscountValue}
                    handleValues={updatePrice}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mb-12">
            <h3 className="mt-5 mb-4 text-17px">{t("Discount items")} </h3>
            <div className="py-4 flex flex-wrap">
              <SimpleRadioButton
                label={t("All items")}
                keyVal="itemSelectionType"
                value={C.ALL}
                checked={props?.state?.values?.itemSelectionType === C.ALL}
                handleValues={handleChange}
              />
              <SimpleRadioButton
                label={t("Selected items")}
                keyVal="itemSelectionType"
                value={C.SELECTED}
                checked={props?.state?.values?.itemSelectionType === C.SELECTED}
                handleValues={handleChange}
              />
            </div>
            {props?.state?.values?.itemSelectionType === C.SELECTED && (
              <>
                <div className="flex flex-col sm:flex-row justify-between my-4 mb-2">
                  <div className="align-middle text-17px">
                    {t("Discount applies to")}
                  </div>
                  <div classN="flex flex-col sm:flex-row">
                    <div className="flex justify-end"></div>
                    <div className="group relative mt-4 sm:mt-0">
                      <svg
                        fill="currentColor"
                        className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        ></path>
                      </svg>
                      <input
                        className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm"
                        type="text"
                        placeholder={t("search item")}
                        autoComplete="off"
                        value={searchTerm}
                        onChange={handSearch}
                      />
                    </div>
                  </div>
                </div>

                <div className="py-2">
                  {Items?.map((item, index) => (
                    <div
                      className="flex py-2"
                      onClick={() => addItem(item._id)}
                      key={index}
                    >
                      <div className="flex justify-between w-full">
                        <div className="px-2">
                          {item?.baseLanguage?.title}
                          <span className="text-gray-400 px-2">
                            AED {item?.price}
                          </span>
                        </div>
                        {props?.state?.values?.selectedItems?.includes(
                          item._id
                        ) ? (
                          <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200 check"></span>
                        ) : (
                          <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200"></span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="mb-12">
            <h3 className="mt-5 mb-4 text-17px">
              {t("Customer segmentation")}
            </h3>
            <div className="py-2 flex flex-wrap">
              <SimpleRadioButton
                label={t("All customers")}
                keyVal="customerSegmentationType"
                value="all"
                checked={
                  props?.state?.values?.customerSegmentationType === "all"
                }
                handleValues={handleChange}
              />
              <SimpleRadioButton
                label={t("New customers")}
                keyVal="customerSegmentationType"
                value="new"
                checked={
                  props?.state?.values?.customerSegmentationType === "new"
                }
                handleValues={handleChange}
              />
            </div>
            {props?.state?.values?.customerSegmentationType === "all" ? (
              <div className="my-4 w-full sm:w-1/2">
                <TextInput
                  mandatory={true}
                  label={t("Number of uses per customer")}
                  value={getValue("perCustomerUse")}
                  keyVal={"perCustomerUse"}
                  error={props?.state?.errors?.perCustomerUse}
                  handleValues={updatePrice}
                />
              </div>
            ) : null}
          </div>

          <div>
            <h3 className="mt-5 mb-4 text-17px">{t("Availability")} </h3>
            <div>
              <Space direction="vertical" size={12}>
                <RangePicker
                  placeholder={[t("Start Date"), t("End Date")]}
                  allowClear={false}
                  onChange={(date, dateString) =>
                    handleSetDate(date, dateString)
                  }
                  inputReadOnly={true}
                  showTime={{ format: "HH:mm" }}
                  format={C.DATE_FORMAT}
                  value={[
                    props?.state?.values?.startDate
                      ? moment(props?.state?.values?.startDate, C.DATE_FORMAT)
                      : null,
                    props?.state?.values?.endDate
                      ? moment(props.state.values.endDate, C.DATE_FORMAT)
                      : null,
                  ]}
                  className="h-11"
                />
              </Space>
            </div>
            <div className="my-4 w-full sm:w-1/2">
              <TextInput
                mandatory={true}
                label={t("Total number of uses")}
                value={getValue("maxUsage")}
                keyVal={"maxUsage"}
                error={props?.state?.errors?.maxUsage}
                handleValues={updatePrice}
              />
            </div>
          </div>
          <div className="my-4">
            <button
              disabled={stores?.length <= 0 || items?.length <= 0}
              type="button"
              className="ant-btn ant-btn-primary ant-btn-lg w-36"
              onClick={() => {
                props?.addDiscount();
              }}
            >
              {props?.text === "Add" ? t("Add") : t("Update")}
            </button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DiscountForm;
