import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import "moment-timezone";
import moment from "moment";

const OnlineOrderTable = ({ orders, handlePageChange, setPage }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("Number"),
      dataIndex: "orderNumber",
      align: "initial",
      key: "orderNumber",
    },
    {
      title: t("Price"),
      dataIndex: "totalOrderPrice",
      align: "initial",
      key: "totalOrderPrice",
    },
    {
      title: t("Status"),
      dataIndex: "orderStatus",
      align: "initial",
      key: "orderStatus",
      render: (val, row, index) => {
        return t(val);
      },
    },
    {
      title: t("Verified"),
      dataIndex: "orderCheckoutOnlinePaymentMetadata",
      align: "initial",
      key: "orderCheckoutOnlinePaymentMetadata",
      render: (val, row, index) => {
        if (row?.paymentType !== "CREDIT_CARD") return t("N/a");
        return row?.orderCheckoutOnlinePaymentMetadata?.data?.status ===
          "VERIFIED"
          ? t("yes")
          : t("no");
      },
    },
    {
      title: t("Order Placed Time"),
      dataIndex: "orderPlacedTime",
      align: "initial",
      key: "orderPlacedTime",
      render: (val, row, index) => {
        return moment.unix(val).tz("UTC").format("DD/MM/YYYY hh:mm A");
      },
    },

    {
      title: t("Store"),
      dataIndex: "storeInfo",
      width: "25%",
      render: (val, row, index) => {
        return val?.baseLanguage?.name;
      },
      key: "storeInfo",
    },
    {
      title: t("Payment Method"),
      dataIndex: "paymentType",
      align: "initial",
      key: "paymentType",
      render: (val, row, index) => {
        return t(val);
      },
    },
  ];
  return (
    <div className="">
      <Card styleClasses="px-2 py-2 overflow-hidden overflow-x-auto md:overflow-hidden">
        <Table
          locale={{ emptyText: t("No Data") }}
          columns={columns}
          dataSource={orders.data}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 20,
            total: orders.totalPages,
            showQuickJumper: false,
            showSizeChanger: false,
          }}
          // call function on page change
          onChange={(pagination, filters, sorter) => {
            setPage(pagination.current);
            handlePageChange(pagination.current);
          }}
          size="small"
          rowKey={(record) => record._id}
        />
      </Card>
    </div>
  );
};

export default OnlineOrderTable;
