import { useAccordion } from "features/menu/components/AccordionN";
import React, { useEffect } from "react";
import { AngleUpIcon } from "svg/SVGs";
import { AngleDownIcon } from "svg/SVGs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SVG_DRAGDROP } from "svg/SVGs";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
const SingleGroup = ({ modiferGroup, toggle, item, deleteModifierGrp }) => {
  const myRef = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const { selected, toggleItem } = useAccordion();

  const outside = (e) => {
    if (!myRef?.current?.contains(e.target)) {
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", outside);
    return () => document.removeEventListener("mousedown", outside);
  }, []);

  const updateGroup = (item) => {
    history.push("/app/dashboard/update-modifier-group", {
      modifier: {
        _id: modiferGroup.modifierGroupId?._id,
      },
    });
  };

  return (
    <div
      className="flex w-max md:w-full items-top items-center focus:outline-none border-b p-2 md:p-4 text-16px hover:bg-gray-100 cursor-pointer"
      onClick={() => {
        toggleItem(toggle);
      }}
    >
      <div className="w-5 h-5  cursor-pointer z-10 me-4">
        {selected === toggle ? <AngleUpIcon /> : <AngleDownIcon />}
      </div>
      <div className="w-5 h-5 cursor-move">
        <SVG_DRAGDROP />
      </div>
      <table className="w-full mx-4">
        <tr className="flex items-center gap-16">
          <td width={"100%"}>
            <h3 className="min-230 text-14px">
              {modiferGroup?.modifierGroupId?.baseLanguage?.name}
            </h3>
          </td>
          <td width="10%">
            <h3 className=" text-14px">
              <span className="font-semibold">{t("Max")}:</span>{" "}
              {modiferGroup?.modifierGroupId?.maximumAllowedSelection}{" "}
            </h3>{" "}
          </td>
          <td width="10%">
            <h3 className=" text-14px">
              <span className="font-semibold">{t("Type")}:</span>
              {modiferGroup?.modifierGroupId?.isSingleSelection
                ? t("Single")
                : t("Multiple")}
            </h3>
          </td>
          <td width="10%">
            <h3 className="text-14px">
              {modiferGroup?.modifierGroupId?.isRequired
                ? t("Required")
                : t("Optional")}
            </h3>
          </td>
        </tr>
      </table>
      <div className="flex gap-2 px-0 md:px-3 whitespace-nowrap text-center ">
        <button
          className="border border-blue-600  px-4 text-blue-600 h-8"
          onClick={() => updateGroup(item)}
        >
          {t("Edit")}
        </button>
        <button
          className="border border-red-600 px-4 text-red-600 h-8"
          onClick={() => deleteModifierGrp(modiferGroup)}
        >
          {t("Delete")}
        </button>
      </div>
    </div>
  );
};
export default SingleGroup;

SingleGroup.propTypes = {
  toggle: PropTypes.string,
  cat: PropTypes.object,
  editCat: PropTypes.func,
  deleteCat: PropTypes.func,
  keyVal: PropTypes.number,
  itemCount: PropTypes.number,
};
