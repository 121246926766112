import React from "react";

function CurrencyFormatter({ currentLanguage, val }) {
  return (
    <>
      {new Intl.NumberFormat(currentLanguage, {
        style: "currency",
        currency: "AED",
      })?.format(val)}
    </>
  );
}

export default CurrencyFormatter;
