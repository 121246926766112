import React, { Fragment } from "react";
import { Button } from "antd";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { ifnullOrUndefined } from "utils/Utils";
import { errorHandler } from "api/api/errorHandler";
import { API } from "api";
import { logErrorOnSentry } from "utils/Utils";
import { useSendORderToLyveGlobalMutation } from "../service";
import { isEmpty } from "lodash";
const CreateOrder = ({ fetchOrderDetailsByID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sendORderToLyveGlobal] = useSendORderToLyveGlobalMutation();
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);

  const sendOrder = async () => {
    try {
      if (!orderInfo?._id) {
        UTILS.errorNotification(t("Invalid Order ID. Please try again."));
        logErrorOnSentry(
          "Invalid Order ID. Please try again--Lyve global create Order",
          "error",
          JSON.stringify(orderInfo)
        );
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const result = await sendORderToLyveGlobal({ orderId: orderInfo._id });
      console.log({ sendORderToLyveGlobal: result });
      if (result?.data?.success === 1) {
        UTILS.successNotification(result?.data?.message);
      } else {
        UTILS.errorNotification(errorHandler(result?.error));
      }
    } catch (error) {
      UTILS.handleErrorNotification(errorHandler(error?.error));
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 100));
      dispatch(LoaderAction.setLoading(false));
      await fetchOrderDetailsByID();
    }
  };

  const isAllowedToSend = () => {
    const createDeliveryResponse =
      orderInfo?.logisticPartnerMetadata?.LYVE_GLOBAL?.createDeliveryResponse;
    return !(
      !isEmpty(createDeliveryResponse) && createDeliveryResponse?.status === 1
    );
  };

  return (
    <Fragment>
      <div className="flex flex-col justify-center items-center">
        <div className="ml-4 py-4 text-center">
          {isAllowedToSend() ? (
            <Button
              type="default"
              size="large"
              onClick={sendOrder}
              className="my-2 rounded-md font-semibold"
            >
              {t("Send order to LYVE GLOBAL")}
            </Button>
          ) : (
            <div>
              <span className="text-sm text-gray-500">
                {t(
                  "Your order has been sent to LYVE GLOBAL for delivery. Please check the ORDER DETAIL tab for Rider details"
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CreateOrder;
