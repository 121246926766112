import React, { useState } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import CreateClaim from "./CreateClaim";
import ClaimInfo from "./ClaimInfo";
import CancelClaim from "./CancelClaim";
import { useDispatch } from "react-redux";
import {
  useTrackCareemOrderMutation,
  useCancelCareemOrderMutation,
} from "redux/Logistic/CareemService";
import { UTILS } from "utils";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import ModalTabs from "logistic-integrations/components/Tabs";
import CareemSupport from "./CareemSupport";
const { TabPane } = Tabs;
const DeliveryService = (props) => {
  const { t } = useTranslation();
  const { setOpen, order } = props;
  const dispatch = useDispatch();
  const [careemOrder, setCareemOrder] = useState(null);
  const [trackCareemOrder] = useTrackCareemOrderMutation();
  const [cancelCareemOrder] = useCancelCareemOrderMutation();

  /*handle tab chnge */
  const handleTabChange = async (key) => {
    if (key === "3") {
      await getCareemORderStatus();
    }
    await props.fetchOrderDetailsByID();
  };

  const getCareemORderStatus = async () => {
    try {
      if (!order?._id) return;
      dispatch(LoaderAction.setLoading(true));
      const data = await trackCareemOrder({
        orderId: order._id,
      });
      if (data?.data?.success === 1 && data?.data?.data) {
        setCareemOrder(data?.data.data);
      } else {
        UTILS.errorNotification(errorHandler(data?.error));
        setCareemOrder(null);
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const cancelCareemORder = async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      if (!order?._id) return;
      const data = await cancelCareemOrder({
        orderId: order._id,
      });
      if (data?.data?.success === 1) {
        UTILS.successNotification(
          data?.data?.message || t("Order has been cancelled successfully")
        );
      } else {
        UTILS.errorNotification(errorHandler(data?.error));
      }
      await props.fetchOrderDetailsByID();
    } catch (error) {
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return (
    <ModalTabs setOpen={setOpen} handleTabChange={handleTabChange}>
      <TabPane tab={t("SEND ORDER")} key="1">
        <CreateClaim
          fetchOrderDetailsByID={props.fetchOrderDetailsByID}
          key="create-claim"
        />
      </TabPane>
      <TabPane tab={t("ORDER DETAIL")} key="3">
        <ClaimInfo
          key="order-status"
          careemOrder={careemOrder}
          getCareemORderStatus={getCareemORderStatus}
        />
      </TabPane>
      <TabPane tab={t("CANCEL ORDER")} key="4">
        <CancelClaim
          key="cancel-claim"
          cancelCareemORder={cancelCareemORder}
          careemOrder={careemOrder}
        />
      </TabPane>
      <TabPane tab={t("CAREEM SUPPORT CENTER")} key="5">
        <CareemSupport careemOrder={careemOrder} />
      </TabPane>
    </ModalTabs>
  );
};

export default DeliveryService;
