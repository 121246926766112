import React, { useState } from "react";
import { Button } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { API } from "api";
import { UTILS } from "utils";
const CheckDeliveryFee = ({ order, opertation, setLoading }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const fetchData = async () => {
    if (!UTILS.validateTalabat(order)) {
    } else {
      setLoading(true);
      let result = "";
      try {
        if (opertation === "fee") {
          result = await API.CHECK_TALABAT_DELIVERY_FEE({
            storeId: order.storeId,
            brandId: order.brandId,
            restheroOrderId: order._id,
          });
        } else if (opertation === "time") {
          result = await API.CHECK_TALABAT_DELIVERY_TIME({
            storeId: order.storeId,
            brandId: order.brandId,
            restheroOrderId: order._id,
          });
        }

        if (result?.success === 1 && result?.data) {
          setData(result.data);
          UTILS.successNotification("Information fetched Sucessfully");
        } else if (result?.success === 0) {
          UTILS.errorNotification(result.message ?? "something went wrong");
        }
      } catch (error) {
        UTILS.errorNotification(error.message ?? "something went wrong");
      }
      setLoading(false);
    }
  };

  let renderButton = {
    fee: <span>{t("Check Estimated Delivery Fee From TALABAT")}</span>,
    time: (
      <span>{t("Check Estimated Pickup and Delivery Time From TALABAT")}</span>
    ),
  };
  return (
    <div>
      {data && (
        <table className="border-collapse border border-slate-400 w-full">
          <tr>
            <th className="border border-slate-300 p-4">{t("Order")} #</th>
            <td className="border border-slate-300 p-4">
              {data?.client_order_id}
            </td>
          </tr>
          {data?.estimated_delivery_fee && (
            <tr>
              <th className="border border-slate-300 p-4">
                {t("Estimated Delivery Fee")}
              </th>
              <td className="border border-slate-300 p-4">
                {`${data?.estimated_delivery_fee} ${t("AED")}`}
              </td>
            </tr>
          )}
          {data?.estimated_pickup_time && (
            <tr>
              <th className="border border-slate-300 p-4">
                {t("Pickup Time")}
              </th>
              <td className="border border-slate-300 p-4">
                {`${moment(data?.estimated_pickup_time).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}`}
              </td>
            </tr>
          )}
          {data?.estimated_delivery_time && (
            <tr>
              <th className="border border-slate-300 p-4">
                {t("Delivery Time")}
              </th>
              <td className="border border-slate-300 p-4">
                {`${moment(data?.estimated_delivery_time).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}`}
              </td>
            </tr>
          )}
        </table>
      )}

      <div className="flex justify-center p-4">
        <Button type="default" size="large" onClick={fetchData}>
          {renderButton[opertation]}
        </Button>
      </div>
    </div>
  );
};

export default CheckDeliveryFee;
