import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  changeDeliveryModal: false,
  careemModal: false,
  yandexModal: false,
  deliverzoneModal: false,
  orderInfo: {},
  error: null,
  selectedTimeSlot: null,
  changeLogisticService: {
    change: false,
    newServiceName: null,
  },
  vehicleTypes: [],
  selectedvehicleType: null,
  isThermalBagNeeded: true,
  claimInfo: null,
  syncData: false,
  showBtn: false,
};

const Integrations = createSlice({
  name: "Integrations",
  initialState,
  reducers: {
    toggleDeliveryModal: (state, action) => {
      state.changeDeliveryModal = action.payload;
    },
    setBtn: (state, action) => {
      state.showBtn = action.payload;
    },
    setClaimInfo: (state, action) => {
      state.claimInfo = action.payload;
    },
    setSyncData: (state, action) => {
      state.syncData = !state.syncData;
    },
    setVehicleTypes: (state, action) => {
      state.vehicleTypes = action.payload;
    },
    setSelectedVehicleType: (state, action) => {
      state.selectedvehicleType = action.payload;
    },
    setThermalBag: (state, action) => {
      state.isThermalBagNeeded = action.payload;
    },
    toggleYandexModal: (state, action) => {
      state.yandexModal = action.payload;
    },
    toggleCareemModal: (state, action) => {
      state.careemModal = action.payload;
    },
    toggleDeliverZoneModal: (state, action) => {
      state.deliverzoneModal = action.payload;
    },
    setOrderInfo: (state, action) => {
      state.orderInfo = action.payload;
    },
    setOrderInfoFailure: (state, action) => {
      state.error = action.payload;
      state.orderInfo = null;
    },
    setTimeSlot: (state, action) => {
      state.selectedTimeSlot = action.payload;
    },
    changeLogisticService: (state, action) => {
      state.changeLogisticService = action.payload;
    },
    clearData: (state, action) => {
      state.vehicleTypes = [];
      // state.isThermalBagNeeded = true;
      // state.selectedvehicleType = "COURIER";
      state.claimInfo = null;
      state.showBtn = false;
    },
    resetOnModalClose: (state, action) => {
      state.vehicleTypes = [];
      state.isThermalBagNeeded = true;
      state.selectedvehicleType = "COURIER";
      state.claimInfo = null;
      state.showBtn = false;
    },
  },
});

export const {
  toggleDeliverZoneModal,
  toggleCareemModal,
  resetOnModalClose,
  setBtn,
  clearData,
  setSyncData,
  setVehicleTypes,
  setSelectedVehicleType,
  setThermalBag,
  setTimeSlot,
  toggleDeliveryModal,
  toggleYandexModal,
  setOrderInfo,
  setOrderInfoFailure,
  setClaimInfo,
} = Integrations.actions;
export default Integrations.reducer;
