import React, { Fragment } from "react";
import { Button } from "antd";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useConfirmYandexClaimMutation } from "redux/Logistic/LogisticService";
import { CONSTANTS as C } from "config";
import { LoaderAction } from "redux/actions";
import { useDispatch } from "react-redux";
import { LogisticAction } from "redux/actions";

const ConfirmClaim = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { order, showButtons, setShowButtons } = props;
  const [confirmYandexClaim] = useConfirmYandexClaimMutation();

  /* A function that is used to confirm a claim. */
  const confirm_claim = async () => {
    dispatch(LoaderAction.setLoading(true));
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
      orderId: order._id,
      deliveryType: C.YANDEX.EXPRESS_DELIVERY_TYPE,
    };
    const isValid = UTILS.ValidateParams(obj);
    if (isValid) {
      const { data } = await confirmYandexClaim(obj);
      if (data) {
        dispatch(LogisticAction.setSyncData());
        setShowButtons((prevState) => ({
          ...prevState,
          sync: !showButtons.sync,
        }));
        if (data?.success === 1 && data?.data?.code === 777) {
          props.setOpen(true);
        }
      } else {
        UTILS.handleErrorNotification(t("Please try again later"));
      }
    }
    props.setOpen(false);
    dispatch(LoaderAction.setLoading(false));
  };

  const ConfirmClaimButton = () => {
    return (
      <Button
        type="default"
        size="large"
        onClick={confirm_claim}
        disabled={!showButtons?.confirmClaim}
        className="w-56 mx-1 my-2 rounded-md font-semibold"
      >
        {t("Confirm order request")}
      </Button>
    );
  };

  return (
    <Fragment key={props?.key}>
      <ConfirmClaimButton />
    </Fragment>
  );
};

export default ConfirmClaim;
