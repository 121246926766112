import React from "react";
import { Button } from "antd";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const DownloadButton = ({ handleDownload }) => {
  const { t } = useTranslation();
  return (
    <Button
      className="h-10 mx-2 flex items-center rounded-sm"
      type="primary"
      size="large"
      onClick={handleDownload}
    >
      <CloudDownloadOutlined />
      <span className="mx-2">{t("Download QR code")}</span>
    </Button>
  );
};

export default DownloadButton;
