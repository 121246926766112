import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { API } from "api";
import { UTILS } from "utils";
import { errorHandler } from "api/api/errorHandler";

import { AreasAction } from "redux/actions";
import useGetStoreAddedMappedAreas from "../hooks/useGetStoreAddedMapAreas";
import { isValidUpdateInputFields } from "../../../helpers";
import DrawAreaContainer from "./DrawAreaContainer";
import { LoaderAction } from "redux/actions";

function EditDrawnArea() {
  const params = useParams();
  const mapRef = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const [polygons, setPolygons] = useState({
    coordinates: [],
    areaInfo: {},
  });
  const [drawingEnabled, setDrawingEnabled] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [state, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    description: "",
    areaName: "",
    deliveryTime: {
      Hours: 0,
      Minutes: 0,
      Days: 0,
    },
  });

  const getStoreMappedAddedAreas = useGetStoreAddedMappedAreas();

  const handleInputValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handledeliveryFee = async (key, value) =>
    setState({ ...state, [key]: value });
  function convertSinglePolygonToObjectArray(coordinates, area) {
    console.log("coordinates", area);
    return {
      areaInfo: area,
      coordinates: coordinates.map(([lng, lat]) => ({ lat, lng })),
    };
  }

  const updateAreaParams = () => {
    let delTime =
      state.deliveryTime.Days * 1440 +
      state.deliveryTime.Hours * 60 +
      state.deliveryTime.Minutes;
    const userDetail = UTILS.getCurrentUser();
    return {
      brandId: userDetail.brandId,
      storeId: selectedStore?.value,
      mappedAreaBaseDeliveryMethodId: params.mappedAreaId,
      translations: {
        en: {
          name: state.areaName,
        },
        ar: {
          name: state.areaName,
        },
      },
      city: {
        en: selectedCity?.name?.en,
        ar: selectedCity?.name?.ar,
      },
      country: {
        en: selectedCountry?.name?.en,
        ar: selectedCountry?.name?.ar,
      },
      description: state.description,
      polygon: polygons.coordinates,
      minOrder: Number(state.minOrder),
      deliveryFee: Number(state.deliveryFee),
      deliveryTime: Number(delTime),
    };
  };
  const updateCustomBusinessAreas = async () => {
    try {
      if (
        !isValidUpdateInputFields({ state, storeID: selectedStore?.value })
          ?.isValid
      )
        return;
      dispatch(LoaderAction.setLoading(true));
      const result = await API.updateCustomStoreMapArea(updateAreaParams());
      if (result?.success === 1) {
        UTILS.successNotification(result.message);
        history.push(`/app/dashboard/mapped-area-method`);
        await getStoreMappedAddedAreas({
          storeId: selectedStore?.value,
        });
        dispatch(AreasAction.setStore(selectedStore));
      } else {
        UTILS.errorNotification(errorHandler(result));
      }
    } catch (error) {
      UTILS.errorNotification(t(errorHandler(error)));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const getAreaInfo = async (storeId) => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      const result = await API.getCustomStoreMapAreaById({
        brandId: userDetail.brandId,
        storeId,
        mappedAreaBaseDeliveryMethodId: params.mappedAreaId,
      });
      if (result?.success === 1) {
        const data = result?.data;
        setState({
          minOrder: result?.data?.minOrder,
          deliveryFee: result?.data?.deliveryFee,
          description: result?.data?.description,
          areaName: result?.data?.mappedAreaInfo?.translations?.en?.name,
          deliveryTime: {
            Days: Math.floor(result?.data?.deliveryTime / 1440),
            Hours: Math.floor((result?.data?.deliveryTime % 1440) / 60),
            Minutes: Math.floor((result?.data?.deliveryTime % 1440) % 60),
          },
        });
        const country = {
          name: {
            en: data?.mappedAreaInfo?.country.en,
            ar: data?.mappedAreaInfo?.country.ar,
          },
        };
        const city = {
          name: {
            en: data?.mappedAreaInfo?.city.en,
            ar: data?.mappedAreaInfo?.city.ar,
          },
        };

        setSelectedCountry(country);
        setSelectedCity(city);

        const isPolygonDrawn =
          result?.data?.mappedAreaInfo?.mappedAreaId?.polygon?.coordinates
            ?.length > 0;
        if (!isPolygonDrawn) {
          setDrawingEnabled(true);
        }
        if (
          isPolygonDrawn &&
          result?.data?.mappedAreaInfo?.mappedAreaId?.polygon?.coordinates[0]
        ) {
          const convertedPolygon = convertSinglePolygonToObjectArray(
            result?.data?.mappedAreaInfo?.mappedAreaId?.polygon?.coordinates[0],
            result?.data?.mappedAreaInfo
          );
          setPolygons(convertedPolygon);
        }
      } else {
        UTILS.errorNotification(errorHandler(result));
      }
    } catch (error) {
      UTILS.errorNotification(t(errorHandler(error)));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  useEffect(() => {
    if (!params?.mappedAreaId) return;
    if (selectedStore?.value) {
      const Id = selectedStore?.value;
      getAreaInfo(Id);
    }
  }, [params?.mappedAreaId]);

  const handleCountryChange = (value, option) => {
    setSelectedCountry(option.data);
    setSelectedCity(null);
  };

  const handleCityChange = (value, option) => setSelectedCity(option.data);
  const onStoreChange = (value) => {
    localStorage.setItem("storeId", value.value);
    getAreaInfo(value.value);
    dispatch(AreasAction.setStore(value));
  };
  return (
    <DrawAreaContainer
      {...{
        mapRef,
        selectedStore,
        onStoreChange,
        areaState: state,
        handleInputValue,
        handledeliveryFee,
        selectedCountry,
        selectedCity,
        handleCountryChange,
        handleCityChange,
        addAreafromMap: updateCustomBusinessAreas,
        polygons,
        setPolygons,
        drawingEnabled,
        setDrawingEnabled,
        operationType: "edit",
      }}
      plottedPolygons={[polygons]}
    />
  );
}

export default EditDrawnArea;
