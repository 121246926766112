import React from "react";
import SectionHeading from "../../../components/SectionHeading";
import TextInput from "../../../components/TextInput";
import PropTypes from "prop-types";
import { CONSTANTS as C } from "config";
import { Button, notification } from "antd";
import TextArea from "components/TextArea";
import { API } from "api";
import { UTILS } from "utils";
import PhoneNumberInput from "components/PhoneNumberInput";
import SelectInput from "components/SelectInput";
import TimeZones from "../timezone";
import { useTranslation } from "react-i18next";
import SelectMultiLingualInput from "components/SelectMultiLingualInput";
import { isValidValue } from "utils/Utils";
import { STOTE_UTILS } from "utils";

const StoreInfoSection = (props) => {
  const { t } = useTranslation();

  const cititesList = [
    { value: "dubai", label: { en: "dubai", ar: "دبي" } },
    { value: "abu dhabi", label: { en: "abu dhabi", ar: "أبو ظبي" } },
    { value: "sharjah", label: { en: "sharjah", ar: "الشارقة" } },
    { value: "al ain", label: { en: "al ain", ar: "العين" } },
    { value: "ajman", label: { en: "ajman", ar: "عجمان" } },
    {
      value: "ras al khaimah",
      label: { en: "ras al Khaimah", ar: "رأس الخيمة" },
    },
    { value: "fujairah", label: { en: "fujairah", ar: "الفجيرة" } },
    {
      value: "umm al-quwain",
      label: { en: "umm al-quwain", ar: "ام القيوين" },
    },
  ];

  const timeZones = TimeZones();

  const getValue = (key) => {
    // if (props.store && props.store.values[key]) return props.store.values[key];
    // else return "";
    if (isValidValue(props.store.values[key])) return props.store.values[key];
    else return "";
  };

  const handleValues = (key, val) => {
    props.handleValues(key, val);
  };

  const updatePrice = (key, val) => {
    const value = UTILS.NumberConversion(val, C.MOBILE_ARABIC_REGEX);
    handleValues(key, value);
  };

  const handleSubmit = () => {
    let objValid = STOTE_UTILS.VALIDATE_INPUTS_STEP1(props.store.values);
    if (objValid?.isFormValid) {
      props.setStep((step) => step + 1);
    }
  };
  const handleSuccessNotification = (placement, success, message) => {
    notification[success]({
      message: "Success",
      description: message,
      placement,
    });
  };

  const handleErrorNotification = (placement, error, message) => {
    notification[error]({
      message: "Error",
      description: message,
      placement,
    });
  };

  const uploadFile = async (files) => {
    props.handleValues(C.KEY_IMAGE_URL, "");
    if (files.length >= 1) {
      const userDetail = UTILS.getCurrentUser();
      const result = await API.UPLOAD_FILE({
        image: files[0],
        token: userDetail.token,
      });

      if (result.success === 1) {
        handleSuccessNotification(
          "topRight",
          "success",
          t("File Uploaded Succesfully")
        );
        props.handleValues(C.KEY_IMAGE_URL, result.data.url);
      } else {
        handleErrorNotification("topRight", "success", result.message);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="bg-white mb-5 h-full">
        <SectionHeading title={t("Basic Information")} />
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="overflow-hidden">
            <div className="px-4 pb-8">
              <div className="w-full">
                <div className="py-4">
                  <TextInput
                    label={t("Store Name")}
                    value={getValue(C.BASE_LANGUAGE).name}
                    handleValues={handleValues}
                    keyVal={C.STORE_NAME}
                    mandatory={true}
                    error={t(props.store.errors[C.BASE_LANGUAGE].name)}
                  />
                </div>
                <div className="pb-2">
                  <TextArea
                    label={t("Store Description")}
                    value={getValue(C.BASE_LANGUAGE).description}
                    keyVal={C.STORE_DESCRIPTION}
                    handleValues={handleValues}
                    mandatory={true}
                    error={t(props.store.errors[C.BASE_LANGUAGE].description)}
                  />
                </div>
                <div className="pb-2">
                  <TextInput
                    label={t("Email")}
                    value={getValue(C.KEY_EMAIL)}
                    keyVal={C.KEY_EMAIL}
                    handleValues={handleValues}
                    mandatory={true}
                    error={t(props.store.errors.email)}
                  />
                </div>
                <div className="pb-2" dir="ltr">
                  <PhoneNumberInput
                    label={t("Phone Number")}
                    value={getValue(C.KEY_PHONE)}
                    keyVal={C.KEY_PHONE}
                    handleValues={handleValues}
                    mandatory={true}
                    error={t(props.store.errors.mobileNumber)}
                  />
                </div>
                <div className="pb-2" dir="ltr">
                  <PhoneNumberInput
                    label={t("Whatsapp Number")}
                    value={getValue(C.KEY_WHATSAPP_PHONE)}
                    keyVal={C.KEY_WHATSAPP_PHONE}
                    handleValues={handleValues}
                    error={t(props.store.errors.whatsAppContact)}
                  />
                </div>
                <div className="py-2 ">
                  <label className="text-gray-600 block text-14px">
                    {t("Select City")}
                  </label>
                  <SelectMultiLingualInput
                    keyVal={t("city")}
                    handleChange={handleValues}
                    options={cititesList}
                    value={props.store.values.city}
                    defaultSelection={"dubai"}
                    styleClass="text-input-color text-16px mt-1 block w-full py-3 px-3 border border-gray-300 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <span className="text-red-600">
                    {" "}
                    {t(props.store.errors.city)}{" "}
                  </span>
                </div>

                <div className="py-2 ">
                  <label className="text-gray-600 block text-14px">
                    {t("Timezone")}
                  </label>
                  <SelectInput
                    keyVal={t("timeZone")}
                    handleChange={handleValues}
                    options={timeZones}
                    disabled={true}
                    value={props.store.values.timeZone}
                    defaultSelection={"Asia/Dubai"}
                    styleClass="text-input-color text-16px mt-1 block w-full py-3 px-3 border border-gray-300 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <span className="text-red-600">
                    {" "}
                    {t(props.store.errors.timeZone)}{" "}
                  </span>
                </div>

                <div className="mt-12">
                  <h3 className="text-gray-600 text-17px">
                    {t("Tax Settings for Ordering")}
                  </h3>
                  <div className="w-1/2 register-page">
                    <TextInput
                      label={t("Tax Rate(%)")}
                      value={getValue(C.KEY_TAX_RATE)}
                      keyVal={C.KEY_TAX_RATE}
                      handleValues={updatePrice}
                      mandatory={true}
                      error={t(props.store.errors.taxRate)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full text-center mb-4">
          {props.type === "create" ? (
            <Button
              type="primary"
              size="large"
              className="w-36"
              onClick={handleSubmit}
            >
              {t("Next")}
            </Button>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

StoreInfoSection.propTypes = {
  store: PropTypes.object,
  handleValues: PropTypes.func,
};

export default StoreInfoSection;
