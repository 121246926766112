import React from "react";
import PropTypes from "prop-types";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};
const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600">* {error} </span>;
  }
};
const TextInput = (props) => {
  const {
    label,
    mandatory,
    error,
    placeholder,
    register,
    rules,
    type,
    disabled,
    name,
    value,
    maxLength,
  } = props;

  const mandatoryField = getMandatoryField(mandatory);
  const fieldError = getFieldError(error);

  return (
    <>
      <label className="text-gray-600 block text-14px">
        {label}
        {mandatoryField}
      </label>
      <input
        {...props}
        maxLength={maxLength ? maxLength : ""}
        value={value}
        {...(register && register(name, rules))}
        placeholder={placeholder ? placeholder : ""}
        type={type ? type : "text"}
        disabled={disabled ? true : false}
        className={`font-montserrat text-16px text-input-color text-sm border border-grey-600 focus:ring-indigo-500 outline-none focus:border-indigo-500 block w-full  p-2 ${
          error ? "border-red-600" : "border-gray-300"
        }}`}
      />
      <div className="text-15x">{fieldError}</div>
    </>
  );
};

TextInput.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  rules: PropTypes.object,
  name: PropTypes.string,
};

export default TextInput;
