import { useEffect, useState } from "react";
import { UTILS } from "utils";

const useUserType = () => {
  const [userType, setUserType] = useState("");
  useEffect(() => {
    const CURRENT_USER_TYPE = UTILS.getCurrentUser()?.userType;
    setUserType(CURRENT_USER_TYPE);
  }, []);
  return userType;
};

export default useUserType;
