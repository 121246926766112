import React from "react";

function SearchInput({ searchTerm, setSearchTerm, placeholder }) {
  return (
    <div className="group relative my-4 sm:mt-0 w-full sm:w-auto">
      <svg
        fill="currentColor"
        className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        ></path>
      </svg>
      <input
        className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm min-290"
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        autoComplete="off"
        onChange={(e) => setSearchTerm(e.target?.value)}
      />
    </div>
  );
}

export default SearchInput;
