import React, { createContext, useContext, useReducer } from "react";

// Create a context
const LogisticIntegrationsContext = createContext();

// Define action types
const SET_STATE = "SET_STATE";

// Reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return { ...state, [action.payload.key]: action.payload.value };
    case "RESET_STATE":
      return {
        ...state,
        islyveGlobalModalOpen: false,
      };
    default:
      return state;
  }
};

// Create a provider component
export const LogisticContextProvider = ({ children }) => {
  const [logisticState, dispatch] = useReducer(appReducer, {
    islyveGlobalModalOpen: false,
  });

  const updateState = (key, value) => {
    dispatch({
      type: SET_STATE,
      payload: { key, value },
    });
  };
  const resetState = () => {
    dispatch({
      type: "RESET_STATE",
    });
  };

  return (
    <LogisticIntegrationsContext.Provider
      value={{ logisticState, updateState, resetState }}
    >
      {children}
    </LogisticIntegrationsContext.Provider>
  );
};

// Create a custom hook for using the context
export const useLogisticContext = () => {
  const context = useContext(LogisticIntegrationsContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};
