import _ from "lodash";
import { CONSTANTS as C } from "config";
import { store } from "react-notifications-component";
import { UTILS } from "utils";
import { API } from "api";
import moment from "moment";
import "moment-timezone";
import i18next from "i18next";
import * as Sentry from "@sentry/react";
import * as XLSX from "xlsx";

export function isLoggedIn() {
  /*
   * Note:
   *  This app assume if local storage have roles it means
   *  user is authenticated you can update this logic as per your app.
   */

  return localStorage.getItem("roles");
}

export function saveCurrentUser(user) {
  if (user) {
    let val = JSON.stringify(user);
    localStorage.setItem(C.CURRENT_USER, val);
  }
}

export function saveLanguageInformation(obj) {
  if (obj) {
    let val = JSON.stringify(obj);
    localStorage.setItem(C.DIRECTION, val);
  }
}

export function getLanguageInformation() {
  try {
    let dir = localStorage.getItem(C.DIRECTION);
    if (dir) return JSON.parse(dir);
    else return dir;
  } catch (error) {}
}

export function getLayoutDirection(lang) {
  if (lang == "en") return "ltr";
  else if (lang === "ar") return "rtl";
  else return "ltr";
}

export function removeLanguageInformation() {
  localStorage.setItem(C.DIRECTION, "");
}

export function emailValidation() {
  let pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern;
}

export function getCurrentUser() {
  let user = localStorage.getItem(C.CURRENT_USER);

  if (user) {
    return JSON.parse(user);
  }

  return user;
}

export function removeCurrentUser() {
  localStorage.setItem(C.CURRENT_USER, "");
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes) {
  const roles = JSON.parse(localStorage.getItem("roles"));
  const filteredRoutes = routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return _.intersection(permission, roles).length;
  });

  return filteredRoutes;
}

export function saveStores(s) {
  if (s) {
    let val = JSON.stringify(s);
    localStorage.setItem(C.STORES_INFO, val);
  }
}

export function getStores() {
  let store = localStorage.getItem(C.STORES_INFO);
  if (store) {
    return JSON.parse(store);
  }
  return store;
}

export function getSideBarItemList() {
  return [
    { name: "Live Orders", link: "/app/liveorders" },
    { name: "Order History", link: "/app/orderhistory" },
    { name: "Pause Orders", link: "/app/pauseorders" },
    { name: "Delivery Areas", link: "/app/manageareas" },
    { name: "Menu", link: "/app/menu" },
    { name: "Ordering Panel", link: "/app/orderingpanel" },
  ];
}

export const getKey = (key) => {
  if (key === C.KEY_STORE_NAME_AR || key === C.KEY_STORE_DES_AR)
    return C.STORE_INFO_AR;
  else if (key === C.KEY_STORE_NAME_EN || key === C.KEY_STORE_DES_EN)
    return C.STORE_INFO_EN;
  else return key;
};

/* export const getKeyForAddStore = (key) => {
  if (key === C.KEY_STORE_NAME_AR) return C.STORE_NAME_AR;
  else if(key === C.KEY_STORE_DES_AR) return C.STORE_DESCRIPTION_AR;
  else if(key === C.KEY_STORE_NAME_EN) return C.STORE_NAME;
  else if (key === C.KEY_STORE_DES_EN) return C.STORE_DESCRIPTION;
  else return key;
} */

export const getValueAgainstKeyToUpdateStore = (key, val, prevState) => {
  let obj = {};
  if (key === C.KEY_STORE_NAME_AR) {
    obj[C.STORE_NAME] = val;
    obj[C.STORE_DESCRIPTION] = prevState[C.STORE_INFO_AR].storeDescription;
    return obj;
  } else if (key === C.KEY_STORE_DES_AR) {
    obj[C.STORE_DESCRIPTION] = val;
    obj[C.STORE_NAME] = prevState[C.STORE_INFO_AR].storeName;
    return obj;
  } else if (key === C.KEY_STORE_NAME_EN) {
    obj[C.STORE_NAME] = val;
    obj[C.STORE_DESCRIPTION] = prevState[C.STORE_INFO_EN].storeDescription;
    return obj;
  } else if (key === C.KEY_STORE_DES_EN) {
    obj[C.STORE_DESCRIPTION] = val;
    obj[C.STORE_NAME] = prevState[C.STORE_INFO_EN].storeName;
    return obj;
  } else if (key === C.KEY_FRI) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_FRI] = val;
    return obj;
  } else if (key === C.KEY_SAT) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_SAT] = val;
    return obj;
  } else if (key === C.KEY_SUN) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_SUN] = val;
    return obj;
  } else if (key === C.KEY_MON) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_MON] = val;
    return obj;
  } else if (key === C.KEY_TUE) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_TUE] = val;
    return obj;
  } else if (key === C.KEY_WED) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_WED] = val;
    return obj;
  } else if (key === C.KEY_THU) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_THU] = val;
    return obj;
  } else if (key === C.KEY_FRI_START_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_FRI_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_FRI_END_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_FRI_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SAT_START_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_SAT_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SAT_END_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_SAT_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SUN_START_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_SUN_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SUN_END_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_SUN_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_MON_START_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_MON_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_MON_END_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_MON_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_TUE_START_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_TUE_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_TUE_END_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_TUE_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_WED_START_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_WED_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_WED_END_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_WED_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_THU_START_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_THU_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_THU_END_TIME) {
    obj = prevState[C.KEY_WORKING_HOURS];
    obj[C.KEY_THU_END_TIME] = new Date(val).getTime();
    return obj;
  } else {
    return val;
  }
};

export const getValueAgainstKeyToAddStore = (key, val, prevState) => {
  let obj = {};
  if (key === C.KEY_STORE_NAME_AR) {
    obj[C.STORE_NAME] = val;
    obj[C.STORE_DESCRIPTION] =
      prevState.values[C.STORE_INFO_AR].storeDescription;
    return obj;
  } else if (key === C.KEY_STORE_DES_AR) {
    obj[C.STORE_DESCRIPTION] = val;
    obj[C.STORE_NAME] = prevState.values[C.STORE_INFO_AR].storeName;
    return obj;
  } else if (key === C.KEY_STORE_NAME_EN) {
    obj[C.STORE_NAME] = val;
    obj[C.STORE_DESCRIPTION] =
      prevState.values[C.STORE_INFO_EN].storeDescription;
    return obj;
  } else if (key === C.KEY_STORE_DES_EN) {
    obj[C.STORE_DESCRIPTION] = val;
    obj[C.STORE_NAME] = prevState.values[C.STORE_INFO_EN].storeName;
    return obj;
  }
  if (key === C.KEY_FRI) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_FRI] = val;
    return obj;
  } else if (key === C.KEY_SAT) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_SAT] = val;
    return obj;
  } else if (key === C.KEY_SUN) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_SUN] = val;
    return obj;
  } else if (key === C.KEY_MON) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_MON] = val;
    return obj;
  } else if (key === C.KEY_TUE) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_TUE] = val;
    return obj;
  } else if (key === C.KEY_WED) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_WED] = val;
    return obj;
  } else if (key === C.KEY_THU) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_THU] = val;
    return obj;
  } else if (key === C.KEY_FRI_START_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_FRI_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_FRI_END_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_FRI_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SAT_START_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_SAT_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SAT_END_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_SAT_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SUN_START_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_SUN_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_SUN_END_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_SUN_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_MON_START_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_MON_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_MON_END_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_MON_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_TUE_START_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_TUE_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_TUE_END_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_TUE_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_WED_START_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_WED_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_WED_END_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_WED_END_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_THU_START_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_THU_START_TIME] = new Date(val).getTime();
    return obj;
  } else if (key === C.KEY_THU_END_TIME) {
    obj = prevState.values[C.KEY_WORKING_HOURS];
    obj[C.KEY_THU_END_TIME] = new Date(val).getTime();
    return obj;
  } else {
    return val;
  }
};
export const successNotification = (message) => {
  UTILS.HANDLE_SUCCESS_NOTIFICATION("topRight", "success", message);
};
export const errorNotification = (message) => {
  UTILS.HANDLE_ERROR_NOTIFICATION("topRight", "error", message);
};
export const HANDLE_SUCCESS_NOTIFICATION = (placement, success, message) => {
  /* notification[success]({
    message: "Success",
    description: message,
    placement,
    icon: <SmileFilled style={{ color: 'white' }} />,
    className: 'custom-class',
    style: {
      width: 400,
      height:100,
      color:"white",
      background:"#059669"
    },
  }); */
  store.addNotification({
    message: message,
    type: "success",
    insert: "top",
    container: "top-center",
    // animationIn: ["animate__animated", "animate__fadeIn"],
    // animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      // onScreen: true
    },
  });
};

export const HANDLE_ERROR_NOTIFICATION = (placement, error, message) => {
  /* notification[error]({
    message: "Error",
    description: message,
    placement,
    icon: <FrownFilled style={{ color: 'white' }} />,
    className: 'custom-class',
    style: {
      width: 400,
      height:100,
      color:"white",
      background:"#DC2626"
    },
  }); */
  store.addNotification({
    message: message,
    type: "danger",
    insert: "top",
    container: "top-center",
    // animationIn: ["animate__animated", "animate__fadeIn"],
    // animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      //   onScreen: true
    },
  });
};

export const getDumbStoreObject = () => {
  return {
    values: {
      baseLanguage: {
        name: "",
        description: "",
      },
      email: getCurrentUser().email,
      mobileNumber: getCurrentUser().phoneNumber,
      whatsAppContact: null,
      brandId: "",
      instagramId: "N/A",
      storeImageUrl: "",
      storeLogoUrl: "",
      manualAddress: "",
      mapAddress: "",
      zoom: 8,
      vehicleInfoForPickup: {
        isActive: false,
        isRequired: false,
      },
      workingHours: {
        mondayStartTime: C.START_TIME_24_HOURS,
        mondayStartTimeSlot2: null,
        mondayEndTime: C.END_TIME_24_HOURS,
        mondayEndTimeSlot2: null,
        monday: true,
        tuesdayStartTime: C.START_TIME_24_HOURS,
        tuesdayStartTimeSlot2: null,
        tuesdayEndTime: C.END_TIME_24_HOURS,
        tuesdayEndTimeSlot2: null,
        tuesday: true,
        wednesdayStartTime: C.START_TIME_24_HOURS,
        wednesdayStartTimeSlot2: null,
        wednesdayEndTime: C.END_TIME_24_HOURS,
        wednesdayEndTimeSlot2: null,
        wednesday: true,
        thursdayStartTime: C.START_TIME_24_HOURS,
        thursdayStartTimeSlot2: 0,
        thursdayEndTime: C.END_TIME_24_HOURS,
        thursdayEndTimeSlot2: null,
        thursday: true,
        fridayStartTime: C.START_TIME_24_HOURS,
        fridayStartTimeSlot2: null,
        fridayEndTime: C.END_TIME_24_HOURS,
        fridayEndTimeSlot2: null,
        friday: true,
        saturdayStartTime: C.START_TIME_24_HOURS,
        saturdayStartTimeSlot2: null,
        saturdayEndTime: C.END_TIME_24_HOURS,
        saturdayEndTimeSlot2: null,
        saturday: true,
        sundayStartTime: C.START_TIME_24_HOURS,
        sundayStartTimeSlot2: null,
        sundayEndTime: C.END_TIME_24_HOURS,
        sundayEndTimeSlot2: null,
        sunday: true,
      },
      workingHoursForPickup: {
        mondayStartTime: C.START_TIME_24_HOURS,
        mondayStartTimeSlot2: null,
        mondayEndTime: C.END_TIME_24_HOURS,
        mondayEndTimeSlot2: null,
        monday: true,
        tuesdayStartTime: C.START_TIME_24_HOURS,
        tuesdayStartTimeSlot2: null,
        tuesdayEndTime: C.END_TIME_24_HOURS,
        tuesdayEndTimeSlot2: null,
        tuesday: true,
        wednesdayStartTime: C.START_TIME_24_HOURS,
        wednesdayStartTimeSlot2: null,
        wednesdayEndTime: C.END_TIME_24_HOURS,
        wednesdayEndTimeSlot2: null,
        wednesday: true,
        thursdayStartTime: C.START_TIME_24_HOURS,
        thursdayStartTimeSlot2: 0,
        thursdayEndTime: C.END_TIME_24_HOURS,
        thursdayEndTimeSlot2: null,
        thursday: true,
        fridayStartTime: C.START_TIME_24_HOURS,
        fridayStartTimeSlot2: null,
        fridayEndTime: C.END_TIME_24_HOURS,
        fridayEndTimeSlot2: null,
        friday: true,
        saturdayStartTime: C.START_TIME_24_HOURS,
        saturdayStartTimeSlot2: null,
        saturdayEndTime: C.END_TIME_24_HOURS,
        saturdayEndTimeSlot2: null,
        saturday: true,
        sundayStartTime: C.START_TIME_24_HOURS,
        sundayStartTimeSlot2: null,
        sundayEndTime: C.END_TIME_24_HOURS,
        sundayEndTimeSlot2: null,
        sunday: true,
      },
      workingHoursForDinein: {
        mondayStartTime: C.START_TIME_24_HOURS,
        mondayStartTimeSlot2: null,
        mondayEndTime: C.END_TIME_24_HOURS,
        mondayEndTimeSlot2: null,
        monday: true,
        tuesdayStartTime: C.START_TIME_24_HOURS,
        tuesdayStartTimeSlot2: null,
        tuesdayEndTime: C.END_TIME_24_HOURS,
        tuesdayEndTimeSlot2: null,
        tuesday: true,
        wednesdayStartTime: C.START_TIME_24_HOURS,
        wednesdayStartTimeSlot2: null,
        wednesdayEndTime: C.END_TIME_24_HOURS,
        wednesdayEndTimeSlot2: null,
        wednesday: true,
        thursdayStartTime: C.START_TIME_24_HOURS,
        thursdayStartTimeSlot2: 0,
        thursdayEndTime: C.END_TIME_24_HOURS,
        thursdayEndTimeSlot2: null,
        thursday: true,
        fridayStartTime: C.START_TIME_24_HOURS,
        fridayStartTimeSlot2: null,
        fridayEndTime: C.END_TIME_24_HOURS,
        fridayEndTimeSlot2: null,
        friday: true,
        saturdayStartTime: C.START_TIME_24_HOURS,
        saturdayStartTimeSlot2: null,
        saturdayEndTime: C.END_TIME_24_HOURS,
        saturdayEndTimeSlot2: null,
        saturday: true,
        sundayStartTime: C.START_TIME_24_HOURS,
        sundayStartTimeSlot2: null,
        sundayEndTime: C.END_TIME_24_HOURS,
        sundayEndTimeSlot2: null,
        sunday: true,
      },
      isPreOrderEnabledForDelivery: false,
      isPreOrderEnabledForPickup: false,
      isAcceptQuickOrderForDelivery: true,
      isAcceptQuickOrderForPickup: true,
      acceptAsapForDelivery: true,
      acceptAsapForPickup: true,

      preOrderSettingsForDelivery: {
        timeInterval: 15, //slot
        timeIntervalUnit: "minutes",

        minTimeInAdvance: 10,
        minTimeInAdvanceUnit: "minutes", //lead time

        maxDaysInAdvance: 1,
        minDaysInAdvance: 0,
        acceptTodayPreOrders: true,
      },
      preOrderSettingsForPickup: {
        timeInterval: 15, //slot
        timeIntervalUnit: "minutes",

        minTimeInAdvance: 10,
        minTimeInAdvanceUnit: "minutes", //lead time

        maxDaysInAdvance: 1,
        minDaysInAdvance: 0,

        acceptTodayPreOrders: true,
      },

      deliveryChargesWaiverInfo: {
        onOrderAmount: {
          isEnabled: false,
          amount: 0,
        },
        onDateRange: {
          isEnabled: false,
          // current time
          startDateTime: moment()
            .tz("Asia/Dubai")
            .format("YYYY-MM-DDTHH:mm:ssZ"),
          endDateTime: moment().tz("Asia/Dubai").format("YYYY-MM-DDTHH:mm:ssZ"),
        },
      },
      isDeliveryEnabled: false,
      isDineInEnabled: false,
      isPickupEnabled: false,
      averagePrepTime: "",
      isStoreOpen24Hours: true,
      paymentOptions: [],
      averagePrepTimeForPickup: "24 hours",
      isStoreOpen24HoursForPickup: true,
      pickupPaymentOptions: [],
      dineInPaymentOptions: [],
      averagePrepTimeForDinein: "24 hours",
      isStoreOpen24HoursForDinein: true,
      serviceType: [],
      isActive: true,
      isOpened: false,
      language: "EN",
      currency: "",
      storeLocation: {
        type: "Point",
        coordinates: [24.3498856, 52.8346913],
      },
      country: "UAE",
      city: "dubai",
      taxRate: "5",
      timeZone: "Asia/Dubai",
    },
    errors: {
      baseLanguage: {
        name: "",
        description: "",
      },
      address: "",
      email: "",
      mobileNumber: "",
      whatsAppContact: "",
      manualAddress: "",
      mapAddress: "",
      storeLocation: "",
      zoom: "",
      paymentOptions: "",
      serviceType: "",
      taxRate: "",
      timeZone: "",
      averagePrepTime: "",
      averagePrepTimeForPickup: "",
      averagePrepTimeForDinein: "",
      workingHours: {
        saturdayStartTime: "",
        saturdayEndTime: "",
        saturdayStartTimeSlot2: "",
        saturdayEndTimeSlot2: "",
        sundayStartTime: "",
        sundayEndTime: "",
        sundayStartTimeSlot2: "",
        sundayEndTimeSlot2: "",
        mondayStartTime: "",
        mondayEndTime: "",
        mondayStartTimeSlot2: "",
        mondayEndTimeSlot2: "",
        tuesdayStartTime: "",
        tuesdayEndTime: "",
        tuesdayStartTimeSlot2: "",
        tuesdayEndTimeSlot2: "",
        wednesdayStartTime: "",
        wednesdayEndTime: "",
        wednesdayStartTimeSlot2: "",
        wednesdayEndTimeSlot2: "",
        thursdayStartTime: "",
        thursdayEndTime: "",
        thursdayStartTimeSlot2: "",
        thursdayEndTimeSlot2: "",
        fridayStartTime: "",
        fridayEndTime: "",
        fridayStartTimeSlot2: "",
        fridayEndTimeSlot2: "",
        sunday: "",
        saturday: "",
        friday: "",
        thursday: "",
        wednesday: "",
        tuesday: "",
        monday: "",
      },
      workingHoursForPickup: {
        saturdayStartTime: "",
        saturdayEndTime: "",
        saturdayStartTimeSlot2: "",
        saturdayEndTimeSlot2: "",
        sundayStartTime: "",
        sundayEndTime: "",
        sundayStartTimeSlot2: "",
        sundayEndTimeSlot2: "",
        mondayStartTime: "",
        mondayEndTime: "",
        mondayStartTimeSlot2: "",
        mondayEndTimeSlot2: "",
        tuesdayStartTime: "",
        tuesdayEndTime: "",
        tuesdayStartTimeSlot2: "",
        tuesdayEndTimeSlot2: "",
        wednesdayStartTime: "",
        wednesdayEndTime: "",
        wednesdayStartTimeSlot2: "",
        wednesdayEndTimeSlot2: "",
        thursdayStartTime: "",
        thursdayEndTime: "",
        thursdayStartTimeSlot2: "",
        thursdayEndTimeSlot2: "",
        fridayStartTime: "",
        fridayEndTime: "",
        fridayStartTimeSlot2: "",
        fridayEndTimeSlot2: "",
        sunday: "",
        saturday: "",
        friday: "",
        thursday: "",
        wednesday: "",
        tuesday: "",
        monday: "",
      },
      workingHoursForDinein: {
        saturdayStartTime: "",
        saturdayEndTime: "",
        saturdayStartTimeSlot2: "",
        saturdayEndTimeSlot2: "",
        sundayStartTime: "",
        sundayEndTime: "",
        sundayStartTimeSlot2: "",
        sundayEndTimeSlot2: "",
        mondayStartTime: "",
        mondayEndTime: "",
        mondayStartTimeSlot2: "",
        mondayEndTimeSlot2: "",
        tuesdayStartTime: "",
        tuesdayEndTime: "",
        tuesdayStartTimeSlot2: "",
        tuesdayEndTimeSlot2: "",
        wednesdayStartTime: "",
        wednesdayEndTime: "",
        wednesdayStartTimeSlot2: "",
        wednesdayEndTimeSlot2: "",
        thursdayStartTime: "",
        thursdayEndTime: "",
        thursdayStartTimeSlot2: "",
        thursdayEndTimeSlot2: "",
        fridayStartTime: "",
        fridayEndTime: "",
        fridayStartTimeSlot2: "",
        fridayEndTimeSlot2: "",
        sunday: "",
        saturday: "",
        friday: "",
        thursday: "",
        wednesday: "",
        tuesday: "",
        monday: "",
      },
    },
  };
};

export const getDumbAreaObject = () => {
  return {
    deliveryFee: 0,
    deliveryTime: 0,
    minOrder: 0,
    areaID: {
      _id: "",
      areaNameEN: "",
      areaNameAR: "",
    },
    countryID: {
      _id: "",
      countryNameEN: "",
      countryNameAR: "",
    },
    cityID: {
      _id: "",
      cityNameEN: "",
      cityNameAR: "",
    },
    storeId: "",
    brandId: "",
  };
};

export const getDumbAreaObjectForEditing = () => {
  return {
    values: {
      countryID: "",
      cityID: "",
      areaID: "",
      deliveryFee: "",
      deliveryTime: "",
      minOrder: "",
    },
    errors: {
      countryID: "",
      cityID: "",
      areaID: "",
      deliveryFee: "",
      deliveryTime: "",
      minOrder: "",
    },
  };
};

export const isValidObject = (attributesArray, object) => {
  //usage
  //UTILS.isValidObject(['mdata','jockey'],entry)
  if (attributesArray === null) {
    if (object === null || typeof object === "undefined") return false;
    return true;
  } else if (object === null || typeof object === "undefined") return false;

  // NULL will not be considered as valid object
  const res = attributesArray.reduce((xs, x) => {
    return isValidObjectHelper(xs) && isValidObjectHelper(xs[x]) ? xs[x] : null;
  }, object);

  if (res === null) return false;
  return true;
};

function isValidObjectHelper(obj) {
  if (obj !== null && typeof obj !== "undefined") return true;
  return false;
}

export const hasWhiteSpace = (s) => {
  return !/\S/.test(s);
};

export const isValidMobileNumber = (mobileNumber) => {
  if (
    (mobileNumber?.charAt(4) == 0 && mobileNumber?.length !== 14) ||
    (mobileNumber?.charAt(4) != 0 && mobileNumber?.length !== 13)
  ) {
    return false;
  }
  return true;
};

export const getCurrentCurrency = () => {
  return "AED";
};

export const getSideMenuListForAddUpdateStore = () => {
  return [
    { name: "Basic Information", id: 0 },
    { name: "Store Location", id: 1 },
    { name: "Delivery", id: 2 },
    { name: "Pickup", id: 3 },
    { name: "Contactless Dining", id: 4 },
  ];
};

export const getCategoryModel = () => {
  return {
    values: {
      brandId: "",
      baseLanguage: {
        name: "",
      },
      language: "EN",
    },
    errors: {
      baseLanguage: {
        name: "",
      },
    },
  };
};

export const getItemModel = () => {
  return {
    values: {
      brandId: "",
      baseLanguage: {
        title: "",
        description: "",
        ingredients: "",
      },
      sku: "",
      itemImageURLs: [],
      itemAvailabilityStatus: true,
      isItemPriceGiven: true,
      price: 0,
    },
    errors: {
      baseLanguage: {
        title: "",
        description: "",
        ingredients: "",
      },
      sku: "",
      itemImageURLs: [""],
      itemAvailabilityStatus: true,
      isItemPriceGiven: true,
      price: "",
    },
  };
};

export const getModifierModal = () => {
  return {
    values: {
      brandId: "",
      itemId: "",
      itemSection: {
        baseLanguage: {
          sectionName: "",
        },
        isRequired: false,
        isSingleSelection: false,
        maximumAllowedSelection: 1,
        options: [],
      },
    },
    errors: {
      brandId: "",
      itemId: "",
      itemSection: {
        baseLanguage: {
          sectionName: "",
        },
        isRequired: false,
        isSingleSelection: false,
        maximumAllowedSelection: 0,
      },
    },
  };
};

export const getModifierOptionModal = () => {
  return {
    values: {
      baseLanguage: {
        optionName: "",
      },
      price: 0,
    },
    errors: {
      baseLanguage: {
        optionName: "",
      },
      price: 0,
    },
  };
};

export const getPendingOrders = (data, status) => {
  if (
    UTILS.isValidObject(["pendingOrdersCount"], data?.data) &&
    Array.isArray(data?.data.pendingOrdersCount)
  ) {
    for (let index = 0; index < data?.data.pendingOrdersCount.length; index++) {
      const element = data?.data.pendingOrdersCount[index];
      if (
        UTILS.isValidObject(["orderStatus"], element) &&
        element.orderStatus === status
      ) {
        return element.count;
      }
    }
    return 0;
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber === null || phoneNumber === undefined || phoneNumber === "")
    return null;
  if (phoneNumber?.charAt(4) === "0") {
    return phoneNumber?.slice(0, 4) + phoneNumber?.slice(5);
  }
  return phoneNumber;
};

export const validatePhoneNumber = (phoneNumber) => {
  if (phoneNumber === null || phoneNumber === undefined || phoneNumber === "") {
    return false;
  }
  if (
    phoneNumber?.charAt(0) !== "0" &&
    (phoneNumber?.length >= 10 || phoneNumber?.length < 9)
  ) {
    return false;
  }
  if (phoneNumber?.charAt(0) === "0" && phoneNumber?.length === 10) {
    return true;
  }

  if (phoneNumber?.length === 9 && phoneNumber?.charAt(0) !== "0") {
    return true;
  }
};
export const getAreasByCityID = (cityID, filtredareas) => {
  try {
    let areas = [];
    if (filtredareas) {
      filtredareas?.forEach((area) => {
        if (area?.cityID === cityID) {
          areas.push(area);
        }
      });
      return areas;
    }
  } catch (error) {}
};

export const UpdatedAreas = async (storeId, brandId, token) => {
  try {
    const updatedAreas = await API.GET_STORE_DELIVERY_AREAS({
      storeId: storeId,
      brandId: brandId,
      token: token,
    });
    if (updatedAreas?.success === 1) {
      return updatedAreas?.data?.Areas;
    } else {
      return;
    }
  } catch (error) {
    console.error({ error });
  }
};

export const getSelectedAreas = async (areas) => {
  try {
    const selectedAreas = [];
    await areas?.forEach((area) => {
      if (area?.selected && area?.selected === true) {
        selectedAreas.push(area);
      }
    });
    return selectedAreas;
  } catch (err) {}
};
export const getSelectedCountries = async (allCountries) => {
  try {
    const countries = [];
    await allCountries?.forEach((country) => {
      if (country?.selected && country?.selected === true) {
        countries.push(country);
      }
    });
    return countries;
  } catch (err) {}
};

export const filteredAreasList = async (areasData, alreadyAddedAreas) => {
  try {
    const filteredArray = [];
    if (areasData && alreadyAddedAreas) {
      areasData[0]?.cities?.map((city) => {
        city?.areas?.map((area) => {
          if (
            !alreadyAddedAreas?.find((item) => item?.areaID?._id === area?._id)
          ) {
            filteredArray.push({
              ...area,
              isAdded: false,
            });
          } else {
            filteredArray.push({
              ...area,
              isAdded: true,
            });
          }
        });
      });
      return filteredArray;
    }
  } catch (error) {
    console.error({ error });
  }
};
const getFormattedTime = (time) => {
  moment.tz.setDefault("Asia/Dubai");
  return moment(time).format("hh:mm a");
};
const getTimeDiference = (time1, time2) => {
  if (
    time1 === undefined ||
    time2 === undefined ||
    time1 == "" ||
    time2 == ""
  ) {
    return;
  }
  const diff = moment
    .duration(moment(time1, "hh:mm a").diff(moment(time2, "hh:mm a")))
    .asMinutes();
  return diff;
};

const isStartTimeNull = (t1, t2) => {
  if (t1 > 0 && (t2 === 0 || t2 === null)) {
    return true;
  }
};

const isEndTimeNull = (t1, t2) => {
  if (t2 > 0 && (t1 === 0 || t1 === null)) {
    return true;
  }
};

const formatSlot2StartTime = (slot2StartTime) => {
  const formatted = slot2StartTime > 0 ? getFormattedTime(slot2StartTime) : "";
  return formatted;
};

const formatSlot2EndTime = (slot2EndTime) => {
  let formatted;
  if (slot2EndTime !== null && slot2EndTime != "0") {
    formatted = getFormattedTime(slot2EndTime);
  }

  return formatted;
};

export const isValidSlots = (workingHours) => {
  const errorMessage = [
    i18next.t("Please select start time"),
    i18next.t("Please select end time"),
    i18next.t("Hours are overlapping"),
    i18next.t("The end time must be a date after the start time"),
    i18next.t("First slot shall be earlier than second slot"),
  ];
  const {
    fridayStartTime,
    fridayEndTime,
    fridayStartTimeSlot2,
    fridayEndTimeSlot2,
    saturdayStartTime,
    saturdayEndTime,
    saturdayStartTimeSlot2,
    saturdayEndTimeSlot2,
    sundayStartTimeSlot2,
    sundayStartTime,
    sundayEndTime,
    sundayEndTimeSlot2,
    mondayStartTime,
    mondayEndTime,
    mondayStartTimeSlot2,
    mondayEndTimeSlot2,
    tuesdayStartTime,
    tuesdayEndTime,
    tuesdayStartTimeSlot2,
    tuesdayEndTimeSlot2,
    wednesdayStartTime,
    wednesdayEndTime,
    wednesdayStartTimeSlot2,
    wednesdayEndTimeSlot2,
    thursdayStartTime,
    thursdayEndTime,
    thursdayStartTimeSlot2,
    thursdayEndTimeSlot2,
  } = workingHours;
  let isFormValid = true;
  let errors = {};

  // step1==>format time

  // Friday
  var fridayS1Start = getFormattedTime(fridayStartTime);
  var fridayS1End = getFormattedTime(fridayEndTime);
  var fridayS2Start = formatSlot2StartTime(fridayStartTimeSlot2);
  var fridayS2End = formatSlot2EndTime(fridayEndTimeSlot2);

  // saturday
  var satursdayS1Start = getFormattedTime(saturdayStartTime);
  var satursdayS1End = getFormattedTime(saturdayEndTime);
  var saturdayS2Start = formatSlot2StartTime(saturdayStartTimeSlot2);
  var saturdayS2End = formatSlot2EndTime(saturdayEndTimeSlot2);

  //sunday

  var sundayS1Start = getFormattedTime(sundayStartTime);
  var sundayS1End = getFormattedTime(sundayEndTime);
  var sundayS2Start = formatSlot2StartTime(sundayStartTimeSlot2);
  var sundayS2End = formatSlot2EndTime(sundayEndTimeSlot2);

  // monday
  var mondayS1Start = getFormattedTime(mondayStartTime);
  var mondayS1End = getFormattedTime(mondayEndTime);
  var mondayS2Start = formatSlot2StartTime(mondayStartTimeSlot2);
  var mondayS2End = formatSlot2EndTime(mondayEndTimeSlot2);

  //tuesday
  var tuesdayS1Start = getFormattedTime(tuesdayStartTime);
  var tuesdayS1End = getFormattedTime(tuesdayEndTime);
  var tuesdayS2Start = formatSlot2StartTime(tuesdayStartTimeSlot2);
  var tuesdayS2End = formatSlot2EndTime(tuesdayEndTimeSlot2);

  //wednesday
  var wednesdayS1Start = getFormattedTime(wednesdayStartTime);
  var wednesdayS1End = getFormattedTime(wednesdayEndTime);
  var wednesdayS2Start = formatSlot2StartTime(wednesdayStartTimeSlot2);
  var wednesdayS2End = formatSlot2EndTime(wednesdayEndTimeSlot2);

  //thursday
  var thursdayS1Start = getFormattedTime(thursdayStartTime);
  var thursdayS1End = getFormattedTime(thursdayEndTime);
  var thursdayS2Start = formatSlot2StartTime(thursdayStartTimeSlot2);
  var thursdayS2End = formatSlot2EndTime(thursdayEndTimeSlot2);

  // step2==>get differnce between slots and add error messages
  // friday
  var diff1Day1 = getTimeDiference(fridayS1Start, fridayS1End);
  var diff2Day1 = getTimeDiference(fridayS2Start, fridayS2End);
  var diff3Day1 = getTimeDiference(fridayS1Start, fridayS2End);
  var diff4Day1 = getTimeDiference(fridayS1End, fridayS2End);

  if (isStartTimeNull(fridayEndTimeSlot2, fridayStartTimeSlot2)) {
    errors.fridayStartTimeSlot2 = errorMessage?.[0];
    isFormValid = false;
  }
  if (isEndTimeNull(fridayEndTimeSlot2, fridayStartTimeSlot2)) {
    errors.fridayStartTimeSlot2 = errorMessage?.[1];
    isFormValid = false;
  }
  if (diff1Day1 >= 0) {
    errors[C.KEY_FRI_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff2Day1 >= 0) {
    errors[C.KEY_FRI_SLOT2_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }

  if (diff3Day1 > 0 && diff4Day1 > 0) {
    if (fridayEndTimeSlot2 !== 0) {
      errors[C.KEY_FRI_START_TIME] = errorMessage?.[4];
      isFormValid = false;
    }
  }
  var diff5Day1 = getTimeDiference(fridayS1Start, fridayS2End);
  var diff6Day1 = getTimeDiference(fridayS1End, fridayS2Start);
  if (diff5Day1 <= 0 && diff6Day1 >= 0) {
    errors[C.KEY_FRI_SLOT2_START_TIME] = errorMessage?.[2];
    isFormValid = false;
  }

  // saturday

  if (isStartTimeNull(saturdayEndTimeSlot2, saturdayStartTimeSlot2)) {
    errors.saturdayStartTimeSlot2 = errorMessage?.[0];
    isFormValid = false;
  }
  if (isEndTimeNull(saturdayEndTimeSlot2, saturdayStartTimeSlot2)) {
    errors.saturdayStartTimeSlot2 = errorMessage?.[1];
    isFormValid = false;
  }
  var diff1Day2 = getTimeDiference(satursdayS1Start, satursdayS1End);
  var diff2Day2 = getTimeDiference(saturdayS2Start, saturdayS2End);
  var diff3Day2 = getTimeDiference(satursdayS1Start, saturdayS2End);
  var diff4Day2 = getTimeDiference(satursdayS1End, saturdayS2End);
  var diff5Day2 = getTimeDiference(satursdayS1Start, saturdayS2End);
  var diff6Day2 = getTimeDiference(satursdayS1End, saturdayS2Start);
  if (diff1Day2 >= 0) {
    errors[C.KEY_SAT_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff2Day2 >= 0) {
    errors[C.KEY_SAT_SLOT2_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff3Day2 > 0 && diff4Day2 > 0 && saturdayEndTimeSlot2 !== null) {
    errors[C.KEY_SAT_START_TIME] = errorMessage?.[4];
    isFormValid = false;
  }

  if (diff5Day2 <= 0 && diff6Day2 >= 0) {
    errors[C.KEY_SAT_SLOT2_START_TIME] = errorMessage?.[2];
    isFormValid = false;
  }

  // sunday
  if (isStartTimeNull(sundayEndTimeSlot2, sundayStartTimeSlot2)) {
    errors.sundayStartTimeSlot2 = errorMessage?.[0];
    isFormValid = false;
  }
  if (isEndTimeNull(sundayEndTimeSlot2, sundayStartTimeSlot2)) {
    errors.sundayStartTimeSlot2 = errorMessage?.[1];
    isFormValid = false;
  }
  var diff1Day3 = getTimeDiference(sundayS1Start, sundayS1End);
  var diff2Day3 = getTimeDiference(sundayS2Start, sundayS2End);
  var diff3Day3 = getTimeDiference(sundayS1Start, sundayS2End);
  var diff4Day3 = getTimeDiference(sundayS1End, sundayS2End);
  var diff5Day3 = getTimeDiference(sundayS1Start, sundayS2End);
  var diff6Day3 = getTimeDiference(sundayS1End, sundayS2Start);
  if (diff1Day3 >= 0) {
    errors[C.KEY_SUN_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff2Day3 >= 0) {
    errors[C.KEY_SUN_SLOT2_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff3Day3 > 0 && diff4Day3 > 0 && sundayEndTimeSlot2 !== null) {
    errors[C.KEY_SUN_START_TIME] = errorMessage?.[4];
    isFormValid = false;
  }
  if (diff5Day3 <= 0 && diff6Day3 >= 0) {
    errors[C.KEY_SUN_SLOT2_START_TIME] = errorMessage?.[2];
    isFormValid = false;
  }

  // monday
  var diff1Day4 = getTimeDiference(mondayS1Start, mondayS1End);
  var diff2Day4 = getTimeDiference(mondayS2Start, mondayS2End);
  var diff3Day4 = getTimeDiference(mondayS1Start, mondayS2End);
  var diff4Day4 = getTimeDiference(mondayS1End, mondayS2End);
  var diff5Day4 = getTimeDiference(mondayS1Start, mondayS2End);
  var diff6Day4 = getTimeDiference(mondayS1End, mondayS2Start);
  if (isStartTimeNull(mondayEndTimeSlot2, mondayStartTimeSlot2)) {
    errors.mondayStartTimeSlot2 = errorMessage?.[0];
    isFormValid = false;
  }
  if (isEndTimeNull(mondayEndTimeSlot2, mondayStartTimeSlot2)) {
    errors.mondayStartTimeSlot2 = errorMessage?.[1];
    isFormValid = false;
  }
  if (diff1Day4 >= 0) {
    errors[C.KEY_MON_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff2Day4 >= 0) {
    errors[C.KEY_MON_SLOT2_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff3Day4 > 0 && diff4Day4 > 0 && mondayEndTimeSlot2 !== null) {
    errors[C.KEY_MON_START_TIME] = errorMessage?.[4];
    isFormValid = false;
  }
  if (diff5Day4 <= 0 && diff6Day4 >= 0) {
    errors[C.KEY_MON_SLOT2_START_TIME] = errorMessage?.[2];
    isFormValid = false;
  }

  // tuesday
  var diff1Day5 = getTimeDiference(tuesdayS1Start, tuesdayS1End);
  var diff2Day5 = getTimeDiference(tuesdayS2Start, tuesdayS2End);
  var diff3Day5 = getTimeDiference(tuesdayS1Start, tuesdayS2End);
  var diff4Day5 = getTimeDiference(tuesdayS1End, tuesdayS2End);
  var diff5Day5 = getTimeDiference(tuesdayS1Start, tuesdayS2End);
  var diff6Day5 = getTimeDiference(tuesdayS1End, tuesdayS2Start);
  if (isStartTimeNull(tuesdayEndTimeSlot2, tuesdayStartTimeSlot2)) {
    errors.tuesdayStartTimeSlot2 = errorMessage?.[0];
    isFormValid = false;
  }
  if (isEndTimeNull(tuesdayEndTimeSlot2, tuesdayStartTimeSlot2)) {
    errors.tuesdayStartTimeSlot2 = errorMessage?.[1];
    isFormValid = false;
  }

  if (diff1Day5 >= 0) {
    errors[C.KEY_TUE_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff2Day5 >= 0) {
    errors[C.KEY_TUE_SLOT2_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff3Day5 > 0 && diff4Day5 > 0 && tuesdayEndTimeSlot2 !== null) {
    errors[C.KEY_TUE_START_TIME] = errorMessage?.[4];
    isFormValid = false;
  }
  if (diff5Day5 <= 0 && diff6Day5 >= 0) {
    errors[C.KEY_TUE_SLOT2_START_TIME] = errorMessage?.[2];
    isFormValid = false;
  }

  // wednesday

  var diff1Day6 = getTimeDiference(wednesdayS1Start, wednesdayS1End);
  var diff2Day6 = getTimeDiference(wednesdayS2Start, wednesdayS2End);
  var diff3Day6 = getTimeDiference(wednesdayS1Start, wednesdayS2End);
  var diff4Day6 = getTimeDiference(wednesdayS1End, wednesdayS2End);
  var diff5Day6 = getTimeDiference(wednesdayS1Start, wednesdayS2End);
  var diff6Day6 = getTimeDiference(wednesdayS1End, wednesdayS2Start);
  if (isStartTimeNull(wednesdayEndTimeSlot2, wednesdayStartTimeSlot2)) {
    errors.wednesdayStartTimeSlot2 = errorMessage?.[0];
    isFormValid = false;
  }
  if (isEndTimeNull(wednesdayEndTimeSlot2, wednesdayStartTimeSlot2)) {
    errors.wednesdayStartTimeSlot2 = errorMessage?.[1];
    isFormValid = false;
  }
  if (diff1Day6 >= 0) {
    errors[C.KEY_WED_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff2Day6 >= 0) {
    errors[C.KEY_WED_SLOT2_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff3Day6 > 0 && diff4Day6 > 0 && wednesdayEndTimeSlot2 !== null) {
    errors[C.KEY_WED_START_TIME] = errorMessage?.[4];
    isFormValid = false;
  }
  if (diff5Day6 <= 0 && diff6Day6 >= 0) {
    errors[C.KEY_WED_SLOT2_START_TIME] = errorMessage?.[2];
    isFormValid = false;
  }

  // thursday
  var diff1Day7 = getTimeDiference(thursdayS1Start, thursdayS1End);
  var diff2Day7 = getTimeDiference(thursdayS2Start, thursdayS2End);
  var diff3Day7 = getTimeDiference(thursdayS1Start, thursdayS2End);
  var diff4Day7 = getTimeDiference(thursdayS1End, thursdayS2End);
  var diff5Day7 = getTimeDiference(thursdayS1Start, thursdayS2End);
  var diff6Day7 = getTimeDiference(thursdayS1End, thursdayS2Start);
  if (isStartTimeNull(thursdayEndTimeSlot2, thursdayStartTimeSlot2)) {
    errors.thursdayStartTimeSlot2 = errorMessage?.[0];
    isFormValid = false;
  }
  if (isEndTimeNull(thursdayEndTimeSlot2, thursdayStartTimeSlot2)) {
    errors.thursdayStartTimeSlot2 = errorMessage?.[1];
    isFormValid = false;
  }
  if (diff1Day7 >= 0) {
    errors[C.KEY_THU_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff2Day7 >= 0) {
    errors[C.KEY_THU_SLOT2_START_TIME] = errorMessage?.[3];
    isFormValid = false;
  }
  if (diff3Day7 > 0 && diff4Day7 > 0 && thursdayEndTimeSlot2 !== null) {
    errors[C.KEY_THU_START_TIME] = errorMessage?.[4];
    isFormValid = false;
  }
  if (diff5Day7 <= 0 && diff6Day7 >= 0) {
    errors[C.KEY_THU_SLOT2_START_TIME] = errorMessage?.[2];
    isFormValid = false;
  }

  return { isFormValid, errors };
};

export const codEarning = (orders) => {
  const earning = orders?.orders.reduce((acc, order) => {
    if (
      (order?.paymentType === "CARD_MACHINE" &&
        order?.orderStatus === "COMPLETE") ||
      (order?.paymentType === "CASH" && order?.orderStatus === "COMPLETE")
    ) {
      return acc + order.totalOrderPrice;
    }
    return acc;
  }, 0);
  return earning?.toFixed(2);
};

export const onlineEarning = (orders) => {
  const earning = orders.reduce((acc, order) => {
    if (
      order?.paymentType === "CREDIT_CARD" &&
      order?.orderCheckoutOnlinePaymentMetadata?.data?.status === "VERIFIED" &&
      order?.orderStatus === "COMPLETE"
    ) {
      return acc + order.totalOrderPrice;
    }
    return acc;
  }, 0);
  return earning?.toFixed(2);
};

export const onlineOrders = (orders) => {
  console.log("Online Orders", orders.orders);
  if (orders?.orders?.length <= 0 || !orders?.orders) return [];
  const ordersArray = orders?.orders?.filter(
    (order) => order?.paymentType === "CREDIT_CARD"
  );
  return ordersArray;
};

export const codOrders = (orders) => {
  if (orders?.orders?.length <= 0 || !orders?.orders) return [];
  const ordersArray = orders?.orders?.filter(
    (order) =>
      order?.paymentType === "CARD_MACHINE" || order?.paymentType === "CASH"
  );
  return ordersArray;
};

export const getEachCustomercodEarning = (orders) => {
  const earning = orders.reduce((acc, order) => {
    if (
      order?.paymentType === "CARD_MACHINE" ||
      order?.paymentType === "CASH"
    ) {
      return acc + order.totalOrderPrice;
    }
    return acc;
  }, 0);
  return earning?.toFixed(2);
};
export const getEachCustomeronlineEarning = (orders) => {
  const earning = orders.reduce((acc, order) => {
    if (
      order?.paymentType === "CREDIT_CARD" &&
      order?.orderCheckoutOnlinePaymentMetadata?.data?.status === "VERIFIED"
    ) {
      return acc + order.totalOrderPrice;
    }
    return acc;
  }, 0);
  return earning?.toFixed(2);
};
export const getCustomerName = (orders) => {
  const order = orders?.find((order) => order?.userInfo?.name);
  return order?.userInfo?.name;
};

export const getEachCustomeData = async (orders) => {
  const customers = [];
  orders.forEach((order) => {
    const cod = getEachCustomercodEarning(order.orderDetails);
    const online = getEachCustomeronlineEarning(order.orderDetails);
    const name = getCustomerName(order.orderDetails);
    customers.push({
      customerId: order?._id?.userId,
      customerPhone: order?.orderDetails[0]?.userInfo?.phoneNumber,
      codEarning: cod,
      onlineEarning: online,
      name,
    });
  });
  return customers;
};

export const getTotalEarning = (paymentLinks) => {
  const earning = paymentLinks?.reduce((acc, order) => {
    if (order?.linkStatus === "PAID") {
      return acc + order.totalOrderPrice;
    }
    return acc;
  }, 0);
  return earning?.toFixed(2);
};

export const totalLinksEarning = (paymentLinks) => {
  console.log("totalLinksEarning", paymentLinks);
  if (paymentLinks?.length <= 0 || !paymentLinks) return [];
  const earning = paymentLinks?.reduce((acc, order) => {
    if (order?.linkStatus === "PAID") {
      return acc + order.amount;
    }
    return acc;
  }, 0);
  return earning?.toFixed(2);
};

export const handleSuccesNotification = (message) => {
  UTILS.HANDLE_SUCCESS_NOTIFICATION("topRight", "success", message);
};
export const handleErrorNotification = (message) => {
  UTILS.HANDLE_ERROR_NOTIFICATION("topRight", "error", message);
};

export const validateSelectedDates = (startDate, endDate) => {
  let isFormValid = true;
  if (!startDate || !endDate) {
    handleErrorNotification(
      i18next.t("start date and end date cannot be empty")
    );
    isFormValid = false;
  }
  return isFormValid;
};

export const checkPackageValidity = async (language) => {
  let text;
  let notify;
  const userDetail = UTILS.getCurrentUser();
  const result = await API.CHECK_PACKAGE_VALIDITY({
    token: userDetail?.token,
    brandId: userDetail?.brandId,
    language: language,
  });
  if (result && result.success === 1) {
    if (
      result?.data?.subscription?.amountPaid < 1 &&
      result?.data?.subscription?.isValid
    ) {
      text = "trail plan";
      notify = true;
    } else if (!result?.data?.subscription?.isValid) {
      text = "expired plan";
      notify = true;
    }
  } else {
  }

  return { text, notify, result };
};

export const getStoreObj = (store) => {
  const obj = {
    baseLanguage: {
      name: store.baseLanguage.name,
      description: store.baseLanguage.description,
    },
    storeLocation: {
      type: "Point",
      coordinates: [
        store.storeLocation.coordinates[0],
        store.storeLocation.coordinates[1],
      ],
    },

    email: store.email,
    mobileNumber: store.mobileNumber,
    whatsAppContact: store.whatsAppContact,
    instagramId: "N/A",
    storeImageUrl: "",
    storeLogoUrl: "",
    manualAddress: store.manualAddress,
    mapAddress: store.mapAddress,
    isDeliveryEnabled: store.isDeliveryEnabled,
    isDineInEnabled: store.isDineInEnabled,
    isPickupEnabled: store.isPickupEnabled,
    delivery: {
      workingHours: {
        mondayStartTime: store.workingHours.mondayStartTime,
        mondayStartTimeSlot2: store.workingHours.mondayStartTimeSlot2,
        mondayEndTime: store.workingHours.mondayEndTime,
        mondayEndTimeSlot2: store.workingHours.mondayEndTimeSlot2,
        monday: store.workingHours.monday,
        tuesdayStartTime: store.workingHours.tuesdayStartTime,
        tuesdayStartTimeSlot2: store.workingHours.tuesdayStartTimeSlot2,
        tuesdayEndTime: store.workingHours.tuesdayEndTime,
        tuesdayEndTimeSlot2: store.workingHours.tuesdayEndTimeSlot2,
        tuesday: store.workingHours.tuesday,
        wednesdayStartTime: store.workingHours.wednesdayStartTime,
        wednesdayStartTimeSlot2: store.workingHours.wednesdayStartTimeSlot2,
        wednesdayEndTime: store.workingHours.wednesdayEndTime,
        wednesdayEndTimeSlot2: store.workingHours.wednesdayEndTimeSlot2,
        wednesday: store.workingHours.wednesday,
        thursdayStartTime: store.workingHours.thursdayStartTime,
        thursdayStartTimeSlot2: store.workingHours.thursdayStartTimeSlot2,
        thursdayEndTime: store.workingHours.thursdayEndTime,
        thursdayEndTimeSlot2: store.workingHours.thursdayEndTimeSlot2,
        thursday: store.workingHours.thursday,
        fridayStartTime: store.workingHours.fridayStartTime,
        fridayStartTimeSlot2: store.workingHours.fridayStartTimeSlot2,
        fridayEndTime: store.workingHours.fridayEndTime,
        fridayEndTimeSlot2: store.workingHours.fridayEndTimeSlot2,
        friday: store.workingHours.friday,
        saturdayStartTime: store.workingHours.saturdayStartTime,
        saturdayStartTimeSlot2: store.workingHours.saturdayStartTimeSlot2,
        saturdayEndTime: store.workingHours.saturdayEndTime,
        saturdayEndTimeSlot2: store.workingHours.saturdayEndTimeSlot2,
        saturday: store.workingHours.saturday,
        sundayStartTime: store.workingHours.sundayStartTime,
        sundayStartTimeSlot2: store.workingHours.sundayStartTimeSlot2,
        sundayEndTime: store.workingHours.sundayEndTime,
        sundayEndTimeSlot2: store.workingHours.sundayEndTimeSlot2,
        sunday: store.workingHours.sunday,
      },
      averagePrepTime: store.averagePrepTime,
      isStoreOpen24Hours: store.isStoreOpen24Hours,
      paymentOptions: store.paymentOptions,

      //preOrdering
      isAcceptQuickOrderForDelivery: store.isAcceptQuickOrderForDelivery,
      isPreOrderEnabledForDelivery: store.isPreOrderEnabledForDelivery,
      preOrder: {
        ...store.preOrderSettingsForDelivery,
      },
      deliveryChargesWaiverInfo: {
        ...(+store?.deliveryChargesWaiverInfo?.onOrderAmount?.amount > 0 && {
          onOrderAmount: {
            isEnabled:
              store?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled,
            amount: +store?.deliveryChargesWaiverInfo?.onOrderAmount?.amount,
          },
        }),

        ...(store?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime &&
          store?.deliveryChargesWaiverInfo?.onDateRange?.endDateTime && {
            onDateRange: {
              isEnabled:
                store?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled,
              startDateTime:
                store?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime,
              endDateTime:
                store?.deliveryChargesWaiverInfo?.onDateRange?.endDateTime,
            },
          }),
      },
    },
    pickup: {
      workingHoursForPickup: {
        mondayStartTime: store.workingHoursForPickup.mondayStartTime,
        mondayStartTimeSlot2: store.workingHoursForPickup.mondayStartTimeSlot2,
        mondayEndTime: store.workingHoursForPickup.mondayEndTime,
        mondayEndTimeSlot2: store.workingHoursForPickup.mondayEndTimeSlot2,
        monday: store.workingHoursForPickup.monday,
        tuesdayStartTime: store.workingHoursForPickup.tuesdayStartTime,
        tuesdayStartTimeSlot2:
          store.workingHoursForPickup.tuesdayStartTimeSlot2,
        tuesdayEndTime: store.workingHoursForPickup.tuesdayEndTime,
        tuesdayEndTimeSlot2: store.workingHoursForPickup.tuesdayEndTimeSlot2,
        tuesday: store.workingHoursForPickup.tuesday,
        wednesdayStartTime: store.workingHoursForPickup.wednesdayStartTime,
        wednesdayStartTimeSlot2:
          store.workingHoursForPickup.wednesdayStartTimeSlot2,
        wednesdayEndTime: store.workingHoursForPickup.wednesdayEndTime,
        wednesdayEndTimeSlot2:
          store.workingHoursForPickup.wednesdayEndTimeSlot2,
        wednesday: store.workingHoursForPickup.wednesday,
        thursdayStartTime: store.workingHoursForPickup.thursdayStartTime,
        thursdayStartTimeSlot2:
          store.workingHoursForPickup.thursdayStartTimeSlot2,
        thursdayEndTime: store.workingHoursForPickup.thursdayEndTime,
        thursdayEndTimeSlot2: store.workingHoursForPickup.thursdayEndTimeSlot2,
        thursday: store.workingHoursForPickup.thursday,
        fridayStartTime: store.workingHoursForPickup.fridayStartTime,
        fridayStartTimeSlot2: store.workingHoursForPickup.fridayStartTimeSlot2,
        fridayEndTime: store.workingHoursForPickup.fridayEndTime,
        fridayEndTimeSlot2: store.workingHoursForPickup.fridayEndTimeSlot2,
        friday: store.workingHoursForPickup.friday,
        saturdayStartTime: store.workingHoursForPickup.saturdayStartTime,
        saturdayStartTimeSlot2:
          store.workingHoursForPickup.saturdayStartTimeSlot2,
        saturdayEndTime: store.workingHoursForPickup.saturdayEndTime,
        saturdayEndTimeSlot2: store.workingHoursForPickup.saturdayEndTimeSlot2,
        saturday: store.workingHoursForPickup.saturday,
        sundayStartTime: store.workingHoursForPickup.sundayStartTime,
        sundayStartTimeSlot2: store.workingHoursForPickup.sundayStartTimeSlot2,
        sundayEndTime: store.workingHoursForPickup.sundayEndTime,
        sundayEndTimeSlot2: store.workingHoursForPickup.sundayEndTimeSlot2,
        sunday: store.workingHoursForPickup.sunday,
      },
      averagePrepTimeForPickup: store.averagePrepTimeForPickup,
      isAcceptQuickOrderForPickup: store.isAcceptQuickOrderForPickup,
      isStoreOpen24HoursForPickup: store.isStoreOpen24HoursForPickup,
      pickupPaymentOptions: store.pickupPaymentOptions,
      vehicleInfoForPickup: {
        isActive: store.vehicleInfoForPickup.isActive,
        isRequired: store.vehicleInfoForPickup.isRequired,
      },
      isPreOrderEnabledForPickup: store.isPreOrderEnabledForPickup,
      preOrder: {
        ...store.preOrderSettingsForPickup,
      },
    },
    dineIn: {
      workingHoursForDinein: {
        mondayStartTime: store.workingHoursForDinein.mondayStartTime,
        mondayStartTimeSlot2: store.workingHoursForDinein.mondayStartTimeSlot2,
        mondayEndTime: store.workingHoursForDinein.mondayEndTime,
        mondayEndTimeSlot2: store.workingHoursForDinein.mondayEndTimeSlot2,
        monday: store.workingHoursForDinein.monday,
        tuesdayStartTime: store.workingHoursForDinein.tuesdayStartTime,
        tuesdayStartTimeSlot2:
          store.workingHoursForDinein.tuesdayStartTimeSlot2,
        tuesdayEndTime: store.workingHoursForDinein.tuesdayEndTime,
        tuesdayEndTimeSlot2: store.workingHoursForDinein.tuesdayEndTimeSlot2,
        tuesday: store.workingHoursForDinein.tuesday,
        wednesdayStartTime: store.workingHoursForDinein.wednesdayStartTime,
        wednesdayStartTimeSlot2:
          store.workingHoursForDinein.wednesdayStartTimeSlot2,
        wednesdayEndTime: store.workingHoursForDinein.wednesdayEndTime,
        wednesdayEndTimeSlot2:
          store.workingHoursForDinein.wednesdayEndTimeSlot2,
        wednesday: store.workingHoursForDinein.wednesday,
        thursdayStartTime: store.workingHoursForDinein.thursdayStartTime,
        thursdayStartTimeSlot2:
          store.workingHoursForDinein.thursdayStartTimeSlot2,
        thursdayEndTime: store.workingHoursForDinein.thursdayEndTime,
        thursdayEndTimeSlot2: store.workingHoursForDinein.thursdayEndTimeSlot2,
        thursday: store.workingHoursForDinein.thursday,
        fridayStartTime: store.workingHoursForDinein.fridayStartTime,
        fridayStartTimeSlot2: store.workingHoursForDinein.fridayStartTimeSlot2,
        fridayEndTime: store.workingHoursForDinein.fridayEndTime,
        fridayEndTimeSlot2: store.workingHoursForDinein.fridayEndTimeSlot2,
        friday: store.workingHoursForDinein.friday,
        saturdayStartTime: store.workingHoursForDinein.saturdayStartTime,
        saturdayStartTimeSlot2:
          store.workingHoursForDinein.saturdayStartTimeSlot2,
        saturdayEndTime: store.workingHoursForDinein.saturdayEndTime,
        saturdayEndTimeSlot2: store.workingHoursForDinein.saturdayEndTimeSlot2,
        saturday: store.workingHoursForDinein.saturday,
        sundayStartTime: store.workingHoursForDinein.sundayStartTime,
        sundayStartTimeSlot2: store.workingHoursForDinein.sundayStartTimeSlot2,
        sundayEndTime: store.workingHoursForDinein.sundayEndTime,
        sundayEndTimeSlot2: store.workingHoursForDinein.sundayEndTimeSlot2,
        sunday: store.workingHoursForDinein.sunday,
      },
      averagePrepTimeForDinein: store.averagePrepTimeForDinein,
      isStoreOpen24HoursForDinein: store.isStoreOpen24HoursForDinein,
      dineInPaymentOptions: store.dineInPaymentOptions,
    },

    serviceType: store.serviceType,
    isOpened: store.isOpened,
    language: "EN",
    country: store.country,
    city: store.city,
    taxRate: store.taxRate,
    timeZone: "Asia/Dubai",
  };
  return obj;
};

// discount validation
export const validateCouponsInputs = (state) => {
  let startDate = moment(state.values.isoDate).locale("en").format();
  let endDate = moment(state.values.isoEndDate).locale("en").format();
  const errorMessages = {
    discountName: i18next?.t("Discount name is required"),
    couponCode: i18next?.t("Coupon code is required"),
    couponCodeInvalid: i18next?.t(
      "Coupon code should not contain any special characters or spaces"
    ),
    discountAmount: i18next?.t("Discount amount is required"),
    maxAmountAllowd: i18next?.t(
      "Coupons with percentage types should not have an amount field greater than 100"
    ),
    discountAmountInvalid: i18next?.t("Discount amount must be greater than 0"),
    startDate: i18next?.t("Discount start date is required"),
    endDate: i18next?.t("Discount end date is required"),
    storeSelection: i18next?.t("Please select at least one store"),
    itemSelection: i18next?.t("Please choose at least one item"),
    perCustomerUse: i18next?.t("Per customer use must be greater than zero"),
    minOrderValue: i18next?.t("Minimum order value can't be empty"),
    maxUsage: i18next?.t("Total number of uses must be greater than zero"),
    maximumDiscountValue: i18next?.t(
      "Maximum discount must be greater than zero"
    ),
    serviceType: i18next?.t("An order type selection is required"),
  };
  let isFormValid = true;
  let errors = {};

  if (state?.values?.discountName === "") {
    errors.discountName = errorMessages.discountName;
    errorNotification(errorMessages.discountName);
    return { isFormValid: false, errors };
  }
  if (state?.values?.discountCode === "") {
    errors.couponCode = errorMessages.couponCode;
    errorNotification(errorMessages.couponCode);
    return { isFormValid: false, errors };
  }
  if (
    state?.values?.discountCode.match(/[^a-zA-Z0-9]/g) &&
    state?.values?.discountCode !== ""
  ) {
    errors.couponCode = errorMessages.couponCodeInvalid;
    errorNotification(errorMessages.couponCodeInvalid);
    return { isFormValid: false, errors };
  }

  if (state?.values?.discountAmount === "") {
    errors.discountAmount = errorMessages.discountAmount;
    errorNotification(errorMessages.discountAmount);
    return { isFormValid: false, errors };
  }

  if (state?.values?.discountAmount <= 0) {
    errors.discountAmount = errorMessages.discountAmountInvalid;
    errorNotification(errorMessages.discountAmountInvalid);
    return { isFormValid: false, errors };
  }
  if (
    state?.values?.discountAmount !== "" &&
    state?.values?.discountAmount > 100 &&
    state?.values?.discountType === C.PERCENTAGE
  ) {
    errors.maxAmountAld = errorMessages.maxAmountAllowd;
    errorNotification(errorMessages.maxAmountAllowd);
    return { isFormValid: false, errors };
  }
  if (startDate === "" || startDate === "Invalid date") {
    errors.startDate = errorMessages.startDate;
    errorNotification(errorMessages.startDate);
    return { isFormValid: false, errors };
  }
  if (endDate === "" || endDate === "Invalid date") {
    errors.endDate = errorMessages.endDate;
    errorNotification(errorMessages.endDate);
    return { isFormValid: false, errors };
  }

  if (
    state?.values?.outletSelectionType === C.SELECTED &&
    state?.values?.selectedOutlets?.length <= 0
  ) {
    errors.storeSelection = errorMessages.storeSelection;
    errorNotification(errorMessages.storeSelection);
    return { isFormValid: false, errors };
  }
  if (
    state?.values?.itemSelectionType === C.SELECTED &&
    state?.values?.selectedItems?.length <= 0
  ) {
    errors.itemSelection = errorMessages.itemSelection;
    errorNotification(errorMessages.itemSelection);
    return { isFormValid: false, errors };
  }
  if (
    state.values.perCustomerUse === "" ||
    isNaN(state.values.perCustomerUse) ||
    state?.values?.perCustomerUse <= 0
  ) {
    errors.perCustomerUse = errorMessages.perCustomerUse;
    errorNotification(errorMessages.perCustomerUse);
    return { isFormValid: false, errors };
  }
  if (state.values.minOrderValue === "" || isNaN(state.values.minOrderValue)) {
    errors.minOrderValue = errorMessages.minOrderValue;
    errorNotification(errorMessages.minOrderValue);
    return { isFormValid: false, errors };
  }
  if (
    state?.values?.maxUsage === "" ||
    isNaN(state.values.maxUsage) ||
    state.values.maxUsage <= 0
  ) {
    errors.maxUsage = errorMessages.maxUsage;
    errorNotification(errorMessages.maxUsage);
    return { isFormValid: false, errors };
  }
  if (
    state.values.maximumDiscountValue === "" ||
    isNaN(state.values.maximumDiscountValue) ||
    (state.values.maximumDiscountValue <= 0 &&
      state.values.discountType === C.PERCENTAGE)
  ) {
    errors.maximumDiscountValue = errorMessages.maximumDiscountValue;
    errorNotification(errorMessages.maximumDiscountValue);
    return { isFormValid: false, errors };
  }
  if (state?.values?.serviceType?.length <= 0) {
    errors.serviceType = errorMessages.serviceType;
    errorNotification(errorMessages.serviceType);
    return { isFormValid: false, errors };
  }

  return { isFormValid, errors };
};

// creating an object for create and update discount
export const getDiscountObj = (state) => {
  const storeIds = state?.values?.selectedOutlets?.map((store) => {
    return { storeId: store };
  });
  const itemIds = state?.values?.selectedItems?.map((item) => {
    return { itemId: item };
  });
  const obj = {
    startDate: moment(state.values.isoDate).locale("en").format(),
    endDate: moment(state.values.isoEndDate).locale("en").format(),
    discountApplication: state.values.applicationType,
    serviceType: state.values.serviceType,
    discountType: state.values.discountType,
    customerSegment: state.values.customerSegmentationType,
    discountName: state.values.discountName,
    discountCode: state.values.discountCode.toLocaleLowerCase(),
    brandId: UTILS.getCurrentUser()?.brandId,
    discountAmount: Math.round(state.values.discountAmount * 100) / 100,
    maxUsage: +state.values.maxUsage,
    isApplicableOnAllItems:
      state.values.itemSelectionType === C.ALL ? true : false,
    isApplicableOnAllStores:
      state.values.outletSelectionType === C.ALL ? true : false,
    discountAppliedOnSpecificStores:
      state.values.outletSelectionType === C.SELECTED ? storeIds : [],
    discountAppliedOnSpecificItems:
      state.values.itemSelectionType === C.SELECTED ? itemIds : [],
    minOrderValue: Math.round(state.values.minOrderValue),
    maximumDiscountValue:
      state.values.discountType === C.PERCENTAGE
        ? Math.round(state.values.maximumDiscountValue)
        : 0,
    isActive: true,
  };
  if (state?.values?.customerSegmentationType === "new") {
    obj.perCustomerUse = 1;
  } else {
    obj.perCustomerUse = +state?.values?.perCustomerUse;
  }

  return obj;
};

export const getSelectedDelivery = (order) => {
  return order?.logisticPartner;
};

export const OdrAlrdySndToTalabat = (order) => {
  if (
    order?.logisticPartnerMetadata?.TALABAT?.orderPlaceResponse?.order_id ===
      undefined ||
    order?.logisticPartnerMetadata?.TALABAT?.orderPlaceResponse?.order_id ===
      null
  ) {
    return false;
  } else {
    return true;
  }
};

export const ifnullOrUndefined = (value) => {
  if (value === undefined || value === null) {
    return true;
  } else {
    return false;
  }
};
export const ifNotNullAndUndfine = (value) => {
  if (value !== undefined && value !== null) {
    return true;
  } else {
    return false;
  }
};
export const Undfined = (value) => {
  if (value === undefined) {
    return true;
  } else {
    return false;
  }
};
export const validateTalabat = (value) => {
  let message = "Something went wrong,please try later";
  if (ifnullOrUndefined(value?.storeId)) {
    errorNotification(message);
    return false;
  }
  if (ifnullOrUndefined(value?.brandId)) {
    errorNotification(message);
    return false;
  }
  if (ifnullOrUndefined(value?._id)) {
    errorNotification(message);
    return false;
  }
  return true;
};

export const asyncLocalStorage = {
  getLanguage: function (key) {
    return Promise.resolve().then(function () {
      try {
        let dir = localStorage.getItem(C.DIRECTION);
        if (dir) {
          let lang = JSON.parse(dir);
          return lang?.lang?.toUpperCase();
        } else {
          let dir = localStorage.getItem("i18nextLng");
          return dir?.toUpperCase();
        }
      } catch (error) {}
    });
  },
};
export const isDomainNameValid = (name) => {
  const REGEX = new RegExp("^[a-z0-9-]+$");
  let isValidDomainName = REGEX.test(name);
  if (!isValidDomainName) return false;
  return true;
};

//All areas on server  with one isAdded property

//getFilteredAreasList : returns all added+not added areas and add a custom property "isAdded" to each area
//@ params: areaData, addedAreas
//areasData: all business areas
//addedAreas: all added areas
export const getFilteredAreasList = (areasData, alreadyAddedAreas) => {
  const filteredArray = [];
  if (areasData) {
    areasData[0]?.cities?.map((city) => {
      return city?.areas?.map((area) => {
        if (
          !alreadyAddedAreas.find((item) => item?.areaID?._id === area?._id)
        ) {
          filteredArray.push({
            ...area,
            isAdded: false,
          });
        } else {
          filteredArray.push({
            ...area,
            isAdded: true,
          });
        }
      });
    });
    return filteredArray;
  }
};

//All countries on server  with one isAdded property
export const getCustomizedCountryList = (shippingData, addedCountries) => {
  try {
    const filteredArray = [];
    if (shippingData) {
      shippingData?.forEach((countryObj) => {
        const found = addedCountries?.countryDeliveryData.find(
          (added) => added?.countryID?._id === countryObj._id
        );
        if (found) {
          filteredArray.push({
            ...countryObj,
            isAdded: true,
          });
        } else {
          filteredArray.push({
            ...countryObj,
            isAdded: false,
          });
        }
      });
      return filteredArray;
    }
  } catch (error) {
    console.error({ error });
  }
};

//returning list of All cities added+not added ,from client side we are adding on custom proprty
export const getCustomizedCitiesList = (shippingData, addedCities) => {
  try {
    let filteredArray = [];
    if (shippingData) {
      shippingData?.map((country) => {
        return country.cities?.forEach((cityObj) => {
          const found = addedCities?.cityDeliveryData.find(
            (added) => added?.cityID?._id === cityObj?._id
          );
          if (found) {
            filteredArray.push({
              ...cityObj,
              isAdded: true,
            });
          } else {
            filteredArray.push({
              ...cityObj,
              isAdded: false,
            });
          }
        });
      });

      return filteredArray;
    }
  } catch (error) {
    console.error({ error });
  }
};
export const validateAdminSetting = (popup) => {
  let isValid = true;

  if (popup?.title?.length < 2 || popup?.title?.length >= 11) {
    handleErrorNotification(
      i18next.t(
        "Title must be at least 2 characters long and no more than 10 characters long"
      )
    );
    isValid = false;
  }
  if (popup?.message?.length < 2 || popup?.message?.length >= 39) {
    handleErrorNotification(
      i18next.t(
        "Message must be at least 2 characters long and no more than 38 characters long"
      )
    );
    isValid = false;
  }
  if (popup?.buttonText?.length < 2 || popup?.buttonText?.length >= 11) {
    handleErrorNotification(
      i18next.t(
        "Button text must be at least 2 characters long and no more than 10 characters long"
      )
    );
  }
  return isValid;
};
export const simplifiedAreaData = (areaData, language) => {
  let countries = [];
  const handleCountryList = (areaData) => {
    if (areaData) {
      areaData?.forEach((item) => {
        countries.push({
          value: item._id,
          label:
            language?.current === "en"
              ? item.countryNameEN
              : item.countryNameAR,
        });
      });
    }
    getCitesList(countries);
  };

  const getCitesList = (countries) => {
    countries?.length > 0 &&
      countries?.forEach((item) => {
        handleCitiesList(areaData, item.value);
      });
  };

  let citites = [];
  const handleCitiesList = (areaData, countryID) => {
    if (areaData) {
      const selectedCountry = areaData?.filter((el) => el._id === countryID);
      if (selectedCountry) {
        selectedCountry[0]?.cities?.forEach((element) => {
          citites?.push({
            value: element._id,
            label:
              language.current === "en"
                ? element.cityNameEN
                : element.cityNameAR,
            areas: element.areas,
          });
        });
      }
    }
  };
  handleCountryList(areaData, language);
  return { countries, citites };
};

export function getSentryUser() {
  try {
    let user = localStorage.getItem(C.CURRENT_USER);
    if (user) {
      const pasedObj = JSON.parse(user);
      let obj = {
        email: pasedObj.email,
        id: `{{"brandId:"}${pasedObj.brandId}{"userId:"}${pasedObj.userId}}`,
        username: pasedObj.brandName,
      };
      return obj;
    }
  } catch (error) {}
}

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};
export const getLocalStorage = (key) => {
  let value = localStorage.getItem(key);
  return value;
};

export const checkISAppUpdated = () => {
  let savedVersion = localStorage.getItem("APP-version");
  let envVersion = process.env.REACT_APP_VERSION;
  if (envVersion < savedVersion) {
    Sentry.captureMessage("App version Not updated from CDN", {
      level: "error",
    });
  } else {
    localStorage.removeItem("APP-version");
  }
};

// get package prices after applying discount if Discount Applicable
export const getPackagePrice = (packageInfo, planType) => {
  let PackageFinalPrice;
  if (packageInfo?.price) {
    let price = packageInfo.price;
    let discountPercent = packageInfo[planType];
    let discountAmount = (discountPercent / 100) * price;
    PackageFinalPrice = price - discountAmount;
  }
  return parseFloat(PackageFinalPrice)?.toFixed(2);
};

// CONVERT  ARABIC  NUMBERS to ENGLISH
export function NumberConversion(input) {
  if (typeof input === "string") {
    input = input.trim();
    const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let output = "";
    for (let i = 0; i < input.length; i++) {
      if (arabicNums.indexOf(input[i]) !== -1) {
        output += arabicNums.indexOf(input[i]);
      } else if (!isNaN(input[i])) {
        output += input[i];
      }
    }
    return output;
  } else {
    return input;
  }
}

/**
 * It takes a string of Arabic numerals and returns a string of English numerals.
 * @param input - The input string to be converted.
 */
export function ARtoENPrice(input) {
  if (typeof input === "string") {
    input = input.trim();
    const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let output = "";
    let decimalCount = 0;
    for (let i = 0; i < input.length; i++) {
      if (arabicNums.indexOf(input[i]) !== -1) {
        output += arabicNums.indexOf(input[i]);
      } else if (input[i] === "." || !isNaN(input[i])) {
        if (input[i] === ".") {
          decimalCount++;
          if (decimalCount > 1) continue;
        }
        output += input[i];
      }
    }
    if (output.includes(".")) {
      const decimalIndex = output.indexOf(".");
      output = output.substring(0, decimalIndex + 3);
    }
    return output;
  } else {
    return input;
  }
}

// export function logToSentry(message, options) {
//   Sentry.captureMessage(message, options);
//   Sentry.captureException(new Error(message));
// }

export const logToSentry = (message, options) => {
  try {
    const userDetail = UTILS.getCurrentUser();
    Sentry.withScope((scope) => {
      scope.setExtras({
        localstorage: userDetail,
      });
      Sentry.captureMessage(message, options);
      Sentry.captureException(new Error(message));

      Sentry.setTags({
        app_version: process.env.REACT_APP_VERSION,
        app_name: C.APP_NAME,
      });
    });
  } catch (error) {}
};

export const logErrorOnSentry = (message, level, extraDat) => {
  try {
    let user = UTILS.getCurrentUser();
    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setExtra("extra-data", extraDat || "");
      scope.setExtras({
        localstorage: user,
      });
      Sentry.captureMessage(message, {
        level: level ?? "error",
        extra: {
          url: window.location.href,
          userAgent: navigator.userAgent,
        },
      });

      Sentry.setTags({
        app_version: process.env.REACT_APP_VERSION,
        app_name: C.APP_NAME,
      });
    });
  } catch (error) {}
};

/**
 *
 * @param item - The item object that is being validated.
 */
export const validateItemInfo = (item) => {
  let isValidForm = true;
  let objError = {
    baseLanguage: {
      title: "",
      description: "",
    },
    price: "",
  };
  if (
    !item?.values?.baseLanguage?.title.trim() ||
    hasWhiteSpace(item.values.baseLanguage.title)
  ) {
    objError.baseLanguage.title = i18next.t("Please enter Item name");
    isValidForm = false;
  }
  if (
    !item?.values?.baseLanguage?.description?.trim() ||
    UTILS.hasWhiteSpace(item.values.baseLanguage.description)
  ) {
    objError.baseLanguage.description = i18next.t("Please enter description");
    isValidForm = false;
  }

  if (item?.values?.price === "") {
    objError.price = i18next.t("Please enter price");
    isValidForm = false;
  }
  return { isValidForm, objError };
};

export function withTryCatch(callback) {
  return async (...args) => {
    try {
      await callback(...args);
    } catch (error) {
      console.error("Error:", error);
    }
  };
}

export const ValidateParams = (params) => {
  for (const key in params) {
    if (!params.hasOwnProperty(key)) {
      continue;
    }

    const value = params[key];

    if (value === null || value === undefined || value === "") {
      console.error(`Invalid value for key "${key}": ${value}`);
      return false;
    }

    // You can add any other conditions to check for invalid values here.
    // For example:
    // if (typeof value !== 'string') {
    //   return false;
    // }
  }

  return true;
};

export const isValidValue = (value) => {
  return value !== null && value !== undefined && value !== "";
};
export const getCatalogueType = () => {
  const userDetail = getCurrentUser();
  if (userDetail?.catalogueType) {
    return userDetail?.catalogueType;
  }
  return null;
};

// open whtsapp
export const openWhatsApp = (number) => {
  const url = `https://wa.me/${number}`;
  window.open(url, "_blank");
};

export const getStoreInfo = (selectedStore, stores) => {
  console.log({ selectedStore, stores });
  const store = stores.find((store) => store?.value === selectedStore?.value);
  return store;
};
export const getStarRatingClasses = (rating) => {
  const classLookup = {
    5: "green__star",
    1: "red__star",
    2: "red__star",
    3: "selected",
    4: "selected",
  };

  return classLookup[rating] || "";
};

export const getORderPaymentGateway = (order) => {
  return order?.orderCheckoutOnlinePaymentMetadata?.data?.paymentGateway;
};

export const downloadReports = (url) => {
  window.open(url, "_blank");
};
