import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { RedoOutlined } from "@ant-design/icons";
const ManagePaymentLinkHeader = ({ getAllPaymentLinks }) => {
  const router = useHistory();
  const { t } = useTranslation();

  const goToAddPaymentLinkPage = () => {
    router.push("/app/dashboard/create-payment-links");
  };

  return (
    <div className="flex flex-wrap items-center justify-between m-4">
      <div className="">
        <h2 className="text-gray-900 text-24px sm:text-30px font-medium">
          {t("Payment Links")}
        </h2>
        <p className="text-gray-500">
          {t("Manage all your payment links here.")}
        </p>
      </div>

      <div className="mt-4 sm:mt-0">
        <Button
          onClick={getAllPaymentLinks}
          type="primary"
          size="small"
          className="me-1 370x:me-2 font-montserrat px-1 370x:px-4 shadow-sm  font-medium rounded-sm text-white h-10 md:h-10 focus:outline-none"
        >
          <span className="align-middle">
            <RedoOutlined />
          </span>

          <span className="px-1 align-middle font-bold">{t("Refresh")}</span>
        </Button>

        <Button
          type="primary"
          size="small"
          onClick={goToAddPaymentLinkPage}
          className="font-montserrat px-1 370x:px-3  shadow-sm  font-medium rounded-sm text-white h-10 md:h-10 focus:outline-none"
        >
          <span className="align-middle">
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              className="h-4 w-4 370x:h-5 370x:w-5 text-white"
            >
              <path
                d="M10 5.25a.75.75 0 01.75.75v8a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75z"
                clip-rule="evenodd"
              ></path>
              <path
                d="M5.25 10A.75.75 0 016 9.25h8a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
                clip-rule="evenodd"
              ></path>
              <path
                d="M3.813 3.813a8.75 8.75 0 1112.374 12.374A8.75 8.75 0 013.813 3.813zM10 2.75a7.25 7.25 0 100 14.5 7.25 7.25 0 000-14.5z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          <span className="px-1 align-middle font-bold">
            {t("Create Payment Link")}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ManagePaymentLinkHeader;
