import React from "react";
import { useSelector } from "react-redux";
import Support from "logistic-integrations/components/Support";
import { useTranslation } from "react-i18next";

function CareemSupport() {
  const { t } = useTranslation();
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);
  return (
    <Support
      orderNo={
        orderInfo?.logisticPartnerMetadata?.DELIVERY_ZONE
          ?.createDeliveryResponse?.order
      }
      // phone="+97142790334"
      heading={t("Delivery Zone Order Number")}
      // heading2={t("Delivery Zone Helpline Number")}
    />
  );
}

export default CareemSupport;
