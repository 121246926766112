import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

const Header = ({
  rowData,
  handlePrint,
  getOrderIsRefundable,
  showRefundModal,
}) => {
  const { t } = useTranslation();

  const REFUND_STATUS = ["COMPLETE", "REQUESTED"];

  return (
    <div className="bg-white flex justify-between flex-col sm:flex-row px-1 sm:px-7 py-3">
      <h2 className="w-full sm:w-1/3  text-blue-600 font-medium">
        {rowData?.orderNumber ? t("Order #") + rowData?.orderNumber : "..."}
      </h2>
      <h2 className="w-full sm:w-1/3 my-2 sm:my-0 font-medium">
        {rowData?.orderPlacedTime
          ? moment.unix(rowData?.orderPlacedTime).fromNow()
          : "..."}
      </h2>
      <div className="flex flex-wrap sm:flex-nowrap gap-2">
        <h2 className="flex items-center text-theme-red text-center border border-theme-red text-12px font-medium px-2 py-1 rounded-sm">
          <span>{t(rowData?.orderStatus ? rowData?.orderStatus : "...")}</span>
        </h2>
        {(rowData?.refund?.status === "FAILED" && (
          <Button className="" type="primary" onClick={showRefundModal}>
            {t("Refund Order")}
          </Button>
        )) ||
          (Boolean(
            (!rowData?.refund ||
              !rowData?.refund?.status ||
              !rowData?.refund?.amount ||
              rowData?.refund?.amount === 0) &&
              getOrderIsRefundable(rowData)
          ) && (
            <Button className="" type="primary" onClick={showRefundModal}>
              {t("Refund Order")}
            </Button>
          ))}

        <button
          className=" px-2 py-1 bg-blue-500 text-white rounded-sm"
          style={{ width: "80px" }}
          onClick={() => handlePrint()}
        >
          <PrinterOutlined />
          <span className="px-2 mt-1">{t("Print")}</span>
        </button>
      </div>
    </div>
  );
};

export default Header;
