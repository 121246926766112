import React from "react";
import { UTILS } from "utils";

import { useTranslation } from "react-i18next";

function Support({ orderNo, phone, heading, heading2 }) {
  const { t } = useTranslation();
  return (
    <div>
      <table className="border-collapse border border-slate-400 w-full mt-4">
        <>
          {phone && (
            <tr>
              <td className="border border-slate-300 p-4">{heading2}</td>
              <td
                className="border border-slate-300 p-4 text-blue-500 underline cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(phone);
                  UTILS.successNotification(t("Copied to Clipboard"));
                }}
              >
                <span dir="ltr"> {phone}</span>
              </td>
            </tr>
          )}
          <tr>
            <td className="border border-slate-300 p-4">{heading}</td>
            <td
              className="border border-slate-300 p-4 text-blue-500 underline cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(orderNo);
                UTILS.successNotification("Copied to Clipboard");
              }}
            >
              {orderNo}
            </td>
          </tr>
        </>
      </table>
    </div>
  );
}

export default Support;
