import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
const CancelOrder = ({ showButtons, cancelOrder, order }) => {
  const { t } = useTranslation();
  if (showButtons?.cancelClaim) {
    return (
      <div>
        <div className="flex justify-center p-4">
          <Button type="default" size="large" onClick={cancelOrder}>
            {t("CANCEL ORDER")}
          </Button>
        </div>
      </div>
    );
  } else if (order?.logisticPartnerMetadata?.YANDEX?.cancelClaimInfo) {
    return (
      <div className="flex justify-center p-4">
        <div>{t("Order Has been Cancelled Already")}</div>
      </div>
    );
  }

  return null;
};

export default CancelOrder;
