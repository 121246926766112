import React, { Fragment, useEffect, useState } from "react";
import { Button, Radio, Spin } from "antd";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useCreateYandexClaimMutation } from "redux/Logistic/LogisticService";
import { useDispatch, useSelector } from "react-redux";
import { LogisticAction } from "redux/actions";
import { CONSTANTS as C } from "config";
import ConfirmClaim from "./ConfirmClaim";
import Modal from "components/Modal";
const CreateClaim = (props) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const { order, showButtons, setShowButtons } = props;
  const [options, setOptions] = useState([]);
  const [open, setOpen] = React.useState(false);

  // Redux tookit
  const [createYandexClaim] = useCreateYandexClaimMutation();
  const { showBtn, vehicleTypes, selectedvehicleType, isThermalBagNeeded } =
    useSelector((state) => state?.LogisticReducer);

  /* A function that is used to create a claim. */
  const createClaim = async () => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
      orderId: order._id,
      isThermalBagNeeded: isThermalBagNeeded,
      vehicleClass: selectedvehicleType,
      deliveryType: C.YANDEX.EXPRESS_DELIVERY_TYPE,
    };
    const isValid = UTILS.ValidateParams(obj);
    if (isValid) {
      const { data } = await createYandexClaim(obj);
      if (data) {
        reduxDispatch(LogisticAction.setSyncData());
        setShowButtons((prevState) => ({
          ...prevState,
          sync: !showButtons.sync,
        }));
      }
      reduxDispatch(LogisticAction.setSelectedVehicleType(null));
      // reduxDispatch(LogisticAction.setVehicleTypes([]));
    }
  };

  /* Select Box showing All Delivery Methods */
  useEffect(() => {
    props.setLoading(true);
    const options = [];
    vehicleTypes?.forEach((method) => {
      options.push({
        label: C.YANDEX[`${method.name}`],
        value: method.name?.toUpperCase(),
      });
    });
    setOptions(options);
    setTimeout(() => {
      props.setLoading(false);
    }, 1000);
  }, [vehicleTypes]);

  const VehicleTypePicker = () => {
    const onChangeVehicelType = (value) => {
      if (value) {
        reduxDispatch(LogisticAction.setSelectedVehicleType(value));
      }
    };
    return (
      <div className="mt-4">
        <h2 className="mb-2 font-medium text-16px">
          {t("select vehicle type")}
        </h2>
        <Radio.Group
          className="flex items-center"
          onChange={(e) => onChangeVehicelType(e.target.value)}
          value={selectedvehicleType}
        >
          {options?.map((option) => (
            <Radio className="mr-4" value={option.value}>
              {t(option.label)}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    );
  };

  /* onchange for changing thermal bag Needed  */
  const onChangeisThermalBagNeeded = (e) => {
    reduxDispatch(LogisticAction.setThermalBag(e.target.value));
  };

  const CreateClaim = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        {/* Show vehicle type picker and thermal bag option if there are vehicle types available */}
        <>
          {showButtons?.createClaim && options?.length >= 1 ? (
            <>
              <VehicleTypePicker />
              <div className="mt-4">
                <h2 className="mb-2 font-medium text-16px">
                  {t("Is a thermal bag needed")}
                </h2>
                <Radio.Group
                  className="flex items-center"
                  onChange={onChangeisThermalBagNeeded}
                  value={isThermalBagNeeded}
                >
                  <Radio className="mr-4" value={true}>
                    {t("Yes")}
                  </Radio>
                  <Radio className="mr-4" value={false}>
                    {t("No")}
                  </Radio>
                </Radio.Group>
              </div>
            </>
          ) : null}
          {!props.loading && showBtn && (
            <Button
              type="default"
              size="large"
              onClick={props.getAllDeliveryMethods}
              className="w-56  my-2 rounded-md font-semibold"
            >
              {t("Fetch YANGO drivers")}
            </Button>
          )}

          {/* Show create claim button if a vehicle type has been selected */}
          <div className="ml-4 py-4 text-center">
            <>
              {showButtons?.createClaim ? (
                <Button
                  type="default"
                  size="large"
                  disabled={
                    !selectedvehicleType ||
                    options?.length < 1 ||
                    vehicleTypes?.length < 1
                  }
                  onClick={createClaim}
                  className="w-56  my-2 rounded-md font-semibold"
                >
                  {t("Send order request")}
                </Button>
              ) : (
                <Button
                  type="default"
                  size="large"
                  disabled={true}
                  onClick={() => {}}
                  className="w-56  my-2 rounded-md font-semibold"
                >
                  {t("Send order request")}
                </Button>
              )}
            </>
            <ConfirmClaim
              order={order}
              showButtons={showButtons}
              setShowButtons={setShowButtons}
              setOpen={setOpen}
            />
          </div>
        </>
      </div>
    );
  };
  return (
    <Fragment key={props?.key}>
      <CreateClaim />
      <Modal
        open={open}
        modalWidth=" min-h-300 rounded-md border border-gray-300 top-0 w-full lg:w-2/3 items-center flex justify-center"
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="text-center  mt-4 font-semibold flex flex-col">
          <h3 className="mt-0 mb-4 text-red-500">
            {t(
              "Order is NOT confirmed by YANGO, please retry order confirmation"
            )}
          </h3>
          <div className="flex justify-center">
            <div className="space-x-4">
              <ConfirmClaim
                order={order}
                showButtons={showButtons}
                setShowButtons={setShowButtons}
                setOpen={setOpen}
              />
              <Button
                type="default"
                size="large"
                onClick={() => {
                  setOpen(false);
                }}
                className="px-6 rounded-md font-semibold"
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default CreateClaim;
