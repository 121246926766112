import PageHeading from "components/PageHeading";
import React, { useState, useEffect } from "react";
import Facebook from "../components/facebook";
import Instagram from "../components/instagram";
import Jeebly from "../components/jeebly";
import Quiqup from "../components/quiqup";
import Telr from "../components/telr";
import Whatsapp from "../components/whatsapp";
import { useTranslation } from "react-i18next";
import Messenger from "../components/messenger";
import { UTILS } from "utils";
import { useGetSettingsMutation } from "redux/settings/settingsSlice";
import { Spin } from "antd";
import Talabat from "../components/talabat";
import Yandex from "../components/yandex";
import Checkout from "../components/checkout";
const Integrations = () => {
  const [getSettings, { isLoading }] = useGetSettingsMutation();

  const [shopUrl, setShopUrl] = useState("");
  const { t } = useTranslation();
  const fetchSettings = async () => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
    };
    const data = await getSettings(obj).unwrap();

    if (data?.success === 1 && data?.data?.setting) {
      const userDetail = await UTILS.getCurrentUser();
      let url = `${userDetail.storeFrontURL}${data?.data?.setting?.temporaryDomainName}`;
      setShopUrl(url);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeading heading={t("Integrations")} />
        <div className="px-4 py-10">
          <h3 className="text-15px text-gray-500">
            {t(
              "Increase sales and get more exposure by connecting RestHero to your social media channels and other partners.",
              {
                company: process.env.REACT_APP_COMPANY_NAME,
              }
            )}
          </h3>
          <h1 className="text-19px text-blue-600 pt-4">
            {t("Social Integrations")}
          </h1>
          <div className="flex justify between flex-wrap lg:flex-nowrap pt-2">
            <Instagram {...{ shopUrl }} />
            <Facebook {...{ shopUrl }} />
          </div>
          <div className="flex justify between flex-wrap lg:flex-nowrap pt-2">
            <Whatsapp {...{ shopUrl }} />
            <Messenger {...{ shopUrl }} />
          </div>
          <h1 className="text-19px text-blue-600 pt-12">
            {t("Delivery Integrations")}
          </h1>
          <div className="flex justify between flex-wrap lg:flex-nowrap py-2">
            <Talabat />
            <Yandex />
          </div>
          <div className="flex justify between flex-wrap lg:flex-nowrap py-2">
            <Quiqup />
            <Jeebly />
          </div>

          <h1 className="text-19px text-blue-600 pt-12">
            {t("Payment Integrations")}
          </h1>
          <div className="flex justify between flex-wrap lg:flex-nowrap py-2">
            <Telr />
            <Checkout />
          </div>
        </div>
      </Spin>
    </div>
  );
};
export default Integrations;
