import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};

const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600"> {error} </span>;
  }
};

const BgTextInput = (props) => {
  const mandatoryField = getMandatoryField(props?.mandatory || false);

  const fieldError = getFieldError(props?.error || false);

  useEffect(() => {}, [props.value]);

  return (
    <>
      <div className="py-2 px-4 bg-input-bg rounded">
        <label className="font-medium block">
          {props.label}
          {mandatoryField} {fieldError}
        </label>
        <input
          onChange={(event) =>
            props.handleValues(props.keyVal, event.target.value)
          }
          value={props.value}
          type={props.type}
          placeholder={props.placeHolder}
          className={props.styleClasses}
        />
      </div>
    </>
  );
};

BgTextInput.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.bool,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  styleClasses: PropTypes.string,
};

export default BgTextInput;
