import React from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import CreateClaim from "./CreateClaim";
import ClaimInfo from "./ClaimInfo";
import CancelClaim from "./CancelClaim";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetYandexClaimInfoMutation,
  useCancelYandexClaimMutation,
  useGetOrderInfoByIdMutation,
  useGetYandexDeliveryMethodsMutation,
} from "redux/Logistic/LogisticService";
import { UTILS } from "utils";
import { isValidValue } from "utils/Utils";
import { CONSTANTS as C } from "config";
import { LogisticAction } from "redux/actions";
import { ErrorAction } from "redux/actions";
import ModalTabs from "logistic-integrations/components/Tabs";
import YangoSupport from "./YangoSupport";
const { TabPane } = Tabs;
const DeliveryService = (props) => {
  const { setOpen, order, showButtons, fetchOrderDetails, setShowButtons } =
    props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);
  const [cancelYandexClaim] = useCancelYandexClaimMutation();
  const [getYandexClaimInfo] = useGetYandexClaimInfoMutation();
  const [getOrderInfoById] = useGetOrderInfoByIdMutation();
  const [getYandexDeliveryMethods] = useGetYandexDeliveryMethodsMutation();

  /*handle tab chnge */
  const handleTabChange = (key) => {
    fetchOrderDetails();
    if (
      key === "3" &&
      isValidValue(
        orderInfo?.logisticPartnerMetadata?.YANDEX?.createClaimResponse?.id
      )
    ) {
      getyandexOrderInfo();
    }
    if (key === "1") {
      getAllDeliveryMethods();
    }
  };

  /*It fetches the yandex claim info.*/
  const getyandexOrderInfo = async () => {
    const userDetail = UTILS.getCurrentUser();
    let obj = {
      brandId: userDetail.brandId,
      orderId: order._id,
    };
    const isValid = UTILS.ValidateParams(obj);
    if (isValid) {
      await getYandexClaimInfo(obj);
    } else {
      dispatch(LogisticAction.setClaimInfo(null));
      dispatch(
        ErrorAction.setError("Something went wrong while fetching claim info")
      );
    }
  };

  /*It cancels the order.*/
  const cancelOrder = async () => {
    const userDetail = UTILS.getCurrentUser();
    let obj = {
      brandId: userDetail.brandId,
      orderId: order._id,
      deliveryType: C.YANDEX.EXPRESS_DELIVERY_TYPE,
    };
    const isValid = UTILS.ValidateParams(obj);
    if (isValid) {
      const { data } = await cancelYandexClaim(obj);
      if (data?.data?.logisticPartnerMetadata?.YANDEX) {
        let isCancelled = isValidValue(
          data?.data?.logisticPartnerMetadata?.YANDEX?.createClaimResponse?.id
        );
        dispatch(LogisticAction.setSyncData());
        setShowButtons((prevState) => ({
          ...prevState,
          cancelClaim: !isCancelled,
          sync: !showButtons.sync,
        }));
      }
    } else {
      UTILS.handleErrorNotification(t("Something went wrong"));
    }
    fetchOrderDetails();
  };

  const getAllDeliveryMethods = async () => {
    const userDetail = UTILS.getCurrentUser();
    let obj = {
      brandId: userDetail.brandId,
      orderId: order?._id,
    };
    if (obj?.brandId && obj?.orderId) {
      await getYandexDeliveryMethods(obj);
      await getOrderInfoById(obj);
    }
  };

  return (
    <ModalTabs
      setOpen={setOpen}
      handleTabChange={handleTabChange}
      loading={loading}
    >
      <TabPane tab={t("SEND ORDER")} key="1">
        <CreateClaim
          {...{
            showButtons,
            setShowButtons,
            order,
            getAllDeliveryMethods,
            loading,
            setLoading,
          }}
          p={showButtons.createClaim}
        />
      </TabPane>
      <TabPane tab={t("ORDER DETAIL")} key="3">
        <ClaimInfo order={order} key={showButtons.confirmClaim} type={false} />
      </TabPane>
      <TabPane tab={t("CANCEL ORDER")} key="4">
        <CancelClaim
          key={showButtons.cancelClaim}
          {...{ showButtons, setShowButtons, order, cancelOrder }}
        />
      </TabPane>
      <TabPane tab={t("YANGO SUPPORT CENTER")} key="5">
        <YangoSupport />
      </TabPane>
    </ModalTabs>
  );
};

export default DeliveryService;
