import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import AreasList from "./AreasList";
import ShowCityListButton from "../../common/ShowCityListButton";
import { AreasAction } from "redux/actions";

const CitiesList = ({
  cityAreas,
  searchTerm,
  openAreas,
  cityCountry,
  city,
}) => {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { newSelectedMappedAreas } = useSelector((state) => state.AreasReducer);
  const [open, setOpen] = useState(false);
  const setOpenState = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const saveAreaIntoState = (area, areaCity) => {
    let update = [];
    const obj = {
      area: area,
      city: areaCity,
      country: cityCountry,
    };
    const isAreaAlreadySelected = !!newSelectedMappedAreas?.find(
      (c) => c?.area?._id === area?._id
    );
    if (isAreaAlreadySelected) {
      update = newSelectedMappedAreas.filter((c) => c?.area?._id !== area?._id);
    } else {
      update = [...newSelectedMappedAreas, obj];
    }
    dispatch(AreasAction.setNewMappedAreas(update));
  };

  const isChecked = (area) =>
    !!newSelectedMappedAreas?.find((c) => c?.area?._id === area?._id);

  const Items = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) return cityAreas;

    const lowerCasedSearchTerm = searchTerm.toLowerCase();
    return cityAreas?.filter((item) => {
      const areaName =
        item?.translations?.en?.name?.toLowerCase() ||
        item?.translations?.ar?.name?.toLowerCase();

      const cityName =
        item?.city?.name?.en?.toLowerCase() ||
        item?.city?.name?.ar?.toLowerCase();

      return (
        areaName?.includes(lowerCasedSearchTerm) ||
        cityName?.includes(lowerCasedSearchTerm)
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityAreas?.length, searchTerm, currentLanguage]);
  return (
    <div className="w-full mb-2 px-2">
      <div className="flex w-full my-2">
        <ShowCityListButton
          open={open || openAreas}
          setOpenState={setOpenState}
        />
        <div className="flex justify-between w-full mx-4">
          <div>{city?.[currentLanguage?.toLowerCase()]}</div>
        </div>
      </div>
      {open || openAreas ? (
        <AreasList {...{ city, isChecked, Items, saveAreaIntoState }} />
      ) : null}
    </div>
  );
};

export default CitiesList;
