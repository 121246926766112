import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
import { UTILS } from "utils";
import { AreasAction } from "redux/actions";
import { postConfig } from "redux/Api.Slice";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";

const loyaltyService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createLoyalty: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CREATE_LOYALTY,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    updateLoyalty: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.UPDATE_LOYALTY,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),

    getLoyaltyDetailsByBrandName: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_LOYALTY_DETAILS_BY_BRAND_NAME,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));

        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data?.areas) {
            dispatch(AreasAction.setAddedCountries(data.data.areas));
          } else {
            errorHandler(data);
          }
        } catch (err) {
          errorHandler(err);
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    getUserWalletBalance: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_USER_WALLET_BALANCE,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
        } catch (err) {
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    getUserWalletTransactionHistory: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_USER_WALLET_TRANSACTION_HISTORY,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          await queryFulfilled;
        } catch (err) {
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    createUserWalletTransaction: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CREATE_USER_WALLET_TRANSACTION,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
    getAllBrandCustomers: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_ALL_BRAND_CUSTOMERS,
        ...postConfig.query(body),
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;

          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err));
        } finally {
          dispatch(LoaderAction.setLoading(false));
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useCreateLoyaltyMutation,
  useUpdateLoyaltyMutation,
  useGetLoyaltyDetailsByBrandNameMutation,
  useGetUserWalletBalanceMutation,
  useGetUserWalletTransactionHistoryMutation,
  useCreateUserWalletTransactionMutation,
  useGetAllBrandCustomersMutation,
} = loyaltyService;
export default loyaltyService;
