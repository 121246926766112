import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Line } from "svg/SVGs";
import { useSelector } from "react-redux";
const TextSlider = (props) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <Slider {...settings}>
      <div className="text-16px text-white">
        <h3 className="font-montserrat text-white">
          {t(
            "We can keep track of all of our inventory. At all our locations with just a few taps. We have moved to auto generated orders. They have reduced the steps we have in our existing system. Rest hero has indeed organized our business well.",
            {
              company:
                currentLanguage === "EN"
                  ? process.env.REACT_APP_COMPANY_NAME_EN
                  : process.env.REACT_APP_COMPANY_NAME_AR,
            }
          )}
        </h3>
        <p className="color-white font-bold mt-5">
          <Line />
          <span className="font-raleway pl-5">{t("AISHA BUCHA")}</span>
        </p>
        <p className="ml-24 mt-2 mb-8">{t("Manager Debenhams Dubai Mall")}</p>
      </div>
      <div className="text-16px text-white">
        <h3 className="font-montserrat text-white">
          {t(
            "I can say before moving to Resthero, we weren’t able to quantify our sales and profit margins. Now they have pushed our business to a newer level where I can focus on growth and they take care of my business.",
            {
              company:
                currentLanguage === "EN"
                  ? process.env.REACT_APP_COMPANY_NAME_EN
                  : process.env.REACT_APP_COMPANY_NAME_AR,
            }
          )}
        </h3>
        <p className="color-white font-bold mt-5">
          <Line />
          <span className="font-raleway pl-5">{t("ALRAZIH.CO")}</span>
        </p>
        <p className="ml-24 mt-2 mb-8">{t("Manager Tammim Allahdadi")}</p>
      </div>
      <div className="text-16px text-white">
        <h3 className="font-montserrat text-white">
          {t(
            "Rest Hero team rocks. A small note to the team You guys made my business fully automated. your efforts have increased my daily sales.I am your happy customer and I highly recommend your services.",
            {
              company:
                currentLanguage === "EN"
                  ? process.env.REACT_APP_COMPANY_NAME_EN
                  : process.env.REACT_APP_COMPANY_NAME_AR,
            }
          )}
        </h3>
        <p className="color-white font-bold mt-5">
          <Line />
          <span className="font-raleway pl-5">{t("OMAR MAALIK")}</span>
        </p>
        <p className="ml-24 mt-2 mb-8">{t("Owner Ice Parlor")}</p>
      </div>
    </Slider>
  );
};

export default TextSlider;
