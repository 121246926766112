import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice } from "redux/Api.Slice";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import PageHeading from "components/PageHeading";
import AreasTable from "../components/AeasTable";
import { AreasAction } from "redux/actions";
import { LoaderAction } from "redux/actions";

const ManageAreas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [trigger] = apiSlice.endpoints.getBuisnessAreas.useLazyQuery();
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const getBussinessAreas = UTILS.withTryCatch(async () => {
    dispatch(LoaderAction.setLoading(true));
    const { data } = await trigger();
    if (data?.success === 1 && data?.data) {
      getDeliveryAreas(data.data);
      dispatch(AreasAction.setFetchedAreas(data.data));
    } else {
      console.error("areas error", data);
    }
    dispatch(LoaderAction.setLoading(false));
  });

  const getDeliveryAreas = UTILS.withTryCatch(async (areaData) => {
    dispatch(LoaderAction.setLoading(true));
    const userDetail = UTILS.getCurrentUser();
    if (userDetail?.brandId && selectedStore?.value) {
      const addedAreas = await UTILS.UpdatedAreas(
        selectedStore.value,
        userDetail.brandId
      );
      if (addedAreas) {
        dispatch(AreasAction.setAddedAreas(addedAreas));
        const filteredAreasList = UTILS.getFilteredAreasList(
          areaData,
          addedAreas
        );

        if (filteredAreasList) {
          dispatch(AreasAction.setcustomeAddedAreas(filteredAreasList));
        }
      }
    }
    dispatch(LoaderAction.setLoading(false));
  });

  useEffect(() => {
    getBussinessAreas();
  }, [selectedStore?.value]);

  return (
    <div>
      <PageHeading heading={t("Delivery Areas")} />
      <div className="pb-3 px-2">
        <AreasTable />
      </div>
    </div>
  );
};
ManageAreas.propTypes = {
  store: PropTypes.object,
};
export default ManageAreas;
