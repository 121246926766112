import { Spin } from "antd";
import PageHeading from "components/PageHeading";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BubbleTopMessage from "../components/BubbleTopMessage";
import { API } from "api";
import { UTILS } from "utils";
import { PackageUtil } from "utils";
import Step from "../components/Step";
import Modal from "components/Modal";
import useCatalogue from "hooks/useCatalogue";
import { useSelector } from "react-redux";
import ModalSelectStore from "../components/ModalSelectStore";
const GetStarted = () => {
  const history = useHistory();
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [modalVideoOpen, setModalVideoOpen] = useState(false);
  const { t } = useTranslation();
  const catelougeType = useCatalogue("getstarted");
  const getGuidanceFlowSetupStatus = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_GUIDANCE_FLOW_SETUP_WITH_STATUS({
      token: userDetail.token,
    });
    if (result?.success === 1 && Array.isArray(result.result))
      setSteps(result?.result);
    else {
    }
    setLoading(false);
  };

  const playVideo = (id) => {
    setVideoId(id);
    setModalVideoOpen(true);
  };

  const addTaskToDone = async (id) => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.ADD_TASK_TO_DONE({
      token: userDetail.token,
      taskId: id,
    });
    getGuidanceFlowSetupStatus();
  };
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const check_PackageValidity = async () => {
    const { result } = await UTILS.checkPackageValidity(currentLanguage);
    if (result?.data) {
      const diff = PackageUtil.getPackageExpiry(result.data.subscription);
      if (diff > 5) {
        history.push("/app/dashboard/subscription");
      }
    }
  };
  useEffect(() => {}, []);

  useEffect(() => {
    check_PackageValidity();
    getGuidanceFlowSetupStatus();
  }, []);

  const getEnabled = (s, index) => {
    if (s?.isCompleted) return "enabled";
    else if (index == 0) return "enabled";
    else if (steps[index - 1]?.isCompleted && !s?.isCompleted) return "enabled";
    else return "disabled";
  };

  const getOpened = (s, index) => {
    if (index === 0 && !s.isCompleted) return true;
    else if (steps[index - 1]?.isCompleted && !s?.isCompleted) return true;
    else return false;
  };
  return (
    <div className="">
      <PageHeading heading={t("Get Started")} />
      <Modal
        open={open}
        modalWidth="rounded-md border border-gray-300 overflow-y-scroll"
        onClose={() => setOpen(false)}
        scroll={true}
      >
        <ModalSelectStore onClose={() => setOpen(false)} />
      </Modal>
      <Modal
        open={modalVideoOpen}
        modalWidth="rounded-md border border-gray-300"
        onClose={() => setModalVideoOpen(false)}
      >
        <div className="w-full h-96">
          <iframe
            className="video w-full h-80"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${videoId}?autoplay=0`}
          ></iframe>
          <div className="p-3 mt-2 text-center space-x-4 md:block">
            <button
              onClick={() => setModalVideoOpen(false)}
              className="bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-md hover:bg-gray-100 justify-center"
            >
              {t("Ok")}
            </button>
          </div>
        </div>
      </Modal>
      <div className="pb-24">
        <div className="flex justify-between flex-wrap pb:4 md:mb-0">
          <span className="w-full md:w-1/2 mt:4 md:mt-28 px-4 md:px-24 pt-4 md:pt-12 text-19px font-bold">
            {t("Your Quick Start Guide")}
          </span>
          <div className="w-full md:w-1/2">
            <BubbleTopMessage />
          </div>
        </div>
        <Spin spinning={loading || catelougeType?.isLoading}>
          <div className="px-4 md:px-24 flex justify-between">
            <div className="w-full">
              {steps &&
                steps.map((s, index) => {
                  return (
                    <Step
                      catelougeType={catelougeType}
                      key={index}
                      step={s}
                      enabled={getEnabled(s, index)}
                      index={index}
                      length={steps?.length}
                      opened={getOpened(s, index)}
                      openModal={() => setOpen(true)}
                      playVideo={playVideo}
                      addTaskToDone={addTaskToDone}
                    />
                  );
                })}
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default GetStarted;
