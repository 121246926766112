import React from "react";
import PropTypes from "prop-types";
const Context = React.createContext({});

export function Accordion(props) {
  const [selected, setSelected] = React.useState();

  const toggleItem = (id) => {
    setSelected((prevState) => (prevState !== id ? id : ""));
  };
  return (
    <Context.Provider value={{ selected, toggleItem }}>
      {props.children}
    </Context.Provider>
  );
}
Accordion.propTypes = {
  children: PropTypes.array,
};

export const useAccordion = () => React.useContext(Context);
