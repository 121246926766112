import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { UTILS } from "utils";
import { errorNotification } from "utils/Utils";
import { calculateDeliveryTimeInMins } from "features/shipping_methods/helpers";
import useUpdateZone from "../hooks/useUpdateZone";
import ZoneForm from "../components/ZoneForm";

function EditZone({ onClose, selectedDataRows }) {
  const { t } = useTranslation();
  const updateZoneInfo = useUpdateZone();

  const { selectedStore } = useSelector((state) => state.AreasReducer);

  const handleValues = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };
  const resetState = () => {
    setState((prevState) => ({
      ...prevState,

      deliveryTime: {
        Hours: 0,
        Minutes: 0,
        Days: 0,
      },
    }));
  };

  const handleInputValue = (key, val) =>
    handleValues(key, UTILS.NumberConversion(val));

  const [selectedZoneState, setState] = useState({
    minOrder: "",
    deliveryFee: "",
    distance: "",
    name: "",
    deliveryTime: {
      Hours: 0,
      Minutes: 0,
      Days: 0,
    },
  });

  const validateForm = () => {
    let isValid = true;
    const { name, distance, minOrder, deliveryFee, deliveryTime } =
      selectedZoneState;
    if (!name) {
      errorNotification(t("Zone Name is required"));
      isValid = false;
    }
    if (!distance) {
      errorNotification(t("Distance is required"));
      isValid = false;
    }
    if (distance < 0) {
      errorNotification(t("Distance should be greater than 0"));
      isValid = false;
    }

    if (!minOrder) {
      errorNotification(t("Min. Basket Value is required"));
      isValid = false;
    }
    if (!deliveryFee) {
      errorNotification(t("Delivery Fee is required"));
      isValid = false;
    }
    if (!deliveryTime?.Days && !deliveryTime?.Hours && !deliveryTime?.Minutes) {
      errorNotification(t("Delivery Time is required"));
      isValid = false;
    }
    return isValid;
  };

  const updateZone = async () => {
    const brandId = UTILS.getCurrentUser()?.brandId;
    if (!validateForm() || !brandId) return;

    const deliveryTime = calculateDeliveryTimeInMins({
      deliveryTime: selectedZoneState.deliveryTime,
    });
    const payload = {
      brandId,
      storeId: selectedDataRows?.storeId,
      name: selectedZoneState.name,
      distance: +selectedZoneState.distance,
      deliveryFee: +selectedZoneState.deliveryFee,
      deliveryTime: +deliveryTime,
      minOrder: +selectedZoneState.minOrder,
      distanceBaseDeliveryMethodId: selectedDataRows?._id,
    };

    updateZoneInfo({ payload, onClose });
  };

  useEffect(() => {
    if (selectedDataRows?._id) {
      setState((prevState) => ({
        ...prevState,
        minOrder: selectedDataRows?.minOrder,
        deliveryFee: selectedDataRows?.deliveryFee,
        distance: selectedDataRows?.distance,
        name: selectedDataRows?.name,
        deliveryTime: {
          Days: Math.floor(selectedDataRows?.deliveryTime / 1440),
          Hours: Math.floor((selectedDataRows?.deliveryTime % 1440) / 60),
          Minutes: Math.floor((selectedDataRows.deliveryTime % 1440) % 60),
        },
      }));
    }
  }, [selectedDataRows]);
  return (
    <ZoneForm
      {...{
        onClose,
        selectedAreaState: selectedZoneState,
        handleValues,
        handleInputValue,
        handleSubmit: updateZone,
        resetState,
      }}
      heading={t("Edit Zone")}
    />
  );
}

export default EditZone;
