import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Table } from "antd";
import Card from "components/Card";
import PageHeading from "components/PageHeading";
import SelectInput from "components/SelectInput";
import PlayPause from "./PlayPause";
const Inventory = ({
  state,
  handlePlayPause,
  handleChange,
  isLoading,
  stores,
  items,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(null);
  const columns = [
    {
      title: t("Item"),
      dataIndex: "baseLanguage",
      align: "initial",
      width: "70%",
      render: (baseLanguage, row, index) => {
        return baseLanguage?.title;
      },
    },
    {
      title: t("Availability"),
      dataIndex: "itemInfo",
      width: "25%",
      align: "initial",
      render: (val, row, index) => {
        return (
          <PlayPause
            {...{
              rowData: row,
              state,
              handlePlayPause,
            }}
          />
        );
      },
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: "10%",
      align: "initial",
      render: (price, row, index) => {
        return (
          <span className="whitespace-nowrap">{`${t(
            "AED"
          )}${"  "} ${price}`}</span>
        );
      },
    },
  ];

  const ItemsListArr = React.useMemo(() => {
    if (!searchTerm) return items;

    let searchTermLower = searchTerm?.toLowerCase()?.trim();
    console.log({ searchTermLower, items });

    return items.filter((item) =>
      item?.baseLanguage?.title?.toLowerCase()?.includes(searchTermLower)
    );
  }, [items, searchTerm]);

  return (
    <>
      <PageHeading heading={t("Inventory")} />
      <div className="m-5 mx-4 md:mx-5">
        <Card>
          <div className="px-2 py-2">
            <Spin spinning={isLoading}>
              <h3 className="text-gray-500">
                {t(
                  "Select store from the dropdown to play/pause inventory for particular store"
                )}
              </h3>

              <h2 className="text-17px text-blue-600 ">{t("Select store")}</h2>
              <div className="flex justify-between flex-col sm:flex-row">
                <div className="flex w-1/2 mb-4">
                  <SelectInput
                    keyVal="brandId"
                    handleChange={handleChange}
                    options={stores}
                    value={stores._id}
                    styleClass="font-montserrat text-input-color text-16px mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="flex flex-col sm:flex-row mx-0 sm:mx-4 items-center justify-between">
                  <div></div>
                  <div className="group relative my-4 sm:mt-0 w-full sm:w-auto">
                    <svg
                      fill="currentColor"
                      className="absolute start-3 w-5 h-5 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      ></path>
                    </svg>
                    <input
                      className="focus:outline-none appearance-none w-full text-16px leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 ps-10 ring-1 ring-gray-200 shadow-sm min-290"
                      type="text"
                      placeholder={t("Search by item name")}
                      autoComplete="off"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <Card styleClasses="px-2 py-2">
                <Table
                  locale={{ emptyText: t("No Data") }}
                  columns={columns}
                  dataSource={ItemsListArr}
                  pagination={{ position: ["bottomCenter"] }}
                  size="small"
                />
              </Card>
            </Spin>
          </div>
        </Card>
      </div>
    </>
  );
};
export default Inventory;
