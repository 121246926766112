import {
  emailValidation,
  errorNotification,
  ifnullOrUndefined,
  isValidValue,
} from "./Utils";
import { CONSTANTS as C } from "config";
import "moment-timezone";
import i18next from "i18next";
import * as SC from "./storeConstants";

export const VALIDATE_INPUTS = (store) => {
  console.log("VALIDATE_INPUTS", store);
  let isFormValid = true;
  let errors = {
    email: "",
    mobileNumber: "",
    whatsAppContact: "",
    baseLanguage: {
      name: "",
      description: "",
    },
    city: "",
    taxRate: "",
    timeZone: "",
    averagePrepTime: "",
    averagePrepTimeForPickup: "",
    averagePrepTimeForDinein: "",
  };
  if (store?.values[C.BASE_LANGUAGE]?.name?.trim() === "") {
    errors[C.BASE_LANGUAGE].name = i18next.t("Name Field is required");
    isFormValid = false;
    errorNotification(i18next.t("Name Field is required"));
    return { isFormValid, errors };
  }

  if (store?.values[C.BASE_LANGUAGE]?.description.trim() === "") {
    errors[C.BASE_LANGUAGE].description = i18next.t(
      "Description Field is required"
    );
    isFormValid = false;
    errorNotification(i18next.t("Description Field is required"));
    return { isFormValid, errors };
  }
  if (!isValidValue(store?.values[C.KEY_TAX_RATE])) {
    errors[C.KEY_TAX_RATE] = i18next.t("Tax Rate Field is required");
    isFormValid = false;
    errorNotification(i18next.t("Tax Rate Field is required"));
    return { isFormValid, errors };
  }

  //avergage prep for delivery
  if (store?.values[C.ISDELIVERYENABLED]) {
    if (!store?.values[C.AVERAGE_PREP]?.trim()) {
      errors[C.AVERAGE_PREP] = i18next.t(
        "Average Preparation Field is required"
      );
      isFormValid = false;
      errorNotification(
        i18next.t("Average Preparation Field is required in section", {
          serviceType: i18next.t("Delivery"),
        })
      );
      return { isFormValid, errors };
    } else if (store?.values[C.AVERAGE_PREP]?.trim()?.length <= 2) {
      errors[C.AVERAGE_PREP] = i18next.t(
        "Average Preparation Time should be more than 2 characters"
      );
      isFormValid = false;
      errorNotification(
        i18next.t(
          "Average Preparation Time should be more than 2 characters in section",
          {
            serviceType: i18next.t("Delivery"),
          }
        )
      );
      return { isFormValid, errors };
    }

    if (store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled) {
      if (!store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.amount) {
        errors["DELIVERY_CHARGES_WAIVE_OFF_ON_ORDER_AMOUNT"] = i18next.t(
          "Delivery charges waive off on order amount field is required"
        );
        isFormValid = false;
        errorNotification(
          i18next.t(
            "Delivery charges waive off on order amount field is required"
          )
        );
        return { isFormValid, errors };
      }
    }
    if (store?.values?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled) {
      if (
        !store?.values?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime ||
        !store?.values?.deliveryChargesWaiverInfo?.onDateRange?.endDateTime
      ) {
        errors["DELIVERY_CHARGES_WAIVE_OFF_ON_DATE_RANGE"] = i18next.t(
          "Delivery charges waive off date range field is required"
        );
        isFormValid = false;
        errorNotification(
          i18next.t("Delivery charges waive off date range field is required")
        );
        return { isFormValid, errors };
      }
    }
  }
  //avergage prep for pickup
  if (store?.values[C.ISPICKUPENABLED]) {
    if (!store?.values[C.PICKUP_AVERAGE_PREP]?.trim()) {
      errors[C.PICKUP_AVERAGE_PREP] = i18next.t(
        "Average Preparation Field is required"
      );
      isFormValid = false;
      errorNotification(
        i18next.t("Average Preparation Field is required in section", {
          serviceType: i18next.t("Pickup"),
        })
      );
      return { isFormValid, errors };
    } else if (store?.values[C.PICKUP_AVERAGE_PREP]?.trim()?.length <= 2) {
      errors[C.PICKUP_AVERAGE_PREP] = i18next.t(
        "Average Preparation Time should be more than 2 characters"
      );
      isFormValid = false;
      errorNotification(
        i18next.t(
          "Average Preparation Time should be more than 2 characters in section",
          {
            serviceType: i18next.t("Pickup"),
          }
        )
      );
      return { isFormValid, errors };
    }
  }
  // avergage prep dine
  if (store?.values[C.ISDINEINENABLED]) {
    if (!store?.values[C.DINEIN_AVERAGE_PREP]?.trim()) {
      errors[C.DINEIN_AVERAGE_PREP] = i18next.t(
        "Average Preparation Field is required"
      );
      isFormValid = false;
      errorNotification(
        i18next.t("Average Preparation Field is required in section", {
          serviceType: i18next.t("Contactless Dining"),
        })
      );
      return { isFormValid, errors };
    } else if (store?.values[C.DINEIN_AVERAGE_PREP]?.trim()?.length <= 2) {
      errors[C.DINEIN_AVERAGE_PREP] = i18next.t(
        "Average Preparation Time should be more than 2 characters"
      );
      isFormValid = false;
      errorNotification(
        i18next.t(
          "Average Preparation Time should be more than 2 characters in section",
          {
            serviceType: i18next.t("Contactless Dining"),
          }
        )
      );
      return { isFormValid, errors };
    }
  }

  if (!store?.values[C.KEY_CITY] || store.values[C.KEY_CITY]?.trim() === "") {
    errors[C.KEY_CITY] = i18next.t("Please select city");
    isFormValid = false;
    errorNotification(i18next.t("Please select city"));
    return { isFormValid, errors };
  }

  if (store.values[C.KEY_TIMEZONE] === "") {
    errors[C.KEY_TIMEZONE] = i18next.t("Please select time zone");
    isFormValid = false;
    errorNotification(i18next.t("Please select time zone"));
    return { isFormValid, errors };
  }

  if (store.values.email !== "") {
    store.values.email = store?.values?.email?.trim();
    if (!emailValidation().test(store.values[C.KEY_EMAIL])) {
      isFormValid = false;
      errors[C.KEY_EMAIL] = i18next.t("Please enter valid email-ID.");
      errorNotification(i18next.t("Please enter valid email-ID."));
      return { isFormValid, errors };
    }
  } else if (store.values?.email === "") {
    isFormValid = false;
    errors[C.KEY_EMAIL] = i18next.t("Email Field is required");
    errorNotification(i18next.t("Email Field is required"));
    return { isFormValid, errors };
  }

  console.log({ phoneNumber: store.values });
  if (!store?.values[C.KEY_PHONE]) {
    errors[C.KEY_PHONE] = i18next.t("Please enter a valid Phone Number");
    isFormValid = false;
    errorNotification(i18next.t("Please enter a valid Phone Number"));
    return { isFormValid, errors };
  }
  if (store.values[C.KEY_PHONE]) {
    if (
      (store.values[C.KEY_PHONE]?.charAt(4) === "0" &&
        store.values[C.KEY_PHONE]?.length < 11) ||
      (store.values[C.KEY_PHONE]?.charAt(4) !== "0" &&
        store.values[C.KEY_PHONE]?.length < 10)
    ) {
      errors[C.KEY_PHONE] = i18next.t("Please enter a valid Phone Number");
      isFormValid = false;
      errorNotification(i18next.t("Please enter a valid Phone Number"));
      return { isFormValid, errors };
    }
  }
  if (store?.values[C.KEY_WHATSAPP_PHONE]) {
    if (
      (store?.values[C.KEY_WHATSAPP_PHONE]?.charAt(4) === "0" &&
        store?.values[C.KEY_WHATSAPP_PHONE]?.length !== 14) ||
      (store?.values[C.KEY_WHATSAPP_PHONE]?.charAt(4) !== "0" &&
        store?.values[C.KEY_WHATSAPP_PHONE]?.length !== 13)
    ) {
      errors[C.KEY_WHATSAPP_PHONE] = i18next.t(
        "Please Enter a valid whatsapp number"
      );
      isFormValid = false;
      errorNotification(i18next.t("Please Enter a valid whatsapp number"));
      return { isFormValid, errors };
    }
  }

  // else {
  // if (
  //   (store?.values[C.KEY_WHATSAPP_PHONE]?.charAt(4) === "0" &&
  //     store?.values[C.KEY_WHATSAPP_PHONE]?.length !== 14) ||
  //   (store?.values[C.KEY_WHATSAPP_PHONE]?.charAt(4) !== "0" &&
  //     store?.values[C.KEY_WHATSAPP_PHONE]?.length !== 13)
  // ) {
  //   errors[C.KEY_WHATSAPP_PHONE] = i18next.t(
  //     "Please Enter a valid whatsapp number"
  //   );
  //   isFormValid = false;
  //   errorNotification(i18next.t("Please Enter a valid whatsapp number"));
  //   return { isFormValid, errors };
  // }
  // }

  if (store?.values[C.KEY_MANUAL_ADDRESS]?.trim()?.length <= 5) {
    errors[C.KEY_MANUAL_ADDRESS] = i18next.t(
      "Address should be more than 5 characters"
    );

    isFormValid = false;
    errorNotification(i18next.t("Address should be more than 5 characters"));
    return { isFormValid, errors };
  }

  if (store?.values[C.KEY_MAP_ADDRESS].replace(" ", "") === "") {
    errors[C.KEY_MAP_ADDRESS] = i18next.t("Please Locate your store on map");
    isFormValid = false;
    errorNotification(i18next.t("Please Locate your store on map"));
    return { isFormValid, errors };
  }
  if (
    store?.values[C.KEY_MAP_ADDRESS] !== "" &&
    store?.values[C.KEY_MAP_ADDRESS]?.trim()?.length <= 5
  ) {
    errors[C.KEY_MAP_ADDRESS] = i18next.t(
      "Address should be more than 5 characters"
    );
    isFormValid = false;
    errorNotification(i18next.t("Address should be more than 5 characters"));
    return { isFormValid, errors };
  }
  if (store.values[C.KEY_MAP_ADDRESS] && store?.values?.zoom <= 20) {
    errors[C.KEY_MAP_ADDRESS] = i18next.t("zoom level error");
    isFormValid = false;
    errorNotification(i18next.t("zoom level error"));
    return { isFormValid, errors };
  }

  //delivey payment types
  if (
    store.values[C.ISDELIVERYENABLED] &&
    store.values[C.KEY_PAYMENT_TYPES]?.length === 0
  ) {
    errors[C.KEY_PAYMENT_TYPES] = i18next.t(
      "Please select atleast one PAYMENT TYPE in section",
      {
        serviceType: i18next.t("Delivery"),
      }
    );
    isFormValid = false;
    errorNotification(
      i18next.t("Please select atleast one PAYMENT TYPE in section", {
        serviceType: i18next.t("Delivery"),
      })
    );
    return { isFormValid, errors };
  }

  //  pickup payment types
  if (
    store.values[C.ISPICKUPENABLED] &&
    store.values[C.KEY_PICKUP_PAYMENT_TYPES]?.length === 0
  ) {
    errors[C.KEY_PICKUP_PAYMENT_TYPES] = i18next.t(
      "Please select atleast one PAYMENT TYPE in section",
      {
        serviceType: i18next.t("Pickup"),
      }
    );
    isFormValid = false;
    errorNotification(
      i18next.t("Please select atleast one PAYMENT TYPE in section", {
        serviceType: i18next.t("Pickup"),
      })
    );
    return { isFormValid, errors };
  }

  if (
    store.values[C.ISDINEINENABLED] &&
    store.values[C.KEY_DINEIN_PAYMENT_TYPES]?.length === 0
  ) {
    errors[C.KEY_DINEIN_PAYMENT_TYPES] = i18next.t(
      "Please select atleast one PAYMENT TYPE in section",
      {
        serviceType: i18next.t("Contactless Dining"),
      }
    );
    isFormValid = false;
    errorNotification(
      i18next.t("Please select atleast one PAYMENT TYPE in section", {
        serviceType: i18next.t("Contactless Dining"),
      })
    );
    return { isFormValid, errors };
  }

  ///preORdering Vlidtion for delivery
  if (store.values[C.ISDELIVERYENABLED]) {
    const { isFormValidForDelivery, errorsForDelivery } =
      validatePreOrderingForDelivery(store, errors, isFormValid);
    isFormValid = isFormValidForDelivery;
    errors = { ...errors, ...errorsForDelivery };

    if (!isFormValidForDelivery) {
      return { isFormValid, errors };
    }
  }
  if (store.values[C.ISPICKUPENABLED]) {
    const { isFormValidForPickup, errorsForPickup } =
      validatePreOrderingForPickup(store, errors, isFormValid);
    isFormValid = isFormValidForPickup;
    errors = { ...errors, ...errorsForPickup };
    if (!isFormValidForPickup) {
      return { isFormValid, errors };
    }
  }

  return { isFormValid, errors };
};

export const VALIDATE_INPUTS_STEP1 = (store) => {
  let isFormValid = true;
  let errors = {
    email: "",
    mobileNumber: "",
    baseLanguage: {
      name: "",
      description: "",
    },
    city: "",
    taxRate: "",
  };
  if (store[C.BASE_LANGUAGE].name.trim() === "") {
    errors[C.BASE_LANGUAGE].name = i18next.t("Name Field is required");
    isFormValid = false;
    errorNotification(i18next.t("Name Field is required"));
    return { isFormValid, errors };
  }
  if (store[C.BASE_LANGUAGE].description.trim() === "") {
    errors[C.BASE_LANGUAGE].description = i18next.t(
      "Description Field is required"
    );
    isFormValid = false;
    errorNotification(i18next.t("Description Field is required"));
    return { isFormValid, errors };
  }
  if (store.email !== "") {
    store.email = store.email.trim();
    if (!emailValidation().test(store[C.KEY_EMAIL])) {
      isFormValid = false;
      errors[C.KEY_EMAIL] = i18next.t("Please enter valid email-ID.");
      errorNotification(i18next.t("Please enter valid email-ID."));
      return { isFormValid, errors };
    }
  } else if (store.email === "") {
    isFormValid = false;
    errors[C.KEY_EMAIL] = i18next.t("Email Field is required");
    errorNotification(i18next.t("Email Field is required"));
    return { isFormValid, errors };
  }

  if (
    !store?.mobileNumber ||
    (store?.mobileNumber?.charAt(4) === "0" &&
      store?.mobileNumber?.length < 11) ||
    (store.mobileNumber?.charAt(4) !== "0" && store?.mobileNumber?.length < 10)
  ) {
    errors[C.KEY_PHONE] = i18next.t("Please enter a valid Phone Number");
    isFormValid = false;
    errorNotification(i18next.t("Please enter a valid Phone Number"));
    return { isFormValid, errors };
  }
  if (
    store?.whatsAppContact === null ||
    store?.whatsAppContact === "" ||
    store?.whatsAppContact === undefined
  ) {
  } else {
    if (
      (store?.whatsAppContact?.charAt(4) === "0" &&
        store?.whatsAppContact?.length !== 14) ||
      (store?.whatsAppContact?.charAt(4) !== "0" &&
        store?.whatsAppContact?.length !== 13)
    ) {
      errors[C.KEY_WHATSAPP_PHONE] = i18next.t(
        "Please Enter a valid whatsapp number"
      );
      isFormValid = false;
      errorNotification(i18next.t("Please Enter a valid whatsapp number"));
      return { isFormValid, errors };
    }
  }

  if (store[C.KEY_TAX_RATE].trim() == "") {
    errors[C.KEY_TAX_RATE] = i18next.t("Tax Rate Field is required");
    isFormValid = false;
    errorNotification(i18next.t("Tax Rate Field is required"));
    return { isFormValid, errors };
  }

  if (!store[C.KEY_CITY] || store[C.KEY_CITY]?.trim() === "") {
    errors[C.KEY_CITY] = i18next.t("Please select city");
    isFormValid = false;
    errorNotification(i18next.t("Please select city"));
    return { isFormValid, errors };
  }

  if (store[C.KEY_TIMEZONE] == "") {
    errors[C.KEY_TIMEZONE] = i18next.t("Please select time zone");
    isFormValid = false;
    errorNotification(i18next.t("Please select time zone"));
    return { isFormValid, errors };
  }
  return { isFormValid, errors };
};

export const VALIDATE_INPUTS_STEP2 = (store) => {
  let isFormValid = true;
  let errors = {};

  if (
    store[C.KEY_MANUAL_ADDRESS]?.trim() === "" ||
    store[C.KEY_MANUAL_ADDRESS]?.trim()?.length <= 5
  ) {
    errors[C.KEY_MANUAL_ADDRESS] = i18next.t(
      "Address should be more than 5 characters"
    );
    isFormValid = false;
    errorNotification(i18next.t("Address should be more than 5 characters"));
    return { isFormValid, errors };
  }

  if (store[C.KEY_MAP_ADDRESS]?.trim() === "") {
    errors[C.KEY_MAP_ADDRESS] = i18next?.t("Please Locate your store on map");
    isFormValid = false;
    errorNotification(i18next?.t("Please Locate your store on map"));
    return { isFormValid, errors };
  }
  if (
    store[C.KEY_MAP_ADDRESS] !== "" &&
    store[C.KEY_MAP_ADDRESS]?.trim()?.length <= 5
  ) {
    errors[C.KEY_MAP_ADDRESS] = i18next.t(
      "Address should be more than 5 characters"
    );
    isFormValid = false;
    errorNotification(i18next.t("Address should be more than 5 characters"));
    return { isFormValid, errors };
  }
  if (store[C.KEY_MAP_ADDRESS] && store?.zoom <= 20) {
    errors[C.KEY_MAP_ADDRESS] = i18next.t("zoom level error");
    isFormValid = false;
    errorNotification(i18next.t("zoom level error"));
    return { isFormValid, errors };
  }

  return { isFormValid, errors };
};

export const VALIDATE_INPUTS_STEP3 = (store) => {
  console.log("VALIDATE_INPUTS_STEP3", store);
  let isFormValid = true;
  let errors = {};
  if (store.values[C.ISDELIVERYENABLED]) {
    if (store.values[C.KEY_PAYMENT_TYPES]?.length === 0) {
      errors[C.KEY_PAYMENT_TYPES] = i18next.t(
        "Please select atleast one PAYMENT TYPE in section",
        {
          serviceType: i18next.t("Delivery"),
        }
      );
      isFormValid = false;
      errorNotification(
        i18next.t("Please select atleast one PAYMENT TYPE in section", {
          serviceType: i18next.t("Delivery"),
        })
      );
      return { isFormValid, errors };
    }

    if (
      store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled ||
      store?.values?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled
    ) {
      if (!store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.amount) {
        errors["DELIVERY_CHARGES_WAIVE_OFF_ON_ORDER_AMOUNT"] = i18next.t(
          "Delivery Charges Waive Off Field is required"
        );
        isFormValid = false;
        errorNotification(
          i18next.t("Delivery Charges Waive Off Field is required")
        );
        return { isFormValid, errors };
      }

      if (
        !store?.values?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime ||
        !store?.values?.deliveryChargesWaiverInfo?.onDateRange?.endDateTime
      ) {
        errors["DELIVERY_CHARGES_WAIVE_OFF_ON_DATE_RANGE"] = i18next.t(
          "Delivery charges waive off date range field is required"
        );
        isFormValid = false;
        errorNotification(
          i18next.t("Delivery charges waive off date range field is required")
        );
        return { isFormValid, errors };
      }
    }

    return { isFormValid, errors };
  }

  //  pickup payment types
  if (
    store.values[C.ISPICKUPENABLED] &&
    store.values[C.KEY_PICKUP_PAYMENT_TYPES]?.length === 0
  ) {
    errors[C.KEY_PICKUP_PAYMENT_TYPES] = i18next.t(
      "Please select atleast one PAYMENT TYPE in section",
      {
        serviceType: i18next.t("Pickup"),
      }
    );
    isFormValid = false;
    errorNotification(
      i18next.t("Please select atleast one PAYMENT TYPE in section", {
        serviceType: i18next.t("Pickup"),
      })
    );
    return { isFormValid, errors };
  }

  if (
    store.values[C.ISDINEINENABLED] &&
    store.values[C.KEY_DINEIN_PAYMENT_TYPES]?.length === 0
  ) {
    errors[C.KEY_DINEIN_PAYMENT_TYPES] = i18next.t(
      "Please select atleast one PAYMENT TYPE in section",
      {
        serviceType: i18next.t("Contactless Dining"),
      }
    );
    isFormValid = false;
    errorNotification(
      i18next.t("Please select atleast one PAYMENT TYPE in section", {
        serviceType: i18next.t("Contactless Dining"),
      })
    );
  }
  return { isFormValid, errors };
};

export const VALIDATE_INPUTS_STEP4 = (store, errorMessages) => {
  let isFormValid = true;
  let errors = {};
  if (store[C.KEY_SERVICE_TYPES].length === 0) {
    errors[C.KEY_SERVICE_TYPES] = errorMessages[0];
    isFormValid = false;
    errorNotification(errorMessages[0]);
  }
  return { isFormValid, errors };
};

const validatePreOrderingForDelivery = (store) => {
  let isFormValidForDelivery = true;
  let errorsForDelivery = {};
  const sectionName = i18next.t("in delivery section");

  const errorMsgs = {
    minTimeInAdvance: i18next.t("Please enter minimum lead time", {
      sectionName: sectionName,
    }),
    minTimeInAdvanceUnit: i18next.t("Please select minimum lead time unit", {
      sectionName: sectionName,
    }),
    maxDaysInAdvance: i18next.t(
      "Please select maximum number of  days in advance",
      {
        sectionName: sectionName,
      }
    ),
    slotInterval: i18next.t("Please select a slot interval", {
      sectionName: sectionName,
    }),
  };
  if (store?.values?.isPreOrderEnabledForDelivery) {
    //Lead time
    if (
      ifnullOrUndefined(
        store?.values?.preOrderSettingsForDelivery?.minTimeInAdvance
      )
    ) {
      errorsForDelivery[SC.KEY_PREORDER_MIN_TIME_IN_ADVANCE_DELIVERY] =
        errorMsgs.minTimeInAdvance;
      isFormValidForDelivery = false;
      errorNotification(errorMsgs.minTimeInAdvance);
      return { isFormValidForDelivery, errorsForDelivery };
    }

    //lead time unit
    if (!store?.values?.preOrderSettingsForDelivery?.minTimeInAdvanceUnit) {
      errorsForDelivery[SC.KEY_PREORDER_MIN_TIME_IN_ADVANCE_UNIT] =
        errorMsgs.minTimeInAdvanceUnit;
      isFormValidForDelivery = false;
      errorNotification(errorMsgs.minTimeInAdvanceUnit);
      return { isFormValidForDelivery, errorsForDelivery };
    }

    //max days in advance
    if (!store?.values?.preOrderSettingsForDelivery?.maxDaysInAdvance) {
      errorsForDelivery[SC.KEY_PREORDER_MAX_DAYS_IN_ADVANCE] =
        errorMsgs.maxDaysInAdvance;
      isFormValidForDelivery = false;
      errorNotification(errorMsgs.maxDaysInAdvance);
      return { isFormValidForDelivery, errorsForDelivery };
    }

    //slot interval
    if (!store?.values?.preOrderSettingsForDelivery?.timeInterval) {
      errorsForDelivery[SC.KEY_PREORDER_SLOT_INTERVAL] = errorMsgs.slotInterval;
      isFormValidForDelivery = false;
      errorNotification(errorMsgs.slotInterval);
      return { isFormValidForDelivery, errorsForDelivery };
    }

    //slot interval unit

    if (!store?.values?.preOrderSettingsForDelivery?.timeIntervalUnit) {
      errorsForDelivery[SC.KEY_PREORDER_SLOT_INTERVAL_UNIT] =
        errorMsgs.slotInterval;
      isFormValidForDelivery = false;
      errorNotification(errorMsgs.slotInterval);
      return { isFormValidForDelivery, errorsForDelivery };
    }
  }
  return { isFormValidForDelivery, errorsForDelivery };
};

const validatePreOrderingForPickup = (store) => {
  let isFormValidForPickup = true;
  let errorsForPickup = {};
  const sectionName = i18next.t("in pickup section");
  const errorMsgs = {
    minTimeInAdvance: i18next.t("Please enter minimum lead time", {
      sectionName: sectionName,
    }),
    minTimeInAdvanceUnit: i18next.t("Please select minimum lead time unit", {
      sectionName: sectionName,
    }),
    maxDaysInAdvance: i18next.t(
      "Please select maximum number of  days in advance",
      {
        sectionName: sectionName,
      }
    ),
    slotInterval: i18next.t("Please select a slot interval", {
      sectionName: sectionName,
    }),
  };
  if (store?.values?.isPreOrderEnabledForPickup) {
    //Lead time
    if (
      ifnullOrUndefined(
        store?.values?.preOrderSettingsForPickup?.minTimeInAdvance
      )
    ) {
      errorsForPickup[SC.KEY_PREORDER_MIN_TIME_IN_ADVANCE_PICKUP] =
        errorMsgs.minTimeInAdvance;
      isFormValidForPickup = false;
      errorNotification(errorMsgs.minTimeInAdvance);
      return { isFormValidForPickup, errorsForPickup };
    }

    //lead time unit
    if (!store?.values?.preOrderSettingsForPickup?.minTimeInAdvanceUnit) {
      errorsForPickup[SC.KEY_PREORDER_MIN_TIME_IN_ADVANCE_UNIT] =
        errorMsgs.minTimeInAdvanceUnit;
      isFormValidForPickup = false;
      errorNotification(errorMsgs.minTimeInAdvanceUnit);
      return { isFormValidForPickup, errorsForPickup };
    }

    //max days in advance
    if (!store?.values?.preOrderSettingsForPickup?.maxDaysInAdvance) {
      errorsForPickup[SC.KEY_PREORDER_MAX_DAYS_IN_ADVANCE] =
        errorMsgs.maxDaysInAdvance;
      isFormValidForPickup = false;
      errorNotification(errorMsgs.maxDaysInAdvance);
      return { isFormValidForPickup, errorsForPickup };
    }

    //slot interval
    if (!store?.values?.preOrderSettingsForPickup?.timeInterval) {
      errorsForPickup[SC.KEY_PREORDER_SLOT_INTERVAL] = errorMsgs.slotInterval;
      isFormValidForPickup = false;
      errorNotification(errorMsgs.slotInterval);
      return { isFormValidForPickup, errorsForPickup };
    }

    //slot interval unit

    if (!store?.values?.preOrderSettingsForPickup?.timeIntervalUnit) {
      errorsForPickup[SC.KEY_PREORDER_SLOT_INTERVAL_UNIT] =
        errorMsgs.slotInterval;
      isFormValidForPickup = false;
      errorNotification(errorMsgs.slotInterval);
      return { isFormValidForPickup, errorsForPickup };
    }
  }
  return { isFormValidForPickup, errorsForPickup };
};
