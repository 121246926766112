import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { API } from "api";
import { UTILS } from "utils";
import PhoneNumberInput from "components/PhoneNumberInput";
import LanguageToggle from "components/LanguageToggle";
import useToggleLanguage from "hooks/useToggleLanguage";
const ResetPasswordStep1 = () => {
  const { t } = useTranslation();
  const router = useHistory();
  const { lang, switchLanguage } = useToggleLanguage();
  let [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const submitForgotPassword = async () => {
    setLoading(true);
    if (!UTILS.isValidMobileNumber(phone)) {
      setError(t("Please enter correct mobile number"));
      setLoading(false);
      return;
    }
    if (phone?.length >= 0 && phone?.charAt(4) === "0") {
      phone = phone?.slice(0, 4) + phone?.slice(5);
    }
    const result = await API.FORGOT_PASSWORD({
      phoneNumber: phone,
    });
    if (result?.success === 1) {
      UTILS.handleSuccesNotification(
        t(result?.message ?? "Code Sent to your email, Please check your email")
      );

      router.push({
        pathname: "/newpassword",
        state: {
          phoneNumber: phone,
        },
      });
    } else {
      setError(t(result.error));
      UTILS.handleErrorNotification(t(result.error));
    }
    setLoading(false);
  };

  const handleValues = (key, val) => {
    setPhone(val);
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <LanguageToggle
          onClick={switchLanguage}
          selected={lang}
          authenticated={false}
        />
        <div className="flex justify-center mt-10p">
          <div className="w-full md:w-2/5 block">
            <Spin spinning={loading}>
              <div className="text-center">
                <img
                  className="inline-block my-3"
                  src={process.env.REACT_APP_COMPANY_LOGO_SMALL}
                  alt="resthero"
                />
                <h2 className="font-raleway font-bold text-21px">
                  {t("Forgot Password")}
                </h2>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <div className="col-span-1 sm:col-span-1">
                  <PhoneNumberInput
                    label={t("Phone Number")}
                    value={phone}
                    error={error}
                    keyVal={"phoneNumber"}
                    handleValues={handleValues}
                  />
                </div>
              </div>

              <div className="text-center my-5">
                <button
                  className={` font-montserrat py-2 px-12 border border-transparent shadow-sm font-medium rounded  focus:outline-none ${
                    !phone
                      ? "bg-gray-500 text-white cursor-not-allowed"
                      : "bg-theme-red text-white"
                  } `}
                  disabled={!phone}
                  onClick={() => submitForgotPassword()}
                >
                  {t("Submit")}
                </button>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordStep1;
