import { Button, Spin } from "antd";
import useMakeDefault from "features/menus/hooks/useMakeDefault";
import React from "react";
import Modal from "components/Modal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { logToSentry } from "utils/Utils";
import { errorNotification } from "utils/Utils";
import { useTranslation } from "react-i18next";

function MakeDefault({
  isLoading,
  setIsMakeDefault,
  isMakeDefaultOpen,
  menuId,
}) {
  const makeMenuDeafault = useMakeDefault();
  const params = useParams();
  const { t } = useTranslation();

  const markMenuDefault = () => {
    if (!params?.menuId) {
      logToSentry(
        `menu id is required to make default menu ${params}`,
        "error"
      );
      errorNotification("Please try again later");
      return;
    }
    makeMenuDeafault({ menuId, callback: closemodal });

    console.log("menuid", params?.menuId);
  };
  const closemodal = () => {
    setIsMakeDefault(false);
  };

  return (
    <Modal
      modalWidth="rounded-md border border-gray-300"
      open={isMakeDefaultOpen}
      responsiveClass="w-full md:w-1/3 lg:w-1/3 xl:w-2/6 2xl:w-1/5"
      onClose={closemodal}
    >
      <Spin spinning={isLoading}>
        <div className="p-4">
          <h2>{t("Are you sure you want to make this menu default")}</h2>
        </div>
        <div className="flex justify-end px-4 py-4">
          <Button
            type="primary"
            onClick={markMenuDefault}
            className=" border me-1"
            size="medium"
          >
            {/* <Button className="bg-blue-600 text-white" onClick={markMenuDefault}> */}
            {t("Yes")}
          </Button>
          <Button
            className="bg-red-600 text-white"
            onClick={() => setIsMakeDefault(false)}
          >
            {t("No")}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
}

export default MakeDefault;
