import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PageHeading from "components/PageHeading";
import CountriesTable from "../components/CountriesTable";
import { apiSlice } from "redux/Api.Slice";
import { AreasAction } from "redux/actions";
import { useGetAddedCountriesMutation } from "redux/shipping-methods/shipping-service";
import { UTILS } from "utils";
import { LoaderAction } from "redux/actions";
const ManageCountries = () => {
  const [trigger] = apiSlice.endpoints.getBuisnessAreas.useLazyQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [getAddedCountries] = useGetAddedCountriesMutation();
  const { selectedStore, syncData } = useSelector(
    (state) => state.AreasReducer
  );

  const getBussinessAreas = UTILS.withTryCatch(async () => {
    dispatch(LoaderAction.setLoading(true));

    const { data } = await trigger();
    if (data?.success === 1 && data.data) {
      dispatch(AreasAction.setFetchedAreas(data.data));
      getCountries(data.data);
    } else {
      console.error("countries error");
    }
    dispatch(LoaderAction.setLoading(false));
  });

  useEffect(() => {
    getBussinessAreas();
  }, [selectedStore?.value, syncData]);

  const getCountries = UTILS.withTryCatch(async (shippingData) => {
    dispatch(LoaderAction.setLoading(true));

    const userDetail = UTILS.getCurrentUser();
    if (selectedStore?.value && userDetail) {
      const obj = {
        brandId: userDetail.brandId,
        storeId: selectedStore.value,
        language: "EN",
      };
      const data = await getAddedCountries(obj).unwrap();

      if (data?.success === 1) {
        const listOfCountries = UTILS.getCustomizedCountryList(
          shippingData,
          data.data.areas
        );
        if (listOfCountries) {
          dispatch(AreasAction.setcustomizedCountryList(listOfCountries));
        }
      }
    }
    dispatch(LoaderAction.setLoading(false));
  });

  return (
    <div>
      <PageHeading heading={t("Delivery Countries")} />
      <div className="pb-3 px-2">
        <CountriesTable />
      </div>
    </div>
  );
};
export default ManageCountries;
