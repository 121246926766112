import { ACTIONS } from "config";
export const INITIAL_STATE = {
  toggle: false,
};

const CatelogueReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.STORES_CHANGED:
      return {
        toggle: !state.toggle,
      };

    default:
      return state;
  }
};

export default CatelogueReducer;
