import moment from "moment";
export const isItemPublished = (item, state) => {
  const isNotAvailableInCurrentStore = item?.unavailableStores?.find(
    (store) => store?.storeId === state?.currentStoreId
  );

  if (isNotAvailableInCurrentStore) {
    if (!isNotAvailableInCurrentStore?.nextAvailableTime)
      return {
        isValid: false,
        data: isNotAvailableInCurrentStore,
      };
    const nextAvailableTime = moment.unix(
      isNotAvailableInCurrentStore?.nextAvailableTime
    );
    const currentTime = moment();

    // If the current time is greater than the next available time, then the item is available
    if (currentTime.isAfter(nextAvailableTime)) {
      return {
        isValid: true,
        data: null,
      };
    } else {
      return {
        isValid: false,
        data: isNotAvailableInCurrentStore,
      };
    }
  } else {
    return {
      isValid: true,
      data: null,
    };
  }
};
