export const MAPPED_AREA_MODAL_ID = {
  ADD_AREA: 0,
  DELETE_AREA: 1,
  UPDATE_AREA: 2,
  ADD_ITEM_TO_AREA: 3,
  DRAW_MAP_AREA: 4,
  EDIT_MAP_AREA: 5,
  OPEN_DISTANCE_MODAL: 6,
  EDIT_DISTANCE_MODAL: 7,
};
