import React from "react";
import ReactDevicePreview from "react-device-preview";

const LoadPreviewWithDevice = ({
  classes,
  random,
  width,
  height,
  getStorePreviewURL,
}) => {
  return (
    <ReactDevicePreview device="iphone8plus" color="black" scale="0.7">
      <iframe
        className={classes}
        key={random}
        width={width}
        height={height}
        src={getStorePreviewURL()}
      ></iframe>
    </ReactDevicePreview>
  );
};
export default LoadPreviewWithDevice;
