import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const BackButton = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  return (
    <div className="flex inline-flex items-center">
      <span
        className="cursor-pointer flex items-center"
        onClick={() => router.goBack()}
      >
        <span>
          <svg
            xmlns="https://www.w3.org/2000/svg"
            className={`w-4 h-4 text-blue-500 inline-block ${
              currentLanguage === "AR" ? "rotat180" : "rotat0"
            }`}
            viewBox="0 0 32 32"
            fill="currentColor"
          >
            <path d="M23.86 5.509L13.284 15.991l10.591 10.501c.987.978.987 2.565 0 3.543-.99.978-2.59.978-3.577 0L7.921 17.765c-.495-.49-.742-1.134-.741-1.776 0-.641.246-1.284.742-1.772L20.281 1.963c.988-.978 2.59-.978 3.579 0 .987.978.987 2.566-.001 3.545z"></path>
          </svg>
        </span>
        <span className="text-16px mx-2 text-blue-500">{t("BACK")}</span>
      </span>
    </div>
  );
};

export default BackButton;
