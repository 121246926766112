import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  syncData: false,
};

const MenuReducer = createSlice({
  name: "MenuReducer",
  initialState,
  reducers: {
    syncData: (state, action) => {
      state.syncData = !state.syncData;
    },
  },
});

export const { syncData } = MenuReducer.actions;
export default MenuReducer.reducer;
