import React, { memo } from "react";
import { Route, Switch } from "react-router-dom";
import AuthLayout from "Layouts/AuthLayout/AuthLayout";
import DashBoardLayout from "Layouts/dashboardLayout/Layout";
import SideBarRoutesWithComponent from "Layouts/dashboardLayout/SideBarRoutesWithComponent";
import VerificationInput from "features/register/components/VerifyOtp";
function Routes() {
  return (
    <Switch>
      <Route path="/app">
        <DashBoardLayout layoutType="dashboard">
          <SideBarRoutesWithComponent />
        </DashBoardLayout>
      </Route>
      <Route path="/verify">
        <DashBoardLayout layoutType="verification">
          <Route
            path="/verify/validate-otp"
            exact
            component={VerificationInput}
          />
        </DashBoardLayout>
      </Route>
      <Route path="">
        <AuthLayout />
      </Route>
    </Switch>
  );
}

export default memo(Routes);
