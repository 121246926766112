import { errorHandler } from "api/api/errorHandler";
import { LoaderAction } from "redux/actions";
import { useGetMenuInfoMutation } from "redux/menu/service";
import { UTILS } from "utils";
import { useDispatch } from "react-redux";
import { useMenuContext } from "store/contexts/Menus";

function useGetMenuInfo() {
  const dispatch = useDispatch();
  const [getMenuInfo] = useGetMenuInfoMutation();
  const { updateState, resetState } = useMenuContext();

  const getCategoriesByMenuId = async (menuId) => {
    if (!menuId) return;
    try {
      dispatch(LoaderAction.setLoading(true));
      resetState();
      const userDetail = UTILS.getCurrentUser();
      const data = await getMenuInfo({
        brandId: userDetail?.brandId,
        menuId,
      });

      if (data?.data?.success === 1 && data?.data) {
        let res = data?.data?.data;
        updateState("menuInfo", res);
        updateState("categories", res?.categories);
        updateState("itemLinkedStores", res?.stores);
        updateState("linkedStores", res?.stores);
      } else {
        updateState("isLinkMenuWithStoresModalOpen", false);
        UTILS.errorNotification(errorHandler(data?.data?.error?.data));
      }
    } catch (error) {
      updateState("isLinkMenuWithStoresModalOpen", false);
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return getCategoriesByMenuId;
}

export default useGetMenuInfo;
