import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ifNotNullAndUndfine } from "utils/Utils";

const PackageInfoTwo = (props) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const getBgClass = currentLanguage === "AR" ? "bgliar" : "bglien";

  const isPackageSubscribed = () => {
    let billingCycle = props.billingCycle;
    let billingCycle2 = props.subscribedPlan.billingCycle;
    if (
      ifNotNullAndUndfine(billingCycle) &&
      ifNotNullAndUndfine(billingCycle2)
    ) {
      if (billingCycle2 === billingCycle && props?.subscribed) {
        return true;
      }
      return false;
    }
  };

  const Billing = {
    MONTHLY: 1,
    SEMI_ANNUALLY: 6,
    YEARLY: 12,
  };

  const subscribeNewPackage = () => {
    // let userDetail = UTILS.getCurrentUser();
    // let totalPrice = parseFloat(
    //   props.originalPrice * Billing[props.billingCycle]
    // )?.toFixed(2);
    // TRACKING_EVENTS.subscribedUsers({
    //   plan_name: props.billingCycle,
    //   plan_price: totalPrice,
    //   currency: C.AED,
    //   transaction_id: userDetail?.userId,
    // });
    let id = props.packageId;
    let cycle = props.billingCycle;
    let isSubscribed = isPackageSubscribed();
    props.subscribePackage(id, cycle, isSubscribed);
  };

  return (
    <div className="w-full md:w-1/2 xl:w-1/3 2xl:w-1/3  my-2 flex flex-col">
      <div className="bg-white relative h-full flex flex-col rounded-lg p-8 mx-4 md:mx-1 w-fll shadow-4xl">
        <h1 className=" mb-4">
          <span className="text-21px font-semibold">{props?.name}</span>
          {props?.packageIdentifier === "growth" && (
            <span className="bg-green-700 text-white px-2 mx-2 py-2 text-14px rounded-sm">
              {t("best to start")}
            </span>
          )}
        </h1>
        <div>
          <span className="text-40px leading-10">
            <span className="align-top leading-4 text-15px">{t("AED")}</span>
            <span className="font-semibold">{props?.price}</span>
            <span className="ms-1  text-15px">/{t("month")}</span>
          </span>
        </div>
        {props?.billedBy && (
          <p className="pt-2 tracking-wide">
            <span>{t(props?.billedBy)}</span> <br />
            {t("or AED month-to-month", { price: props?.originalPrice })}
          </p>
        )}
        <hr className="mt-2 border-1" />
        <div className="pt-8 pb-16">
          <p className="font-semibold text-gray-400">
            <ul className={`${getBgClass}`}>
              {Object?.keys(props?.text)?.map((value) => {
                return (
                  value !== "id" && (
                    <li className="ps-3 text-15px font-normal text-gray-700">
                      <span className="ps-1">{t(value)}</span>
                    </li>
                  )
                );
              })}
            </ul>
          </p>

          <div className="absolute bottom-3  left-0 right-0 mx-8">
            <button
              type="button"
              className={
                "rounded  py-2 font-medium shadow-lg w-full my-4 text-white " +
                (isPackageSubscribed() ? "bg-green-600" : "bg-blue-500")
              }
              onClick={subscribeNewPackage}
            >
              {isPackageSubscribed() ? (
                <span>{t("Selected")}</span>
              ) : (
                <span>{t("Choose Plan")}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageInfoTwo;
