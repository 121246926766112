import React, { memo } from "react";
import { Redirect } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import { UTILS } from "utils";

function Auth() {
  let loggedInUserToken = UTILS.getCurrentUser()?.token;
  return loggedInUserToken ? (
    <Redirect to="/app/dashboard" />
  ) : (
    <PublicRoutes />
  );
}

export default memo(Auth);
