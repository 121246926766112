import React from "react";
import UpdateItem from "../components/UpdateItem";
import usePushBack from "hooks/usePushBack";
import { useHistory } from "react-router-dom";
function UpdateItemContainer() {
  const router = useHistory();

  usePushBack("/app/dashboard/items", router?.location?.state?.itemId);
  return <UpdateItem />;
}

export default UpdateItemContainer;
