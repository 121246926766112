import React, { useState } from "react";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import Form from "../components/Form";
import Stats from "../components/Stats";
import { UTILS } from "utils";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { downloadReports } from "utils/Utils";
import OnlineOderTable from "../components/OnlineOrderTable";
import { errorNotification } from "utils/Utils";
const Orders = () => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    startData: "",
    endData: "",
    loading: false,
    orderHistory: {
      data: [],
      totalPages: 0,
    },
    codEarning: 0,
    onlineEarning: 0,
  });
  const [page, setPage] = useState(1);

  const setLocalStateData = (key, val) =>
    setState((prevState) => ({ ...prevState, [key]: val }));

  const resetstate = () => {
    setLocalStateData("orderHistory", {
      data: [],
      totalPages: 0,
    });

    setLocalStateData("codEarning", 0);
    setLocalStateData("onlineEarning", 0);
  };

  const getOrdersHistory = async (pageNumber) => {
    try {
      if (!UTILS.validateSelectedDates(state.startData, state.endData)) return;
      setLocalStateData("loading", true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.GET_ORDER_HISTORY({
        startDate: state.startData,
        endDate: state.endData,
        brandId: userDetail.brandId,
        page: pageNumber ?? page,
      });

      if (result?.success === 1 && result?.data?.orders) {
        if (result?.data?.orders.length >= 1 && result?.data?.count) {
          setLocalStateData("orderHistory", {
            data: result.data.orders,
            totalPages: result.data.count?.totalOrders,
          });
          const cardEarning = result.data.count.cardMachineOrdersEarning || 0;
          const codeEarning = result.data.count.cashOrdersEarning || 0;
          const totalCodEarning = Number(codeEarning + cardEarning).toFixed(2);
          setLocalStateData("codEarning", totalCodEarning);
          setLocalStateData(
            "onlineEarning",
            result.data.count.onlineOrdersEarning
          );
        } else {
          UTILS.handleSuccesNotification(t("No Order History Found"));
          resetstate();
        }
      } else {
        // reset
        resetstate();
        UTILS.handleErrorNotification(
          t(result?.error?.message || errorHandler(result?.error))
        );
      }
    } catch (error) {
      resetstate();
      UTILS.handleErrorNotification(
        t(error?.error?.message || errorHandler(error?.error))
      );
    } finally {
      setLocalStateData("loading", false);
    }
  };

  const setDate = (date) => {
    setLocalStateData("startData", date.startData);
    setLocalStateData("endData", date.endData);
  };

  const downloadexcel = async () => {
    try {
      if (!UTILS.validateSelectedDates(state.startData, state.endData)) return;
      setLocalStateData("loading", true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.downloadOrderHistoryExcelReport({
        startDate: state.startData,
        endDate: state.endData,
        brandId: userDetail.brandId,
      });
      console.log({ downloadOrderHistoryExcelReport: result });
      if (result?.success === 1 && result?.data) {
        UTILS.handleSuccesNotification(
          t("Order History Exported Successfully")
        );
        const url = result?.data;
        downloadReports(url);
      } else {
        UTILS.handleErrorNotification(
          t(result?.error?.message || errorHandler(result?.error))
        );
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error?.message || error?.error));
    } finally {
      setLocalStateData("loading", false);
    }
  };
  return (
    <div className="px-4 mx-0 md:mx-8 py-10">
      <Spin spinning={state.loading}>
        <div className="text-19px text-blue-600 pt-4">{t("Order History")}</div>

        <div className="flex justify-between flex-wrap items-center">
          <Form setState={setDate} getOrdersHistory={getOrdersHistory} />

          {state.orderHistory?.data?.length > 0 && (
            <Button
              onClick={downloadexcel}
              type="primary"
              className="mx-0 sm:mx-2 ant-btn ant-btn-primary w-36 mb-4 sm:mb-0"
            >
              {t("Export")}
            </Button>
          )}
        </div>
        <Stats
          cardEarning={state.codEarning}
          onlineEarning={state.onlineEarning}
        />
        {state.orderHistory?.data?.length > 0 && (
          <div className="flex justify-between items-center">
            <div className="text-lg font-semibold">
              <span className="text-gray-700 mx-1">{t("Note")}</span>
              <span className="text-gray-400 font-normal text-13px">
                {t(
                  "The online orders income is shown without PAYMENT gateway fees"
                )}
              </span>
            </div>
          </div>
        )}

        <OnlineOderTable
          orders={state.orderHistory}
          handlePageChange={getOrdersHistory}
          setPage={setPage}
        />
      </Spin>
    </div>
  );
};

export default Orders;
