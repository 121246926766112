import { Button, Form, Input, InputNumber, Spin } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
const RefundForm = ({ closeModal, onSubmit, loading, order }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const handleSubmit = (values) => {
    const { reason, amount } = values;
    onSubmit({
      order,
      reason,
      amount,
    });
  };
  return (
    <Spin spinning={loading}>
      <div className="w-full p-5 relative mx-auto my-auto bg-white ">
        <div className="">
          <div className="flex-auto justify-center my-2">
            <div className="flex justify-between">
              <h2 className=" font-extrabold me-4">{t("Refund Order")}</h2>
            </div>
            <table class="border-collapse border border-slate-400 w-full">
              <tr>
                <td className="border border-slate-300 p-4">{t("Order")} #</td>
                <td className="border border-slate-300 p-4">
                  {order?.orderNumber}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-4">{t("Status")}</td>
                <td className="border border-slate-300 p-4">
                  {order?.orderStatus}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Max Allowed (Order Total Price)")}
                </td>
                <td className="border border-slate-300 p-4">
                  {new Intl.NumberFormat(currentLanguage, {
                    style: "currency",
                    currency: "AED",
                  }).format(order?.totalOrderPrice || 0)}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-4">{t("Store")}</td>
                <td className="border border-slate-300 p-4">
                  {order?.storeInfo.baseLanguage.name}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Whatsapp Number")}
                </td>
                <td className="border border-slate-300 p-4">
                  <span dir="ltr">{order?.userInfo?.phoneNumber}</span>
                </td>
              </tr>
            </table>

            <div className=" text-auto py-4">
              <Form
                name="refund_form"
                layout="vertical"
                form={form}
                scrollToFirstError
                onFinish={handleSubmit}
              >
                <div className="w-full flex flex-wrap flex-col">
                  <div className="w-full sm:w-1/2">
                    <Form.Item
                      label={t("Refund Reason")}
                      name="reason"
                      rules={[
                        {
                          required: true,
                          message: t("Please enter refund reason"),
                        },
                      ]}
                    >
                      <Input placeholder={t("Refund Reason")} />
                    </Form.Item>
                  </div>
                  <div className="w-full sm:w-1/2 refund">
                    <Form.Item
                      label={t("Refund Amount")}
                      name={"amount"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: t("Please enter refund amount"),
                        },
                        {
                          max: order.totalOrderPrice,
                          type: "number",
                          message: t("Maximum refund amount can be", {
                            amount: order.totalOrderPrice,
                          }),
                        },
                        {
                          min: 2,
                          type: "number",
                          message: t("Minimum refund amount can be 2"),
                        },
                        {
                          pattern: /^(?!.*\.([0-9]{3,}|0{3,})).*$/,
                          message: t("Maximum 2 decimal points allowed"),
                        },
                      ]}
                    >
                      <InputNumber placeholder={t("Refund Amount")} />
                    </Form.Item>
                  </div>
                </div>
                <div className=" mt-2  text-center md:block flex justify-center">
                  <Button
                    type="default"
                    size="large"
                    className="w-24 mx-2"
                    disabled={loading}
                    onClick={closeModal}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className="w-24"
                    size="large"
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                  >
                    {t("Submit")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default RefundForm;
