import { errorHandler } from "api/api/errorHandler";
import { LoaderAction } from "redux/actions";
import { UTILS } from "utils";
import { useDispatch } from "react-redux";
import { API } from "api";
import { useShippingContext } from "store/contexts/ShippingMethodContext";

function useGetAllZones() {
  const dispatch = useDispatch();
  const { updateShippingState } = useShippingContext();

  const getAddedDeliveryZones = async (storeId) => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      const response = await API.getDistanceBaseDeliveryZones({
        storeId,
        brandId: userDetail.brandId,
      });
      if (response?.success === 1 && response?.data) {
        updateShippingState("distanceBasedAreas", response.data);
        return response?.data;
      } else {
        UTILS.errorNotification(errorHandler(response?.message));
        return null;
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
      return null;
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return {
    getAddedDeliveryZones,
  };
}

export default useGetAllZones;
