import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import AreasList from "./AreasList";
import ShowCityListButton from "../../common/ShowCityListButton";
import { AreasAction } from "redux/actions";
import { CONSTANTS as C } from "config";

const CitiesList = ({ cityAreas, city, searchTerm, openAreas }) => {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { selectedAreas } = useSelector((state) => state.AreasReducer);
  const [open, setOpen] = useState(false);
  const setOpenState = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const saveAreaIntoState = (area) => {
    let update = [];
    const isAreaAlreadySelected = !!selectedAreas?.find(
      (c) => c?._id === area?._id
    );
    if (isAreaAlreadySelected) {
      update = selectedAreas.filter((c) => c._id !== area._id);
    } else {
      update = [...selectedAreas, area];
    }
    dispatch(AreasAction.updateSelectedArea(update));
  };

  const isChecked = (city) => {
    return !!selectedAreas.find((c) => c?._id === city?._id);
  };

  const Items = useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return cityAreas;
    }
    return cityAreas.filter((item) =>
      currentLanguage === C.CURRENT_LANGUAGE.EN
        ? item?.areaNameEN?.toLowerCase()?.includes(searchTerm?.trim())
        : item?.areaNameAR?.includes(searchTerm?.trim())
    );
  }, [cityAreas, searchTerm, currentLanguage]);
  return (
    <div className="w-full mb-2 px-2">
      <div className="flex w-full my-2">
        <ShowCityListButton
          open={open || openAreas}
          setOpenState={setOpenState}
        />
        <div className="flex justify-between w-full mx-4">
          <div>{city?.[`cityName${currentLanguage}`]}</div>
          <div>
            {/* <span
              className={`block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200 
                half-check
               `}
            ></span> */}
          </div>
        </div>
      </div>
      {open || openAreas ? (
        <AreasList {...{ isChecked, Items, saveAreaIntoState }} />
      ) : null}
    </div>
  );
};

export default CitiesList;
