import React from "react";
import ViewPaymentLink from "../components/ViewPaymentLink";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import usePushBack from "hooks/usePushBack";

const ViewPaymentLinkInfo = () => {
  const router = useHistory();
  usePushBack(
    "/app/dashboard/payment-links",
    router?.location?.state?.paymentLink
  );
  return (
    <div>
      <ViewPaymentLink />
    </div>
  );
};

export default ViewPaymentLinkInfo;
