import axios from "axios";
import { UTILS } from "utils";
import reduxStore from "redux/store";
import { APPUpdate } from "redux/actions";
import { NotPersistAction } from "redux/actions";
import { CONSTANTS as C } from "config";

// Create axios client, pre-configured with baseURL
let APIKit = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 15000,
});

// Before sending request to server
APIKit.interceptors.request.use(function (config) {
  const userDetail = UTILS.getCurrentUser();
  config.params = {
    app_version: process.env.REACT_APP_VERSION,
    app_name: C.APP_NAME,
  };
  if (process.env.REACT_APP_REFRRER) {
    config.params.referrer = process.env.REACT_APP_REFRRER;
  }
  if (userDetail) {
    config.headers.Authorization = userDetail.token;
  }

  return config;
});
// Before sending Response to the Client

// check if any update has refresh action
const AppActions = (data) => {
  const ActionArray = data?.reduce((acc, file) => {
    let check = file?.actions?.find((action) => action?.refresh === true);
    if (check) {
      acc.push(check);
    }
    return acc;
  }, []);
  return ActionArray;
};

APIKit.interceptors.response.use(
  function (response) {
    if (
      response?.data?.error_category === "new_version_enforced" &&
      response?.data?.success === 0 &&
      response.data.data
    ) {
      let RefreshActions = AppActions(response.data.data);
      if (RefreshActions?.length >= 1) {
        reduxStore.dispatch(APPUpdate.refreshApp());
      }
    }

    return response;
  },
  function (error) {
    if (error?.code === C.API_ERRORS.TIMEOUT_ERROR) {
      reduxStore.dispatch(NotPersistAction.updateOnlineStatus(false));
    }

    if (
      typeof error === "object" &&
      error !== null &&
      typeof error.toJSON === "function"
    ) {
      if (error?.toJSON()?.message === C.API_ERRORS.NETWORK_ERROR) {
        reduxStore.dispatch(NotPersistAction.updateOnlineStatus(false));
      } else {
        return Promise.reject(error?.response?.data);
      }
    } else {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error?.response?.data);
  }
);

export async function get(url, config = {}) {
  const language = (await UTILS?.asyncLocalStorage.getLanguage()) ?? "EN";
  return await APIKit.get(url, language, { ...config }).then(
    (response) => response.data
  );
}

export async function post(url, data, config = {}) {
  const language = (await UTILS?.asyncLocalStorage.getLanguage()) ?? "EN";
  let res = await APIKit.post(url, { ...data, language }, { ...config });
  return res.data;
}
export async function postUpdated(url, data, config = {}) {
  try {
    const language = (await UTILS?.asyncLocalStorage.getLanguage()) ?? "EN";
    let res = await APIKit.post(url, { ...data, language }, { ...config });
    return res.data;
  } catch (error) {
    return error;
  }
}
export async function patch(url, data, config = {}) {
  let res = await APIKit.patch(url, { ...data }, { ...config });
  return res;
}

// file upload
export const upload = async (url, data, config = {}) => {
  const language = (await UTILS?.asyncLocalStorage.getLanguage()) ?? "EN";
  data.append("language", language);
  return APIKit.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      if (response) {
        if (response.status === 200) {
          return response.data;
        }
      }
    })
    .catch((error) => {
      return error;
    });
};
export default APIKit;
