import { API } from "api";
import Card from "components/Card";
import RadioButton from "components/RadioButton";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
const StoreAccessRightsList = (props) => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const getStoreList = (result) => {
    let arr = [];
    if (
      result.success === 1 &&
      UTILS.isValidObject(["data", "pulledStores"], result)
    ) {
      arr = [...result.data.pulledStores];
      const modifiedArr = arr.map((obj) => (obj = { ...obj, isON: false }));
      arr = modifiedArr;
      return arr;
    } else {
      UTILS.HANDLE_ERROR_NOTIFICATION(
        "topRight",
        "error",
        t("There are not stores")
      );
      return arr;
    }
  };
  const getUserStoresByUserId = (result) => {
    let tempArr = getStoreList(result[0]);
    if (result[1].success === 1 && UTILS.isValidObject(["data"], result[1])) {
      const arr = [...result[1].data];
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        tempArr = tempArr.map((el) =>
          el._id == element.storeId._id ? { ...el, isON: true } : el
        );
      }

      setStores(tempArr);
    } else {
    }
  };

  const addUserToStore = async (sId, val) => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.ADD_NEW_STORE_ADMIN({
      token: userDetail.token,
      userType: props.user.userType,
      adminId: props.user._id,
      storeId: sId,
      brandId: userDetail.brandId,
    });
    if (result.success == 1) {
      let arr = [...stores];
      var modifiedArr = arr.map((el) =>
        el._id == sId ? { ...el, isON: val } : el
      );
      setStores(modifiedArr);
    } else {
    }
  };

  const deleteUserFromStore = async (sId, val) => {
    const userDetail = UTILS.getCurrentUser();
    const result = await API.DELETE_STORE_ADMIN({
      token: userDetail.token,
      userType: props.user.userType,
      adminId: props.user._id,
      storeId: sId,
      brandId: userDetail.brandId,
    });
    if (result.success == 1) {
      let arr = [...stores];
      var modifiedArr = arr.map((el) =>
        el._id == sId ? { ...el, isON: val } : el
      );
      setStores(modifiedArr);
    } else {
    }
  };

  const handleValues = (key, val) => {
    if (val) addUserToStore(key, val);
    else deleteUserFromStore(key, val);
  };

  const Row = (item) => {
    return (
      <tr className="border-b border-gray-300" key={item._id}>
        <td className="px-3 py-2">{item.baseLanguage?.name}</td>
        <td className="px-3 py-2">{item.manualAddress}</td>
        <td className="">
          <RadioButton
            label={""}
            value={item.isON}
            keyVal={item._id}
            handleValues={handleValues}
          />
        </td>
      </tr>
    );
  };

  const getUserDataWithStores = async () => {
    const userDetail = UTILS.getCurrentUser();
    setLoading(true);
    const result = await API.GET_DATA_FOR_USERS_WITH_STORE_LIST({
      token: userDetail.token,
      brandId: userDetail.brandId,
      userId: props.user._id,
      language: "EN",
    });

    getUserStoresByUserId(result);
    setLoading(false);
  };

  useEffect(() => {
    getUserDataWithStores();
  }, []);
  return (
    <div className="w-full bg-white px-4 py-2 text-center">
      <h2 className="text-15px font-bold py-1">{t("Grant Access to")}</h2>
      <h3 className="text-19px font-bold text-indigo-600 ">
        {"[" + props.user?.email + "]"}
      </h3>
      {/* <h4 className="text-16px font-ibm-plex text-gray-500 pb-12">Allow User to Manage Live Orders, Order History and Items for Selected Stores</h4> */}
      <p className="text-sm text-gray-500">
        {t(
          "Allow User to Manage Live Orders, Order History and Items for Selected Stores"
        )}
      </p>
      <Card>
        <Spin spinning={loading}>
          <table className="w-full text-14px font-lato text-left">
            <thead className="border-t border-b border-gray-300 font-lato">
              <tr>
                <td className="text-gray-500 px-3 py-3 tracking-wider">
                  {t("Store")}
                </td>
                <td className="text-gray-500 px-3 py-3 tracking-wider w-1/2">
                  {t("Address")}
                </td>
                <td className="text-gray-500 px-1 py-3 tracking-wider">
                  {t("Access")}
                </td>
              </tr>
            </thead>
            <tbody className="bg-white text-black">
              {stores &&
                stores.map((item) => {
                  return Row(item);
                })}
            </tbody>
          </table>
        </Spin>
      </Card>
      <div className="p-3  mt-2 text-center space-x-4 md:block">
        <button
          onClick={props.onClose}
          className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100"
        >
          {t("Close")}
        </button>
      </div>
    </div>
  );
};

StoreAccessRightsList.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
};
export default StoreAccessRightsList;
