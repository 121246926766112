import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

function VersionUpdate({ heading, message, btnText, onClick }) {
  const { t } = useTranslation();
  useEffect(() => {
    localStorage.setItem("APP-version", process.env.REACT_APP_VERSION);
  }, []);

  return (
    <div className="flex  min-h-scrn px-4 relative mx-auto my-auto bg-gray-300 min-h-screen items-center justify-center">
      <div className="p-6 md:max-w-lg  bg-white ">
        <div className="">
          <div className="p-5 flex-auto justify-center">
            <h2 className="text-16px text-blue-600 font-bold pt-4 pb-2 text-center">
              {/* {t("new update available")} */}
              {t(heading)}
            </h2>
            <p className="text-sm text-gray-500 px-8  text-center">
              {/* {t(
                "We made bug fixes and improvements to keep your operations smooth. Please click below button to get latest application version."
              )} */}
              {t(message)}
            </p>
          </div>
          <div className="pb-5 text-center md:block">
            <Button className="" type="primary" size="large" onClick={onClick}>
              {/* {t("get latest version")} */}
              {t(btnText)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VersionUpdate;
