import RadioButton from "components/RadioButton";
import SectionHeading from "components/SectionHeading";
import React from "react";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import moment from "moment";
import { CONSTANTS as C } from "config";
import "moment-timezone";
import useDeliveryFeeWaiveoff from "../hooks/useDeliveryFeeWaiveoff";
import { UTILS } from "utils";
const { RangePicker } = DatePicker;

function DeliveryChargesWaveOff({ store, setStore }) {
  const { t } = useTranslation();
  moment.tz.setDefault("Asia/Dubai");
  const {
    handleOnOrderAmount,
    handleDateRange,
    handleSetDate,
    dataConversion,
  } = useDeliveryFeeWaiveoff({ setStore, store });

  const getInitialValues = () => {
    const value = [
      store?.values?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime
        ? dataConversion(
            store?.values?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime
          )
        : null,
      store?.values?.deliveryChargesWaiverInfo?.onDateRange?.endDateTime
        ? dataConversion(
            store?.values?.deliveryChargesWaiverInfo?.onDateRange?.endDateTime,
            C.DATE_FORMAT
          )
        : null,
    ];
    return value;
  };
  const [error, setError] = React.useState("");

  const handleInputValue = (key, val) => {
    const value = UTILS.NumberConversion(val);
    if (
      value <= 0 &&
      store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled
    ) {
      handleOnOrderAmount(key, null);
      setError(t("Minimum Order Amount should be greater than 0"));
      return;
    } else {
      setError("");
      handleOnOrderAmount(key, value);
    }
  };

  return (
    <div className="mb-4">
      <SectionHeading title={t("Delivery Charges Wave Off")} />
      <div className="">
        <RadioButton
          customKey="onOrderAmount"
          label={t("On Order Amount")}
          value={
            store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled
          }
          keyVal="isEnabled"
          handleValues={handleOnOrderAmount}
        />
      </div>
      <div className="px-4">
        {store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.isEnabled && (
          <div className="w-full md:w-1/2 py-4">
            <TextInput
              placeholder={t("Enter Minimum Order Amount")}
              value={
                store?.values?.deliveryChargesWaiverInfo?.onOrderAmount?.amount
              }
              keyVal="amount"
              handleValues={handleInputValue}
              error={error}
              label={t("Enter Minimum Order Amount")}
              mandatory={true}
            />
          </div>
        )}
      </div>
      <div className="flex">
        <RadioButton
          customKey="dateRange"
          label={t("On Date Range")}
          value={
            store?.values?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled
          }
          keyVal="isEnabled"
          handleValues={handleDateRange}
        />
      </div>
      <div className="px-4">
        {store?.values?.deliveryChargesWaiverInfo?.onDateRange?.isEnabled && (
          <div className="w-full md:w-1/2 py-4 wave-off">
            <RangePicker
              placeholder={[t("Start Date"), t("End Date")]}
              allowClear={false}
              onChange={(date, dateString) => handleSetDate(date, dateString)}
              inputReadOnly={true}
              showTime={{ format: "hh:mm A", use12Hours: true }}
              format="YYYY-MM-DD HH:mm A"
              value={getInitialValues()}
              className="h-11 w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DeliveryChargesWaveOff;
