import React, { useState } from "react";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { API } from "api";
import { UTILS } from "utils";
import CustomerTable from "../components/CustomerTable";
import Form from "../components/Form";
import Heading from "../components/Heading";

import { errorHandler } from "api/api/errorHandler";
import { downloadReports } from "utils/Utils";
import { errorNotification } from "utils/Utils";
const CustomersReport = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [state, setState] = useState({
    startData: "",
    endData: "",
  });
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState({
    data: [],
    totalPages: 1,
  });
  const resetstate = () => {
    setCustomers((customers) => ({
      data: [],
      totalPages: 1,
    }));
  };

  const getCustomerReport = async (pageNumber) => {
    try {
      if (!UTILS.validateSelectedDates(state.startData, state.endData)) return;
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.GET_CUSTOMER_REPORT({
        startDate: state.startData,
        endDate: state.endData,
        brandId: userDetail.brandId,
        page: pageNumber ?? page,
      });
      if (result?.success === 1) {
        if (result?.data?.customers && result?.data?.customers.length > 0) {
          const customerReport = await UTILS.getEachCustomeData(
            result?.data?.customers
          );

          setCustomers((customers) => ({
            data: customerReport,
            totalPages: result?.data?.count,
          }));
        } else {
          UTILS.handleSuccesNotification(t("No Customer Report Found"));
          resetstate();
        }
      }
    } catch (error) {
      resetstate();
      UTILS.handleErrorNotification(
        t(error?.error?.message || errorHandler(error?.error))
      );
    } finally {
      setLoading(false);
    }
  };

  const downloadexcel = async () => {
    try {
      if (!UTILS.validateSelectedDates(state.startData, state.endData)) return;
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.downloadCustomerExcelReport({
        startDate: state.startData,
        endDate: state.endData,
        brandId: userDetail.brandId,
      });
      if (result?.success === 1 && result?.data) {
        UTILS.handleSuccesNotification(
          t("Customer Report Fetched Successfully")
        );
        const url = result?.data;
        downloadReports(url);
      } else {
        UTILS.handleErrorNotification(
          t(result?.error?.message || errorHandler(result?.error))
        );
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error?.message || error?.error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-4 mx-0 md:mx-8 py-10">
      <Spin spinning={loading}>
        <Heading title={t("Customer Report")} />
        <div className="flex justify-between flex-wrap items-center">
          <Form setState={setState} getOrdersHistory={getCustomerReport} />

          {customers?.data?.length > 0 && (
            <Button
              onClick={downloadexcel}
              type="primary"
              className="mx-0 sm:mx-2 ant-btn ant-btn-primary w-36 mb-4 sm:mb-0"
            >
              {t("Export")}
            </Button>
          )}
        </div>
        <CustomerTable
          {...{ customers }}
          handlePageChange={getCustomerReport}
          setPage={setPage}
        />
      </Spin>
    </div>
  );
};

export default CustomersReport;
