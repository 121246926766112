import { API } from "api";
import TextInput from "components/TextInput";
import React, { useState } from "react";
import { UTILS } from "utils";
import PropTypes from "prop-types";
import { SVG_Business } from "svg/SVGs";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import { errorHandler } from "api/api/errorHandler";

const AddCategory = ({ menuId, handleValues, onClose }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState(UTILS.getCategoryModel());
  const [loading, setLoading] = useState(false);

  const handleChange = (key, val) => {
    setCategory((prevState) => ({
      ...prevState,
      values: {
        baseLanguage: {
          [key]: val,
        },
      },
      errors: {
        baseLanguage: {
          [key]: "",
        },
      },
    }));
  };
  const validateInput = () => {
    const baseLanguageName = category?.values?.baseLanguage?.name;
    if (!baseLanguageName || UTILS.hasWhiteSpace(baseLanguageName)) {
      setCategory((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
        },
        errors: {
          baseLanguage: {
            name: t("Enter category name"),
          },
        },
      }));
      return false;
    }
    return true;
  };

  const saveCategory = async () => {
    try {
      const {
        baseLanguage: { name },
      } = category.values;
      const trimmedName = name.trim();

      if (!validateInput()) {
        return;
      }

      const userDetail = UTILS.getCurrentUser();
      setLoading(true);

      const result = await API.ADD_CATEGORY({
        ...category.values,
        brandId: userDetail.brandId,
        menuId: menuId,
        baseLanguage: { name: trimmedName },
      });

      if (result?.success === 1) {
        const categoryId = result.data?.category?._id;
        const obj = {
          categoryId: {
            _id: categoryId,
            baseLanguage: { name: trimmedName },
            ...category.values,
          },
          items: [],
        };
        handleValues(obj);
        UTILS.successNotification(result?.message || t("Category added"));
      } else {
        UTILS.errorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full p-5 mx-auto my-auto rounded-xl shadow-lg  bg-white">
      <Spin spinning={loading}>
        <div className="">
          <div className="p-5 justify-center">
            <SVG_Business />
            <h2 className="text-xl font-bold py-4 text-center">
              {t("Add New Category")}
            </h2>
            <div className="w-full px-4">
              <TextInput
                label={t("Name")}
                placeHolder={t("Example watches")}
                value={category?.values?.baseLanguage?.name}
                keyVal={"name"}
                handleValues={handleChange}
                mandatory={true}
                error={category.errors.baseLanguage.name}
              />
            </div>
          </div>
          <div className="p-3 mt-2 text-center space-x-4 md:block">
            <Button type="default" size="large" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="primary" size="large" onClick={saveCategory}>
              {t("Create")}
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

AddCategory.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddCategory;
