import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Tabs } from "antd";
import DineIn from "./components/DineIn";
import PickupDelivery from "./components/PickupDelivery";
import { useGetSettingsMutation } from "redux/settings/settingsSlice";
import { UTILS } from "utils";
import useAllStores from "hooks/useCurrentUserStores";
import RadioButton from "components/RadioButton";
import { Link } from "react-router-dom";
import { CONSTANTS as C } from "config";
import html2canvas from "html2canvas";
const { TabPane } = Tabs;

const Index = () => {
  const { t } = useTranslation();
  const { stores, loading } = useAllStores();
  const [settings, setSettings] = useState();
  const [orderPageLink, setOrderPageLink] = useState();
  const [getSettings] = useGetSettingsMutation();
  const fetchSettings = async () => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
    };
    const { data } = await getSettings(obj);
    if (data?.success === 1 && data?.data) {
      setSettings(data.data);
    }
  };
  useEffect(() => {
    fetchSettings();
    const userDetail = UTILS.getCurrentUser();
    const text = userDetail.storeFrontURL + userDetail?.temporaryDomainName;
    setOrderPageLink(text);
  }, []);

  const [qrCodeSetting, setQrCodeSetting] = useState({
    innerColor: null,
    logo: null,
  });

  const handleLogoSettings = (key, val) => {
    if (val) {
      setQrCodeSetting({
        ...qrCodeSetting,
        logo: settings?.setting?.brandLogoFirst,
      });
    } else {
      setQrCodeSetting({
        ...qrCodeSetting,
        logo: null,
      });
    }
  };

  const handleColorSettings = (key, val) => {
    if (val) {
      setQrCodeSetting({
        ...qrCodeSetting,
        innerColor: settings?.setting?.color,
      });
    } else {
      setQrCodeSetting({
        ...qrCodeSetting,
        innerColor: null,
      });
    }
  };

  const handleDownload = (ID) => {
    const el = document.getElementById(ID);
    html2canvas(el, {}).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="px-4 lg:px-8 py-4">
        <h1 className="text-gray-900 text-24px md:text-30px font-medium">
          {t("QR codes for your Digital Menu")}
        </h1>
        <h3 className="text-gray-500 pt-2 pb-4">
          {t(
            "Create a QR code with your ordering page and advertise it on offline places like diner tables and counters, packaging, banners, and more"
          )}
        </h3>
        <div className="flex-col items-center w-full lg:w-1/2 p-4 justify-between border border-gray-200">
          {/* add note  */}
          <div className="text-red-600 font-bold text-14px ">
            {t(
              "When using branding in QR codes, always verify that they are scanning well before printing"
            )}
          </div>
          <div className="flex justify-between items-center pt-8">
            <h2 className="text-gray-900  text-17px font-semibold">
              {t("Customization")}
            </h2>
            <Link
              to="/app/dashboard/settings/branding"
              className="text-blue-500 ant-btn ant-btn-defualt ant-btn-lg mx-2"
            >
              {t("Edit Branding")}
            </Link>
          </div>

          <div className="pb-3 flex">
            <RadioButton
              label={t("Use business colors")}
              value={qrCodeSetting?.innerColor}
              keyVal={"color"}
              handleValues={handleColorSettings}
            />
          </div>
        </div>
        <div className="mt-5">
          <Tabs defaultActiveKey="1" onChange={() => {}}>
            <TabPane
              tabBarStyle="color: pink;"
              tab={t("Pickup & Delivery")}
              key="1"
            >
              <PickupDelivery
                {...{ settings, orderPageLink, qrCodeSetting, handleDownload }}
              />
            </TabPane>
            {UTILS.getCatalogueType() !== C.BRAND_BASED && (
              <TabPane tab={t("DINEIN")} key="2">
                <DineIn
                  {...{
                    settings,
                    orderPageLink,
                    stores,
                    qrCodeSetting,
                    handleDownload,
                  }}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </Spin>
  );
};

export default Index;
