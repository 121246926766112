import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import CustomersTable from "../components/CustomersTable";
import { useGetAllBrandCustomersMutation } from "redux/loyalty/loyalty-service";

const LoyaltyCustomers = () => {
  const [searchTerm, setSearch] = useState(null);
  const [customers, setCustomers] = useState({
    data: [],
    totalrecords: 0,
    currentPage: 1,
  });
  const [getAllBrandCustomers] = useGetAllBrandCustomersMutation();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  async function fetchData(page, term) {
    const { data } = await getAllBrandCustomers({
      page: page,
      ...(term && {
        phoneNumber: term,
      }),
    });
    if (data?.success === 1 && data?.data?.users?.users?.length > 0) {
      handleCustomerState("data", data?.data?.users?.users);
      handleCustomerState("totalrecords", data?.data?.users?.count);
    }
  }

  const handSearch = (e) => setSearch(e.target.value);

  const handlePageChange = (page) => {
    handleCustomerState("currentPage", page);
    fetchData(page, searchTerm);
  };

  useEffect(() => {
    if (
      searchTerm === null ||
      searchTerm?.length === 0 ||
      searchTerm?.length > 2
    ) {
      handleCustomerState("currentPage", 1);
      fetchData(1, searchTerm);
    }
  }, [searchTerm]);

  const handleCustomerState = (key, val) => {
    setCustomers((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  return (
    <Spin spinning={isLoading}>
      <CustomersTable
        {...{ customers, handlePageChange, handSearch, searchTerm }}
      />
    </Spin>
  );
};
export default LoyaltyCustomers;
