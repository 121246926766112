import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NotPersistAction } from "redux/actions";
function useOffLine() {
  const dispatch = useDispatch();
  useEffect(() => {
    function handleOnline() {
      dispatch(NotPersistAction.updateOnlineStatus(true));
      window.location.reload();
    }

    function handleOffline() {
      dispatch(NotPersistAction.updateOnlineStatus(false));
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
}

export default useOffLine;
