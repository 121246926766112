import React, { Fragment } from "react";
import { Button } from "antd";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import { logErrorOnSentry } from "utils/Utils";
import { useCancelLyveGlobalOrderMutation } from "../service";

const ORDER_STATUSES = [
  "ASSIGN",
  "ASSIGNED",
  "UNASSIGNED",
  "PICK UP",
  "IN_PROGRESS",
]; //order cancelled in these cases

const canOrderBeCancelled = (order) => {
  const deliveryStatus =
    order?.logisticPartnerMetadata?.LYVE_GLOBAL?.deliveryStatus;
  if (!deliveryStatus) return false;
  // Order can be cancelled if the delivery status is in ORDER_STATUSES
  return ORDER_STATUSES.includes(deliveryStatus);
};

const CancelORder = ({ fetchOrderDetailsByID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cancelLyveGlobalOrder] = useCancelLyveGlobalOrderMutation();
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);

  const cancelOrder = async () => {
    try {
      if (!orderInfo?._id) {
        UTILS.errorNotification(t("Invalid Order ID. Please try again."));
        logErrorOnSentry(
          "Invalid Order ID. Please try again--Lyve global create Order",
          "error",
          JSON.stringify(orderInfo)
        );
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const result = await cancelLyveGlobalOrder({
        orderId: orderInfo._id,
        cancelReason: "testing",
      });
      console.log({ cancelLyveGlobalOrder: result });

      if (result?.data?.success === 1) {
        UTILS.successNotification(result?.data?.message);
      } else {
        UTILS.errorNotification(errorHandler(result?.data?.message));
      }
    } catch (error) {
      UTILS.handleErrorNotification(errorHandler(error?.error));
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 100));
      dispatch(LoaderAction.setLoading(false));
      await fetchOrderDetailsByID();
    }
  };

  const isCancellable = canOrderBeCancelled(orderInfo);

  return (
    <Fragment>
      <div className="flex flex-col justify-center items-center">
        <div className="ml-4 py-4 text-center">
          {isCancellable ? (
            <Button
              type="default"
              size="large"
              onClick={cancelOrder}
              className="my-2 rounded-md font-semibold"
            >
              {t("Cancel Order from LYVE GLOBAL")}
            </Button>
          ) : (
            <div>
              <span className="text-sm text-gray-500">
                {t(
                  "Your order is not cancellable at the moment. Please check the ORDER DETAIL tab for more details."
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CancelORder;
