import { errorHandler } from "api/api/errorHandler";
import { useLinkStoreWithMenuMutation } from "redux/menu/service";
import { UTILS } from "utils";
import { errorNotification } from "utils/Utils";

const getStoresObject = (selectedStores) => {
  let stores = [];
  let isValidate = true;
  if (!Array.isArray(selectedStores) || selectedStores?.length <= 0) {
    errorNotification("Please select at least one store to link");
    isValidate = false;
    return;
  }

  selectedStores?.forEach((store) => {
    if (store?.serviceType?.length <= 0 || !store?.serviceType) {
      errorNotification(`Please select service type for ${store?.label}`);
      isValidate = false;
      return;
    }
    stores.push({
      storeId: store?.storeId?._id,
      serviceType: store?.serviceType,
    });
  });

  return {
    stores,
    isValidate,
  };
};

function UesLinkStoreWithMenu({
  setLoading,
  updateState,
  selectedStores,
  getAllMenus,
  menuId,
}) {
  const [linkStoreWithMenu] = useLinkStoreWithMenuMutation();
  const linkMenu = async () => {
    if (!menuId) return UTILS.errorNotification("Please select menu");
    const { stores, isValidate } = getStoresObject(selectedStores);
    if (!isValidate) return;
    try {
      setLoading(true);
      const res = await linkStoreWithMenu({
        brandId: UTILS.getCurrentUser()?.brandId,
        menuId,
        stores,
      });
      if (res?.data?.success === 1) {
        updateState("linkedStores", []);
        updateState("isLinkMenuWithStoresModalOpen", false);
        UTILS.successNotification(res?.data?.message);
      } else {
        UTILS.errorNotification(errorHandler(res?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      await getAllMenus();
      setLoading(false);
    }
  };
  return {
    linkMenu,
  };
}

export default UesLinkStoreWithMenu;
