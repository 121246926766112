import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { SVG_PLUS } from "svg/SVGs";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useGetBrandInfoWithIDMutation,
  useUpdateShippingMethodStatusMutation,
} from "redux/shipping-methods/shipping-service";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import { SettingsAction } from "redux/actions";
import AlertBox from "components/AlertBox";
import useUserType from "hooks/useUserType";
import ShippingMethodsList from "../common/ShippingMethodsList";
import AreaBusinessIcon from "../common/AreaBusinessIcon";

const ManageDelivery = () => {
  const router = useHistory();
  const userType = useUserType();
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    switchModal: false,
    disableModal: false,
    title: "",
    description: "",
    switch: false,
    data: {},
    successBtnText: "",
    cancelBtnText: "",
  });
  const dispatch = useDispatch();
  const { CatalogueType } = useSelector((state) => state?.SettingsReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const [getBrandInfoWithID] = useGetBrandInfoWithIDMutation();
  const [updateShippingMethodStatus] = useUpdateShippingMethodStatusMutation();
  const [shippingInfo, setshippingInfo] = useState({
    activeMethod: {},
    brandInfo: {},
  });

  /**
   * It fetches the brand information from the backend.
   */
  const getBrandInfowithId = async () => {
    const userDetail = UTILS.getCurrentUser();
    const { data } = await getBrandInfoWithID({
      brandId: userDetail.brandId,
    });
    if (
      data?.success === 1 &&
      data?.data?.brand &&
      data?.data?.brand?.catalogueType
    ) {
      setshippingInfo((prevState) => ({
        ...prevState,
        brandInfo: data?.data?.brand,
      }));
      dispatch(
        SettingsAction.updateCatalogueType(data.data.brand.catalogueType)
      );
      handleMethodState(data.data);
    }
  };
  useEffect(() => {
    getBrandInfowithId();
  }, []);

  /* toggling the shipping method. */
  const toggleShippingMethod = UTILS.withTryCatch(async (key, val) => {
    if (returnButtonStatus(key, val)) return;
    const userDetail = UTILS.getCurrentUser();
    let obj = {
      brandId: userDetail.brandId,
      deliveryMethod: key,
      deliveryMethodStatus: val,
    };
    setModal((prevState) => ({
      ...prevState,
      data: obj,
    }));

    // disabing active method
    if (shippingInfo?.activeMethod?.methodName && !val) {
      setModal((prevState) => ({
        ...prevState,
        disableModal: true,
        switchModal: false,
        description: t("Do you want to disable the active delivery method"),
        successBtnText: t("yes"),
        cancelBtnText: t("no"),
      }));
    }

    if (val) {
      // switch to other method
      if (shippingInfo?.activeMethod?.methodName) {
        let des = t("It is possible to enable only one delivery method");
        setModal((prevState) => ({
          ...prevState,
          switchModal: true,
          disableModal: false,
          description: des,
          successBtnText: t("okay"),
          cancelBtnText: false,
        }));
      }
      //activating new  method
      else {
        await updateShippingMethodStatus(obj);
      }
    }
    getBrandInfowithId();
  });

  const disableMethod = async () => {
    await updateShippingMethodStatus(modal.data);
    await getBrandInfowithId();
    closeModal();
  };

  const closeModal = () => {
    setModal((prevState) => ({
      ...prevState,
      switchModal: false,
      disableModal: false,
    }));
  };

  // setting the active shipping method in state
  const handleMethodState = (data) => {
    setshippingInfo((prevState) => ({
      ...prevState,
      activeMethod:
        data?.brand?.deliveryMethods?.find((method) => method?.status) ?? {},
    }));
  };

  const returnButtonStatus = (shippingMethodName, toggleSwitchValue) => {
    if (userType === C.USER_TYPE.MANAGER) return true;
    return !!isAllowed(shippingMethodName) && toggleSwitchValue;
  };

  const isAllowed = (method) => {
    if (CatalogueType === C.OUTLET_BASED) {
      if (
        method === C.SHIPPING_METHODS.CITY_BASED ||
        method === C.SHIPPING_METHODS.COUNTRY_BASED
      )
        return true;
      else return false;
    } else return false;
  };

  return (
    <Spin spinning={isLoading}>
      <AlertBox
        open={modal.disableModal}
        {...{ setModal, isLoading, closeModal, modal }}
        PressYes={disableMethod}
      />
      <AlertBox
        open={modal.switchModal}
        {...{ setModal, isLoading, modal }}
        PressYes={closeModal}
      />
      <div className="pt-6 px-5  bg-white h-full">
        <h2 className="text-21px mb-8">{t("Delivery Methods")}</h2>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-3/12 px-0 pb-4 lg:px-4">
            <h3 className="font-medium text-17px mb-4">
              {t("Add a new delivery method")}
            </h3>
            <p className="">
              {t(
                "Select how you’ll be getting your products to customers. Add your preferred local delivery method."
              )}
            </p>
          </div>
          <div className="w-full lg:w-9/12">
            {shippingInfo?.brandInfo?.deliveryMethods?.length >= 1 && (
              <div className="flex flex-row rounded-md bg-gray-50 mb-6 px-5 py-8 items-center shadow ">
                <div className="rr w-full text-14px font-lato text-left">
                  <div className="bg-white text-black">
                    {shippingInfo?.brandInfo?.deliveryMethods?.map((obj, i) => (
                      <ShippingMethodsList
                        method={obj}
                        toggleShippingMethod={toggleShippingMethod}
                        CatalogueType={CatalogueType}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col sm:flex-row rounded-md bg-gray-50 mb-6 px-5 py-8 shadow">
              <div className="w-full lg:w-2/3 px-4">
                <h4 className="text-16px pb-4 font-medium">
                  {t("Local Delivery")}
                </h4>
                <p className="pb-4">
                  {t(
                    "Deliver orders yourself in city and suburbs or outsource delivery to the services you trust. Set when, where, and under which conditions you deliver."
                  )}
                </p>
                <Button
                  type="primary"
                  className="rounded-md px-12 items-center"
                  size="large"
                  onClick={() =>
                    router.push("/app/dashboard/local-delivery-methods")
                  }
                >
                  <SVG_PLUS color="#FFF" />
                  <span className="ps-1">{t("Add Delivery")}</span>
                </Button>
              </div>
              <AreaBusinessIcon />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ManageDelivery;
