import React from "react";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";

function DeleteAreasModal({ isLoading, selectedAreas, onClose, deleteAreas }) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-5 mx-auto my-auto rounded-xl shadow-lg  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            {selectedAreas && selectedAreas?.length === 1 ? (
              <h2 className="text-19px font-bold py-1">
                {t(`Are you sure want to delete selected area`)}
              </h2>
            ) : (
              <h2 className="text-19px font-bold py-1">
                {t("Are you sure want to delete selected areas")}
              </h2>
            )}
          </div>
          <div className="text-gray-500 p-1">
            {t("They will be removed from this outlet’s delivery reach.")}
          </div>
          <div className="p-3 mt-2 flex justify-end space-x-4 md:block">
            <Button type="default" size="large" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="primary" size="large" onClick={deleteAreas}>
              {t("Delete")}
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default DeleteAreasModal;
