import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import "antd/dist/antd.css";
import RegisterStep1 from "../components/RegisterStep1";
import RegisterStep2 from "../components/RegisterStep2";
import { UTILS } from "utils";
import { API } from "api/";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TRACKING_EVENTS } from "utils";
import { SettingsAction } from "redux/actions";
import { useDispatch } from "react-redux";
const Register = () => {
  const [state, setS] = useState({});
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState("");
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();

  const startDate = new Date().setHours(7, 0, 0);
  const endDate = new Date().setHours(20, 0, 0);
  const [stateRegister, setState] = useState({
    values: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      baseLanguage: "en",
      verificationCode: "",
      brandName: "",
      serviceType: "",
      storeName: "",
      storeNameAr: "",
      description: "",
      descriptionAr: "",
      storeEmail: "",
      storePhone: "",
      latitudeLongitude: {},
      PaymentOptionCOD: "",
      PaymentOptionCreditCard: "",
      workingHours: {
        saturdayStartTime: startDate,
        saturdayEndTime: endDate,
        saturday: false,
        sundayStartTime: startDate,
        sundayEndTime: endDate,
        sunday: true,
        mondayStartTime: startDate,
        mondayEndTime: endDate,
        monday: true,
        tuesdayStartTime: startDate,
        tuesdayEndTime: endDate,
        tuesday: true,
        wednesdayStartTime: startDate,
        wednesdayEndTime: endDate,
        wednesday: true,
        thursdayStartTime: startDate,
        thursdayEndTime: endDate,
        thursday: true,
        fridayStartTime: startDate,
        fridayEndTime: endDate,
        friday: true,
      },
    },
    errors: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      baseLanguage: "",
      verificationCode: "",
      brandName: "",
      serviceType: "",
      storeName: "",
      storeNameAr: "",
      description: "",
      descriptionAr: "",
      storeEmail: "",
      storePhone: "",
      PaymentOptionCOD: "",
      PaymentOptionCreditCard: "",
      latitudeLongitude: "",
      workingHours: {
        saturdayStartTime: "",
        saturdayEndTime: "",
        saturday: "",
        sundayStartTime: "",
        sundayEndTime: "",
        sunday: "",
        mondayStartTime: "",
        mondayEndTime: "",
        monday: "",
        tuesdayStartTime: "",
        tuesdayEndTime: "",
        tuesday: "",
        wednesdayStartTime: "",
        wednesdayEndTime: "",
        wednesday: "",
        thursdayStartTime: "",
        thursdayEndTime: "",
        thursday: "",
        fridayStartTime: "",
        fridayEndTime: "",
        friday: "",
      },
    },
  });

  // handle input change values
  const handleValues = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: value,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  // user register input validation
  const registerValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (stateRegister?.values.email !== "") {
      if (!UTILS.emailValidation().test(stateRegister.values["email"].trim())) {
        formIsValid = false;
        errors["email"] = t("Please enter valid email-ID.");
      }
    } else if (stateRegister?.values?.email === "") {
      formIsValid = false;
      errors["email"] = t("Field is required");
    }
    if (stateRegister?.values?.password === "") {
      errors["password"] = t("Field is required");
    }
    if (stateRegister?.values?.name === "") {
      errors["name"] = t("Field is required");
    }
    if (stateRegister.values.phoneNumber === "") {
      errors["phoneNumber"] = t("Field is required");
    }

    if (
      (stateRegister?.values?.phoneNumber?.charAt(4) == 0 &&
        stateRegister?.values?.phoneNumber?.length !== 14) ||
      (stateRegister?.values?.phoneNumber?.charAt(4) != 0 &&
        stateRegister?.values?.phoneNumber?.length !== 13)
    ) {
      errors["phoneNumber"] = t("Please enter a valid Mobile Number");
    }

    if (
      Object.keys(errors).filter((itemFilter) => errors[itemFilter]).length > 0
    ) {
      try {
        formIsValid = false;
        window?.scrollTo(0, 200);
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            ...errors,
          },
        }));
      } catch (error) {}
    }

    return formIsValid;
  };

  // register store input validation
  const registerStoreValidation = () => {
    let errors = {};

    let formIsValid = true;
    if (stateRegister.values.storeEmail !== "") {
      if (!UTILS.emailValidation().test(stateRegister.values["storeEmail"])) {
        formIsValid = false;
        errors["storeEmail"] = t("Please enter valid email-ID.");
      }
    } else if (stateRegister.values.storeEmail == "") {
      formIsValid = false;
      errors["storeEmail"] = t("Field is required");
    }
    if (stateRegister.values.phoneNumber == "") {
      errors["phoneNumber"] = t("Field is required");
    }

    if (stateRegister.values.phoneNumber.length != 12) {
      errors["phoneNumber"] = t("Please enter a valid Mobile Number");
    }

    if (
      Object.keys(errors).filter((itemFilter) => errors[itemFilter]).length > 0
    ) {
      try {
        formIsValid = false;
        window?.scrollTo(0, 200);
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            ...errors,
          },
        }));
      } catch (error) {}
    }
    return formIsValid;
  };

  const switchStep = (step) => {
    const backStep = step - 1;
    setStep(backStep);
    renderRegisterStep(step);
    sessionStorage.setItem("registrationStep", step);
    sessionStorage.setItem(
      "stateRegister",
      JSON.stringify(stateRegister.values)
    );
  };

  //  submit steps for registration
  const submitRegisterStep = async (step, data) => {
    if (step === 1) {
      handleValues("serviceType", data);
      const nextStep = step + 1;
      setStep(nextStep);
      renderRegisterStep(nextStep);
      sessionStorage.setItem("registrationStep", step);
      sessionStorage.setItem(
        "stateRegister",
        JSON.stringify(stateRegister.values)
      );
    }

    if (step === 2) {
      if (!registerValidation()) return;
      registerStepOne();
      return;
    }

    if (step === 4) {
      if (!registerStoreValidation()) return;
      const nextStep = step + 1;
      setStep(nextStep);
      renderRegisterStep(nextStep);
      sessionStorage.setItem("registrationStep", step);
      sessionStorage.setItem(
        "stateRegister",
        JSON.stringify(stateRegister.values)
      );
    }

    if (step === 6) {
      if (!stateRegister.values.latitudeLongitude) return;
      const nextStep = step + 1;
      setStep(nextStep);
      renderRegisterStep(nextStep);
      sessionStorage.setItem("registrationStep", step);
      sessionStorage.setItem(
        "stateRegister",
        JSON.stringify(stateRegister.values)
      );
    }

    if (step === 7) {
      const nextStep = step + 1;
      setStep(nextStep);
      renderRegisterStep(nextStep);
      sessionStorage.setItem("registrationStep", step);
      sessionStorage.setItem(
        "stateRegister",
        JSON.stringify(stateRegister.values)
      );
    }

    if (step === 8) {
      createBrandStore();
      return;
    }

    if (step === 9) {
      goToDashboard();
    }
  };

  const createBrandStore = async () => {
    const result = await API.CREATE_BRAND_STORE({
      ...stateRegister,
      userId: state.user.userId,
      brandId: state.user.brandId,
      language: stateRegister.values.baseLanguage == "en" ? "EN" : "AR",
    });
    if (result?.response === 200 && result.success) {
      UTILS.successNotification(result.message);
      const nextStep = step + 1;
      setStep(nextStep);
      renderRegisterStep(nextStep);
      sessionStorage.setItem("registrationStep", step);
      sessionStorage.setItem(
        "stateRegister",
        JSON.stringify(stateRegister.values)
      );
    } else {
      UTILS.errorNotification(result.message);
    }
  };

  const goToDashboard = async () => {
    history.push("/verify/validate-otp", {
      stateRegister,
    });
  };

  const checkUTMTags = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmTagsFromQuery = {};

    if (urlParams.has("utm_source")) {
      utmTagsFromQuery.utm_source = urlParams.get("utm_source");
    }
    if (urlParams.has("utm_medium")) {
      utmTagsFromQuery.utm_medium = urlParams.get("utm_medium");
    }
    if (urlParams.has("utm_campaign")) {
      utmTagsFromQuery.utm_campaign = urlParams.get("utm_campaign");
    }

    if (Object.keys(utmTagsFromQuery)?.length === 0) {
      const cookies = document.cookie?.split(";");
      cookies?.forEach((cookie) => {
        const [name, value] = cookie?.trim()?.split("=");
        if (
          name === "utm_source" ||
          name === "utm_medium" ||
          name === "utm_campaign"
        ) {
          utmTagsFromQuery[name] = value || "";
        }
      });
    }

    return utmTagsFromQuery;
  };

  const registerStepOne = async () => {
    setLoading(true);
    if (stateRegister.values.phoneNumber.charAt(4) === "0") {
      stateRegister.values.phoneNumber =
        stateRegister.values.phoneNumber.slice(0, 4) +
        stateRegister.values.phoneNumber.slice(5);
    }

    const utmTags = checkUTMTags();
    const utmSource = utmTags.utm_source;
    const utmMedium = utmTags.utm_medium;
    const utmCampaign = utmTags.utmCampaign;

    const result = await API.REGISTER({
      brandName: stateRegister.values.brandName,
      name: stateRegister.values.name,
      email: stateRegister.values.email.trim(),
      phoneNumber: stateRegister.values.phoneNumber,
      password: stateRegister.values.password,
      superCategoryId: stateRegister.values.serviceType,
      baseLanguage: stateRegister.values.baseLanguage,
      language: stateRegister.values.baseLanguage === "en" ? "EN" : "AR",
      utmSource,
      utmMedium,
      utmCampaign,
    });
    if (result?.response === 200 && result?.success) {
      UTILS.saveCurrentUser({
        name: result.data.user.userName,
        email: result.data.user.email,
        phoneNumber: result.data.user.phoneNumber,
        token: null,
        storeFrontURL: result.data.storeFrontURL,
        storePreviewURL: result.data.storePreviewURL,
        brandId: result.data.user.brandId._id,
        userId: result.data.user.userId,
        userType: result?.data?.user?.userType,
        brandName: result.data.user.brandId.brandName,
        subscribedPackage: result.data.subscribedPackage?.packageId
          ? result.data.subscribedPackage?.packageId
          : "",
        catalogueType: result?.data?.user?.brandId?.catalogueType,
        temporaryDomainName: result?.data?.brandSettings?.temporaryDomainName,
      });
      TRACKING_EVENTS.nonVerifiedRegistrations({
        userId: result.data.user.userId,
        brandName: stateRegister.values.brandName,
        phoneNumber: stateRegister.values.phoneNumber,
      });
      dispatch(
        SettingsAction.updateCatalogueType(
          result?.data?.user?.brandId?.catalogueType
        )
      );
      const obj = {
        direction: UTILS.getLayoutDirection(stateRegister.values.baseLanguage),
        lang: stateRegister.values.baseLanguage,
      };
      UTILS.saveLanguageInformation(obj);
      goToDashboard();
      sessionStorage.setItem("registrationStep", step);
      sessionStorage.setItem(
        "stateRegister",
        JSON.stringify(stateRegister.values)
      );
      UTILS.checkPackageValidity();
    } else {
      UTILS.handleErrorNotification(result?.message);
      setInvalidLogin(result.message);
    }
    setLoading(false);
  };

  const renderRegisterStep = (step) => {
    switch (step) {
      case 1:
        return (
          <RegisterStep1
            step="1"
            onSubmitRegisterStep={submitRegisterStep}
            handleValues={handleValues}
            stateRegister={stateRegister}
          />
        );

      case 2:
        return (
          <RegisterStep2
            step="2"
            switchStep={switchStep}
            onSubmitRegisterStep={submitRegisterStep}
            handleValues={handleValues}
            stateRegister={stateRegister}
          />
        );

      default:
        return (
          <RegisterStep1
            step="1"
            onSubmitRegisterStep={submitRegisterStep}
            handleValues={handleValues}
            stateRegister={stateRegister}
          />
        );
    }
  };

  return (
    <div className="register-page">
      <Spin spinning={loading}>{renderRegisterStep(step)}</Spin>
    </div>
  );
};
export default Register;
