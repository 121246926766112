import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import {
  useGetLoyaltyDetailsByBrandNameMutation,
  useUpdateLoyaltyMutation,
  useCreateLoyaltyMutation,
} from "redux/loyalty/loyalty-service";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import LoyaltyForm from "../components/LoyaltyForm";
const AddUpdateLoyaltyProgram = () => {
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const [getLoyaltyDetailsByBrandName] =
    useGetLoyaltyDetailsByBrandNameMutation();
  const [updateLoyalty] = useUpdateLoyaltyMutation();
  const [createLoyality] = useCreateLoyaltyMutation();
  const [actionType, setActionType] = useState(C.LOYALTY_CONSTANTS.ADD_OP);
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm(
    {
      mode: "onChange",
      defaultValues: async () => {
        const loyaltyDetails = await fetchLoyaltyDetails();
        return loyaltyDetails;
      },
    },
    []
  );

  const fetchLoyaltyDetails = async () => {
    reset();
    const userDetail = UTILS.getCurrentUser();
    if (userDetail?.brandId) {
      const { data } = await getLoyaltyDetailsByBrandName({
        brandId: userDetail.brandId,
      });
      if (data?.success === 1 && data?.data && data?.data?._id) {
        setActionType(C.LOYALTY_CONSTANTS.EDIT_OP);
      } else {
        setActionType(C.LOYALTY_CONSTANTS.ADD_OP);
      }
      reset(data?.data);
      return data?.data;
    }
  };

  /* 
    This function is used to add or update loyalty program
  */
  const addUpdateLoyaltyInfo = async (data) => {
    const commonData = {
      minOrderValueForCashback: 0,
      redeemablePercentagePerOrder: Number(data.redeemablePercentagePerOrder),
      maxRedeemableValuePerOrder: Number(data.maxRedeemableValuePerOrder),
      welcomeBonus: {
        ...data.welcomeBonus,
        value: Number(data.welcomeBonus.value || 0),
      },
    };

    let obj = {
      ...data,
      specificToDelivery: {
        isActive: true,
        cashbackPercentage: Number(data.specificToDelivery.cashbackPercentage),
      },
      specificToPickup: {
        isActive: true,
        cashbackPercentage: Number(data.specificToDelivery.cashbackPercentage),
      },
      specificToDineIn: {
        isActive: true,
        cashbackPercentage: Number(data.specificToDelivery.cashbackPercentage),
      },
    };
    if (actionType === C.LOYALTY_CONSTANTS.ADD_OP) {
      obj = {
        ...obj,
        ...commonData,
      };
      await createLoyality(obj);
    } else {
      obj = {
        ...obj,
        loyaltyId: data._id,
        specificToDelivery: {
          ...data.specificToDelivery,
          cashbackPercentage: Number(
            data.specificToDelivery.cashbackPercentage
          ),
        },
        ...commonData,
      };

      await updateLoyalty(obj);
    }

    await fetchLoyaltyDetails();
  };

  return (
    <Spin spinning={isLoading}>
      <div className="p-4">
        <div className="flex w-full flex-col">
          <LoyaltyForm
            {...{
              register,
              errors,
              watch,
              actionType,
              isValid,
              trigger,
              setValue,
            }}
            onsubmit={handleSubmit(addUpdateLoyaltyInfo)}
          />
        </div>
      </div>
    </Spin>
  );
};

export default AddUpdateLoyaltyProgram;
