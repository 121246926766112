import React from "react";
import { useSelector } from "react-redux";
import { Spin, Button } from "antd";
import PriceInput from "components/useForm/PriceInput";
import ChooseTime from "./ChooseTime";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";

function ZoneForm({
  onClose,
  selectedAreaState,
  handleValues,
  handleInputValue,
  handleSubmit,
  heading,
  resetState,
}) {
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { t } = useTranslation();

  return (
    <div className="w-full p-2 sm:p-5 mx-auto my-auto  bg-white">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="p-1 justify-center">
            <div className="flex justify-between">
              <div>
                <h2 className="text-19px font-bold mb-3 py-1 px-2">
                  {heading}
                </h2>
              </div>
              <div className="cursor-pointer" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-6 h-6 bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="px-2  w-full sm:w-1/2 register-page">
                <TextInput
                  label={t("Zone Name")}
                  value={selectedAreaState?.name}
                  keyVal={"name"}
                  handleValues={handleValues}
                  mandatory={true}
                />
              </div>
              <div className="px-2 w-full sm:w-1/2 register-page">
                <TextInput
                  label={t("Distance")}
                  value={selectedAreaState?.distance}
                  keyVal={"distance"}
                  handleValues={handleInputValue}
                  mandatory={true}
                />
              </div>
            </div>

            <div className="flex flex-wrap my-2">
              <div className="px-2  w-full sm:w-1/2 register-page">
                <TextInput
                  label={t("Min. Basket Value")}
                  value={selectedAreaState.minOrder}
                  keyVal={"minOrder"}
                  handleValues={handleInputValue}
                  mandatory={true}
                />
              </div>
              <div className="px-2 w-full sm:w-1/2 register-page">
                <PriceInput
                  label={t("Delivery Fee")}
                  value={selectedAreaState.deliveryFee}
                  keyVal={"deliveryFee"}
                  handleValues={handleValues}
                  mandatory={true}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <ChooseTime
                {...{
                  time: selectedAreaState?.deliveryTime,
                  setTime: (val) => handleValues("deliveryTime", val),
                }}
              />
            </div>
            <div className="flex justify-end gap-1 items-center px-3 mt-4">
              <Button
                type="primary"
                onClick={resetState}
                className=" border "
                size="large"
              >
                {t("Reset Time")}
              </Button>
              <Button type="primary" size="large" onClick={handleSubmit}>
                {t("Save")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default ZoneForm;
