import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import Modal from "components/Modal";
import Tabs from "./components/Tabs";
import { isValidValue } from "utils/Utils";
import { ErrorAction, LogisticAction } from "redux/actions";
const Yandex = ({ order, fetchOrderDetailsByID }) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.ErrorReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { yandexModal, syncData } = useSelector(
    (state) => state.LogisticReducer
  );

  const [showButtons, setShowButtons] = useState({
    createClaim: false, //-->yes means you can create a claim
    confirmClaim: false,
    cancelClaim: false,
    sync: false,
  });

  /* It fetches the order details. */
  const validateYandex = async () => {
    dispatch(ErrorAction.setError(null));
    const data = await fetchOrderDetailsByID();
    const yandexMetadata =
      data?.data?.fetchedOrder?.logisticPartnerMetadata?.YANDEX;
    if (yandexMetadata) {
      let showButtons = {};
      if (isValidValue(yandexMetadata?.cancelClaimInfo)) {
        showButtons.createClaim = true;
      }
      // confirmed claim
      if (
        isValidValue(yandexMetadata?.createClaimResponse?.id) &&
        !isValidValue(yandexMetadata?.cancelClaimInfo) &&
        !isValidValue(yandexMetadata.confirmClaimResponse)
      ) {
        showButtons.confirmClaim = true;
      }

      // cancel claim
      if (
        !isValidValue(yandexMetadata?.cancelClaimInfo) &&
        isValidValue(yandexMetadata.createClaimResponse?.id)
      ) {
        showButtons.cancelClaim = true;
      }
      setShowButtons(showButtons);
    } else {
      showButtons.createClaim = true;
      showButtons.confirmClaim = false;
      showButtons.cancelClaim = false;
      setShowButtons(showButtons);
    }
  };

  useEffect(() => {
    validateYandex();
  }, [syncData]);

  return (
    <Spin spinning={isLoading}>
      <Modal
        open={yandexModal}
        modalWidth=" min-h-300 rounded-md border border-gray-300 top-0 w-full lg:w-2/3"
        onClose={() => {}}
      >
        {error && (
          <div className="text-center text-red-500 mt-4 font-semibold">
            {error}
          </div>
        )}
        <Tabs
          fetchOrderDetails={validateYandex}
          showButtons={showButtons}
          setShowButtons={setShowButtons}
          {...{ order }}
          setOpen={() => {
            dispatch(LogisticAction.toggleYandexModal(false));
            dispatch(LogisticAction.resetOnModalClose());
          }}
        />
      </Modal>
    </Spin>
  );
};

export default Yandex;
