import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AreasAction } from "redux/actions";
import {
  calculateDeliveryTimeInMins,
  convertPolygonArrayToObject,
  isValidDrawAreInputFields,
} from "../../../helpers";
import { UTILS } from "utils";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { getStoreInfo } from "utils/Utils";
import DrawAreaContainer from "./DrawAreaContainer";
import { LoaderAction } from "redux/actions";

function DrawAreaOnMap() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [plottedPolygons, setPlottedPolygons] = useState([]);
  const [polygons, setPolygons] = useState({
    coordinates: [],
    areaInfo: {},
  });
  const [areaState, setAreaState] = useState({
    minOrder: "",
    deliveryFee: "",
    description: "",
    areaName: "",
    deliveryTime: {
      Hours: 0,
      Minutes: 0,
      Days: 0,
    },
  });
  const [selectedCity, setSelectedCity] = useState(null);
  const [drawingEnabled, setDrawingEnabled] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { selectedStore } = useSelector((state) => state.AreasReducer);

  async function getStoreMappedAddedAreas(ID) {
    try {
      if (!ID) return;
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      const response = await API.getStoreMappedAreas({
        brandId: userDetail.brandId,
        storeId: ID,
        isPolygonRequired: true,
      });
      if (response?.success === 1) {
        if (response?.data?.length > 0) {
          setPlottedPolygons(convertPolygonArrayToObject(response?.data));
        } else {
          setPlottedPolygons([]);
        }
      } else UTILS.errorNotification(errorHandler(response));
    } catch (error) {
      UTILS.errorNotification(t(errorHandler(error)));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  }

  const handleInputValue = (key, value) =>
    setAreaState({ ...areaState, [key]: value });

  const handledeliveryFee = async (key, value) =>
    setAreaState({ ...areaState, [key]: value });
  const addAreaParams = () => {
    const deliveryTimeInMins = calculateDeliveryTimeInMins({
      deliveryTime: areaState.deliveryTime,
    });
    const userDetail = UTILS.getCurrentUser();
    return {
      brandId: userDetail.brandId,
      storeId: selectedStore?.value,
      translations: {
        en: {
          name: areaState.areaName,
        },
        ar: {
          name: areaState.areaName,
        },
      },
      city: {
        en: selectedCity?.name.en,
        ar: selectedCity?.name.ar,
      },
      country: {
        en: selectedCountry?.name.en,
        ar: selectedCountry?.name.ar,
      },
      description: areaState.description,
      polygon: polygons.coordinates?.length > 0 ? polygons.coordinates : null,
      minOrder: Number(areaState.minOrder),
      deliveryFee: Number(areaState.deliveryFee),
      deliveryTime: Number(deliveryTimeInMins),
    };
  };

  const addAreafromMap = async () => {
    try {
      if (
        !isValidDrawAreInputFields({
          areaState,
          selectedCity,
          selectedCountry,
          selectedStore,
        }).isValid
      ) {
        return;
      }
      dispatch(LoaderAction.setLoading(true));
      const result = await API.addCustomStoreMapArea(addAreaParams());
      if (result?.success === 1) {
        history.push(`/app/dashboard/mapped-area-method`);
        UTILS.successNotification(result.message);
      } else {
        UTILS.errorNotification(errorHandler(result));
      }
    } catch (error) {
      UTILS.errorNotification(t(errorHandler(error?.message)));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const handleCountryChange = (value, option) => {
    setSelectedCountry(option.data);
    setSelectedCity(null);
  };

  const handleCityChange = (value, option) => setSelectedCity(option.data);

  const onStoreChange = (value) => {
    localStorage.setItem("storeId", value.value);
    getStoreMappedAddedAreas(value.value);
    dispatch(AreasAction.setStore(value));
  };
  useEffect(() => {
    if (selectedStore?.value) getStoreMappedAddedAreas(selectedStore.value);
  }, []);

  return (
    <DrawAreaContainer
      {...{
        mapRef,
        getStoreInfo,
        selectedStore,
        onStoreChange,
        areaState,
        handleInputValue,
        handledeliveryFee,
        selectedCountry,
        selectedCity,
        handleCountryChange,
        handleCityChange,
        addAreafromMap,
        polygons,
        setPolygons,
        drawingEnabled,
        setDrawingEnabled,
        plottedPolygons,
        operationType: "add",
      }}
    />
  );
}

export default DrawAreaOnMap;
