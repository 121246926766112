import React, { Fragment } from "react";
import { Button } from "antd";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSendORderToCareemMutation } from "redux/Logistic/CareemService";
import { LoaderAction } from "redux/actions";
import { ifnullOrUndefined } from "utils/Utils";
import { errorHandler } from "api/api/errorHandler";
const CreateClaim = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);
  const [sendOrderToCareem] = useSendORderToCareemMutation();

  const sendOrderToCareemFunc = async () => {
    try {
      if (!orderInfo?._id) return;
      dispatch(LoaderAction.setLoading(true));
      const result = await sendOrderToCareem({
        orderId: orderInfo._id,
      });
      if (result?.data?.success === 1) {
        UTILS.successNotification(t(result?.data?.message));
      } else {
        UTILS.errorNotification(t(result?.error?.data));
      }
      await props.fetchOrderDetailsByID();
    } catch (error) {
      UTILS.handleErrorNotification(errorHandler(error?.error));
    }
    new Promise((resolve) => setTimeout(resolve, 3000));
    dispatch(LoaderAction.setLoading(false));
  };

  const allowSendOrderToCareem = () => {
    if (
      ifnullOrUndefined(
        orderInfo?.logisticPartnerMetadata?.CAREEM_NOW?.createDeliveryResponse
          ?.id
      ) ||
      orderInfo?.logisticPartnerMetadata?.CAREEM_NOW?.deliveryStatus ===
        "CANCELED"
    ) {
      return true;
    }
    return false;
  };

  return (
    <Fragment key={props?.key}>
      <div className="flex flex-col justify-center items-center">
        <>
          <div className="ml-4 py-4 text-center">
            {allowSendOrderToCareem() ? (
              <Button
                type="default"
                size="large"
                onClick={sendOrderToCareemFunc}
                className="w-56  my-2 rounded-md font-semibold"
              >
                {t("Send order to Careem")}
              </Button>
            ) : (
              <div>
                <span className="text-sm text-gray-500">
                  {t(
                    "Your order has been sent to Careem for delivery. Please check the ORDER DETAIL tab for Rider details"
                  )}
                </span>
              </div>
            )}
          </div>
        </>
      </div>
    </Fragment>
  );
};

export default CreateClaim;
