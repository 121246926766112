import { combineReducers } from "@reduxjs/toolkit";
import LanguageSlice from "./language/language.slice";
import SettingsSlice from "./settings/settingsSlice";
import SettingsReducer from "./settings/settingReducer";
import AreasSlice from "./shipping-methods/shipping-service";
import AreasReducer from "./shipping-methods/shipping-reducer";
import LoaderReducer from "./loader/loader";
import LoginReducer from "./user/userReducer";
import storage from "redux-persist/lib/storage";
import Logout from "./auth/logout/logout";
import APPUpdate from "./app/appUpdate";
import SettingErrorReducer from "./settings/ErrorReducer";
import NotPersistReducer from "./Non-persist/Non-persist";
import PackagesReducer from "./packages/packages";
import ErrorReducer from "./error/reducer";
import LogisticReducer from "./Logistic/Logistic";
import MenuReducer from "./menu/menu-reducer";

const appReducer = combineReducers({
  [SettingsSlice.reducerPath]: SettingsSlice.reducer,
  [AreasSlice.reducerPath]: AreasSlice.reducer,

  LanguageSlice,
  SettingsReducer,
  AreasReducer,
  LoaderReducer,
  LoginReducer,
  Logout,
  APPUpdate,
  SettingErrorReducer,
  NotPersistReducer,
  PackagesReducer,
  LogisticReducer,
  ErrorReducer,
  MenuReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "Logout/logout") {
    storage.removeItem("persist:root");
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
