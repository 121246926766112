import React from "react";
import BgTextInput from "components/BgTextInput";
import Footer from "components/Footer";
import PropTypes from "prop-types";
import PhoneNumberInput from "components/PhoneNumberInput";
import { useTranslation } from "react-i18next";
import { Button, Popover } from "antd";
import BgPasswordInput from "components/BgPasswordInput";
import useToggleLanguage from "hooks/useToggleLanguage";
import LanguageToggle from "components/LanguageToggle";

const RegisterStep1 = (props) => {
  const { lang, switchLanguage } = useToggleLanguage();

  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmitRegisterStep(2);
  };

  return (
    <div className="mt-10p">
      <LanguageToggle
        onClick={switchLanguage}
        selected={lang}
        authenticated={false}
      />

      <div className="flex justify-center items-center mb-5">
        <div className="w-full">
          <div className="text-center">
            <h2 className="font-bold text-24px my-7">
              {t("Please fill the form?")}
            </h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="py-5 md:flex md:w-2/3 flex-col w-full justify-center items-center mx-auto">
              <div className="flex flex-wrap justify-center">
                <div className="md:w-1/2 w-full px-4 py-1">
                  <BgTextInput
                    label={t("FULL NAME")}
                    styleClasses="py-2 border border-gray-300 px-2 font-montserrat text-silver-dark mt-1 text-16px w-full block  focus:outline-none"
                    placeHolder={t("Your Name")}
                    type="text"
                    handleValues={props.handleValues}
                    value={props.stateRegister?.values?.name}
                    error={props.stateRegister?.errors?.name}
                    keyVal="name"
                    mandatory="true"
                  />
                </div>
                <div className="w-full px-4 py-1 md:w-1/2" dir="ltr">
                  <BgTextInput
                    label={t("Email")}
                    placeHolder="user@resthero.com"
                    type="text"
                    handleValues={props.handleValues}
                    value={props.stateRegister?.values?.email.trim()}
                    error={props.stateRegister?.errors?.email}
                    keyVal="email"
                    mandatory="true"
                    styleClasses="py-2 border border-gray-300 px-2 font-montserrat text-silver-dark mt-1 text-16px w-full block  focus:outline-none"
                  />
                </div>
                <div className="w-full px-4 py-1 md:w-1/2">
                  <div className="py-2 px-4 bg-input-bg rounded">
                    <label className="font-medium block">
                      {t("Mobile Number")}{" "}
                      <span className="text-red-600"> *</span>{" "}
                      <span className="text-red-600">
                        {" "}
                        {props.stateRegister?.errors?.phoneNumber}{" "}
                      </span>
                    </label>
                    <Popover
                      content={t("We will send a verification code")}
                      trigger="focus"
                    >
                      <div
                        className="relative text-gray-400 focus-within:text-gray-600 py-0.5"
                        dir="ltr"
                      >
                        <PhoneNumberInput
                          label=""
                          value={props.stateRegister?.values?.phoneNumber}
                          keyVal={"phoneNumber"}
                          handleValues={props.handleValues}
                        />
                      </div>
                    </Popover>
                  </div>
                </div>
                <div className="w-full px-4 py-1 md:w-1/2">
                  <BgPasswordInput
                    label={t("Password")}
                    placeHolder="*******"
                    handleValues={props.handleValues}
                    value={props.stateRegister?.values?.password}
                    error={props.stateRegister?.errors?.password}
                    keyVal="password"
                    mandatory="true"
                    styleClasses="py-2 border border-gray-300 px-2 font-montserrat text-silver-dark mt-1 text-16px w-full block  focus:outline-none"
                    type="text"
                  />
                </div>
                <div className="">
                  <div className="">
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      className="w-36"
                    >
                      {t("Next")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

RegisterStep1.propTypes = {
  onSubmitRegisterStep: PropTypes.func,
  handleValues: PropTypes.func,
  stateRegister: PropTypes.object,
};

export default RegisterStep1;
