import React from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ModalTabs from "logistic-integrations/components/Tabs";

import CreateClaim from "./CreateClaim";
import ClaimInfo from "./ClaimInfo";
import DZSupport from "./DZSupport";
import useGetORderInfo from "../useGetORderInfo";

const { TabPane } = Tabs;

const DeliveryService = ({ setOpen, order, fetchOrderDetailsByID }) => {
  const { t } = useTranslation();
  const { DZOrder, getDZOrderStatus } = useGetORderInfo();
  console.log({ abc: DZOrder });

  const handleTabChange = async (key) => {
    if (key === "3") {
      fetchLatestClaimInfo();
    }
    await fetchOrderDetailsByID();
  };

  const fetchLatestClaimInfo = async () => {
    await getDZOrderStatus(order);
  };

  return (
    <ModalTabs setOpen={setOpen} handleTabChange={handleTabChange}>
      <TabPane tab={t("SEND ORDER")} key="1">
        <CreateClaim fetchOrderDetailsByID={fetchOrderDetailsByID} />
      </TabPane>
      <TabPane tab={t("ORDER DETAIL")} key="3">
        <ClaimInfo
          fetchLatestClaimInfo={fetchLatestClaimInfo}
          DZOrder={DZOrder}
        />
      </TabPane>
      <TabPane tab={t("DZ SUPPORT CENTER")} key="5">
        <DZSupport DZOrder={DZOrder} />
      </TabPane>
    </ModalTabs>
  );
};

export default DeliveryService;
