import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
const postConfig = {
  query: (body) => ({
    method: "POST",
    body: body,
  }),
  transformResponse: (response) => {
    return response;
  },
};

const CareemService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendORderToCareem: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CAREEM_NOW_CREATE_DELIVERY,
        ...postConfig.query(body),
      }),
    }),
    trackCareemOrder: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CAREEM_NOW_GET_DELIVERY_STATUS,
        ...postConfig.query(body),
      }),
    }),
    cancelCareemOrder: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.CAREEM_NOW_CANCEL_DELIVERY,
        ...postConfig.query(body),
      }),
    }),
  }),
});
export const {
  useSendORderToCareemMutation,
  useTrackCareemOrderMutation,
  useCancelCareemOrderMutation,
} = CareemService;
export default CareemService;
