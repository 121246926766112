import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import Card from "components/Card";

const CustomerTable = ({ customers, handlePageChange = () => {}, setPage }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("Customer Name"),
      dataIndex: "name",
      align: "initial",
      key: "customerName",
      render: (val, row, index) => {
        return (
          <div>
            <span>{val || t("N/A")}</span>
          </div>
        );
      },
    },
    {
      title: t("Phone Number"),
      dataIndex: "customerPhone",
      align: "initial",
      key: "customerPhone",
      render: (val, row, index) => {
        return (
          <div>
            <span dir="ltr">{val}</span>
          </div>
        );
      },
    },
    {
      title: t("CASH Spending"),
      dataIndex: "codEarning",
      align: "initial",
      key: "codEarning",
    },
    {
      title: t("ONLINE Spending"),
      dataIndex: "onlineEarning",
      align: "initial",
      key: "onlineEarning",
    },
  ];

  return (
    <div className="">
      <Card styleClasses="px-2 py-2 overflow-hidden overflow-x-auto md:overflow-hidden">
        <Table
          locale={{ emptyText: t("No Data") }}
          columns={columns}
          dataSource={customers?.data}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 20,
            total: customers.totalPages,
            showQuickJumper: false,
            showSizeChanger: false,
          }}
          rowKey={(record) => record?.customerId}
          size="small"
          onChange={(pagination, filters, sorter) => {
            setPage(pagination.current);
            handlePageChange(pagination.current);
          }}
        />
      </Card>
    </div>
  );
};

export default CustomerTable;
