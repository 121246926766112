import React from "react";
import { Checkbox, Spin } from "antd";
import { useSelector } from "react-redux";

function LinkUnLinkMenuWithStoresModal({
  stores,
  selectedStores,
  setselectedStores,
  loading,
}) {
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const handleCheckboxChange = (store) => {
    const storeExists = isSelected(store);
    const storeId = store?.value;

    if (storeExists && storeId) {
      const filtered = selectedStores.filter(
        (item) => item?.storeId?._id !== storeId
      );
      setselectedStores(filtered);
    } else {
      const obj = {
        label: store?.label,
        storeId: {
          _id: storeId,
        },
      };
      setselectedStores([...selectedStores, obj]);
    }
  };

  const isSelected = (store) => {
    const storeId = store?.value;
    if (!storeId) return false;
    return (
      Array.isArray(selectedStores) &&
      selectedStores.some((item) => item?.storeId?._id === storeId)
    );
  };

  const getStore = (id) => {
    if (!id) return;
    if (
      !selectedStores ||
      !Array.isArray(selectedStores) ||
      selectedStores?.length <= 0
    )
      return;
    return selectedStores?.find((item) => item?.storeId?._id === id);
  };

  const toggleOption = (store, type) => {
    const storeExists = isSelected(store);
    const storeId = store?.value;

    if (!storeExists) {
      const obj = {
        label: store?.label,
        storeId: {
          _id: storeId,
        },
        serviceType: [type.toUpperCase()],
      };
      setselectedStores([...selectedStores, obj]);
      return;
    }

    const storeObj = getStore(storeId);

    if (storeObj?.serviceType?.includes(type)) {
      if (storeObj?.serviceType?.length === 1) {
        const filteredStores = selectedStores?.filter(
          (item) => item?.storeId?._id !== storeId
        );
        setselectedStores(filteredStores);
      } else {
        const filtered = storeObj?.serviceType?.filter(
          (item) => item !== type.toUpperCase()
        );
        const updatedStore = { ...storeObj, serviceType: filtered };
        setselectedStores([
          ...selectedStores.filter((item) => item?.storeId?._id !== storeId),
          updatedStore,
        ]);
      }
    } else {
      const updatedServiceType = storeObj.serviceType
        ? [...storeObj.serviceType, type.toUpperCase()]
        : [type.toUpperCase()];
      const updatedStore = { ...storeObj, serviceType: updatedServiceType };
      setselectedStores([
        ...selectedStores.filter((item) => item?.storeId?._id !== storeId),
        updatedStore,
      ]);
    }
  };

  return (
    <Spin spinning={loading || isLoading}>
      <div className="modal">
        {stores?.map((store, index) => {
          const storeObj = getStore(store?.value);
          return (
            <div key={index} className="flex items-center mb-2">
              <Checkbox
                indeterminate={isSelected(store)}
                key={index}
                name="checkbox"
                prefixCls="ant-checkbox"
                autoFocus={index === 0}
                className="mr-2"
                id="checkbox"
                checked={isSelected(store)}
                onChange={() => handleCheckboxChange(store)}
              >
                {store.label}

                <div>
                  <div>
                    <Checkbox
                      checked={storeObj?.serviceType?.includes("PICKUP")}
                      onChange={() => toggleOption(store, "PICKUP")}
                    >
                      Pickup
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={storeObj?.serviceType?.includes("DELIVERY")}
                      onChange={() => toggleOption(store, "DELIVERY")}
                    >
                      Delivery
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={storeObj?.serviceType?.includes("DINE IN")}
                      onChange={() => toggleOption(store, "DINE IN")}
                    >
                      Dine In
                    </Checkbox>
                  </div>
                </div>
              </Checkbox>
            </div>
          );
        })}
      </div>
    </Spin>
  );
}

export default LinkUnLinkMenuWithStoresModal;
