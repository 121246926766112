import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
import { UTILS } from "utils";
import { errorHandler } from "api/api/errorHandler";
import { logErrorOnSentry } from "utils/Utils";
import { LoaderAction } from "redux/actions";
const postConfig = {
  query: (body) => ({
    method: "POST",
    body: body,
  }),
  transformResponse: (response) => {
    return response;
  },
};

const LyveGlobalService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendORderToLyveGlobal: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.LYVE_GLOBAL.CREATE_ORDER,
        ...postConfig.query(body),
      }),
    }),
    trackLyveGlobalOrder: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.LYVE_GLOBAL.TRACK_ORDER,
        ...postConfig.query(body),
      }),
    }),
    cancelLyveGlobalOrder: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.LYVE_GLOBAL.CANCEL_ORDER,
        ...postConfig.query(body),
      }),
    }),
  }),
});
export const {
  useSendORderToLyveGlobalMutation,
  useTrackLyveGlobalOrderMutation,
  useCancelLyveGlobalOrderMutation,
} = LyveGlobalService;
export default LyveGlobalService;
