import React from "react";
import PropTypes from "prop-types";

const PageHeading = (props) => {
  return (
    ""
    // <div className="p-3.5 bg-white border-b border-gray-300 shadow-sm">
    //   <h2 className=" text-blue-600 text-16px font-medium">
    //     {props.heading}
    //   </h2>
    // </div>
  );
};

PageHeading.propTypes = {
  heading: PropTypes.string,
};

export default PageHeading;
