import React from "react";

function PlusIcon() {
  return (
    <svg fill="currentColor" viewBox="0 0 20 20" className="h-5 w-5 text-white">
      <path
        d="M10 5.25a.75.75 0 01.75.75v8a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      ></path>
      <path
        d="M5.25 10A.75.75 0 016 9.25h8a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
      ></path>
      <path
        d="M3.813 3.813a8.75 8.75 0 1112.374 12.374A8.75 8.75 0 013.813 3.813zM10 2.75a7.25 7.25 0 100 14.5 7.25 7.25 0 000-14.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PlusIcon;
