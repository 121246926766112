//Talabat
let courierBase = "order/delivery-service/";
export const SEND_ORDER_TO_TALABAT = `${courierBase}send-order-to-talabat`;
export const CHECK_TALABAT_DELIVERY_TIME = `${courierBase}check-delivery-time-est-with-talabat`;
export const CHECK_TALABAT_DELIVERY_FEE = `${courierBase}check-delivery-fee-with-talabat`;
export const CANCEL_TALABAT_ORDER = `${courierBase}cancel-order-from-talabat`;

//marsool
export const SEND_ORDER_TO_MARSOOL = `${courierBase}send-order-to-marsool`;

// Yandex
export const GET_TIME_SLOTS =
  "order/yandex/get-next-day-delivery-interval-slots";
export const CREATE_CLAIM = "order/yandex/create-claim";
export const CONFIRM_CLAIM = "order/yandex/confirm-claim";
export const CLAIM_INFO = "order/yandex/information-claim";
export const CANCEL_CLAIM = "order/yandex/cancel-claim";

//yandex express
export const GET_YANDEX_DELIVERY_METHODS = "order/yandex/get-delivery-methods";
export const GET_YANDEX_EXPRESS_RIDER_INFO =
  "order/yandex/get-rider-phone-info";

//Settings
export const UPDATE_PASSWORD = "admin/update-password";
export const UPDATE_PHONE_NUMBER = "admin/update-phone-number";
export const UPDATE_BRAND_NAME = "brands/updateBrand";
export const UPDATE_DOMAIN_NAME = "branding/update-temporary-domain-name";
export const UPDATE_BRAND_SETTINGS = "branding/updateSetting";
export const GET_BRAND_SETTINGS = "branding/getBrandSettingWithBrandId";

// city base Delivery
let cityBase = "city-base-delivery-method/";
export const ADD_CITY_BASE_DELIVERY = `${cityBase}add`;
export const EDIT_CITY_BASE_DELIVERY = `${cityBase}update`;
export const DELETE_CITY_BASE_DELIVERY = `${cityBase}delete`;
export const GET_CITY_BASE_DELIVERY = `${cityBase}get`;

// country  base Delivery
let countryBase = "country-base-delivery-method/";
export const ADD_COUNTRY_BASE_DELIVERY = `${countryBase}add`;
export const EDIT_COUNTRY_BASE_DELIVERY = `${countryBase}update`;
export const DELETE_COUNTRY_BASE_DELIVERY = `${countryBase}delete`;
export const GET_COUNTRY_BASE_DELIVERY = `${countryBase}get`;

//Buisness Areas
export const GET_BUSINESS_AREAS = "country/getAllCountriesWithCompleteDetail";

//shipping
export const GET_BRAND_INFO_WITH_ID = "brands/getBrandInfoWithBrandId";
export const UPDATE_SHIPPING_METHOD_STATUS =
  "delivery-method/update-delivery-method-activation-status";
// packages
export const GET_ALL_PACKAGES = "onboardingSubscriptions/getAllActivePackages";
export const GET_PAYMENT_PAGE = "onboardingSubscriptions/getHostedPaymentPage";
export const VERIFY_PKG_PAYMENT =
  "onboardingSubscriptions/verifyPackagePayment";

//loyalty
export const CREATE_LOYALTY = "loyalty/create";
export const UPDATE_LOYALTY = "loyalty/update";
export const GET_LOYALTY_DETAILS_BY_BRAND_NAME = "loyalty/get-by-brand";
export const GET_USER_WALLET_BALANCE = "wallet/get-user-wallet";
export const GET_USER_WALLET_TRANSACTION_HISTORY =
  "wallet-transaction/get-by-user";
export const CREATE_USER_WALLET_TRANSACTION = "wallet-transaction/create";

//customer section
export const GET_ALL_BRAND_CUSTOMERS = "user/get-brand-customers-v2";
// Order
export const GET_ORDER_INFO_BY_ID = "order/getOrderInfoById";

// careem now
export const CAREEM_NOW_CREATE_DELIVERY = "order/careem-now/create-delivery";
export const CAREEM_NOW_CANCEL_DELIVERY = "order/careem-now/cancel-delivery";
export const CAREEM_NOW_GET_DELIVERY_STATUS = "order/careem-now/track-delivery";

// bulk menu
export const UPLOAD_BULK_ITEMS = "importData/upload-excel-file";

//Modifier

//Section
export const GET_MODIFIER_GROUPS = "items/get-modifier-groups";
export const CREATE_MODIFIER_GROUP = "items/add-modifier-group";
export const UPDATE_MODIFIER_GROUP = "items/update-modifier-group";
export const DELETE_MODIFIER_GROUP = "items/delete-modifier-group";
export const LINK_MODIFIER_GROUP_WITH_ITEM =
  "items/add-modifier-groups-in-item";
export const UNLINK_MODIFIER_GROUP_WITH_ITEM =
  "items/remove-modifier-group-in-item";
export const GET_MODIFIER_GROUP_INFO = "items/get-modifier-group-info";
export const SORT_MODIFIER_GROUPS = "items/sort-modifier-group-in-item";

//options
export const GET_BRAND_MODIFIERS = "items/get-modifiers";
export const CREATE_NEW_MODIFIER = "items/add-modifier";
export const DELETE_MODIFIER = "items/delete-modifier";
export const UPDATE_MODIFIER = "items/update-modifier";
export const GET_MODIFIER_GROUP = "items/get-modifier-info";
export const UNLINK_MODIFIER_WITH_GROUP =
  "items/remove-modifier-in-modifier-group";
export const LINK_MODIFIER_WITH_GROUP = "items/add-modifiers-in-modifier-group";
export const SORT_MODIFIER_OPTION = "items/sort-modifier-in-modifier-group";

//modifier -inventory
export const GET_STORE_MODIFIERS = "items/get-store-modifiers";
export const MODIFER_STORE_BLOCK = "items/block-unblock-modifier-for-store";

//reporting
export const GET_ORDERS_FEEDABACKS = "feedbacks/get-all-v2";

//Mapped Areas
export const GET_COUNTRIES_CITIES_MAPPED_AREAS =
  "mapped-areas/get-countries-cities-mapped-areas";

export const ADD_STORE_MAPPED_AREAS = "mapped-area-base-delivery-method/add";
export const UPDATE_STORE_MAPPED_AREAS =
  "mapped-area-base-delivery-method/update";
export const DELETE_STORE_MAPPED_AREAS =
  "mapped-area-base-delivery-method/delete";
export const GET_STORE_MAPPED_AREAS = "mapped-area-base-delivery-method/get";

//custom mapped areas
export const ADD_CUSTOM_STORE_MAP_AREA =
  "mapped-area-base-delivery-method/add-custom";
export const GET_CUSTOM_STORE_MAP_AREA_BY_ID =
  "mapped-area-base-delivery-method/get-info";
export const UPDATE_CUSTOM_STORE_MAP_AREA =
  "mapped-area-base-delivery-method/update-custom";
export const GET_ALL_CUSTOM_BUSINESS_AREAS =
  "mapped-areas/get-countries-cities";

export const TOGGLE_PLOYGON_AREA_STATUS =
  "mapped-area-base-delivery-method/mapped-area/toggle-status";
//multi-menu
export const GET_BRAND_MENUS = "menus/get-menus";
export const CREATE_NEW_MENU = "menus/add-menu";
export const GET_MENU_INFO = "menus/get-menu-info";
export const GET_MENU_CAT_INFO = "menus/get-menu-category-info";
export const GET_BRAND_ITEMS = "items/get-items";

export const LINK_STORES_WITH_MENU = "menus/add-stores-in-menu";
export const LINK_CATEGORY_WITH_MENU = "menus/add-category-in-menu";
export const UNLINK_CATEGORY_IN_MENU = "menus/remove-category-in-menu";
export const SORT_CATEGORY_IN_MENU = "menus/sort-category-in-menu";
export const LINK_ITEMS_IN_CATEGORY = "menus/add-items-in-menu-category";
export const UNLINK_ITEMS_IN_CATEGORY = "menus/remove-item-in-menu-category";
export const SORT_ITEMS_IN_CATEGORY = "menus/sort-item-in-menu-category";
export const TOGGLE_STORE_IN_MENU =
  "menus/connect-un-connect-store-in-menu-category-item";
export const GET_STORE_ITEMS = "items/get-store-items";
export const BLOCK_UNBLOCK_ITEM_FOR_STORE =
  "items/block-unblock-item-for-store";

//polling
export const GET_ORDER_META_DATA = "order/get-order-meta-data-info";

//distance based delivery
export const ADD_DISTANCE_BASE_DELIVERY_ZONE =
  "distance-base-delivery-zone/add";
export const GET_DISTANCE_BASE_DELIVERY_ZONE_INFO =
  "distance-base-delivery-zone/get-info";
export const UPDATE_DISTANCE_BASE_DELIVERY_ZONE =
  "distance-base-delivery-zone/update";
export const DELETE_DISTANCE_BASE_DELIVERY_ZONE =
  "distance-base-delivery-zone/delete";
export const GET_DISTANCE_BASE_DELIVERY_ZONES =
  "distance-base-delivery-zone/get-all";
export const TOGGLE_DISTANCE_BASE_DELIVERY_ZONE_STATUS =
  "distance-base-delivery-zone/toggle-status";

// delivery zone url
export const CREATE_DZ_DELIVERY = "order/delivery-zone/create-delivery";
export const TRACK_DZ_DELIVERY = "order/delivery-zone/track-delivery";

//excel report
export const DOWNNLOAD_ORDERHISTORY_EXCEL_REPORT = "reports/order-history-xlsx";
export const CUSTOMER_REPORT_EXCEL = "reports/customer-report-xlsx";
export const PAYMENT_LINKS_EXCEL = "reports/payments-link-history-xlsx";
export const UPDATE_MENU = "menus/update-menu";
export const CLONE_MENU = "menus/clone-menu";
export const DELETE_MENU = "menus/delete-menu";
export const MARK_AS_DEFAULT_MENU = "menus/mark-menu-default";

//lyve-global variables

export const LYVE_GLOBAL = {
  CREATE_ORDER: "order/lyve-global/create-order",
  CANCEL_ORDER: "order/lyve-global/cancel-order",
  TRACK_ORDER: "order/lyve-global/track-order",
};
