import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { errorNotification } from "utils/Utils";
import { successNotification } from "utils/Utils";
import useGetAllZones from "./useGetAllZones";
import { UTILS } from "utils";

function useToggleStatus() {
  const dispatch = useDispatch();
  const { getAddedDeliveryZones } = useGetAllZones("UPDATE");
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const toggleZoneStatus = async ({ payload, onClose }) => {
    try {
      const userDetail = UTILS.getCurrentUser();

      dispatch(LoaderAction.setLoading(true));
      const response = await API.toggleDistanceBaseDeliveryZoneStatus({
        ...payload,
        storeId: selectedStore.value,
        brandId: userDetail.brandId,
      });

      if (response?.success === 1 && response?.data) {
        successNotification(response?.message);
        await getAddedDeliveryZones(selectedStore?.value);
        onClose();
      } else {
        errorNotification(errorHandler(response?.message));
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return toggleZoneStatus;
}

export default useToggleStatus;
