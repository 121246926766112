import { Input } from "antd";
import React from "react";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";

function CopyLink({ orderPageLink }) {
  const { t } = useTranslation();
  return (
    <div className="pb-2 my-4 px-2">
      <div className="flex justify-between">
        <label className="text-gray-600 font-bold text-14px">
          {t("Ordering page link")}
        </label>
      </div>
      <div className="flex justify-between items-center">
        <Input
          disabled
          compact
          placeholder={orderPageLink}
          value=""
          className="py-2 rounded"
        />
        <span
          className="cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(orderPageLink);
            UTILS.successNotification(t("Copied to Clipboard"));
          }}
        >
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="https://www.w3.org/2000/svg"
            className="w-5 h-5 text-gray-500 mx-2"
          >
            <path
              fillRule="evenodd"
              d="M16 5.5H6a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5zM6 4a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V6a2 2 0 00-2-2H6z"
              clipRule="evenodd"
            ></path>
            <path
              fillRule="evenodd"
              d="M1.25 4A2.75 2.75 0 014 1.25h6a.75.75 0 010 1.5H4c-.69 0-1.25.56-1.25 1.25v6a.75.75 0 01-1.5 0V4z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
      </div>
    </div>
  );
}

export default CopyLink;
