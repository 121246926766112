import React, { useState, useEffect } from "react";
import VerificationInput from "components/VerificationInput";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";
import { API } from "api/";
import { useDispatch } from "react-redux";
import { LoginAction } from "redux/actions";
import { Language } from "redux/actions";
import { ROUTES } from "config";
import { TRACKING_EVENTS } from "utils";
const VerifyOtp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(false);
  const [state, setState] = useState({
    loading: false,
    values: {},
  });

  const [verificationCode, setVerificationCode] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let CODE = UTILS.NumberConversion(value);
    setVerificationCode({ ...verificationCode, [name]: CODE });
  };

  const inputfocus = (elmnt) => {
    const { name, value } = elmnt.target;
    Object.keys(verificationCode).map((key, index) => {
      if (name === `code${index + 1}` && name !== "code4") {
        document.getElementById(`code${index + 2}`).focus();
      }
      if (name === `code${index + 1}` && name === "code4") {
        document.getElementById(`code${index + 1}`).blur();
      }
    });
    Object.keys(verificationCode).map((key, index) => {
      if (name === `code${index + 1}` && value === "" && index > 0) {
        document.getElementById(`code${index}`).focus();
      }
      if (name === `code${index + 1}` && value === "" && index === 0) {
        document.getElementById(`code${index + 1}`).focus();
      }
    });
  };

  useEffect(() => {
    if (isMounted) {
      const state = history?.location?.state?.stateRegister;
      if (state) {
        const { stateRegister } = history?.location?.state;
        setState((prevState) => ({
          ...prevState,
          values: stateRegister.values,
        }));

        const element = document.getElementById("code1");

        element.focus();
      } else {
        history.push(ROUTES.REGISTER);
      }
    } else {
      setIsMounted(true);
    }
  }, [isMounted]);

  const validateOtp = () => {
    const code = Object?.values(verificationCode)?.join("");
    if (code === "" || code?.length < 4) {
      UTILS.errorNotification(t("Please provide a valid OTP code"));
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    const code = Object?.values(verificationCode)?.join("");
    const userDetail = UTILS.getCurrentUser();
    e.preventDefault();
    if (!validateOtp()) return;
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const result = await API.VERIFY_MOBILE_NUMBER({
      verificationCode: code,
      phoneNumber: state?.values?.phoneNumber,
      language: state?.values?.baseLanguage === "en" ? "EN" : "AR",
    });
    if (result?.success === 1 && result?.response === 200 && result?.token) {
      UTILS.handleSuccesNotification(result?.message);
      UTILS.saveCurrentUser({
        ...userDetail,
        token: result.token,
      });
      dispatch(LoginAction.toogleLogin(true));

      TRACKING_EVENTS.verifiedRegistrations({
        user_id: userDetail?.userId,
      });
      setTimeout(() => {
        process.env.REACT_APP_COMPANY_NAME === C.COMPANY_NAMES.RESTHERO
          ? (window.location.href = ROUTES.ONBOARDING)
          : (window.location.href = "/app/dashboard");
      }, 2000);
    } else {
      UTILS.handleErrorNotification(result?.message);
    }

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }, 5000);
  };
  const resend_verification = async () => {
    setSeconds(30);
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const result = await API.RESEND_VERIFICATION_CODE({
      phoneNumber: state?.values?.phoneNumber,
    });

    if (result && result?.success === 1) {
      let number = state?.values?.phoneNumber?.slice(1, 13);
      UTILS.successNotification(t("Code re-sent to") + ":" + number);
    } else {
      UTILS.errorNotification(t("Please try again later"));
    }
    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);
  return (
    <div className="register-page">
      <Spin spinning={state.loading}>
        <form onSubmit={handleSubmit}>
          <div className="mt-10p bg-white">
            <div className="flex justify-center items-center">
              <div className="w-full md:w-2/3">
                <div className="text-center">
                  <h2 className="font-bold text-24px mt-5 mb-3">
                    {t("Enter Your Verification Code")}
                  </h2>
                  <p className="text-19px text-silver-light">
                    {t("We have sent a verification code to") + ": "}
                    <span dir="ltr">{state?.values?.phoneNumber}</span>
                  </p>
                </div>
                <div className="py-6">
                  <div className="text-center" dir="ltr">
                    {Object?.keys(verificationCode)?.map((key) => {
                      return (
                        <VerificationInput
                          key={key}
                          id={key}
                          value={verificationCode[key]}
                          handleChange={handleChange}
                          name={key}
                          onKeyUp={(e) => inputfocus(e)}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="text-center">
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="w-36"
                    id="otp_verification"
                  >
                    {t("Next")}
                  </Button>
                </div>
                {seconds > 0 ? (
                  <div className="text-center mt-4">
                    <span className=" text-sm  text-15x ">
                      {t("I haven't received the code")} • {seconds} {t("sec")}
                    </span>
                  </div>
                ) : (
                  <div
                    className="text-center cursor-pointer mt-4"
                    onClick={() => resend_verification()}
                  >
                    <span className=" text-sm underline text-15x text-brand-base-primary cursor-pointer">
                      {t("I haven't received the code")}
                    </span>
                  </div>
                )}

                <div className="text-center">
                  <p>
                    <span className="text-19px text-silver-light">
                      {t("Have trouble ? Call us at")}
                    </span>
                    <span
                      className="font-lato bb text-19px text-silver-light  px-2"
                      dir="ltr"
                    >
                      {process.env.REACT_APP_SUPPORT_MOBILE}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Spin>
    </div>
  );
};

export default VerifyOtp;
