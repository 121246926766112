import React from "react";
import { useTranslation } from "react-i18next";
function SearchCity(props) {
  const { t } = useTranslation();
  return (
    <div className="mt-1 flex py-2 px-2 w-full rounded-md search">
      <span className="inline-flex items-center h-10 px-3 border  border-area-border">
        <svg viewBox="0 0 24 24" className="h-6 w-6">
          <path
            fill="#abadad"
            d="M10.5 17C14.0841 17 17 14.0841 17 10.5C17 6.91592 14.0841 4 10.5 4C6.91592 4 4 6.91592 4 10.5C4 14.0841 6.91592 17 10.5 17ZM10.5 2C15.1944 2 19 5.80558 19 10.5C19 12.3368 18.4173 14.0374 17.4268 15.4273L22 20.0049L20.0049 22L15.4272 17.4269C14.0373 18.4173 12.3368 19 10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2Z"
          ></path>
        </svg>
      </span>
      <input
        onChange={(event) =>
          props.handleValues(props.keyVal, event.target.value)
        }
        value={props.value}
        type="text"
        className="focus:outline-none flex-1 input px-2 text-16px block w-full rounded-none  border  border-gray-200"
        placeholder={t(props.placeholder)}
      />
    </div>
  );
}

export default SearchCity;
