import React from "react";
import HomeTab from "logistic-integrations/components/HomeTab";
import { useLogisticContext } from "logistic-integrations/context";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ModalTabs from "logistic-integrations/components/Tabs";

import CreateClaim from "./containers/CreateOrder";
import ClaimInfo from "./containers/TrackOrder";
import CancelORder from "./containers/CancelORder";
import useLyveGlobalGetORderInfo from "./useLyveGlobalGetORderInfo";

const { TabPane } = Tabs;
const DeliveryZone = ({ order, fetchOrderDetailsByID }) => {
  const { logisticState, updateState } = useLogisticContext();

  const closeModel = () => {
    updateState("islyveGlobalModalOpen", false);
  };
  const { t } = useTranslation();
  const { lyveGlobalOrderInfo, getlyveGlobalOrderInfo } =
    useLyveGlobalGetORderInfo();

  const handleTabChange = async (key) => {
    if (key === "3") {
      fetchLatestClaimInfo();
    }
    await fetchOrderDetailsByID();
  };

  const fetchLatestClaimInfo = async () => {
    await getlyveGlobalOrderInfo(order);
  };

  return (
    <HomeTab
      {...{
        order,
        fetchOrderDetailsByID,
        isOpen: logisticState.islyveGlobalModalOpen,
        onClose: closeModel,
      }}
    >
      <ModalTabs
        setOpen={() => {
          updateState("islyveGlobalModalOpen", false);
        }}
        handleTabChange={handleTabChange}
      >
        <TabPane tab={t("SEND ORDER")} key="1">
          <CreateClaim fetchOrderDetailsByID={fetchOrderDetailsByID} />
        </TabPane>
        <TabPane tab={t("ORDER DETAIL")} key="3">
          <ClaimInfo
            fetchLatestClaimInfo={fetchLatestClaimInfo}
            lyveGlobalOrderInfo={lyveGlobalOrderInfo}
          />
        </TabPane>
        <TabPane tab={t("CANCEL ORDER")} key="5">
          <CancelORder fetchOrderDetailsByID={fetchOrderDetailsByID} />
        </TabPane>
      </ModalTabs>
    </HomeTab>
  );
};

export default DeliveryZone;
