import React from "react";
import { Menu } from "antd";
import { RocketOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "moment-timezone";
const DashboardMenu = ({ setVisible }) => {
  const router = useHistory();
  const { t } = useTranslation();
  const onMenuClick = () => {
    setVisible(false);
  };

  return (
    <div className="flex flex-col justify-between nav-height">
      <Menu
        theme="dark"
        selectedKeys={[router.location.pathname]}
        defaultSelectedKeys={["/"]}
        mode="vertical"
        onClick={onMenuClick}
      >
        <Menu.Item
          align="initial"
          key="/verify/validate-otp"
          icon={<RocketOutlined />}
        >
          <Link className="ps-3 ml-0" to={"/verify/validate-otp"}>
            {t("Verify Account")}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default DashboardMenu;
