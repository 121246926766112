import React from "react";
import WhatsAppButton from "components/WhatsAppButton";
import { CONSTANTS as C } from "config";

const Help = () => {
  return (
    <>
      {process.env.REACT_APP_COMPANY_NAME !== C.COMPANY_NAMES.CUSINETTE ? (
        <WhatsAppButton />
      ) : null}
    </>
    /*<div id="my_custom_link">
      <Affix
        style={{
          margin: 0,
          top: "auto",
          right: right,
          bottom: 20,
          left: left,
          position: "fixed",
          zIndex: 1000,
        }}
      >
        <Button
          style={{
            width: 60,
            height: 60,
            backgroundColor: "#1890ff",
            color: "white",
          }}
          shape="circle"
          size="large"
        >
          <span className="leading-4 text-14px text-center">
            {t("Need")}
            <br />
            {t("Help?")}
          </span>
        </Button>
      </Affix>
    </div>*/
  );
};
export default Help;
