import { logToSentry } from "utils/Utils";
import i18n from "../../i18n";

export const errorHandler = (error) => {
  if (!error) return i18n.t("Please try again later");
  if (typeof error === "string") {
    logToSentry(error);
    return error;
  }
  if (typeof error?.data === "string") {
    logToSentry(error.data);
    return error.data;
  }
  if (typeof error?.message === "string") {
    logToSentry(error.message);
    return error?.message;
  }
  if (typeof error?.response?.data === "string") {
    logToSentry(error?.response?.data);
    return error?.response?.data;
  }
  if (typeof error?.error?.message === "string") {
    logToSentry(error?.error?.message);
    return error?.error?.message;
  }
  if (typeof error?.error?.data?.message === "string") {
    logToSentry(error?.error?.data?.message);
    return error?.error?.data?.message;
  } else return i18n.t("Please try again later");
};
