import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { API } from "api";
import { CONSTANTS as C } from "config";
import UpdateForm from "../components/DiscountForm";
import DiscountPreview from "../components/DiscountPreview";
import usePushBack from "hooks/usePushBack";
import BackButton from "components/BackButton";

const UpdateDiscount = () => {
  const zone = moment.tz.guess();
  moment.tz.setDefault(zone);
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [discountId, setDiscountId] = useState(null);
  const [state, setState] = useState({
    values: {
      discountName: "",
      discountCode: "",
      discountAmount: 0,
      minOrderValue: 0,
      maximumDiscountValue: 0,
      startDate: "",
      perCustomerUse: 0,
      maxUsage: 0,
      endDate: "",
      isoDate: "",
      isoEndDate: "",
      applicationType: C.HIDDEN,
      discountType: C.PERCENTAGE,
      itemSelectionType: C.ALL,
      customerSegmentationType: "all",
      outletSelectionType: C.ALL,
      selectedOutlets: [],
      selectedItems: [],
      isActive: true,
      serviceType: [],
    },
    errors: {
      discountName: "",
      discountCode: "",
      discountAmount: "",
      minOrderValue: "",
      maximumDiscountValue: "",
      perCustomerUse: "",
      maxUsage: "",
      startDate: "",
      endDate: "",
      items: [],
    },
  });
  useEffect(() => {
    setLoading(true);
    if (history.location.state) {
      setInputFields(history.location.state.discount);
      setDiscountId(history.location.state.discount._id);
    }
    setLoading(false);
  }, []);

  const setInputFields = (values) => {
    moment.locale("en");
    setLoading(true);
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        discountName: values?.discountName,
        discountCode: values?.discountCode,
        discountAmount: values?.discountAmount,
        minOrderValue: values?.minOrderValue,
        isActive: values?.isActive,
        maximumDiscountValue: values?.maximumDiscountValue,
        outletSelectionType: values?.isApplicableOnAllStores
          ? C.ALL
          : C.SELECTED,
        itemSelectionType: values?.isApplicableOnAllItems ? C.ALL : C.SELECTED,
        applicationType: values?.discountApplication,
        discountType: values?.discountType,
        customerSegmentationType: values?.customerSegment,

        startDate: moment.unix(values?.startDate).format(C.DATE_FORMAT),
        endDate: moment.unix(values?.endDate).format(C.DATE_FORMAT),
        isoDate: moment.unix(values?.startDate).tz(moment.tz.guess()).format(),
        isoEndDate: moment.unix(values?.endDate).tz(moment.tz.guess()).format(),
        perCustomerUse: values?.perCustomerUse,
        maxUsage: values.maxUsage,

        selectedOutlets: values?.discountAppliedOnSpecificStores?.map(
          (store) => store.storeId
        ),

        selectedItems: values?.discountAppliedOnSpecificItems?.map(
          (item) => item.itemId
        ),
        serviceType: values?.serviceType?.map((service) => {
          return {
            serviceName: service.serviceName,
            label: service.serviceName,
          };
        }),
      },
    }));

    setLoading(false);
  };

  const updateDiscount = async () => {
    const { isFormValid } = UTILS.validateCouponsInputs(state);
    if (!isFormValid) return;
    setLoading(true);
    let discountObj = UTILS.getDiscountObj(state);
    const result = await API.UPDATE_COUPONS({
      data: {
        ...discountObj,
        _id: discountId,
      },
    });
    if (result?.success === 1) {
      UTILS.successNotification(t("Discount Updated successfully"));
      history.push("/app/dashboard/discount");
    } else {
      UTILS.errorNotification(
        result?.message || result?.error || t("Please try again later")
      );
    }
    setLoading(false);
  };
  return (
    <div className="p-4">
      <Spin spinning={loading}>
        <BackButton />
        <div className="flex w-full flex-col sm:flex-row">
          <UpdateForm
            addDiscount={updateDiscount}
            text={"Update"}
            setState={setState}
            state={state}
          />
          {/* <DiscountPreview /> */}
        </div>
      </Spin>
    </div>
  );
};

function UpdateDiscountContainer() {
  const router = useHistory();
  usePushBack("/app/dashboard/discount", router?.location?.state?.discount);

  return <UpdateDiscount />;
}

export default UpdateDiscountContainer;
