import PageHeading from "components/PageHeading";
import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { UTILS } from "utils";
import TextInput from "components/TextInput";
import TextArea from "components/TextArea";
import Modal from "components/Modal";
import { CONSTANTS as C } from "config";
import { API } from "api";
import { useHistory } from "react-router-dom";
import { Button, Spin, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import PriceInput from "components/PriceInput";
import GroupWithModifer from "features/Modifier/components/GroupWithModifer";
import DeleteComponent from "components/DeleteConfirm";
import { Modal as AntdModal } from "antd";
import { errorHandler } from "api/api/errorHandler";
const UpdateItem = () => {
  // hooks
  const { t } = useTranslation();
  const router = useHistory();

  // states
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    item: null,
  });
  const [item, setItem] = useState(UTILS.getItemModel());
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const getItemDetail = async (id) => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_ITEM_INFO({
      itemId: id,
      token: userDetail.token,
      brandId: userDetail.brandId,
    });
    if (result?.success === 1) {
      setItem((prevState) => ({
        values: result.data,
        errors: {
          ...prevState.errors,
        },
      }));
      let urls = [];
      result.data.itemImageURLs?.forEach((img, index) => {
        urls.push({
          url: img,
        });
      });
      setFileList(urls);
    } else {
    }
    setLoading(false);
  };

  useEffect(() => {
    if (router?.location?.state?.itemId)
      getItemDetail(router.location.state.itemId);
  }, [router?.location?.state?.itemId]);

  /* A function that is used to update the image url in the state. */
  const handleImage = (key, val) => {
    if (key === C.KEY_IMAGE_URL) {
      setItem((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          itemImageURLs: val,
        },
        errors: {
          ...prevState.errors,
        },
      }));
    }
  };

  /* A function that is used to update the basic info in the state. */
  const handleBasicInfo = (key, val) => {
    setItem((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        baseLanguage: {
          ...prevState.values.baseLanguage,
          [key]: val,
        },
      },
      errors: {
        ...prevState.errors,
        baseLanguage: {
          ...prevState.errors.baseLanguage,
          [key]: "",
        },
      },
    }));
  };

  /* A function that is used to update the basic info in the state. */
  const handleValues = (key, val) => {
    setItem((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: val,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  /* Validating the inputs. */
  const validateInputs = () => {
    let { objError, isValidForm } = UTILS.validateItemInfo(item);
    setItem((prevState) => ({
      values: {
        ...prevState.values,
      },
      errors: objError,
    }));
    return isValidForm;
  };

  /**
   * An async function that updates an item.
   */
  const updateItem = async () => {
    try {
      if (!validateInputs()) return;
      if (validateInputs()) {
        setLoading(true);
        const userDetail = UTILS.getCurrentUser();
        const result = await API.UPDATE_ITEM({
          itemId: item.values._id,
          ...item.values,
          price: +parseFloat(item.values.price)?.toFixed(2),
          brandId: userDetail.brandId,
        });

        if (result?.success === 1) {
          UTILS.successNotification(t("Item Updated"));
          router.goBack();
        } else {
          UTILS.errorNotification(errorHandler(result?.message));
        }
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
    }
  };

  /* Used to validate the image before uploading. */
  function beforeUpload(file) {
    setError(false);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setError(true);
      UTILS.errorNotification(t("You can only upload JPG/PNG file"));
    }
    const F_Size = file.size / 1024;
    const isLt1M = F_Size <= C.MAX_IMAGE_SIZE;

    if (!isLt1M) {
      setError(true);
      UTILS.errorNotification(t("The image must be less than 2MB in size."));
    }

    return isJpgOrPng && isLt1M;
  }

  /**
   * This function is called when a user uploads a file. It takes the file and uploads it to the server.
   * @param info - the file object
   * @param type - the type of the file, which is the file extension.
   */
  const handleChange = async (info, type) => {
    const { file, fileList } = info;
    if (error) return;
    if (!error) {
      setFileList(fileList);
    }
    if (file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (file.status === "done") {
      let list = [...item.values.itemImageURLs, info.file.response.data.url];
      handleImage(C.KEY_IMAGE_URL, list);
      setLoading(false);
    }
  };

  /**
   * It takes a file as an argument, and then it returns a function that takes an event as an argument
   * @param file - The file to be removed.
   */
  const onRemove = async (file) => {
    let List = fileList.filter((d) => d.url !== file.url);
    let List1 = item?.values.itemImageURLs.filter(
      (d) => d !== file.url && d !== file?.response?.data?.url
    );
    setFileList(List);
    handleImage(C.KEY_IMAGE_URL, List1);
  };

  /* Used to get the base64 of the image. */

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /* A function that is used to close the preview modal. */
  const handleCancel = () => setPreviewOpen(false);

  /**
   * A function that takes in a file and returns a promise.
   * @param file - The file to be previewed.
   */
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  /* A function that returns a button that is used to upload images. */
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("Upload")}
      </div>
    </div>
  );
  const onClickDeleteButton = (group) => {
    if (group?.modifierGroupId) {
      setDeleteModal({
        isOpen: true,
        item: group.modifierGroupId,
      });
    }
  };

  const UnlinkModiferGroupFromItem = async () => {
    try {
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const response = await API.unLinkModifierGroupWithItem({
        brandId: userDetail.brandId,
        itemId: item?.values?._id,
        modifierGroupId: deleteModal.item._id,
      });
      if (response?.success === 1) {
        UTILS.successNotification(
          response?.message ?? t("Unlinked Successfully")
        );
      } else {
        UTILS.errorNotification(errorHandler(response?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      await getItemDetail(item?.values._id);
      handleDeleteCancel();
      setLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModal({ isOpen: false, item: null });
  };
  return (
    <div>
      <div className="">
        <PageHeading heading={t("Update Item")} />
        <div className="px-3 py-3">
          <Spin spinning={loading}>
            <Modal
              open={deleteModal.isOpen}
              modalWidth="rounded-md border border-gray-300 top-0"
              onClose={handleDeleteCancel}
            >
              <DeleteComponent
                loading={loading}
                onCancel={handleDeleteCancel}
                onDelete={UnlinkModiferGroupFromItem}
                title={deleteModal.item?.baseLanguage?.name}
                Heading={t("will be permanently unlinked")}
              />
            </Modal>
            <Card>
              <h1 className="px-2 md:px-6 pt-4 text-19px text-blue-600">
                {t("Update Item")}
              </h1>
              <h2 className="px-2 md:px-6 pt-1 text-gray-500">
                {t("Please enter complete details for better user experience")}
              </h2>
              <div className="flex flex-wrap justify-between py-4">
                <div className="w-full md:w-1/2 px-2 md:px-6 py-1">
                  <div className="pb-3">
                    <TextInput
                      label={t("Name")}
                      value={item.values.baseLanguage.title}
                      handleValues={handleBasicInfo}
                      keyVal={"title"}
                      mandatory={true}
                      error={item.errors.baseLanguage.title}
                    />
                  </div>
                  <div className="pb-3">
                    <PriceInput
                      label={t("Price") + " (" + t("AED") + ")"}
                      value={item.values.price}
                      keyVal={"price"}
                      handleValues={handleValues}
                      mandatory={true}
                      error={item.errors.price}
                    />
                  </div>
                  <div className="pb-3">
                    <TextInput
                      label={t("Ingredients")}
                      value={item.values.baseLanguage.ingredients}
                      keyVal={"ingredients"}
                      handleValues={handleBasicInfo}
                      mandatory={false}
                      error={item.errors.baseLanguage.ingredients}
                    />
                  </div>
                  <div className="pb-3">
                    <TextInput
                      label={t("SKU (Stock Keeping Unit)")}
                      value={item.values.sku}
                      keyVal={"sku"}
                      handleValues={handleValues}
                      mandatory={false}
                      error={item.errors.sku}
                    />
                  </div>
                  <div className="pb-3">
                    <TextArea
                      label={t("Description")}
                      value={item.values.baseLanguage.description}
                      keyVal={"description"}
                      handleValues={handleBasicInfo}
                      mandatory={false}
                      error={item.errors.baseLanguage.description}
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-2 md:px-6 py-1">
                  <label className="text-14px font-bold">
                    {t("Upload Item Image")}
                  </label>

                  <h3 className="text-12px text-gray-400">
                    {t("Item image size")}
                  </h3>
                  <div>
                    <Upload
                      multiple={true}
                      accept="image/png, image/jpg, image/jpeg"
                      style={{ width: "100%", height: "100%", padding: 0 }}
                      name="image"
                      listType="picture-card"
                      fileList={fileList}
                      headers={{
                        Authorization: `Bearer ${UTILS.getCurrentUser().token}`,
                      }}
                      action={C.BASE_URL + "user/uploadFile"}
                      onChange={(info) => handleChange(info, "")}
                      onPreview={onPreview}
                      beforeUpload={beforeUpload}
                      onRemove={onRemove}
                    >
                      {fileList?.length <= 9 ? uploadButton : null}
                    </Upload>
                    <AntdModal
                      open={previewOpen}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "100%",
                        }}
                        src={previewImage}
                      />
                    </AntdModal>
                  </div>
                </div>
                <div className="px-2 md:px-6 py-2">
                  <Button
                    className="me-2 w-28"
                    type="primary"
                    size="large"
                    onClick={() => updateItem()}
                  >
                    {t("Update")}
                  </Button>
                  <Button
                    className="w-28"
                    type="default"
                    size="large"
                    onClick={() => router.goBack()}
                  >
                    {t("Back")}
                  </Button>
                </div>
              </div>
            </Card>
            <div className="mt-4">
              <GroupWithModifer
                attachedModifierGroups={item?.values?.modifierGroups}
                setItem={setItem}
                deleteModifierGrp={onClickDeleteButton}
                item={item}
                getItemDetail={getItemDetail}
              />
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default UpdateItem;
