import React, { useEffect } from "react";
import moment from "moment";
function useDeliveryWaiveoff({ setStore, store }) {
  const handleOnOrderAmount = (key, val) => {
    setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        deliveryChargesWaiverInfo: {
          ...prevState.values.deliveryChargesWaiverInfo,
          onOrderAmount: {
            ...prevState.values.deliveryChargesWaiverInfo.onOrderAmount,
            [key]: val,
          },
        },
      },
      errors: {
        ...prevState.errors,
      },
    }));
  };

  const handleDateRange = (key, val) => {
    setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        deliveryChargesWaiverInfo: {
          ...prevState.values.deliveryChargesWaiverInfo,
          onDateRange: {
            ...prevState.values.deliveryChargesWaiverInfo.onDateRange,
            [key]: val,
          },
        },
      },
      errors: {
        ...prevState.errors,
      },
    }));
  };

  const handleSetDate = (date) => {
    const [startDateTime, endDateTime] = date?.map((date) => {
      return moment(date).locale("en").format();
    });
    handleDateRange("startDateTime", startDateTime);
    handleDateRange("endDateTime", endDateTime);
  };

  const dataConversion = (unixTimestamp) => {
    if (unixTimestamp?.toString()?.length === 10) {
      const formattedDate = moment
        .unix(unixTimestamp)
        .format("YYYY-MM-DDTHH:mm:ssZ");
      return moment(formattedDate);
    } else return moment(unixTimestamp);
  };
  useEffect(() => {
    console.log(
      "asasa",
      store?.values?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime
    );
    if (
      store?.values?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime?.toString()
        ?.length === 10
    ) {
      const startDate = moment
        .unix(
          store?.values?.deliveryChargesWaiverInfo?.onDateRange?.startDateTime
        )
        .format("YYYY-MM-DDTHH:mm:ssZ");
      const endDate = moment
        .unix(
          store?.values?.deliveryChargesWaiverInfo?.onDateRange?.endDateTime
        )
        .format("YYYY-MM-DDTHH:mm:ssZ");

      handleDateRange("startDateTime", startDate);
      handleDateRange("endDateTime", endDate);
    }
  }, []);
  return {
    handleOnOrderAmount,
    handleDateRange,
    handleSetDate,
    dataConversion,
  };
}

export default useDeliveryWaiveoff;
