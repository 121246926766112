import { Spin } from "antd";
import Modal from "components/Modal";
import BackArrow from "features/icons/BackArrow";
import usePushBack from "hooks/usePushBack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useCreateUserWalletTransactionMutation,
  useGetUserWalletBalanceMutation,
  useGetUserWalletTransactionHistoryMutation,
} from "redux/loyalty/loyalty-service";
import { UTILS } from "utils";
import CreditLoyaltyPointsModal from "../components/CreditLoyaltyPointsModal";
import CustomerWalletHistoryTable from "../components/CustomerWalletHistoryTable";
import CreditLoyaltyPoints from "../models/CreditLoyaltyPoints";
const CustomerDetail = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const [userWalletHistory, setUserWalletHistory] = useState([]);
  const [userWalletBalance, setUserWalletBalance] = useState(0);
  const { t } = useTranslation();
  const [getUserWalletTransactionHistory, { isLoading: transactionsLoader }] =
    useGetUserWalletTransactionHistoryMutation();
  const [createUserWalletTransaction] =
    useCreateUserWalletTransactionMutation();
  const [getUserWalletBalance, { isLoading: balanceLoader }] =
    useGetUserWalletBalanceMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({}, []);

  // Fetching user wallet transaction history and balance
  async function fetchUserWalletTransactionHistory() {
    const userDetail = UTILS.getCurrentUser();
    const state = history?.location?.state;
    if (!state || !state._id || !userDetail?.brandId) return;

    const transationPayload = {
      userId: state._id,
      brandId: userDetail.brandId,
      limit: 120,
    };
    const balancePayload = {
      userId: state._id,
      brandId: userDetail.brandId,
    };

    const [transactionHistory, walletBalance] = await Promise.all([
      getUserWalletTransactionHistory(transationPayload),
      getUserWalletBalance(balancePayload),
    ]);
    if (
      transactionHistory?.data?.success === 1 &&
      transactionHistory?.data?.data?.length > 0
    ) {
      setUserWalletHistory(transactionHistory?.data?.data);
    }
    if (walletBalance?.data?.success === 1 && walletBalance?.data?.data) {
      setUserWalletBalance(walletBalance?.data?.data);
    }
  }

  useEffect(() => {
    fetchUserWalletTransactionHistory();
  }, []);

  // credit loyalty points in customer wallet
  const handleAddPoints = async (data) => {
    let payload = {
      ...data,
      userId: history?.location?.state?._id,
      MANUAL_CREDITED: {
        amount: Number(data.MANUAL_CREDITED.amount),
      },
    };
    const { data: res } = await createUserWalletTransaction(payload);
    if (res?.success === 1) {
      setOpen(false);
      await fetchUserWalletTransactionHistory();
      reset();
    }
  };
  return (
    <Spin spinning={isLoading || transactionsLoader || balanceLoader}>
      <div className="px-8 py-4">
        <div className="">
          <div
            className="cursor-pointer inline-block"
            // onClick={() => history.goBack()}
          >
            <span className="align-middle">
              <BackArrow onClick={() => history.goBack()} />
            </span>
            <span className="align-middle px-2 text-gray-500">
              {t("Customers")}
            </span>
          </div>
        </div>
        <h1 className="text-gray-900 text-24px sm:text-30px font-normal pt-4">
          {t("User loyalty details")}
        </h1>
        <h3 className="text-gray-500  pb-4">{t("Manage user loyalty.")}</h3>
        <div className="w-full md:w-1/2 lg:w-1/3 ">
          <div className="">
            <h3 className="text-gray-900  text-17px font-semibold">
              {t("Customer details")}
            </h3>
            <p className="text-gray-500 text-15px">
              {" "}
              {history?.location?.state?.name}
            </p>
            <p className="text-gray-500">
              <span>
                {t("Customer current whatsapp number is")}
                <strong dir="ltr mx-1">
                  {` +${history?.location?.state?.countryCode}${history?.location?.state?.phoneNumber} `}
                </strong>
              </span>
            </p>
            <p className="text-gray-500">
              <span>
                {t("Customer lifetime spending value is")}
                <strong className=" mx-1 text-green-600">
                  {" "}
                  {t("AED")}{" "}
                  {parseFloat(userWalletBalance?.lifetimeSpending)?.toFixed(
                    2
                  ) || 0}
                </strong>
              </span>
            </p>
          </div>
          <CreditLoyaltyPointsModal {...{ userWalletBalance, setOpen }} />
          <Modal
            open={open}
            modalWidth="w-full rounded-md border border-gray-300 top-0"
            onClose={() => setOpen(false)}
          >
            <CreditLoyaltyPoints
              onClose={() => setOpen(false)}
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
              onSubmit={handleSubmit(handleAddPoints)}
            />
          </Modal>
        </div>

        <CustomerWalletHistoryTable
          {...{ userWalletHistory, fetchUserWalletTransactionHistory }}
        />
      </div>
    </Spin>
  );
};

function CustomerWalletInfo() {
  const router = useHistory();
  usePushBack("/app/dashboard/brand-customers", router?.location?.state?._id);
  return <CustomerDetail />;
}

export default CustomerWalletInfo;
