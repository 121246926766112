import React from "react";
import PropTypes from "prop-types";

const DashboardCard = (props) => {
  return (
    <div className="bg-white mb-5 rounded shadow-sm border-gray-200 border h-44 lg:h-auto">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="overflow-hidden h-44 lg:h-auto">
          <div className="px-4 py-5 h-44 lg:h-auto flex flex-col justify-between">
            <h2 className="font-bold text-dash-title text-17px border-b">
              {props.heading}
            </h2>
            <p className={props.pClass}>{props.count}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
DashboardCard.propTypes = {
  pClass: PropTypes.string,
  count: PropTypes.number,
  heading: PropTypes.string,
};
export default DashboardCard;
