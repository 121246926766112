import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isError: false,
  error: null,
  domainError: null,
  brandError: null,
  passwordError: null,
};

const SettingsErrorReducer = createSlice({
  name: "settingReducer",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
      state.isError = action.payload ? true : false;
    },
    setDomainError: (state, action) => {
      state.domainError = action.payload;
      state.isError = action.payload ? true : false;
    },
    setBrandError: (state, action) => {
      state.brandError = action.payload;
      state.isError = action.payload ? true : false;
    },
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
      state.isError = action.payload ? true : false;
    },
  },
});

export const { setError, setBrandError, setDomainError, setPasswordError } =
  SettingsErrorReducer.actions;
export default SettingsErrorReducer.reducer;
