import React from "react";
import PropTypes from "prop-types";
import { API } from "api";
import { UTILS } from "utils";
import { useSelector, useDispatch } from "react-redux";
import { AreasAction } from "redux/actions";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import useGetStoreAddedMappedAreas from "./hooks/useGetStoreAddedMapAreas";
import DeleteAreasModal from "features/shipping_methods/common/DeleteAreasModal";
const DeleteMapAreas = ({ onClose, selectedAreas, setSelectedRowsKeys }) => {
  const dispatch = useDispatch();
  const getStoreMappedAddedAreas = useGetStoreAddedMappedAreas();
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const deleteSelectedAreas = async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      let objectArray = [];
      selectedAreas.forEach((area) => {
        objectArray.push(area._id);
      });

      const result = await API.deleteStoreMappedArea({
        brandId: userDetail.brandId,
        storeId: selectedAreas[0].storeId,
        mappedAreaBaseDeliveryMethodIds: objectArray,
      });
      if (result?.success === 1) {
        UTILS.successNotification(result.message);
        dispatch(AreasAction.updateSelectedArea([]));
      } else {
        UTILS.errorNotification(errorHandler(result));
      }
      setSelectedRowsKeys([]);

      await getStoreMappedAddedAreas({
        storeId: selectedAreas[0].storeId,
      });
      onClose();
    } catch (error) {
      UTILS.errorNotification(errorHandler(error));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return (
    <DeleteAreasModal
      {...{ isLoading, selectedAreas, onClose }}
      deleteAreas={deleteSelectedAreas}
    />
  );
};

DeleteMapAreas.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default DeleteMapAreas;
