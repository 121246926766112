import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Card, Table, Space, Tag } from "antd";
import moment from "moment";
import SearchInput from "components/SearchInput";
import { Switch } from "@headlessui/react";
import { EditOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import useUpdateMenu from "../hooks/useUpdateMenu";
import useDeleteMenu from "../hooks/useDeleteMenu";
import { useMenuContext } from "store/contexts/Menus";
import Modal from "components/Modal";
import DeleteComponent from "components/DeleteConfirm";

const MenuListing = ({ menus, setOpen, CreateNewMenu, getMenuById }) => {
  const router = useHistory();
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  console.log({ currentLanguage });

  const updateMenu = useUpdateMenu();
  const deleteMenu = useDeleteMenu();
  const { resetState, updateState } = useMenuContext();
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    item: null,
  });
  const [searchTerm, setSearch] = useState(null);
  const getMenu = async (id, cb) => {
    resetState();
    await getMenuById(id);
    updateState(cb, true);
  };
  const Actions = ({ rowData }) => {
    return (
      <Space>
        <Button
          type="primary"
          size="large"
          className="me-1 rounded-md"
          onClick={() => {
            getMenu(rowData?._id, "editMenumodal");
          }}
        >
          <EditOutlined />
          {t("Edit")}
        </Button>
        <Button
          type="primary"
          size="large"
          className="me-1 rounded-md"
          onClick={() => {
            getMenu(rowData?._id, "cloneMenuModal");
          }}
        >
          <CopyOutlined />
          {t("Duplicate")}
        </Button>
        <Button
          disabled={rowData.isDefault}
          type="danger"
          size="large"
          className="rounded-md "
          onClick={() => {
            onClickDeleteButton(rowData);
          }}
        >
          <DeleteOutlined />
          {t("Delete")}
        </Button>
      </Space>
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "isActive",
      align: "initial",
      render: (value, rowData, index) => {
        const isActive = rowData.isActive;
        return (
          <Switch
            disabled={rowData.isDefault}
            checked={isActive}
            onChange={(event) => {
              updateMenu({ rowData, event });
            }}
            className={`mb-1 
              ${isActive ? " bg-theme-green" : "bg-gray-300"}
             relative inline-flex items-center h-8 rounded-full w-16`}
          >
            <div
              className={
                !isActive
                  ? "dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                  : "dot absolute right-1 top-1 bg-white w-6 h-6 rounded-full transition"
              }
            ></div>
          </Switch>
        );
      },
    },
    {
      title: t("Name"),
      dataIndex: "baseLanguage",
      align: "initial",
      render: (value, rowData, index) => {
        return (
          <div className="flex gap-2 default-tag">
            <span
              className="underline cursor-pointer text-blue-500"
              onClick={() => {
                resetState();
                router.push({
                  pathname: `/app/dashboard/menu-editor/${rowData?._id}`,
                  state: {
                    menu: rowData,
                  },
                });
              }}
            >
              {value?.name}
            </span>

            {rowData?.isDefault ? (
              <Tag className="ms-2 rounded h-4 tag-span">{t("Default")}</Tag>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: t("Used By"),
      dataIndex: "stores",
      align: "initial",
      render: (value, rowData, index) => {
        return (
          <span
            onClick={() => {
              setOpen(true, rowData?._id);
            }}
            className="underline cursor-pointer text-blue-500 "
          >
            {`${value?.length} ${t("Outlet(s)")}`}
          </span>
        );
      },
    },
    {
      title: t("Last Updated"),
      dataIndex: "updatedAt",
      align: "initial",
      render: (value, rowData, index) => {
        return <>{moment(value).format("DD MMM YYYY")}</>;
      },
    },
    {
      title: t("Action"),
      dataIndex: "action",
      align: "center",
      render: (value, rowData, index) => {
        return <Actions rowData={rowData} />;
      },
    },
  ];

  const FilteredData = useMemo(() => {
    let searchTermLower = searchTerm?.toLowerCase()?.trim();

    if (searchTerm === "" || searchTerm === null) {
      return menus;
    }
    return menus?.filter((group) =>
      group.baseLanguage?.name?.toLowerCase()?.includes(searchTermLower)
    );
  }, [menus, searchTerm]);

  const onClickDeleteButton = (rowData) => {
    if (rowData?._id) {
      setDeleteModal({ isOpen: true, item: rowData });
    }
  };

  const handleCancel = () => {
    setDeleteModal({ isOpen: false, item: null });
  };

  return (
    <div className="w-full my-2">
      <div className="flex justify-between flex-col sm:flex-row mb-5">
        <div>
          <h1 className="text-gray-900 text-24px md:text-30px font-medium">
            {t("Manage Menus")}
          </h1>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="group relative my-4 sm:mt-0">
            <SearchInput
              placeholder={t("Search by name")}
              searchTerm={searchTerm}
              setSearchTerm={setSearch}
            />
          </div>
          <div className="flex justify-center sm:justify-end">
            <Button
              type="primary"
              size="large"
              className=" ms-2 rounded-md"
              onClick={CreateNewMenu}
            >
              {t("Create Menu")}
            </Button>
          </div>
        </div>
      </div>
      <Card>
        <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
          <Table
            locale={{ emptyText: t("No Data") }}
            columns={columns}
            dataSource={FilteredData}
            pagination={{ position: ["bottomCenter"] }}
            size="small"
            rowKey={(record) => record._id}
          />
        </div>
      </Card>

      <Modal
        open={deleteModal.isOpen}
        modalWidth="rounded-md border border-gray-300 top-0"
        onClose={handleCancel}
      >
        <DeleteComponent
          loading={isLoading}
          onCancel={handleCancel}
          onDelete={() => {
            deleteMenu({
              menuId: deleteModal.item?._id,
            }).then(() => handleCancel());
          }}
          title={deleteModal.item?.baseLanguage?.name}
          Heading={t("will be permanently deleted")}
        />
      </Modal>
    </div>
  );
};
export default MenuListing;
MenuListing.propTypes = {
  item: PropTypes.object,
};
