import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
const PackageTabs = ({ subscribedPlan, children, selectedKey, callback }) => {
  // const [selectedKey, setSelectedKey] = useState("SEMI_ANNUALLY");
  // const callback = (key) => {
  //   setSelectedKey(key);
  // };
  // const getSelectedTab = () => {
  //   if (subscribedPlan?.billingCycle) {
  //     setSelectedKey(subscribedPlan.billingCycle);
  //     return subscribedPlan.billingCycle;
  //   }
  // };
  // useEffect(() => {
  //   getSelectedTab();
  // }, [subscribedPlan]);

  return (
    <div className="flex justify-center tabseting">
      <Tabs
        activeKey={selectedKey}
        onChange={callback}
        centered
        size="large"
        type="card"
      >
        {children}
      </Tabs>
    </div>
  );
};

export default PackageTabs;
