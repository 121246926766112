import React from "react";
import { SVG_TICK, SVG_PLUS } from "svg/SVGs";
import { useTranslation } from "react-i18next";

function AreaBusinessIcon() {
  const { t } = useTranslation();
  return (
    <div className="w-full lg:w-1/3 sm:border-l border-gray-200 px-4">
      <div className="pt-8 sm:pt-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-8 h-10 inline-block me-3"
          viewBox="0 0 40 26"
        >
          <rect x="3" width="34" height="26" rx="1" fill="#ddf2ff"></rect>
          <path
            fill="#fff"
            d="M25.7 15.9L18 8.4 26.4 0h-2.8l-7 7-7-7H6.7l8.5 8.4-8 8-4.2-4v2.8L5.7 18 3 20.6v2.8l4.1-4.1L14 26h2.8l-8.2-8.1 8.1-8.1L33.1 26h2.8l-8.8-8.7L37 7.4V4.6L25.7 15.9z"
          ></path>
          <path
            d="M21 10a6 6 0 00-12 0c0 5.5 6 11 6 11s6-5.5 6-11z"
            fill="#f46b5d"
          ></path>
          <circle fill="#fff" cx="15" cy="9.9" r="2.4"></circle>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-8 h-10  inline-block me-3"
          viewBox="0 0 40 26"
        >
          <path fill="#e89234" d="M40 23H11V0h29z"></path>
          <path fill="#fbd888" d="M22 0h7v8h-7z"></path>
          <rect y="10" width="19" height="16" rx="1" fill="#dcf2ff"></rect>
          <path
            d="M13.92 14.37a3.41 3.41 0 011.16.49 1.45 1.45 0 01.72 1.08c0 .37-.3.57-.7.59a.87.87 0 01-.93-.55 1.4 1.4 0 00-1.17-.42c-.69 0-1.24.27-1.24.75 0 .31.22.51.67.67.63.24 1.45.35 2.2.67A1.91 1.91 0 0116 19.48a2.26 2.26 0 01-2.1 2.18v.61a.79.79 0 01-.84.73.8.8 0 01-.75-.61v-.68a3.62 3.62 0 01-1.74-.71 1.43 1.43 0 01-.57-1 .7.7 0 01.74-.66 1.28 1.28 0 011 .63 1.55 1.55 0 001.3.53c.77 0 1.4-.34 1.4-.94a.81.81 0 00-.49-.7 23.63 23.63 0 00-2.3-.73 1.82 1.82 0 01-1.4-1.73c0-1.17.95-1.83 2.1-2v-.68a.79.79 0 01.8-.67.76.76 0 01.79.67zM3 15l6 2.25v1.5L3 21v-1.5L7 18l-4-1.5V15z"
            fill="#457ba4"
          ></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-8 h-10  inline-block me-3"
          viewBox="0 0 40 26"
        >
          <path fill="#e89234" d="M40 23H11V0h29z"></path>
          <path fill="#fbd888" d="M22 0h7v8h-7z"></path>
          <rect y="5" width="19" height="21" rx=".9" fill="#457ba4"></rect>
          <path fill="#dcf2ff" d="M2 7h15v4H2z"></path>
          <path
            fill="#7ebbe8"
            d="M6 13h3v3H6zm4 0h3v3h-3zm4 0h3v3h-3zm-8 4h3v3H6zm4 0h3v3h-3zm4 0h3v7h-3zm-8 4h3v3H6zm-4-8h3v3H2zm0 4h3v3H2zm0 4h3v3H2zm8 0h3v3h-3z"
          ></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-8 h-10  inline-block me-3"
          viewBox="0 0 40 26"
        >
          <rect x="3" width="34" height="26" rx="2.2" fill="#ddf2ff"></rect>
          <path
            fill="#7ebbe8"
            d="M36 0H4a1 1 0 00-1 1v4h34V1a1 1 0 00-1-1z"
          ></path>
          <path fill="#fff" d="M8 8h6v6H8zm9 0h6v6h-6zm9 0h6v6h-6z"></path>
          <path fill="#7ebbe8" d="M26 17h6v6h-6z"></path>
          <path fill="#fff" d="M8 17h6v6H8zm9 0h6v6h-6z"></path>
        </svg>
      </div>
      <div className="">
        <ul className="">
          <li className="text-13px my-2">
            <SVG_TICK />
            {t("Delivery limited by zones")}
          </li>
          <li className="text-13px mb-2">
            <SVG_TICK />
            {t("Delivery limited by order subtotal")}
          </li>
          <li className="text-13px mb-2">
            <SVG_TICK /> {t("Custom rates")}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AreaBusinessIcon;
