import { TRACKING_UTILS } from "utils";

// call on next-button on registration page on Api success
export const nonVerifiedRegistrations = (data) => {
  const { userId, brandName, phoneNumber } = data;
  // check if plugin is active, for example facebook pixel
  TRACKING_UTILS.triggerFacebookEvent(
    "NonVerifiedRegistrations",
    {
      userId: userId,
      brandName: brandName,
      phoneNumber: phoneNumber,
    },
    {
      isCustomEvent: true,
    }
  );
  TRACKING_UTILS.triggerFacebookEvent("RegisterUser", data, {
    isCustomEvent: true,
  });

  TRACKING_UTILS.triggerGoogleAdsEvent("NonVerifiedRegistrations", {
    send_to: "AW-11071182048/dJp2CNztvI4YEOCpk58p",
  });

  TRACKING_UTILS.triggerGoogleAdsEvent("conversion", {
    send_to: "AW-11071182048/uG_vCL6ghokYEOCpk58p",
  });

  TRACKING_UTILS.triggerGoogleAnalyticsEvent("non_verified_registration_ga", {
    transaction_id: userId,
    value: 0,
  });
};

// call on otp  Api success
export const verifiedRegistrations = (data) => {
  const { user_id } = data;
  TRACKING_UTILS.triggerFacebookEvent("VerifiedRegistrations", data, {
    isCustomEvent: true,
  });
  TRACKING_UTILS.triggerGoogleAdsEvent("VerifiedRegistrations", {
    send_to: "AW-11071182048/KLugCKrxvI4YEOCpk58p",
  });

  TRACKING_UTILS.triggerGoogleAnalyticsEvent("verified_registration_ga", {
    transaction_id: user_id,
    value: 0,
  });
};

export const subscribedUsers = (data) => {
  const { transaction_id, plan_price, plan_name, currency } = data;

  TRACKING_UTILS.triggerFacebookEvent(
    "SubscribedUsers",
    { value: "" + plan_price, currency: currency },
    {
      isCustomEvent: true,
    }
  );

  TRACKING_UTILS.triggerGoogleAdsEvent("SubscribedUsers", {
    send_to: "AW-11071182048/-IFWCJGCvY4YEOCpk58p",
  });
  //transaction_id:transaction_id
  TRACKING_UTILS.triggerGoogleAnalyticsEvent("subscribed_plan_ga", {
    transaction_id: transaction_id,
    plan_name: plan_name,
    plan_price: plan_price,
  });
};
