import React, { useState } from "react";
import { Tabs, Spin } from "antd";
import { useTranslation } from "react-i18next";
import DeliveryPartner from "./DeliveryPartner";
import CheckDeliveryFee from "./CheckDeliveryFee";
import Table from "./Table";
import CancelOrder from "./CancelOrder";
const { TabPane } = Tabs;
const DeliveryService = ({ order, getOrderInfoById, setOpen }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const callback = (key) => {};
  return (
    <div className="p-5  bg-white">
      <Spin spinning={loading}>
        <div className="flex justify-end mb-4">
          <span onClick={() => setOpen(false)} className="cursor-pointer">
            <svg
              xmlns="https://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </span>
        </div>
        <div className="">
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab={t("Send Order")} key="1">
              <DeliveryPartner
                order={order}
                setLoading={setLoading}
                getOrderInfoById={getOrderInfoById}
              />
            </TabPane>
            <TabPane tab={t("Delivery Fee")} key="2">
              <CheckDeliveryFee
                order={order}
                opertation="fee"
                setLoading={setLoading}
              />
            </TabPane>
            <TabPane tab={t("Delivery Time")} key="3">
              <CheckDeliveryFee
                order={order}
                opertation="time"
                setLoading={setLoading}
              />
            </TabPane>
            <TabPane tab={t("Order Detail")} key="4">
              <Table order={order} getOrderInfoById={getOrderInfoById} />
            </TabPane>
            <TabPane tab={t("Cancel Order")} key="5">
              <CancelOrder
                order={order}
                setLoading={setLoading}
                getOrderInfoById={getOrderInfoById}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  );
};

export default DeliveryService;
