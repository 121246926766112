import EditMapArea from "features/shipping_methods/mapped-areas/PolygonBased/areas-drawing/EditDrawnArea";
import AddcustomMapArea from "features/shipping_methods/mapped-areas/PolygonBased/areas-drawing/DrawAreaOnMap";
import StoreMapAreasListing from "features/shipping_methods/mapped-areas/PolygonBased/StoreMapAreasListing";
import ManageMappedAreas from "features/shipping_methods/mapped-areas/ManageMappedAreas";

const routes = [
  {
    path: "/app/dashboard/mapped-area-method",
    component: ManageMappedAreas,
  },

  {
    path: "/app/dashboard/edit-map-area/:mappedAreaId",
    component: EditMapArea,
  },
  {
    path: "/app/dashboard/add-custom-map-area",
    component: AddcustomMapArea,
  },
  {
    path: "/app/dashboard/store-map-areas-listing",
    component: StoreMapAreasListing,
  },
];

export default routes;
