import { Tabs, Select } from "antd";
import React, { useState } from "react";
import MapAreasListing from "./PolygonBased/MapAreasListing";
import ZoneListing from "./distanced-based-method/containers/ZoneListing";
import { useTranslation } from "react-i18next";
import useUserType from "hooks/useUserType";
import useAllStores from "hooks/useCurrentUserStores";
import { useDispatch, useSelector } from "react-redux";
import { UTILS } from "utils";
import ActionButtonsDropDown from "../common/ActionButtonsDropDown";
import { AreasAction } from "redux/actions";
import { MAPPED_AREA_MODAL_ID } from "features/shipping_methods/constants";
import AddNewAreas from "./PolygonBased/AddNewAreas";
import DeleteArea from "./PolygonBased/DeleteArea";
import UpdateAreas from "./PolygonBased/UpdateAreas";
import AddZone from "features/shipping_methods/mapped-areas/distanced-based-method/containers/AddZone";
import Modal from "components/Modal";
import BackButton from "components/BackButton";
import EditDistanceBAsedZone from "./distanced-based-method/containers/EditZone";

const { TabPane } = Tabs;

function Index() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { stores } = useAllStores();
  const [dialogContentId, setDialogContentId] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowsKeys] = useState([]);
  const [selectedDataRows, setSelectedDataRows] = useState([]);
  const userType = useUserType() || UTILS.getCurrentUser()?.userType;
  const { selectedStore } = useSelector((state) => state.AreasReducer);

  const addArea = () => {
    setOpen(true);
    setDialogContentId(MAPPED_AREA_MODAL_ID.ADD_AREA);
  };
  const onStoreChange = (value) => {
    localStorage.setItem("storeId", value.value);
    dispatch(AreasAction.setStore(value));
  };

  const openDistanceModal = () => {
    setOpen(true);
    setDialogContentId(MAPPED_AREA_MODAL_ID.OPEN_DISTANCE_MODAL);
  };
  const onSelectChange = () => setSelectedRowsKeys(selectedRowKeys);

  const onClose = () => {
    setOpen(false);
    setSelectedRowsKeys([]);
  };

  const COMPONENTS_MAP = {
    0: <AddNewAreas onClose={onClose} />,
    1: (
      <DeleteArea
        selectedAreas={selectedDataRows}
        onClose={onClose}
        setSelectedRowsKeys={setSelectedRowsKeys}
        onSelectChange={onSelectChange}
      />
    ),
    2: (
      <UpdateAreas
        selectedAreas={selectedDataRows}
        onClose={onClose}
        setSelectedRowsKeys={setSelectedRowsKeys}
        onSelectChange={onSelectChange}
      />
    ),
    6: <AddZone onClose={onClose} open={open} />,
    7: (
      <EditDistanceBAsedZone
        onClose={onClose}
        open={open}
        selectedDataRows={selectedDataRows}
      />
    ),
  };
  const renderModalContent = () => COMPONENTS_MAP[dialogContentId] || null;

  return (
    <div>
      <div className="mb-3">
        <BackButton />
      </div>
      <Modal
        scroll={dialogContentId === 0 ? true : false}
        open={open}
        modalWidth="rounded-md border border-gray-300"
        onClose={() => setOpen(false)}
        responsiveClass="w-full lg:w-full xl:w-4/5 2xl:2/3"
      >
        {renderModalContent()}
      </Modal>
      <div className="mb-2">
        <h2 className="text-17px text-blue-600 ">{t("Select store")}</h2>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="flex items-center my-3 w-full  me-0 sm:me-2  sm:w-96 sm:my-0">
            <Select
              value={selectedStore}
              size="large"
              labelInValue={true} // Add this prop
              showSearch
              className="w-full rounded"
              placeholder={t("Select store")}
              optionFilterProp="children"
              onChange={onStoreChange}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={stores}
            />
          </div>

          {selectedStore && (
            <ActionButtonsDropDown
              addArea={addArea}
              userType={userType}
              openDistanceModal={openDistanceModal}
            />
          )}
        </div>
      </div>

      {selectedStore && (
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane tab={t("Polygon Based Areas")} key="1">
            <MapAreasListing
              {...{
                setOpen,
                setDialogContentId,
                selectedRowKeys,
                setSelectedDataRows,
                setSelectedRowsKeys,
              }}
            />
          </TabPane>
          <TabPane tab={t("Distance Based Zones")} key="2">
            <ZoneListing
              {...{
                setOpen,
                setDialogContentId,
                setSelectedDataRows,
                selectedDataRows,
              }}
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
}

export default Index;
