import { Button } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetYandexExpressRiderInfoMutation } from "redux/Logistic/LogisticService";
import { UTILS } from "utils";
const ClaimInfo = ({ order, type }) => {
  const [riderInfo, setRiderInfo] = useState({});
  const { t } = useTranslation();
  const { claimInfo } = useSelector((state) => state?.LogisticReducer);
  const [getYandexExpressRiderInfo] = useGetYandexExpressRiderInfoMutation();

  const getRiderInfo = async () => {
    const userDetail = UTILS.getCurrentUser();
    const obj = {
      brandId: userDetail.brandId,
      orderId: order._id,
    };
    const isValid = UTILS.ValidateParams(obj);
    if (isValid) {
      const { data } = await getYandexExpressRiderInfo(obj);
      if (data?.success === 1 && data?.data) {
        setRiderInfo(data.data);
      }
    }
  };

  return (
    <Fragment>
      <div>
        {claimInfo ? (
          <table className="border-collapse border border-slate-400 w-full">
            <tr>
              <td className="border border-slate-300 p-4">{t("status")}</td>
              <td className="border border-slate-300 p-4">
                <div>{claimInfo?.status}</div>
              </td>
            </tr>

            <>
              {claimInfo?.performer_info &&
                Object.keys(claimInfo.performer_info)?.map((key, i) => {
                  const length = Object.keys(claimInfo.performer_info).length;
                  return (
                    <tr>
                      {i === 0 && (
                        <td rowSpan={length} className="px-4">
                          {t("Rider Info")}
                        </td>
                      )}
                      <td className="border border-slate-300 p-4">{key}</td>
                      <td className="border border-slate-300 p-4">
                        <div>{claimInfo?.performer_info[key]}</div>
                      </td>
                    </tr>
                  );
                })}
            </>
            {claimInfo?.performer_info && (
              <tr>
                <td className="border border-slate-300 p-4">
                  {t("Driver Phone Number")}
                </td>
                <td className="border border-slate-300 p-4">
                  {type === true ? null : (
                    <Button onClick={getRiderInfo} type="primary">
                      {t("Get Driver Phone Number")}
                    </Button>
                  )}
                  {riderInfo?.phone}
                </td>
              </tr>
            )}
          </table>
        ) : null}
      </div>
    </Fragment>
  );
};

export default ClaimInfo;
