import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { CONSTANTS as C } from "config";
import "antd/es/slider/style";
function FaviconUpload({
  onPreview,
  handleChange,
  beforeUpload,
  settings,
  uploadButton,
  name,
  title,
  description,
}) {
  const { t } = useTranslation();

  return (
    <div className="py-2 w-full md:w-1/2">
      <label className=" text-gray-600 block text-14px font-bold">
        {title}
      </label>
      <ImgCrop
        showGrid={true}
        rotationSlider={true}
        aspectSlider={true}
        showReset={true}
        quality={1}
        modalTitle={t("Edit Image")}
        modalOk={t("Ok")}
        modalCancel={t("Cancel")}
        rotate
      >
        <Upload
          name="image"
          listType="picture-card"
          showUploadList={false}
          headers={{
            Authorization: `Bearer ${UTILS.getCurrentUser().token}`,
          }}
          action={C.BASE_URL + "user/uploadFile"}
          onChange={(info) => handleChange(info, name)}
          onPreview={onPreview}
          beforeUpload={beforeUpload}
        >
          {settings?.[name] ? (
            <img
              src={settings?.[name]}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </ImgCrop>
      <p className="text-gray-400">{description}</p>
    </div>
  );
}

export default FaviconUpload;
