import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refreshApp: false,
};

const AppUpdate = createSlice({
  name: "AppUpdate",
  initialState,
  reducers: {
    refreshApp: (state, action) => {
      state.refreshApp = true;
    },
  },
});

export const { refreshApp } = AppUpdate.actions;
export default AppUpdate.reducer;
