import React, { useEffect } from "react";
import { AddPaymentLink } from "../components/AddPaymentLink";
import useAllStores from "../hooks/useAllStores";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { LoaderAction } from "redux/actions";
import { UTILS } from "utils";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
const CreatePaymentLinks = () => {
  const { stores, loading } = useAllStores();
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({}, []);

  useEffect(() => {
    if (isSubmitting) {
      const fields = ["amount", "purposeOfPayment", "message"];
      fields.forEach((field) => {
        setValue(field, getValues(field).trim());
      });
    }
  }, [isSubmitting]);

  const onSubmit = handleSubmit(async (data) => {
    dispatch(LoaderAction.setLoading(true));
    const { label, value } = JSON.parse(data.storeSelection);
    let obj = {
      storeId: value,
      storeName: label,
      amount: Number(Number(data.amount)?.toFixed(2)),
      currency: "AED",
      purposeOfPayment: data.purposeOfPayment,
      message: data.message,
    };
    try {
      const userDetail = UTILS.getCurrentUser();
      const result = await API.CREATE_PAYMENT_LINK({
        brandId: userDetail.brandId,
        language: currentLanguage,
        data: obj,
      });
      if (result?.success === 1 && result?.data) {
        UTILS.handleSuccesNotification(t(errorHandler(result?.message)));
        redirectToPaymentLink(result.data);
      } else {
        UTILS.errorNotification(errorHandler(errorHandler(result?.message)));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(errorHandler(error?.error)));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  });
  const redirectToPaymentLink = (paymentLink) => {
    history.push("/app/dashboard/payment-link-detail", {
      paymentLink: paymentLink,
    });
  };
  return (
    <div>
      <Spin spinning={loading || isLoading}>
        <AddPaymentLink
          handleSubmit={handleSubmit(onSubmit)}
          {...{
            stores,
            register,
            Controller,
            errors,
            control,
            getValues,
            watch,
            setValue,
            trigger,
          }}
        />
      </Spin>
    </div>
  );
};

export default CreatePaymentLinks;
