import { Input } from "antd";
import React from "react";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import { CONSTANTS as C } from "config";
import Dragger from "antd/lib/upload/Dragger";
import s from "./branding.module.css";
import ColorPicker from "components/ColorPicker";
import FaviconUpload from "./FaviconUpload";

const BrandingPlatformCustomizeAndWidget = ({
  setSettings,
  settings,
  orderNowPopupConfig,
  setOrderNowPopupConfig,
  handleChange,
  onPreview,
  uploadButton,
  beforeUpload,
}) => {
  const { t } = useTranslation();

  const handleValues = (key, val) => {
    setSettings((prev) => ({
      ...prev,
      [key]: val,
    }));
  };
  const handleConfig = (key, val) => {
    setOrderNowPopupConfig((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  return (
    <div className={s.designleft}>
      <h1 className={s.heading}>{t("Platform customization")}</h1>
      <p className={s.para}>
        {t(
          "Change the appearance of your ordering platform and make it look like your business."
        )}
      </p>
      <div className="">
        <label className="mt-10 text-gray-600 block text-14px font-bold">
          {t("Cover image")}
        </label>
        <h3 className="text-gray-500">
          {t(
            "2 MB is the maximum size allowed. In order to get the best results, keep the width (620 px) and height (350 px). Only JPEGs and PNGs are allowed."
          )}
        </h3>
        <ImgCrop
          modalTitle={t("Edit Image")}
          modalOk={t("Ok")}
          modalCancel={t("Cancel")}
          aspect={620 / 350}
          rotate
        >
          <Dragger
            style={{ height: 350 }}
            className="w-full max-w-lg lg:m-w-full border  border-dashed rounded"
            name="image"
            showUploadList={false}
            headers={{
              Authorization: `Bearer ${UTILS.getCurrentUser().token}`,
            }}
            action={C.BASE_URL + "user/uploadFile"}
            onChange={(info) => handleChange(info, "brandImageHomeBG")}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
          >
            {settings?.brandImageHomeBG ? (
              <>
                <img
                  src={settings?.brandImageHomeBG}
                  className="rounded"
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              </>
            ) : (
              <div
                className="flex justify-center items-center flex-col"
                style={{ height: 220 }}
              >
                <svg
                  viewBox="0 0 20 20"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="#d0dbe3"
                  className="w-10 h-10 inline-block"
                >
                  <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M4.288 0A2.8 2.8 0 001.74 1.641L.236 4.95A2.741 2.741 0 00.5 7.677c.328.459.74.82 1.198 1.077V18.2A1.8 1.8 0 003.5 20h13a1.8 1.8 0 001.8-1.8V8.756c.46-.258.873-.62 1.201-1.08.57-.796.67-1.836.265-2.727l-1.503-3.308A2.8 2.8 0 0015.712 0H4.29zM16.7 9.224a3.694 3.694 0 01-2.634-.887l-.531-.455c-.161.14-.354.294-.575.448-.693.486-1.729 1.02-2.958 1.02-1.23 0-2.266-.534-2.96-1.02a7.14 7.14 0 01-.574-.448l-.53.455a3.694 3.694 0 01-2.638.887V18.2c0 .11.09.2.2.2H7V15a3 3 0 116 0v3.4h3.5a.2.2 0 00.2-.2V9.224zM3.196 2.304A1.2 1.2 0 014.288 1.6h11.425a1.2 1.2 0 011.092.703l1.504 3.308c.168.371.126.804-.11 1.136a2.112 2.112 0 01-3.093.375l-1.692-1.45-.52.606-.007.008-.038.042a5.553 5.553 0 01-.807.692c-.557.39-1.271.73-2.041.73s-1.485-.34-2.042-.73a5.544 5.544 0 01-.845-.734l-.008-.009-.519-.605-1.692 1.45a2.112 2.112 0 01-3.092-.375 1.141 1.141 0 01-.11-1.136l1.503-3.308zM11.4 18.4V15a1.4 1.4 0 10-2.8 0v3.4h2.8z"
                  ></path>
                </svg>
                <div className="text-gray-500">
                  {"Drag and drop cover image or"} <br />
                  <span className="text-blue-400">{"browse"}</span>{" "}
                  {"to upload"}
                </div>
              </div>
            )}
          </Dragger>
        </ImgCrop>
      </div>
      <div className="flex flex-col 490x:flex-row">
        <div className="pb-2 me-0 md:me-1 w-full md:w-1/2">
          <label className="mt-10 text-gray-600 block text-14px font-bold">
            {t("Text color")}
          </label>
          <div className="border flex p-1">
            <div className="px-2 flex items-center">
              <ColorPicker
                onChange={handleValues}
                colorValue={settings?.textColor}
                pkey="textColor"
              />
            </div>
            <div className=" flex items-center" dir="ltr">
              {settings?.textColor}
            </div>
          </div>
        </div>
        <div className="pb-2 me-0 md:me-1 w-full md:w-1/2">
          <label className="mt-2 490x:mt-10 text-gray-600 block text-14px font-bold">
            {t("Base color")}
          </label>
          <div className="border flex p-1">
            <div className="px-2 flex items-center">
              <ColorPicker
                onChange={handleValues}
                colorValue={settings?.color}
                pkey="color"
              />
            </div>
            <div className=" flex items-center" dir="ltr">
              {settings?.color}
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 w-full">
        <div className="flex justify-between">
          <label className="text-gray-600 font-bold text-14px">
            {t("Tag line")}
          </label>
          <label className="text-14px text-gray-400">
            {t("Max 65 Charaters Allowed")}
          </label>
        </div>
        <Input
          maxLength={65}
          compact
          placeholder={t("Enter Tagline")}
          value={settings?.taglineEN}
          className="py-2 rounded"
          onChange={(event) => {
            const inputValue = event.target.value;
            if (inputValue.length <= 65) {
              handleValues("taglineEN", inputValue);
            } else {
              handleValues("taglineEN", inputValue.substring(0, 65));
            }
          }}
        />
      </div>

      <FaviconUpload
        {...{ onPreview, handleChange, beforeUpload, settings, uploadButton }}
        name="brandLogoFirst"
        title={t("Brand logo")}
        description={t(
          "2 MB is the maximum size allowed. In order to get the best results, keep the width (160 px) and height (160 px). Only JPEGs and PNGs are allowed."
        )}
      />

      <FaviconUpload
        {...{ onPreview, handleChange, beforeUpload, settings, uploadButton }}
        name="brandFavicon"
        title={t("Favicon")}
        description={t(
          "2 MB is the maximum size allowed. In order to get the best results, keep the width (16 px) and height (16 px). Only JPEGs and PNGs are allowed."
        )}
      />

      {/*  */}
      <div className="mb-4 mt-8 text-19px text-gray-600 font-bold">
        {t("Ordering widget")}
      </div>
      <div className="pb-2 w-full">
        <div className="flex justify-between">
          <label className="text-gray-600 font-bold text-14px">
            {t("Title")}
          </label>
          <label className="text-14px text-gray-400">
            {t("Max 10 Charaters Allowed")}
          </label>
        </div>

        <Input
          maxLength={10}
          minLength={2}
          compact
          placeholder={t("Enter title")}
          value={orderNowPopupConfig?.title}
          className="py-2 rounded"
          onChange={(event) => {
            const inputValue = event.target.value;
            if (inputValue?.length <= 10) {
              handleConfig("title", inputValue);
            } else {
              handleConfig("title", inputValue.substring(0, 10));
            }
          }}
        />
      </div>
      <div className="py-4 w-full">
        <div className="flex justify-between">
          <label className="text-gray-600 font-bold text-14px">
            {t("Message")}
          </label>
          <label className="text-14px text-gray-400">
            {t("Max 38 Charaters Allowed")}
          </label>
        </div>

        <Input
          maxLength={38}
          compact
          placeholder={t("Enter message")}
          value={orderNowPopupConfig?.message}
          className="py-2 rounded"
          onChange={(event) => {
            const inputValue = event.target.value;
            if (inputValue?.length <= 38) {
              handleConfig("message", inputValue);
            } else {
              handleConfig("message", inputValue.substring(0, 38));
            }
          }}
        />
      </div>

      <div className="py-4 w-full">
        <div className="flex justify-between">
          <label className="text-gray-600 font-bold text-14px">
            {t("Button text")}
          </label>
          <label className="text-14px text-gray-400">
            {t("Max 10 Charaters Allowed")}
          </label>
        </div>

        <Input
          maxLength={10}
          compact
          placeholder={t("Enter button text")}
          value={orderNowPopupConfig?.buttonText}
          className="py-2 rounded"
          onChange={(event) => {
            const inputValue = event.target.value;
            if (inputValue?.length <= 10) {
              handleConfig("buttonText", inputValue);
            } else {
              handleConfig("buttonText", inputValue.substring(0, 10));
            }
          }}
        />
      </div>
    </div>
  );
};

export default BrandingPlatformCustomizeAndWidget;
