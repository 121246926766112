import useGetMenus from "./useGetMenus";
import { UTILS } from "utils";
import { LoaderAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import { useDispatch } from "react-redux";
import { API } from "api";

function useDeleteMenu() {
  const dispatch = useDispatch();

  const getAllMenus = useGetMenus();

  const menuDelete = async ({ menuId }) => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const brandId = UTILS.getCurrentUser()?.brandId;
      const data = await API.deleteMenu({
        menuId,
        brandId,
      });
      if (data?.success === 1) {
        UTILS.successNotification(data?.message);
        await getAllMenus();
      } else {
        UTILS.handleErrorNotification(errorHandler(data));
      }
    } catch (err) {
      UTILS.handleErrorNotification(errorHandler(err?.error));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };

  return menuDelete;
}

export default useDeleteMenu;
