import React, { useEffect, useState } from "react";
import "moment-timezone";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CONSTANTS as C } from "config";
import PriceInput from "components/useForm/PriceInput";

const LoyaltyForm = ({
  onsubmit,
  register,
  errors,
  watch,
  actionType,
  trigger,
  setValue,
}) => {
  const { t } = useTranslation();
  const [welcomeBonus, setWelcomeBonus] = useState(true);
  const isLoyaltyActive = watch("isLoyaltyActive");
  const isWelcomeBonusActive = watch("welcomeBonus.isActive");
  const handleWelcomeBonus = () => {
    setWelcomeBonus(!welcomeBonus);
  };
  useEffect(() => {
    trigger("welcomeBonus.value");
  }, [isWelcomeBonusActive, trigger, welcomeBonus]);
  const handleValues = (key, value) => {
    setValue(key, value);
    trigger(key);
  };

  return (
    <form onSubmit={onsubmit}>
      <div className="w-full md:w-9/12 lg:w-10/12 xl:w-8/12 discount-form">
        <div className="flex justify-between items-center lg:w-2/3">
          <h1 className="text-gray-900 text-24px md:text-30px font-medium">
            {actionType === C.LOYALTY_CONSTANTS.ADD_OP
              ? t("Add Loyalty")
              : t("Update Loyalty")}
          </h1>
        </div>

        <h3 className="text-gray-500 pb-4">
          {t("Enable and manage a loyalty program for your customers.")}
        </h3>
        <div className="py-2">
          <div className="flex items-center">
            <div className="flex items-center">
              <label
                htmlFor="loyalty status"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    type="checkbox"
                    id="loyalty status"
                    className="sr-only"
                    {...register("isLoyaltyActive")}
                  />
                  <div
                    className={`block  w-16 h-8 rounded-full ${
                      isLoyaltyActive ? "bg-theme-green" : "bg-gray-300"
                    }`}
                  ></div>
                  <div
                    className={`dot absolute  top-1 bg-white w-6 h-6 rounded-full transition ${
                      isLoyaltyActive ? "right-1" : "left-1"
                    }`}
                  ></div>
                </div>
                <div className="text-gray-600 text-14px ms-3"></div>
              </label>
            </div>
            <span className="ms-2 text-gray-900 font-semibold">
              {isLoyaltyActive
                ? t("Loyalty Activated")
                : t("Loyalty Deactivated")}
            </span>
          </div>
        </div>

        <div className="lg:w-2/3">
          <h1 className="text-gray-900  text-17px pt-8 font-semibold">
            {t("Earning")}
          </h1>
          <h2 className="text-gray-500">
            {t("How much credit your customers get with each order.")}
          </h2>
          <div className="py-2 w-full md:w-1/2">
            <PriceInput
              label={t("Cashback (%)")}
              placeholder={t("Cashback (%)")}
              name="specificToDelivery.cashbackPercentage"
              register={register}
              mandatory={true}
              rules={{
                required: t("Cashback(%) is a required field"),
                min: {
                  value: 1,
                  message: t("Cashback(%) should be greater than 0"),
                },
                max: {
                  value: 100,
                  message: t("Cashback(%) should be less than 100"),
                },
              }}
              error={errors?.specificToDelivery?.cashbackPercentage?.message}
              keyVal="specificToDelivery.cashbackPercentage"
              handleValues={handleValues}
            />
          </div>

          <div className="my-8">
            <h1 className="text-gray-900 text-17px font-semibold pt-4">
              {t("Spending")}
            </h1>
            <h2 className="text-gray-500 pb-2">
              {t(
                "Set the maximum limits for redeeming credit. The system will always apply the lowest"
              )}
            </h2>

            <div className="flex flex-col md:flex-row">
              <div className="py-1 me-0 md:me-1 w-full md:w-1/2">
                <PriceInput
                  label={t("Redeemable(%)")}
                  placeholder={t("Redeemable(%)")}
                  name="redeemablePercentagePerOrder"
                  register={register}
                  mandatory={true}
                  rules={{
                    required: t("Redeemable(%) is a required field"),
                    min: {
                      value: 0,
                      message: t("Redeemable(%) should be greater or equal 0"),
                    },
                    max: {
                      value: 99,
                      message: t("Redeemable(%) should be less or equal to 99"),
                    },
                  }}
                  error={errors?.redeemablePercentagePerOrder?.message}
                  keyVal="redeemablePercentagePerOrder"
                  handleValues={handleValues}
                />
              </div>
              <div className="py-1 w-full md:w-1/2">
                <PriceInput
                  label={t("Max redeemable value")}
                  placeholder={t("Max redeemable value")}
                  name="maxRedeemableValuePerOrder"
                  register={register}
                  mandatory={true}
                  rules={{
                    required: t("Max redeemable value is a required field"),
                    min: {
                      value: 0,
                      message: t(
                        "Max redeemable value should be greater or equal  0"
                      ),
                    },
                  }}
                  error={errors?.maxRedeemableValuePerOrder?.message}
                  keyVal="maxRedeemableValuePerOrder"
                  handleValues={handleValues}
                />
              </div>
            </div>
          </div>

          <h1 className="text-gray-900 text-17px font-semibold pt-4">
            {t("Loyalty welcome bonus")}
          </h1>
          <h2 className="text-gray-500 pb-2">
            {t("Give your first time customers a sign in cashback bonus.")}
          </h2>
          <div className="py-2" onClick={handleWelcomeBonus}>
            <div className="flex items-center">
              <div className="flex items-center">
                <label
                  htmlFor="welcomeBonus"
                  className="flex items-center cursor-pointer"
                >
                  <div className="relative">
                    <input
                      id="welcomeBonus"
                      type="checkbox"
                      className="sr-only"
                      {...register("welcomeBonus.isActive")}
                    />
                    <div
                      className={`block  w-16 h-8 rounded-full ${
                        isWelcomeBonusActive ? "bg-theme-green" : "bg-gray-300"
                      }`}
                    ></div>
                    <div
                      className={`dot absolute  top-1 bg-white w-6 h-6 rounded-full transition ${
                        isWelcomeBonusActive ? "right-1" : "left-1"
                      }`}
                    ></div>
                  </div>
                  <div className="text-gray-600 text-14px ms-3"></div>
                </label>
              </div>
              <span className="ms-2 text-gray-900 font-semibold">
                {t("Welcome bonus credit")}
              </span>
            </div>
          </div>

          <div className="py-2 w-full md:w-1/2">
            <PriceInput
              label={t("Credit value")}
              name="welcomeBonus.value"
              register={register}
              mandatory={true}
              rules={{
                required: isWelcomeBonusActive
                  ? t("Credit value is a required field")
                  : false,

                min: {
                  value: isWelcomeBonusActive ? 1 : 0,
                  message: isWelcomeBonusActive
                    ? t("Credit value should be greater or equal 1")
                    : false,
                },
              }}
              error={errors?.welcomeBonus?.value?.message}
              keyVal="welcomeBonus.value"
              handleValues={handleValues}
            />
          </div>
        </div>

        <div className="my-4">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="rounded-sm font-semibold w-36"
          >
            {actionType === C.LOYALTY_CONSTANTS.EDIT_OP && t("Update")}
            {actionType === C.LOYALTY_CONSTANTS.ADD_OP &&
              isLoyaltyActive &&
              t("Create")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default LoyaltyForm;
