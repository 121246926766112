import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "redux/actions";
import { errorNotification } from "utils/Utils";
import { successNotification } from "utils/Utils";
import { UTILS } from "utils";
import useGetStoreAddedMappedAreas from "./useGetStoreAddedMapAreas";

function useToggleStatus() {
  const dispatch = useDispatch();
  const getAddedPolygonBasedAreas = useGetStoreAddedMappedAreas();
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const toggleZoneStatus = async ({ payload, onClose }) => {
    try {
      const userDetail = UTILS.getCurrentUser();

      dispatch(LoaderAction.setLoading(true));
      const response = await API.togglePolyonAreaStatus({
        ...payload,
        storeId: selectedStore.value,
        brandId: userDetail.brandId,
      });

      if (response?.success === 1 && response?.data) {
        successNotification(response?.message);
        await getAddedPolygonBasedAreas({
          storeId: selectedStore?.value,
          isPolygonRequired: false,
        });
        onClose();
      } else {
        console.log("error", response);
        errorNotification(errorHandler(response?.message));
      }
    } catch (error) {
      console.log("error", error);
      errorNotification(errorHandler(error?.error?.message));
    } finally {
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return toggleZoneStatus;
}

export default useToggleStatus;
