import React from "react";
import PropTypes from "prop-types";
import { SVG_Business } from "svg/SVGs";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

const AddStoreModel = (props) => {
  const { t } = useTranslation();

  return (
    <div className="w-full p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      <div className="">
        <div className="text-center p-5 flex-auto justify-center">
          <SVG_Business />
          <h2 className="text-xl font-bold py-4 ">{t("Add Store")}</h2>
          <p className="text-sm text-gray-500 px-8">
            {t("Please Add Store to get the full user experiences")}
          </p>
        </div>
        <div className="p-3  mt-2 text-center space-x-4 md:block">
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={props.onClose}
            className="w-36"
          >
            {t("Ok")}
          </Button>
        </div>
      </div>
    </div>
  );
};

AddStoreModel.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
};
export default AddStoreModel;
