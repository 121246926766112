import React, { useEffect, useState } from "react";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import ProfileNumberUpdate from "../components/ProfileNumberUpdate";
import ProfilePasswordUpdate from "../components/ProfilePasswordUpdate";
import { useSelector } from "react-redux";

const Profile = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  useEffect(() => {
    const userDetail = UTILS.getCurrentUser();
    setUser(userDetail);
  }, []);
  return (
    <div className="px-8 py-4">
      <h1 className="text-blue-600 text-19px pt-4">{t("My profile")}</h1>
      <h3 className="text-gray-500  pb-4">
        {t("Manage your personal information and security of your account", {
          company:
            currentLanguage === "EN"
              ? process.env.REACT_APP_COMPANY_NAME_EN
              : process.env.REACT_APP_COMPANY_NAME_AR,
        })}
      </h3>
      <div className="w-full md:w-1/2 lg:w-1/3 ">
        <div className="my-4">
          <h3 className="text-19px font-medium ">{t("Email")}</h3>
          <p className="text-gray-500">
            <span>
              {t("Your current email address is")}{" "}
              <strong>{user?.email}</strong>
            </span>
          </p>
        </div>
        <ProfilePasswordUpdate />
        <ProfileNumberUpdate />
      </div>
    </div>
  );
};

export default Profile;
