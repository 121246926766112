import { Spin, Table } from "antd";
import Card from "components/Card";
import React, { useState } from "react";
import SelectInput from "components/SelectInput";
import { useTranslation } from "react-i18next";
import SearchInput from "components/SearchInput";
import PlayPause from "./PlayPause";

const PauseModifiers = ({
  state,
  handlePlayPause,
  handleChange,
  isLoading,
  stores,
}) => {
  console.log({ PauseModifiers: state });
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState(null);

  const columns = [
    {
      title: t("Name"),
      dataIndex: "baseLanguage",
      align: "initial",
      sorter: (a, b) =>
        a.baseLanguage.name?.localeCompare(b.baseLanguage?.name),
      render: (val, row, index) => {
        return (
          <h3 className="text-16px font-bold truncate-line-4 min-230">
            {val?.name}
          </h3>
        );
      },
    },

    {
      title: t("Availability"),
      width: "25%",
      align: "initial",
      dataIndex: "_id",
      render: (val, row, index) => {
        return (
          <PlayPause
            {...{
              rowData: row,
              state,
              handlePlayPause,
            }}
          />
        );
      },
    },

    {
      title: t("Maximum Order Quantity"),
      width: "10%",
      dataIndex: "maximumOrderQuantity",
      align: "initial",
      sorter: {
        compare: (a, b) => a.maximumOrderQuantity - b.maximumOrderQuantity,
        multiple: 3,
      },
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: "10%",
      align: "initial",
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 3,
      },
      render: (val, row, index) => {
        return (
          <span className="whitespace-nowrap">{`${t(
            "AED"
          )}${"  "} ${val}`}</span>
        );
      },
    },
  ];

  const ModifierListArr = React.useMemo(() => {
    if (searchTerm === "" || searchTerm === null) {
      return state?.modifiers;
    }
    let searchTermLower = searchTerm.toLowerCase().trim();
    return state?.modifiers.filter((item) =>
      item?.baseLanguage?.name?.toLowerCase()?.includes(searchTermLower)
    );
  }, [state?.modifiers, searchTerm]);

  return (
    <>
      <div className="m-5 mx-4 md:mx-5">
        <Card>
          <div className="px-2 py-2">
            <Spin spinning={isLoading}>
              <h3 className="text-gray-500">
                {t(
                  "Select store from the dropdown to play/pause inventory for particular store"
                )}
              </h3>
              <h2 className="text-17px text-blue-600 ">{t("Select store")}</h2>
              <div className="flex flex-col sm:flex-row justify-between">
                <div className="flex w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4">
                  <SelectInput
                    keyVal="brandId"
                    handleChange={handleChange}
                    options={stores}
                    value={stores._id}
                    styleClass="font-montserrat text-input-color text-16px mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <SearchInput
                    placeholder={t("Search by name")}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </div>
              </div>
              <Card styleClasses="px-2 py-2 overflow-x-auto lg:overflow-hidden">
                <Table
                  locale={{ emptyText: t("No Data") }}
                  columns={columns}
                  dataSource={ModifierListArr}
                  pagination={{ position: ["bottomCenter"] }}
                  size="small"
                />
              </Card>
            </Spin>
          </div>
        </Card>
      </div>
    </>
  );
};
export default PauseModifiers;
