import React, { useState } from "react";
import PropTypes from "prop-types";
import { RedoOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
import { UTILS } from "utils";
import { Spin } from "antd";
import { API } from "api";

import OrderTabs from "../components/OrderTabs";
import OrdersPanel from "../components/OrdersPanel";
import useOrder from "hooks/useOrder";
// import { LogisticAction } from "redux/actions";
import OrderRejectForm from "../components/OrderRejectForm";
import { errorHandler } from "api/api/errorHandler";
import LocalModal from "../modals/LocalModal";
// import { useOrderMetaDataContext } from "store/contexts/OrderMetaDataContext";
import useRefundableOrder from "hooks/useRefundableOrders";
import useGetORderByStatus from "../hooks/useGetORderByStatus";

const LiveOrders = () => {
  // const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  // const { revalidate } = useOrderMetaDataContext();
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState({
    values: {
      Modal: false,
      reason: null,
      isRefundRequired: false,
      type: null,
    },
    errors: {
      Modal: null,
      reason: null,
      logisticPartner: null,
    },
  });
  const [tabStatus, setTabStatus] = useState("PENDING");
  const [currentOrderId, setCurrentOrderId] = useState();
  const audio = new Audio(
    "https://uapp-live-com.s3.amazonaws.com/UAPP-MEDIA/PENDING_ORDER_MUSIC.mpeg"
  );
  const { t } = useTranslation();
  const { data } = useOrder();

  const { data: refundableOrdersCountData, revalidate: refundableMutate } =
    useRefundableOrder();

  const getPendingOrders = (status) => {
    if (
      UTILS.isValidObject(["pendingOrdersCount"], data?.data) &&
      Array.isArray(data?.data.pendingOrdersCount)
    ) {
      for (
        let index = 0;
        index < data?.data.pendingOrdersCount.length;
        index++
      ) {
        const element = data?.data.pendingOrdersCount[index];
        if (
          UTILS.isValidObject(["orderStatus"], element) &&
          element.orderStatus === status
        ) {
          return element.count;
        }
      }
      return 0;
    }
  };

  const getRefundableOrders = () => {
    if (
      UTILS.isValidObject(["refundableOrdersCount"], data?.data) &&
      Array.isArray(data?.data.refundableOrdersCount)
    ) {
      return data?.data.refundableOrdersCount.reduce((acc, statusCount) => {
        return statusCount.count + acc;
      }, 0);
    }
  };

  const checkPendingOrdersCount = (status) => {
    const count = getPendingOrders(status);
    if (count > 0) {
    }
    return count;
  };

  const checkRefundableOrdersCount = (status) => {
    const count = getRefundableOrders();
    if (count > 0) {
    }
    return count;
  };

  checkRefundableOrdersCount();

  checkPendingOrdersCount("PENDING");

  const getOrdersByStatus = useGetORderByStatus(
    setLoading,
    tabStatus,
    setOrders,
    setCurrentOrderId
  );

  // const getOrdersByStatus = async (type) => {
  //   try {
  //     setLoading(true);

  //     const userDetail = UTILS.getCurrentUser();
  //     const result = await API.GET_ORDER_BY_STATUS({
  //       orderStatus: tabStatus,
  //       token: userDetail.token,
  //     });
  //     if (result?.success === 1 && result?.data?.orders) {
  //       setOrders(result.data.orders);
  //       if (result?.data?.orders?.length > 0) {
  //         setCurrentOrderId(result.data.orders[0]._id);
  //         dispatch(LogisticAction.setOrderInfo(result.data.orders[0]));
  //       } else {
  //         dispatch(LogisticAction.setOrderInfo({}));
  //       }
  //     } else if (result.status === 1) {
  //       UTILS.successNotification(t("There are no orders"));
  //     } else {
  //       UTILS.errorNotification(t("Please try again later!"));
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //     type === "SCHEDULED" &&
  //       revalidate("order/get-order-meta-data-info", { revalidate: true });
  //   }
  // };

  const validateRejectReason = (type) => {
    if (type === "REJECT") {
      if (!rejectReason?.values?.reason) {
        handleErrors("reason", t("Reason is a required field"));
        return false;
      }
      if (rejectReason?.values?.reason?.trim()?.length <= 2) {
        handleErrors("reason", t("Reason must be atleast 3 characters long"));
        return false;
      }
      return true;
    }
    return true;
  };

  const onSubmit = async (status, type) => {
    try {
      if (!validateRejectReason(type)) return;
      const userDetail = UTILS.getCurrentUser();
      setLoading(true);
      let obj = {
        brandId: userDetail.brandId,
        token: userDetail.token,
        orderId: currentOrderId,
        isRefundRequired: rejectReason.values?.isRefundRequired,
        orderStatus: type === "REJECT" ? rejectReason.values?.type : status,

        ...(type === "REJECT" &&
          rejectReason.values?.type === "REJECTED" && {
            cancelOrderReason: null,
            rejectedOrderReason: rejectReason.values.reason,
          }),
        ...(type === "REJECT" &&
          rejectReason.values?.type === "CANCELLED" && {
            cancelOrderReason: rejectReason.values.reason,
            rejectedOrderReason: null,
          }),
        language: "EN",
      };
      const result = await API.SET_ORDER_STATUS(obj);
      if (result?.success === 1) {
        if (type === "REJECT") {
          setRejectReason((prevState) => ({
            ...prevState,
            values: {
              ...prevState.values,
              Modal: false,
              reason: null,
              isRefundRequired: false,
            },
          }));
          refundableMutate("order/get-refundable-orders-count", {
            revalidate: true,
          });
        }
        UTILS.successNotification(t("Order Updated!"));
      } else {
        UTILS.errorNotification(errorHandler(result?.error));
      }
      if (status === "PREPARING") audio.pause();
      await getOrdersByStatus("SCHEDULED");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleValues = (key, value) => {
    setRejectReason((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: value,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };

  const handleErrors = (key, value) => {
    setRejectReason((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
      },
      errors: {
        ...prevState.errors,
        [key]: value,
      },
    }));
  };
  return (
    <div className="">
      <div className="h-full">
        <Spin spinning={loading}>
          <div className="mb-2 mx-2 flex justify-end">
            <button
              className="me-2 px-2 py-1 bg-white text-blue-500 border border-blue-500"
              onClick={() => getOrdersByStatus("PENDING")}
            >
              <RedoOutlined />
              <span className="ps-1">{t("Load New Orders")}</span>
            </button>
          </div>
          <OrderTabs
            orderCountData={data?.data}
            refundableOrdersCountData={refundableOrdersCountData}
            refundableMutate={refundableMutate}
            handleTabStatus={(status) => {
              setTabStatus(status);
            }}
            loading={loading}
          />
          <OrdersPanel
            handleSound={(state) => audio.pause()}
            {...{
              tabStatus,
              setLoading,
              loading,
              orders,
              getOrdersByStatus,
              currentOrderId,
              setCurrentOrderId,
              setRejectReason,
              order,
              setOrder,
              onSubmit,
            }}
            refundableMutate={refundableMutate}
          />

          <LocalModal
            open={rejectReason.values.Modal}
            showScroll={true}
            modalWidth="w-1/3 rounded-md border border-gray-300 p-8"
            onClose={() => setRejectReason}
          >
            <OrderRejectForm
              loading={loading}
              currentOrderId={currentOrderId}
              order={order}
              handleErrors={handleErrors}
              closeModal={() => {
                setRejectReason((prevState) => ({
                  ...prevState,
                  values: {
                    Modal: false,
                    reason: null,
                    isRefundRequired: false,
                  },
                  errors: {
                    reason: null,
                  },
                }));
              }}
              handleValues={handleValues}
              {...{ rejectReason, onSubmit }}
            />
          </LocalModal>
        </Spin>
      </div>
    </div>
  );
};
LiveOrders.propTypes = {
  status: PropTypes.string,
  color: PropTypes.string,
};
export default LiveOrders;
