import React from "react";
import { Button, Select } from "antd";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

const ChooseTime = ({ time, setTime }) => {
  const { t } = useTranslation();

  const resetState = () => {
    setTime({ Days: 0, Hours: 0, Minutes: 0 });
  };
  const Days = [
    {
      value: 1,
      label: 1 + i18n.t("day"),
    },
  ];

  Array.from({ length: 39 }, (_, x) =>
    Days.push({
      value: x + 2,
      label: x + 2 + i18n.t("days"),
    })
  );

  const Hours = [
    {
      value: 1,
      label: 1 + i18n.t("hour"),
    },
  ];
  Array.from({ length: 23 }, (_, x) =>
    Hours.push({
      value: x + 2,
      label: x + 2 + i18n.t("hours"),
    })
  );
  const Minutes = [
    {
      value: 1,
      label: 1 + i18n.t("minute"),
    },
  ];
  Array.from({ length: 59 }, (_, x) =>
    Minutes.push({
      value: x + 2,
      label: x + 2 + i18n.t("minutes"),
    })
  );
  const selectDay = (value) => {
    setTime({ ...time, Days: value });
  };
  const selectHours = (value) => {
    setTime({ ...time, Hours: value });
  };
  const selectMinutes = (value) => {
    setTime({ ...time, Minutes: value });
  };

  const getSelectedTime = () => {
    let dd = 0,
      hh = 0;
    let button = "";

    if (time?.Days === 0 && time?.Hours === 0 && time?.Minutes === 0) {
      button = i18n.t("Select time");
    }

    if (time?.Days > 0) {
      if (time?.Days === 1) {
        dd = time.Days;
        button += time.Days + i18n.t("day");
      }
      if (time?.Days >= 2) {
        dd = time.Days;
        button += time.Days + i18n.t("days");
      }
    }
    if (time?.Hours > 0) {
      button += dd >= 1 ? ", " : "";
      if (time?.Hours === 1) {
        hh = time.Hours;
        button += time.Hours + i18n.t("hour");
      }
      if (time?.Hours >= 2) {
        hh = time.Hours;
        button += time.Hours + i18n.t("hours");
      }
    }
    if (time?.Minutes > 0) {
      button += hh >= 1 ? ", " : "" || dd >= 1 ? "," : "";
      if (time?.Minutes === 1) {
        button += time.Minutes + i18n.t("minute");
      }
      if (time?.Minutes >= 2) {
        button += time.Minutes + i18n.t("minutes");
      }
    }

    return button;
  };
  return (
    <>
      <div className="w-full lg:w-1/2">
        <div className="flex flex-col choose-time">
          <div>
            <label className="font-medium text-gray-600 block text-sm mt-1">
              {i18n.t("Delivery Time")} <span className="text-red-600"> *</span>
            </label>
          </div>
          <div className="flex wrap-con border border-gray-200">
            <div className="w-full ">
              <div className="scrollbar overflow-y-auto">
                <Select
                  style={{ width: "150px" }}
                  value={time?.Days > 0 ? time?.Days : i18n.t("select days")}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  showSearch
                  size="large"
                  placeholder={i18n.t("select days")}
                  optionFilterProp="children"
                  onChange={selectDay}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={Days}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="scrollbar overflow-y-auto">
                <Select
                  value={time?.Hours > 0 ? time?.Hours : i18n.t("select hours")}
                  style={{ width: "150px" }}
                  showSearch
                  size="large"
                  placeholder={i18n.t("select hours")}
                  optionFilterProp="children"
                  onChange={selectHours}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  options={Hours}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="scrollbar overflow-y-auto">
                <Select
                  value={
                    time?.Minutes > 0 ? time?.Minutes : i18n.t("select minutes")
                  }
                  style={{ width: "150px" }}
                  size="large"
                  showSearch
                  placeholder={i18n.t("select minutes")}
                  optionFilterProp="children"
                  onChange={selectMinutes}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  options={Minutes}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        <div className="flex flex-col choose-time w-full">
          <div className="mt-1 h-0 sm:h-5 w-full"></div>
          <div className="flex items-center justify-between w-full flex-wrap 440x:flex-nowrap">
            <Button
              type="primary"
              className="border w-full me-0 440x:me-2 h42 ms-0 md:ms-4"
              size="large"
            >
              {getSelectedTime()}
            </Button>

            <Button
              type="primary"
              onClick={resetState}
              className=" border w-full mt-1 440x:mt-0 h42 ms-0 md:ms-4"
              size="large"
            >
              {t("Reset Time")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseTime;
