import { Modal, Spin } from "antd";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuAction } from "redux/actions";
import { LoaderAction } from "redux/actions";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
import { CloudUploadOutlined } from "@material-ui/icons";

function BulkMenu({ open, setOpen }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const uploadFile = async (e) => {
    e.preventDefault();
    setError(null);
    if (!file) {
      UTILS.errorNotification(t("Please select a file"));
      return;
    }
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setError(t("Please select a valid excel file"));
      return;
    }
    dispatch(LoaderAction.setLoading(true));
    const userDetail = UTILS.getCurrentUser();
    try {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("brandId", userDetail.brandId);
      const res = await API.uploadBulkItems(formData);
      if (res?.success === 1) {
        UTILS.successNotification(res?.message);
        dispatch(MenuAction.syncData());
        setOpen(false);
      } else {
        setError(errorHandler(res?.message));
      }
    } catch (err) {
      setError(errorHandler(err));
    } finally {
      setFile(null);
      if (fileInputRef?.current) {
        fileInputRef.current.value = "";
      }
      dispatch(LoaderAction.setLoading(false));
    }
  };
  return (
    <Modal
      title={t("Upload Bulk Items")}
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      footer={null}
    >
      {error && (
        <div className="text-white bg-red-500 text-center p-2 m-4 rounded">
          <p dangerouslySetInnerHTML={{ __html: error }}></p>
        </div>
      )}
      <Spin spinning={isLoading}>
        <div className="max-w-md mx-auto bg-white p-6 rounded-md shadow-md">
          <form onSubmit={uploadFile}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                <div className="flex items-center bg-gray-200 py-2 px-4 rounded cursor-pointer">
                  <CloudUploadOutlined className="mx-2" />
                  <div className="flex-grow truncate">
                    {file ? file.name : t("Select excel file")}
                  </div>
                </div>

                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  name="file"
                  id="file"
                  lang="ar"
                  accept=".xlsx, .xls"
                  className="hidden"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    setError(null);
                  }}
                />
              </label>
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {t("Submit")}
            </button>
          </form>
        </div>
      </Spin>
    </Modal>
  );
}

export default BulkMenu;
