import React from "react";
import { useSelector } from "react-redux";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";

function YangoSupport() {
  const { t } = useTranslation();
  const { orderInfo } = useSelector((state) => state?.LogisticReducer);

  if (!orderInfo?.logisticPartnerMetadata?.YANDEX?.createClaimResponse?.id)
    return (
      <h2 className="text-2xl text-center font-semibold">
        {t("Order is not yet assigned to Yango")}
      </h2>
    );
  return (
    <div>
      <table className="border-collapse border border-slate-400 w-full mt-4">
        <>
          <tr>
            <td className="border border-slate-300 p-4">
              {t("Whatsapp Number")}
            </td>
            <td className="border border-slate-300 p-4">
              <a
                dir="ltr"
                className="text-blue-500 underline"
                href={`https://wa.me/971528321710?text=Hello I need help regarding this order number. ${orderInfo?.logisticPartnerMetadata?.YANDEX?.createClaimResponse?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                +971528321710
              </a>
            </td>
          </tr>

          <tr>
            <td className="border border-slate-300 p-4">
              {t("Yango Order Number")}
            </td>
            <td
              className="border border-slate-300 p-4 text-blue-500 underline cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(
                  orderInfo?.logisticPartnerMetadata?.YANDEX
                    ?.createClaimResponse?.id
                );
                UTILS.successNotification(t("Copied to Clipboard"));
              }}
            >
              {
                orderInfo?.logisticPartnerMetadata?.YANDEX?.createClaimResponse
                  ?.id
              }
            </td>
          </tr>
        </>
      </table>
    </div>
  );
}

export default YangoSupport;
