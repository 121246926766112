import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const getLabel = (label) => {
  if (label !== "") {
    return (
      <label className="font-montserrat text-gray-600 block text-14px">
        {label}
      </label>
    );
  }
};

const SelectInput = ({
  name,
  options,
  label,
  register,
  value,
  error,
  defaultSelection,
  ...rest
}) => {
  const { t } = useTranslation();
  const mandatoryLabel = getLabel(label);
  return (
    <>
      {mandatoryLabel}
      <select
        name={name}
        ref={register}
        className={rest.styleClass}
        value={value}
        {...rest}
      >
        <option value="" disabled>
          {t("Select")}
        </option>
        {options.map((option) => (
          <option
            value={JSON.stringify(option)}
            key={option.value}
            selected={option.value === defaultSelection ? "selected" : ""}
          >
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  defaultSelection: PropTypes.string.isRequired,
  rest: PropTypes.object,
};

export default SelectInput;
