import React, { useEffect, useState } from "react";
import SectionHeading from "../../../components/SectionHeading";
import RadioButton from "../../../components/RadioButton";
import PropTypes from "prop-types";
import { CONSTANTS as C } from "config";
import { UTILS } from "utils";
import { API } from "api";
import { getI18n, useTranslation } from "react-i18next";
import Modal from "components/Modal";
import { Spin } from "antd";

const StorePaymentMethodSection = (props) => {
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const getPaymentTypes = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_PAYMENT_TYPES({
      token: userDetail.token,
    });
    if (result && result.success === 1) setPaymentTypes(result.data);
    else {
    }
    setLoading(false);
  };

  useEffect(() => {
    getPaymentTypes();
  }, []);

  const isON = (item) => {
    const arr = props?.values[props.paymentType];
    const found = arr?.find((obj) => obj === item._id);
    return found ? true : false;
  };
  const getKeyAgainstLanguage = () => {
    const lang = getI18n();
    return lang.language == "en" ? "EN" : "AR";
  };

  const checkOnlinePaymentEnabled = async (key, val) => {
    const userDetail = UTILS.getCurrentUser();
    setLoading(true);
    const result = await API.CHECK_ONLINE_PAYMENT({
      token: userDetail.token,
    });

    if (
      result?.success == 1 &&
      UTILS.isValidObject(["isAvailable"], result) &&
      result.isAvailable
    ) {
      props.handleValues(key, val);
    } else {
      setOpen(true);
    }
    setLoading(false);
  };

  const isPaymentTypeOnline = (key) => {
    const p = paymentTypes.find((obj) => obj._id == key);
    if (p.paymentType === C.PAYMENT_TYPE_ONLINE) return true;
    else return false;
  };

  const PaymentType = (item, index) => {
    return (
      <React.Fragment key={index}>
        <div className="mb-5" key={index}>
          <RadioButton
            label={
              item[getKeyAgainstLanguage()].name
                ? item[getKeyAgainstLanguage()].name
                : "No Name"
            }
            keyVal={item._id}
            value={isON(item)}
            handleValues={(key, val) => {
              if (val && isPaymentTypeOnline(key))
                checkOnlinePaymentEnabled(key, val);
              else props.handleValues(key, val);
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="col-span-2 h-1/2">
        <div className="bg-white h-full">
          <SectionHeading title={t("Supported Payment Methods?")} />
          <Spin spinning={loading}>
            <Modal
              open={open}
              modalWidth="rounded-md border border-gray-300"
              onClose={() => setOpen(false)}
            >
              <div className="w-full h-full text-center justify-center px-12">
                <h1 className="px-4 pt-4 font-bold text-16px text-blue-600">
                  {t("Online Payment")}
                </h1>
                <img
                  alt="Online Payment"
                  className="inline-block justify-center"
                  src="/images/mobile-card.png"
                  width={100}
                  height={100}
                />
                <h2 className="px-4 text-red-600">
                  {t("Online Payment Text")}
                  <span className="text-blue-600">
                    {process.env.REACT_APP_SUPPORT_EMAIL}
                  </span>
                </h2>
                <div className="p-3 mt-2 text-center space-x-4 md:block">
                  <button
                    onClick={() => setOpen(false)}
                    className="bg-white px-5 py-2 text-sm shadow-sm font-medium border text-gray-600 rounded-sm hover:shadow-lg hover:bg-gray-100 justify-center"
                  >
                    {t("Ok")}
                  </button>
                </div>
              </div>
            </Modal>
            <div className="px-5 text-blue-600 text-16px py-4">
              {t(
                "For receiving payments directly into your account please contact",
                {
                  email: process.env.REACT_APP_SUPPORT_EMAIL,
                }
              )}

              <a
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                className="text-red-600 underline ml-1"
              >
                {t("Click Here")}
              </a>
              {t(" to send an email")}
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="overflow-hidden">
                <div className="px-4 pt-5 sm:px-0">
                  {paymentTypes &&
                    paymentTypes.map((item, index) => {
                      if (item.serviceType === props.serviceType)
                        return PaymentType(item, index);
                    })}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

StorePaymentMethodSection.propTypes = {
  store: PropTypes.object,
  handleValues: PropTypes.func,
};
export default StorePaymentMethodSection;
