import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { Modal as AntModal } from "antd";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import LinkItemWithCategoryModal from "features/menus/components/LinkItemWithCategoryModal";
import LinkUnlinkItemWithStoresModal from "./LinkUnlinkItemWithStoresModal";
import { useAccordion } from "features/menu/components/AccordionN";
import DeleteComponent from "components/DeleteConfirm";
import { errorHandler } from "api/api/errorHandler";
import Modal from "components/Modal";
import ItemRow from "./ItemRow";
import { UTILS } from "utils";

import { useToggLeStoreInMenuCategoryITemMutation } from "redux/menu/service";
import { logErrorOnSentry } from "utils/Utils";
import { useDispatch } from "react-redux";
import { LoaderAction } from "redux/actions";
import { API } from "api";

const CategoryItem = ({
  setLoading,
  cat,
  connectedStores,
  menuId,
  brandItems,
  loading,
  items,
  setItems,
  getCategoryInfo,
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selected } = useAccordion();
  const [open, setOpen] = useState(false);
  const [dialogContentId, setDialogContentId] = useState(-1);
  const [itemToPassStoresDialog, setItemToPassStoreDialog] = useState();

  const DIALOG_VIEW = {
    ADD_ITEM: 0,
    STORES: 1,
  };

  const style = {
    panel: `overflow-hidden md:overflow-x-hidden transition-height ease text-gray-600 text-16px`,
  };

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    item: null,
    indexToDelete: -1,
  });
  const [isStoreItemModalOpen, setisStoreItemModalOpen] = useState(false);
  const inlineStyle =
    selected === cat?._id
      ? { height: ref.current?.scrollHeight }
      : { height: 0 };

  const renderDialogContent = () => {
    switch (dialogContentId) {
      case DIALOG_VIEW.ADD_ITEM:
        return (
          <LinkItemWithCategoryModal
            onClose={() => setOpen(false)}
            open={open}
            {...{
              cat,
              menuId,
              brandItems,
              getCategoryInfo,
              items,
            }}
          />
        );
      default:
        break;
    }
  };
  const handleStores = (item, index) => {
    setItemToPassStoreDialog(item);
    setisStoreItemModalOpen(true);
  };

  const [toggLeStoreInMenuCategoryITem] =
    useToggLeStoreInMenuCategoryITemMutation();

  const removeItemFromStore = async (sId, itemId, val, selectedCatory) => {
    try {
      const userDetail = UTILS.getCurrentUser();
      setLoading(true);
      dispatch(LoaderAction.setLoading(true));

      const result = await toggLeStoreInMenuCategoryITem({
        brandId: userDetail.brandId,
        menuId,
        categoryId: selectedCatory._id,
        itemId,
        storeId: sId,
      });

      if (result?.data?.success) {
        let arr = [...itemToPassStoresDialog?.unConnectedStoreIds];
        if (!val) {
          //add
          arr.push(sId);
          setItemToPassStoreDialog((prevState) => ({
            ...prevState,
            unConnectedStoreIds: arr,
          }));
        } else {
          //remove
          arr.splice(
            arr.findIndex((id) => id === sId),
            1
          );
          setItemToPassStoreDialog((prevState) => ({
            ...prevState,
            unConnectedStoreIds: arr,
          }));
        }
        await getCategoryInfo(selectedCatory?._id);
      } else {
        UTILS.errorNotification(errorHandler(result?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      setLoading(false);
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const reOrderCategory = async (order) => {
    setLoading(true);
    dispatch(LoaderAction.setLoading(true));
    try {
      let item = items[order.oldIndex];
      const userDetail = UTILS.getCurrentUser();
      const result = await API.sortItemInCategory({
        brandId: userDetail.brandId,
        menuId,
        categoryId: cat._id,
        itemId: item.itemId._id,
        index: order.newIndex,
      });
      if (result?.success === 1) {
        let arr = [...items];
        arr.splice(order.oldIndex, 1);
        arr.splice(order.newIndex, 0, item);
        setItems(arr);
      } else {
        UTILS.errorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
      dispatch(LoaderAction.setLoading(false));
    }
  };

  const unLinkMenuItemFromCategory = async () => {
    try {
      const brandId = UTILS.getCurrentUser()?.brandId;
      if (!deleteModal?.item?._id || !cat?._id || !menuId) {
        setDeleteModal({ isOpen: false, item: null, indexToDelete: -1 });
        logErrorOnSentry(
          `unLinkMenuItemFromCategory ${brandId}`,
          "error",
          deleteModal
        );
        UTILS.errorNotification(
          t("We're sorry, but something went wrong. Please try again")
        );
        return;
      }
      setLoading(true);
      const result = await API.unLinkItemWithCategory({
        brandId,
        categoryId: cat?._id,
        itemId: deleteModal?.item?._id,
        menuId,
      });

      if (result?.success === 1) {
        let arr = [...items];
        arr.splice(deleteModal.indexToDelete, 1);
        setItems(arr);
        setDeleteModal({ isOpen: false, item: null, indexToDelete: -1 });
        UTILS.successNotification(result?.message || "Item Unlinked");
      } else UTILS.errorNotification(errorHandler(result?.message));
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCancel = () =>
    setDeleteModal({ isOpen: false, item: null });

  const onClickDeleteButton = (item, index) => {
    if (item) {
      setDeleteModal({
        isOpen: true,
        item: item,
        indexToDelete: index,
      });
    }
  };

  return (
    <div ref={ref} id={cat?._id} className={style.panel} style={inlineStyle}>
      <Modal
        scroll={dialogContentId === 0 ? false : true}
        open={open}
        modalWidth="w-full md:w-3/4 rounded-md border border-gray-300"
        onClose={() => setOpen(false)}
      >
        {renderDialogContent()}
      </Modal>
      <Modal
        open={deleteModal.isOpen}
        modalWidth="rounded-md border border-gray-300 top-0"
        onClose={handleDeleteCancel}
      >
        <DeleteComponent
          loading={loading}
          onCancel={handleDeleteCancel}
          onDelete={unLinkMenuItemFromCategory}
          title={deleteModal.item?.baseLanguage?.title}
          Heading={t("will be permanently unlinked")}
        />
      </Modal>
      <AntModal
        open={isStoreItemModalOpen}
        onCancel={() => {
          setisStoreItemModalOpen(false);
        }}
        footer={null}
        title={
          <div className="truncate-line-3 uppercase me-6 md:me-8">
            {itemToPassStoresDialog?.itemId?.baseLanguage?.title || ""}
          </div>
        }
        width={500}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 500px)" }}
        className="link-modalpopup"
      >
        <LinkUnlinkItemWithStoresModal
          {...{
            connectedStores,
            removeItemFromStore,
            loading,
          }}
          cat={cat}
          key={cat?._id}
          onClose={() => setOpen(false)}
          item={itemToPassStoresDialog}
        />
      </AntModal>
      {isMobile ? (
        <>
          {items?.map((item, index) => {
            return (
              <ItemRow
                key={item?.itemId?._id}
                item={item}
                deleteItem={unLinkMenuItemFromCategory}
                index={index}
                handleStores={handleStores}
                getCategoryInfo={getCategoryInfo}
                cat={cat}
              />
            );
          })}
        </>
      ) : (
        <ReactSortable
          list={items}
          setList={setItems}
          animation={300}
          onUpdate={(order) => {
            reOrderCategory(order);
          }}
        >
          {items &&
            items.map((item, index) => {
              return (
                <ItemRow
                  key={item?.itemId?._id}
                  item={item}
                  deleteItem={onClickDeleteButton}
                  index={index}
                  handleStores={handleStores}
                  getCategoryInfo={getCategoryInfo}
                  cat={cat}
                />
              );
            })}
        </ReactSortable>
      )}

      <div className="ml-12 py-6">
        <button
          className="text-indigo-600 text-12px font-semibold"
          onClick={() => {
            setDialogContentId(DIALOG_VIEW.ADD_ITEM);
            setOpen(true);
            getCategoryInfo(cat?._id);
          }}
        >
          {t("Add Item")}
        </button>
      </div>
    </div>
  );
};
export default CategoryItem;

CategoryItem.propTypes = {
  cat: PropTypes.object,
  handleCount: PropTypes.func,
  pushFrom: PropTypes.string,
};
