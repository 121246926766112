import PageHeading from "components/PageHeading";
import DashboardBranchesTable from "features/dashboard/components/DashboardBranchesTable";
import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import useUserType from "hooks/useUserType";
import useCatalogue from "hooks/useCatalogue";
const StoreList = () => {
  const router = useHistory();
  const userType = useUserType();
  const { t } = useTranslation();
  const catelougeType = useCatalogue();

  const addStore = () => {
    router.push("/app/dashboard/addstore");
  };
  return (
    <div className="">
      <PageHeading heading={t("Stores")} />
      <div className="px-4 py-2">
        <div className="flex flex-wrap justify-between px-1 md:px-10">
          <div className="">
            <h2 className="text-16px">{t("Manage Stores")}</h2>
            <p className="text-sm text-gray-500">
              {t(
                "Add, Update Stores, You can also OFF store to not accept any online order"
              )}
            </p>
          </div>
          <div>
            {catelougeType?.showStore &&
            !catelougeType?.isLoading &&
            userType === "BRAND-ADMIN" ? (
              <Button
                type="primary"
                size="medium"
                className="w-36"
                onClick={() => addStore()}
              >
                {t("Add Store")}
              </Button>
            ) : null}
          </div>
        </div>
        <DashboardBranchesTable />
      </div>
    </div>
  );
};
export default StoreList;
