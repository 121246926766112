import RadioButton from "components/RadioButton";
import React from "react";
import { CITY } from "svg/SVGs";
import { FLAG } from "svg/SVGs";
import { AREA } from "svg/SVGs";
import { Button } from "antd";
import { CONSTANTS as C } from "config";
import { MAP } from "svg/SVGs";
import { useTranslation } from "react-i18next";
import * as R_CONST from "routes/Constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ShippingMethodsList({ method, toggleShippingMethod, CatalogueType }) {
  const { t } = useTranslation();
  const router = useHistory();
  const goToAreas = (shippingMethodName) => {
    console.log("goToAreas", shippingMethodName);
    if (shippingMethodName) {
      let routeUrl = {
        AREA_BASED_DELIVERY_METHOD: R_CONST.ADD_AREA,
        CITY_BASED_DELIVERY_METHOD: R_CONST.ADD_CITY,
        COUNTRY_BASED_DELIVERY_METHOD: R_CONST.ADD_COUNTRY,
        MAPPED_AREA_BASED_DELIVERY_METHOD: "/app/dashboard/mapped-area-method",
      };
      router.push(routeUrl[shippingMethodName]);
    }
  };

  const isAllowed = (method) => {
    console.log("isAllowed", CatalogueType, method);
    //case 1: if outlet based and method is not area based
    //case Not disable if if any type of catalouge and method name is mapped area based
    if (CatalogueType === C.OUTLET_BASED) {
      if (
        method === C.SHIPPING_METHODS.CITY_BASED ||
        method === C.SHIPPING_METHODS.COUNTRY_BASED
      )
        return true;
      else return false;
    } else return false;
  };

  return (
    <div
      key={method?._id}
      className="border-b border-gray-100 flex flex-col sm:flex-row  items-center justify-between"
    >
      <div className="flex items-center mt-4 sm:mt-0">
        <div className="px-2">
          {method?.methodName === C.SHIPPING_METHODS.AREA_BASED && <AREA />}
          {method?.methodName === C.SHIPPING_METHODS.COUNTRY_BASED && <FLAG />}
          {method?.methodName === C.SHIPPING_METHODS.CITY_BASED && <CITY />}
          {method?.methodName === C.SHIPPING_METHODS.MAPPED_AREA_BASED && (
            <AREA />
          )}
        </div>

        <div className="px-3 py-2">
          {t(C.SHIPPING_METHODS[method?.methodName])}
        </div>
      </div>
      <div className="flex items-center">
        <Button
          disabled={
            // method?.methodName !== C.SHIPPING_METHODS.AREA_BASED &&
            // CatalogueType === C.OUTLET_BASED
            isAllowed(method?.methodName)
          }
          type="default"
          className="rounded-md px-8 me-2 items-center"
          size="large"
          onClick={() => goToAreas(method?.methodName)}
        >
          <span className="ps-1">{t("Edit")}</span>
        </Button>
        <div className="pb-3">
          <RadioButton
            label={""}
            value={method?.status}
            keyVal={method?.methodName}
            handleValues={toggleShippingMethod}
          />
        </div>
      </div>
    </div>
  );
}

export default ShippingMethodsList;
