import React from "react";
import { DatePicker, Space } from "antd";
import { useTranslation } from "react-i18next";

const Form = ({ setState, getOrdersHistory }) => {
  const { t } = useTranslation();

  const { RangePicker } = DatePicker;
  const handleChange = (date, dateString) => {
    setState({
      startData: dateString[0],
      endData: dateString[1],
    });
  };
  return (
    <div className="py-4">
      <label className="text-gray-600 block text-14px">
        {t("Select Date")}
        <span className="text-red-600"> *</span>
      </label>
      <Space direction="vertical" size={12}>
        <RangePicker
          onChange={handleChange}
          format="DD/MM/YYYY"
          className="text-16px"
          placeholder={[t("Start Date"), t("End Date")]}
          inputReadOnly={true}
        />
      </Space>
      <button
        type="button"
        className="mx-2 ant-btn ant-btn-primary w-36"
        onClick={() => getOrdersHistory(null)}
      >
        <span>{t("Submit")}</span>
      </button>
    </div>
  );
};

export default Form;
