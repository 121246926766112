import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

function RefundButton({ status, setOpen }) {
  const { t } = useTranslation();
  const REFUND_STATUS = {
    COMPLETE: "COMPLETE",
    REQUESTED: "REQUESTED",
    FAILED: "FAILED",
  };
  const RefundButton = ({ title }) => (
    <>
      <div className="w-full py-3 hidden-print sm:w-1/2">
        <div className="text-gray-500">{t("Refund Details")}</div>
        <Button
          type="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          {title}
        </Button>
      </div>
    </>
  );

  switch (status) {
    case REFUND_STATUS.COMPLETE:
      return <RefundButton title={t("Refund Completed")} />;
    case REFUND_STATUS.REQUESTED:
      return <RefundButton title={t("Refund Requested")} />;
    case REFUND_STATUS.FAILED:
      return <RefundButton title={t("Refund Failed")} />;
    default:
      return null;
  }
}

export default RefundButton;
