import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { UTILS } from "utils";
import PropsTypes from "prop-types";
import RadioButton from "components/RadioButton";
import { API } from "api";
import { CONSTANTS as C } from "config";
import Card from "components/Card";
import { Spin, Table } from "antd";
import Modal from "components/Modal";
import AddStoreModel from "./AddStoreModel";
import { useTranslation } from "react-i18next";
import { BASE_LANGUAGE } from "config/Constants";
import useUserType from "hooks/useUserType";
import useCatalogue from "hooks/useCatalogue";
const DashboardBranchesTable = (props) => {
  const userType = useUserType() || UTILS.getCurrentUser()?.userType;
  const [stores, setStores] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const catelougeType = useCatalogue();
  const router = useHistory();
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);

  const getCurrentUserStores = async () => {
    setLoading(true);
    catelougeType.setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_CRRENT_USER_STORES({
      token: userDetail.token,
      language: currentLanguage ?? "EN",
    });
    if (
      result &&
      result.success == 1 &&
      Array.isArray(result.data) &&
      result.data.length >= 1
    )
      setStores(result.data);
    else {
      if (userType === "MANAGER") {
      } else {
        setOpen(true);
      }
    }

    catelougeType.setLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    getCurrentUserStores();
  }, []);

  const getServiceTypes = (types) => {
    let text = "";
    types &&
      types.map((item, index) => {
        text += item.nameEN + " ";
      });
    return text;
  };

  const publishStore = async (storeId, val) => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    let s = {
      brandId: userDetail.brandId,
      storeId: storeId,
      isOpened: val,
      language: "EN",
    };
    const result = await API.PUBLISH_UNPUBLISH_STORE({
      token: userDetail.token,
      store: s,
    });
    if (result && result.success === 1) {
      let arr = [...stores];
      let re = arr.map((el) =>
        el._id == storeId ? { ...el, isOpened: val } : el
      );
      setStores(re);
    } else {
    }
    getCurrentUserStores(() => {
      setLoading(false);
    });
  };

  const handleValues = (key, val) => {
    publishStore(key, val);
  };

  const updateStore = (sId) => {
    router.push({
      pathname: "/app/dashboard/updatestore",
      state: {
        storeId: sId,
      },
    });
  };

  const renderUpdateStore = (value, rowData, index) => {
    return (
      <button
        // onClick={() => updateStore(rowData._id)}
        onClick={() =>
          updateStore(
            rowData[BASE_LANGUAGE] ? rowData._id : rowData?.storeId?._id
          )
        }
        className="py-1 px-2 text-13px border border-red-200 shadow-sm rounded-sm text-theme-red bg-gray-50 focus:outline-none hover:bg-theme-red hover:text-white"
      >
        {t("Update Store Information")}
      </button>
    );
  };

  const renderAreas = (value, rowData, index) => {
    return (
      <button
        onClick={() => {
          localStorage.setItem("storeId", rowData._id);
          router.push({
            pathname: "/app/dashboard/shipping",
            state: {
              sId: rowData[BASE_LANGUAGE] ? rowData._id : rowData?.storeId?._id,
            },
          });
        }}
        className="py-1 px-2 text-13px border border-blue-600 shadow-sm rounded-sm text-blue-600 bg-gray-50 focus:outline-none hover:bg-blue-600 hover:text-white"
      >
        {t("Manage Areas")}
      </button>
    );
  };

  const renderActions = (value, rowData, index) => {
    return (
      <RadioButton
        key={index}
        label={""}
        value={value ?? rowData?.storeId?.isOpened}
        keyVal={rowData[BASE_LANGUAGE] ? rowData._id : rowData?.storeId?._id}
        handleValues={handleValues}
      />
    );
  };
  const columns = [
    {
      title: t("Store"),
      dataIndex: "baseLanguage",
      render: (value, rowData, index) => {
        return rowData[C.BASE_LANGUAGE]
          ? rowData[C.BASE_LANGUAGE].name
          : rowData?.storeId[C.BASE_LANGUAGE]?.name;
      },
    },
    {
      title: t("Description"),
      dataIndex: "description",
      render: (value, rowData, index) => {
        return rowData[C.BASE_LANGUAGE]
          ? rowData[C.BASE_LANGUAGE].description
          : rowData?.storeId[C.BASE_LANGUAGE]?.description;
      },
    },
    {
      title: t("Actions"),
      dataIndex: "_id",
      width: "30%",
      render: renderUpdateStore,
    },
    {
      title: t("Areas"),
      dataIndex: "_id",
      width: "15%",
      render: renderAreas,
    },
    {
      title: t("Publish"),
      dataIndex: "isOpened",
      render: renderActions,
      width: "12%",
    },
  ];

  return (
    <div className="flex flex-col overflow-hidden overflow-x-auto md:overflow-hidden">
      <Modal
        open={open}
        modalWidth="w-1/2 rounded-md border border-gray-300"
        onClose={() => setOpen(false)}
      >
        <AddStoreModel
          onClose={() => {
            setOpen(false);
            router.push("/app/dashboard/addstore");
          }}
        />
      </Modal>
      <div className="-my-2 sm:-mx-6 lg:-mx-8 store-table">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="py-3 px-1 md:px-10">
            <Card>
              <Spin spinning={loading || catelougeType?.isLoading}>
                <Table
                  locale={{ emptyText: t("No Data") }}
                  columns={columns}
                  dataSource={stores}
                  pagination={false}
                  rowKey={(record) => record._id}
                />
              </Spin>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardBranchesTable.propTypes = {
  data: PropsTypes.array,
};

export default DashboardBranchesTable;
