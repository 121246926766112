import React from "react";
import { Route } from "react-router-dom";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem("__CURRENT_USER__")) {
          return null;
        } else return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
