import { errorHandler } from "api/api/errorHandler";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTrackCareemOrderMutation } from "redux/Logistic/CareemService";
import { LoaderAction } from "redux/actions";
import ClaimInfoTable from "logistic-integrations/careem-now/components/ClaimInfoTable";

import { UTILS } from "utils";
const ClaimInfo = (props) => {
  const dispatch = useDispatch();
  const [careemOrder, setCareemOrder] = useState(null);
  const [trackCareemOrder] = useTrackCareemOrderMutation();

  useEffect(() => {
    const getCareemORderStatus = async (order) => {
      try {
        if (!order?._id) return;
        dispatch(LoaderAction.setLoading(true));
        const data = await trackCareemOrder({
          orderId: order._id,
        });
        if (data?.data?.success === 1 && data?.data?.data) {
          setCareemOrder(data?.data.data);
        } else {
          UTILS.errorNotification(errorHandler(data?.error));
          setCareemOrder(null);
        }
      } catch (error) {
        UTILS.errorNotification(errorHandler(error?.error));
      } finally {
        dispatch(LoaderAction.setLoading(false));
      }
    };
    if (props?.order?._id) getCareemORderStatus(props?.order);
  }, [props?.order?._id]);

  return (
    <div>
      <ClaimInfoTable {...{ careemOrder }} />
    </div>
  );
};

export default ClaimInfo;
