import React from "react";
import QRCodeGenerator from "./QRCodeGenerator";
import CopyLink from "./CopyLink";
import DownloadButton from "./DownloadButton";

function PickupDelivery({
  settings,
  orderPageLink,
  stores,
  qrCodeSetting,
  handleLogoSettings,
  handleColorSettings,
  handleDownload,
}) {
  return (
    <div className=" p-0 lg:p-4 mt-4 w-full lg:w-1/2">
      {stores?.map((store, index) => {
        return store?.isDineInEnabled ? (
          <div
            key={index}
            className="mx-0 lg:mx-2  mb-8 w-full  border py-4 border-gray-300"
          >
            <h2 className="px-2 w-full lg:w-2/3 capitalize text-gray-900  text-17px font-semibold">
              {store?.label}
            </h2>
            <QRCodeGenerator
              type="dine-in"
              id={store?.value}
              orderPageLink={`${orderPageLink}/dine-in?store-id=${store?.value}&service-type=dine-in&table-number=0000`}
              {...{
                settings,
                qrCodeSetting,
              }}
              size={150}
              handleLogoSettings={handleLogoSettings}
              handleColorSettings={handleColorSettings}
            />

            <CopyLink
              orderPageLink={`${orderPageLink}/dine-in?store-id=${store?.value}&service-type=dine-in&table-number=0000`}
            />

            <DownloadButton
              handleDownload={() => handleDownload(store?.value)}
            />
          </div>
        ) : null;
      })}
    </div>
  );
}

export default PickupDelivery;
