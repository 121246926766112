import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import routes from "routes/ROUTES";
import {
  ReportingObserver as ReportingObserverIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  Debug as DebugIntegration,
} from "@sentry/integrations";
import { matchPath } from "react-router-dom";
import { UTILS } from "utils";
import Home from "./Home";
function Index() {
  const history = createBrowserHistory();
  Sentry.init({
    dsn: "https://f825fc8011ed436da044b3b79d2bebee@o94327.ingest.sentry.io/4504286261084160",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing(),
      new ReportingObserverIntegration(),
      new ExtraErrorDataIntegration(),
      new DebugIntegration(),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    // maxBreadcrumbs: 50,
    attachStacktrace: true,
    debug: false,
    normalizeDepth: 6,
    maxBreadcrumbs: 50,
    environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    ignoreErrors: ["ReportingObserver", "Deprecation"],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error.message && error.message.match(/ReportingObserver/i)) {
        return null;
      }
      return event;
    },
  });
  Sentry.setUser(UTILS.getSentryUser());
  return <Home />;
}
export default Sentry.withProfiler(Index);
