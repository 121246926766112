import React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useAddNewMenuMutation } from "redux/menu/service";
import { errorHandler } from "api/api/errorHandler";
import AddNewMenu from "../AddNewMenu";
import { UTILS } from "utils";
import { errorNotification } from "utils/Utils";
import { isValidValue } from "utils/Utils";

const CreateNewMenuModal = ({
  loading,
  stores,
  updateState,
  menuState,
  getAllMenus,
  setLoading,
}) => {
  const { t } = useTranslation();
  const [addNewMenu] = useAddNewMenuMutation();
  const [newMenuState, setNewMenuState] = React.useState({
    name: "",
    description: "",
  });

  const getStoresObject = (selectedStores) => {
    if (!newMenuState?.name) {
      errorNotification(t("Please enter menu name"));
      return { stores: [], isValidate: false };
    }
    if (
      isValidValue(newMenuState?.description) &&
      newMenuState?.description?.trim()?.length < 5
    ) {
      errorNotification(
        t("Minimum description length must be at least 5 characters")
      );
      return { stores: [], isValidate: false };
    }

    if (
      !menuState?.newSelectedStores ||
      menuState?.newSelectedStores?.length <= 0
    ) {
      errorNotification(t("Please select at least one store to link"));
      return { stores: [], isValidate: false };
    }

    if (!Array.isArray(selectedStores) || selectedStores.length <= 0) {
      errorNotification(t("Please select at least one store to link"));
      return { stores: [], isValidate: false };
    }

    const stores = [];
    let isValidate = true;

    selectedStores?.forEach((store) => {
      if (!store?.serviceType || store?.serviceType?.length <= 0) {
        errorNotification(
          t("Please select service type for x store", {
            storeName: store?.label,
          })
        );
        isValidate = false;
        return { stores: [], isValidate: false };
      }
      stores.push({
        storeId: store.storeId?._id,
        serviceType: store.serviceType,
      });
    });

    return { stores, isValidate };
  };

  const handleMenuStateChange = (e) => {
    setNewMenuState({ ...newMenuState, [e.target.name]: e.target.value });
  };
  const CreateNewMenu = async () => {
    const { stores: StoreIds, isValidate } = getStoresObject(
      menuState?.newSelectedStores
    );
    if (!isValidate) return;
    setLoading(true);

    try {
      const res = await addNewMenu({
        brandId: UTILS.getCurrentUser()?.brandId,
        baseLanguage: { name: newMenuState?.name },
        ...(newMenuState?.description?.trim() && {
          description: newMenuState?.description,
        }),
        stores: StoreIds,
      });

      if (res?.data?.success === 1) {
        UTILS.successNotification(res?.data?.message);
        setNewMenuState({
          name: null,
          description: null,
        });
        resetMenuState();
      } else {
        UTILS.errorNotification(errorHandler(res?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      updateState("isCreateNewMenuModalOpen", false);
      await getAllMenus();
      setLoading(false);
    }
  };

  const resetMenuState = () => {
    setNewMenuState((prev) => ({
      ...prev,
      name: "",
      description: "",
    }));
    updateState("newMenuName", "");
    updateState("newSelectedStores", []);
    updateState("linkedStores", []);
    updateState("isCreateNewMenuModalOpen", false);
  };

  return (
    <Modal
      title={t("Create New Menu")}
      open={menuState?.isCreateNewMenuModalOpen}
      onCancel={() => {
        updateState("isCreateNewMenuModalOpen", false);
        resetMenuState();
      }}
      footer={[
        <div className=" flex justify-between">
          <Button type="primary" onClick={CreateNewMenu}>
            {t("Create")}
          </Button>
          <Button onClick={resetMenuState}>{t("Cancel")}</Button>
        </div>,
      ]}
      width={500}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 500px)" }}
    >
      <AddNewMenu
        loading={loading}
        newMenuName={menuState?.newMenuName}
        newMenuState={newMenuState}
        handleMenuNameChange={handleMenuStateChange}
        stores={stores}
        selectedStores={menuState?.newSelectedStores}
        setselectedStores={(val) => updateState("newSelectedStores", val)}
      />
    </Modal>
  );
};

export default CreateNewMenuModal;
