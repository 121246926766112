export const CURRENT_USER = "__CURRENT_USER__";
export const SIXHOURS = 21600000;
export const STORES_INFO = "__STORE_INFO___";
export const CASH_ON_DELIVERY_OBJECT = {
  nameEN: "cash on delivery",
  nameAR: "الدفع عن الاستلام",
};
export const CREDIT_CARD_OBJECT = {
  nameEN: "credit/debit card",
  nameAR: "بطاقة الائتمان / الخصم",
};

// export const BASE_URL = "https://dev-api.resthero.io/api/v1/";
export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
// export const BASE_URL = "https://api.resthero.io/api/v1/";
export const START_TIME_24_HOURS = 1652817600000;
export const END_TIME_24_HOURS = 1652903999000;

export const PAYMENT_TYPE_ONLINE = "CREDIT_CARD";

export const KEY_CAT_INFO_EN = "categoryInfoEN";
export const KEY_CAT_INFO_AR = "categoryInfoAR";
export const KEY_CAT_TITLE = "categoryTitle";
export const KEY_STORE_NAME_EN = "___KEY_STORE_NAME_EN____";
export const KEY_STORE_DES_EN = "___KEY_STORE_DES_EN____";
export const KEY_STORE_NAME_AR = "___KEY_STORE_NAME_AR_";
export const KEY_STORE_DES_AR = "___KEY_STORE_DES_AR_";
export const KEY_EMAIL = "email";
export const KEY_PHONE = "mobileNumber";
export const KEY_WHATSAPP_PHONE = "whatsAppContact";
export const KEY_PHONE_FOR_USER = "phoneNumber";
export const KEY_PASSWORD = "password";
export const KEY_CONFIRM_PASSWORD = "confirmPassword";
export const KEY_USER_TYPE = "userType";
export const KEY_ADDRESS = "address";
export const KEY_IS_CASH_ON_DELIVERY = "isCashOnDeliveryOptionAvailable";
export const KEY_IS_ONLINE_PAYMENT = "isOnlinePaymentOptionAvailable";
export const STORE_INFO_AR = "storeInfoAR";
export const STORE_INFO_EN = "storeInfoEN";
export const BASE_LANGUAGE = "baseLanguage";
export const STORE_NAME = "name";
export const AVERAGE_PREP = "averagePrepTime";
export const PICKUP_AVERAGE_PREP = "averagePrepTimeForPickup";
export const DINEIN_AVERAGE_PREP = "averagePrepTimeForDinein";
export const STORE_DESCRIPTION = "description";
export const STORE_NAME_AR = "storeNameAR";
export const STORE_DESCRIPTION_AR = "storeDescriptionAR";

export const KEY_LAT_LNG_ADDRESS = "latitudeLongitude";
export const KEY_MANUAL_ADDRESS = "manualAddress";
export const ZOOM = "zoom";

export const KEY_MAP_ADDRESS = "mapAddress";
export const KEY_IMAGE_URL = "storeImageUrl";
export const KEY_DELETE_IMG_URL = "__DELETE_IMG_URL___";
export const KEY_IS_TAX_INCLUSIVE = "isInclusive";
export const KEY_TAX_RATE = "taxRate";
export const KEY_CITY = "city";
export const KEY_TIMEZONE = "timeZone";

export const KEY_PAYMENT_TYPES = "paymentOptions";
export const KEY_PICKUP_PAYMENT_TYPES = "pickupPaymentOptions";
export const KEY_DINEIN_PAYMENT_TYPES = "dineInPaymentOptions";
export const KEY_SERVICE_TYPES = "serviceType";

export const KEY_WORKING_HOURS = "workingHours";
export const KEY_FRI = "friday";
export const KEY_FRI_END_TIME = "fridayEndTime";
export const KEY_FRI_START_TIME = "fridayStartTime";
export const KEY_FRI_SLOT2_START_TIME = "fridayStartTimeSlot2";
export const KEY_FRI_SLOT2_END_TIME = "fridayEndTimeSlot2";
export const KEY_MON = "monday";
export const KEY_MON_END_TIME = "mondayEndTime";
export const KEY_MON_START_TIME = "mondayStartTime";
export const KEY_MON_SLOT2_START_TIME = "mondayStartTimeSlot2";
export const KEY_MON_SLOT2_END_TIME = "mondayEndTimeSlot2";
export const KEY_SAT = "saturday";
export const KEY_SAT_END_TIME = "saturdayEndTime";
export const KEY_SAT_START_TIME = "saturdayStartTime";
export const KEY_SAT_SLOT2_START_TIME = "saturdayStartTimeSlot2";
export const KEY_SAT_SLOT2_END_TIME = "saturdayEndTimeSlot2";
export const KEY_SUN = "sunday";
export const KEY_SUN_END_TIME = "sundayEndTime";
export const KEY_SUN_START_TIME = "sundayStartTime";
export const KEY_SUN_SLOT2_START_TIME = "sundayStartTimeSlot2";
export const KEY_SUN_SLOT2_END_TIME = "sundayEndTimeSlot2";
export const KEY_THU = "thursday";
export const KEY_THU_END_TIME = "thursdayEndTime";
export const KEY_THU_START_TIME = "thursdayStartTime";
export const KEY_THU_SLOT2_START_TIME = "thursdayStartTimeSlot2";
export const KEY_THU_SLOT2_END_TIME = "thursdayEndTimeSlot2";
export const KEY_TUE = "tuesday";
export const KEY_TUE_END_TIME = "tuesdayEndTime";
export const KEY_TUE_START_TIME = "tuesdayStartTime";
export const KEY_TUE_SLOT2_START_TIME = "tuesdayStartTimeSlot2";
export const KEY_TUE_SLOT2_END_TIME = "tuesdayEndTimeSlot2";
export const KEY_WED = "wednesday";
export const KEY_WED_END_TIME = "wednesdayEndTime";
export const KEY_WED_START_TIME = "wednesdayStartTime";
export const KEY_WED_SLOT2_START_TIME = "wednesdayStartTimeSlot2";
export const KEY_WED_SLOT2_END_TIME = "wednesdayEndTimeSlot2";

export const DIRECTION = "__DIRECTION___";

export const USER_ADDED = "___USER_ADDED___";
export const USER_TYPE_ADMIN = "BRAND-ADMIN";
export const USER_TYPE_MANAGER = "MANAGER";
export const ISDELIVERYENABLED = "isDeliveryEnabled";
export const ISPICKUPENABLED = "isPickupEnabled";
export const ISDINEINENABLED = "isDineInEnabled";
export const DATE_FORMAT = "DD-MM-YYYY HH:mm";
export const STORE = "STORE";
export const TALABAT = "TALABAT";
export const OTHER = "OTHER";
export const AED = "AED";
export const VERIFICATION_LAYOUT = "verification";
export const DASHBOARD_LAYOUT = "dashboard";
export const BRAND_BASED = "BRAND_BASED_CATALOGUE";
export const OUTLET_BASED = "OUTLET_BASED_CATALOGUE";
export const MAX_IMAGE_SIZE = 2048;
export const SETTINGS_DEFAULT_COLOUR = "#C32933";
export const DEFAULT_TEXT_COLOUR = "#ffffff";
export const NEW_PASSWORD = "newPassword";
export const OLD_PASSWORD = "oldPassword";

export const CONFIRM_PASSWORD = "confirmPassword";
export const PLACEHOLDER_IMAGE =
  "https://uapp-live-com.s3.amazonaws.com/UAPP-MEDIA/placeholder.jpeg";
export const DOMAIN_NAME = "temporaryDomainName";
export const PREVIEW = "/preview/";
export const MERCHANT_WEB_ADMIN = "MERCHANT_WEB_ADMIN";
export const MOBILE_ARABIC_REGEX = /[^0-9]/gi;
export const PRICE_ARABIC_REGEX = /[^0-9.]/gi;
export const DELIVERY_SERICETYPE = "DELIVERY";
export const packageHeader = [
  {
    header: "12 MONTHS",
    key: "YEARLY",
    subscriptionDiscount: "annualSubscriptionDiscount",
    discount: true,
    billedBy: "Billed every 12 months",
  },
  {
    header: "6 MONTHS",
    key: "SEMI_ANNUALLY",
    subscriptionDiscount: "semiAnnualSubscriptionDiscount",
    discount: true,
    billedBy: "Billed every 6 months",
  },
  {
    header: "1 MONTH",
    key: "MONTHLY",
    subscriptionDiscount: "monthlySubscriptionDiscount",
    discount: false,
    billedBy: false,
  },
];

export const getPackText = (id) => {
  if (id === "entrepreneur") return PackageText[0];
  if (id === "growth") return PackageText[1];
  if (id === "enterprise") return PackageText[2];
};
export const PackageText = [
  {
    id: "entrepreneur",
    "1 online store": "1 online store",
    "Up to 100 products": "Up to 100 products",
    "Up to 250 orders": "Up to 250 orders",
    "Unlimited customers": "Unlimited customers",
    "Payment gateway integration": "Payment gateway integration",
    "Multiple local delivery Integrations":
      "Multiple local delivery Integrations",
    "Discount coupons": "Discount coupons",
    "Staff accounts": "Staff accounts",
    "Custom branding of store": "Custom branding of store",
    Plugins: "Plugins",
    "Free SSL certificate": "Free SSL certificate",
    "Free Hosting": "Free Hosting",
  },
  {
    id: "growth",
    "Everything in Entrepreneur, plus": "Everything in Entrepreneur, plus",
    "Up to 3 stores": "Up to 3 stores",
    "Up to 300 products": "Up to 300 products",
    "Up to 450 orders": "Up to 450 orders",
  },
  {
    id: "enterprise",
    "Everything in Growth, plus": "Everything in Growth, plus",
    "Tailor-made themes": "Tailor-made themes",
    "Custom CRM integrations": "Custom CRM integrations",
    "All other needs large enterprise might have":
      "All other needs large enterprise might have",
  },
];

export const refreshAppProps = {
  heading: "new update available",
  message:
    "We made bug fixes and improvements to keep your operations smooth. Please click below button to get latest application version.",

  btnText: "get latest version",
};

export const noInternetProps = {
  heading: "Oops",
  message:
    "Looks like you're offline. Try reconnecting to a working internet connection.",
  btnText: "Refresh",
};

export const API_ERRORS = {
  TIMEOUT_ERROR: "ECONNABORTED",
  NETWORK_ERROR: "Network Error",
};

export const SHIPPING_METHODS = {
  AREA_BASED: "AREA_BASED_DELIVERY_METHOD",
  CITY_BASED: "CITY_BASED_DELIVERY_METHOD",
  COUNTRY_BASED: "COUNTRY_BASED_DELIVERY_METHOD",
  MAPPED_AREA_BASED: "MAPPED_AREA_BASED_DELIVERY_METHOD",

  AREA_BASED_DELIVERY_METHOD: "AREA BASED DELIVERY METHOD",
  CITY_BASED_DELIVERY_METHOD: "CITY BASED DELIVERY METHOD",
  COUNTRY_BASED_DELIVERY_METHOD: "COUNTRY BASED DELIVERY METHOD",
  MAPPED_AREA_BASED_DELIVERY_METHOD: "MAPPED AREA BASED DELIVERY METHOD",
};

export const HIDDEN = "hidden";
export const COUPUN_APPLICATION_TYPE = {
  MANUAL: "manual",
  AUTOMATIC: "automatic",
  HIDDEN: "hidden",
};

export const PERCENTAGE = "percentage";
export const ALL = "ALL";
export const SELECTED = "SELECTED";
export const CURRENT_LANGUAGE = {
  EN: "EN",
  AR: "AR",
};
export const SERVICE_TYPES = {
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
  DINEIN: "DINE IN",
};
export const LOYALTY_CONSTANTS = {
  ADD_OP: "add",
  EDIT_OP: "edit",
};
export const USER_TYPE = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
};
export const YANDEX = {
  courier: "Motor Bike",
  express: "Car",
  EXPRESS_DELIVERY_TYPE: "EXPRESS",
};
export const APP_NAME = "MERCHANT_WEB_ADMIN";

export const COMPANY_NAMES = {
  RESTHERO: "Resthero",
  CUSINETTE: "cuisinette",
};

export const orderStatus = {
  CANCELLED: "CANCELLED",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  PREPARING: "PREPARING",
  COMPLETED: "COMPLETED",
};

export const PAYMENT_GATWAYS = {
  STRIPE: "STRIPE",
};

export const ITEM_MODIFIER_UNAVAILABLE_DURATIONS = [
  {
    value: 6,
    unit: "hours",
    title: "6 Hours",
    duration: "6_HOURS",
  },
  {
    value: 1,
    unit: "days",
    title: "1 Day",
    duration: "1_DAY",
  },
  {
    value: 3,
    unit: "days",
    title: "3 Days",
    duration: "3_DAYS",
  },
  {
    value: 1,
    unit: "weeks",
    title: "1 week",
    duration: "1_WEEK",
  },
  {
    value: null,
    unit: null,
    title: "Indefinitely",
    duration: "INDEFINITELY",
  },
];

export const logisticPartnerNames = [
  "TALABAT",
  "YANDEX",
  "CAREEM_NOW",
  "DELIVERY_ZONE",
  "LYVE_GLOBAL",
];
