import { API } from "api";
import { CONSTANTS as C } from "config";
import useSWR from "swr";
import { UTILS } from "utils";

const useRefundableOrder = () => {
  const token = UTILS.getCurrentUser()?.token;
  const fetcher = () =>
    API.GET_REFUNDABLE_COUNT({ token: UTILS.getCurrentUser().token });

  const { data, error, mutate } = useSWR(
    token ? C.BASE_URL + "order/get-refundable-orders-count" : null,
    fetcher,
    {
      refreshInterval: 300000,
      revalidateOnFocus: false, // Don't revalidate on tab focus

      refreshWhenHidden: true, // Allow polling even if tab is hidden
      refreshWhenOffline: true, // Allow polling even if the user is offline
      revalidateOnMount: true, // Always revalidate when the hook is mounted
      revalidateOnReconnect: true, // Revalidate when the user is back online
      revalidateOnWindowFocus: true,
    }
  );

  return {
    data: data,
    error: error,
    revalidate: mutate,
  };
};

export default useRefundableOrder;
