import React, { useEffect, useState } from "react";
import PageHeading from "components/PageHeading";
import ModifiersList from "./components/ModifierList";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import { errorHandler } from "api/api/errorHandler";
import { useDeleteModifierMutation } from "redux/modifiers/service";
import { useGetModifierListMutation } from "redux/modifiers/service";
import DeleteComponent from "components/DeleteConfirm";
import Modal from "components/Modal";
const Modifiers = () => {
  const { t } = useTranslation();
  const [getModifierList] = useGetModifierListMutation();
  const [deleteModifier] = useDeleteModifierMutation();
  const [modifiers, setmodifiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    deleteItemIndex: null,
    item: null,
  });
  const getAllModifers = async () => {
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const { data } = await getModifierList({
        brandId: userDetail.brandId,
      });
      if (data?.success === 1 && data?.data) {
        setmodifiers(data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllModifers();
  }, []);

  const deleteSingleModifier = async (rowData) => {
    try {
      const item = modifiers[deleteModal.deleteItemIndex];
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const data = await deleteModifier({
        brandId: userDetail.brandId,
        modifierId: item._id,
      });
      if (data?.data?.success === 1) {
        UTILS.successNotification(
          data?.data?.message ?? t("Modifier Deleted Successfully")
        );
        let arr = [...modifiers];
        arr.splice(deleteModal.deleteItemIndex, 1);
        setmodifiers(arr);
      } else {
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      handleCancel();
      setLoading(false);
    }
  };

  const onClickDeleteButton = (row) => {
    const index = modifiers?.findIndex((obj) => obj?._id === row?._id);
    if (index !== -1) {
      const item = modifiers[index];
      setDeleteModal({ isOpen: true, deleteItemIndex: index, item: item });
    }
  };

  const handleCancel = () => {
    setDeleteModal({ isOpen: false, deleteItemIndex: -1, item: null });
  };

  return (
    <Spin spinning={loading}>
      <div className="w-full">
        <PageHeading heading={t("Modifiers")} />
        <Modal
          open={deleteModal.isOpen}
          modalWidth="rounded-md border border-gray-300 top-0"
          onClose={handleCancel}
        >
          <DeleteComponent
            loading={loading}
            onCancel={handleCancel}
            onDelete={deleteSingleModifier}
            title={deleteModal.item?.baseLanguage?.name}
            Heading={t(
              "will be permanentely deleted and removed from existing menus"
            )}
          />
        </Modal>
        <div className="w-full px-4 py-4">
          <ModifiersList
            modifiers={modifiers}
            deleteModifier={onClickDeleteButton}
          />
        </div>
      </div>
    </Spin>
  );
};

export default Modifiers;
