import React, { useEffect, useState } from "react";
import PageHeading from "components/PageHeading";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useGetModifierGroupsMutation } from "redux/modifiers/service";
import ModifierGroupList from "./components/ModifierGroupList";
import { UTILS } from "utils";
import { errorHandler } from "api/api/errorHandler";
import { useDeleteModifierGroupMutation } from "redux/modifiers/service";
import DeleteComponent from "components/DeleteConfirm";
import Modal from "components/Modal";
const ModifierGroup = () => {
  const { t } = useTranslation();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getModifierGroups] = useGetModifierGroupsMutation();
  const [deleteModifierGroup] = useDeleteModifierGroupMutation();
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    deleteItemIndex: null,
    item: null,
  });

  const getAllModiferGroups = async () => {
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const { data } = await getModifierGroups({
        brandId: userDetail.brandId,
      });
      if (data?.success === 1) {
        setGroups(data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      handleCancel();
    }
  };

  useEffect(() => {
    getAllModiferGroups();
  }, []);

  const deleteModifierGrp = async () => {
    try {
      setLoading(true);
      const item = groups[deleteModal.deleteItemIndex];
      const userDetail = UTILS.getCurrentUser();
      const data = await deleteModifierGroup({
        brandId: userDetail.brandId,
        modifierGroupId: item._id,
      });
      if (data?.data?.success === 1) {
        let arr = [...groups];
        arr.splice(deleteModal.deleteItemIndex, 1);
        setGroups(arr);
        UTILS.successNotification(
          data?.data?.message ?? t("Modifier Group Deleted Successfully")
        );
      } else {
        UTILS.errorNotification(errorHandler(data?.error?.data));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.message));
    } finally {
      await getAllModiferGroups();
      setLoading(false);
    }
  };

  const onClickDeleteButton = (row) => {
    const index = groups.findIndex((obj) => obj?._id === row?._id);
    if (index !== -1) {
      const item = groups[index];
      setDeleteModal({ isOpen: true, deleteItemIndex: index, item: item });
    }
  };

  const handleCancel = () => {
    setDeleteModal({ isOpen: false, deleteItemIndex: -1, item: null });
  };

  return (
    <Spin spinning={loading}>
      <div className="w-full">
        <PageHeading heading={t("Modifier Groups")} />
        <Modal
          open={deleteModal.isOpen}
          modalWidth="rounded-md border border-gray-300 top-0"
          onClose={handleCancel}
        >
          <DeleteComponent
            loading={loading}
            onCancel={handleCancel}
            onDelete={deleteModifierGrp}
            title={deleteModal.item?.baseLanguage?.name}
            Heading={t(
              "will be permanentely deleted and removed from existing menus"
            )}
          />
        </Modal>
        <div className="w-full px-4 py-4">
          <ModifierGroupList
            groups={groups}
            deleteModifierGroup={onClickDeleteButton}
          />
        </div>
      </div>
    </Spin>
  );
};

export default ModifierGroup;
