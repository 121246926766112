import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";
import "./phoneinput.css";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};
const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600"> {error} </span>;
  }
};
const PhoneNumberInput = (props) => {
  const mandatoryField = getMandatoryField(props.mandatory);
  const fieldError = getFieldError(props.error);
  return (
    <>
      <label className="text-gray-600 block text-14px">
        {props.label}
        {mandatoryField}
        {fieldError}
      </label>
      <div dir="ltr">
        <PhoneInput
          className="outline-none focus:outline-0 text-16px telinput"
          international
          countryCallingCodeEditable={false}
          defaultCountry={"AE"}
          countries={["AE"]}
          onChange={(val) => props.handleValues(props.keyVal, val)}
          placeholder={props.placeHolder}
          value={props.value}
        />
      </div>
    </>
  );
};

PhoneNumberInput.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
};

export default PhoneNumberInput;
