import { apiSlice } from "redux/Api.Slice";
import * as URL from "api/api/apiUrls";
import { LoaderAction } from "redux/actions";
import { ErrorAction } from "redux/actions";
import { errorHandler } from "api/api/errorHandler";
import { LogisticAction } from "redux/actions";
import { UTILS } from "utils";
import i18n from "../../i18n";
import { logToSentry } from "utils/Utils";

const postConfig = {
  query: (body) => ({
    method: "POST",
    body: body,
  }),
  transformResponse: (response) => {
    return response;
  },
};

const LogisticService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderInfoById: builder.mutation({
      ...postConfig,
      query: (body) => ({
        url: URL.GET_ORDER_INFO_BY_ID,
        ...postConfig.query(body),
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1 && data?.data?.fetchedOrder) {
            dispatch(LogisticAction.setOrderInfo(data.data.fetchedOrder));
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err?.error));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    getYandexPickupSlots: builder.mutation({
      query: (body) => ({
        url: URL.GET_TIME_SLOTS,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
    getYandexDeliveryMethods: builder.mutation({
      query: (body) => ({
        url: URL.GET_YANDEX_DELIVERY_METHODS,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(LoaderAction.setLoading(true));
        dispatch(ErrorAction.setError(null));
        dispatch(LogisticAction.clearData());
        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            let availableTariffs =
              data?.data?.express_delivery?.available_tariffs;
            let isArrayAndNotEmpty =
              Array.isArray(availableTariffs) && availableTariffs?.length >= 1;
            if (isArrayAndNotEmpty && data?.data?.express_delivery?.allowed) {
              dispatch(LogisticAction.setVehicleTypes(availableTariffs));
              const isCourierAvailable = availableTariffs?.find(
                (item) => item.name === "courier"
              );
              if (isCourierAvailable) {
                const selectedvehicleType =
                  getState().LogisticReducer?.selectedvehicleType;
                if (!selectedvehicleType) {
                  dispatch(LogisticAction.setSelectedVehicleType("COURIER"));
                }
              }
              if (!isCourierAvailable) {
                logToSentry(
                  "yanex courier(Motor Bike) is not available in  availableTariffs"
                );
              }
            } else {
              dispatch(LogisticAction.setBtn(true));
              UTILS.handleErrorNotification(
                i18n.t(
                  "Sorry, YANGO isn’t serving at the moment, please try again after few minutes"
                )
              );
            }
          } else {
            dispatch(LogisticAction.setBtn(true));
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          dispatch(LogisticAction.setBtn(true));
          UTILS.handleErrorNotification(errorHandler(err?.error));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    createYandexClaim: builder.mutation({
      query: (body) => ({
        url: URL.CREATE_CLAIM,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(ErrorAction.setError(null));
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data?.message);
            dispatch(LogisticAction.setSelectedVehicleType(null));
          } else {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err?.error));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    confirmYandexClaim: builder.mutation({
      query: (body) => ({
        url: URL.CONFIRM_CLAIM,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data?.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err?.error));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    getYandexClaimInfo: builder.mutation({
      query: (body) => ({
        url: URL.CLAIM_INFO,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(ErrorAction.setError(null));

        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (
            data?.success === 1 &&
            data?.data?.logisticPartnerMetadata?.YANDEX?.claimInformation
          ) {
            let claimInfo =
              data?.data?.logisticPartnerMetadata?.YANDEX?.claimInformation;
            dispatch(LogisticAction.setClaimInfo(claimInfo));
          } else {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err?.error));
        }
        setTimeout(() => {
          dispatch(LoaderAction.setLoading(false));
        }, 5000);
      },
    }),
    cancelYandexClaim: builder.mutation({
      query: (body) => ({
        url: URL.CANCEL_CLAIM,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(ErrorAction.setError(null));
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            UTILS.successNotification(data?.message);
          }
          if (data?.success === 0) {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err?.error));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
    getYandexExpressRiderInfo: builder.mutation({
      query: (body) => ({
        url: URL.GET_YANDEX_EXPRESS_RIDER_INFO,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(LoaderAction.setLoading(true));
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
          } else {
            UTILS.handleErrorNotification(errorHandler(data));
          }
        } catch (err) {
          UTILS.handleErrorNotification(errorHandler(err?.error));
        }
        dispatch(LoaderAction.setLoading(false));
      },
    }),
  }),
});
export const {
  useGetOrderInfoByIdMutation,
  useGetYandexPickupSlotsMutation,
  useCreateYandexClaimMutation,
  useConfirmYandexClaimMutation,
  useCancelYandexClaimMutation,
  useGetYandexClaimInfoMutation,
  useGetYandexDeliveryMethodsMutation,
  useGetYandexExpressRiderInfoMutation,
} = LogisticService;
export default LogisticService;
