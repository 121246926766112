import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const CountryCityList = ({ CITIES, isChecked, saveArea }) => {
  const { currentLanguage } = useSelector((state) => state.LanguageSlice);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div>
        {CITIES?.length > 0 &&
          CITIES?.map((city) => (
            <div className="flex w-full py-1" key={city._id}>
              <div className="h-6 w-6"></div>
              <div className="flex justify-between w-full mx-4">
                {city?.isAdded ? (
                  <React.Fragment>
                    <div className="text-gray-500">
                      {city?.[`cityName${currentLanguage}`]}
                    </div>
                    <div className="text-gray-500">{t("Already added")}</div>
                  </React.Fragment>
                ) : (
                  <div
                    className="flex w-full justify-between"
                    onClick={() => saveArea(city)}
                  >
                    <div>{city?.[`cityName${currentLanguage}`]}</div>
                    <div>
                      {isChecked(city) ? (
                        <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200 check"></span>
                      ) : (
                        <span className="block relative w-5 h-5 border cursor-pointer border-gray-200 bg-gray-200"></span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default CountryCityList;
