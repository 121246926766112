import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./slices";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "redux/Api.Slice";
import * as Sentry from "@sentry/react";
import createSentryMiddleware from "redux-sentry-middleware";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
  configureScopeWithState: true,
});
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["LanguageSlice", "SettingsReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.NODE_ENV === "production"
) {
  console.log = console.error = console.assert = console.warn = () => {};
}
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      sentryReduxEnhancer,
      serializableCheck: false,
    }).concat([apiSlice.middleware, createSentryMiddleware(Sentry, {})]),
  devTools:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? true
      : false,
});

setupListeners(store.dispatch);
export const persistor = persistStore(store);
export default store;
