import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { convertPolygonArrayToObject } from "../../helpers";
import { UTILS } from "utils";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import GoogleMap from "../components/GoogleMap";
import BackButton from "components/BackButton";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { AreasAction } from "redux/actions";
import useAllStores from "hooks/useCurrentUserStores";
function DrawAreaOnMap() {
  const mapRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { stores } = useAllStores();

  const [polygons, setPolygons] = useState(null);
  const [plottedPolygons, setPlottedPolygons] = useState([]);

  const { selectedStore } = useSelector((state) => state.AreasReducer);

  async function getStoreMappedAddedAreas(ID) {
    try {
      if (!ID) return;
      const userDetail = UTILS.getCurrentUser();
      const response = await API.getStoreMappedAreas({
        brandId: userDetail.brandId,
        storeId: ID,
        isPolygonRequired: true,
      });
      if (response?.success === 1) {
        if (response?.data?.length > 0) {
          setPlottedPolygons(convertPolygonArrayToObject(response?.data));
        } else {
          setPlottedPolygons([]);
        }
      } else UTILS.errorNotification(errorHandler(response));
    } catch (error) {}
  }

  useEffect(() => {
    if (selectedStore?.value) {
      const Id = selectedStore?.value;
      getStoreMappedAddedAreas(Id);
    }
  }, []);

  const onStoreChange = (value) => {
    localStorage.setItem("storeId", value.value);
    getStoreMappedAddedAreas(value.value);
    dispatch(AreasAction.setStore(value));
  };

  return (
    <>
      <div className="px-2 md:px-6">
        <div className="mb-2">
          <BackButton />
        </div>

        <div className="flex items-center my-3 w-full sm:w-96 sm:my-0">
          <Select
            value={selectedStore}
            size="large"
            labelInValue={true}
            showSearch
            className="w-full rounded"
            placeholder={t("Select store")}
            optionFilterProp="children"
            onChange={onStoreChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={stores}
          />
        </div>
      </div>
      {selectedStore && (
        <GoogleMap
          {...{
            mapRef,
            polygons,
            setPolygons,
            drawingEnabled: false,
            setDrawingEnabled: () => {},
          }}
          selectedStore={null}
          operationType="view"
          plottedPolygons={plottedPolygons}
        />
      )}
    </>
  );
}

export default DrawAreaOnMap;
