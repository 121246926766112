import React from "react";
import PropTypes from "prop-types";

const RadioButtonStoreTime = ({ id, handleValues, keyVal, label, value }) => {
  return (
    <>
      <div className="flex items-center mtl-3 w-full">
        <label
          htmlFor={id}
          className="flex items-center cursor-pointer justify-between w-full"
        >
          <div className="text-gray-600 text-14px">{label}</div>
          <div className="relative">
            <input
              onChange={(event) => {
                handleValues(keyVal, event.target.checked);
              }}
              checked={value}
              type="checkbox"
              id={id}
              className="sr-only"
            />
            <div
              className={
                !value
                  ? "block bg-gray-300 w-16 h-8 rounded-full"
                  : "block bg-theme-green w-16 h-8 rounded-full"
              }
            ></div>
            <div
              className={
                !value
                  ? "dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                  : "dot absolute right-1 top-1 bg-white w-6 h-6 rounded-full transition"
              }
            ></div>
          </div>
        </label>
      </div>
    </>
  );
};

RadioButtonStoreTime.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  keyVal: PropTypes.string,
  handleValues: PropTypes.func,
};

export default RadioButtonStoreTime;
