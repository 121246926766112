import React from "react";
import SelectInput from "./SelectInput";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { BackButton } from "./BackButton";
import TextArea from "components/useForm/TextArea";
import PriceInput from "components/useForm/PriceInput";

export const AddPaymentLink = ({
  stores,
  handleSubmit,
  register,
  Controller,
  errors,
  control,
  watch,
  setValue,
  trigger,
}) => {
  const { t } = useTranslation();

  let purposeOfPayment = watch("purposeOfPayment");
  let message = watch("message");

  const handleValues = (key, value) => {
    setValue(key, value);
    trigger(key);
  };
  return (
    <div className="px-5">
      <BackButton />
      <div className="flex flex-wrap justify-between  w-full sm:w-1/2">
        <h2 className="text-gray-900 text-24px sm:text-30px font-medium">
          {t("Create payment link")}
        </h2>
        <p className=" text-gray-500 ">
          {t(
            "Specify the amount to be paid and the purpose of payment to generate a link for your customers. Be as clear as possible, so they understand what they're paying for."
          )}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="w-full sm:w-1/2 ">
          <div className="py-2">
            <div className=" w-full sm:w-1/2">
              <PriceInput
                label={t("Amount")}
                name="amount"
                register={register}
                mandatory={true}
                rules={{
                  required: t("Amount is a required field"),
                  min: {
                    value: 2.1,
                    message: t("Amount should be greater than 2"),
                  },
                }}
                error={errors?.amount?.message}
                keyVal="amount"
                handleValues={handleValues}
              />
            </div>
          </div>
          <div className="py-2">
            <label className="text-gray-600 block text-14px ">
              {t("Select Store")}
            </label>
            <div className="flex w-full sm:w-1/2">
              <Controller
                name="storeSelection"
                control={control}
                defaultValue=""
                rules={{ required: t("Store is a required field") }}
                render={({ field }) => (
                  <SelectInput
                    options={stores}
                    {...field}
                    error={errors.storeSelection?.message}
                    styleClass="font-montserrat text-input-color text-sm mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                )}
              />
            </div>
            {errors.storeSelection?.message && (
              <div className="text-red-600">
                {errors.storeSelection?.message}{" "}
              </div>
            )}
          </div>

          <div className="py-2 w-full">
            <TextArea
              label={t("Purpose of payment")}
              name="purposeOfPayment"
              register={register}
              mandatory={true}
              rows={2}
              rules={{
                required: t("Purpose of payment is a required field"),
                minLength: {
                  value: 5,
                  message: t(
                    "Purpose of payment should be at least 5 characters long"
                  ),
                },
              }}
              maxLength={300}
              error={errors?.purposeOfPayment?.message}
            />
            <div className="grid justify-items-end ">
              {`${t("Characters remaining to type")}:  ${
                purposeOfPayment?.length
              }/300`}
            </div>
          </div>
          <div className="py-2 w-full">
            <TextArea
              label={t("Message")}
              name="message"
              register={register}
              mandatory={true}
              rows={2}
              rules={{
                required: t("Message is a required field"),
                minLength: {
                  value: 5,
                  message: t("Message should be at least 5 characters long"),
                },
              }}
              maxLength={300}
              error={errors?.message?.message}
            />
            <div className="grid justify-items-end ">
              {`${t("Characters remaining to type")}:  ${message?.length}/300`}
            </div>
          </div>
          <div className="py-2">
            <div className="flex">
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                className=""
              >
                {t("Create payment link")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
