import React, { useEffect } from "react";
import SectionHeading from "../../../components/SectionHeading";
import RadioButton from "../../../components/RadioButton";
import "react-datepicker/dist/react-datepicker.css";
import { CONSTANTS as C } from "config";
import PropTypes from "prop-types";
import DayRow from "./DayRow";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { STOTE_UTILS } from "utils";

const StoreHoursSection = (props) => {
  const { t } = useTranslation();
  const [showSlot2, setShowSlot2] = React.useState({
    mondayStartTimeSlot2: false,
    tuesdayStartTimeSlot2: false,
    wednesdayStartTimeSlot2: false,
    thursdayStartTimeSlot2: false,
    fridayStartTimeSlot2: false,
    saturdayStartTimeSlot2: false,
    sundayStartTimeSlot2: false,
  });

  const showSlot2IfContainsValue = (key) => {
    if (props.store.values && props.store.values.workingHoursForPickup) {
      if (props.store.values.workingHoursForPickup[key]) {
        setShowSlot2((previous) => ({ ...previous, [key]: true }));
      }
    }
  };
  const slo2Array = [
    "mondayStartTimeSlot2",
    "tuesdayStartTimeSlot2",
    "wednesdayStartTimeSlot2",
    "thursdayStartTimeSlot2",
    "fridayStartTimeSlot2",
    "saturdayStartTimeSlot2",
    "sundayStartTimeSlot2",
  ];
  useEffect(() => {
    slo2Array.forEach((key) => showSlot2IfContainsValue(key));
  }, [props]);

  const handleSubmit = () => {
    const store = {
      values: props.store.values,
    };
    let objValid = STOTE_UTILS.VALIDATE_INPUTS(store);

    if (objValid.isFormValid) {
      props.addStore();
    }
  };

  const isSlotError = (key) => {
    if (props?.store?.errors?.workingHoursForPickup) {
      return props.store.errors.workingHoursForPickup[key];
    }
    return false;
  };

  const addSlotInput = (key) => {
    setShowSlot2({ ...showSlot2, [key]: true });
  };
  const removeSlotInput = (Slotstart, slotEnd) => {
    props.setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        workingHoursForPickup: {
          ...prevState.values.workingHoursForPickup,
          [Slotstart]: null,
          [slotEnd]: null,
        },
      },
      errors: {
        ...prevState.errors,
        workingHoursForPickup: {
          ...prevState.errors.workingHoursForPickup,
          [Slotstart]: null,
          [slotEnd]: null,
        },
      },
    }));

    setShowSlot2({ ...showSlot2, [Slotstart]: false });
  };
  const pickupHoursSection = (key, val) => {
    props.setStore((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        workingHoursForPickup: {
          ...prevState.values.workingHoursForPickup,
          [key]: typeof val == "boolean" ? val : new Date(val).getTime(),
        },
      },
      errors: {
        ...prevState.errors,
        workingHoursForPickup: {
          [key]: "",
        },
      },
    }));
  };
  return (
    <React.Fragment>
      <div className="bg-white">
        <SectionHeading title={t("Working Hours For Pickup")} />

        <div className="text-16px py-4">
          <h3 className="text-gray-600">{t("Opening/Closing Hours")}</h3>
          <h4 className="text-gray-400">
            {t("Set the hours your store is open for", {
              serviceType: t("Pickup"),
            })}
          </h4>
        </div>
        <div className="">
          <div>
            <RadioButton
              label={t("Open 24 Hours to Accept Orders")}
              value={props.store.values.isStoreOpen24HoursForPickup}
              keyVal={"isStoreOpen24HoursForPickup"}
              handleValues={props.handleTwentyFourHours}
            />
          </div>
          <div
            className={
              "py-6 " +
              (props.store.values.isStoreOpen24HoursForPickup
                ? "hidden"
                : "block")
            }
          >
            <div className="flex d-none d-lg-flex invisible lg:visible">
              <div className="col-auto" style={{ width: "175px" }}>
                <p className="f-w-500 mb-1">{t("Day")}</p>
              </div>
              <div className="col-auto px-2" style={{ width: "120px" }}>
                <p className="f-w-500 mb-1">{t("From")}</p>
              </div>
              <div className="col px-2">
                <p className="f-w-500 mb-1">{t("To")}</p>
              </div>
            </div>
            <DayRow
              id={C.KEY_FRI + "pickup"}
              label={t("FRIDAY")}
              keyDay={C.KEY_FRI}
              keyStartTime={C.KEY_FRI_START_TIME}
              keyEndTime={C.KEY_FRI_END_TIME}
              keyStartTime2={C.KEY_FRI_SLOT2_START_TIME}
              keyEndTime2={C.KEY_FRI_SLOT2_END_TIME}
              handleValues={pickupHoursSection}
              store={props.store}
              workingHours={props.store.values.workingHoursForPickup}
              showSlot2={showSlot2.fridayStartTimeSlot2}
              addSlotInput={() => addSlotInput(C.KEY_FRI_SLOT2_START_TIME)}
              removeSlotInput={() =>
                removeSlotInput(
                  C.KEY_FRI_SLOT2_START_TIME,
                  C.KEY_FRI_SLOT2_END_TIME
                )
              }
              s1Error={isSlotError("fridayStartTime")}
              s2Error={isSlotError("fridayStartTimeSlot2")}
              overLapError={isSlotError("fridayEndTime")}
            />
            <DayRow
              id={C.KEY_SAT + "pickup"}
              label={t("SATURDAY")}
              keyDay={C.KEY_SAT}
              keyStartTime={C.KEY_SAT_START_TIME}
              keyEndTime={C.KEY_SAT_END_TIME}
              keyStartTime2={C.KEY_SAT_SLOT2_START_TIME}
              keyEndTime2={C.KEY_SAT_SLOT2_END_TIME}
              handleValues={pickupHoursSection}
              store={props.store}
              workingHours={props.store.values.workingHoursForPickup}
              showSlot2={showSlot2.saturdayStartTimeSlot2}
              addSlotInput={() => addSlotInput(C.KEY_SAT_SLOT2_START_TIME)}
              removeSlotInput={() =>
                removeSlotInput(
                  C.KEY_SAT_SLOT2_START_TIME,
                  C.KEY_SAT_SLOT2_END_TIME
                )
              }
              s1Error={isSlotError("saturdayStartTime")}
              s2Error={isSlotError("saturdayStartTimeSlot2")}
              overLapError={isSlotError("saturdayEndTime")}
            />

            <DayRow
              id={C.KEY_SUN + "pickup"}
              label={t("SUNDAY")}
              keyDay={C.KEY_SUN}
              keyStartTime={C.KEY_SUN_START_TIME}
              keyEndTime={C.KEY_SUN_END_TIME}
              keyStartTime2={C.KEY_SUN_SLOT2_START_TIME}
              keyEndTime2={C.KEY_SUN_SLOT2_END_TIME}
              handleValues={pickupHoursSection}
              store={props.store}
              workingHours={props.store.values.workingHoursForPickup}
              showSlot2={showSlot2.sundayStartTimeSlot2}
              addSlotInput={() => addSlotInput(C.KEY_SUN_SLOT2_START_TIME)}
              removeSlotInput={() =>
                removeSlotInput(
                  C.KEY_SUN_SLOT2_START_TIME,
                  C.KEY_SUN_SLOT2_END_TIME
                )
              }
              s1Error={isSlotError("sundayStartTime")}
              s2Error={isSlotError("sundayStartTimeSlot2")}
              overLapError={isSlotError("sundayEndTime")}
            />

            <DayRow
              id={C.KEY_MON + "pickup"}
              label={t("MONDAY")}
              keyDay={C.KEY_MON}
              keyStartTime={C.KEY_MON_START_TIME}
              keyEndTime={C.KEY_MON_END_TIME}
              keyStartTime2={C.KEY_MON_SLOT2_START_TIME}
              keyEndTime2={C.KEY_MON_SLOT2_END_TIME}
              handleValues={pickupHoursSection}
              store={props.store}
              workingHours={props.store.values.workingHoursForPickup}
              showSlot2={showSlot2.mondayStartTimeSlot2}
              addSlotInput={() => addSlotInput(C.KEY_MON_SLOT2_START_TIME)}
              removeSlotInput={() =>
                removeSlotInput(
                  C.KEY_MON_SLOT2_START_TIME,
                  C.KEY_MON_SLOT2_END_TIME
                )
              }
              s1Error={isSlotError("mondayStartTime")}
              s2Error={isSlotError("mondayStartTimeSlot2")}
              overLapError={isSlotError("mondayEndTime")}
            />

            <DayRow
              id={C.KEY_TUE + "pickup"}
              label={t("TUESDAY")}
              keyDay={C.KEY_TUE}
              keyStartTime={C.KEY_TUE_START_TIME}
              keyEndTime={C.KEY_TUE_END_TIME}
              keyStartTime2={C.KEY_TUE_SLOT2_START_TIME}
              keyEndTime2={C.KEY_TUE_SLOT2_END_TIME}
              handleValues={pickupHoursSection}
              store={props.store}
              workingHours={props.store.values.workingHoursForPickup}
              showSlot2={showSlot2.tuesdayStartTimeSlot2}
              addSlotInput={() => addSlotInput(C.KEY_TUE_SLOT2_START_TIME)}
              removeSlotInput={() =>
                removeSlotInput(
                  C.KEY_TUE_SLOT2_START_TIME,
                  C.KEY_TUE_SLOT2_END_TIME
                )
              }
              s1Error={isSlotError("tuesdayStartTime")}
              s2Error={isSlotError("tuesdayStartTimeSlot2")}
              overLapError={isSlotError("tuesdayEndTime")}
            />

            <DayRow
              id={C.KEY_WED + "pickup"}
              label={t("WEDNESDAY")}
              keyDay={C.KEY_WED}
              keyStartTime={C.KEY_WED_START_TIME}
              keyEndTime={C.KEY_WED_END_TIME}
              keyStartTime2={C.KEY_WED_SLOT2_START_TIME}
              keyEndTime2={C.KEY_WED_SLOT2_END_TIME}
              handleValues={pickupHoursSection}
              store={props.store}
              workingHours={props.store.values.workingHoursForPickup}
              showSlot2={showSlot2.wednesdayStartTimeSlot2}
              addSlotInput={() => addSlotInput(C.KEY_WED_SLOT2_START_TIME)}
              removeSlotInput={() =>
                removeSlotInput(
                  C.KEY_WED_SLOT2_START_TIME,
                  C.KEY_WED_SLOT2_END_TIME
                )
              }
              s1Error={isSlotError("wednesdayStartTime")}
              s2Error={isSlotError("wednesdayStartTimeSlot2")}
              overLapError={isSlotError("wednesdayEndTime")}
            />

            <DayRow
              id={C.KEY_THU + "pickup"}
              label={t("THURSDAY")}
              keyDay={C.KEY_THU}
              keyStartTime={C.KEY_THU_START_TIME}
              keyEndTime={C.KEY_THU_END_TIME}
              keyStartTime2={C.KEY_THU_SLOT2_START_TIME}
              keyEndTime2={C.KEY_THU_SLOT2_END_TIME}
              handleValues={pickupHoursSection}
              store={props.store}
              workingHours={props.store.values.workingHoursForPickup}
              showSlot2={showSlot2.thursdayStartTimeSlot2}
              addSlotInput={() => addSlotInput(C.KEY_THU_SLOT2_START_TIME)}
              removeSlotInput={() =>
                removeSlotInput(
                  C.KEY_THU_SLOT2_START_TIME,
                  C.KEY_THU_SLOT2_END_TIME
                )
              }
              s1Error={isSlotError("thursdayStartTime")}
              s2Error={isSlotError("thursdayStartTimeSlot2")}
              overLapError={isSlotError("thursdayEndTime")}
            />
          </div>
        </div>
      </div>
      <div className="w-full text-center mb-4">
        {props.type === "create" ? (
          <Button
            type="primary"
            size="large"
            className="w-36"
            onClick={handleSubmit}
          >
            {t("Create Store")}
          </Button>
        ) : null}
      </div>
    </React.Fragment>
  );
};
StoreHoursSection.propTypes = {
  store: PropTypes.object,
  handleValues: PropTypes.func,
  handleTwentyFourHours: PropTypes.func,
};
export default StoreHoursSection;
