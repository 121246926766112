import React from "react";
import { useTranslation } from "react-i18next";
const Jeebly = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full lg:w-1/2 border border-gray-300 rounded px-2 py-2 mx-1">
      <div className="flex justify-betwen">
        <div className="flex w-1/2">
          <img
            src="/images/jeebly.png"
            style={{ height: "60px", width: "60px" }}
            width={60}
            height={60}
          />
          <h2 className="py-3 text-17px px-2">{t("Jeebly")}</h2>
        </div>
        <div className="w-1/2 text-right ihidden smsblock px-2 py-1">
          <button className="bg-yellow-200 text-yellow-600 w-32 font-semibold px-2 md:px-4 py-2 rounded">
            {t("Coming Soon")}
          </button>
        </div>
      </div>
      <h3>
        {t(
          "Dont have delivery? We covered you by integrating Jeebly. While Accepting an order select Jeebly. Send Email to"
        )}{" "}
        <span className="text-red-600">
          {" "}
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </span>
      </h3>
      <div className=" smblock smhidden py-1 pt-3">
        <button className="bg-yellow-200 text-yellow-600 w-32 font-semibold px-2 md:px-4 py-2 rounded">
          {t("Coming Soon")}
        </button>
      </div>
    </div>
  );
};
export default Jeebly;
