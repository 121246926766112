import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../components/Form";
import Heading from "../components/Heading";
import PaymentLinkStats from "../components/PaymentLinkStats";
import PaymentLinkTable from "../components/PaymentLinkTable";
import { UTILS } from "utils";
import { API } from "api";

import { Button, Spin } from "antd";

import { errorHandler } from "api/api/errorHandler";
import { downloadReports } from "utils/Utils";
import { errorNotification } from "utils/Utils";
const PaymentLinks = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [state, setState] = useState({
    startData: "",
    endData: "",
  });
  const [loading, setLoading] = useState(false);
  const [earning, setEarning] = useState(0);
  const [paymentLinkData, setPaymentData] = useState({
    data: [],
    totalPages: 1,
  });

  const resetstate = () => {
    setPaymentData((paymentLinkData) => ({
      data: [],
      totalPages: 1,
    }));
    setEarning(0);
  };

  const getPaymentLinksHistory = async (pageNumber) => {
    try {
      if (!UTILS.validateSelectedDates(state.startData, state.endData)) return;
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.GET_PAYMENTLINK_HISTORY({
        startDate: state.startData,
        endDate: state.endData,
        brandId: userDetail.brandId,
        page: pageNumber ?? page,
      });
      if (result && result.success === 1 && result?.data?.fetchLinks) {
        if (result?.data?.fetchLinks?.length > 0) {
          setPaymentData((prevState) => {
            return {
              data: result?.data?.fetchLinks,
              totalPages: result.data.count,
            };
          });

          setEarning(UTILS.totalLinksEarning(result?.data?.fetchLinks));
          UTILS.handleSuccesNotification(t(result.message));
        } else {
          resetstate();
          UTILS.handleSuccesNotification(t("No Payment Links Found"));
        }
      } else {
        resetstate();
        UTILS.handleErrorNotification(
          t(result?.error?.message || errorHandler(result?.error))
        );
      }
    } catch (error) {
      resetstate();
      UTILS.handleErrorNotification(
        t(error?.error?.message || errorHandler(error?.error))
      );
    } finally {
      setLoading(false);
    }
  };

  const downloadexcel = async () => {
    try {
      if (!UTILS.validateSelectedDates(state.startData, state.endData)) return;
      setLoading(true);
      const userDetail = UTILS.getCurrentUser();
      const result = await API.downloadPaymentLinksExcelReport({
        startDate: state.startData,
        endDate: state.endData,
        brandId: userDetail.brandId,
      });
      if (result?.success === 1 && result?.data) {
        UTILS.handleSuccesNotification(
          t("Payment Links Exported Successfully")
        );
        const url = result?.data;
        downloadReports(url);
      } else {
        UTILS.handleErrorNotification(
          t(result?.error?.message || errorHandler(result?.error))
        );
      }
    } catch (error) {
      errorNotification(errorHandler(error?.error?.message || error?.error));
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="px-4 mx-8 py-10">
      <Spin spinning={loading}>
        <Heading title={t("Payment Links History")} />
        <div className="flex justify-between flex-wrap items-center">
          <Form setState={setState} getOrdersHistory={getPaymentLinksHistory} />
          {paymentLinkData?.data?.length > 0 && (
            <Button
              onClick={downloadexcel}
              type="primary"
              className="mx-0 sm:mx-2 ant-btn ant-btn-primary w-36 mb-4 sm:mb-0"
            >
              {t("Export")}
            </Button>
          )}
        </div>

        <PaymentLinkStats earning={earning} />
        <PaymentLinkTable
          paymentLinks={paymentLinkData}
          handlePageChange={getPaymentLinksHistory}
          setPage={setPage}
        />
      </Spin>
    </div>
  );
};

export default PaymentLinks;
