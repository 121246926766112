import React from "react";
import { UTILS } from "utils";
import { useTranslation } from "react-i18next";
const LoadPreview = ({ imgSrc, imgDes }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-12 px-4">
      <div className="flex py-2 px-1 border-t border-b border-gray-200">
        <img src="/images/logo.png" width="37" height="20" />
        <a
          href="https://www.instagram.com/healthaholicad/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h1 className="py-1 font-bold text-14px mt-1 pl-4 hover:underline cursor-pointer">
            {UTILS.getCurrentUser().brandName}
          </h1>
        </a>
      </div>
      <div className="object-fill h-72">
        <img src={imgSrc} className="w-full h-full" />
      </div>
      <div className="flex justify-around">
        <div className="flex justify-left px-2 py-2 w-11/12">
          <div className="px-1">
            <img src="/images/insta-heart.png" width="25" height="15" />
          </div>
          <div className="px-1">
            <img src="/images/insta-comment.png" width="25" height="15" />
          </div>
          <div className="px-1">
            <img src="/images/send.png" width="25" height="15" />
          </div>
        </div>
        <div className="w-1/12 py-2">
          <img src="/images/insta-bookmark.png" width="25" height="15" />
        </div>
      </div>

      <div className="text-left px-4">
        <p className="font-bold">5 {t("Likes")}</p>
        <p className="text-sm">{imgDes}</p>
      </div>
    </div>
  );
};
export default LoadPreview;
