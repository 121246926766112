import { API } from "api";
import useSWR from "swr";
import { UTILS } from "utils";
import { CONSTANTS as C } from "config";

const useOrder = () => {
  const token = UTILS.getCurrentUser()?.token;
  const fetcher = () =>
    API.GET_ORDER_COUNT({ token: UTILS.getCurrentUser().token });

  const { data, error } = useSWR(
    token ? C.BASE_URL + "order/getAllPendingOrdersCount" : null,
    fetcher,
    {
      refreshInterval: 5000,
      revalidateOnFocus: true,
      refreshWhenHidden: true, // Allow polling even if tab is hidden
      refreshWhenOffline: true, // Allow polling even if the user is offline
      revalidateOnMount: true, // Always revalidate when the hook is mounted
      revalidateOnReconnect: true, // Revalidate when the user is back online
      revalidateOnWindowFocus: true,
    }
  );

  return {
    data: data,
    error: error,
  };
};

export default useOrder;
