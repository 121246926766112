import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Select, Button } from "antd";
import UserFeedbacksTable from "../components/UserFeedbacksTable";
import { UTILS } from "utils";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
import useAllStores from "hooks/useCurrentUserStores";
import RadioButton from "components/RadioButton";
import CustomSelctBox from "../components/SelectBox";
import { successNotification } from "utils/Utils";

const OrderTypes = ["All", "DELIVERY", "PICKUP", "DINE IN"];
const RatingTypes = ["All", "Bad", "Excellent"];
const ALL = "All";
const { Option } = Select;

const UserFeedbacks = () => {
  const { t } = useTranslation();
  const { stores, loading, setLoading } = useAllStores();
  const [state, setState] = useState({
    error: null,
    userFeedbacks: [],
    allUserFeedbacks: [],
    selectedStore: null,
    selectedOrderType: null,
    selectedRatingType: null,
    isCommentsOnly: false,
    currentPage: 1,
    showLoadMore: true,
  });

  const updateState = (key, value) =>
    setState((prevState) => ({ ...prevState, [key]: value }));

  const buildApiRequestParams = () => {
    const params = {
      brandId: UTILS.getCurrentUser()?.brandId,
      page: state.currentPage,
      limit: 10,
    };

    if (state?.selectedStore && state?.selectedStore !== ALL) {
      params.storeId = state.selectedStore;
    }

    if (state?.selectedOrderType && state?.selectedOrderType !== ALL) {
      params.serviceType = state.selectedOrderType;
    }

    if (state.isCommentsOnly) {
      params.isCommentsOnly = state.isCommentsOnly;
    }

    if (state?.selectedRatingType && state?.selectedRatingType !== ALL) {
      params.rating = state.selectedRatingType;
    }

    return params;
  };

  const fetchAllOrdersFeedbacks = async () => {
    try {
      setLoading(true);
      const result = await API.getOrdersBacks(buildApiRequestParams());

      if (result?.success === 1 && result?.data?.feedbacks) {
        const newData = result.data?.feedbacks;
        const allfeedacks = [...state.allUserFeedbacks, ...newData];
        updateState("allUserFeedbacks", allfeedacks);
        updateState("userFeedbacks", result?.data);
        updateState("currentPage", state.currentPage + 1);

        if (newData.length <= 0) {
          updateState("showLoadMore", false);
          successNotification(
            "You've reached the end of the feedbacks. No more data to load"
          );
        }
      } else {
        UTILS.handleErrorNotification(
          errorHandler(result?.error?.message || "Error fetching data")
        );
      }
    } catch (error) {
      UTILS.handleErrorNotification(
        errorHandler(error?.message || "An unexpected error occurred")
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userDetail = UTILS.getCurrentUser();
    if (!userDetail?.brandId) return;
    fetchAllOrdersFeedbacks();
  }, [
    state.selectedStore,
    state.selectedOrderType,
    state.isCommentsOnly,
    state.selectedRatingType,
  ]);

  const handleChangeOptions = (key, val) => {
    updateState("showLoadMore", true);
    updateState("allUserFeedbacks", []);
    updateState("userFeedbacks", []);
    updateState("currentPage", 1);
    updateState(key, val);
  };

  return (
    <div className="px-4 mx-0 md:mx-8 pb-10">
      <Spin spinning={loading}>
        <div className="text-gray-900 text-24px mb-4 md:text-30px font-medium">
          {t("Order Feedbacks")}
        </div>
        <div className="flex justify-between items-center gap-2">
          <div className="flex-1">
            <div className="">
              <Select
                placeholder={t("Select Store")}
                className="me-4"
                style={{ margin: "2px 4px" }}
                value={state.selectedStore}
                onChange={(value) =>
                  handleChangeOptions("selectedStore", value)
                }
              >
                {/* Populate with store options */}
                <Option value={ALL}>{t("All Stores")}</Option>
                {stores?.map((store) => (
                  <Option key={store.value} value={store.value}>
                    {store.label}
                  </Option>
                ))}
              </Select>
              <CustomSelctBox
                placeholder={t("All Order Types")}
                className="me-4"
                value={state.selectedOrderType}
                onChange={(value) =>
                  handleChangeOptions("selectedOrderType", value)
                }
                options={OrderTypes}
              />
              <CustomSelctBox
                placeholder={t("All Ratings")}
                className="me-4"
                value={state.selectedRatingType}
                onChange={(value) =>
                  handleChangeOptions("selectedRatingType", value)
                }
                options={RatingTypes}
              />
              <div className="flex justify-items-start items-center my-2">
                <div>
                  <RadioButton
                    label={""}
                    value={state.isCommentsOnly}
                    keyVal={state.isCommentsOnly}
                    handleValues={(value) => {
                      handleChangeOptions("isCommentsOnly", !value);
                    }}
                  />
                </div>
                <div className="text-15px text-gray-400 mt-3">
                  {t("Only With Notes")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserFeedbacksTable
          feedbacks={state.allUserFeedbacks}
          avgOrderRating={state.userFeedbacks?.avgOrderRating}
          feedbackRatingCount={state.userFeedbacks?.feedbackRatingCount}
          totalCount={state.userFeedbacks?.totalCount}
        />
        {state?.showLoadMore && (
          <div className="flex justify-center items-center gap-2">
            <Button type="primary" onClick={() => fetchAllOrdersFeedbacks()}>
              {loading ? t("Loading...") : t("Load More")}
            </Button>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default UserFeedbacks;
