import Card from "components/Card";
import PageHeading from "components/PageHeading";
import React, { useEffect, useMemo, useState } from "react";
import { UTILS } from "utils";
import { Accordion } from "features/menu/components/AccordionN";
import SingleGroup from "./SingleGroup";
import ModifierRow from "./ModifierRow";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { Button, Spin } from "antd";
import Modal from "components/Modal";
import { isMobile } from "react-device-detect";
import AttachModifierGroupWithItem from "./AttachModifierGroupWithItem";
import {
  useGetModifierGroupsMutation,
  useSortModifierGroupMutation,
} from "redux/modifiers/service";
import { API } from "api";
import { errorHandler } from "api/api/errorHandler";
const _ = require("lodash");

const GroupWithModifer = ({
  attachedModifierGroups,
  item,
  getItemDetail,
  deleteModifierGrp,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [brandModifiers, setbrandModifiers] = useState([]);
  const [List, setList] = useState(attachedModifierGroups);

  const [openGroupModal, setopenGroupModal] = useState(false);
  const [getModifierGroups] = useGetModifierGroupsMutation();

  const getAllModiferGroups = async () => {
    setLoading(true);
    try {
      const userDetail = UTILS.getCurrentUser();
      const { data } = await getModifierGroups({
        brandId: userDetail.brandId,
      });
      if (data?.success === 1) {
        setbrandModifiers(data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllModiferGroups();
  }, []);

  const getCategoryView = (modiferGroup, index) => {
    return (
      <div key={modiferGroup._id}>
        <SingleGroup
          key={modiferGroup._id}
          toggle={modiferGroup._id}
          modiferGroup={modiferGroup}
          item={item}
          deleteModifierGrp={deleteModifierGrp}
        />
        <ModifierRow modiferGroup={modiferGroup} />
      </div>
    );
  };

  const sortModiferGrp = async (group) => {
    try {
      let selected = List[group.oldIndex];
      const userDetail = UTILS.getCurrentUser();
      setLoading(true);
      const result = await API.sortModifierGroup({
        brandId: userDetail.brandId,
        itemId: item.values?._id,
        modifierGroupId: selected?.modifierGroupId?._id,
        index: group.newIndex,
      });
      if (result?.success === 1) {
        // UTILS.successNotification(result?.message ?? t("sorted Successfully"));
      } else {
        UTILS.errorNotification(errorHandler(result?.message));
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
    }
  };
  const MemizedModifiers = useMemo(() => {
    const attachedModifiersLookup = _.keyBy(
      attachedModifierGroups,
      (item) => item?.modifierGroupId?._id
    );

    const unattachedModifiers = brandModifiers?.filter(
      (item) => !attachedModifiersLookup[item?._id]
    );
    setList(attachedModifierGroups);
    return unattachedModifiers;
  }, [attachedModifierGroups, brandModifiers]);

  return (
    <div className="h-full">
      <PageHeading heading={t("Menu Editor")} />
      <Modal
        scroll={false}
        open={openGroupModal}
        modalWidth="w-full md:w-3/4 rounded-md border border-gray-300"
        onClose={() => setopenGroupModal(false)}
      >
        <AttachModifierGroupWithItem
          AttachedModiers={attachedModifierGroups}
          BrandModifers={brandModifiers}
          MemizedModifiers={MemizedModifiers}
          item={item}
          onClose={() => setopenGroupModal(false)}
          getItemDetail={getItemDetail}
        />
      </Modal>
      <div className="py-4">
        <Card>
          <div className="flex justify-between flex-wrap h-full">
            <div className="w-full px-2 md:px-4 flex-wrap h-full">
              <div className="py-2 flex items-center justify-between flex-wrap border-b border-gray-200">
                <div className="">
                  <h2 className="text-19px text-blue-600 mb-2 md:mb-0">
                    {t("Attached Modifer Groups")}
                  </h2>
                </div>
                <div className="flex justify-center w-full sm:w-auto">
                  <Button
                    type="primary"
                    size="large"
                    className=""
                    onClick={() => setopenGroupModal(true)}
                  >
                    {t("Attach New Modifer Group")}
                  </Button>
                </div>
              </div>
              <div className="py-2 md:py-12 overflow-x-auto">
                <Spin spinning={loading}>
                  <Accordion>
                    {List && List?.length >= 1 ? (
                      isMobile ? (
                        <>
                          {List &&
                            List?.map((cat, index) => {
                              return getCategoryView(cat, index);
                            })}
                        </>
                      ) : (
                        <ReactSortable
                          list={List}
                          setList={setList}
                          animation={300}
                          onUpdate={(order) => {
                            sortModiferGrp(order);
                          }}
                        >
                          {List &&
                            List?.map((cat, index) => {
                              return getCategoryView(cat, index);
                            })}
                        </ReactSortable>
                      )
                    ) : (
                      <div className="flex justify-center w-full">
                        <div className="pb-16">
                          <p>{t("There is no modifier group attached")}</p>
                        </div>
                      </div>
                    )}
                  </Accordion>
                </Spin>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
export default GroupWithModifer;
