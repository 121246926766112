import { API } from "api";
import PageHeading from "components/PageHeading";
import React, { useEffect, useState } from "react";
import { UTILS } from "utils";
import { Button, Space, Spin, Table, Tooltip } from "antd";
import RadioButton from "components/RadioButton";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import Title from "antd/lib/typography/Title";
import useWindowDimensions from "hooks/useWindowDimensions";
import LoadPreviewWithDevice from "../components/LoadPreviewWithDevice";
import LoadPreview from "../components/LoadPreview";
const Marketing = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImageSrc] = useState("");
  const [imgDes, setDes] = useState("");
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  // const [itemToDisplay, setItemToDisplay] = useState();

  const getItemsByBrand = async () => {
    setLoading(true);
    const userDetail = UTILS.getCurrentUser();
    const result = await API.GET_ITEMS_BY_BRAND({
      token: userDetail.token,
      brandId: userDetail.brandId,
      language: "EN",
    });
    if (result && result.success == 1) {
      let arr = result.data;
      if (Array.isArray(arr) && arr.length > 0) {
        const modifiedArr = arr.map((obj) => (obj = { ...obj, isON: false }));
        modifiedArr[0].isON = true;
        setItems(modifiedArr);
        setImageSrc(result.data[0].itemImageURLs[0]);
        const userDetail = UTILS.getCurrentUser();
        const text =
          userDetail.storeFrontURL + userDetail.brandName + "?platform=insta";
        setDes(
          result.data[0]?.baseLanguage?.title +
            ", " +
            result.data[0]?.baseLanguage?.description +
            ", only " +
            result?.data[0]?.price +
            " AED, Order Now: " +
            text
        );
      }
    } else {
    }
    setLoading(false);
  };

  useEffect(() => {
    getItemsByBrand();
  }, []);

  const renderTitleWithImage = (value, rowData, index) => {
    return (
      <div className="flex justify-between items-center text-left">
        <img
          src={
            rowData.itemImageURLs.length > 0
              ? rowData.itemImageURLs[0]
              : "/images/logo_gray.png"
          }
          className="w-20 h-20"
        />
        <div className="text-left w-48 md:w-full ml-4 my-auto">
          <h3 className="leading-4 text-16px truncate-line-3 font-bold">
            {UTILS.isValidObject(["baseLanguage", t("title")], rowData)
              ? rowData.baseLanguage.title
              : ""}
          </h3>
          {/* <p className="text-12px text-gray-500 pt-2"><span className="text-indigo-700 font-bold">Ingredients: </span>{UTILS.isValidObject(["baseLanguage","ingredients"],rowData) ? rowData.baseLanguage.ingredients : ""}</p> */}
          <p className="text-12px truncate-line-4 text-gray-500 pt-1">
            <span className="text-indigo-700 font-bold"></span>
            {UTILS.isValidObject(["baseLanguage", t("description")], rowData)
              ? rowData.baseLanguage.description
              : ""}
          </p>
        </div>
      </div>
    );
  };

  const handleValues = (key, val) => {
    let arr = [...items];
    let modifiedArr = arr.map((obj) => (obj = { ...obj, isON: false }));
    modifiedArr[key].isON = val;
    setItems(modifiedArr);
    if (val) {
      setImageSrc(arr[key].itemImageURLs[0]);
      const userDetail = UTILS.getCurrentUser();
      const text =
        userDetail.storeFrontURL + userDetail.brandName + "?platform=insta";
      setDes(
        arr[key]?.baseLanguage?.title +
          ", " +
          arr[key]?.baseLanguage?.description +
          ", only " +
          arr[key]?.price +
          " AED, Order Now: " +
          text
      );
    }
  };

  const renderSelection = (value, rowData, index) => {
    return (
      <div className="px-3 py-1 whitespace-nowrap text-center">
        <RadioButton
          label={""}
          value={rowData.isON}
          keyVal={index}
          handleValues={handleValues}
        />
      </div>
    );
  };

  const columns = [
    {
      title: t("Select"),
      width: "20%",
      render: renderSelection,
    },
    {
      title: t("Item"),
      dataIndex: "baseLanguage",
      render: renderTitleWithImage,
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: "20%",
      render: (val, rowData, index) => {
        return t("AED") + " " + val;
      },
    },
  ];

  return (
    <div>
      <PageHeading heading={t("Marketing")} />
      <div className="flex justify-between flex-wrap border-t border-gray-200">
        <div className="w-full lg:w-7/12 px-2 py-2">
          <Spin spinning={loading}>
            <Card>
              <h1 className="text-blue-600 text-19px px-4 pt-4">
                {t("Items")}
              </h1>
              <h3 className="text-gray-500 px-4 pb-4">
                {t("Select Items from Left Pane to post on Social Media.")}
              </h3>
              <div className="px-2 py-2">
                <Card>
                  <div className="overflow-hidden overflow-x-auto md:overflow-hidden">
                    <Table
                      locale={{ emptyText: t("No Data") }}
                      columns={columns}
                      dataSource={items}
                      pagination={{ position: ["bottomCenter"] }}
                      size="small"
                    />
                  </div>
                </Card>
              </div>
            </Card>
          </Spin>
        </div>
        <div className="w-full lg:w-5/12 bg-gray-50 text-center justify-center mx-auto border-l border-gray-200">
          <div className="flex justify-between border-b border-gray-200 bg-white">
            <h1 className="px-8 text-blue-600 bg-white py-4 text-19px">
              {t("Instagram Preview")}
            </h1>
            <Button
              type="primary"
              size="medium"
              className="w-24 my-2 mx-4"
              onClick={() =>
                UTILS.HANDLE_SUCCESS_NOTIFICATION(
                  "topRight",
                  "success",
                  t("Coming Soon!")
                )
              }
            >
              {t("Post")}
            </Button>
          </div>

          <div className="flex w-full justify-center text-center mx-auto mb-8 mt-4">
            {width >= 768 ? (
              <LoadPreviewWithDevice imgSrc={imgSrc} imgDes={imgDes} />
            ) : (
              <LoadPreview imgSrc={imgSrc} imgDes={imgDes} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Marketing;
