import React from "react";
import PropTypes from "prop-types";
import { getI18n } from "react-i18next";

const getLabel = (label) => {
  if (label !== "") {
    return (
      <label className="font-montserrat text-gray-600 block text-14px">
        {label}
      </label>
    );
  }
};

const getKeyAgainstLanguage = () => {
    const lang = getI18n();
    return lang.language == "en" ? "en" : "ar";
}


const SelectMultiLingualInput = (props) => {
  const mandatoryLabel = getLabel(props.label);
  return (
    <>
      {mandatoryLabel}
      <select
        className={props.styleClass}
        onChange={(e) => props.handleChange(props.keyVal, e.target.value)}
        value= {props.value ? props.value : null}
      >
        <option value="null" key={`0`}>
          select
        </option>
        {props.options.map((option) => (
          <option value={option.value} key={option.value} selected={option.value === props.defaultSelection ? "selected" : ""}>
            {option.label[getKeyAgainstLanguage()]}
          </option>
        ))}
      </select>
    </>
  );
};

SelectMultiLingualInput.propTypes = {
  keyVal: PropTypes.string,
  label: PropTypes.string,
  styleClass: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  value:PropTypes.string,
  defaultSelection: PropTypes.string,
};
export default SelectMultiLingualInput;
