import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import TextInput from "components/TextInput";
import { SVG_Business } from "svg/SVGs";
import { errorHandler } from "api/api/errorHandler";
import { UTILS } from "utils";
import { API } from "api";

const UpdateCategory = (props) => {
  const [category, setCategory] = useState(UTILS.getCategoryModel());
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleValues = (key, val) => {
    setCategory((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        baseLanguage: {
          [key]: val,
        },
      },
      errors: {
        baseLanguage: {
          [key]: "",
        },
      },
    }));
  };

  useEffect(() => {
    setCategory((prevState) => ({
      ...prevState,
      values: props.cat,
      errors: prevState.errors,
    }));
  }, []);
  const validateInput = () => {
    if (
      !category.values.baseLanguage.name ||
      UTILS.hasWhiteSpace(category.values.baseLanguage.name)
    ) {
      setCategory((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
        },
        errors: {
          baseLanguage: {
            ["name"]: t("Enter category name"),
          },
        },
      }));
      return false;
    } else {
      return true;
    }
  };
  const updateCategory = async () => {
    try {
      if (validateInput()) {
        const userDetaill = UTILS.getCurrentUser();
        setLoading(true);

        const {
          baseLanguage: { name },
        } = category.values;
        const trimmedName = name?.trim();
        const result = await API.UPDATE_CATEGORY({
          categoryId: category?.values?._id,
          ...category.values,
          brandId: userDetaill?.brandId,
          baseLanguage: { name: trimmedName },
        });
        if (result?.success === 1) {
          UTILS.handleSuccesNotification(result?.message);
          props.handleValues(category.values);
        } else {
          UTILS.errorNotification(errorHandler(result?.message));
        }
      }
    } catch (error) {
      UTILS.errorNotification(errorHandler(error?.error?.message));
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      <div className="">
        <Spin spinning={loading}>
          <div className="text-center p-5 flex-auto justify-center">
            <SVG_Business />
            <h2 className="text-xl font-bold py-4 ">{t("Update Category")}</h2>
            <div className="px-4 text-left">
              <TextInput
                label={t("Name:")}
                placeHolder={t("Example watches")}
                value={category?.values?.baseLanguage?.name}
                keyVal={"name"}
                handleValues={handleValues}
                mandatory={true}
                error={category.errors?.baseLanguage?.name}
              />
            </div>
          </div>
          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <Button type="default" size="large" onClick={props.onClose}>
              {t("Cancel")}
            </Button>
            <Button type="primary" size="large" onClick={updateCategory}>
              {t("Update")}
            </Button>
          </div>
        </Spin>
      </div>
    </div>
  );
};

UpdateCategory.propTypes = {
  handleValues: PropTypes.func,
  onClose: PropTypes.func,
  cat: PropTypes.object,
  index: PropTypes.number,
};
export default UpdateCategory;
