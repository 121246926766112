import React from "react";
import CareemClaimInfo from "./CareemClaimInfo";
import CareeemOrderInfo from "./CareemORderInfo";
import ShowCourierData from "./TalabatOrderInfo";
import YandexClaimInfo from "./YangoClaimInfo";
import YangoOrderInfo from "./YangoOrderInfo";
import LyveGlobalOrderInfo from "./LyveGlobalOrderInfo";
import DZOrderInfo from "./DZOrderInfo";

function Index({
  courierModal,
  setCourierModal,
  rowData,
  getDZOrderStatus,
  DZOrder,
  getlyveGlobalOrderInfo,
  lyveGlobalOrderInfo,
  careemOrder,
  setCareemOrder,
  orderInfo,
}) {
  return (
    <>
      <CareeemOrderInfo
        open={courierModal.CAREEM_NOW}
        setOpen={() =>
          setCourierModal({
            ...courierModal,
            CAREEM_NOW: false,
          })
        }
      >
        <CareemClaimInfo order={rowData} />
      </CareeemOrderInfo>
      <DZOrderInfo
        open={courierModal.DELIVERY_ZONE}
        setOpen={() =>
          setCourierModal({
            ...courierModal,
            DELIVERY_ZONE: false,
          })
        }
        order={rowData}
        {...{ getDZOrderStatus, DZOrder }}
      />
      <LyveGlobalOrderInfo
        open={courierModal.LYVE_GLOBAL}
        setOpen={() =>
          setCourierModal({
            ...courierModal,
            LYVE_GLOBAL: false,
          })
        }
        order={rowData}
        getlyveGlobalOrderInfo={getlyveGlobalOrderInfo}
        lyveGlobalOrderInfo={lyveGlobalOrderInfo}
      />
      <YangoOrderInfo
        open={courierModal.YANDEX}
        setOpen={() =>
          setCourierModal({
            ...courierModal,
            YANDEX: false,
          })
        }
      >
        <YandexClaimInfo
          careemOrder={careemOrder}
          setCareemOrder={setCareemOrder}
          order={rowData}
          type="completed_order"
        />
      </YangoOrderInfo>
      <ShowCourierData
        order={orderInfo}
        open={courierModal.TALABAT}
        setOpen={setCourierModal}
      />
    </>
  );
}

export default Index;
