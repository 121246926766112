import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UTILS } from "utils";
import PageHeading from "components/PageHeading";
import AreasListingContainer from "./AreasListingContainer";
import { LoaderAction } from "redux/actions";
import useGetAllBusinessAreas from "./PolygonBased/hooks/useGetAllBusinessAreas";
import useGetStoreAddedMappedAreas from "./PolygonBased/hooks/useGetStoreAddedMapAreas";

const ManageAreas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedStore } = useSelector((state) => state.AreasReducer);
  const getStoreMappedAddedAreas = useGetStoreAddedMappedAreas();

  const handleAddedMapAreas = async () => {
    try {
      dispatch(LoaderAction.setLoading(true));
      const userDetail = UTILS.getCurrentUser();
      if (userDetail?.brandId && selectedStore?.value) {
        await getStoreMappedAddedAreas({
          storeId: selectedStore.value,
        });
      }
      dispatch(LoaderAction.setLoading(false));
    } catch (error) {}
  };

  useGetAllBusinessAreas(handleAddedMapAreas);

  return (
    <div>
      <PageHeading heading={t("Delivery Areas")} />
      <div className="pb-3 px-2">
        <AreasListingContainer />
      </div>
    </div>
  );
};

export default ManageAreas;
