import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const getMandatoryField = (man) => {
  if (man) {
    return <span className="text-red-600"> *</span>;
  }
};

const getFieldError = (error) => {
  if (error) {
    return <span className="text-red-600"> {error} </span>;
  }
};

const BgPasswordInput = (props) => {
  const mandatoryField = getMandatoryField(props?.mandatory || false);
  const fieldError = getFieldError(props?.error || false);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="rounded bg-input-bg py-2 px-4">
      {/* <div className="py-2 px-4 bg-input-bg rounded "> */}
      <label className="font-medium block">
        {props.label}
        {mandatoryField} {fieldError}
      </label>
      <div className="inline-flex items-center w-full bg-white border border-gray-300">
        <input
          onChange={(event) =>
            props.handleValues(props.keyVal, event.target.value)
          }
          value={props.value}
          type={isVisible ? "text" : "password"}
          placeholder={props.placeHolder}
          className="w-full px-2 py-2.5 outline-none text-16px"
        />
        <button
          className="rounded-md px-1"
          onClick={(event) => {
            event.preventDefault();
            setIsVisible(!isVisible);
          }}
          type="button"
        >
          <div className={!isVisible ? "hidden" : "block"}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="gray"
              viewBox="0 0 24 24"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              ></path>
            </svg>
          </div>

          <div className={isVisible ? "hidden" : "block"}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="gray"
              viewBox="0 0 24 24"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              ></path>
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

BgPasswordInput.propTypes = {
  mandatory: PropTypes.bool,
  error: PropTypes.bool,
  handleValues: PropTypes.func,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  keyVal: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  styleClasses: PropTypes.string,
};

export default BgPasswordInput;
