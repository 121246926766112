import { Spin } from "antd";
import Modal from "components/Modal";
import React from "react";
const AlertBox = (props) => {
  return (
    <Spin spinning={props.isLoading}>
      <div className="p-4 py-16 text-center">
        <h3 className="font-medium text-18px mb-8">
          {props?.modal?.description}
        </h3>

        <div>
          <button
            onClick={props.PressYes}
            className="px-12 py-2 me-2 my-2  text-black rounded border  border-red-500"
          >
            {props?.modal?.successBtnText}
          </button>

          {props?.modal?.cancelBtnText && (
            <button
              onClick={props.closeModal}
              className="px-12 py-2 me-2 my-2 text-black rounded border border-green-500 "
            >
              {props?.modal?.cancelBtnText}
            </button>
          )}
        </div>
      </div>
    </Spin>
  );
};

function ModalBox({ open, modal, setModal, PressYes, closeModal, isLoading }) {
  return (
    <div>
      <Modal
        open={open}
        modalWidth="rounded-md border border-gray-300 top-0 w-full md:w-1/2"
        onClose={() => {}}
      >
        <AlertBox {...{ modal, PressYes, closeModal, isLoading }} />
      </Modal>
    </div>
  );
}

export default ModalBox;
