import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin, Button, Modal } from "antd";

import TextInput from "components/TextInput";
import useUpdateMenu from "features/menus/hooks/useUpdateMenu";
import { errorNotification } from "utils/Utils";

function EditMenu({ menuState, updateState }) {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.LoaderReducer);

  const menuUpdate = useUpdateMenu();
  const [editMenu, setEditMenu] = useState({
    values: {
      name: "",
      description: "",
      _id: "",
    },
    errors: {
      name: "",
      description: "",
    },
  });

  const handleChange = (key, value) => {
    setEditMenu((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: value,
      },
      errors: {
        ...prevState.errors,
        [key]: "",
      },
    }));
  };
  const handleSetErrors = (key, value) => {
    setEditMenu((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [key]: value,
      },
    }));
  };

  const EditSelectedMenu = async () => {
    if (!editMenu?.values?.name) {
      handleSetErrors("name", "Please enter menu name");
      return;
    }

    if (
      editMenu?.values?.description &&
      editMenu?.values?.description?.length < 5
    ) {
      handleSetErrors("description", "Please enter minimum 5 characters");
      return;
    }
    try {
      await menuUpdate({
        rowData: {
          baseLanguage: {
            name: editMenu?.values?.name,
          },
          ...(EditMenu?.values?.description?.trim() && {
            description: editMenu?.values?.description,
          }),
          _id: editMenu?.values?._id,
        },
        event: menuState.isActive,
      });
    } catch (error) {
      errorNotification(error.message);
    } finally {
    }
  };
  const resetState = () => {
    setEditMenu({
      values: {
        name: "",
        description: "",
      },
      errors: {
        name: "",
        description: "",
      },
    });
  };

  useEffect(() => {
    if (menuState?.menuInfo) {
      setEditMenu({
        values: {
          name: menuState?.menuInfo?.baseLanguage?.name,
          description: menuState?.menuInfo?.description,
          _id: menuState?.menuInfo?._id,
        },
        errors: {
          name: "",
          description: "",
        },
      });
    }
  }, [menuState.editMenumodal, menuState?.menuInfo]);

  return (
    <div key={menuState?.menuInfo?._id}>
      <Modal
        onCancel={() => {
          updateState("editMenumodal", false);
          resetState();
        }}
        footer={[
          <div className="flex justify-center">
            <Button type="primary" onClick={EditSelectedMenu}>
              {t("Save")}
            </Button>
            <Button
              onClick={() => {
                updateState("editMenumodal", false);
              }}
            >
              {t("Cancel")}
            </Button>
          </div>,
        ]}
        title={t("Edit Menu")}
        width={500}
        open={menuState.editMenumodal}
      >
        <Spin spinning={isLoading}>
          <div className="py-2">
            <TextInput
              label={t("Menu Name")}
              placeHolder={t("E.g. Delivery menu")}
              value={editMenu?.values?.name}
              keyVal="name"
              mandatory={true}
              error={editMenu?.errors?.name}
              handleValues={handleChange}
            />
          </div>
          <div className="py-2">
            <TextInput
              label={t("Menu description")}
              placeHolder={t("E.g. menu for delivery")}
              value={editMenu?.values?.description}
              keyVal="description"
              mandatory={false}
              error={editMenu?.errors?.description}
              handleValues={handleChange}
            />
          </div>
        </Spin>
      </Modal>
    </div>
  );
}

export default EditMenu;
