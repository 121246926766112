export const KEY_PREORDER_MIN_TIME_IN_ADVANCE_DELIVERY =
  "preOrderMinTimeInAdvanceDelivery";
export const KEY_PREORDER_MIN_TIME_IN_ADVANCE_PICKUP =
  "preOrderMinTimeInAdvancePickup";

export const KEY_PREORDER_MIN_TIME_IN_ADVANCE_UNIT =
  "preOrderMinTimeInAdvanceUnit";
export const KEY_PREORDER_MAX_DAYS_IN_ADVANCE = "preOrderMaxDaysInAdvance";
export const KEY_PREORDER_MAX_DAYS_IN_ADVANCE_UNIT =
  "preOrderMaxDaysInAdvanceUnit";
export const KEY_PREORDER_MIN_DAYS_IN_ADVANCE_UNIT =
  "preOrderMinDaysInAdvanceUnit";
export const KEY_PREORDER_SLOT_INTERVAL = "preOrderSlotInterval";
export const KEY_PREORDER_SLOT_INTERVAL_UNIT = "preOrderSlotIntervalUnit";
