import React, { useEffect } from "react";
import { Button, Checkbox, Spin } from "antd";
import { useSelector } from "react-redux";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";
import { CONSTANTS as C } from "config";
import { useGetOrderInfoByIdMutation } from "redux/Logistic/LogisticService";
import { UTILS } from "utils";

const OrderRejectForm = ({
  closeModal,
  rejectReason,
  handleValues,
  onSubmit,
  loading,
  order,
  handleErrors,
}) => {
  const { t } = useTranslation();
  const [getOrderInfoById] = useGetOrderInfoByIdMutation();
  const { orderInfo } = useSelector((state) => state.LogisticReducer);

  useEffect(() => {
    async function fetchOrderInfo() {
      try {
        await getOrderInfoById({
          brandId: UTILS.getCurrentUser().brandId,
          orderId: order?._id,
        });
      } catch (error) {
      } finally {
        if (orderInfo?.logisticPartner !== C.STORE) {
          handleErrors(
            "logisticPartner",
            t(
              "You have already sent a rider request to , so cancel it as well. Otherwise, you will be charged",
              {
                logisticPartner: orderInfo?.logisticPartner,
              }
            )
          );
        }
      }
    }
    fetchOrderInfo();
    return () => {
      handleErrors("logisticPartner", null);
    };
  }, [order?.logisticPartner, order?.orderNumber]);
  return (
    <Spin spinning={loading}>
      <div className="w-full p-5 relative mx-auto my-auto bg-white ">
        <div className="">
          <div className="flex-auto justify-center my-2">
            <div className="flex justify-between">
              <h2 className="font-semibold me-4">
                {rejectReason.values?.type === "CANCELLED"
                  ? t("Cancel Order")
                  : t("Reject Order")}
              </h2>

              <h2 className="font-semibold">#{order?.orderNumber}</h2>
            </div>
            <div className="px-4 text-auto pt-4 pb-2">
              <TextInput
                label={t("Reason")}
                value={rejectReason?.values?.reason}
                handleValues={handleValues}
                keyVal="reason"
                mandatory={true}
                error={rejectReason?.errors?.reason}
              />
            </div>
            {order?.orderCheckoutOnlinePaymentMetadata?.data?.paymentGateway ===
              "STRIPE" && (
              <div className="px-4">
                <Checkbox
                  checked={rejectReason?.values?.isRefundRequired}
                  defaultChecked
                  onChange={(e) =>
                    handleValues("isRefundRequired", e.target.checked)
                  }
                >
                  {t("Is Refundable")}
                </Checkbox>
              </div>
            )}
          </div>
          {rejectReason?.errors?.logisticPartner ? (
            <div className="text-red-500 px-4 font-semibold">
              {rejectReason?.errors?.logisticPartner}
            </div>
          ) : null}
          <div className=" mt-2 px-4 text-center md:block flex justify-end">
            <Button
              type="default"
              size="large"
              className="w-24 mx-2"
              onClick={closeModal}
            >
              {t("Close")}
            </Button>
            <Button
              className="w-24"
              type="danger"
              size="large"
              onClick={() => onSubmit("CANCELLED", "REJECT")}
              htmlType="button"
            >
              {rejectReason.values?.type === "REJECTED" && t("Reject")}
              {rejectReason.values?.type === "CANCELLED" && t("Cancel")}
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default OrderRejectForm;
